/*
 * Venn Platform API 1.0.0 Operations for "factorlenses-v2"
 * Automatically generated file - do not modify!
 */

import type { NotableFactorPeriodGroup } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Get a factor periods group by factor for a specific factorlens ID */
export async function getNotableFactorLensPeriodsByIdV2(
  id: number,
  signal?: AbortSignal,
): Promise<OperationResult<NotableFactorPeriodGroup[]>> {
  const endpoint = `/api/factorlenses/v2/${encodeURIComponent(id)}/periods`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: NotableFactorPeriodGroup[] = (await response.json()) as NotableFactorPeriodGroup[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get notable factor periods for user */
export async function getNotableFactorLensPeriodsV2(
  signal?: AbortSignal,
): Promise<OperationResult<NotableFactorPeriodGroup[]>> {
  const endpoint = '/api/factorlenses/v2/periods';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: NotableFactorPeriodGroup[] = (await response.json()) as NotableFactorPeriodGroup[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
