import { isNil, uniq } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import type { AnalysisView, PrivatePortfolioNode } from 'venn-api';
import { getSpecificAnalysisView, viewEntity } from 'venn-api';
import type { AfterUnsavedChangeAction } from 'venn-components';
import { EditMode, PortfoliosContext, StudioPrintSettingsContext, UserContext, useAppPrintMode } from 'venn-components';
import {
  allocatorAnalysisSubject,
  currentAnalysisView,
  customizedViews,
  openPrivateAllocatorPortfolio,
  originalAnalysisSubjectQuery,
  useSendSignal,
  useUnsavedChanges,
} from 'venn-state';
import { Notifications, NotificationType } from 'venn-ui-kit';
import type { CustomizableBlockSetting, StudioTemplateState } from 'venn-utils';
import {
  AnalysisSubject,
  getBlankStudio,
  getNonTemplateType,
  getTemplateType,
  getViewIdFromLocation,
  isStudioTemplate,
  logExceptionIntoSentry,
  parseValueFromLocation,
  Routes,
  updateUrlParam,
} from 'venn-utils';

import { compareViewsHasDiff, trackViewOpened } from './studioUtils';
import { useSyncAllocatorConfigToView } from './useSyncAllocatorConfigToView';
import { useSyncPrivateAllocatorConfigToView } from './useSyncPrivateAllocatorConfigToView';

/** Handle Studio setup information, such as fetching new view, store view into localstorage and sync url */
const useStudioSetup = () => {
  const { demoPortfolio } = useContext(PortfoliosContext);
  const { hasPermission, currentContext } = useContext(UserContext);
  const { addDisclosurePage, loadSettings: loadStudioPrintSettings } = useContext(StudioPrintSettingsContext);
  const isFetchingNewViewRef = useRef(false);
  const [draggingBlock, setDraggingBlock] = useState<CustomizableBlockSetting>();
  const history = useHistory();
  const location = useLocation<StudioTemplateState>();
  const { inPrintMode } = useAppPrintMode();
  const locationSavedId = parseValueFromLocation(location, 'savedId');

  const setPortfolioInPrivateAllocator = useRecoilCallback(
    ({ snapshot, set }) =>
      async (portfolio?: PrivatePortfolioNode, unsavedModifiedPortfolio?: PrivatePortfolioNode) => {
        const studioSubject = { privatePortfolioId: portfolio?.id };

        // portfolio that is passed into this function is coming from history.state and can be stale
        const portfolioFromServer = (await snapshot.getPromise(originalAnalysisSubjectQuery(studioSubject)))
          ?.privatePortfolio;

        if (isNil(portfolioFromServer)) {
          return;
        }

        set(openPrivateAllocatorPortfolio, portfolioFromServer);
        set(
          allocatorAnalysisSubject(studioSubject),
          new AnalysisSubject(unsavedModifiedPortfolio ?? portfolioFromServer, 'private-portfolio'),
        );
      },
    [],
  );

  const [analysisView, setAnalysisView] = useState<AnalysisView | undefined>();
  const [baselineView, setBaselineView] = useState<AnalysisView | undefined>();
  const [firstOpeningOfTheView, setFirstOpeningOfTheView] = useState(false);
  const [isGlobalAnalysisRangeLoading, setIsGlobalAnalysisRangeLoading] = useState(false);
  const [editMode, setEditMode] = useState(EditMode.DEFAULT);
  const setCurrentAnalysisView = useSetRecoilState(currentAnalysisView);

  const [hasUnsavedRecoilChanges, setHasUnsavedRecoilChanges] = useUnsavedChanges();

  const [afterUnsavedChangesAction, setAfterUnsavedChangesAction] = useState<AfterUnsavedChangeAction>();

  const canCreateTemplate = useMemo(() => hasPermission('STUDIO_CREATE_TEMPLATE'), [hasPermission]);

  // Memoized because compareViewsHasDiff is expensive
  const hasUnsavedChange = useMemo(
    () => !inPrintMode && (hasUnsavedRecoilChanges || compareViewsHasDiff(analysisView, baselineView)),
    [analysisView, baselineView, inPrintMode, hasUnsavedRecoilChanges],
  );
  const [isCheckingDuplicateReportName, setIsCheckingDuplicateReportName] = useState(false);
  const [isDuplicateReportName, setIsDuplicateReportName] = useState(false);

  const setCustomizedViews = useSetRecoilState(customizedViews);

  const resetState = useSendSignal({ type: 'StudioReset' });

  useSyncAllocatorConfigToView();
  useSyncPrivateAllocatorConfigToView();

  const fetchNewView = useCallback(
    async (viewId?: string, localAnalysisView?: AnalysisView) => {
      // Prevent fetch new view while there is another one in progress
      if (isFetchingNewViewRef.current) {
        return;
      }

      isFetchingNewViewRef.current = true;
      resetState();
      const editTemplate = parseValueFromLocation(history.location, 'editTemplate') === 'true';
      const isReport = history.location.pathname.startsWith(Routes.REPORT_LAB_PATH);

      setIsGlobalAnalysisRangeLoading(true);
      // Loading to show empty state
      setAnalysisView(
        getBlankStudio(
          editTemplate
            ? getTemplateType(isReport ? 'REPORT_TEMPLATE' : localAnalysisView?.analysisViewType)
            : getNonTemplateType(isReport ? 'REPORT' : localAnalysisView?.analysisViewType),
          addDisclosurePage,
          [{ portfolioId: demoPortfolio?.id }],
        ),
      );

      setPortfolioInPrivateAllocator(undefined);
      setIsDuplicateReportName(false);
      setIsCheckingDuplicateReportName(false);
      loadStudioPrintSettings();

      if (!canCreateTemplate && editTemplate) {
        setBaselineView(undefined);
        setAnalysisView(undefined);
        isFetchingNewViewRef.current = false;
        setIsGlobalAnalysisRangeLoading(false);
        return;
      }
      editTemplate ? setEditMode(EditMode.TEMPLATE) : setEditMode(EditMode.DEFAULT);

      let view: AnalysisView | undefined = localAnalysisView;

      try {
        if (viewId) {
          const { content: savedView } = await getSpecificAnalysisView(viewId);
          view = savedView;
          setBaselineView(view);
          trackViewOpened(view);
        } else {
          setBaselineView(undefined);
        }

        const uniqueSubjectIds = uniq(view?.subjects.map((s) => s.id) ?? []);

        const isTemplate = isStudioTemplate(view?.analysisViewType);
        if (view) {
          if (isTemplate) {
            if (editTemplate) {
              setEditMode(EditMode.TEMPLATE);
            } else {
              view.id = undefined;
              view.name = undefined;
              view.analysisViewType = getNonTemplateType(view.analysisViewType);
              view.published = false;
              // When open a report from template, default to current context
              view.ownerContextId = currentContext ?? view.ownerContextId;
              setBaselineView(undefined);
              updateUrlParam(history, 'REPLACE', 'savedId', undefined);
              // Auto open setup modal when it's a template but not in edit mode
              setFirstOpeningOfTheView(true);
            }
          }
        }

        !editTemplate && updateUrlParam(history, 'REPLACE', 'editTemplate', undefined);

        setAnalysisView(view);
        setCurrentAnalysisView(view);
        Promise.all(
          uniqueSubjectIds.map((subjectId) =>
            viewEntity(
              subjectId.toString(),
              viewId
                ? {
                    viewId,
                    viewType: view?.analysisViewType,
                  }
                : undefined,
            ),
          ),
        );
      } catch (e) {
        Notifications.notify(`Unable to load ${isReport ? 'report' : 'studio view'}`, NotificationType.ERROR);
        logExceptionIntoSentry(e);
        history.push(Routes.HOME_PATH);
      } finally {
        isFetchingNewViewRef.current = false;
        setIsGlobalAnalysisRangeLoading(false);
        setHasUnsavedRecoilChanges(false);
      }
    },
    [
      resetState,
      history,
      addDisclosurePage,
      demoPortfolio?.id,
      setPortfolioInPrivateAllocator,
      loadStudioPrintSettings,
      canCreateTemplate,
      setCurrentAnalysisView,
      setHasUnsavedRecoilChanges,
      currentContext,
    ],
  );

  const checkHasUnsavedState = useCallback(
    (proceedCallback: () => void) => {
      if (isFetchingNewViewRef.current) {
        return;
      }

      if (hasUnsavedChange && analysisView) {
        // Un applied change would prompt warning modal
        setAfterUnsavedChangesAction({
          proceedCallback,
          cancelCallback: undefined,
          navigateToNew: true,
        });
      } else {
        proceedCallback();
      }
    },
    [analysisView, hasUnsavedChange],
  );

  const handleHistoryStateUpdate = useCallback(
    (id: string | undefined, newDocument: AnalysisView) => {
      checkHasUnsavedState(() => {
        fetchNewView(id, newDocument);
      });
    },
    [checkHasUnsavedState, fetchNewView],
  );

  useEffect(() => {
    if (!location.state) {
      return;
    }

    const newDocument = location.state.newDocument;
    const newDocumentId = location.state.id || locationSavedId;
    if (newDocument) {
      handleHistoryStateUpdate(newDocumentId, newDocument);
    }
    const openPrivatePortfolioAllocator = location.state.openAllocatorForPrivatePortfolio;
    const modifiedUnsavedPortfolio = location.state.modifiedUnsavedPortfolio;
    if (!isNil(openPrivatePortfolioAllocator)) {
      setPortfolioInPrivateAllocator(openPrivatePortfolioAllocator, modifiedUnsavedPortfolio);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    const newDocument = location.state?.newDocument;
    if (newDocument) {
      return;
    }
    const viewIdFromLocation = getViewIdFromLocation(history.location);
    if (viewIdFromLocation && analysisView?.id !== viewIdFromLocation) {
      checkHasUnsavedState(() => {
        fetchNewView(viewIdFromLocation);
      });
    } else if (!analysisView) {
      history.push(Routes.HOME_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    setCustomizedViews(analysisView?.customizedViews ?? []);
  }, [setCustomizedViews, analysisView]);

  const studioSetupValue = useMemo(
    () => ({
      analysisView,
      baselineView,
      isFetchingNewViewRef,
      setAnalysisView,
      setBaselineView,
      draggingBlock,
      setDraggingBlock,
      isGlobalAnalysisRangeLoading,
      setIsGlobalAnalysisRangeLoading,
      firstOpeningOfTheView,
      setFirstOpeningOfTheView,
      hasUnsavedChange,
      afterUnsavedChangesAction,
      setAfterUnsavedChangesAction,
      isDuplicateReportName,
      setIsDuplicateReportName,
      isCheckingDuplicateReportName,
      setIsCheckingDuplicateReportName,
      editMode,
      setEditMode,
    }),
    [
      afterUnsavedChangesAction,
      analysisView,
      baselineView,
      draggingBlock,
      editMode,
      firstOpeningOfTheView,
      hasUnsavedChange,
      isCheckingDuplicateReportName,
      isDuplicateReportName,
      isGlobalAnalysisRangeLoading,
    ],
  );
  return studioSetupValue;
};

export default useStudioSetup;
