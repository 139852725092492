/*
 * Venn Platform API 1.0.0 Operations for "library"
 * Automatically generated file - do not modify!
 */

import type {
  AggregationBucket,
  AggregationQuery,
  AnalysisViewEntity,
  LibraryIndexRequest,
  LibrarySearchResult,
  OrderEnum,
  SavedSearchSortByEnum,
  SearchQuery,
  StoredSearch,
  StoredSearchRequest,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Bulk index the passed in portfolios and funds */
export async function libraryIndex(
  prioritize?: boolean,
  queue?: boolean,
  body?: Partial<LibraryIndexRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/library/index${buildQuery({ prioritize, queue })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Clear and recreate index */
export async function libraryReset(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/library/reset';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Create saved search */
export async function createSavedSearch(
  body?: Partial<StoredSearchRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<StoredSearch>> {
  const endpoint = '/api/library/search/saved';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: StoredSearch = (await response.json()) as StoredSearch;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all morningstar categories */
export async function getMorningstarCategories(signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = '/api/library/morningstar-category';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets a set of data sources viewable by the permission user */
export async function getViewableDataSources(signal?: AbortSignal): Promise<OperationResult<string[]>> {
  const endpoint = '/api/library/data-sources';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Perform a search aggregation using a different method */
export async function globalAggregationV2(
  body?: Partial<AggregationQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<AggregationBucket[]>> {
  const endpoint = '/api/library/aggregationv2';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AggregationBucket[] = (await response.json()) as AggregationBucket[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Perform a search percentile aggregation */
export async function percentileAggregation(
  body?: Partial<AggregationQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: number }>> {
  const endpoint = '/api/library/percentile';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: number } = (await response.json()) as { [key: string]: number };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove funds from the index */
export async function libraryDeindex(
  body?: Partial<LibraryIndexRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/library/deindex';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove specific saved search */
export async function deleteSavedSearch(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/library/search/saved/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve all saved searches */
export async function getSavedSearches(
  order?: OrderEnum,
  sort?: SavedSearchSortByEnum,
  signal?: AbortSignal,
): Promise<OperationResult<StoredSearch[]>> {
  const endpoint = `/api/library/search/saved${buildQuery({ order, sort })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: StoredSearch[] = (await response.json()) as StoredSearch[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve specific saved search */
export async function getSavedSearch(id: string, signal?: AbortSignal): Promise<OperationResult<SearchQuery>> {
  const endpoint = `/api/library/search/saved/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: SearchQuery = (await response.json()) as SearchQuery;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Searches funds and portfolios by attributes */
export async function globalSearch(
  body?: Partial<SearchQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<LibrarySearchResult>> {
  const endpoint = '/api/library/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LibrarySearchResult = (await response.json()) as LibrarySearchResult;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update saved search */
export async function updateSavedSearch(
  id: string,
  body?: Partial<StoredSearchRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/library/search/saved/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** View a particular entity on the platform */
export async function viewEntity(
  id: string,
  body?: Partial<AnalysisViewEntity>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/library/view/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
