import type { AnalysisRequest } from '../../types';
import { getFactorChartTimeStamps, parseFactorTrendData } from '../../../charts/analysis-charts/factor-charts/utils';
import type { HeatMapTypes } from '../../../heat-map';
import { get, isNil, sortBy } from 'lodash';
import type { factorAPIResponseKey } from '../../../charts/analysis-charts/factor-charts/types';
import type { Analysis, CustomBenchmarkTypeEnum, FactorLensWithReturns } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import type { AnalysisSubject, ExcelCell, CustomizableMetric } from 'venn-utils';
import {
  formatDate,
  formatExportValue,
  getComparisonLabelForBlockLegend,
  getSecondarySubjectFromRequests,
} from 'venn-utils';
import { TOTAL_NAME } from '../../../heat-map/utils';

export type FactorTrendData = {
  series: HeatMapTypes.Root[];
  exportableSeries: HeatMapTypes.Root[];
  timestamps: number[];
  analyzedFactors: number;
};

type FactorTrendDataType = 'risk' | 'return' | 'exposure';

export const mapAnalysisTypeToFactorTrendDataType: Partial<Record<string, FactorTrendDataType>> = {
  factorExposuresTrend: 'exposure',
  factorContributionToRiskTrend: 'risk',
  factorContributionToReturnTrend: 'return',
};

export interface GeneralDataInput {
  metric?: CustomizableMetric;
  requests?: AnalysisRequest[];
  analyses?: (Analysis | undefined)[][];
  activeFactorLens?: FactorLensWithReturns;
  theme: Theme;
  selectedFactors?: string[];
  analysisSubject: AnalysisSubject;
}

export const generalDataToFactorTrendData = ({
  requests,
  metric,
  analyses = [],
  activeFactorLens,
  theme,
  selectedFactors = [],
  analysisSubject,
}: GeneralDataInput): FactorTrendData => {
  if (!metric || !requests?.length) {
    return { series: [], exportableSeries: [], timestamps: [], analyzedFactors: 0 };
  }

  const analysisResultKey = metric.analysisResultKey;

  return getSeriesAndTimestamps({
    requests,
    metric,
    analyses,
    activeFactorLens,
    theme,
    selectedFactors,
    residualKey: getResidualKey(analysisResultKey!),
    hasTotalRow: hasTotalRow(analysisResultKey!),
    hasRiskFreeRate: hasRiskFreeRateRow(analysisResultKey!, requests[0].relative),
    benchmarkType: requests[0].benchmarkType,
    analysisSubject,
  });
};

type GetSeriesAndTimestamps = Pick<
  GeneralDataInput,
  'requests' | 'analyses' | 'activeFactorLens' | 'theme' | 'selectedFactors' | 'analysisSubject'
> & {
  metric: CustomizableMetric;
  residualKey?: string;
  hasTotalRow: boolean;
  hasRiskFreeRate: boolean;
  benchmarkType: CustomBenchmarkTypeEnum;
};

export const getSeriesAndTimestamps = ({
  requests,
  metric,
  analyses = [],
  activeFactorLens,
  theme,
  selectedFactors,
  residualKey,
  hasTotalRow,
  hasRiskFreeRate,
  benchmarkType,
  analysisSubject,
}: GetSeriesAndTimestamps): FactorTrendData => {
  const analysis = analyses[0]?.find((analysis) => analysis?.analysisType === metric?.analysisType);
  // filter common benchmark case, use the one in the same request instead
  if (!analysis || !requests?.length || requests[0]?.isBenchmark) {
    return {
      series: [],
      exportableSeries: [],
      timestamps: [],
      analyzedFactors: 0,
    };
  }

  const analysisResult = get(analysis, [metric.analysisResultKey!, 0]);
  const newTimestamps = analysisResult ? getFactorChartTimeStamps(analysisResult) : [];
  const multiplier = metric.dataType === 'PERCENTAGE' ? 100 : 1;

  const secondaryPortfolio = getSecondarySubjectFromRequests(requests[0].subject, requests?.[1]?.subject)?.portfolio;

  const secondaryAnalysis = secondaryPortfolio
    ? analyses?.[1]?.find((analysis) => analysis?.analysisType === metric?.analysisType)
    : undefined;
  const secondaryLabel = secondaryAnalysis ? getComparisonLabelForBlockLegend(requests[1].subject) : undefined;

  const parsedAnalysis = getParsedAnalysisForFactorTrendParser(analysis, secondaryAnalysis, metric, benchmarkType);

  const exportableAnalysis = analysis.exportable?.[0] && (!secondaryAnalysis || secondaryAnalysis.exportable?.[0]);

  const { series: newFactorSeries, analyzedFactors } = parseFactorTrendData(
    metric.analysisResultKey as factorAPIResponseKey,
    parsedAnalysis,
    activeFactorLens,
    mapAnalysisTypeToFactorTrendDataType[metric.analysisResultKey!],
    analysisSubject,
    multiplier,
    theme.Colors,
    requests[0].subject.feesMapping ?? {},
    residualKey,
    hasTotalRow,
    hasRiskFreeRate,
    !!requests[0].benchmark,
    requests[0].benchmark?.name,
    secondaryLabel,
  );

  const filteredNewFactorSeries = selectedFactors
    ? sortBy(
        newFactorSeries.filter(({ factorId }) => !isNil(factorId) && selectedFactors.includes(factorId.toString())),
        ({ factorId }) => selectedFactors.indexOf(factorId!.toString()),
      )
    : newFactorSeries;

  return {
    series: filteredNewFactorSeries,
    exportableSeries: exportableAnalysis ? filteredNewFactorSeries : [],
    timestamps: newTimestamps,
    analyzedFactors,
  };
};

/**
 * This method prepares the `analysis` param for `parseFactorTrendData()` utility function. That function was originally
 * written for Analysis Page, and expects that `analysis[metric.analysisResultKey]` contains an array of analysis results
 * for [mainData, benchmarkData, secondaryData], with the caveat that `benchmarkData` and/or `secondaryData` can be missing
 * (not `undefined` or `null`, just missing from that table completely).
 */
const getParsedAnalysisForFactorTrendParser = (
  analysis: Analysis,
  secondaryAnalysis: Analysis | undefined,
  metric: CustomizableMetric,
  benchmarkType: CustomBenchmarkTypeEnum,
): Analysis => {
  const analysisResult = get(analysis, [metric.analysisResultKey!, 0]);
  const secondaryAnalysisResult = secondaryAnalysis
    ? get(secondaryAnalysis, [metric.analysisResultKey!, 0])
    : undefined;

  const analysisWithCommonBenchmark =
    benchmarkType === 'COMMON'
      ? analysis
      : // make sure individual benchmark won't show up to make the behavior consistent with other blocks
        { ...analysis, [metric.analysisResultKey!]: [analysisResult] };

  if (!secondaryAnalysisResult) {
    return analysisWithCommonBenchmark;
  }

  return {
    ...analysisWithCommonBenchmark,
    // `parseFactorTrendData()` expects [mainData, benchmarkData, secondaryData] if a benchmark is used (relative or not),
    // or [mainData, secondaryData] otherwise
    [metric.analysisResultKey!]:
      benchmarkType !== 'NONE'
        ? [
            analysisWithCommonBenchmark[metric.analysisResultKey!]?.[0],
            analysisWithCommonBenchmark[metric.analysisResultKey!]?.[1],
            secondaryAnalysisResult,
          ]
        : [analysisWithCommonBenchmark[metric.analysisResultKey!]?.[0], secondaryAnalysisResult],
  };
};

export const getResidualKey = (analysisType: string): string | undefined => {
  switch (analysisType) {
    case 'factorContributionToRiskTrend':
      return 'residualRisk';
    case 'factorContributionToReturnTrend':
      return 'periodResidualReturn';
    default:
      return undefined;
  }
};

export const hasTotalRow = (analysisType: string): boolean => analysisType === 'factorContributionToReturnTrend';

export const hasRiskFreeRateRow = (analysisType: string, relativeToBenchmark: boolean) =>
  analysisType === 'factorContributionToReturnTrend' && !relativeToBenchmark;

export const convertHeatMapDataToExcel = (
  data: HeatMapTypes.Root[],
  timestamps: number[],
  title: string,
  hasBaseline: boolean,
  isPercentage: boolean,
) => {
  if (!data || data.length === 0) {
    return undefined;
  }
  const header: ExcelCell[] = [
    { value: title },
    { value: 'Instrument' },
    ...timestamps.map((stamp) => ({ value: formatDate(stamp) })),
  ];

  const excelSheet = [header];
  const hasBaselineActive = hasBaseline && data[0].series.length > 1;
  const hasBenchmark = data[0].series.find(({ portfolioType }) => portfolioType === 'Benchmark') !== undefined;
  const hasCategory = data[0].series.find(({ portfolioType }) => portfolioType === 'Category') !== undefined;

  loadFactorResults(excelSheet, data, 0, isPercentage);
  let dataIdx = 1;
  if (hasBaselineActive) {
    loadFactorResults(excelSheet, data, dataIdx++, isPercentage, true);
  }
  if (hasBenchmark) {
    loadFactorResults(excelSheet, data, dataIdx++, isPercentage);
  }
  if (hasCategory) {
    loadFactorResults(excelSheet, data, dataIdx++, isPercentage, true);
  }
  excelSheet[0].forEach((cell) => (cell.bold = true));
  excelSheet.forEach((row) => (row[0].bold = true));

  return excelSheet;
};

const loadFactorResults = (
  excelSheet: ExcelCell[][],
  data: HeatMapTypes.Root[],
  idx: number,
  isPercentage: boolean,
  addPrefix?: boolean,
) => {
  data.forEach((factor) => {
    if (!factor.series[idx]) {
      return;
    }

    const prefix = addPrefix ? `${factor?.series?.[idx]?.portfolioType}: ` : '';

    const factorValueRow = [
      { value: factor.name },
      { value: `${prefix}${factor.series[idx].name}` },
      ...factor.series[idx].data.map((datapoint) => formatExportValue(datapoint.value, isPercentage)),
    ];
    excelSheet.push(factorValueRow);

    if (factor.name !== TOTAL_NAME) {
      const factorTstatRow = [
        { value: `${factor.name} T-stat` },
        { value: `${prefix}${factor.series[idx].name}` },
        ...factor.series[idx].data.map((datapoint) => formatExportValue(datapoint.tValue, false)),
      ];
      excelSheet.push(factorTstatRow);
    }
  });
};
