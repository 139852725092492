import React, { useMemo, useContext, useRef } from 'react';
import { ThemeContext } from 'styled-components';
import type { GeneralChartProps } from './types';
import { DownloadableContentBlock } from '../../../content-block';
import HighchartsReact from 'highcharts-react-official';
import Highstock from 'highcharts/highstock';
import { createVennScatterChartConfig } from '../../../charts/analysis-charts/chart-config-logic';
import useExportUpdate from '../../logic/useExportUpdate';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import { isNil } from 'lodash';
import StyledEmptyState from '../StyledEmptyState';
import { getScatterLabels, useScatterChartData } from './scatter-chart-logic';
import { useBlockId } from '../../contexts/BlockIdContext';
import { useRecoilValue } from 'recoil';
import { blockFonts } from 'venn-state';
import { EmptyHeaderSpace } from '../../../content-block/DownloadableContentBlock';
import { useChartReflow } from '../../../hooks/useChartReflow';

const ScatterChart = ({
  metrics,
  selectedBlock,
  analyses,
  responseParser,
  requests,
  downloadableContentRef,
  height,
  subjectColors,
  selectedRefId,
  exportMetaData,
  inPrintMode,
}: GeneralChartProps) => {
  const blockId = useBlockId();
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(blockId));
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  useChartReflow(blockId, chartRef);
  const axisFont = useRecoilValue(blockFonts.blockChartAxis(blockId));
  const theme = useContext(ThemeContext);

  const { xUnit, yUnit, xLabel, yLabel } = getScatterLabels(metrics, selectedBlock?.relativeToBenchmark);

  const [series, excelDataFn] = useScatterChartData(
    metrics,
    responseParser,
    selectedBlock,
    analyses,
    requests,
    subjectColors,
  );

  useExportUpdate({ exportMetaData, excelDataFn, selectedRefId });

  const config = useMemo(
    () =>
      !series?.length
        ? undefined
        : createVennScatterChartConfig(
            series,
            xUnit,
            yUnit,
            xLabel ?? '',
            yLabel ?? '',
            theme.Typography,
            theme.Colors,
            theme.Schemes,
            legendFont.fontSizePt,
            axisFont.fontSizePt,
            height,
          ),
    [series, xUnit, yUnit, xLabel, yLabel, theme, height, legendFont.fontSizePt, axisFont.fontSizePt],
  );

  const chart = isNil(config) ? (
    <StyledEmptyState selectedRefId={selectedRefId} header="Unable to run analysis" message="Chart is empty" />
  ) : (
    <HighchartsReact
      highcharts={Highstock}
      options={config}
      ref={chartRef}
      containerProps={{ style: { height: '100%' } }}
    />
  );

  if (inPrintMode) {
    return chart;
  }

  const defaultHeader = xLabel && yLabel ? `${xLabel} by ${yLabel}` : 'Builder Chart';

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: selectedBlock?.header ? selectedBlock?.header : defaultHeader,
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'BUILDER',
          relativeToBenchmark: !!selectedBlock?.relativeToBenchmark,
          userUploaded: false,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: series ? series.length === 0 : true,
        target: downloadableContentRef,
      }}
    >
      {chart}
    </DownloadableContentBlock>
  );
};

export default ScatterChart;
