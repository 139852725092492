import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

// we only show disabled styling if the all the fields are uneditable, so we can't use the field-level `isEditable` prop
const FieldWithLabel = styled.div<{ isPredicted?: boolean; showDisabledStyling?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ showDisabledStyling }) =>
    showDisabledStyling
      ? css`
          padding: 15px 20px;

          &:not(:last-child) {
            border-bottom: 1px solid ${GetColor.Grey};
          }
        `
      : css`
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        `}

  .qa-dropmenu-trigger {
    line-height: 14px;
    ${({ isPredicted }) =>
      isPredicted &&
      css`
        border-color: ${GetColor.HighlightDark};
        label {
          color: ${GetColor.HighlightDark};
        }
      `}
  }
`;
export default FieldWithLabel;
