import { noop } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export interface TabOption<T extends string = string> {
  /** Tab unique id */
  tabId: T;
  title: string;
  className?: string;

  /** Custom data passed into Tab */
  data?: { [key: string]: unknown };
}

export type TypeClickHandler<T extends string = string> = (tab: { tabId: T; data: unknown }) => void;

export interface TabProps<T extends string = string> extends TabOption<T> {
  className?: string;

  isSelected: boolean;

  /** whether the colored border is on the bottom or the top of the container. Defaults to top. */
  borderLocation?: 'top' | 'bottom';

  onClick: TypeClickHandler<T>;

  tabRenderer?: React.ComponentType<TabWrapperProps>;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default <T extends string = string>({
  className,
  isSelected,
  title,
  tabId,
  data,
  tabRenderer,
  borderLocation = 'top',
  onClick = noop,
}: TabProps<T>) => {
  const TabRenderer = tabRenderer ?? TabContent;
  return (
    <button className={className} onClick={() => onClick({ tabId, data })} type="button">
      <TabRenderer isSelected={isSelected} borderLocation={borderLocation}>
        <span>{title}</span>
      </TabRenderer>
    </button>
  );
};

export interface TabWrapperProps {
  isSelected: boolean;
  borderLocation?: 'top' | 'bottom';
}

const TabContent = styled.div<TabWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 220px;
  padding-top: 15px;
  padding-bottom: 18px;

  border-right: 1px solid ${GetColor.Grey};

  color: ${(props) => (props.isSelected ? GetColor.Black : GetColor.DarkGrey)};
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;

  cursor: pointer;
  ${(props) =>
    props.isSelected && props.borderLocation === 'bottom'
      ? css`
          border-bottom: 6px solid ${GetColor.Primary.Dark};
          border-top: 1px solid ${GetColor.Grey};
          color: ${GetColor.Primary.Dark};
          background-color: ${GetColor.White};
        `
      : props.isSelected && props.borderLocation === 'top'
        ? css`
            border-top: 6px solid ${GetColor.Black};
            border-bottom: 1px solid ${GetColor.Grey};
            color: ${GetColor.Black};
            background-color: ${GetColor.White};
          `
        : css`
            border-bottom: ${props.borderLocation === 'bottom'
              ? `6px solid ${GetColor.MidGrey2}`
              : `1px solid ${GetColor.Grey}`};
            border-top: ${props.borderLocation === 'bottom'
              ? `1px solid ${GetColor.Grey}`
              : `6px solid ${GetColor.Black}`};
            color: ${GetColor.MidGrey2};
            background-color: ${GetColor.PaleGrey};

            &:hover {
              color: ${GetColor.Black};
            }

            @media print {
              display: none;
            }
          `};
  @media (max-width: 1279px) {
    width: 200px;
  }
`;
