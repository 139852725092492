import type { IMarcomSiteLinks } from 'venn-ui-kit';

interface Category {
  title: string;
  subtitle: string;
  primary?: boolean;
  groups: Group[];
}

interface Group {
  title?: string;
  factors: Factor[];
}

interface Factor {
  title: string;
  description: string;
  hyperlink?: string;
}

export const factors = (links: IMarcomSiteLinks, newES: boolean) =>
  [
    {
      primary: true,
      title: 'The Four Pillars',
      subtitle: 'The Two Sigma Factor Lens is intended to be:',
      groups: [
        {
          factors: [
            {
              title: 'Holistic:',
              description:
                'By capturing the large majority of cross-sectional and time-series risk for typical institutional portfolios.',
              hyperlink: links.FACTORS_HOLISTIC_URL,
            },
            {
              title: 'Parsimonious:',
              description: 'By using as few factors as possible.',
              hyperlink: links.FACTORS_PARSIMONIOUS_URL,
            },
            {
              title: 'Orthogonal:',
              description: 'With each risk factor capturing a statistically uncorrelated risk across assets.',
              hyperlink: links.FACTORS_ORTHOGONAL_URL,
            },
            {
              title: 'Actionable:',
              description:
                'Such that desired changes to factor exposure can be readily translated into asset allocation changes.',
              hyperlink: links.FACTORS_ACTIONABLE_URL,
            },
          ],
        },
      ],
    },
    {
      title: 'Macro Factors',
      subtitle:
        'Macro factors are commonly found risk exposures in an institutional investor’s portfolio due to their high liquidity and capacity.',
      groups: [
        {
          title: 'Core Macro',
          factors: [
            {
              title: 'Equity',
              description: 'Exposure to the long-term economic growth and profitability of companies.',
            },
            {
              title: 'Interest Rates',
              description: 'Exposure to the time value of money (interest rates and inflation risk).',
            },
            {
              title: 'Credit',
              description:
                'Exposure to corporate default and failure-to-pay risks specific to developed market corporate bonds.',
            },
            { title: 'Commodities', description: 'Exposure to changes in prices for hard assets.' },
          ],
        },
        {
          title: 'Secondary Macro',
          factors: [
            {
              title: 'Emerging Markets',
              description:
                'Exposure to the sovereign and economic risks of emerging markets relative to developed markets.',
            },
            {
              title: 'Foreign Currency',
              description: 'Exposure to moves in foreign currency values versus the portfolio’s local currency.',
            },
            {
              title: 'Local Inflation',
              description:
                'Exposure to inflation-linked rates relative to fixed nominal rates within the local currency area (only available in USD or GBP).',
            },
            {
              title: 'Local Equity',
              description: 'Exposure to home bias (the tendency to invest in domestic over foreign equity).',
            },
          ],
        },
      ],
    },
    {
      title: 'Style Factors',
      subtitle:
        'Style factors correspond to sizeable common risk drivers within asset classes, such as individual stocks or bonds.',
      groups: [
        {
          title: 'Macro Styles',
          factors: [
            {
              title: 'Equity Short Volatility',
              description: 'Negative exposure to the moves in equity market volatility.',
            },
            {
              title: 'Fixed Income Carry',
              description:
                'Exposure to high-yielding 10-year bond futures funded by low-yielding 10-year bond futures.',
            },
            {
              title: 'Foreign Exchange Carry',
              description: 'Exposure to high-yielding G10 currencies funded by low-yielding G10 currencies.',
            },
            {
              title: 'Trend Following',
              description: 'Long-short exposure to multi-asset-class futures based on 6- to 12-month trailing returns.',
            },
          ],
        },
        {
          title: 'Equity Styles',
          factors: [
            {
              title: 'Low Risk',
              description: newES
                ? 'Long exposure to stocks with low market betas and residual volatility and short exposure to higher-risk stocks.'
                : 'Exposure to stocks with low market betas and residual volatility funded by higher-risk stocks.',
            },
            {
              title: 'Momentum',
              description: newES
                ? 'Long exposure to stocks that have outperformed recently and short exposure to recently underperforming stocks.'
                : 'Exposure to stocks that have outperformed recently funded by underperforming stocks.',
            },
            {
              title: 'Quality',
              description: newES
                ? 'Long exposure to stocks with low leverage and high profitability and short exposure to lower-quality stocks.'
                : 'Exposure to stocks with low leverage, stable and high-quality earnings, and high profitability and investment quality, funded by lower-quality stocks.',
            },
            {
              title: 'Value',
              description: newES
                ? 'Long exposure to stocks with low prices relative to accounting fundamentals and short exposure to higher-priced stocks relative to fundamentals.'
                : 'Exposure to stocks with low prices relative to accounting fundamentals and past prices, funded by higher-priced stocks.',
            },
            {
              title: 'Small Cap',
              description: newES
                ? 'Long exposure to stocks with smaller market caps and short exposure to larger-cap stocks.'
                : 'Exposure to stocks with smaller market caps funded by larger-cap stocks.',
            },
            {
              title: 'Crowding',
              description: newES
                ? 'Short exposure to U.S. stocks that are widely shorted by the investment community and long exposure to those stocks that are not as widely held short.'
                : 'Short exposure to stocks with a wide investment community holding of short positions.',
            },
          ],
        },
      ],
    },
  ] as Category[];
