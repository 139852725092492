import React, { useCallback, useContext, useMemo } from 'react';
import { ReorderableList, StudioContext, UserContext } from 'venn-components';
import { FlexHeader, BorderBottom } from '../../shared';
import styled from 'styled-components';
import { updateRowBasedOnIndex } from '../../../logic/studioUtils';
import BlockItem from './components/BlockItem';

const OutlinePanel = () => {
  const { hasPermission } = useContext(UserContext);
  const { analysisView, setAnalysisView } = useContext(StudioContext);

  const views = useMemo(
    () =>
      analysisView?.customizedViews?.map((s, index) => ({
        key: String(s.id ?? s.refId),
        label: s.name,
        index,
        value: s,
      })) ?? [],
    [analysisView],
  );

  const onReorderBlocks = useCallback(
    (newItems: typeof views) => {
      if (analysisView?.customizedViews) {
        let studio = analysisView;
        const reordered = newItems.map((item) => item.value);
        studio = {
          ...studio,
          customizedViews: updateRowBasedOnIndex(reordered),
        };
        setAnalysisView(studio);
      }
    },
    [setAnalysisView, analysisView],
  );

  return (
    <>
      <BorderBottom>
        <FlexHeader>Blocks</FlexHeader>
      </BorderBottom>
      <BlocksWrapper>
        <ReorderableList
          hideDelete
          reorderableItems={views}
          onReorderItems={onReorderBlocks}
          itemRenderer={(viewItem) => <BlockItem key={viewItem.value.refId} block={viewItem.value} />}
          disabled={!hasPermission('STUDIO_REORDER_BLOCKS')}
          id="blockList"
        />
      </BlocksWrapper>
    </>
  );
};

export default OutlinePanel;

const BlocksWrapper = styled.div`
  margin: 10px 30px;

  ul > div {
    display: flex;
    align-items: center;
    > i {
      top: unset;
      left: 1px;
    }
  }
`;
