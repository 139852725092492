/*
 * Venn Platform API 1.0.0 Operations for "timeseries"
 * Automatically generated file - do not modify!
 */

import type { FundSeriesData } from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Get the upload (price or returns) series for fund */
export async function getUploadedSeriesForFund(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<FundSeriesData>> {
  const endpoint = `/api/timeseries/fund/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundSeriesData = (await response.json()) as FundSeriesData;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the upload (price or returns) series for funds */
export async function getUploadedSeriesForFunds(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<FundSeriesData[]>> {
  const endpoint = '/api/timeseries/fund/batch';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: FundSeriesData[] = (await response.json()) as FundSeriesData[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update the series for fund */
export async function updateSeriesForFund(
  id: string,
  overwrite?: boolean,
  body?: number[][],
  signal?: AbortSignal,
): Promise<OperationResult<number[][]>> {
  const endpoint = `/api/timeseries/fund/${encodeURIComponent(id)}${buildQuery({ overwrite })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: number[][] = (await response.json()) as number[][];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update the series for funds */
export async function updateSeriesForFunds(
  overwrite?: boolean,
  body?: { [key: string]: number[][] },
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: number[][] }>> {
  const endpoint = `/api/timeseries/fund${buildQuery({ overwrite })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: number[][] } = (await response.json()) as { [key: string]: number[][] };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
