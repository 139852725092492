import React, { useContext } from 'react';
import styled from 'styled-components';
import { StudioContext, StudioPrintSettingsContext, UserContext } from 'venn-components';
import { Img, ImgWrapper } from '../shared';
import {
  DEFAULT_MARGIN,
  studioHideHeaderCompanyName,
  studioHideHeaderLogo,
  studioHideHeaderViewName,
  studioHidePageHeader,
} from 'venn-state';
import { useRecoilValue } from 'recoil';

const PageHeader = () => {
  const { analysisView } = useContext(StudioContext);
  const { profileSettings } = useContext(UserContext);

  const { defaultLogoId, organizationPrintSettings } = useContext(StudioPrintSettingsContext);

  const hidePageHeader = useRecoilValue(studioHidePageHeader);
  const hideCompanyLogo = useRecoilValue(studioHideHeaderLogo);
  const hideCompanyName = useRecoilValue(studioHideHeaderCompanyName);
  const hideViewName = useRecoilValue(studioHideHeaderViewName);

  if (hidePageHeader) {
    return <EmptyHeader />;
  }

  return (
    <PageHeaderWrapper>
      {defaultLogoId && !hideCompanyLogo && (
        <ImgWrapper>
          <Img src={`/api/printing/logo/${defaultLogoId}`} />
        </ImgWrapper>
      )}
      <Flex>
        {!hideCompanyName && (
          <CompanyName>
            {organizationPrintSettings?.organizationName ?? profileSettings?.organization?.name}
          </CompanyName>
        )}
        {!hideCompanyName && !hideViewName && <StyledSeparator>{' - '}</StyledSeparator>}
        {!hideViewName && (analysisView?.name ?? 'Venn')}
      </Flex>
    </PageHeaderWrapper>
  );
};

const EmptyHeader = styled.div`
  height: 20px;
`;

const Flex = styled.div`
  display: flex;
  font-size: 1rem;
`;

const StyledSeparator = styled.div`
  margin-right: 5px;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${DEFAULT_MARGIN}px;
  padding-bottom: 5px;
  justify-content: space-between;

  /* TODO: 0.25in is the same as DEFAULT_MARGIN, so this is useless? Dedupe? */
  @media print {
    padding-top: 0.25in;
    padding-left: 0.25in;
    padding-right: 0.25in;
  }
`;

const CompanyName = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export default PageHeader;
