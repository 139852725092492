import { compact } from 'lodash';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  Flexbox,
  GetColor,
  getTextThemeProvider,
  Headline1,
  Icon,
  PRIVATE_ASSET_LAB_FAQ_HREF,
  Subtitle1,
} from 'venn-ui-kit';
import { analyticsService, useHasFF } from 'venn-utils';

const HighlightedFeature = ({ name, newTag }: { name: string; newTag?: boolean }) => {
  return (
    <FeatureContainer isNew={newTag}>
      <Icon type="check" />
      {newTag && <NewFeatureTag>NEW</NewFeatureTag>}
      <span>{name}</span>
    </FeatureContainer>
  );
};

const BetaContainer = styled.div`
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;

const BetaTag = () => {
  return <BetaContainer>BETA</BetaContainer>;
};
export const PrivateAssetLabTabAGSLaunch = () => {
  const { Images } = useContext(ThemeContext);
  const hasEditHyperparametersFF = useHasFF('privates_hyperparameters_editing_ff');

  const includedFeaturesLine1 = compact([
    hasEditHyperparametersFF && { name: 'Cash Flow Pacing Model Settings', newTag: true },
    { name: 'Total Portfolio Asset Growth Simulation', newTag: !hasEditHyperparametersFF },
    { name: 'Cash Flow Pacing' },
    { name: 'Desmoothing' },
  ]);
  const includedFeaturesLine2 = [
    { name: 'Fund Performance' },
    { name: 'Public Market Equivalents' },
    { name: 'Indices' },
  ];

  return (
    <MainContainer>
      <Flexbox direction="column">
        <Flexbox direction="row" alignItems="center" gap={4}>
          <StyledHeadline>Private Asset Lab</StyledHeadline>
          <BetaTag />
        </Flexbox>
        <StyledSubtitle>Get access to specialized data and analytics for private asset portfolios.</StyledSubtitle>
        <Flexbox direction="row">
          {includedFeaturesLine1.map((feature) => (
            <HighlightedFeature {...feature} />
          ))}
        </Flexbox>
        <Flexbox direction="row">
          {includedFeaturesLine2.map((feature) => (
            <HighlightedFeature {...feature} />
          ))}
        </Flexbox>
        <Flexbox direction="row" gap={8}>
          <StyledImg
            key={hasEditHyperparametersFF ? Images.privateAssetCFPModelSettings : Images.privateAssetAGSConeChart}
            src={hasEditHyperparametersFF ? Images.privateAssetCFPModelSettings : Images.privateAssetAGSConeChart}
            alt="private analytics in-app demo"
          />
        </Flexbox>
        <Flexbox direction="row" gap={30}>
          <StyledButton
            onClick={() => {
              window.open(PRIVATE_ASSET_LAB_FAQ_HREF, '_blank');
              analyticsService.ctaClicked({
                destination: 'Private Asset Lab help article',
                filled: true,
                locationOnPage: 'Private Asset lab carousel tab',
                purpose: 'Learn more about Private Asset Lab',
                text: 'Learn more',
                type: 'button',
              });
            }}
            className="qa-learn-more"
            data-testid="qa-learn-more"
            style={{ width: 509, height: 40 }}
          >
            Learn more
          </StyledButton>
          <StyledButton
            onClick={() => {
              window.location.href = `mailto:${getTextThemeProvider().salesEmail}?subject=Private Asset Lab Inquiry`;
              analyticsService.ctaClicked({
                destination: 'Private Asset Lab inquiry email',
                filled: true,
                locationOnPage: 'Private Asset lab carousel tab',
                purpose: 'Inquire about Private Asset lab',
                text: 'Questions? Contact us',
                type: 'button',
              });
            }}
            className="qa-questions-mailto"
            data-testid="qa-questions-mailto"
            style={{ width: 509, height: 40 }}
          >
            Questions? Contact us
          </StyledButton>
        </Flexbox>
      </Flexbox>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  margin-bottom: 36px;
`;

const StyledHeadline = styled(Headline1)`
  font-weight: 500;
  font-style: normal;
  line-height: 60px;
  margin-top: 36px;
`;

const StyledSubtitle = styled(Subtitle1)`
  color: #292929;
  font-size: 19px;
  margin-bottom: 16px;
  font-weight: 400;
`;

const StyledImg = styled.img`
  background-color: white;
  width: 100%;
  height: auto;
  min-width: 800px;
  max-height: 100%;
  padding: 15px 0px;
  margin-bottom: 15px;
  border: solid 1px ${GetColor.Black};
`;

const NewFeatureTag = styled.span`
  padding: 3px 6px;
  border-radius: 4px;
  border: 2px solid ${GetColor.HighlightDark};
  justify-content: center;
`;

const FeatureContainer = styled.div<{ isNew?: boolean }>`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: ${(isNew) => (isNew ? 700 : 500)};
  line-height: normal;
  font-style: normal;
  padding: 6.88px;
  gap: 8px;
  align-items: center;
  color: ${({ isNew }) => (isNew ? GetColor.HighlightDark : GetColor.Black)};
`;

const StyledButton = styled.button`
  display: flex;
  height: 40px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 2px;
  border: 1px solid ${GetColor.PeerGroupCarouselColor};
  background: rgba(0, 129, 138, 0.02);
  color: ${GetColor.Black};
  text-align: center;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-content: center;
  width: 523px;
`;
