import React, { useContext } from 'react';
import type { NodeType } from 'venn-components';
import {
  StudioPrintSettingsContext,
  Markdown,
  STUDIO_DISCLOSURE_PAGE_DEFAULT_DISCLOSURE,
  UserContext,
  StudioShimmerBlock,
} from 'venn-components';
import styled from 'styled-components';
import {
  assertNotNil,
  findInPortfolio,
  hasFeesAndExcludedInvestments,
  Numbers,
  STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE,
  withSuspense,
} from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { allSubjects, requestSubjects } from 'venn-state';
import { compact } from 'lodash';

const DISALLOWED_MARKDOWN: NodeType[] = [
  'root',
  'break',
  'emphasis',
  'strong',
  'thematicBreak',
  'blockquote',
  'delete',
  'link',
  'image',
  'linkReference',
  'imageReference',
  'table',
  'tableHead',
  'tableBody',
  'tableRow',
  'tableCell',
  'list',
  'listItem',
  'definition',
  'heading',
  'inlineCode',
  'code',
  'html',
  'virtualHtml',
];

const useExcludedFeesDisclosure = (organizationDisplayName: string) => {
  const allSubjectsData = useRecoilValue(allSubjects);
  const subjectsWithExcludedFees = useRecoilValue(requestSubjects(allSubjectsData)).filter(
    hasFeesAndExcludedInvestments,
  );
  const excludedNodes = subjectsWithExcludedFees.map((subject) =>
    compact(
      Object.keys(assertNotNil(subject.feesMapping))
        .filter((key) => subject.feesMapping?.[key] === 0 && key !== subject.id)
        .map((node) => findInPortfolio(subject.portfolio!, Number(node))),
    ),
  );

  if (subjectsWithExcludedFees.length === 0) {
    return undefined;
  }

  return `
  ---
  ### **Investments excluded from advisory fees**
  The following portfolios' investments have been excluded from the stated advisory fees. Some investments' returns are already presented net of fees, while other investments are not subject to fees.
  Please contact ${organizationDisplayName} for additional information.
  ${subjectsWithExcludedFees
    .map(
      (subject, idx) => `
  - **${subject.name}** *(${Numbers.safeFormatPercentage(
    subjectsWithExcludedFees[idx].feesMapping?.[subject.id],
  )} fee)* **excludes**:
  ${excludedNodes[idx].map((node) => node.name).join(', ')}
  `,
    )
    .join('')}
  `;
};

const DisclosurePage = () => {
  const { profileSettings } = useContext(UserContext);
  const { organizationPrintSettings } = useContext(StudioPrintSettingsContext);
  const organizationDisplayName = assertNotNil(
    organizationPrintSettings?.organizationName ?? profileSettings?.organization?.name,
  );
  const organizationDefaultDisclosure = STUDIO_DISCLOSURE_PAGE_DEFAULT_DISCLOSURE(organizationDisplayName);
  const excludedFeesDisclosureText = useExcludedFeesDisclosure(organizationDisplayName);
  const disclosure = organizationPrintSettings?.disclosure ?? organizationDefaultDisclosure;
  const disclosureFontSize = organizationPrintSettings?.disclosureFontSize ?? STUDIO_DEFAULT_DISCLOSURE_FONT_SIZE;
  return (
    <Wrapper>
      <Header>Disclosure</Header>
      {disclosure && (
        <StyledMarkdown
          noMargin
          fontSize={disclosureFontSize}
          disallowedTypes={DISALLOWED_MARKDOWN}
          source={disclosure}
        />
      )}
      {excludedFeesDisclosureText && (
        <ExcludedFeesWrapper>
          <StyledMarkdown noMargin fontSize={disclosureFontSize} source={excludedFeesDisclosureText} />
        </ExcludedFeesWrapper>
      )}
      <Breaker />
    </Wrapper>
  );
};

export default withSuspense(<StudioShimmerBlock height={450} />, DisclosurePage);

const ExcludedFeesWrapper = styled.div`
  padding: 1em 0;
  page-break-inside: avoid;
`;

const Breaker = styled.div`
  width: 100%;
  height: 10px;
  margin-top: -10px;
`;

const Wrapper = styled.div`
  padding: 40px 40px 0 40px;
  font-size: 10px;
  overflow: auto;

  p {
    page-break-inside: avoid;
    padding: 1em 0 0 0;
  }
`;

const Header = styled.h3`
  font-weight: bold;
`;

const StyledMarkdown = styled(Markdown)<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize}pt;
`;
