import React, { useContext } from 'react';
import styled from 'styled-components';
import { getSpecificPortfolioV3 } from 'venn-api';
import { Label, Loading, LoadingSize, selectClasses } from 'venn-ui-kit';
import type { BasePortfolioField } from './types';
import BasePortfolioContext from './context';
import { portfolioToSearchMenuItem, SearchMenuBar } from '../../search-menu';
import userContext from '../../contexts/user-context';
import { CreateFromScratchFooter } from '../../search-menu/components/CreateCompositeFooter';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const qaClass = 'qa-create-portfolio-from';

export type BasePortfolioStateUpdater = (state: BasePortfolioField) => BasePortfolioField;
export type BasePortfolioChangeCallback = () => void;

export interface BasePortfolioProps {
  onChange(updater: BasePortfolioStateUpdater, callback?: BasePortfolioChangeCallback): void;
}

const BasePortfolio = ({ onChange }: BasePortfolioProps) => {
  const { hasPermissionForResource } = useContext(userContext);
  const basePortfolioContext = useContext(BasePortfolioContext);
  const {
    basePortfolio: { loading, disabled: baseDisabled, allowFromScratch, baseline },
  } = basePortfolioContext;
  const toggleLoading = (newLoading: boolean) =>
    onChange((prevState) => ({
      ...prevState,
      loading: newLoading,
    }));

  const startLoading = () => toggleLoading(true);

  const fetchSpecificPortfolio = async (id: number | undefined) => {
    startLoading();

    if (!id) {
      onChange((prevState) => ({
        ...prevState,
        loading: false,
        baseline: undefined,
      }));
      return;
    }

    try {
      const portfolio = (await getSpecificPortfolioV3(id)).content;
      onChange((prevState) => ({
        ...prevState,
        loading: false,
        baseline: portfolio,
      }));
    } catch (error) {
      onChange((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const onLocalChange = (id: number | undefined) => {
    onChange(
      (prevState) => ({
        ...prevState,
        id,
      }),
      () => fetchSpecificPortfolio(id),
    );
  };

  const onCreateFromScratch = () => {
    onLocalChange(undefined);
  };

  return (
    <Wrapper className={qaClass}>
      {loading ? (
        <Loading size={LoadingSize.small} />
      ) : (
        <>
          <Label>Create new portfolio from:</Label>
          <StyledSearchMenuBar
            smallScreen
            autofocus={false}
            customPlaceholder="Create from scratch..."
            value={baseline ? portfolioToSearchMenuItem(baseline) : null}
            isOptionDisabled={(item) =>
              !!item.value?.portfolio && !hasPermissionForResource('EDIT_PORTFOLIO', item.value?.portfolio)
            }
            isClearable
            portfoliosOnly
            onSelected={(item) => onLocalChange(item.value?.portfolio?.id)}
            location="Add to portfolio"
            footer={allowFromScratch ? CreateFromScratchFooter(onCreateFromScratch) : undefined}
            disabled={baseDisabled}
            privateAssetSearchMode="PUBLIC_ONLY"
          />
        </>
      )}
    </Wrapper>
  );
};

export default BasePortfolio;

const StyledSearchMenuBar = styled(SearchMenuBar)`
  .${selectClasses.SearchIcon} {
    display: none;
  }
`;
