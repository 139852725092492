import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { isFunction } from 'lodash';
import styled, { ThemeContext } from 'styled-components';
import type { OutputFileType } from 'venn-api';
import { IMAGE_EXPORT_SCALE_KEY } from 'venn-api';
import { getTextThemeProvider, Notifications, NotificationType, Tooltip, TooltipPosition } from 'venn-ui-kit';
import DownloadableDropdown from './DownloadableDropdown';
import {
  analyticsService,
  type ExcelCell,
  generateExportEvent,
  isChrome,
  logMessageToSentry,
  SpecialCssClasses,
  useHasFF,
} from 'venn-utils';
import {
  copyCanvasToClipboard,
  downloadCanvasContentAsImage,
  getImagesFooter,
  onExportExcelWithMetaData,
  renderStaticMarkupWithTheme,
} from './helper';
import type { DownloadMetaData, DownloadTrackingEvent, ExcelSheetData, Padding, WatermarkPosition } from './types';
import { DEFAULT_IMAGE_EXPORT_SCALE, generateCanvasFromHtml, generateCanvasFromNode } from './generate-canvas';
import ExportContext from '../contexts/export-context';
import UserContext from '../contexts/user-context';
import Markdown from '../markdown/Markdown';
import { useAppPrintMode } from '../print';

const DEFAULT_PADDING = 30;

interface DownloadableOptions {
  /**
   * Filename for both the image and the data export.
   */
  fileName?: string;
  /**
   * Text on the Download button (if required)
   */
  buttonText?: string;
  /**
   * Watermark position. By default, 20px from both the top and the right of the exported content,
   * exclusive of padding.
   */
  watermark?: WatermarkPosition;
  /**
   * Allows adding some padding (in pixel) to the printed content, so it can accomodate the watermark.
   * This defaults to 30 (px) in each direction if not set.
   */
  padding?: Padding;
  /**
   * Forces the width of the generated image.
   * By default, the generated image will have the same width as the original content on screen.
   */
  width?: number;
  /**
   * If set, this adds a tooltip on the button if the button is disabled.
   */
  disabledReason?: string;
  /**
   * Optional element that will be inserted at the bottom of the content
   */
  footer?: JSX.Element;
  /**
   * Optional element that will be inserted at the top of the content
   */
  header?: JSX.Element;
  /**
   * Metadata to include in excel or image footer
   */
  metaData?: DownloadMetaData;
}

export interface DownloadableProps {
  /**
   * Disables the Download button
   */
  disabled?: boolean;
  /**
   * Provide the data that will be inserted in the Excel export.
   * If no data is provided, or if the array is empty, exporting to Excel is disabled (unless `excelMultiSheet` is present).
   */
  excel?: ExcelCell[][];
  /**
   * Provide the formatted multi-sheet excel data for the Excel export, if `excel` is not provided.
   * Multi-sheet export DOES NOT work with Analysis Bulk Export (one block can export up to one sheet only; use `excel`
   * prop for that case).
   */
  excelMultiSheet?: ExcelSheetData[];
  /**
   * Enables PNG (Image) download
   */
  png?: boolean;
  /**
   * Node that will be exported as an image, or function that will provide the content
   */
  target: React.RefObject<HTMLElement> | (() => JSX.Element);
  /**
   * Options (filename, etc)
   */
  options?: DownloadableOptions;
  /**
   * Informations for the tracking event. This is compulsory.
   */
  tracking: DownloadTrackingEvent;
  /** Show message to explain why the data export has been disabled */
  disableExcelExportMessage?: string;
}

const Downloadable = ({
  disabled,
  png,
  excel,
  excelMultiSheet,
  tracking,
  options,
  target,
  disableExcelExportMessage,
}: DownloadableProps) => {
  const { updateExportData } = useContext(ExportContext);
  const { profileSettings, settings } = useContext(UserContext);
  const sponsor = profileSettings?.sponsor;
  const hasReportLab = useHasFF('studio_report_editor');
  const hasExportDisabled = useHasFF('disable_exports_ff');
  const hasWatermark = useHasFF('venn_branded_watermark_ff');
  const forceDisabled = hasExportDisabled && !hasReportLab;
  const forceDisabledMessage = hasWatermark
    ? `In order to remove Venn branding and export Venn output, access to Report Lab is required. 
     [Contact Us](<mailto:${getTextThemeProvider().salesEmail}?subject=Report Lab Inquiry>) to learn more.`
    : `In order export Venn output, access to Report Lab is required.
     [Contact Us](<mailto:${getTextThemeProvider().salesEmail}?subject=Report Lab Inquiry>) to learn more.`;
  const scale = settings?.user?.[IMAGE_EXPORT_SCALE_KEY] ?? DEFAULT_IMAGE_EXPORT_SCALE;
  const optionsWithDefaults = useMemo(() => {
    return {
      fileName: 'download',
      watermark: { top: 20, right: 20, ...options?.watermark },
      buttonText: 'Download',
      padding: { top: DEFAULT_PADDING, right: DEFAULT_PADDING, bottom: DEFAULT_PADDING, left: DEFAULT_PADDING },
      width: undefined,
      disabledReason: undefined,
      footer: getImagesFooter(options?.metaData),
      header: undefined,
      ...(options || {}),
    };
  }, [options]);
  const { buttonText, disabledReason } = optionsWithDefaults;
  const theme = useContext(ThemeContext);

  const handleExportAsImage = useCallback(async () => {
    const { fileName = 'download', watermark, padding, width, footer, header } = optionsWithDefaults;
    const generatedName = `${fileName}.png`;
    const canvas = isFunction(target)
      ? await generateCanvasFromHtml(
          // TODO(VENN-23056): refactor to use portal and client-side rendering rather than super brittle client-side usage of server-side rendering utils
          renderStaticMarkupWithTheme(target(), theme),
          watermark,
          padding,
          theme,
          width,
          footer,
          header,
          sponsor,
          scale,
        )
      : await generateCanvasFromNode(target.current!, watermark, padding, theme, width, footer, header, sponsor, scale);
    track('PNG', tracking);
    downloadCanvasContentAsImage(canvas, generatedName);
  }, [target, optionsWithDefaults, tracking, sponsor, theme, scale]);

  const handleExportToExcel = useCallback(
    async (exportData: ExcelCell[][]) => {
      const { fileName } = optionsWithDefaults;
      track('XLSX', tracking);
      await onExportExcelWithMetaData([{ sheetName: fileName, data: exportData }], fileName, options?.metaData);
    },
    [optionsWithDefaults, tracking, options],
  );

  const handleMultiSheetExportToExcel = useCallback(
    async (exportExcelSheetData: ExcelSheetData[]) => {
      const { fileName } = optionsWithDefaults;
      track('XLSX', tracking);
      await onExportExcelWithMetaData(exportExcelSheetData, fileName, options?.metaData);
    },
    [optionsWithDefaults, tracking, options],
  );

  const handleExportToClipboard = useCallback(async () => {
    const { watermark, padding, width, footer, header } = optionsWithDefaults;
    const canvas = isFunction(target)
      ? await generateCanvasFromHtml(
          renderStaticMarkupWithTheme(target(), theme),
          watermark,
          padding,
          theme,
          width,
          footer,
          header,
          sponsor,
          scale,
        )
      : await generateCanvasFromNode(target.current!, watermark, padding, theme, width, footer, header, sponsor, scale);

    track('CLIPBOARD', tracking);
    try {
      copyCanvasToClipboard(canvas);
      Notifications.notify('Image copied to clipboard');
    } catch (err) {
      if (err?.message.includes('Document is not focused')) {
        Notifications.notify(
          'Failed to copy image. Please try again, making sure the Venn application is not a background tab of your browser.',
          NotificationType.ERROR,
        );
      } else {
        logMessageToSentry(`Error while copying canvas to clipboard: ${err}`);
        Notifications.notify('Failed to copy image. Please try again.', NotificationType.ERROR);
      }
    }
  }, [tracking, optionsWithDefaults, target, sponsor, scale, theme]);

  useEffect(() => {
    if (!excel) {
      return;
    }
    updateExportData(optionsWithDefaults.fileName, () => excel);
  }, [optionsWithDefaults.fileName, excel, updateExportData]);

  const { inPrintMode } = useAppPrintMode();
  if (inPrintMode) {
    return null;
  }

  return (
    <Container className={SpecialCssClasses.NotDownloadable}>
      <Tooltip
        content={forceDisabled ? <Markdown noMargin source={forceDisabledMessage} /> : disabledReason}
        isHidden={!forceDisabled && (!disabledReason || !disabled)}
        position={TooltipPosition.Left}
        interactive
      >
        <DownloadableDropdown
          onDownloadPNG={png ? handleExportAsImage : undefined}
          disableDownloadDataMessage={disableExcelExportMessage}
          onDownloadData={
            excel && excel.length
              ? () => handleExportToExcel(excel)
              : excelMultiSheet && excelMultiSheet.length
                ? () => handleMultiSheetExportToExcel(excelMultiSheet)
                : undefined
          }
          onCopyImage={png && isChrome ? handleExportToClipboard : undefined}
          disabled={disabled || forceDisabled}
          absolutelyPositioned={false}
        >
          {buttonText}
        </DownloadableDropdown>
      </Tooltip>
    </Container>
  );
};

function track(fileType: OutputFileType, tracking: DownloadTrackingEvent) {
  const exportEvent = generateExportEvent(
    tracking.subjectType === 'investment' || tracking.subjectType === 'private-investment' ? 'INVESTMENT' : 'PORTFOLIO',
    tracking.subjectId,
    tracking.description,
    tracking.userUploaded,
    tracking.relativeToBenchmark,
  );
  exportEvent.outputFileType = fileType;
  analyticsService.dataExported(exportEvent);
}

export default Downloadable;

const Container = styled.div`
  display: inline-block;
  position: relative;
`;
