import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import RequiredLabel from '../../label';
import { default as BaseStatefulInput } from '../../stateful-input/StatefulInput';
import { checkPortfolioNameV3 } from 'venn-api';
import Field from '../validation/Field';
import type { NameField } from './types';
import { useDebounce } from '../../hooks';

const Label = styled(RequiredLabel)`
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
`;

const StatefulInput = styled(BaseStatefulInput)`
  display: flex;
`;

const Wrapper = styled.div`
  margin-top: 20px;
`;

export type NameStateUpdater = (state: NameField) => NameField;
export type NameChangeCallback = () => void;

export interface NameProps {
  initialValue?: string;
  onChange: (field: NameField) => void;
}

const PORTFOLIO_NAME_VALIDATION_DEBOUNCE = 500;

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({ initialValue, onChange }: NameProps) => {
  const [name, setName] = useState<string | undefined>(initialValue);
  const [debouncedName] = useDebounce(name, PORTFOLIO_NAME_VALIDATION_DEBOUNCE);
  const [error, setError] = useState<string | undefined>();

  const checkIfPortfolioExists = useCallback(
    async (input: string) => {
      try {
        const isUsed = (await checkPortfolioNameV3(input)).content;
        setError(isUsed ? 'Portfolio name is already used' : '');
        onChange({
          value: debouncedName,
          isError: isUsed,
          validating: false,
        });
      } catch (e) {
        const { content } = await e;
        setError(content?.message);
        onChange({
          value: debouncedName,
          isError: true,
          validating: false,
        });
      }
    },
    [debouncedName, onChange],
  );

  const onInputChange = useCallback((input: string) => {
    setName(input);
  }, []);

  useEffect(() => {
    setError('');
    onChange({
      value: name,
      isError: false,
      validating: true,
    });
  }, [name, onChange]);

  useEffect(() => {
    if (debouncedName !== undefined) {
      if (debouncedName.length === 0) {
        setError('Portfolio name must not be empty');
        onChange({
          value: debouncedName,
          validating: false,
          isError: true,
        });
      } else {
        checkIfPortfolioExists(debouncedName);
      }
    } else {
      onChange({
        value: debouncedName,
        isError: true,
        validating: false,
      });
    }
  }, [checkIfPortfolioExists, debouncedName, onChange]);

  return (
    <Wrapper>
      <Field error={error}>
        <Label required>Portfolio name:</Label>
        <StatefulInput
          placeholder="Enter portfolio name"
          value={name}
          onChange={onInputChange}
          className="qa-portfolio-name"
          selectOnFocus
        />
      </Field>
    </Wrapper>
  );
};
