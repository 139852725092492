import React from 'react';
import styled from 'styled-components';
import every from 'lodash/every';
import FilterTrigger from '../triggers/FilterTrigger';
import type { CheckboxMenuProps } from '../menus/CheckboxMenu';
import { default as CheckboxMenu } from '../menus/CheckboxMenu';
import BaseDropMenu from './BaseDropMenu';
import { buildLabel } from '../logic/checkbox-logic';
import type { BaseDropMenuProps, MenuContainerProps } from '../types';

export interface FilterDropMenuProps<T> extends CheckboxMenuProps<T>, BaseDropMenuProps<T>, MenuContainerProps {}

export class DropMenu<T> extends React.Component<FilterDropMenuProps<T>> {
  onClear = () => {
    const { onChange, items } = this.props;
    onChange(
      items.map((i) => ({
        ...i,
        checked: true,
      })),
    );
  };

  render() {
    const {
      label,
      items,
      className,
      openByDefault,
      usePortal,
      disabled,
      width,
      height,
      header,
      onChange,
      innerRef,
      selectedMetricText,
      tooltipPortal,
    } = this.props;
    const allSelected = every(items, { checked: true });
    return (
      <BaseDropMenu
        openByDefault={openByDefault}
        usePortal={usePortal}
        filteredItems={items}
        triggerComponent={(expanded, _, onExpand) => (
          <FilterTrigger
            className={className}
            disabled={disabled}
            label={label}
            expanded={expanded}
            selection={buildLabel(items, selectedMetricText)}
            all={allSelected}
            innerRef={innerRef}
            onClick={onExpand}
            onClear={this.onClear}
            width={width}
            square
          />
        )}
        menuComponent={(_, onCollapse, menuClassName) => (
          <MenuWrapper className={menuClassName}>
            <CheckboxMenu<T>
              width={width}
              height={height}
              header={header}
              items={items}
              onChange={onChange}
              onCollapse={onCollapse}
              hideSelectAll={disabled}
              tooltipPortal={tooltipPortal}
            />
          </MenuWrapper>
        )}
      />
    );
  }
}

const MenuWrapper = styled.div`
  margin-top: 10px;
`;

export default DropMenu;
