import type { DateRange, RangeType } from 'venn-ui-kit';
import type { AnalysisView, UserProfileSettings, AnalysisSubjectTypeEnum, AnalysisViewSubject } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import {
  isEqualWithFullPeriodAmendment,
  getRecentDateRange,
  compareDateEqual,
  ignoreEmptyEqual,
  getDateRangeFromLocation,
  shouldUseRecent,
} from 'venn-utils';
import isEqual from 'lodash/isEqual';
import type { Location } from 'history';

export interface ComparisonViewBaseline {
  name?: string;
  dateRange: DateRange;
  subjects: AnalysisViewSubject[];
  benchmark?: AnalysisViewSubject;
  relative: boolean;
  isPivoted: boolean;
  isOwner: boolean;
  ownerContextId?: string;
}

export const emptyBaseline: ComparisonViewBaseline = {
  dateRange: {},
  subjects: [],
  relative: false,
  isOwner: true,
  isPivoted: true,
};

export const convertViewToBaseline = (
  analysisView: AnalysisView,
  profileSettings?: UserProfileSettings,
): ComparisonViewBaseline => {
  const validSubjects = analysisView.subjects.filter((subject) => !subject.subjectInaccessible);
  return {
    name: analysisView.name,
    dateRange: {
      from: analysisView.analysisPeriod?.startDate,
      to: analysisView.analysisPeriod?.endDate,
      period: analysisView.analysisPeriod?.periodToDate as RangeType,
    },
    subjects: validSubjects.filter((subject) => subject.comparisonType !== 'BENCHMARK'),
    benchmark: validSubjects.find((subject) => subject.comparisonType === 'BENCHMARK'),
    relative: analysisView.customViewOptions?.relative,
    isOwner: analysisView.owner.id === profileSettings?.user.id,
    isPivoted: !!analysisView.customViewOptions?.isPivoted,
    ownerContextId: analysisView.ownerContextId,
  };
};

export const convertToView = (
  name: string,
  subjects: AnalysisSubject[],
  dateRange: DateRange,
  relative: boolean,
  benchmark?: AnalysisSubject,
  id?: string,
  isPivoted?: boolean,
  ownerContextId?: string,
): Partial<AnalysisView> => {
  const subjectsList: AnalysisViewSubject[] = subjects.map((s, index) => ({
    comparisonType: index === 0 ? 'PRIMARY' : 'COMPARISON',
    id: String(s.id),
    subjectType: s.type.toUpperCase() as AnalysisSubjectTypeEnum,
    isPrivate: s.private,
  }));

  if (benchmark) {
    subjectsList.unshift({
      comparisonType: 'BENCHMARK',
      id: String(benchmark.id),
      subjectType: benchmark.type.toUpperCase() as AnalysisSubjectTypeEnum,
      isPrivate: benchmark.private,
    });
  }
  return {
    id,
    analysisPeriod: { endDate: dateRange.to, periodToDate: dateRange.period, startDate: dateRange.from },
    analysisViewType: 'COMPARE',
    customViewOptions: { relative, isPivoted },
    name,
    subjects: subjectsList,
    systemTemplate: 'compare',
    ownerContextId,
  };
};

export const compareDiff = (
  baseline: ComparisonViewBaseline,
  subjects: AnalysisSubject[],
  dateRange: DateRange,
  relative: boolean,
  benchmark?: AnalysisSubject,
  isPivoted?: boolean,
) => {
  const benchmarkId = benchmark ? String(benchmark.id) : undefined;
  if (
    !compareDateEqual(baseline.dateRange.from, dateRange.from) ||
    !compareDateEqual(baseline.dateRange.to, dateRange.to) ||
    !isEqualWithFullPeriodAmendment(baseline.dateRange.period, dateRange.period) ||
    !ignoreEmptyEqual(baseline.isPivoted, isPivoted) ||
    baseline.relative !== relative ||
    baseline.benchmark?.id !== benchmarkId
  ) {
    return true;
  }

  // Convert portfolio id to string to match baseline subject id
  const currentList = subjects.map((s) => String(s.id));
  const baselineList = baseline.subjects.map((s) => s.id);
  return !isEqual(baselineList, currentList);
};

export const getInitialDateRange = (location: Location): DateRange => {
  const dateRangeFromLocation = getDateRangeFromLocation(location);
  if (
    dateRangeFromLocation &&
    (dateRangeFromLocation.from || dateRangeFromLocation.to || dateRangeFromLocation.period)
  ) {
    return dateRangeFromLocation;
  }
  return shouldUseRecent(location) ? getRecentDateRange() : {};
};
