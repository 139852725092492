import { isEmpty } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';

import styled from 'styled-components';
import { CurrencyEnum } from 'venn-api';
import { blockExportMetadata, blockInfoGraphicType, blockSettings, customizedBlock, isReportState } from 'venn-state';
import { assertNotNil, DEFAULT_USE_LOGARITHMIC_SCALE, logExceptionIntoSentry } from 'venn-utils';
import DownloadableContentBlock, { EmptyHeaderSpace } from '../../../../content-block/DownloadableContentBlock';
import { GridWrapper } from '../../../common';
import { useBlockId } from '../../../contexts/BlockIdContext';
import { WATERMARK_POSITION_TOP } from '../../../customAnalysisContants';
import useExportUpdate from '../../../logic/useExportUpdate';
import GrowthSimulationLineChart from '../../charts/GrowthSimulationLineChart';
import { PublicPrivateErrorMessages } from '../../PrivatesErrorMessages';
import StyledEmptyState from '../../StyledEmptyState';
import { usePercentileChartExportDataFunction } from './logic/usePercentileChartExportDataFunction';
import { usePublicPrivateGrowthPercentileChartData } from './logic/usePublicPrivateGrowthPercentileChartData';
import { PublicPrivateAssetGrowthBreakdownBlock } from './PublicPrivateAssetGrowthBreakdownBlock';
import { PublicPrivateAssetGrowthGridBlock } from './PublicPrivateAssetGrowthGridBlock';
import type { PublicPrivateAssetGrowthBlockProps, PublicPrivateAssetGrowthBlockWrapperProps } from './types';

export const PublicPrivateAssetGrowthBlockWrapper = ({
  exportable,
  inPrintMode,
  data,
  subjectErrorMessages,
  downloadableContentRef,
}: PublicPrivateAssetGrowthBlockWrapperProps) => {
  const blockId = useBlockId();
  const customBlockSettings = useRecoilValue(blockSettings(blockId));
  const selectedInfoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const isReport = useRecoilValue(isReportState);

  if (subjectErrorMessages && !isEmpty(subjectErrorMessages)) {
    return <PublicPrivateErrorMessages errorMessages={subjectErrorMessages} />;
  }

  if (!data) {
    return <StyledEmptyState selectedRefId={blockId} header="Unable to run analysis" message="Chart is empty" />;
  }

  if (selectedInfoGraphicType === 'GRID') {
    return (
      <GridWrapper isReport={isReport}>
        <PublicPrivateAssetGrowthGridBlock
          data={assertNotNil(data)}
          exportable={exportable}
          inPrintMode={inPrintMode}
          downloadableContentRef={downloadableContentRef}
        />
      </GridWrapper>
    );
  }

  switch (customBlockSettings.customBlockType) {
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES':
      return (
        <PublicPrivateAssetGrowthBlock
          downloadableContentRef={downloadableContentRef}
          inPrintMode={inPrintMode}
          exportable={exportable}
          data={data}
        />
      );
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN':
      return (
        <PublicPrivateAssetGrowthBreakdownBlock
          downloadableContentRef={downloadableContentRef}
          inPrintMode={inPrintMode}
          exportable={exportable}
          data={data}
        />
      );
    default:
      logExceptionIntoSentry('Invalid custom block type for public-private asset growth');
      return <></>;
  }
};
const PublicPrivateAssetGrowthBlock = ({
  data,
  inPrintMode,
  downloadableContentRef,
  exportable,
}: PublicPrivateAssetGrowthBlockProps) => {
  const blockId = useBlockId();
  const selectedBlock = useRecoilValue(customizedBlock(blockId));
  const exportMetaData = useRecoilValue(blockExportMetadata(blockId));
  const useLogarithmicScale = selectedBlock?.logarithmicScale ?? DEFAULT_USE_LOGARITHMIC_SCALE;
  const { subjectsSeries, subjectInfos, fundingFailuresSeries } = usePublicPrivateGrowthPercentileChartData(data);

  const excelDataFn = usePercentileChartExportDataFunction({
    subjectsSeries: [...subjectsSeries],
    subjectInfos,
    exportable,
  });
  useExportUpdate({
    exportMetaData,
    excelDataFn,
    selectedRefId: blockId,
  });

  const simulationRange = data[0]?.simulationRange;

  const chartComponent = (
    <ChartWrapper data-testid="qa-public-private-growth-simulation-chart">
      <GrowthSimulationLineChart
        series={subjectsSeries}
        additionalSeries={fundingFailuresSeries}
        start={undefined}
        end={simulationRange?.end}
        frequency={'QUARTERLY' as const}
        yAxisUnitFormat="ratio"
        inPrintMode={inPrintMode}
        useLogarithmicScale={useLogarithmicScale}
        currency={CurrencyEnum.USD} // only USD supported for Private Asset Lab
        subjectInfo={subjectInfos}
      />
    </ChartWrapper>
  );

  if (inPrintMode) {
    return chartComponent;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: 'Asset Growth - Percentiles',
          watermark: {
            top: WATERMARK_POSITION_TOP,
            right: 20,
          },
        },
        tracking: {
          description: 'GROWTH_SIMULATION_PUBLIC_PRIVATE',
          relativeToBenchmark: false,
          userUploaded: false,
          subjectType: subjectInfos.length
            ? subjectInfos?.[0]?.subject.subjectType
              ? subjectInfos?.[0]?.subject.subjectType === 'PORTFOLIO'
                ? 'portfolio'
                : 'investment'
              : undefined
            : undefined,
          subjectId: subjectInfos.length ? subjectInfos[0].subject.id : undefined,
        },
        disabled: (subjectsSeries?.length ?? 0) === 0,
        target: downloadableContentRef,
      }}
      floatingOptions
    >
      {chartComponent}
    </DownloadableContentBlock>
  );
};

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div:first-child {
    flex: 1;
  }
`;
