import React, { useCallback, useMemo } from 'react';
import { compact } from 'lodash';
import { EllipsisTooltipSpan } from 'venn-ui-kit';

import type { AnalysisBlockProps, TabularDataRow } from './types';
import { StyledBasicTable, FIRST_COLUMN_INNER_WIDTH, FIRST_COLUMN_OUTER_WIDTH_PX } from './StyledBasicTable';
import type { BasicTableColumn } from '../../../../basictable';
import { ColumnAlign } from '../../../../basictable';
import CorrelationColorLegend from './CorrelationColorLegend';

import { pairwiseCorrelationExtractor } from './dataExtractors';
import { pairwiseCorrelationCellRenderer } from './cellRenderers';
import type { ExportInfo } from '../../../types';
import { type ExcelCell } from 'venn-utils';
import useExportUpdate from '../../../logic/useExportUpdate';
import VerticalHeader from './VerticalHeader';
import { formatExportableSubjectWithOptionalFee } from '../../../../legend';
import { useRecoilValue } from 'recoil';
import { blockDisplayHeader, blockFonts, getCustomFontSize, studioPrintOrientationType } from 'venn-state';

interface PairwiseCorrelationProps extends ExportInfo, AnalysisBlockProps {}

const PairwiseCorrelation = ({ requests, analysis, exportMetaData, selectedRefId }: PairwiseCorrelationProps) => {
  const tableFont = useRecoilValue(blockFonts.blockTableData(selectedRefId));
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(selectedRefId));
  const printOrientation = useRecoilValue(studioPrintOrientationType);
  const header = useRecoilValue(blockDisplayHeader(selectedRefId));
  const subjects = useMemo(() => requests.map((req) => req.subject), [requests]);

  const data = useMemo(() => pairwiseCorrelationExtractor(subjects, analysis), [subjects, analysis]);

  const excelDataFn = useCallback(() => {
    const isRelative = requests[0]?.relative;
    const benchmarks = requests.map((r) => r.benchmark);
    const headerRow: ExcelCell[] = data.map((data, index) => ({
      value: isRelative ? `${data.label} (Relative to ${benchmarks[index]?.name ?? '--'})` : data.label,
      bold: true,
    }));
    const body: ExcelCell[][] = data.map((row) => {
      const cellData: ExcelCell[] = [];
      for (let subjectIdx = 0; subjectIdx < requests.length; subjectIdx++) {
        cellData.push({
          value: row[subjectIdx]?.value ?? '--',
          percentage: row?.type === 'percentage',
          digits: 2,
        });
      }
      return [{ value: row.label, bold: true }, ...cellData];
    });
    return [[{ value: header, bold: true }, ...headerRow], ...body];
  }, [data, requests, header]);

  useExportUpdate({ selectedRefId, exportMetaData, excelDataFn });

  const getColumns = (): BasicTableColumn<unknown>[] =>
    compact([
      {
        label: 'Correlation',
        accessor: 'label',
        headerStyle: {
          maxWidth: FIRST_COLUMN_OUTER_WIDTH_PX,
          fontSize: `${tableFont.fontSizePt}pt`,
        },
        cellRenderer: (rowData: TabularDataRow) => (
          <EllipsisTooltipSpan usePortal maxWidth={FIRST_COLUMN_INNER_WIDTH}>
            {`${rowData.label}`}
          </EllipsisTooltipSpan>
        ),
      },
      ...subjects.map((subject, colNum) => ({
        label: formatExportableSubjectWithOptionalFee(subject),
        align: ColumnAlign.RIGHT,
        cellRenderer: pairwiseCorrelationCellRenderer(colNum, 0, undefined),
        headerRenderer: (_: unknown, data: TabularDataRow[]) => <VerticalHeader>{data[colNum].label}</VerticalHeader>,
      })),
    ]);
  return (
    <>
      <StyledBasicTable
        columns={getColumns()}
        data={data}
        compactRows={printOrientation === 'LANDSCAPE'}
        fontSize={getCustomFontSize(tableFont)}
      />
      <CorrelationColorLegend fontSize={getCustomFontSize(legendFont)} />
    </>
  );
};

export default PairwiseCorrelation;
