/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment';

export interface AllocatorInvestmentsChanged {
  /**
   * IDs of added investments
   */
  addedInvestmentIds?: string[];
  /**
   * whether this is private allocator panel
   */
  isPrivateAllocatorPanel?: boolean;
  /**
   * ID of a portfolio opened in allocator panel
   */
  portfolioId?: string;
  /**
   * IDs of removed investments
   */
  removedInvestmentIds?: string[];
}
export interface AllocatorPanelOpened {
  /**
   * whether this is private allocator panel
   */
  isPrivateAllocatorPanel?: boolean;
  /**
   * ID of a portfolio opened in allocator panel
   */
  portfolioId?: string;
}
export interface AnalysisFailed {
  /**
   * name of analysis template
   */
  analysisName?: string;
  /**
   * failed blocks in the analysis template
   */
  blocks?: any[];
  /**
   * startDate-endDate
   */
  dateRange?: string;
  /**
   * max frequency from backend analysis response
   */
  frequency?: string;
  /**
   * true if investment has active benchmark
   */
  hasBenchmark?: boolean;
  /**
   * true if the investment has a proxy
   */
  hasProxy?: boolean;
  /**
   * investment or portfolio
   */
  objectType?: string;
  /**
   * For metered accounts, analysis failed because they didn't have bandwidth to run analysis
   */
  overThreshold?: boolean;
  /**
   * true if relative to benchmark
   */
  relativeToBenchmark?: boolean;
}
export interface AnalysisPerformed {
  /**
   * $ or % for portfolio allocation
   */
  allocationType?: string | null;
  /**
   * names of analyses blocks included
   */
  analysisNames?: any[];
  /**
   * StartDate-EndDate of analysis range
   */
  dateRange?: string;
  /**
   * Frontend request Factor lens Id used for the analysis
   */
  factorLensId?: number;
  /**
   * Backend response of max frequency for the analysis
   */
  frequency?: string;
  /**
   * true if the investment/portfolio has active benchmark
   */
  hasBenchmark?: boolean;
  /**
   * true if the investment has proxy
   */
  hasProxy?: boolean;
  /**
   * true if the template is not system template
   */
  isCustomTemplate?: boolean;
  /**
   * true if current template is user's default template
   */
  isDefaultTemplate?: boolean;
  /**
   * true if the objectType is investment and the investment is in the user's master portfolio
   */
  isInMasterPortfolio?: boolean;
  /**
   * true if current portfolio is master portfolio
   */
  isMaster?: boolean | null;
  /**
   * analyzed investment or portfolio's id
   */
  objectId?: any | null;
  /**
   * analyzed investment or portfolio's name
   */
  objectName?: string;
  /**
   * investment or portfolio
   */
  objectType?: string;
  /**
   * 0 for root portfolio and each nested level +1
   */
  portfolioLevelSelected?: number | null;
  /**
   * true if relative to benchmark
   */
  relativeToBenchmark?: boolean;
  /**
   * true if the investment is user uploaded
   */
  userUploaded?: boolean | null;
}
export interface AnalysisTemplateSaved {
  /**
   * a list of analysis blocks name
   */
  analysisNames?: any[];
  /**
   * T/F - is this a brand new template that, in being saved, was created
   */
  new?: string;
}
export interface ApplyBlockSettings {
  /**
   * Common, individual or none
   */
  benchmark?: string;
  /**
   * Whether block is linked or unlinked
   */
  blockStatus?: string;
  /**
   * What block user is editing
   */
  blockType?: string;
  /**
   * Format of the block
   */
  format?: string | null;
  /**
   * Either on or off
   */
  percentageContribution?: boolean | null;
  /**
   * either true or false
   */
  relativeToBenchmark?: boolean;
  /**
   * factors selected by an user
   */
  selectedFactors?: any[] | null;
  /**
   * metrics selected by an user
   */
  selectedMetrics?: any[] | null;
}
export interface BlockFontSizeChanged {
  blockId: string;
  blockType: string;
  fontSize: number;
  fontType: string;
  viewId?: string;
}
export interface SubjectsItem {
  /**
   * Benchmark id
   */
  benchmarkId?: string;
  /**
   * Primary subject id
   */
  subjectId?: string;
}
export interface BuilderAnalysisPerformed {
  /**
   * A list of analysis types
   */
  analysisTypes?: string[];
  /**
   * None, Common or Individual
   */
  benchmarkType?: string;
  /**
   * Id to identify the block type
   */
  builderId?: string;
  /**
   * Select contribution to percentage
   */
  contributionToPercentage?: boolean;
  /**
   * Start and end time to run analysis
   */
  dateRange?: string;
  /**
   * "MONTHLY“ "DAILY"
   */
  frequency?: string;
  /**
   * Select relative to benchmark
   */
  relativeToBenchmark?: boolean;
  /**
   * A list of selected factor ids
   */
  selectedFactors?: string[];
  /**
   * a list of metrics key
   */
  selectedMetrics?: string[];
  /**
   * A list of subjects including benchmark
   */
  subjects?: SubjectsItem[];
  /**
   * standard or pivot
   */
  tableLayout?: string;
  /**
   * individual or single benchmark
   */
  useOwnBenchmark?: boolean;
}
export interface Subjects {
  /**
   * List of private fund IDs
   */
  privateFundIds?: string[];
  /**
   * List of private portfolio IDs
   */
  privatePortfolioIds?: string[];
}
export interface BulkActionPerformed {
  /**
   * the currencies selected
   */
  currencyFilters?: string[];
  /**
   * [DEPRECATED] selected library filter such as 'all', 'investments', 'composite' and etc.
   */
  libraryFilter?: string;
  /**
   * the metric fields on which there is a filter applied
   */
  metricFilters?: string[];
  /**
   * number of selected items
   */
  numberOfItems?: number;
  /**
   * if there is a search query
   */
  queryDriven?: boolean;
  /**
   * the quick filters applied (eg, recently analyzed, requires attention)
   */
  quickFilters?: any[];
  /**
   * Subjects (funds/portfolios) on which this bulk action was performed
   */
  subjects?: Subjects;
  /**
   * the tags applied to the search
   */
  tagFilters?: any[];
  /**
   * the number of total results available for the search query
   */
  totalResults?: number;
  /**
   * bulk action type such as 'Add to Portfolio', 'Create Portfolio', 'Delete' or 'Compare'
   */
  type?: string;
  /**
   * which types are allowed - eg, Investments, Portfolios, Benchmarks
   */
  typeFilters?: any[];
  /**
   * the number of results visible to the user
   */
  visibleResults?: number;
}
export interface CashFlowSettingsApplied {
  /**
   * ID of the fund to which setting is being applied to
   */
  fundId?: string;
  /**
   * ID of the subject for which the modal is open for (can be either a fund itself or a portfolio containing this fund)
   */
  modalSubjectId?: string;
  /**
   * Denotes if modal is open for a private fund or a private portfolio
   */
  modalSubjectType?: string;
  /**
   * ID of the setting
   */
  settingId?: string;
}
export interface CashFlowSettingsModalOpened {
  /**
   * Source of the action - e.g. data library or studio
   */
  source?: string;
  /**
   * Private fund or portfolio ID
   */
  subjectId?: string;
  /**
   * Denotes if this is a private fund or a private portfolio
   */
  subjectType?: string | null;
  /**
   * ID of the triggering fund (if preselected)
   */
  triggeringFundId?: string;
}
export interface ChartModified {
  /**
   * Which chart is it?
   */
  chart?: string;
  /**
   * startDate-endDate
   */
  dateRange?: string;
  /**
   * investment or portfolio id
   */
  objectID?: string;
  /**
   * investment or portfolio name
   */
  objectName?: string;
  /**
   * investment or portfolio
   */
  objectType?: string;
  /**
   * 1Y, 3Y, etc.; whatever option selected
   */
  rollingPeriod?: string;
  /**
   * Is "Venncast missing data" toggled on?
   */
  venncast?: string;
}
export interface ChecklistDismissedAppcues {
  checklistId?: string;
  id?: string;
  name?: string;
  sessionId?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: string;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userId'?: number;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface ChecklistItemCompletedAppcues {
  checklistId?: string;
  checklistName?: string;
  id?: string;
  itemId?: string;
  itemIndex?: number;
  itemLabel?: string;
  name?: string;
  sessionId?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: string;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.createdAt'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface ChecklistItemStartedAppcues {
  checklistId?: string;
  id?: string;
  itemId?: string;
  itemIndex?: number;
  itemLabel?: string;
  name?: string;
  sessionId?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: string;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.createdAt'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface ChecklistShownAppcues {
  checklistId?: string;
  checklistName?: string;
  id?: string;
  name?: string;
  sessionId?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: string;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.createdAt'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface ChecklistSkippedAppcues {
  checklistId?: string;
  id?: string;
  name?: string;
  sessionId?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: any | null;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.createdAt'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface ComparisonFailed {
  /**
   * true if there is a benchmark
   */
  hasBenchmark?: boolean;
  /**
   * number of compared subjects
   */
  numberOfSubjects?: number;
  /**
   * true if error is excess analysis limit
   */
  overThreshold?: boolean;
  /**
   * true if relative to benchmark
   */
  relativeToBenchmark?: boolean;
}
export interface ComparisonPerformed {
  /**
   * true if there is a benchmark
   */
  hasBenchmark?: boolean;
  /**
   * true if comparison list has any investments
   */
  hasInvestments?: boolean;
  /**
   * true if comparison list has any portfolios
   */
  hasPortfolios?: boolean;
  /**
   * classic | pivoted
   */
  layout?: string;
  /**
   * number of compared subjects
   */
  numberOfSubjects?: number;
  /**
   * true if relative to benchmark
   */
  relativeToBenchmark?: boolean;
}
export interface CookiePreferencesModified {
  /**
   * Users accepting all cookies will have ['required', 'functional', 'advertising'], if they've restricted their access they will have a subset of these choices.
   */
  cookiesAllowed?: any[];
}
export interface CreatingNewStudios {
  /**
   * Name of the block or template
   */
  name?: string;
  /**
   * Builder, toolbar or link
   */
  source?: string;
  /**
   * Either block or template
   */
  type?: string;
}
export interface CtaClicked {
  /**
   * undefined when stay on the same page
   */
  destination?: any | null;
  /**
   * True when cta button is filled
   */
  filled?: boolean;
  /**
   * Cta location such as 'Account Settings - Plan section', 'date range picker'
   */
  locationOnPage?: string;
  /**
   * Cta purpose such as 'select date range'.
   */
  purpose?: string;
  /**
   * Cta context for investment Id or portfolio id
   */
  subjectId?: string;
  /**
   * Cta text
   */
  text?: string;
  /**
   * button or link
   */
  type?: string;
  /**
   * Cta context for saved view ID
   */
  viewId?: string;
}
export interface CustomMetricsCleared {
  cellsCleared: number;
  type: string;
}
export interface DataExported {
  /**
   * investment, portfolio
   */
  objectType?: string;
  /**
   * returns, factor risk, etc.
   */
  outputDescription?: string;
  /**
   * png (or other image), pdf, xlsx
   */
  outputFileType?: string;
  /**
   * true if relative to benchmark
   */
  relativeToBenchmark?: boolean;
  /**
   * This is the ID of the investment or portfolio that the analysis was done on.
   */
  subjectId?: string;
  /**
   * true if the investment is user uploaded
   */
  userUploaded?: boolean;
}
export interface DataManagementOpened {
  /**
   * Where the data management was trigger ie. analysis, library, etc.
   */
  location?: string;
  /**
   * investment id or portfolio id
   */
  objectId?: string;
  /**
   * portfolio, investment, etc.
   */
  objectType?: string;
}
export interface DeletedInvestmentOverride {
  investmentId: string;
}
export interface DocumentsDownloaded {
  /**
   * ids of documents downloaded
   */
  downloadIds?: string[];
  /**
   * how many documents downloaded
   */
  downloadNums: number;
  /**
   * location on the site where documents were downloaded
   */
  location: string;
}
export interface DrawdownSelected {
  /**
   * startDate-endDate
   */
  dateRange?: string;
  /**
   * 'vulnerability row' or 'custom date range' (deprecated)
   */
  drawdownType?: string;
  /**
   * Threshold the user enter in the drawdown table field
   */
  threshold?: number;
}
export interface DropdownClosed {
  /**
   * Dropdown label
   */
  label?: string;
  /**
   * custom, multiselect, or single-select?
   */
  selectType?: string;
  /**
   * Item(s) currently selected
   */
  selection?: string;
  /**
   * Primary, secondary, or 'advanced filter' dropdown?
   */
  type?: string;
}
export interface DropdownItemSSelected {
  /**
   * Dropdown label
   */
  label?: string;
  /**
   * Multiselect or single-select?
   */
  selectType?: string;
  /**
   * Item(s) currently selected
   */
  selection?: string;
  /**
   * Primary or secondary dropdown?
   */
  type?: string;
}
export interface DropdownOpened {
  /**
   * Dropdown label
   */
  label?: string;
  /**
   * custom, multiselect, or single-select?
   */
  selectType?: string;
  /**
   * Item(s) currently selected
   */
  selection?: string;
  /**
   * Primary, secondary, or 'advanced filter' dropdown?
   */
  type?: string;
}
export interface FactorInsightsFailed {
  /**
   * True if the error is analysis exceed limit
   */
  overThreshold?: boolean;
}
export interface FactorsAnalyzed {
  /**
   * true if there are comparator subjects
   */
  comparatorSelected?: boolean;
  dateRange?: string;
  /**
   * true if factor input is on
   */
  factorInputsOn?: boolean;
  /**
   * selected factors name
   */
  factors?: string[];
  /**
   * Selected factor length
   */
  numberOfFactors?: number;
  /**
   * Previous page title, such as "Factor Lens".
   */
  previousPage?: string;
}
export interface FileTemplateDownloaded {
  /**
   * Whether this file is a template for public or private uploads
   */
  isPrivatesTemplate?: boolean;
  /**
   * File tag (e.g. cumulative_private_sample_xls)
   */
  tag?: string;
}
export interface FlowCompletedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  name?: string;
  sessionId?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: string;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface FlowSkippedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  name?: string;
  sessionId?: string;
  stepId?: string;
  stepNumber?: number;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: any | null;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface FlowStartedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  name?: string;
  sessionId?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: string;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface ForecastCreationStarted {
  /**
   * Which type of custom forecast is being created, eg, CMA or Historical
   */
  forecastType: string;
}
export interface ForecastFactorOverride {
  /**
	 * 	
Name of the factor modified
	 */
  factor: string;
  /**
   * ID of the forecast, should be same as multi_factor_forecast
   */
  forecastId: string;
  /**
   * value of the new forecast
   */
  forecastValue: number;
  /**
   * date of which the forecast was modified
   */
  updated: string;
  /**
   * User that updates the forecast
   */
  userId?: number;
}
export interface CmaIndicesItem {
  /**
   * true if forecast is cash
   */
  cashForecast?: boolean;
  /**
   * investment id
   */
  fundId?: string;
  /**
   * return value
   */
  returnVal?: number;
}
export interface ForecastInspected {
  /**
   * Array of investment forecasts
   */
  cmaIndices?: CmaIndicesItem[];
  /**
   * end of the custom historical period
   */
  endDate?: string;
  /**
   * 'historical', 'two sigma', 'historical and CMAs', 'custom historical period', or 'two sigma and CMAs'
   */
  forecastType?: string;
  /**
   * factor forecast length
   */
  numberOfCmaFactors?: number;
  /**
   * investment forecast length
   */
  numberOfCmaIndices?: number;
  /**
   * start of the custom historical period
   */
  startDate?: string;
}
export interface CmaIndicesItem1 {
  /**
   * true if forecast is cash
   */
  cashForecast?: boolean;
  /**
   * investment id
   */
  fundId?: string;
  /**
   * return value
   */
  returnVal?: number;
}
export interface ForecastSelected {
  /**
   * Array of investment forecasts
   */
  cmaIndices?: CmaIndicesItem1[];
  /**
   * 'historical', 'two sigma', 'historical and CMAs', 'custom historical period', or 'two sigma and CMAs'
   */
  forecastType?: string;
  /**
   * factor forecast length
   */
  numberOfCmaFactors?: number;
  /**
   * investment forecast length
   */
  numberOfCmaIndices?: number;
}
export interface FormFieldSubmittedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  interaction?: Record<string, any>;
  'interaction.category'?: string;
  'interaction.fieldId'?: string;
  'interaction.fieldRequired'?: boolean;
  'interaction.fieldType'?: any | null;
  'interaction.formId'?: any | null;
  'interaction.label'?: string;
  'interaction.value'?: string;
  interactionType?: string;
  name?: string;
  sessionId?: string;
  stepChildId?: string;
  stepChildNumber?: number;
  stepId?: string;
  stepType?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: any | null;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
}
export interface FormSubmittedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  interaction?: Record<string, any>;
  'interaction.category'?: string;
  'interaction.formId'?: any | null;
  'interaction.response'?: any[];
  'interaction.response.$'?: Record<string, any>;
  'interaction.response.$.fieldId'?: string;
  'interaction.response.$.fieldRequired'?: boolean;
  'interaction.response.$.fieldType'?: any | null;
  'interaction.response.$.label'?: string;
  'interaction.response.$.value'?: string;
  interactionType?: string;
  name?: string;
  sessionId?: string;
  stepChildId?: string;
  stepChildNumber?: number;
  stepId?: string;
  stepType?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: any | null;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
}
export interface HomeContentUpdated {
  /**
   * sponsor's account id
   */
  sponsorAccountId: number;
  /**
   * reset | update
   */
  type: string;
  /**
   * array of updated properties, i.e. WhatsNewContent
   */
  updatedProperties?: any[];
}
export interface InputsBenchmarkGroupCreated {
  /**
   * Common benchmark, in format { name: string, id: string }, if COMMON selected
   */
  benchmark?: Record<string, any>;
  /**
   * Name of the subject group
   */
  name: string;
  /**
   * Whether "relative to benchmark" setting is selected
   */
  relative?: boolean;
  /**
   * STUDIO, REPORT_LAB
   */
  sourcePage: string;
  /**
   * COMMON | INDIVIDUAL | NONE
   */
  type: string;
  /**
   * id of view being edited or undefined
   */
  viewId?: string;
}
export interface InputsBenchmarkGroupDeleted {
  /**
   * Common benchmark, in format { name: string, id: string }, if COMMON selected
   */
  benchmark?: Record<string, any>;
  /**
   * Name of the subject group
   */
  name: string;
  /**
   * Whether "relative to benchmark" setting is selected
   */
  relative?: boolean;
  /**
   * STUDIO, REPORT_LAB
   */
  sourcePage: string;
  /**
   * COMMON | INDIVIDUAL | NONE
   */
  type: string;
  /**
   * id of view being edited or undefined
   */
  viewId?: string;
}
export interface InputsBenchmarkGroupModified {
  /**
   * Common benchmark, in format { name: string, id: string }, if COMMON selected
   */
  benchmark?: Record<string, any>;
  /**
   * Name of the subject group
   */
  name: string;
  /**
   * Whether "relative to benchmark" setting is selected
   */
  relative?: boolean;
  /**
   * STUDIO, REPORT_LAB
   */
  sourcePage: string;
  /**
   * COMMON | INDIVIDUAL | NONE
   */
  type: string;
  /**
   * id of view being edited or undefined
   */
  viewId?: string;
}
export interface InputsDateRangeGroupCreated {
  /**
   * Date range consistency setting GLOBAL | BLOCK | INPUT
   */
  consistency?: string;
  /**
   * End date of a manually selected period, or undefined
   */
  endDate?: string;
  /**
   * Name of the subject group
   */
  name: string;
  /**
   * Predefined date range setting (YTD | 1YR | 3YR | 5YR | FULL_HISTORY), or undefined
   */
  range?: string;
  /**
   * STUDIO, REPORT_LAB
   */
  sourcePage: string;
  /**
   * Start date of a manually selected period, or undefined
   */
  startDate?: string;
  /**
   * id of view being edited or undefined
   */
  viewId?: string;
}
export interface InputsDateRangeGroupDeleted {
  /**
   * Date range consistency setting GLOBAL | BLOCK | INPUT
   */
  consistency?: string;
  /**
   * End date of a manually selected period, or undefined
   */
  endDate?: string;
  /**
   * Name of the subject group
   */
  name: string;
  /**
   * Predefined date range setting (YTD | 1YR | 3YR | 5YR | FULL_HISTORY), or undefined
   */
  range?: string;
  /**
   * STUDIO, REPORT_LAB
   */
  sourcePage: string;
  /**
   * Start date of a manually selected period, or undefined
   */
  startDate?: string;
  /**
   * id of view being edited or undefined
   */
  viewId?: string;
}
export interface InputsDateRangeGroupModified {
  /**
   * Date range consistency setting GLOBAL | BLOCK | INPUT
   */
  consistency?: string;
  /**
   * End date of a manually selected period, or undefined
   */
  endDate?: string;
  /**
   * Name of the subject group
   */
  name: string;
  /**
   * Predefined date range setting (YTD | 1YR | 3YR | 5YR | FULL_HISTORY), or undefined
   */
  range?: string;
  /**
   * STUDIO, REPORT_LAB
   */
  sourcePage: string;
  /**
   * Start date of a manually selected period, or undefined
   */
  startDate?: string;
  /**
   * id of view being edited or undefined
   */
  viewId?: string;
}
export interface InputsSubjectGroupCreated {
  /**
   * Name of the subject group
   */
  name: string;
  /**
   * STUDIO, REPORT_LAB
   */
  sourcePage: string;
  /**
   * Subjects in this group in format: { type: "investment" | "portfolio", id: string | number, name: string }
   */
  subjects: any[];
  /**
   * id of view being edited or undefined
   */
  viewId?: string;
}
export interface InputsSubjectGroupDeleted {
  /**
   * Name of the subject group
   */
  name: string;
  /**
   * STUDIO, REPORT_LAB
   */
  sourcePage: string;
  /**
   * Subjects in this group in format: { type: "investment" | "portfolio", id: string | number, name: string }
   */
  subjects: any[];
  /**
   * id of view being edited or undefined
   */
  viewId?: string;
}
export interface InputsSubjectGroupModified {
  /**
   * Name of the subject group
   */
  name: string;
  /**
   * STUDIO, REPORT_LAB
   */
  sourcePage: string;
  /**
   * Subjects in this group in format: { type: "investment" | "portfolio", id: string | number, name: string }
   */
  subjects: any[];
  /**
   * id of view being edited or undefined
   */
  viewId?: string;
}
export interface InvestmentMetadataSaved {
  /**
   * True if the category is predicted by Venn
   */
  categorizerPredicted?: boolean;
  /**
   * names of fields modified
   */
  fieldsModified?: any[];
  /**
   * investment id
   */
  investmentId?: string;
  /**
   * true if it's user uploaded investment (fires in return update)
   */
  userUploaded?: boolean;
}
export interface InvestmentsUploaded {
  /**
   * returns or navs
   */
  dataType?: string;
  /**
   * upload error number
   */
  numberOfDiscarded?: number;
  /**
   * updated fund number
   */
  numberOfExisting?: number;
  /**
   * new fund number + updated fund number
   */
  numberOfInvestments?: number;
  /**
   * new fund number
   */
  numberOfNew?: number;
  /**
   * 'file', 'copy-paste' and 'samplefile'
   */
  uploadType?: string;
}
export interface InvitationAction {
  /**
   * email of the invitation
   */
  email: string;
  /**
   * true if the user is admin
   */
  isWorkspaceAdmin?: boolean;
  /**
   * Revoked, Resent, Removed
   */
  type: string;
}
export interface KeyboardShortcutUsed {
  /**
   * such as 'color scheme picker', 'search'
   */
  shortcutPurpose?: string;
  /**
   * such as 'c', 's'
   */
  shortcutString?: string;
}
export interface PerformanceFiltersItem {
  /**
   * the performance field name
   */
  field: string;
  /**
   * the maximum value for the filter
   */
  maximum?: number;
  /**
   * the minimum value for the filter
   */
  minimum?: number;
}
export interface LibraryFiltersSelected {
  /**
   * asset types selected
   */
  assetTypeFilters?: any[];
  /**
   * the currencies selected
   */
  currencyFilters?: string[];
  /**
   * data source selected
   */
  dataSourceFilters?: string[];
  /**
   * Corresponds to LibraryTab, indicated whether it was public or private library
   */
  libraryTab?: string;
  /**
   * the metric fields on which there is a filter applied
   */
  metricFilters?: string[];
  /**
   * the performance fields and their filter values
   */
  performanceFilters?: PerformanceFiltersItem[];
  /**
   * the quick filters selected (eg, requires attention, uploaded by me)
   */
  quickFilters: string[];
  /**
   * the tags selected
   */
  tagFilters: string[];
  /**
   * the item types filtered
   */
  typeFilters: string[];
}
export interface LinkClicked {
  /**
   * URL value
   */
  destinationUrl: string;
  /**
   * The text displayed
   */
  label: string;
  /**
   * Position on page, parent component, etc...
   */
  locationOnPage: string;
  /**
   * Id of the sponsor, if the account is sponsored
   */
  sponsorId?: number;
}
export interface MeteredInvestmentsViewed {
  /**
   * trigger place such as 'navbar', 'Comparison Page error button', 'Factor Insights error button', or 'Analysis Page error button'
   */
  triggerName?: string;
}
export interface ModalClosed {
  /**
   * if user can't the modal
   */
  cancelled?: boolean;
  /**
   * such as 'invite modal'
   */
  topic?: string;
}
export interface ModalDisplayed {
  /**
   * such as 'invite modal'
   */
  topic?: string;
}
export interface ModifiedInvestmentOverride {
  currentState: string;
  investmentId: string;
  previousState: string;
}
export interface Company {
  id?: number;
  name?: string;
}
export interface User {
  _ABGroup?: number;
  _appcuesId?: string;
  _currentPageTitle?: string;
  _currentPageUrl?: string;
  _doNotTrack?: boolean;
  _hostname?: string;
  _lastBrowserLanguage?: string;
  _lastPageTitle?: string;
  _lastPageUrl?: string;
  _localId?: string;
  _myAppcuesId?: any | null;
  _sessionPageviews?: number;
  _sessionRandomizer?: number;
  _testContentId?: any | null;
  _updatedAt?: number;
  _userAgent?: string;
  accountTier?: string;
  actualUser?: boolean;
  company?: Company;
  email?: string;
  firstName?: string;
  id?: string;
  isAccountAdmin?: boolean;
  lastName?: string;
  organizationID?: number;
  organizationId?: number;
  organizationName?: string;
  trialStatus?: string;
  userId?: string;
  workspaceId?: number;
  workspaceName?: string;
}
export interface NpsAskMeLaterSelectedAtAppcues {
  askMeLaterSelectedAt?: number;
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  name?: string;
  sessionId?: string;
  timestamp?: number;
  user?: User;
}
export interface Company1 {
  id?: number;
  name?: string;
}
export interface User1 {
  _ABGroup?: number;
  _appcuesId?: string;
  _currentPageTitle?: string;
  _currentPageUrl?: string;
  _doNotTrack?: boolean;
  _hostname?: string;
  _lastBrowserLanguage?: string;
  _lastPageTitle?: string;
  _lastPageUrl?: string;
  _localId?: string;
  _myAppcuesId?: any | null;
  _sessionPageviews?: number;
  _sessionRandomizer?: number;
  _testContentId?: any | null;
  _updatedAt?: number;
  _userAgent?: string;
  accountTier?: string;
  actualUser?: boolean;
  company?: Company1;
  email?: string;
  firstName?: string;
  id?: string;
  isAccountAdmin?: boolean;
  lastName?: string;
  organizationID?: number;
  organizationId?: number;
  organizationName?: string;
  trialStatus?: string;
  userId?: string;
  workspaceId?: number;
  workspaceName?: string;
}
export interface NpsScoreAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  name?: string;
  score?: number;
  sessionId?: string;
  timestamp?: number;
  user?: User1;
}
export interface Company2 {
  id?: number;
  name?: string;
}
export interface User2 {
  _ABGroup?: number;
  _appcuesId?: string;
  _currentPageTitle?: string;
  _currentPageUrl?: string;
  _doNotTrack?: boolean;
  _hostname?: string;
  _lastBrowserLanguage?: string;
  _lastPageTitle?: string;
  _lastPageUrl?: string;
  _localId?: string;
  _myAppcuesId?: any | null;
  _sessionPageviews?: number;
  _sessionRandomizer?: number;
  _testContentId?: any | null;
  _updatedAt?: number;
  _userAgent?: string;
  accountTier?: string;
  actualUser?: boolean;
  company?: Company2;
  email?: string;
  firstName?: string;
  id?: string;
  isAccountAdmin?: boolean;
  lastName?: string;
  organizationID?: number;
  organizationId?: number;
  organizationName?: string;
  trialStatus?: string;
  userId?: string;
  workspaceId?: number;
  workspaceName?: string;
}
export interface NpsSurveyStartedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  name?: string;
  sessionId?: string;
  timestamp?: number;
  user?: User2;
}
export interface NavigationTriggered {
  /**
   * such as 'Factor Homepage',
   */
  destinationPageTitle?: string;
  /**
	 * dropdown, link

	 */
  itemType?: string;
  /**
	 * 
'breadcrumb', 'navigation bar', 'search bar'
	 */
  location?: string;
  /**
	 * 'navigate', 'change analysis', 'change object', 'learn about factors'

	 */
  userIntent?: string;
}
export interface NotablePeriodAdded {
  /**
   * Name of Venn Notable Period Added
   */
  periodName?: string;
}
export interface NotablePeriodViewed {
  /**
   * startDate-endDate
   */
  dateRange?: string;
  /**
   * factor name
   */
  factor?: string;
  /**
   * such as 'chart'
   */
  viewType?: string;
}
export interface PeerGroupSelected {
  /**
   * The id of the peer group selected
   */
  peerGroupId: string;
  /**
   * The display name of the peer group selected
   */
  peerGroupName?: string;
}
export interface PortfolioComparisonSelected {
  /**
   * master | optimized | saved (for historical versions) | last saved (for the most recently saved version) | none
   */
  compareType: string;
  /**
   * YYYY-MM-DD HH:mm formated date of when the "saved" version was last updated (if historical versions are available), null if other compareType selected
   */
  historicalUpdatedTime?: string;
  /**
   * id of the primary portfolio subject
   */
  portfolioId?: number;
  /**
   * name of the primary portfolio subject
   */
  portfolioName?: string;
}
export interface PortfolioLabConstraintsModified {
  /**
   * Number of allocation constraints
   */
  allocationConstraintsCount?: number;
  /**
   * Id of benchmark, if present
   */
  benchmarkId?: string;
  /**
   * 'target' | 'allocation' | 'factor'
   */
  constraintType?: string;
  /**
   * True if optimizing with portfolio policy constraints only
   */
  constraintsMatchPolicy?: boolean;
  /**
   * Number of factor exposure constraints
   */
  factorConstraintsCount?: number;
  /**
   * The source component of the change
   */
  locationOnPage?: string;
  /**
   * Fund ids of new opportunities included in current constraints
   */
  newOpportunities?: any[];
  /**
   * The name of the page where the setting was selected (i.e. Library if we're bulk-optimizing investments from there)
   */
  originPage?: string;
  /**
   * Id of strategy that the new opportunities are added to
   */
  parentStrategyId?: number;
  /**
   * Id of portfolio being optimized
   */
  portfolioId?: number;
  /**
   * Return constraint, i.e. 0.25, if targeting return of 25% with minimum volatility
   */
  returnConstraint?: number;
  /**
   * 'maximizeReturn' | 'targetReturn' | 'maximizeSharpe'
   */
  target?: string;
  /**
   * Volatility constraint, i.e. 0.25, if optimizing with volatility less than 25%
   */
  volatilityConstraint?: number;
}
export interface PortfolioLabMigrationAccepted {
  /**
   * 'modal' | 'top bar' | 'tutorial' | 'banner' | ...
   */
  component: string;
  /**
   * Source page of the action
   */
  source: string;
}
export interface PortfolioLabMigrationDismissed {
  /**
   * 'modal' | 'top bar' | 'tutorial' | 'banner' | ...
   */
  component: string;
  /**
   * i.e. 3, if it's the third time this user dismissed the migration message
   */
  dismissCounter?: number;
  /**
   * Source page of the action
   */
  source: string;
}
export interface PortfolioLabSolutionSaved {
  /**
   * 'Save and Tag' | 'Analyze' | 'Add to compare'
   */
  action?: string;
  /**
   * Id of the portfolio being optimized
   */
  basePortfolioId?: number;
  /**
   * True if it's the main optimization result, false if one of the alternate solutions
   */
  isOptimal?: boolean;
  /**
   * Id of the solution portfolio being saved
   */
  optimizedPortfolioId?: number;
}
export interface PortfolioLabSolutionViewed {
  /**
   * Number of allocation constraints met
   */
  allocationConstraintsMet?: number;
  /**
   * Number of allocation constraints NOT met
   */
  allocationConstraintsNotMet?: number;
  /**
   * 'Current' | 'Optimized' | 'Alternate #1' | 'Alternate #2' | ...
   */
  category?: string;
  /**
   * Number of factor constraints met
   */
  factorConstraintsMet?: number;
  /**
   * Number of factor constraints NOT met
   */
  factorConstraintsNotMet?: number;
  /**
   * True if ret/vol constraint is met
   */
  objectiveConstraintMet?: boolean;
  /**
   * Solution forecasted return
   */
  return?: number;
  /**
   * Return constraint, i.e. 0.25, if targeting return of 25% with minimum volatility
   */
  returnConstraint?: number;
  /**
   * 'maximizeReturn' | 'targetReturn' | 'maximizeSharpe'
   */
  target?: string;
  /**
   * Solution forecasted volatility
   */
  volatility?: number;
  /**
   * Volatility constraint, i.e. 0.25, if optimizing with volatility less than 25%
   */
  volatilityConstraint?: number;
}
export interface FactorConstraintsItem {
  /**
   * factor name
   */
  factorName?: string;
  /**
   * max exposure
   */
  maxExposure?: number;
  /**
   * min exposure
   */
  minExposure?: number;
}
export interface PortfolioOptimized {
  /**
   * Analysis Results or "Venn - Analysis Results"
   */
  entryPoint?: string;
  /**
   * Factor constraint list
   */
  factorConstraints?: FactorConstraintsItem[];
  /**
   * true if master portfolio
   */
  isMaster?: boolean;
  /**
   * when select Maximize Returns, provide max volatility value
   */
  maxVolatility?: number | null;
  /**
   * when select Target Returns, provide min return value
   */
  minReturn?: any | null;
  /**
   * true if user has modified default allocation constraints
   */
  modifiedAllocationConstraints?: boolean;
  /**
   * true if user has modified factor allocation constraints
   */
  modifiedFactorConstraints?: boolean;
  /**
   * Target Returns, Maximize Returns
   */
  optimizationObjective?: string;
  /**
   * portfolio id
   */
  portfolioId?: number;
  /**
   * 0 if it's root portfolio
   */
  portfolioLevelSelected?: number;
  /**
   * if any strategy selected
   */
  strategyId?: any | null;
  /**
   * achieved goal, best possible
   */
  successType?: string;
  /**
   * true if user open allocation constraints
   */
  viewedAllocationConstraints?: boolean;
  /**
   * true if user open the factor constraints
   */
  viewedFactorConstraints?: boolean;
  /**
   * true if find result
   */
  wasSuccessful?: boolean;
}
export interface PortfolioPolicyModified {
  /**
   * Array of constraints containing a list of subject ids, constraint type, value type and value
   */
  allocationConstraints?: any[];
  /**
   * Array of constraints containing factor id, constraint type and value
   */
  factorConstraints?: any[];
  /**
   * True if any allocation constraint is for "all investments"
   */
  hasAllInvestmentsConstraints?: boolean;
  /**
   * True if any allocation constraint has listed investments
   */
  hasInvestmentConstraints?: boolean;
  /**
   * True if any allocation constraint has listed strategies
   */
  hasStrategyConstraints?: boolean;
  /**
   * Where the policy was modified from, i.e. "Manage Data" / "Portfolio Lab inputs side bar"
   */
  location?: string;
}
export interface PortfolioRebalancingUpdated {
  /**
   * Id of the portfolio
   */
  portfolioId: number;
  /**
   * Name of the portfolio
   */
  portfolioName: string;
  /**
   * The rebalance strategy the portfolio is changing to
   */
  rebalanceStrategy: string;
}
export interface PortfolioSaved {
  /**
   * startDate-endDate
   */
  dateRange?: string;
  /**
   * true if portfolio has active benchmark
   */
  hasBenchmark?: boolean;
  /**
   * true if portfolio is master portfolio
   */
  isMaster?: boolean;
  /**
   * portfolio id
   */
  portfolioId?: number;
}
export interface PortfolioSetupStepCompleted {
  /**
   * Save and View
   */
  stepDescription?: string;
}
export interface PortfolioSetupStepViewed {
  /**
   * Add Investments, Add Strategies, Edit Portfolio, Add Allocations, Save and View'
   */
  stepDescription?: string;
}
export interface ProRequested {
  /**
   * true if the user doesn't on trial before
   */
  trialAvailable?: boolean;
}
export interface ProfileEdited {
  /**
   * First name, last name, display name, avatar
   */
  fieldsChanged?: string;
}
export interface ProxyModified {
  /**
   * whether the user is applying extrapolation
   */
  extrapolate?: boolean;
  /**
   * id of the fund being proxied
   */
  fundId: string;
  /**
   * number of lags used for desmoothing
   */
  numLags?: number;
  /**
   * proxy category
   */
  proxyCategory?: string;
  /**
   * id of the fund used for proxying
   */
  proxyId?: string;
  /**
   * type of the proxy
   */
  proxyType?: string;
}
export interface RemovedFromWorkspace {
  /**
   * Was the remover an admin?
   */
  isWorkspaceAdmin?: boolean;
  /**
   * Id of the org to be removed from
   */
  orgId: number;
  /**
   * userId of the removed user
   */
  userId: number;
}
export interface ReportLabExport {
  /**
   * number of blocks
   */
  blockCount?: number;
  /**
   * all exported blocks
   */
  blocks?: any[];
  /**
   * If there was any sensitivity blocks that contained macro scenarios
   */
  containsMacroScenarios?: boolean;
  /**
   * If there was any sensitivity blocks that contained market scenarios
   */
  containsMarketScenarios?: boolean;
  /**
   * PDF, XLS, PNG, PrintPreview
   */
  exportTo?: string;
  /**
   * whether export was a final copy or a draft (this is the opposite of isInternal: internal copies are drafts)
   */
  isFinal?: boolean;
  /**
   * whether export failed or succeed
   */
  isSuccessful?: boolean;
  /**
   * number of portfolio comparison blocks in the view
   */
  numPortfolioComparisonBlocks?: number;
  /**
   * number of portfolio comparison blocks in the view with custom metrics
   */
  numPortfolioComparisonBlocksWithCustomMetrics?: number;
  /**
   * portrait, landscape
   */
  printOrientation?: string;
  /**
   * the names of any funds in any sensitivity analysis blocks in the export
   */
  scenarioFundNames?: any[];
  /**
   * ids of exported subjects
   */
  subjectIds?: any[];
  /**
   * number of unlinked blocks
   */
  unlinkedBlocks?: number;
  /**
   * id of the reportlab view
   */
  viewId?: string;
}
export interface ReportLabOpened {
  /**
   * number of blocks
   */
  blockCount?: number;
  /**
   * all blocks in the view
   */
  blocks?: any[];
  /**
   * If there was any sensitivity blocks that contained macro scenarios
   */
  containsMacroScenarios?: boolean;
  /**
   * If there was any sensitivity blocks that contained market scenarios
   */
  containsMarketScenarios?: boolean;
  /**
   * If the view is a template
   */
  isTemplate?: boolean;
  /**
   * number of portfolio comparison blocks in the view
   */
  numPortfolioComparisonBlocks?: number;
  /**
   * number of portfolio comparison blocks with custom metrics
   */
  numPortfolioComparisonBlocksWithCustomMetrics?: number;
  /**
   * the names of any funds in any sensitivity analysis blocks in the export
   */
  scenarioFundNames?: any[];
  /**
   * the id of the studio view
   */
  viewId?: string;
}
export interface ResetFontSizeToWorkspaceDefaults {
  blockId: string;
  blockType: string;
  fontSize: number;
  fontType: string;
  viewId?: string;
}
export interface ResetViewFontSizes {
  viewId?: string;
}
export interface RoleChanged {
  /**
   * role that was changed to
   */
  role: string;
  /**
   * userId of user whose role was changed
   */
  userId: number;
}
export interface SavedNewInvestmentOverride {
  currentState?: string;
  investmentId: string;
}
export interface ScenarioCreated {
  entryInRange?: boolean;
  entryReturn?: number;
  /**
   * analysis page, studio or report lab
   */
  location?: string;
  scenarioIndexName?: string;
  scenarioReturn?: number;
  scenarioStd?: number;
  /**
   * macro or market
   */
  type?: string;
}
export interface ScenarioDeleted {
  entryInRange?: boolean;
  entryReturn?: number;
  /**
   * analysis page, studio or report lab
   */
  location?: string;
  scenarioIndexName?: string;
  scenarioReturn?: number;
  scenarioStd?: number;
  /**
   * macro or market
   */
  type?: string;
}
export interface ScenarioModified {
  entryInRange?: boolean;
  entryReturn?: number;
  /**
   * analysis page, studio or report lab
   */
  location?: string;
  scenarioIndexName?: string;
  scenarioReturn?: number;
  scenarioStd?: number;
  /**
   * macro or market
   */
  type?: string;
}
export interface SearchDismissed {
  /**
   * location of the search in the app
   */
  location: string;
  /**
   * the search query text
   */
  query?: string;
  /**
   * the quick filters applied (eg, recently analyzed, requires attention)
   */
  quickFilters?: any[];
  /**
   * the number of total results available for the search query
   */
  totalResults?: number;
  /**
   * which types are allowed - eg, Investments, Portfolios, Benchmarks
   */
  typeFilters?: any[];
  /**
   * the number of results visible to the user
   */
  visibleResults?: number;
}
export interface SearchMultiSelectionMade {
  /**
   * number of selected items
   */
  countItems: number;
  /**
   * number of items in the “cart” that were tagged
   */
  countItemsFromTag?: number;
  /**
   * data source e.g. user uploaded, BNY, northern trust, venn provided, Returns Inbox
   */
  createdBy?: any[];
  /**
   * comparison, allocator panel, portfolio labs
   */
  location: string;
  /**
   * e.g. Investment, Portfolio, etc
   */
  objectType?: any[];
  /**
   * the search query
   */
  query: string;
  /**
   * the quick filters applied (eg, recently analyzed, requires attention)
   */
  quickFilters?: any[];
  /**
   * the number of visible results shown to the user
   */
  totalResults: number;
  /**
   * which types are allowed - eg, Investments, Portfolios, Benchmarks
   */
  typeFilters?: any[];
  /**
   * data source e.g. user uploaded, BNY, northern trust, venn provided, Returns Inbox
   */
  updatedBy?: any[];
}
export interface SearchQueryEntered {
  /**
   * asset types selected
   */
  assetTypeFilters?: any[];
  /**
   * the currencies selected
   */
  currencyFilters?: string[];
  /**
   * data source selected
   */
  dataSourceFilters?: string[];
  /**
   * values of the filters (archive page only)
   */
  filterValues?: Record<string, any>;
  /**
   * location on the site that the search was made
   */
  location: string;
  /**
   * the metric fields on which there is a filter applied
   */
  metricFilters?: string[];
  /**
   * the performance fields on which there is a filter applied
   */
  performanceFilters?: string[];
  /**
   * the search query
   */
  query: string;
  /**
   * the quick filters applied (eg, recently analyzed, requires attention)
   */
  quickFilters?: any[];
  /**
   * the tags applied to the search
   */
  tagFilters?: any[];
  /**
   * the number of total results available for the search query
   */
  totalResults?: number;
  /**
   * which types are allowed - eg, Investments, Portfolios, Benchmarks
   */
  typeFilters?: any[];
  /**
   * the number of results visible to the user
   */
  visibleResults: number;
}
export interface SearchSelectionMade {
  /**
   * "Sharpe Ratio Ascending"
   */
  activeSort?: string;
  /**
   * asset types to filter on
   */
  assetTypeFilters?: any[];
  /**
   * name of custodian, user uploaded, venn, returns inbox
   */
  createdBy: string;
  /**
   * the currencies selected
   */
  currencyFilters?: string[];
  /**
   * location on the site that the search was made
   */
  location: string;
  /**
   * the metric fields on which there is a filter applied
   */
  metricFilters?: string[];
  /**
   * id of the item selected
   */
  objectId?: any | null;
  /**
   * investment, portfolio...
   */
  objectType: string;
  /**
   * the performance fields on which there is a filter applied
   */
  performanceFilters?: string[];
  /**
   * the search query
   */
  query: string;
  /**
   * if there is a search query
   */
  queryDriven: boolean;
  /**
   * the quick filters applied (eg, recently analyzed, requires attention)
   */
  quickFilters?: any[];
  /**
   * order that the selected appeared in (0 for first item)
   */
  rank: number;
  /**
   * the tags applied to the search
   */
  tagFilters?: any[];
  /**
   * the number of total results available for the search query
   */
  totalResults?: number;
  /**
   * which types are allowed - eg, Investments, Portfolios, Benchmarks
   */
  typeFilters?: any[];
  /**
   * the number of visible results shown to the user
   */
  visibleResults?: number;
}
export interface SignupFunnelTerminated {
  /**
   * password creation
   */
  stepDescription?: string;
}
export interface SignupStepCompleted {
  /**
   * 'account details' or 'password creation'
   */
  stepDescription?: any | null;
}
export interface SignupStepViewed {
  /**
   * 'account details' or 'password creation'
   */
  stepDescription?: any | null;
}
export interface StepCompletedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  name?: string;
  sessionId?: string;
  stepId?: string;
  stepType?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: string;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface StepInteractedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  interaction?: Record<string, any>;
  'interaction.category'?: string;
  'interaction.destination'?: string;
  'interaction.element'?: any[];
  'interaction.element.$'?: Record<string, any>;
  'interaction.element.$.order_filter'?: number;
  'interaction.element.$.selector'?: string;
  interactionType?: string;
  name?: string;
  sessionId?: string;
  stepChildId?: string;
  stepChildNumber?: number;
  stepId?: string;
  stepType?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: string;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface StepSkippedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  name?: string;
  sessionId?: string;
  stepChildId?: string;
  stepChildNumber?: number;
  stepId?: string;
  stepType?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: any | null;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface StepStartedAppcues {
  flowId?: string;
  flowName?: string;
  flowType?: string;
  flowVersion?: number;
  id?: string;
  name?: string;
  sessionId?: string;
  stepId?: string;
  stepType?: string;
  timestamp?: number;
  user?: Record<string, any>;
  'user._ABGroup'?: number;
  'user._appcuesId'?: string;
  'user._currentPageTitle'?: string;
  'user._currentPageUrl'?: string;
  'user._doNotTrack'?: boolean;
  'user._hostname'?: string;
  'user._lastBrowserLanguage'?: string;
  'user._lastPageTitle'?: string;
  'user._lastPageUrl'?: string;
  'user._localId'?: string;
  'user._myAppcuesId'?: string;
  'user._sessionPageviews'?: number;
  'user._sessionRandomizer'?: number;
  'user._testContentId'?: any | null;
  'user._updatedAt'?: number;
  'user._userAgent'?: string;
  'user.accountStatus'?: string;
  'user.accountTier'?: string;
  'user.actualUser'?: boolean;
  'user.company'?: Record<string, any>;
  'user.company.id'?: number;
  'user.company.name'?: string;
  'user.email'?: string;
  'user.firstName'?: string;
  'user.id'?: number;
  'user.isAccountAdmin'?: boolean;
  'user.lastName'?: string;
  'user.organizationID'?: number;
  'user.organizationId'?: number;
  'user.organizationName'?: string;
  'user.trialStatus'?: string;
  'user.userEmail'?: string;
  'user.userId'?: number;
  'user.userName'?: string;
  'user.workspaceId'?: number;
  'user.workspaceName'?: string;
}
export interface StudioExport {
  /**
   * number of blocks
   */
  blockCount?: number;
  /**
   * All exported blocks
   */
  blocks?: any[];
  /**
   * If there was any sensitivity blocks that contained macro scenarios
   */
  containsMacroScenarios?: boolean;
  /**
   * If there was any sensitivity blocks that contained market scenarios
   */
  containsMarketScenarios?: boolean;
  /**
   * PDF, XLS, PNG, PrintPreview
   */
  exportTo?: string;
  /**
   * whether the export is a final export or draft export
   */
  isFinal?: boolean;
  /**
   * whether export failed or succeed
   */
  isSuccessful?: boolean;
  /**
   * number of portfolio comparison blocks in the view
   */
  numPortfolioComparisonBlocks?: number;
  /**
   * number of portfolio comparison blocks with custom metrics in the view
   */
  numPortfolioComparisonBlocksWithCustomMetrics?: number;
  /**
   * portrait, landscape
   */
  printOrientation?: string;
  /**
   * the names of any funds in any sensitivity analysis blocks in the export
   */
  scenarioFundNames?: any[];
  /**
   * ids of exported subjects
   */
  subjectIds?: any[];
  /**
   * Number of unlinked blocks
   */
  unlinkedBlocks?: number;
  /**
   * id of the studio view
   */
  viewId?: string;
}
export interface StudioOpened {
  /**
   * number of blocks
   */
  blockCount?: number;
  /**
   * all blocks in the view
   */
  blocks?: any[];
  /**
   * If there was any sensitivity blocks that contained macro scenarios
   */
  containsMacroScenarios?: boolean;
  /**
   * If there was any sensitivity blocks that contained market scenarios
   */
  containsMarketScenarios?: boolean;
  /**
   * if the view is a template
   */
  isTemplate?: boolean;
  /**
   * number of portfolio comparison blocks in the view
   */
  numPortfolioComparisonBlocks?: number;
  /**
   * number of portfolio comparison blocks in the view with custom metrics
   */
  numPortfolioComparisonBlocksWithCustomMetrics?: number;
  /**
   * the names of any funds in any sensitivity analysis blocks in the export
   */
  scenarioFundNames?: any[];
  /**
   * the id of the studio view
   */
  viewId?: string;
}
export interface TextFieldUpdated {
  /**
   * The location on page, e.g. "Allocator Panel"
   */
  locationOnPage?: string;
}
export interface ToggleToggled {
  /**
   * (Same as for `Cta Clicked`)
   */
  locationOnPage?: string;
  /**
   * What should this toggle do?
   */
  purpose?: string;
  /**
   * What option did the user select?
   */
  selection?: string;
}
export interface UpgradeModalViewed {
  /**
   * true if user hasn't done trial before
   */
  trialAvailable?: boolean;
  /**
   * "Analysis Limit Reached", ""Venn Pro Block", ""Unlock Portfolio Analysis", etc
   */
  triggerName?: string;
}
export interface UploadAttempted {
  /**
   * 'returns', 'navs'
   */
  dataType?: string;
  /**
   * 'file', 'copy-paste', 'samplefile'
   */
  type?: string;
}
export interface UploadStepCompleted {
  /**
   * 'returns', 'navs'
   */
  dataType?: string;
  /**
   * 0, 1
   */
  step?: number;
  /**
   * 'file', 'copy-paste', 'samplefile'
   */
  uploadType?: string;
}
export interface UploadStepFailed {
  /**
   * 'returns', 'navs'
   */
  dataType?: string;
  /**
   * error message
   */
  error?: string;
  /**
   * review step's error
   */
  errorCounts?: number[];
  /**
   * review step's error type
   */
  errorTypes?: string[];
  /**
   * 0, 1
   */
  step?: number;
}
export interface UploadStepViewed {
  /**
   * 'returns', 'navs'
   */
  dataType?: string;
  /**
   * 0, 1
   */
  step?: number;
  /**
   * 'file', 'copy-paste', 'samplefile'
   */
  uploadType?: any | null;
}
export interface UploadTabChanged {
  /**
   * True if the user was on the Nav uploader
   */
  Nav?: boolean;
  /**
   * The name of the tab that was changed to
   */
  TabName: string;
  /**
   * Whether this event was fired from public or private uploader
   */
  isPrivatesUploader?: boolean;
}
export interface UserCreated {
  /**
   * 'invited', 'organic'
   */
  source?: string;
}
export interface UsersInvited {
  /**
   * in-app
   */
  entryPoint?: string;
  /**
   * emails number
   */
  numberOfInvites?: number;
}
export interface VideoPlaying {
  /**
   * Where on the page was the video launcher placed
   */
  locationOnPage?: string;
  /**
   * The page where the video was played on
   */
  pageName?: string;
  /**
   * Seconds into video
   */
  timeElapsed?: number;
  /**
   * Length of video in seconds
   */
  timeTotal?: number;
  /**
   * Title of the video
   */
  videoTitle: string;
}
export interface EnrichmentProvider {
  product?: string;
  type?: string;
}

export type ViolationHandler = (message: Record<string, any>, violations: any[]) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2,
  );

  console.warn(msg);
};

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '7.0.1',
      },
    },
  };
}

/**
 * @typedef AllocatorInvestmentsChanged
 * @property {string[]} [addedInvestmentIds] - IDs of added investments
 * @property {boolean} [isPrivateAllocatorPanel] - whether this is private allocator panel
 * @property {string} [portfolioId] - ID of a portfolio opened in allocator panel
 * @property {string[]} [removedInvestmentIds] - IDs of removed investments
 */
/**
 * @typedef AllocatorPanelOpened
 * @property {boolean} [isPrivateAllocatorPanel] - whether this is private allocator panel
 * @property {string} [portfolioId] - ID of a portfolio opened in allocator panel
 */
/**
 * @typedef AnalysisFailed
 * @property {string} [analysisName] - name of analysis template
 * @property {any[]} [blocks] - failed blocks in the analysis template
 * @property {string} [dateRange] - startDate-endDate
 * @property {string} [frequency] - max frequency from backend analysis response
 * @property {boolean} [hasBenchmark] - true if investment has active benchmark
 * @property {boolean} [hasProxy] - true if the investment has a proxy
 * @property {string} [objectType] - investment or portfolio
 * @property {boolean} [overThreshold] - For metered accounts, analysis failed because they didn't have bandwidth to run analysis
 * @property {boolean} [relativeToBenchmark] - true if relative to benchmark
 */
/**
 * @typedef AnalysisPerformed
 * @property {string | null} [allocationType] - $ or % for portfolio allocation
 * @property {any[]} [analysisNames] - names of analyses blocks included
 * @property {string} [dateRange] - StartDate-EndDate of analysis range
 * @property {number} [factorLensId] - Frontend request Factor lens Id used for the analysis
 * @property {string} [frequency] - Backend response of max frequency for the analysis
 * @property {boolean} [hasBenchmark] - true if the investment/portfolio has active benchmark
 * @property {boolean} [hasProxy] - true if the investment has proxy
 * @property {boolean} [isCustomTemplate] - true if the template is not system template
 * @property {boolean} [isDefaultTemplate] - true if current template is user's default template
 * @property {boolean} [isInMasterPortfolio] - true if the objectType is investment and the investment is in the user's master portfolio
 * @property {boolean | null} [isMaster] - true if current portfolio is master portfolio
 * @property {any | null} [objectId] - analyzed investment or portfolio's id
 * @property {string} [objectName] - analyzed investment or portfolio's name
 * @property {string} [objectType] - investment or portfolio
 * @property {number | null} [portfolioLevelSelected] - 0 for root portfolio and each nested level +1
 * @property {boolean} [relativeToBenchmark] - true if relative to benchmark
 * @property {boolean | null} [userUploaded] - true if the investment is user uploaded
 */
/**
 * @typedef AnalysisTemplateSaved
 * @property {any[]} [analysisNames] - a list of analysis blocks name
 * @property {string} [new] - T/F - is this a brand new template that, in being saved, was created
 */
/**
 * @typedef ApplyBlockSettings
 * @property {string} [benchmark] - Common, individual or none
 * @property {string} [blockStatus] - Whether block is linked or unlinked
 * @property {string} [blockType] - What block user is editing
 * @property {string | null} [format] - Format of the block
 * @property {boolean | null} [percentageContribution] - Either on or off
 * @property {boolean} [relativeToBenchmark] - either true or false
 * @property {any[] | null} [selectedFactors] - factors selected by an user
 * @property {any[] | null} [selectedMetrics] - metrics selected by an user
 */
/**
 * @typedef BlockFontSizeChanged
 * @property {string} blockId -
 * @property {string} blockType -
 * @property {number} fontSize -
 * @property {string} fontType -
 * @property {string} [viewId] -
 */
/**
 * @typedef SubjectsItem
 * @property {string} [benchmarkId] - Benchmark id
 * @property {string} [subjectId] - Primary subject id
 */
/**
 * @typedef BuilderAnalysisPerformed
 * @property {string[]} [analysisTypes] - A list of analysis types
 * @property {string} [benchmarkType] - None, Common or Individual
 * @property {string} [builderId] - Id to identify the block type
 * @property {boolean} [contributionToPercentage] - Select contribution to percentage
 * @property {string} [dateRange] - Start and end time to run analysis
 * @property {string} [frequency] - "MONTHLY“ "DAILY"
 * @property {boolean} [relativeToBenchmark] - Select relative to benchmark
 * @property {string[]} [selectedFactors] - A list of selected factor ids
 * @property {string[]} [selectedMetrics] - a list of metrics key
 * @property {SubjectsItem[]} [subjects] - A list of subjects including benchmark
 * @property {string} [tableLayout] - standard or pivot
 * @property {boolean} [useOwnBenchmark] - individual or single benchmark
 */
/**
 * @typedef Subjects
 * @property {string[]} [privateFundIds] - List of private fund IDs
 * @property {string[]} [privatePortfolioIds] - List of private portfolio IDs
 */
/**
 * @typedef BulkActionPerformed
 * @property {string[]} [currencyFilters] - the currencies selected
 * @property {string} [libraryFilter] - [DEPRECATED] selected library filter such as 'all', 'investments', 'composite' and etc.
 * @property {string[]} [metricFilters] - the metric fields on which there is a filter applied
 * @property {number} [numberOfItems] - number of selected items
 * @property {boolean} [queryDriven] - if there is a search query
 * @property {any[]} [quickFilters] - the quick filters applied (eg, recently analyzed, requires attention)
 * @property {Subjects} [subjects] - Subjects (funds/portfolios) on which this bulk action was performed
 * @property {any[]} [tagFilters] - the tags applied to the search
 * @property {number} [totalResults] - the number of total results available for the search query
 * @property {string} [type] - bulk action type such as 'Add to Portfolio', 'Create Portfolio', 'Delete' or 'Compare'
 * @property {any[]} [typeFilters] - which types are allowed - eg, Investments, Portfolios, Benchmarks
 * @property {number} [visibleResults] - the number of results visible to the user
 */
/**
 * @typedef CashFlowSettingsApplied
 * @property {string} [fundId] - ID of the fund to which setting is being applied to
 * @property {string} [modalSubjectId] - ID of the subject for which the modal is open for (can be either a fund itself or a portfolio containing this fund)
 * @property {string} [modalSubjectType] - Denotes if modal is open for a private fund or a private portfolio
 * @property {string} [settingId] - ID of the setting
 */
/**
 * @typedef CashFlowSettingsModalOpened
 * @property {string} [source] - Source of the action - e.g. data library or studio
 * @property {string} [subjectId] - Private fund or portfolio ID
 * @property {string | null} [subjectType] - Denotes if this is a private fund or a private portfolio
 * @property {string} [triggeringFundId] - ID of the triggering fund (if preselected)
 */
/**
 * @typedef ChartModified
 * @property {string} [chart] - Which chart is it?
 * @property {string} [dateRange] - startDate-endDate
 * @property {string} [objectID] - investment or portfolio id
 * @property {string} [objectName] - investment or portfolio name
 * @property {string} [objectType] - investment or portfolio
 * @property {string} [rollingPeriod] - 1Y, 3Y, etc.; whatever option selected
 * @property {string} [venncast] - Is "Venncast missing data" toggled on?
 */
/**
 * @typedef ChecklistDismissedAppcues
 * @property {string} [checklistId] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {string} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {number} [user.userId] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef ChecklistItemCompletedAppcues
 * @property {string} [checklistId] -
 * @property {string} [checklistName] -
 * @property {string} [id] -
 * @property {string} [itemId] -
 * @property {number} [itemIndex] -
 * @property {string} [itemLabel] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {string} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.createdAt] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef ChecklistItemStartedAppcues
 * @property {string} [checklistId] -
 * @property {string} [id] -
 * @property {string} [itemId] -
 * @property {number} [itemIndex] -
 * @property {string} [itemLabel] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {string} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.createdAt] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef ChecklistShownAppcues
 * @property {string} [checklistId] -
 * @property {string} [checklistName] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {string} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.createdAt] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef ChecklistSkippedAppcues
 * @property {string} [checklistId] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {any | null} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.createdAt] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef ComparisonFailed
 * @property {boolean} [hasBenchmark] - true if there is a benchmark
 * @property {number} [numberOfSubjects] - number of compared subjects
 * @property {boolean} [overThreshold] - true if error is excess analysis limit
 * @property {boolean} [relativeToBenchmark] - true if relative to benchmark
 */
/**
 * @typedef ComparisonPerformed
 * @property {boolean} [hasBenchmark] - true if there is a benchmark
 * @property {boolean} [hasInvestments] - true if comparison list has any investments
 * @property {boolean} [hasPortfolios] - true if comparison list has any portfolios
 * @property {string} [layout] - classic | pivoted
 * @property {number} [numberOfSubjects] - number of compared subjects
 * @property {boolean} [relativeToBenchmark] - true if relative to benchmark
 */
/**
 * @typedef CookiePreferencesModified
 * @property {any[]} [cookiesAllowed] - Users accepting all cookies will have ['required', 'functional', 'advertising'], if they've restricted their access they will have a subset of these choices.
 */
/**
 * @typedef CreatingNewStudios
 * @property {string} [name] - Name of the block or template
 * @property {string} [source] - Builder, toolbar or link
 * @property {string} [type] - Either block or template
 */
/**
 * @typedef CtaClicked
 * @property {any | null} [destination] - undefined when stay on the same page
 * @property {boolean} [filled] - True when cta button is filled
 * @property {string} [locationOnPage] - Cta location such as 'Account Settings - Plan section', 'date range picker'
 * @property {string} [purpose] - Cta purpose such as 'select date range'.
 * @property {string} [subjectId] - Cta context for investment Id or portfolio id
 * @property {string} [text] - Cta text
 * @property {string} [type] - button or link
 * @property {string} [viewId] - Cta context for saved view ID
 */
/**
 * @typedef CustomMetricsCleared
 * @property {number} cellsCleared -
 * @property {string} type -
 */
/**
 * @typedef DataExported
 * @property {string} [objectType] - investment, portfolio
 * @property {string} [outputDescription] - returns, factor risk, etc.
 * @property {string} [outputFileType] - png (or other image), pdf, xlsx
 * @property {boolean} [relativeToBenchmark] - true if relative to benchmark
 * @property {string} [subjectId] - This is the ID of the investment or portfolio that the analysis was done on.
 * @property {boolean} [userUploaded] - true if the investment is user uploaded
 */
/**
 * @typedef DataManagementOpened
 * @property {string} [location] - Where the data management was trigger ie. analysis, library, etc.
 * @property {string} [objectId] - investment id or portfolio id
 * @property {string} [objectType] - portfolio, investment, etc.
 */
/**
 * @typedef DeletedInvestmentOverride
 * @property {string} investmentId -
 */
/**
 * @typedef DocumentsDownloaded
 * @property {string[]} [downloadIds] - ids of documents downloaded
 * @property {number} downloadNums - how many documents downloaded
 * @property {string} location - location on the site where documents were downloaded
 */
/**
 * @typedef DrawdownSelected
 * @property {string} [dateRange] - startDate-endDate
 * @property {string} [drawdownType] - 'vulnerability row' or 'custom date range' (deprecated)
 * @property {number} [threshold] - Threshold the user enter in the drawdown table field
 */
/**
 * @typedef DropdownClosed
 * @property {string} [label] - Dropdown label
 * @property {string} [selectType] - custom, multiselect, or single-select?
 * @property {string} [selection] - Item(s) currently selected
 * @property {string} [type] - Primary, secondary, or 'advanced filter' dropdown?
 */
/**
 * @typedef DropdownItemSSelected
 * @property {string} [label] - Dropdown label
 * @property {string} [selectType] - Multiselect or single-select?
 * @property {string} [selection] - Item(s) currently selected
 * @property {string} [type] - Primary or secondary dropdown?
 */
/**
 * @typedef DropdownOpened
 * @property {string} [label] - Dropdown label
 * @property {string} [selectType] - custom, multiselect, or single-select?
 * @property {string} [selection] - Item(s) currently selected
 * @property {string} [type] - Primary, secondary, or 'advanced filter' dropdown?
 */
/**
 * @typedef FactorInsightsFailed
 * @property {boolean} [overThreshold] - True if the error is analysis exceed limit
 */
/**
 * @typedef FactorsAnalyzed
 * @property {boolean} [comparatorSelected] - true if there are comparator subjects
 * @property {string} [dateRange] -
 * @property {boolean} [factorInputsOn] - true if factor input is on
 * @property {string[]} [factors] - selected factors name
 * @property {number} [numberOfFactors] - Selected factor length
 * @property {string} [previousPage] - Previous page title, such as "Factor Lens".
 */
/**
 * @typedef FileTemplateDownloaded
 * @property {boolean} [isPrivatesTemplate] - Whether this file is a template for public or private uploads
 * @property {string} [tag] - File tag (e.g. cumulative_private_sample_xls)
 */
/**
 * @typedef FlowCompletedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {string} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef FlowSkippedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {string} [stepId] -
 * @property {number} [stepNumber] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {any | null} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef FlowStartedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {string} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef ForecastCreationStarted
 * @property {string} forecastType - Which type of custom forecast is being created, eg, CMA or Historical
 */
/**
 * @typedef ForecastFactorOverride
 * @property {string} factor - 	
Name of the factor modified
 * @property {string} forecastId - ID of the forecast, should be same as multi_factor_forecast
 * @property {number} forecastValue - value of the new forecast
 * @property {string} updated - date of which the forecast was modified
 * @property {number} [userId] - User that updates the forecast
 */
/**
 * @typedef CmaIndicesItem
 * @property {boolean} [cashForecast] - true if forecast is cash
 * @property {string} [fundId] - investment id
 * @property {number} [returnVal] - return value
 */
/**
 * @typedef ForecastInspected
 * @property {CmaIndicesItem[]} [cmaIndices] - Array of investment forecasts
 * @property {string} [endDate] - end of the custom historical period
 * @property {string} [forecastType] - 'historical', 'two sigma', 'historical and CMAs', 'custom historical period', or 'two sigma and CMAs'
 * @property {number} [numberOfCmaFactors] - factor forecast length
 * @property {number} [numberOfCmaIndices] - investment forecast length
 * @property {string} [startDate] - start of the custom historical period
 */
/**
 * @typedef CmaIndicesItem1
 * @property {boolean} [cashForecast] - true if forecast is cash
 * @property {string} [fundId] - investment id
 * @property {number} [returnVal] - return value
 */
/**
 * @typedef ForecastSelected
 * @property {CmaIndicesItem1[]} [cmaIndices] - Array of investment forecasts
 * @property {string} [forecastType] - 'historical', 'two sigma', 'historical and CMAs', 'custom historical period', or 'two sigma and CMAs'
 * @property {number} [numberOfCmaFactors] - factor forecast length
 * @property {number} [numberOfCmaIndices] - investment forecast length
 */
/**
 * @typedef FormFieldSubmittedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {Record<string, any>} [interaction] -
 * @property {string} [interaction.category] -
 * @property {string} [interaction.fieldId] -
 * @property {boolean} [interaction.fieldRequired] -
 * @property {any | null} [interaction.fieldType] -
 * @property {any | null} [interaction.formId] -
 * @property {string} [interaction.label] -
 * @property {string} [interaction.value] -
 * @property {string} [interactionType] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {string} [stepChildId] -
 * @property {number} [stepChildNumber] -
 * @property {string} [stepId] -
 * @property {string} [stepType] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {any | null} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 */
/**
 * @typedef FormSubmittedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {Record<string, any>} [interaction] -
 * @property {string} [interaction.category] -
 * @property {any | null} [interaction.formId] -
 * @property {any[]} [interaction.response] -
 * @property {Record<string, any>} [interaction.response.$] -
 * @property {string} [interaction.response.$.fieldId] -
 * @property {boolean} [interaction.response.$.fieldRequired] -
 * @property {any | null} [interaction.response.$.fieldType] -
 * @property {string} [interaction.response.$.label] -
 * @property {string} [interaction.response.$.value] -
 * @property {string} [interactionType] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {string} [stepChildId] -
 * @property {number} [stepChildNumber] -
 * @property {string} [stepId] -
 * @property {string} [stepType] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {any | null} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 */
/**
 * @typedef HomeContentUpdated
 * @property {number} sponsorAccountId - sponsor's account id
 * @property {string} type - reset | update
 * @property {any[]} [updatedProperties] - array of updated properties, i.e. WhatsNewContent
 */
/**
 * @typedef InputsBenchmarkGroupCreated
 * @property {Record<string, any>} [benchmark] - Common benchmark, in format { name: string, id: string }, if COMMON selected
 * @property {string} name - Name of the subject group
 * @property {boolean} [relative] - Whether "relative to benchmark" setting is selected
 * @property {string} sourcePage - STUDIO, REPORT_LAB
 * @property {string} type - COMMON | INDIVIDUAL | NONE
 * @property {string} [viewId] - id of view being edited or undefined
 */
/**
 * @typedef InputsBenchmarkGroupDeleted
 * @property {Record<string, any>} [benchmark] - Common benchmark, in format { name: string, id: string }, if COMMON selected
 * @property {string} name - Name of the subject group
 * @property {boolean} [relative] - Whether "relative to benchmark" setting is selected
 * @property {string} sourcePage - STUDIO, REPORT_LAB
 * @property {string} type - COMMON | INDIVIDUAL | NONE
 * @property {string} [viewId] - id of view being edited or undefined
 */
/**
 * @typedef InputsBenchmarkGroupModified
 * @property {Record<string, any>} [benchmark] - Common benchmark, in format { name: string, id: string }, if COMMON selected
 * @property {string} name - Name of the subject group
 * @property {boolean} [relative] - Whether "relative to benchmark" setting is selected
 * @property {string} sourcePage - STUDIO, REPORT_LAB
 * @property {string} type - COMMON | INDIVIDUAL | NONE
 * @property {string} [viewId] - id of view being edited or undefined
 */
/**
 * @typedef InputsDateRangeGroupCreated
 * @property {string} [consistency] - Date range consistency setting GLOBAL | BLOCK | INPUT
 * @property {string} [endDate] - End date of a manually selected period, or undefined
 * @property {string} name - Name of the subject group
 * @property {string} [range] - Predefined date range setting (YTD | 1YR | 3YR | 5YR | FULL_HISTORY), or undefined
 * @property {string} sourcePage - STUDIO, REPORT_LAB
 * @property {string} [startDate] - Start date of a manually selected period, or undefined
 * @property {string} [viewId] - id of view being edited or undefined
 */
/**
 * @typedef InputsDateRangeGroupDeleted
 * @property {string} [consistency] - Date range consistency setting GLOBAL | BLOCK | INPUT
 * @property {string} [endDate] - End date of a manually selected period, or undefined
 * @property {string} name - Name of the subject group
 * @property {string} [range] - Predefined date range setting (YTD | 1YR | 3YR | 5YR | FULL_HISTORY), or undefined
 * @property {string} sourcePage - STUDIO, REPORT_LAB
 * @property {string} [startDate] - Start date of a manually selected period, or undefined
 * @property {string} [viewId] - id of view being edited or undefined
 */
/**
 * @typedef InputsDateRangeGroupModified
 * @property {string} [consistency] - Date range consistency setting GLOBAL | BLOCK | INPUT
 * @property {string} [endDate] - End date of a manually selected period, or undefined
 * @property {string} name - Name of the subject group
 * @property {string} [range] - Predefined date range setting (YTD | 1YR | 3YR | 5YR | FULL_HISTORY), or undefined
 * @property {string} sourcePage - STUDIO, REPORT_LAB
 * @property {string} [startDate] - Start date of a manually selected period, or undefined
 * @property {string} [viewId] - id of view being edited or undefined
 */
/**
 * @typedef InputsSubjectGroupCreated
 * @property {string} name - Name of the subject group
 * @property {string} sourcePage - STUDIO, REPORT_LAB
 * @property {any[]} subjects - Subjects in this group in format: { type: "investment" | "portfolio", id: string | number, name: string }
 * @property {string} [viewId] - id of view being edited or undefined
 */
/**
 * @typedef InputsSubjectGroupDeleted
 * @property {string} name - Name of the subject group
 * @property {string} sourcePage - STUDIO, REPORT_LAB
 * @property {any[]} subjects - Subjects in this group in format: { type: "investment" | "portfolio", id: string | number, name: string }
 * @property {string} [viewId] - id of view being edited or undefined
 */
/**
 * @typedef InputsSubjectGroupModified
 * @property {string} name - Name of the subject group
 * @property {string} sourcePage - STUDIO, REPORT_LAB
 * @property {any[]} subjects - Subjects in this group in format: { type: "investment" | "portfolio", id: string | number, name: string }
 * @property {string} [viewId] - id of view being edited or undefined
 */
/**
 * @typedef InvestmentMetadataSaved
 * @property {boolean} [categorizerPredicted] - True if the category is predicted by Venn
 * @property {any[]} [fieldsModified] - names of fields modified
 * @property {string} [investmentId] - investment id
 * @property {boolean} [userUploaded] - true if it's user uploaded investment (fires in return update)
 */
/**
 * @typedef InvestmentsUploaded
 * @property {string} [dataType] - returns or navs
 * @property {number} [numberOfDiscarded] - upload error number
 * @property {number} [numberOfExisting] - updated fund number
 * @property {number} [numberOfInvestments] - new fund number + updated fund number
 * @property {number} [numberOfNew] - new fund number
 * @property {string} [uploadType] - 'file', 'copy-paste' and 'samplefile'
 */
/**
 * @typedef InvitationAction
 * @property {string} email - email of the invitation
 * @property {boolean} [isWorkspaceAdmin] - true if the user is admin
 * @property {string} type - Revoked, Resent, Removed
 */
/**
 * @typedef KeyboardShortcutUsed
 * @property {string} [shortcutPurpose] - such as 'color scheme picker', 'search'
 * @property {string} [shortcutString] - such as 'c', 's'
 */
/**
 * @typedef PerformanceFiltersItem
 * @property {string} field - the performance field name
 * @property {number} [maximum] - the maximum value for the filter
 * @property {number} [minimum] - the minimum value for the filter
 */
/**
 * @typedef LibraryFiltersSelected
 * @property {any[]} [assetTypeFilters] - asset types selected
 * @property {string[]} [currencyFilters] - the currencies selected
 * @property {string[]} [dataSourceFilters] - data source selected
 * @property {string} [libraryTab] - Corresponds to LibraryTab, indicated whether it was public or private library
 * @property {string[]} [metricFilters] - the metric fields on which there is a filter applied
 * @property {PerformanceFiltersItem[]} [performanceFilters] - the performance fields and their filter values
 * @property {string[]} quickFilters - the quick filters selected (eg, requires attention, uploaded by me)
 * @property {string[]} tagFilters - the tags selected
 * @property {string[]} typeFilters - the item types filtered
 */
/**
 * @typedef LinkClicked
 * @property {string} destinationUrl - URL value
 * @property {string} label - The text displayed
 * @property {string} locationOnPage - Position on page, parent component, etc...
 * @property {number} [sponsorId] - Id of the sponsor, if the account is sponsored
 */
/**
 * @typedef MeteredInvestmentsViewed
 * @property {string} [triggerName] - trigger place such as 'navbar', 'Comparison Page error button', 'Factor Insights error button', or 'Analysis Page error button'
 */
/**
 * @typedef ModalClosed
 * @property {boolean} [cancelled] - if user can't the modal
 * @property {string} [topic] - such as 'invite modal'
 */
/**
 * @typedef ModalDisplayed
 * @property {string} [topic] - such as 'invite modal'
 */
/**
 * @typedef ModifiedInvestmentOverride
 * @property {string} currentState -
 * @property {string} investmentId -
 * @property {string} previousState -
 */
/**
 * @typedef Company
 * @property {number} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef User
 * @property {number} [_ABGroup] -
 * @property {string} [_appcuesId] -
 * @property {string} [_currentPageTitle] -
 * @property {string} [_currentPageUrl] -
 * @property {boolean} [_doNotTrack] -
 * @property {string} [_hostname] -
 * @property {string} [_lastBrowserLanguage] -
 * @property {string} [_lastPageTitle] -
 * @property {string} [_lastPageUrl] -
 * @property {string} [_localId] -
 * @property {any | null} [_myAppcuesId] -
 * @property {number} [_sessionPageviews] -
 * @property {number} [_sessionRandomizer] -
 * @property {any | null} [_testContentId] -
 * @property {number} [_updatedAt] -
 * @property {string} [_userAgent] -
 * @property {string} [accountTier] -
 * @property {boolean} [actualUser] -
 * @property {Company} [company] -
 * @property {string} [email] -
 * @property {string} [firstName] -
 * @property {string} [id] -
 * @property {boolean} [isAccountAdmin] -
 * @property {string} [lastName] -
 * @property {number} [organizationID] -
 * @property {number} [organizationId] -
 * @property {string} [organizationName] -
 * @property {string} [trialStatus] -
 * @property {string} [userId] -
 * @property {number} [workspaceId] -
 * @property {string} [workspaceName] -
 */
/**
 * @typedef NpsAskMeLaterSelectedAtAppcues
 * @property {number} [askMeLaterSelectedAt] -
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {User} [user] -
 */
/**
 * @typedef Company1
 * @property {number} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef User1
 * @property {number} [_ABGroup] -
 * @property {string} [_appcuesId] -
 * @property {string} [_currentPageTitle] -
 * @property {string} [_currentPageUrl] -
 * @property {boolean} [_doNotTrack] -
 * @property {string} [_hostname] -
 * @property {string} [_lastBrowserLanguage] -
 * @property {string} [_lastPageTitle] -
 * @property {string} [_lastPageUrl] -
 * @property {string} [_localId] -
 * @property {any | null} [_myAppcuesId] -
 * @property {number} [_sessionPageviews] -
 * @property {number} [_sessionRandomizer] -
 * @property {any | null} [_testContentId] -
 * @property {number} [_updatedAt] -
 * @property {string} [_userAgent] -
 * @property {string} [accountTier] -
 * @property {boolean} [actualUser] -
 * @property {Company1} [company] -
 * @property {string} [email] -
 * @property {string} [firstName] -
 * @property {string} [id] -
 * @property {boolean} [isAccountAdmin] -
 * @property {string} [lastName] -
 * @property {number} [organizationID] -
 * @property {number} [organizationId] -
 * @property {string} [organizationName] -
 * @property {string} [trialStatus] -
 * @property {string} [userId] -
 * @property {number} [workspaceId] -
 * @property {string} [workspaceName] -
 */
/**
 * @typedef NpsScoreAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {number} [score] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {User1} [user] -
 */
/**
 * @typedef Company2
 * @property {number} [id] -
 * @property {string} [name] -
 */
/**
 * @typedef User2
 * @property {number} [_ABGroup] -
 * @property {string} [_appcuesId] -
 * @property {string} [_currentPageTitle] -
 * @property {string} [_currentPageUrl] -
 * @property {boolean} [_doNotTrack] -
 * @property {string} [_hostname] -
 * @property {string} [_lastBrowserLanguage] -
 * @property {string} [_lastPageTitle] -
 * @property {string} [_lastPageUrl] -
 * @property {string} [_localId] -
 * @property {any | null} [_myAppcuesId] -
 * @property {number} [_sessionPageviews] -
 * @property {number} [_sessionRandomizer] -
 * @property {any | null} [_testContentId] -
 * @property {number} [_updatedAt] -
 * @property {string} [_userAgent] -
 * @property {string} [accountTier] -
 * @property {boolean} [actualUser] -
 * @property {Company2} [company] -
 * @property {string} [email] -
 * @property {string} [firstName] -
 * @property {string} [id] -
 * @property {boolean} [isAccountAdmin] -
 * @property {string} [lastName] -
 * @property {number} [organizationID] -
 * @property {number} [organizationId] -
 * @property {string} [organizationName] -
 * @property {string} [trialStatus] -
 * @property {string} [userId] -
 * @property {number} [workspaceId] -
 * @property {string} [workspaceName] -
 */
/**
 * @typedef NpsSurveyStartedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {number} [timestamp] -
 * @property {User2} [user] -
 */
/**
 * @typedef NavigationTriggered
 * @property {string} [destinationPageTitle] - such as 'Factor Homepage', 
 * @property {string} [itemType] - dropdown, link

 * @property {string} [location] - 
'breadcrumb', 'navigation bar', 'search bar'
 * @property {string} [userIntent] - 'navigate', 'change analysis', 'change object', 'learn about factors'

 */
/**
 * @typedef NotablePeriodAdded
 * @property {string} [periodName] - Name of Venn Notable Period Added
 */
/**
 * @typedef NotablePeriodViewed
 * @property {string} [dateRange] - startDate-endDate
 * @property {string} [factor] - factor name
 * @property {string} [viewType] - such as 'chart'
 */
/**
 * @typedef PeerGroupSelected
 * @property {string} peerGroupId - The id of the peer group selected
 * @property {string} [peerGroupName] - The display name of the peer group selected
 */
/**
 * @typedef PortfolioComparisonSelected
 * @property {string} compareType - master | optimized | saved (for historical versions) | last saved (for the most recently saved version) | none
 * @property {string} [historicalUpdatedTime] - YYYY-MM-DD HH:mm formated date of when the "saved" version was last updated (if historical versions are available), null if other compareType selected
 * @property {number} [portfolioId] - id of the primary portfolio subject
 * @property {string} [portfolioName] - name of the primary portfolio subject
 */
/**
 * @typedef PortfolioLabConstraintsModified
 * @property {number} [allocationConstraintsCount] - Number of allocation constraints
 * @property {string} [benchmarkId] - Id of benchmark, if present
 * @property {string} [constraintType] - 'target' | 'allocation' | 'factor'
 * @property {boolean} [constraintsMatchPolicy] - True if optimizing with portfolio policy constraints only
 * @property {number} [factorConstraintsCount] - Number of factor exposure constraints
 * @property {string} [locationOnPage] - The source component of the change
 * @property {any[]} [newOpportunities] - Fund ids of new opportunities included in current constraints
 * @property {string} [originPage] - The name of the page where the setting was selected (i.e. Library if we're bulk-optimizing investments from there)
 * @property {number} [parentStrategyId] - Id of strategy that the new opportunities are added to
 * @property {number} [portfolioId] - Id of portfolio being optimized
 * @property {number} [returnConstraint] - Return constraint, i.e. 0.25, if targeting return of 25% with minimum volatility
 * @property {string} [target] - 'maximizeReturn' | 'targetReturn' | 'maximizeSharpe'
 * @property {number} [volatilityConstraint] - Volatility constraint, i.e. 0.25, if optimizing with volatility less than 25%
 */
/**
 * @typedef PortfolioLabMigrationAccepted
 * @property {string} component - 'modal' | 'top bar' | 'tutorial' | 'banner' | ...
 * @property {string} source - Source page of the action
 */
/**
 * @typedef PortfolioLabMigrationDismissed
 * @property {string} component - 'modal' | 'top bar' | 'tutorial' | 'banner' | ...
 * @property {number} [dismissCounter] - i.e. 3, if it's the third time this user dismissed the migration message
 * @property {string} source - Source page of the action
 */
/**
 * @typedef PortfolioLabSolutionSaved
 * @property {string} [action] - 'Save and Tag' | 'Analyze' | 'Add to compare'
 * @property {number} [basePortfolioId] - Id of the portfolio being optimized
 * @property {boolean} [isOptimal] - True if it's the main optimization result, false if one of the alternate solutions
 * @property {number} [optimizedPortfolioId] - Id of the solution portfolio being saved
 */
/**
 * @typedef PortfolioLabSolutionViewed
 * @property {number} [allocationConstraintsMet] - Number of allocation constraints met
 * @property {number} [allocationConstraintsNotMet] - Number of allocation constraints NOT met
 * @property {string} [category] - 'Current' | 'Optimized' | 'Alternate #1' | 'Alternate #2' | ...
 * @property {number} [factorConstraintsMet] - Number of factor constraints met
 * @property {number} [factorConstraintsNotMet] - Number of factor constraints NOT met
 * @property {boolean} [objectiveConstraintMet] - True if ret/vol constraint is met
 * @property {number} [return] - Solution forecasted return
 * @property {number} [returnConstraint] - Return constraint, i.e. 0.25, if targeting return of 25% with minimum volatility
 * @property {string} [target] - 'maximizeReturn' | 'targetReturn' | 'maximizeSharpe'
 * @property {number} [volatility] - Solution forecasted volatility
 * @property {number} [volatilityConstraint] - Volatility constraint, i.e. 0.25, if optimizing with volatility less than 25%
 */
/**
 * @typedef FactorConstraintsItem
 * @property {string} [factorName] - factor name
 * @property {number} [maxExposure] - max exposure
 * @property {number} [minExposure] - min exposure
 */
/**
 * @typedef PortfolioOptimized
 * @property {string} [entryPoint] - Analysis Results or "Venn - Analysis Results"
 * @property {FactorConstraintsItem[]} [factorConstraints] - Factor constraint list
 * @property {boolean} [isMaster] - true if master portfolio
 * @property {number | null} [maxVolatility] - when select Maximize Returns, provide max volatility value
 * @property {any | null} [minReturn] - when select Target Returns, provide min return value
 * @property {boolean} [modifiedAllocationConstraints] - true if user has modified default allocation constraints
 * @property {boolean} [modifiedFactorConstraints] - true if user has modified factor allocation constraints
 * @property {string} [optimizationObjective] - Target Returns, Maximize Returns
 * @property {number} [portfolioId] - portfolio id
 * @property {number} [portfolioLevelSelected] - 0 if it's root portfolio
 * @property {any | null} [strategyId] - if any strategy selected
 * @property {string} [successType] - achieved goal, best possible
 * @property {boolean} [viewedAllocationConstraints] - true if user open allocation constraints
 * @property {boolean} [viewedFactorConstraints] - true if user open the factor constraints
 * @property {boolean} [wasSuccessful] - true if find result
 */
/**
 * @typedef PortfolioPolicyModified
 * @property {any[]} [allocationConstraints] - Array of constraints containing a list of subject ids, constraint type, value type and value
 * @property {any[]} [factorConstraints] - Array of constraints containing factor id, constraint type and value
 * @property {boolean} [hasAllInvestmentsConstraints] - True if any allocation constraint is for "all investments"
 * @property {boolean} [hasInvestmentConstraints] - True if any allocation constraint has listed investments
 * @property {boolean} [hasStrategyConstraints] - True if any allocation constraint has listed strategies
 * @property {string} [location] - Where the policy was modified from, i.e. "Manage Data" / "Portfolio Lab inputs side bar"
 */
/**
 * @typedef PortfolioRebalancingUpdated
 * @property {number} portfolioId - Id of the portfolio
 * @property {string} portfolioName - Name of the portfolio
 * @property {string} rebalanceStrategy - The rebalance strategy the portfolio is changing to
 */
/**
 * @typedef PortfolioSaved
 * @property {string} [dateRange] - startDate-endDate
 * @property {boolean} [hasBenchmark] - true if portfolio has active benchmark
 * @property {boolean} [isMaster] - true if portfolio is master portfolio
 * @property {number} [portfolioId] - portfolio id
 */
/**
 * @typedef PortfolioSetupStepCompleted
 * @property {string} [stepDescription] - Save and View
 */
/**
 * @typedef PortfolioSetupStepViewed
 * @property {string} [stepDescription] - Add Investments, Add Strategies, Edit Portfolio, Add Allocations, Save and View'
 */
/**
 * @typedef ProRequested
 * @property {boolean} [trialAvailable] - true if the user doesn't on trial before
 */
/**
 * @typedef ProfileEdited
 * @property {string} [fieldsChanged] - First name, last name, display name, avatar
 */
/**
 * @typedef ProxyModified
 * @property {boolean} [extrapolate] - whether the user is applying extrapolation
 * @property {string} fundId - id of the fund being proxied
 * @property {number} [numLags] - number of lags used for desmoothing
 * @property {string} [proxyCategory] - proxy category
 * @property {string} [proxyId] - id of the fund used for proxying
 * @property {string} [proxyType] - type of the proxy
 */
/**
 * @typedef RemovedFromWorkspace
 * @property {boolean} [isWorkspaceAdmin] - Was the remover an admin?
 * @property {number} orgId - Id of the org to be removed from
 * @property {number} userId - userId of the removed user
 */
/**
 * @typedef ReportLabExport
 * @property {number} [blockCount] - number of blocks
 * @property {any[]} [blocks] - all exported blocks
 * @property {boolean} [containsMacroScenarios] - If there was any sensitivity blocks that contained macro scenarios
 * @property {boolean} [containsMarketScenarios] - If there was any sensitivity blocks that contained market scenarios
 * @property {string} [exportTo] - PDF, XLS, PNG, PrintPreview
 * @property {boolean} [isFinal] - whether export was a final copy or a draft (this is the opposite of isInternal: internal copies are drafts)
 * @property {boolean} [isSuccessful] - whether export failed or succeed
 * @property {number} [numPortfolioComparisonBlocks] - number of portfolio comparison blocks in the view
 * @property {number} [numPortfolioComparisonBlocksWithCustomMetrics] - number of portfolio comparison blocks in the view with custom metrics
 * @property {string} [printOrientation] - portrait, landscape
 * @property {any[]} [scenarioFundNames] - the names of any funds in any sensitivity analysis blocks in the export
 * @property {any[]} [subjectIds] - ids of exported subjects
 * @property {number} [unlinkedBlocks] - number of unlinked blocks
 * @property {string} [viewId] - id of the reportlab view
 */
/**
 * @typedef ReportLabOpened
 * @property {number} [blockCount] - number of blocks
 * @property {any[]} [blocks] - all blocks in the view
 * @property {boolean} [containsMacroScenarios] - If there was any sensitivity blocks that contained macro scenarios
 * @property {boolean} [containsMarketScenarios] - If there was any sensitivity blocks that contained market scenarios
 * @property {boolean} [isTemplate] - If the view is a template
 * @property {number} [numPortfolioComparisonBlocks] - number of portfolio comparison blocks in the view
 * @property {number} [numPortfolioComparisonBlocksWithCustomMetrics] - number of portfolio comparison blocks with custom metrics
 * @property {any[]} [scenarioFundNames] - the names of any funds in any sensitivity analysis blocks in the export
 * @property {string} [viewId] - the id of the studio view
 */
/**
 * @typedef ResetFontSizeToWorkspaceDefaults
 * @property {string} blockId -
 * @property {string} blockType -
 * @property {number} fontSize -
 * @property {string} fontType -
 * @property {string} [viewId] -
 */
/**
 * @typedef ResetViewFontSizes
 * @property {string} [viewId] -
 */
/**
 * @typedef RoleChanged
 * @property {string} role - role that was changed to
 * @property {number} userId - userId of user whose role was changed
 */
/**
 * @typedef SavedNewInvestmentOverride
 * @property {string} [currentState] -
 * @property {string} investmentId -
 */
/**
 * @typedef ScenarioCreated
 * @property {boolean} [entryInRange] -
 * @property {number} [entryReturn] -
 * @property {string} [location] - analysis page, studio or report lab
 * @property {string} [scenarioIndexName] -
 * @property {number} [scenarioReturn] -
 * @property {number} [scenarioStd] -
 * @property {string} [type] - macro or market
 */
/**
 * @typedef ScenarioDeleted
 * @property {boolean} [entryInRange] -
 * @property {number} [entryReturn] -
 * @property {string} [location] - analysis page, studio or report lab
 * @property {string} [scenarioIndexName] -
 * @property {number} [scenarioReturn] -
 * @property {number} [scenarioStd] -
 * @property {string} [type] - macro or market
 */
/**
 * @typedef ScenarioModified
 * @property {boolean} [entryInRange] -
 * @property {number} [entryReturn] -
 * @property {string} [location] - analysis page, studio or report lab
 * @property {string} [scenarioIndexName] -
 * @property {number} [scenarioReturn] -
 * @property {number} [scenarioStd] -
 * @property {string} [type] - macro or market
 */
/**
 * @typedef SearchDismissed
 * @property {string} location - location of the search in the app
 * @property {string} [query] - the search query text
 * @property {any[]} [quickFilters] - the quick filters applied (eg, recently analyzed, requires attention)
 * @property {number} [totalResults] - the number of total results available for the search query
 * @property {any[]} [typeFilters] - which types are allowed - eg, Investments, Portfolios, Benchmarks
 * @property {number} [visibleResults] - the number of results visible to the user
 */
/**
 * @typedef SearchMultiSelectionMade
 * @property {number} countItems - number of selected items
 * @property {number} [countItemsFromTag] - number of items in the “cart” that were tagged
 * @property {any[]} [createdBy] - data source e.g. user uploaded, BNY, northern trust, venn provided, Returns Inbox
 * @property {string} location - comparison, allocator panel, portfolio labs
 * @property {any[]} [objectType] - e.g. Investment, Portfolio, etc
 * @property {string} query - the search query
 * @property {any[]} [quickFilters] - the quick filters applied (eg, recently analyzed, requires attention)
 * @property {number} totalResults - the number of visible results shown to the user
 * @property {any[]} [typeFilters] - which types are allowed - eg, Investments, Portfolios, Benchmarks
 * @property {any[]} [updatedBy] - data source e.g. user uploaded, BNY, northern trust, venn provided, Returns Inbox
 */
/**
 * @typedef SearchQueryEntered
 * @property {any[]} [assetTypeFilters] - asset types selected
 * @property {string[]} [currencyFilters] - the currencies selected
 * @property {string[]} [dataSourceFilters] - data source selected
 * @property {Record<string, any>} [filterValues] - values of the filters (archive page only)
 * @property {string} location - location on the site that the search was made
 * @property {string[]} [metricFilters] - the metric fields on which there is a filter applied
 * @property {string[]} [performanceFilters] - the performance fields on which there is a filter applied
 * @property {string} query - the search query
 * @property {any[]} [quickFilters] - the quick filters applied (eg, recently analyzed, requires attention)
 * @property {any[]} [tagFilters] - the tags applied to the search
 * @property {number} [totalResults] - the number of total results available for the search query
 * @property {any[]} [typeFilters] - which types are allowed - eg, Investments, Portfolios, Benchmarks
 * @property {number} visibleResults - the number of results visible to the user
 */
/**
 * @typedef SearchSelectionMade
 * @property {string} [activeSort] - "Sharpe Ratio Ascending"
 * @property {any[]} [assetTypeFilters] - asset types to filter on
 * @property {string} createdBy - name of custodian, user uploaded, venn, returns inbox
 * @property {string[]} [currencyFilters] - the currencies selected
 * @property {string} location - location on the site that the search was made
 * @property {string[]} [metricFilters] - the metric fields on which there is a filter applied
 * @property {any | null} [objectId] - id of the item selected
 * @property {string} objectType - investment, portfolio...
 * @property {string[]} [performanceFilters] - the performance fields on which there is a filter applied
 * @property {string} query - the search query
 * @property {boolean} queryDriven - if there is a search query
 * @property {any[]} [quickFilters] - the quick filters applied (eg, recently analyzed, requires attention)
 * @property {number} rank - order that the selected appeared in (0 for first item)
 * @property {any[]} [tagFilters] - the tags applied to the search
 * @property {number} [totalResults] - the number of total results available for the search query
 * @property {any[]} [typeFilters] - which types are allowed - eg, Investments, Portfolios, Benchmarks
 * @property {number} [visibleResults] - the number of visible results shown to the user
 */
/**
 * @typedef SignupFunnelTerminated
 * @property {string} [stepDescription] - password creation
 */
/**
 * @typedef SignupStepCompleted
 * @property {any | null} [stepDescription] - 'account details' or 'password creation'
 */
/**
 * @typedef SignupStepViewed
 * @property {any | null} [stepDescription] - 'account details' or 'password creation'
 */
/**
 * @typedef StepCompletedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {string} [stepId] -
 * @property {string} [stepType] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {string} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef StepInteractedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {Record<string, any>} [interaction] -
 * @property {string} [interaction.category] -
 * @property {string} [interaction.destination] -
 * @property {any[]} [interaction.element] -
 * @property {Record<string, any>} [interaction.element.$] -
 * @property {number} [interaction.element.$.order_filter] -
 * @property {string} [interaction.element.$.selector] -
 * @property {string} [interactionType] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {string} [stepChildId] -
 * @property {number} [stepChildNumber] -
 * @property {string} [stepId] -
 * @property {string} [stepType] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {string} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef StepSkippedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {string} [stepChildId] -
 * @property {number} [stepChildNumber] -
 * @property {string} [stepId] -
 * @property {string} [stepType] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {any | null} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef StepStartedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [sessionId] -
 * @property {string} [stepId] -
 * @property {string} [stepType] -
 * @property {number} [timestamp] -
 * @property {Record<string, any>} [user] -
 * @property {number} [user._ABGroup] -
 * @property {string} [user._appcuesId] -
 * @property {string} [user._currentPageTitle] -
 * @property {string} [user._currentPageUrl] -
 * @property {boolean} [user._doNotTrack] -
 * @property {string} [user._hostname] -
 * @property {string} [user._lastBrowserLanguage] -
 * @property {string} [user._lastPageTitle] -
 * @property {string} [user._lastPageUrl] -
 * @property {string} [user._localId] -
 * @property {string} [user._myAppcuesId] -
 * @property {number} [user._sessionPageviews] -
 * @property {number} [user._sessionRandomizer] -
 * @property {any | null} [user._testContentId] -
 * @property {number} [user._updatedAt] -
 * @property {string} [user._userAgent] -
 * @property {string} [user.accountStatus] -
 * @property {string} [user.accountTier] -
 * @property {boolean} [user.actualUser] -
 * @property {Record<string, any>} [user.company] -
 * @property {number} [user.company.id] -
 * @property {string} [user.company.name] -
 * @property {string} [user.email] -
 * @property {string} [user.firstName] -
 * @property {number} [user.id] -
 * @property {boolean} [user.isAccountAdmin] -
 * @property {string} [user.lastName] -
 * @property {number} [user.organizationID] -
 * @property {number} [user.organizationId] -
 * @property {string} [user.organizationName] -
 * @property {string} [user.trialStatus] -
 * @property {string} [user.userEmail] -
 * @property {number} [user.userId] -
 * @property {string} [user.userName] -
 * @property {number} [user.workspaceId] -
 * @property {string} [user.workspaceName] -
 */
/**
 * @typedef StudioExport
 * @property {number} [blockCount] - number of blocks
 * @property {any[]} [blocks] - All exported blocks
 * @property {boolean} [containsMacroScenarios] - If there was any sensitivity blocks that contained macro scenarios
 * @property {boolean} [containsMarketScenarios] - If there was any sensitivity blocks that contained market scenarios
 * @property {string} [exportTo] - PDF, XLS, PNG, PrintPreview
 * @property {boolean} [isFinal] - whether the export is a final export or draft export
 * @property {boolean} [isSuccessful] - whether export failed or succeed
 * @property {number} [numPortfolioComparisonBlocks] - number of portfolio comparison blocks in the view
 * @property {number} [numPortfolioComparisonBlocksWithCustomMetrics] - number of portfolio comparison blocks with custom metrics in the view
 * @property {string} [printOrientation] - portrait, landscape
 * @property {any[]} [scenarioFundNames] - the names of any funds in any sensitivity analysis blocks in the export
 * @property {any[]} [subjectIds] - ids of exported subjects
 * @property {number} [unlinkedBlocks] - Number of unlinked blocks
 * @property {string} [viewId] - id of the studio view
 */
/**
 * @typedef StudioOpened
 * @property {number} [blockCount] - number of blocks
 * @property {any[]} [blocks] - all blocks in the view
 * @property {boolean} [containsMacroScenarios] - If there was any sensitivity blocks that contained macro scenarios
 * @property {boolean} [containsMarketScenarios] - If there was any sensitivity blocks that contained market scenarios
 * @property {boolean} [isTemplate] - if the view is a template
 * @property {number} [numPortfolioComparisonBlocks] - number of portfolio comparison blocks in the view
 * @property {number} [numPortfolioComparisonBlocksWithCustomMetrics] - number of portfolio comparison blocks in the view with custom metrics
 * @property {any[]} [scenarioFundNames] - the names of any funds in any sensitivity analysis blocks in the export
 * @property {string} [viewId] - the id of the studio view
 */
/**
 * @typedef TextFieldUpdated
 * @property {string} [locationOnPage] - The location on page, e.g. "Allocator Panel"
 */
/**
 * @typedef ToggleToggled
 * @property {string} [locationOnPage] - (Same as for `Cta Clicked`)
 * @property {string} [purpose] - What should this toggle do?
 * @property {string} [selection] - What option did the user select?
 */
/**
 * @typedef UpgradeModalViewed
 * @property {boolean} [trialAvailable] - true if user hasn't done trial before
 * @property {string} [triggerName] - "Analysis Limit Reached", ""Venn Pro Block", ""Unlock Portfolio Analysis", etc
 */
/**
 * @typedef UploadAttempted
 * @property {string} [dataType] - 'returns', 'navs'
 * @property {string} [type] - 'file', 'copy-paste', 'samplefile'
 */
/**
 * @typedef UploadStepCompleted
 * @property {string} [dataType] - 'returns', 'navs'
 * @property {number} [step] - 0, 1
 * @property {string} [uploadType] - 'file', 'copy-paste', 'samplefile'
 */
/**
 * @typedef UploadStepFailed
 * @property {string} [dataType] - 'returns', 'navs'
 * @property {string} [error] - error message
 * @property {number[]} [errorCounts] - review step's error
 * @property {string[]} [errorTypes] - review step's error type
 * @property {number} [step] - 0, 1
 */
/**
 * @typedef UploadStepViewed
 * @property {string} [dataType] - 'returns', 'navs'
 * @property {number} [step] - 0, 1
 * @property {any | null} [uploadType] - 'file', 'copy-paste', 'samplefile'
 */
/**
 * @typedef UploadTabChanged
 * @property {boolean} [Nav] - True if the user was on the Nav uploader
 * @property {string} TabName - The name of the tab that was changed to
 * @property {boolean} [isPrivatesUploader] - Whether this event was fired from public or private uploader
 */
/**
 * @typedef UserCreated
 * @property {string} [source] - 'invited', 'organic'
 */
/**
 * @typedef UsersInvited
 * @property {string} [entryPoint] - in-app
 * @property {number} [numberOfInvites] - emails number
 */
/**
 * @typedef VideoPlaying
 * @property {string} [locationOnPage] - Where on the page was the video launcher placed
 * @property {string} [pageName] - The page where the video was played on
 * @property {number} [timeElapsed] - Seconds into video
 * @property {number} [timeTotal] - Length of video in seconds
 * @property {string} videoTitle - Title of the video
 */
/**
 * @typedef EnrichmentProvider
 * @property {string} [product] -
 * @property {string} [type] -
 */

/**
 * Fires a 'Allocator Investments Changed' track call.
 *
 * @param {AllocatorInvestmentsChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function allocatorInvestmentsChanged(
  props?: AllocatorInvestmentsChanged,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Allocator Investments Changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when user opens allocator panel
 *
 * @param {AllocatorPanelOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function allocatorPanelOpened(
  props?: AllocatorPanelOpened,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Allocator Panel Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track failed analysis
 *
 * @param {AnalysisFailed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function analysisFailed(props?: AnalysisFailed, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Analysis Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track analysis performed when all analysis blocks loaded in Front end
 *
 * @param {AnalysisPerformed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function analysisPerformed(
  props?: AnalysisPerformed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Analysis Performed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track user create or update a template
 *
 * @param {AnalysisTemplateSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function analysisTemplateSaved(
  props?: AnalysisTemplateSaved,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Analysis Template Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Apply block level changes
 *
 * @param {ApplyBlockSettings} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applyBlockSettings(
  props?: ApplyBlockSettings,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Apply Block Settings', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Block Font Size Changed' track call.
 *
 * @param {BlockFontSizeChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockFontSizeChanged(
  props: BlockFontSizeChanged,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Block Font Size Changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track builder analysis settings
 *
 * @param {BuilderAnalysisPerformed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function builderAnalysisPerformed(
  props?: BuilderAnalysisPerformed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Builder Analysis Performed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track on bulk action complete
 *
 * @param {BulkActionPerformed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bulkActionPerformed(
  props?: BulkActionPerformed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Bulk Action Performed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Cash Flow Settings Applied' track call.
 *
 * @param {CashFlowSettingsApplied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cashFlowSettingsApplied(
  props?: CashFlowSettingsApplied,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Cash Flow Settings Applied', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Cash Flow Settings Modal Opened' track call.
 *
 * @param {CashFlowSettingsModalOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cashFlowSettingsModalOpened(
  props?: CashFlowSettingsModalOpened,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Cash Flow Settings Modal Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User modifies an individual chart to adjust data display
 *
 * @param {ChartModified} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chartModified(props?: ChartModified, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Chart Modified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Checklist Dismissed (Appcues)' track call.
 *
 * @param {ChecklistDismissedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checklistDismissedAppcues(
  props?: ChecklistDismissedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Checklist Dismissed (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Checklist Item Completed (Appcues)' track call.
 *
 * @param {ChecklistItemCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checklistItemCompletedAppcues(
  props?: ChecklistItemCompletedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Checklist Item Completed (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Checklist Item Started (Appcues)' track call.
 *
 * @param {ChecklistItemStartedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checklistItemStartedAppcues(
  props?: ChecklistItemStartedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Checklist Item Started (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Checklist Shown (Appcues)' track call.
 *
 * @param {ChecklistShownAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checklistShownAppcues(
  props?: ChecklistShownAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Checklist Shown (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Checklist Skipped (Appcues)' track call.
 *
 * @param {ChecklistSkippedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checklistSkippedAppcues(
  props?: ChecklistSkippedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Checklist Skipped (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Error being thrown on comparison page
 *
 * @param {ComparisonFailed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function comparisonFailed(
  props?: ComparisonFailed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Comparison Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track comparison performed (both success or throw error)
 *
 * @param {ComparisonPerformed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function comparisonPerformed(
  props?: ComparisonPerformed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Comparison Performed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * This event is triggered when an EU-user updates their cookie preferences via the Cookie Preferences Manager
 *
 * @param {CookiePreferencesModified} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cookiePreferencesModified(
  props?: CookiePreferencesModified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Cookie Preferences Modified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Create new studio either from builder or studio page
 *
 * @param {CreatingNewStudios} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function creatingNewStudios(
  props?: CreatingNewStudios,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Creating New Studios', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * CTA link or button is clicked
 *
 * @param {CtaClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ctaClicked(props?: CtaClicked, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Cta Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered whenever custom metrics for a subject or changes column is cleared due to subjects changing
 *
 * @param {CustomMetricsCleared} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customMetricsCleared(
  props: CustomMetricsCleared,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Custom Metrics Cleared', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * chart exports (png, pdf, xlsx)
 *
 * @param {DataExported} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataExported(props?: DataExported, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Data Exported', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User has opened the data management feature for a portfolio or investment
 *
 * @param {DataManagementOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementOpened(
  props?: DataManagementOpened,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Data Management Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Deleted Investment Override' track call.
 *
 * @param {DeletedInvestmentOverride} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deletedInvestmentOverride(
  props: DeletedInvestmentOverride,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Deleted Investment Override', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User has downloaded documents
 *
 * @param {DocumentsDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function documentsDownloaded(
  props: DocumentsDownloaded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Documents Downloaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Select a drawdown period in Hypothetical Drawdown Periods table
 *
 * @param {DrawdownSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function drawdownSelected(
  props?: DrawdownSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Drawdown Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Close dropdown menu(can fire either when user closes dropdown without changing selection or after item(s) are selected)
 *
 * @param {DropdownClosed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dropdownClosed(props?: DropdownClosed, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Dropdown Closed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * on onselect an option in dropdown
 *
 * @param {DropdownItemSSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dropdownItemSSelected(
  props?: DropdownItemSSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Dropdown Item(s) Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks a dropdown
 *
 * @param {DropdownOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dropdownOpened(props?: DropdownOpened, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Dropdown Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track factor lens insight request failure
 *
 * @param {FactorInsightsFailed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function factorInsightsFailed(
  props?: FactorInsightsFailed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Factor Insights Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * fires when user analyzes factor performance history in factor insight page, such as change selected factors, factor inputs or date range.
 *
 * @param {FactorsAnalyzed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function factorsAnalyzed(props?: FactorsAnalyzed, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Factors Analyzed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when user downloads file template (e.g. Excel file) from upload modal
 *
 * @param {FileTemplateDownloaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileTemplateDownloaded(
  props?: FileTemplateDownloaded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('File Template Downloaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Flow Completed (Appcues)' track call.
 *
 * @param {FlowCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flowCompletedAppcues(
  props?: FlowCompletedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Flow Completed (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Flow Skipped (Appcues)' track call.
 *
 * @param {FlowSkippedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flowSkippedAppcues(
  props?: FlowSkippedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Flow Skipped (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Flow Started (Appcues)' track call.
 *
 * @param {FlowStartedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flowStartedAppcues(
  props?: FlowStartedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Flow Started (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track when a user clicks to create a new forecast
 *
 * @param {ForecastCreationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forecastCreationStarted(
  props: ForecastCreationStarted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Forecast Creation Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Forecast Factor Override' track call.
 *
 * @param {ForecastFactorOverride} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forecastFactorOverride(
  props: ForecastFactorOverride,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Forecast Factor Override', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track when a user clicks to view an individual forecast
 *
 * @param {ForecastInspected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forecastInspected(
  props?: ForecastInspected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Forecast Inspected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When set a forecast as default
 *
 * @param {ForecastSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forecastSelected(
  props?: ForecastSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Forecast Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires each time user opens Forecasts modal
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forecastsViewed(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Forecasts Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Form Field Submitted (Appcues)' track call.
 *
 * @param {FormFieldSubmittedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formFieldSubmittedAppcues(
  props?: FormFieldSubmittedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Form Field Submitted (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Form Submitted (Appcues)' track call.
 *
 * @param {FormSubmittedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formSubmittedAppcues(
  props?: FormSubmittedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Form Submitted (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track sponsor has updated the custom content to be shown on home page to their sponsored accounts
 *
 * @param {HomeContentUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function homeContentUpdated(
  props: HomeContentUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Home Content Updated', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track creation of Benchmark Group in IMv2
 *
 * @param {InputsBenchmarkGroupCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputsBenchmarkGroupCreated(
  props: InputsBenchmarkGroupCreated,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Inputs Benchmark Group Created', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track deletion of Benchmark Group in IMv2
 *
 * @param {InputsBenchmarkGroupDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputsBenchmarkGroupDeleted(
  props: InputsBenchmarkGroupDeleted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Inputs Benchmark Group Deleted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track edits of Benchmark Group in IMv2
 *
 * @param {InputsBenchmarkGroupModified} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputsBenchmarkGroupModified(
  props: InputsBenchmarkGroupModified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Inputs Benchmark Group Modified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track creation of Date Range Group in IMv2
 *
 * @param {InputsDateRangeGroupCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputsDateRangeGroupCreated(
  props: InputsDateRangeGroupCreated,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Inputs Date Range Group Created', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track deletion of Date Range Group in IMv2
 *
 * @param {InputsDateRangeGroupDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputsDateRangeGroupDeleted(
  props: InputsDateRangeGroupDeleted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Inputs Date Range Group Deleted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track edits of Date Range Group in IMv2
 *
 * @param {InputsDateRangeGroupModified} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputsDateRangeGroupModified(
  props: InputsDateRangeGroupModified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Inputs Date Range Group Modified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track creation of Subject Group in IMv2
 *
 * @param {InputsSubjectGroupCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputsSubjectGroupCreated(
  props: InputsSubjectGroupCreated,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Inputs Subject Group Created', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track edits of Subject Group in IMv2
 *
 * @param {InputsSubjectGroupDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputsSubjectGroupDeleted(
  props: InputsSubjectGroupDeleted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Inputs Subject Group Deleted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track deletion of Subject Group in IMv2
 *
 * @param {InputsSubjectGroupModified} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inputsSubjectGroupModified(
  props: InputsSubjectGroupModified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Inputs Subject Group Modified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when update investment metadata
 *
 * @param {InvestmentMetadataSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function investmentMetadataSaved(
  props?: InvestmentMetadataSaved,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Investment Metadata Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * fires when user successful uploads investment(s) in uploader’s last step
 *
 * @param {InvestmentsUploaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function investmentsUploaded(
  props?: InvestmentsUploaded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Investments Uploaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When user revokes, resends, or removes an invitation in their workspace.
 *
 * @param {InvitationAction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invitationAction(
  props: InvitationAction,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Invitation action', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * fires when user use shortcut
 *
 * @param {KeyboardShortcutUsed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function keyboardShortcutUsed(
  props?: KeyboardShortcutUsed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Keyboard Shortcut Used', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when a filter in the library is selected
 *
 * @param {LibraryFiltersSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function libraryFiltersSelected(
  props: LibraryFiltersSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Library Filters Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Link is clicked. Preferred over "CTA Clicked" when the link can be customised, so it's difficult to capture the "action" it's "calling to".
 *
 * @param {LinkClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function linkClicked(props: LinkClicked, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Link Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when use see metered investments modal
 *
 * @param {MeteredInvestmentsViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function meteredInvestmentsViewed(
  props?: MeteredInvestmentsViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Metered Investments Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when a user closes modal
 *
 * @param {ModalClosed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modalClosed(props?: ModalClosed, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Modal Closed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when a user opens modal
 *
 * @param {ModalDisplayed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modalDisplayed(props?: ModalDisplayed, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Modal Displayed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Modified Investment Override' track call.
 *
 * @param {ModifiedInvestmentOverride} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modifiedInvestmentOverride(
  props: ModifiedInvestmentOverride,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Modified Investment Override', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'NPS Ask Me Later Selected At (Appcues)' track call.
 *
 * @param {NpsAskMeLaterSelectedAtAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function npsAskMeLaterSelectedAtAppcues(
  props?: NpsAskMeLaterSelectedAtAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('NPS Ask Me Later Selected At (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'NPS Score (Appcues)' track call.
 *
 * @param {NpsScoreAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function npsScoreAppcues(props?: NpsScoreAppcues, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('NPS Score (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'NPS Survey Started (Appcues)' track call.
 *
 * @param {NpsSurveyStartedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function npsSurveyStartedAppcues(
  props?: NpsSurveyStartedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('NPS Survey Started (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when user navigate to different page
 *
 * @param {NavigationTriggered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navigationTriggered(
  props?: NavigationTriggered,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Navigation Triggered', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Tracks venn notable periods added to the notable period block
 *
 * @param {NotablePeriodAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notablePeriodAdded(
  props?: NotablePeriodAdded,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Notable Period Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when user select notable period in factor insights
 *
 * @param {NotablePeriodViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notablePeriodViewed(
  props?: NotablePeriodViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Notable Period Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when a user selects a peer group from the search modal in studio or RL
 *
 * @param {PeerGroupSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function peerGroupSelected(
  props: PeerGroupSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Peer Group Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track portfolio comparison selected from the Allocation Panel compare column
 *
 * @param {PortfolioComparisonSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioComparisonSelected(
  props: PortfolioComparisonSelected,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Comparison Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track whenever user modifies constraints used to run Portfolio Lab optimization
 *
 * @param {PortfolioLabConstraintsModified} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioLabConstraintsModified(
  props?: PortfolioLabConstraintsModified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Lab Constraints Modified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Portfolio Lab Migration Accepted' track call.
 *
 * @param {PortfolioLabMigrationAccepted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioLabMigrationAccepted(
  props: PortfolioLabMigrationAccepted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Lab Migration Accepted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Portfolio Lab Migration Dismissed' track call.
 *
 * @param {PortfolioLabMigrationDismissed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioLabMigrationDismissed(
  props: PortfolioLabMigrationDismissed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Lab Migration Dismissed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track when Portfolio Lab doesn't have any solutions to display
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioLabNoSolutionsFound(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Lab No Solutions Found', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track Portfolio Lab solution being saved
 *
 * @param {PortfolioLabSolutionSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioLabSolutionSaved(
  props?: PortfolioLabSolutionSaved,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Lab Solution Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track which optimized solution appears as selected in Portfolio Lab
 *
 * @param {PortfolioLabSolutionViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioLabSolutionViewed(
  props?: PortfolioLabSolutionViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Lab Solution Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * track optimizer run
 *
 * @param {PortfolioOptimized} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioOptimized(
  props?: PortfolioOptimized,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Optimized', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track changes to portfolio policy
 *
 * @param {PortfolioPolicyModified} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioPolicyModified(
  props?: PortfolioPolicyModified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Policy Modified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track when a portfolios rebalancing strategy is changed
 *
 * @param {PortfolioRebalancingUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioRebalancingUpdated(
  props: PortfolioRebalancingUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Rebalancing Updated', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * fires when user saved portfolio in allocator
 *
 * @param {PortfolioSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioSaved(props?: PortfolioSaved, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * fires when complete portfolio setup
 *
 * @param {PortfolioSetupStepCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioSetupStepCompleted(
  props?: PortfolioSetupStepCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Setup Step Completed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When user view each setup step
 *
 * @param {PortfolioSetupStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function portfolioSetupStepViewed(
  props?: PortfolioSetupStepViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Portfolio Setup Step Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when the print file finish render
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function printingCompleted(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Printing Completed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when user try to print
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function printingInitialized(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Printing Initialized', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when use click request pro
 *
 * @param {ProRequested} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function proRequested(props?: ProRequested, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Pro Requested', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fire when user saves changes to their profile.
 *
 * @param {ProfileEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileEdited(props?: ProfileEdited, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Profile Edited', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when user saves changes to their proxy or sets a new one
 *
 * @param {ProxyModified} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function proxyModified(props: ProxyModified, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Proxy Modified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When a user is removed from a workspace
 *
 * @param {RemovedFromWorkspace} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removedFromWorkspace(
  props: RemovedFromWorkspace,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Removed from workspace', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Export of print Report Lab
 *
 * @param {ReportLabExport} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reportLabExport(props?: ReportLabExport, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Report Lab Export', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * On open of a saved report lab view
 *
 * @param {ReportLabOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reportLabOpened(props?: ReportLabOpened, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Report Lab Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Reset Font Size to Workspace Defaults' track call.
 *
 * @param {ResetFontSizeToWorkspaceDefaults} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resetFontSizeToWorkspaceDefaults(
  props: ResetFontSizeToWorkspaceDefaults,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Reset Font Size to Workspace Defaults', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Reset View Font Sizes' track call.
 *
 * @param {ResetViewFontSizes} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resetViewFontSizes(
  props?: ResetViewFontSizes,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Reset View Font Sizes', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When a user's role is changed
 *
 * @param {RoleChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function roleChanged(props: RoleChanged, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Role changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Saved New Investment Override' track call.
 *
 * @param {SavedNewInvestmentOverride} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function savedNewInvestmentOverride(
  props: SavedNewInvestmentOverride,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Saved New Investment Override', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track properties of newly created shocks in scenario analysis
 *
 * @param {ScenarioCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scenarioCreated(props?: ScenarioCreated, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Scenario Created', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track properties of a deleted shock in scenario analysis
 *
 * @param {ScenarioDeleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scenarioDeleted(props?: ScenarioDeleted, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Scenario Deleted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track changes to the size of shock used in scenario analysis
 *
 * @param {ScenarioModified} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scenarioModified(
  props?: ScenarioModified,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Scenario Modified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When a search is blurred after searching
 *
 * @param {SearchDismissed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchDismissed(props: SearchDismissed, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Search Dismissed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Search Multi-Selection Made
 *
 * @param {SearchMultiSelectionMade} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchMultiSelectionMade(
  props: SearchMultiSelectionMade,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Search Multi-Selection Made', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When a search is made (debounced)
 *
 * @param {SearchQueryEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchQueryEntered(
  props: SearchQueryEntered,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Search Query Entered', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When an item is selected from search results
 *
 * @param {SearchSelectionMade} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchSelectionMade(
  props: SearchSelectionMade,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Search Selection Made', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User activate account from invite or sign up
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupFunnelCompleted(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Signup Funnel Completed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * blacklisted, waitlisted, terms disagreed
 *
 * @param {SignupFunnelTerminated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupFunnelTerminated(
  props?: SignupFunnelTerminated,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Signup Funnel Terminated', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * track which step that user completes
 *
 * @param {SignupStepCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupStepCompleted(
  props?: SignupStepCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Signup Step Completed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * track which step user has seen
 *
 * @param {SignupStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupStepViewed(
  props?: SignupStepViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Signup Step Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Step Completed (Appcues)' track call.
 *
 * @param {StepCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function stepCompletedAppcues(
  props?: StepCompletedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Step Completed (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Step Interacted (Appcues)' track call.
 *
 * @param {StepInteractedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function stepInteractedAppcues(
  props?: StepInteractedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Step Interacted (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Step Skipped (Appcues)' track call.
 *
 * @param {StepSkippedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function stepSkippedAppcues(
  props?: StepSkippedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Step Skipped (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'Step Started (Appcues)' track call.
 *
 * @param {StepStartedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function stepStartedAppcues(
  props?: StepStartedAppcues,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Step Started (Appcues)', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Export or print studio data
 *
 * @param {StudioExport} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function studioExport(props?: StudioExport, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Studio Export', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * On open of a saved studio view
 *
 * @param {StudioOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function studioOpened(props?: StudioOpened, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Studio Opened', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Input Text field update
 *
 * @param {TextFieldUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function textFieldUpdated(
  props?: TextFieldUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Text Field Updated', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks the "Large Toggle" element
 *
 * @param {ToggleToggled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function toggleToggled(props?: ToggleToggled, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Toggle Toggled', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when user open upgrade modal
 *
 * @param {UpgradeModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function upgradeModalViewed(
  props?: UpgradeModalViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Upgrade Modal Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fire before upload file (no matter success or failure)
 *
 * @param {UploadAttempted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadAttempted(props?: UploadAttempted, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Upload Attempted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * track upload step completed
 *
 * @param {UploadStepCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadStepCompleted(
  props?: UploadStepCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Upload Step Completed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * track errors in upload
 *
 * @param {UploadStepFailed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadStepFailed(
  props?: UploadStepFailed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Upload Step Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires in each upload step
 *
 * @param {UploadStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadStepViewed(
  props?: UploadStepViewed,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Upload Step Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires when switch tab on uploader
 *
 * @param {UploadTabChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadTabChanged(
  props: UploadTabChanged,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('Upload Tab Changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * New user created succesfully
 *
 * @param {UserCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userCreated(props?: UserCreated, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('User Created', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Send email to invite user
 *
 * @param {UsersInvited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function usersInvited(props?: UsersInvited, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Users Invited', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Track user played the video
 *
 * @param {VideoPlaying} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaying(props: VideoPlaying, options?: Segment.Options, callback?: Segment.Callback): void {
  const a = analytics();
  if (a) {
    a.track('Video Playing', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Fires a 'enrichment_provider' track call.
 *
 * @param {EnrichmentProvider} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function enrichmentProvider(
  props?: EnrichmentProvider,
  options?: Segment.Options,
  callback?: Segment.Callback,
): void {
  const a = analytics();
  if (a) {
    a.track('enrichment_provider', props || {}, withTypewriterContext(options), callback);
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * Fires a 'Allocator Investments Changed' track call.
   *
   * @param {AllocatorInvestmentsChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  allocatorInvestmentsChanged,
  /**
   * Fires when user opens allocator panel
   *
   * @param {AllocatorPanelOpened} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  allocatorPanelOpened,
  /**
   * Track failed analysis
   *
   * @param {AnalysisFailed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  analysisFailed,
  /**
   * Track analysis performed when all analysis blocks loaded in Front end
   *
   * @param {AnalysisPerformed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  analysisPerformed,
  /**
   * Track user create or update a template
   *
   * @param {AnalysisTemplateSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  analysisTemplateSaved,
  /**
   * Apply block level changes
   *
   * @param {ApplyBlockSettings} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  applyBlockSettings,
  /**
   * Fires a 'Block Font Size Changed' track call.
   *
   * @param {BlockFontSizeChanged} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  blockFontSizeChanged,
  /**
   * Track builder analysis settings
   *
   * @param {BuilderAnalysisPerformed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  builderAnalysisPerformed,
  /**
   * Track on bulk action complete
   *
   * @param {BulkActionPerformed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  bulkActionPerformed,
  /**
   * Fires a 'Cash Flow Settings Applied' track call.
   *
   * @param {CashFlowSettingsApplied} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cashFlowSettingsApplied,
  /**
   * Fires a 'Cash Flow Settings Modal Opened' track call.
   *
   * @param {CashFlowSettingsModalOpened} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cashFlowSettingsModalOpened,
  /**
   * User modifies an individual chart to adjust data display
   *
   * @param {ChartModified} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  chartModified,
  /**
   * Fires a 'Checklist Dismissed (Appcues)' track call.
   *
   * @param {ChecklistDismissedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checklistDismissedAppcues,
  /**
   * Fires a 'Checklist Item Completed (Appcues)' track call.
   *
   * @param {ChecklistItemCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checklistItemCompletedAppcues,
  /**
   * Fires a 'Checklist Item Started (Appcues)' track call.
   *
   * @param {ChecklistItemStartedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checklistItemStartedAppcues,
  /**
   * Fires a 'Checklist Shown (Appcues)' track call.
   *
   * @param {ChecklistShownAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checklistShownAppcues,
  /**
   * Fires a 'Checklist Skipped (Appcues)' track call.
   *
   * @param {ChecklistSkippedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checklistSkippedAppcues,
  /**
   * Error being thrown on comparison page
   *
   * @param {ComparisonFailed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  comparisonFailed,
  /**
   * Track comparison performed (both success or throw error)
   *
   * @param {ComparisonPerformed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  comparisonPerformed,
  /**
   * This event is triggered when an EU-user updates their cookie preferences via the Cookie Preferences Manager
   *
   * @param {CookiePreferencesModified} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cookiePreferencesModified,
  /**
   * Create new studio either from builder or studio page
   *
   * @param {CreatingNewStudios} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  creatingNewStudios,
  /**
   * CTA link or button is clicked
   *
   * @param {CtaClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  ctaClicked,
  /**
   * Triggered whenever custom metrics for a subject or changes column is cleared due to subjects changing
   *
   * @param {CustomMetricsCleared} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  customMetricsCleared,
  /**
   * chart exports (png, pdf, xlsx)
   *
   * @param {DataExported} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  dataExported,
  /**
   * User has opened the data management feature for a portfolio or investment
   *
   * @param {DataManagementOpened} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  dataManagementOpened,
  /**
   * Fires a 'Deleted Investment Override' track call.
   *
   * @param {DeletedInvestmentOverride} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  deletedInvestmentOverride,
  /**
   * User has downloaded documents
   *
   * @param {DocumentsDownloaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  documentsDownloaded,
  /**
   * Select a drawdown period in Hypothetical Drawdown Periods table
   *
   * @param {DrawdownSelected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  drawdownSelected,
  /**
   * Close dropdown menu(can fire either when user closes dropdown without changing selection or after item(s) are selected)
   *
   * @param {DropdownClosed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  dropdownClosed,
  /**
   * on onselect an option in dropdown
   *
   * @param {DropdownItemSSelected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  dropdownItemSSelected,
  /**
   * User clicks a dropdown
   *
   * @param {DropdownOpened} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  dropdownOpened,
  /**
   * Track factor lens insight request failure
   *
   * @param {FactorInsightsFailed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  factorInsightsFailed,
  /**
   * fires when user analyzes factor performance history in factor insight page, such as change selected factors, factor inputs or date range.
   *
   * @param {FactorsAnalyzed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  factorsAnalyzed,
  /**
   * Fires when user downloads file template (e.g. Excel file) from upload modal
   *
   * @param {FileTemplateDownloaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  fileTemplateDownloaded,
  /**
   * Fires a 'Flow Completed (Appcues)' track call.
   *
   * @param {FlowCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  flowCompletedAppcues,
  /**
   * Fires a 'Flow Skipped (Appcues)' track call.
   *
   * @param {FlowSkippedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  flowSkippedAppcues,
  /**
   * Fires a 'Flow Started (Appcues)' track call.
   *
   * @param {FlowStartedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  flowStartedAppcues,
  /**
   * Track when a user clicks to create a new forecast
   *
   * @param {ForecastCreationStarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forecastCreationStarted,
  /**
   * Fires a 'Forecast Factor Override' track call.
   *
   * @param {ForecastFactorOverride} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forecastFactorOverride,
  /**
   * Track when a user clicks to view an individual forecast
   *
   * @param {ForecastInspected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forecastInspected,
  /**
   * When set a forecast as default
   *
   * @param {ForecastSelected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forecastSelected,
  /**
   * Fires each time user opens Forecasts modal
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forecastsViewed,
  /**
   * Fires a 'Form Field Submitted (Appcues)' track call.
   *
   * @param {FormFieldSubmittedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  formFieldSubmittedAppcues,
  /**
   * Fires a 'Form Submitted (Appcues)' track call.
   *
   * @param {FormSubmittedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  formSubmittedAppcues,
  /**
   * Track sponsor has updated the custom content to be shown on home page to their sponsored accounts
   *
   * @param {HomeContentUpdated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  homeContentUpdated,
  /**
   * Track creation of Benchmark Group in IMv2
   *
   * @param {InputsBenchmarkGroupCreated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputsBenchmarkGroupCreated,
  /**
   * Track deletion of Benchmark Group in IMv2
   *
   * @param {InputsBenchmarkGroupDeleted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputsBenchmarkGroupDeleted,
  /**
   * Track edits of Benchmark Group in IMv2
   *
   * @param {InputsBenchmarkGroupModified} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputsBenchmarkGroupModified,
  /**
   * Track creation of Date Range Group in IMv2
   *
   * @param {InputsDateRangeGroupCreated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputsDateRangeGroupCreated,
  /**
   * Track deletion of Date Range Group in IMv2
   *
   * @param {InputsDateRangeGroupDeleted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputsDateRangeGroupDeleted,
  /**
   * Track edits of Date Range Group in IMv2
   *
   * @param {InputsDateRangeGroupModified} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputsDateRangeGroupModified,
  /**
   * Track creation of Subject Group in IMv2
   *
   * @param {InputsSubjectGroupCreated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputsSubjectGroupCreated,
  /**
   * Track edits of Subject Group in IMv2
   *
   * @param {InputsSubjectGroupDeleted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputsSubjectGroupDeleted,
  /**
   * Track deletion of Subject Group in IMv2
   *
   * @param {InputsSubjectGroupModified} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  inputsSubjectGroupModified,
  /**
   * Fires when update investment metadata
   *
   * @param {InvestmentMetadataSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  investmentMetadataSaved,
  /**
   * fires when user successful uploads investment(s) in uploader’s last step
   *
   * @param {InvestmentsUploaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  investmentsUploaded,
  /**
   * When user revokes, resends, or removes an invitation in their workspace.
   *
   * @param {InvitationAction} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  invitationAction,
  /**
   * fires when user use shortcut
   *
   * @param {KeyboardShortcutUsed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  keyboardShortcutUsed,
  /**
   * Fires when a filter in the library is selected
   *
   * @param {LibraryFiltersSelected} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  libraryFiltersSelected,
  /**
   * Link is clicked. Preferred over "CTA Clicked" when the link can be customised, so it's difficult to capture the "action" it's "calling to".
   *
   * @param {LinkClicked} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  linkClicked,
  /**
   * Fires when use see metered investments modal
   *
   * @param {MeteredInvestmentsViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  meteredInvestmentsViewed,
  /**
   * Fires when a user closes modal
   *
   * @param {ModalClosed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  modalClosed,
  /**
   * Fires when a user opens modal
   *
   * @param {ModalDisplayed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  modalDisplayed,
  /**
   * Fires a 'Modified Investment Override' track call.
   *
   * @param {ModifiedInvestmentOverride} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  modifiedInvestmentOverride,
  /**
   * Fires a 'NPS Ask Me Later Selected At (Appcues)' track call.
   *
   * @param {NpsAskMeLaterSelectedAtAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  npsAskMeLaterSelectedAtAppcues,
  /**
   * Fires a 'NPS Score (Appcues)' track call.
   *
   * @param {NpsScoreAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  npsScoreAppcues,
  /**
   * Fires a 'NPS Survey Started (Appcues)' track call.
   *
   * @param {NpsSurveyStartedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  npsSurveyStartedAppcues,
  /**
   * Fires when user navigate to different page
   *
   * @param {NavigationTriggered} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navigationTriggered,
  /**
   * Tracks venn notable periods added to the notable period block
   *
   * @param {NotablePeriodAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  notablePeriodAdded,
  /**
   * Fires when user select notable period in factor insights
   *
   * @param {NotablePeriodViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  notablePeriodViewed,
  /**
   * Fires when a user selects a peer group from the search modal in studio or RL
   *
   * @param {PeerGroupSelected} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  peerGroupSelected,
  /**
   * Track portfolio comparison selected from the Allocation Panel compare column
   *
   * @param {PortfolioComparisonSelected} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioComparisonSelected,
  /**
   * Track whenever user modifies constraints used to run Portfolio Lab optimization
   *
   * @param {PortfolioLabConstraintsModified} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioLabConstraintsModified,
  /**
   * Fires a 'Portfolio Lab Migration Accepted' track call.
   *
   * @param {PortfolioLabMigrationAccepted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioLabMigrationAccepted,
  /**
   * Fires a 'Portfolio Lab Migration Dismissed' track call.
   *
   * @param {PortfolioLabMigrationDismissed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioLabMigrationDismissed,
  /**
   * Track when Portfolio Lab doesn't have any solutions to display
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioLabNoSolutionsFound,
  /**
   * Track Portfolio Lab solution being saved
   *
   * @param {PortfolioLabSolutionSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioLabSolutionSaved,
  /**
   * Track which optimized solution appears as selected in Portfolio Lab
   *
   * @param {PortfolioLabSolutionViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioLabSolutionViewed,
  /**
   * track optimizer run
   *
   * @param {PortfolioOptimized} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioOptimized,
  /**
   * Track changes to portfolio policy
   *
   * @param {PortfolioPolicyModified} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioPolicyModified,
  /**
   * Track when a portfolios rebalancing strategy is changed
   *
   * @param {PortfolioRebalancingUpdated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioRebalancingUpdated,
  /**
   * fires when user saved portfolio in allocator
   *
   * @param {PortfolioSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioSaved,
  /**
   * fires when complete portfolio setup
   *
   * @param {PortfolioSetupStepCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioSetupStepCompleted,
  /**
   * When user view each setup step
   *
   * @param {PortfolioSetupStepViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  portfolioSetupStepViewed,
  /**
   * Fires when the print file finish render
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  printingCompleted,
  /**
   * Fires when user try to print
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  printingInitialized,
  /**
   * Fires when use click request pro
   *
   * @param {ProRequested} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  proRequested,
  /**
   * Fire when user saves changes to their profile.
   *
   * @param {ProfileEdited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileEdited,
  /**
   * Fires when user saves changes to their proxy or sets a new one
   *
   * @param {ProxyModified} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  proxyModified,
  /**
   * When a user is removed from a workspace
   *
   * @param {RemovedFromWorkspace} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  removedFromWorkspace,
  /**
   * Export of print Report Lab
   *
   * @param {ReportLabExport} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  reportLabExport,
  /**
   * On open of a saved report lab view
   *
   * @param {ReportLabOpened} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  reportLabOpened,
  /**
   * Fires a 'Reset Font Size to Workspace Defaults' track call.
   *
   * @param {ResetFontSizeToWorkspaceDefaults} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resetFontSizeToWorkspaceDefaults,
  /**
   * Fires a 'Reset View Font Sizes' track call.
   *
   * @param {ResetViewFontSizes} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resetViewFontSizes,
  /**
   * When a user's role is changed
   *
   * @param {RoleChanged} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  roleChanged,
  /**
   * Fires a 'Saved New Investment Override' track call.
   *
   * @param {SavedNewInvestmentOverride} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  savedNewInvestmentOverride,
  /**
   * Track properties of newly created shocks in scenario analysis
   *
   * @param {ScenarioCreated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  scenarioCreated,
  /**
   * Track properties of a deleted shock in scenario analysis
   *
   * @param {ScenarioDeleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  scenarioDeleted,
  /**
   * Track changes to the size of shock used in scenario analysis
   *
   * @param {ScenarioModified} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  scenarioModified,
  /**
   * When a search is blurred after searching
   *
   * @param {SearchDismissed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchDismissed,
  /**
   * Search Multi-Selection Made
   *
   * @param {SearchMultiSelectionMade} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchMultiSelectionMade,
  /**
   * When a search is made (debounced)
   *
   * @param {SearchQueryEntered} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchQueryEntered,
  /**
   * When an item is selected from search results
   *
   * @param {SearchSelectionMade} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchSelectionMade,
  /**
   * User activate account from invite or sign up
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupFunnelCompleted,
  /**
   * blacklisted, waitlisted, terms disagreed
   *
   * @param {SignupFunnelTerminated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupFunnelTerminated,
  /**
   * track which step that user completes
   *
   * @param {SignupStepCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupStepCompleted,
  /**
   * track which step user has seen
   *
   * @param {SignupStepViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupStepViewed,
  /**
   * Fires a 'Step Completed (Appcues)' track call.
   *
   * @param {StepCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  stepCompletedAppcues,
  /**
   * Fires a 'Step Interacted (Appcues)' track call.
   *
   * @param {StepInteractedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  stepInteractedAppcues,
  /**
   * Fires a 'Step Skipped (Appcues)' track call.
   *
   * @param {StepSkippedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  stepSkippedAppcues,
  /**
   * Fires a 'Step Started (Appcues)' track call.
   *
   * @param {StepStartedAppcues} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  stepStartedAppcues,
  /**
   * Export or print studio data
   *
   * @param {StudioExport} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  studioExport,
  /**
   * On open of a saved studio view
   *
   * @param {StudioOpened} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  studioOpened,
  /**
   * Input Text field update
   *
   * @param {TextFieldUpdated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  textFieldUpdated,
  /**
   * User clicks the "Large Toggle" element
   *
   * @param {ToggleToggled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  toggleToggled,
  /**
   * Fires when user open upgrade modal
   *
   * @param {UpgradeModalViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  upgradeModalViewed,
  /**
   * Fire before upload file (no matter success or failure)
   *
   * @param {UploadAttempted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  uploadAttempted,
  /**
   * track upload step completed
   *
   * @param {UploadStepCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  uploadStepCompleted,
  /**
   * track errors in upload
   *
   * @param {UploadStepFailed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  uploadStepFailed,
  /**
   * Fires in each upload step
   *
   * @param {UploadStepViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  uploadStepViewed,
  /**
   * Fires when switch tab on uploader
   *
   * @param {UploadTabChanged} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  uploadTabChanged,
  /**
   * New user created succesfully
   *
   * @param {UserCreated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userCreated,
  /**
   * Send email to invite user
   *
   * @param {UsersInvited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  usersInvited,
  /**
   * Track user played the video
   *
   * @param {VideoPlaying} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  videoPlaying,
  /**
   * Fires a 'enrichment_provider' track call.
   *
   * @param {EnrichmentProvider} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  enrichmentProvider,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/two-sigma/protocols/tracking-plans/rs_1WU03NuOuL5olxcAyg2OxpHAjaI`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext(),
        );
      }
    };
  },
});
