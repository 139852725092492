import { useMemo } from 'react';
import type { AnalysisView, PrintOrientationTypeEnum } from 'venn-api';
import { getPDF } from 'venn-api';
import {
  UrlParam,
  addUrlParam,
  analyticsService,
  assertNotNil,
  logExceptionIntoSentry,
  useDebugValue,
} from 'venn-utils';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { useRecoilCallback, waitForAll } from 'recoil';
import {
  allBlockIdsState,
  allViewSubjectIds,
  blockCustomMetricSettingsState,
  blockScenarios,
  blockSettings,
  studioPrintOrientationType,
} from 'venn-state';
import { useStudioExcelExport } from './useStudioExcelExport';
import { sumBy, zip } from 'lodash';

function writeToFile(data: BlobPart, name: string) {
  const blob = new Blob([data], { type: 'application/pdf' });
  const url = window.URL?.createObjectURL?.(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

const useStudioExport = (analysisViewRef: React.RefObject<AnalysisView | undefined>) => {
  const debugPageState = useDebugValue('debugPrintPage');

  const logExport = useRecoilCallback(
    ({ snapshot }) =>
      async (
        props:
          | { exportTo: 'Excel'; isSuccessful: boolean; blockCount: number }
          | { exportTo: 'PDF'; printOrientation: PrintOrientationTypeEnum; isSuccessful: boolean; isFinal: boolean },
      ) => {
        const analysisView = analysisViewRef.current ?? undefined;
        const blockIds = await snapshot.getPromise(allBlockIdsState);
        const blockInfos = zip(
          blockIds,
          await snapshot.getPromise(waitForAll(blockIds.map((id) => blockSettings(id)))),
          await snapshot.getPromise(waitForAll(blockIds.map((id) => blockCustomMetricSettingsState(id)))),
        ).map(([blockId, blockSetting, customMetrics]) => ({
          blockId: assertNotNil(blockId),
          blockSetting: assertNotNil(blockSetting),
          customMetrics: assertNotNil(customMetrics),
          blockType: assertNotNil(blockSetting).customBlockType,
        }));

        const numPortfolioComparisonBlocks = sumBy(
          blockInfos,
          ({ blockType }) => +(blockType === 'PORTFOLIO_COMPARISON'),
        );
        const numPortfolioComparisonBlocksWithCustomMetrics = sumBy(
          blockInfos,
          ({ customMetrics, blockType }) => +(customMetrics.length > 0 && blockType === 'PORTFOLIO_COMPARISON'),
        );
        const allScenarios = (await snapshot.getPromise(waitForAll(blockIds.map((id) => blockScenarios(id))))).flat();
        const logFunction = ['REPORT', 'REPORT_TEMPLATE'].includes(analysisView?.analysisViewType ?? '')
          ? analyticsService.reportLabExport
          : analyticsService.studioExport;

        const subjectIds = await snapshot.getPromise(allViewSubjectIds);
        logFunction({
          blockCount: blockIds.length,
          subjectIds,
          viewId: analysisView?.id,
          blocks: blockInfos.map(({ blockType }) => blockType),
          scenarioFundNames: allScenarios.map((s) => s.fundName),
          containsMacroScenarios: allScenarios.some((s) => s.type === 'MACRO'),
          containsMarketScenarios: allScenarios.some((s) => s.type === 'MARKET'),
          numPortfolioComparisonBlocks,
          numPortfolioComparisonBlocksWithCustomMetrics,
          ...props,
        });
      },
    [analysisViewRef],
  );

  const onExport = useRecoilCallback(
    ({ snapshot }) =>
      async (isInternal: boolean, analysisViewName?: string, analysisViewId?: string) => {
        const analysisView = analysisViewRef.current ?? undefined;
        /** isFinal is the opposite of isInternal; internal reports are not 'final'. */
        const isFinal = !isInternal;
        const filename = `${analysisView?.name ?? 'report'}.pdf`;
        let isSuccessful = true;
        const toastId = Notifications.notify(`Downloading '${filename}'`, NotificationType.LOADING);
        const printOrientationType = await snapshot.getPromise(studioPrintOrientationType);
        const urlParams = new URLSearchParams();
        urlParams.append('print', 'true');
        urlParams.append('internal', String(isInternal));
        debugPageState?.enabledOnPrint && addUrlParam(urlParams, UrlParam.PRINT_DEBUG, debugPageState);
        const restUrlParams = `&${urlParams.toString()}`;
        try {
          const response = await getPDF({
            printOrientationType,
            url:
              window.location.hostname === 'localhost'
                ? `https://dev.tsvenn.com${window.location.pathname}${window.location.search}${restUrlParams}`
                : `${window.location.href}${restUrlParams}`,
            final: isFinal,
            ownerContextId: analysisView?.ownerContextId,
            name: analysisViewName ?? analysisView?.name,
            viewId: analysisViewId ?? analysisView?.id,
          });

          writeToFile(response.content, filename);
          Notifications.notifyUpdate(toastId, `Downloaded '${filename}'`, NotificationType.SUCCESS);
        } catch (exception) {
          isSuccessful = false;
          Notifications.notifyUpdate(toastId, `Downloading '${filename}' failed`, NotificationType.ERROR);
          exception && logExceptionIntoSentry(exception);
        } finally {
          logExport({
            exportTo: 'PDF',
            printOrientation: printOrientationType,
            isSuccessful,
            isFinal,
          });
        }
      },
    [analysisViewRef, debugPageState, logExport],
  );

  const onExcelExport = useStudioExcelExport(analysisViewRef, logExport);

  return useMemo(
    () => ({
      onExport,
      onExcelExport,
    }),
    [onExcelExport, onExport],
  );
};

export default useStudioExport;
