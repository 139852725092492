import type { TabularDataRow } from '../types';
import type { Analysis, FactorLensWithReturns } from 'venn-api';
import type { ComparisonSubject, ExcelCell } from 'venn-utils';
import { Dates } from 'venn-utils';
import getAnalysisBlocks from '../analysisBlocks';
import type { DateRange } from 'venn-ui-kit';
import { onExportExcel } from 'venn-components';
import times from 'lodash/times';
import { getAppTitle } from 'venn-ui-kit';

const BLOCK_ROW_SPACING = 2;
const SHEET_NAME = `${getAppTitle()} Comparison`;

const getFilename = (dateRange: DateRange) =>
  `venn_comparison_${dateRange.from ? Dates.toYYYYMMDD(dateRange.from, '-') : 'start'}-${
    dateRange.to ? Dates.toYYYYMMDD(dateRange.to, '-') : 'end'
  }.xlsx`;

export const xlsxDownload = async (
  subjects: ComparisonSubject[],
  analyses: Analysis[],
  relative: boolean,
  factorLens: FactorLensWithReturns,
  dateRange: DateRange,
  isPivoted: boolean,
  hideForecasts: boolean,
) => {
  const blocksWithData = getAnalysisBlocks(hideForecasts)
    .filter((block) => !block.hideInPrint)
    .map((block) => ({
      ...block,
      data: block?.extractor?.(
        subjects,
        analyses.find((a) => a.analysisType === block.type),
        relative,
        factorLens,
      ),
    }));

  const allExcelData: (ExcelCell | undefined)[][] = [];

  if (!isPivoted) {
    for (const block of blocksWithData) {
      const headerRow: ExcelCell[] = [
        { value: block.getTitle(relative), bold: true },
        ...subjects
          .filter((s) => !(relative && s.isBenchmark))
          .map((s) => ({
            value: s.analysisSubject?.name,
            bold: true,
          })),
      ];
      allExcelData.push(headerRow);

      // Convert each row to an array of values
      block.data?.forEach((data: TabularDataRow) => {
        const cellData: ExcelCell[] = [];
        for (let subjectIdx = 0; subjectIdx < subjects.length; subjectIdx++) {
          const subject = subjects[subjectIdx];
          // Skip the benchmark column when relative
          if (!(relative && subject.isBenchmark)) {
            cellData.push({
              value: data?.[subjectIdx]?.value ?? '--',
              percentage: data?.type === 'percentage',
              digits: 2,
            });
          }
        }
        allExcelData.push([{ value: data.label }, ...cellData]);
      });

      times(BLOCK_ROW_SPACING, () => allExcelData.push([]));
    }
  } else {
    const EMPTY: ExcelCell = { value: undefined };

    const superHeaderRow: ExcelCell[] = [EMPTY];
    const headerRow: ExcelCell[] = [EMPTY];
    const cellData: ExcelCell[][] = [];

    subjects.forEach((s) => {
      cellData.push([
        {
          value: s.analysisSubject?.name,
          bold: true,
        },
      ]);
    });

    for (const block of blocksWithData) {
      superHeaderRow.push({ value: block.getTitle(relative), bold: true });

      block.data?.forEach((data: TabularDataRow, rowIdx: number) => {
        if (rowIdx !== 0) {
          superHeaderRow.push(EMPTY);
        }
        headerRow.push({ value: data.label });

        subjects.forEach((_, subjectIdx: number) => {
          cellData[subjectIdx].push({
            value: data?.[subjectIdx]?.value ?? '--',
            percentage: data?.type === 'percentage',
            digits: 2,
          });
        });
      });
    }

    if (relative && subjects?.[0].isBenchmark) {
      cellData.shift();
    }

    allExcelData.push(superHeaderRow);
    allExcelData.push(headerRow);
    cellData.forEach((row: ExcelCell[]) => {
      allExcelData.push(row);
    });
  }

  const filename = getFilename(dateRange);
  await onExportExcel(allExcelData, filename, SHEET_NAME, true);
};
