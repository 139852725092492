import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import type { EmailSubscriptionSetting } from 'venn-api';
import { getUserEmailSubscriptions, updateEmailSubscription } from 'venn-api';
import { SliderToggle } from 'venn-components';
import { StyledWrapper, Name, FlexWrapper, FlexRow, StyledIcon, Info } from './components/shared';
import { analyticsService } from 'venn-utils';
import { getAppTitle } from 'venn-ui-kit';

const FACTOR_ALERTS_NOTIFICATION_KEY = '9167';

const Notifications = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [isToggledOn, setIsToggledOn] = useState<boolean>(false);

  useEffect(() => {
    const fetchInitialSetting = async () => {
      setLoading(true);

      try {
        const subscriptions: EmailSubscriptionSetting[] = (await getUserEmailSubscriptions()).content;
        const factorAlertsSubscription = subscriptions.find(
          (subscription: EmailSubscriptionSetting) => subscription.key === FACTOR_ALERTS_NOTIFICATION_KEY,
        );
        setIsToggledOn(factorAlertsSubscription !== undefined && factorAlertsSubscription.enabled);
        setError(false);
      } catch (e) {
        setIsToggledOn(false);
        setError(true);
      }

      setLoading(false);
    };
    fetchInitialSetting();
  }, []);

  const onToggle = async (enabled: boolean) => {
    setLoading(true);

    try {
      await updateEmailSubscription({
        key: FACTOR_ALERTS_NOTIFICATION_KEY,
        provider: 'SENDGRID',
        enabled,
      });
      setIsToggledOn(enabled);
      analyticsService.toggleToggled({
        purpose: 'Toggle to receive factor alert emails',
        selection: enabled ? 'On' : 'Off',
        locationOnPage: 'Account - Notifications',
      });

      setError(false);
    } catch (e) {
      setIsToggledOn(false);
      setError(true);
    }

    setLoading(false);
  };

  return (
    <>
      <Info>
        {`We'll always let you know about important changes to ${getAppTitle()} and your account. You pick what else you want to hear
        about.`}
      </Info>
      <StyledWrapper>
        <FlexRow>
          <FlexWrapper>
            <StyledIcon type="bell" />
            <Name>Factor Alert Emails</Name>
          </FlexWrapper>
          <RightElement>
            <SliderToggle
              disabled={error}
              tooltipMessage={error ? 'Failed to load settings. Please try again later.' : undefined}
              toggled={isToggledOn}
              onToggle={() => onToggle(!isToggledOn)}
              loading={loading}
            />
          </RightElement>
        </FlexRow>
        <StyledInfo>
          Factor alert emails notify you of significant movements in the Two Sigma Factor Lens as it pertains to your
          master portfolio.
        </StyledInfo>
      </StyledWrapper>
    </>
  );
};

export default Notifications;

const StyledInfo = styled(Info)`
  margin-top: 4px;
  padding-bottom: 10px;
`;

const RightElement = styled.div`
  height: 32px;
  display: flex;
`;
