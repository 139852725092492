import type { ProxyTypeEnum } from 'venn-api';
import { capitalizeFirstLetter } from '../strings';
import { assertExhaustive } from '../type';

export const getFormattedProxyType = (proxyType: ProxyTypeEnum): string => {
  switch (proxyType) {
    case 'DESMOOTH_INTERPOLATE':
      return 'Desmoothing with Interpolation';
    default:
      return capitalizeFirstLetter(proxyType.toLowerCase());
  }
};

export const getProxyTypeDescription = (proxyType: ProxyTypeEnum): string => {
  switch (proxyType) {
    case 'BACKFILL':
      return 'Extend past performance';
    case 'INTERPOLATE':
      return 'Increase return frequency to daily';
    case 'SUBSTITUTE':
      return 'Replace with a different investment';
    case 'DESMOOTH':
      return 'Reverse the smoothing process';
    case 'DESMOOTH_INTERPOLATE':
      return 'Reverse the smoothing process and increase return frequency to daily';
    case 'EXTRAPOLATE':
      return 'Extend performance forward';
    default:
      throw assertExhaustive(proxyType, 'Unexpected proxy type');
  }
};
