import { mockTheme } from 'venn-utils';
import { theme } from '../global';
import { userIsAuthenticated } from '../user';
import { makeAtomValuePair, makeState } from 'venn-test/recoil';
import { apiPrintSettings, DEFAULT_FONT_SIZE_OVERRIDES } from '../studio/fonts';
import type { PrintSettings } from 'venn-api';

/** A simple array of common states to kickstart testing that involves Recoil and potentially Studio.  */
export const basicAuthedStudio = () =>
  Object.freeze([
    makeState([userIsAuthenticated, true]),
    makeState([theme, mockTheme]),
    makeAtomValuePair({ atom: apiPrintSettings, value: DEFAULT_FONT_SIZE_OVERRIDES as PrintSettings }),
  ]);
