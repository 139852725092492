/*
 * Venn Platform API 1.0.0 Operations for "analysis"
 * Automatically generated file - do not modify!
 */

import type {
  AnalysisRequest,
  AnalysisResponse,
  AnalysisSearch,
  AnalysisTemplate,
  AnalysisView,
  GeneralAnalysisTemplate,
  PageAnalysisViewSearchResult,
  PrivatesAnalysisRequest,
  PrivatesAnalysisResponse,
  ShareMetadata,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Append a child view */
export async function saveChildView(
  id: string,
  body?: Partial<AnalysisView>,
  signal?: AbortSignal,
): Promise<OperationResult<AnalysisView>> {
  const endpoint = `/api/analysis/view/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AnalysisView = (await response.json()) as AnalysisView;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Check if given view name is already used for current permission group */
export async function checkViewName(name?: string, signal?: AbortSignal): Promise<OperationResult<boolean>> {
  const endpoint = `/api/analysis/view/exists${buildQuery({ name })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: boolean = (await response.json()) as boolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete a venn template */
export async function deleteVennTemplate(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/analysis/view/${encodeURIComponent(id)}/venn`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete user saved analysis view by id */
export async function deleteAnalysisView(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/analysis/view/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Demote a studio document from being a template */
export async function reportUntemplatize(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/analysis/view/${encodeURIComponent(id)}/untemplatize`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Designate a template as a system provided template */
export async function setVennTemplate(
  id: string,
  description?: string,
  isPrivateAssetTemplate?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/analysis/view/${encodeURIComponent(id)}/venn${buildQuery({ description, isPrivateAssetTemplate })}`;
  const requestInit = buildRequestInit({ method: 'PUT', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Promote a studio document to become a template */
export async function reportTemplatize(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/analysis/view/${encodeURIComponent(id)}/templatize`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Publish a studio document */
export async function reportPublish(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/analysis/view/${encodeURIComponent(id)}/publish`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve all templates includes block templates and Venn templates */
export async function getAllGeneralTemplates(
  onlyHasAccess?: boolean,
  signal?: AbortSignal,
): Promise<OperationResult<GeneralAnalysisTemplate[]>> {
  const endpoint = `/api/analysis/templates/all${buildQuery({ onlyHasAccess })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: GeneralAnalysisTemplate[] = (await response.json()) as GeneralAnalysisTemplate[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve analysis for private subjects */
export async function privatesAnalysis(
  body?: Partial<PrivatesAnalysisRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<PrivatesAnalysisResponse>> {
  const endpoint = '/api/analysis/privates';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivatesAnalysisResponse = (await response.json()) as PrivatesAnalysisResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve list of report templates */
export async function deleteReportTemplate(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/analysis/templates/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve list of report templates */
export async function getReportTemplate(id: string, signal?: AbortSignal): Promise<OperationResult<AnalysisTemplate>> {
  const endpoint = `/api/analysis/templates/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AnalysisTemplate = (await response.json()) as AnalysisTemplate;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve list of report templates */
export async function getReportTemplates(signal?: AbortSignal): Promise<OperationResult<AnalysisTemplate[]>> {
  const endpoint = '/api/analysis/templates';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AnalysisTemplate[] = (await response.json()) as AnalysisTemplate[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve set of analyses */
export async function analysis(
  body?: Partial<AnalysisRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<AnalysisResponse>> {
  const endpoint = '/api/analysis';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AnalysisResponse = (await response.json()) as AnalysisResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve the analysis view by id */
export async function getSpecificAnalysisView(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<AnalysisView>> {
  const endpoint = `/api/analysis/view/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AnalysisView = (await response.json()) as AnalysisView;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Save analysis view */
export async function saveAnalysisView(
  body?: Partial<AnalysisView>,
  signal?: AbortSignal,
): Promise<OperationResult<AnalysisView>> {
  const endpoint = '/api/analysis/view';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AnalysisView = (await response.json()) as AnalysisView;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Save report template */
export async function saveReportTemplate(
  body?: Partial<AnalysisTemplate>,
  signal?: AbortSignal,
): Promise<OperationResult<AnalysisTemplate>> {
  const endpoint = '/api/analysis/templates';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AnalysisTemplate = (await response.json()) as AnalysisTemplate;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Search saved analysis view */
export async function searchAnalysisView(
  body?: Partial<AnalysisSearch>,
  signal?: AbortSignal,
): Promise<OperationResult<PageAnalysisViewSearchResult>> {
  const endpoint = '/api/analysis/view/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PageAnalysisViewSearchResult = (await response.json()) as PageAnalysisViewSearchResult;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Share a view link by email */
export async function shareViewLink(
  body?: Partial<ShareMetadata>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/analysis/share';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Unpublish a studio document */
export async function reportUnpublish(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/analysis/view/${encodeURIComponent(id)}/unpublish`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
