import React from 'react';
import styled from 'styled-components';
import type { AnalysisSubject } from 'venn-utils';
import { getComparisonLabelForBlockLegend } from 'venn-utils';
import { GetColor, Icon, ItemIcon, ItemType } from 'venn-ui-kit';
import { convertRequestSubjectToItemType, getUniqueKeyFromStudioRequestSubject } from 'venn-components';
import {
  blockDoesNotSupportBenchmark,
  blockDoesNotSupportPrivateBenchmark,
  blockSelectedPeerGroupId,
  blockSettings,
  blockFonts,
  getCustomFontSize,
  type StudioRequestSubject,
  type CustomFont,
} from 'venn-state';
import { useRecoilValue } from 'recoil';

interface BlockObjectsProps {
  blockId: string;
  subjects: StudioRequestSubject[];
  individualBenchmarks: boolean;
  commonBenchmark?: AnalysisSubject;
  showInvestments?: boolean;
  forceHideBenchmarks?: boolean;
}

const Item = ({
  icon,
  name,
  selectedStrategyName,
}: {
  icon: JSX.Element;
  name: string;
  selectedStrategyName?: string;
}) => (
  <>
    {icon}
    <Name>
      {selectedStrategyName ? (
        <>
          {name.slice(0, name.length - selectedStrategyName.length)}
          <b>{selectedStrategyName}</b>
        </>
      ) : (
        name
      )}
    </Name>
  </>
);

const BlockObjects = ({
  blockId,
  subjects,
  commonBenchmark,
  individualBenchmarks,
  showInvestments,
  forceHideBenchmarks,
}: BlockObjectsProps) => {
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const headerInfoFont = useRecoilValue(blockFonts.blockHeaderInfo(blockId));
  const hideBenchmark = useRecoilValue(blockDoesNotSupportBenchmark(blockId)) || forceHideBenchmarks;
  const hidePrivateBenchmark = useRecoilValue(blockDoesNotSupportPrivateBenchmark(blockId)) || forceHideBenchmarks;
  const peerGroupInformation = useRecoilValue(blockSelectedPeerGroupId(blockId));
  const hideAllSubjects = blockSetting.customBlockType === 'SCENARIO';
  const trimmedSubjects = hideAllSubjects ? [] : subjects;

  return (
    <Wrapper fontSize={headerInfoFont}>
      {commonBenchmark && !hideBenchmark && (
        <ItemPair>
          <ItemIcon item={ItemType.CommonBenchmark} />
          <Name>{commonBenchmark.name}</Name>
          <SeparatorIcon type="pipe" />
        </ItemPair>
      )}
      {trimmedSubjects.map(
        (subject) =>
          (subject.subjectType !== 'INVESTMENT' ||
            (subject.subjectType === 'INVESTMENT' && (showInvestments === undefined || showInvestments))) && (
            <ItemPair key={getUniqueKeyFromStudioRequestSubject(subject)}>
              <Item
                icon={
                  subject.portfolioComparisonType === 'SAVED' ? (
                    <Icon type="clock-rotate-left" />
                  ) : (
                    <ItemIcon item={convertRequestSubjectToItemType(subject)} />
                  )
                }
                name={getComparisonLabelForBlockLegend(subject)}
                selectedStrategyName={
                  subject.portfolio && subject.strategy && subject.portfolio.id !== subject.strategy.id
                    ? subject.strategy.name
                    : undefined
                }
              />
              {individualBenchmarks &&
              !hideBenchmark &&
              (!subject.private || (subject.private && !hidePrivateBenchmark)) ? (
                <>
                  <SeparatorIcon type="pipe" />
                  <ItemIcon item={ItemType.CommonBenchmark} />
                  {subject.individualBenchmark?.name || 'No Benchmark'}
                </>
              ) : null}
            </ItemPair>
          ),
      )}
      {peerGroupInformation && (
        <ItemPair key={peerGroupInformation.categoryName}>
          <Item
            icon={<ItemIcon item={ItemType.PeerGroup} className="far" />}
            name={peerGroupInformation.categoryName}
          />
        </ItemPair>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ fontSize: CustomFont | undefined }>`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ fontSize }) => (fontSize ? '0.6em' : '20px')};
  row-gap: ${({ fontSize }) => (fontSize ? '0.3em' : '10px')};
  align-items: center;
  ${({ fontSize }) => `font-size: ${fontSize ? getCustomFontSize(fontSize) : '11px'};`}
  max-width: 100%;
  padding: 0.5em 20px;
  border-bottom: 1px solid ${GetColor.Grey};
`;

const ItemPair = styled.div`
  display: flex;
  column-gap: 5px;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
`;

const Name = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SeparatorIcon = styled(Icon)`
  color: ${GetColor.Grey};
`;

export default BlockObjects;
