import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import type { TooltipBodyDirection } from 'venn-ui-kit';
import { GetColor, Icon, Tooltip, TooltipPosition } from 'venn-ui-kit';
import { buttonize } from 'venn-utils';
import type { EditMode } from 'venn-components';
import { StudioEditModeContext } from 'venn-components';
import { TopBarColor } from './shared';
import MenuCaret from './MenuCaret';
import type { TopBarDropdownMenuOption } from './TopBarDropdownMenu';
import TopBarDropdownMenu from './TopBarDropdownMenu';
import { useRecoilValue } from 'recoil';
import { isReportState } from 'venn-state';

interface StudioTileProps {
  small?: boolean;
  onClick?: () => void;
  type: string;
  disabled?: boolean;
  header?: boolean;
  prefix?: string;
  tooltip?: string;
  bodyDirection?: TooltipBodyDirection;
  options?: TopBarDropdownMenuOption[];
  dropdownStyle?: React.CSSProperties;
}

const StudioTile = React.memo(function StudioTile({
  small,
  onClick,
  type,
  disabled,
  header,
  prefix,
  tooltip,
  bodyDirection,
  options,
  dropdownStyle,
}: StudioTileProps) {
  const editMode = useContext(StudioEditModeContext);
  const isReport = useRecoilValue(isReportState);

  return (
    <Tooltip content={tooltip} position={TooltipPosition.Bottom} usePortal bodyDirection={bodyDirection}>
      <IconWrapper
        data-testid={tooltip}
        {...(onClick && !disabled ? buttonize(onClick) : {})}
        prefix={prefix}
        small={small}
        disabled={disabled}
        header={header}
        clickable={(!!onClick || options) && !disabled}
        editMode={editMode}
        isReport={isReport}
      >
        {options ? (
          <TopBarDropdownMenu options={options} dropdownStyle={dropdownStyle}>
            <Flex>
              <Icon type={type} />
              <StyledMenuCaret />
            </Flex>
          </TopBarDropdownMenu>
        ) : (
          <Icon type={type} />
        )}
      </IconWrapper>
    </Tooltip>
  );
});

export default StudioTile;

const IconWrapper = styled.div<{
  small?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  header?: boolean;
  editMode: EditMode;
  isReport?: boolean;
}>`
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ small }) => (small ? 32 : 36)}px;
  height: ${({ small }) => (small ? 32 : 36)}px;

  font-size: 18px;
  color: ${({ header }) => (header ? GetColor.White : GetColor.Primary.Dark)};
  background-color: ${({ header, editMode, isReport }) =>
    header ? TopBarColor[editMode][isReport ? 'ReportBackground' : 'Background'] : GetColor.White};
  border-radius: 6px;

  transition: all 0.2s ease-out;

  > i {
    font-weight: normal;
  }

  > div {
    display: flex;
  }

  ${({ disabled, clickable, header, editMode, isReport }) =>
    disabled
      ? css`
          ${header
            ? css`
                color: ${TopBarColor[editMode].MidGrey2};
              `
            : ''};
          cursor: not-allowed;
        `
      : clickable &&
        css`
          :hover {
            background-color: ${header
              ? TopBarColor[editMode][isReport ? 'ReportActiveBackground' : 'ActiveBackground']
              : GetColor.GreyScale.Grey10};
          }
        `}
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMenuCaret = styled(MenuCaret)`
  font-size: 14px;
  margin-left: 5px;
`;
