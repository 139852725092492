import React from 'react';
import styled from 'styled-components';
import { FootnoteSymbols, getBaseFee, getComparisonLabelForBlockLegend, hasFeesExcluded, Numbers } from 'venn-utils';
import type { StudioRequestSubject } from 'venn-state';
import { useBlockId } from '../studio-blocks/contexts/BlockIdContext';
import { useRecoilValue } from 'recoil';
import { blockSupportsFees } from 'venn-state';

export const NameWithOptionalFee = ({ subject }: { subject: StudioRequestSubject }) => {
  const blockId = useBlockId();
  const name = getComparisonLabelForBlockLegend(subject);
  const supportsFees = useRecoilValue(blockSupportsFees(blockId));
  const fee = getBaseFee(subject);

  if (!supportsFees || fee === 0) {
    return <>{name}</>;
  }

  const feesExcluded = hasFeesExcluded(subject);

  return (
    <NameWithFeeContainer>
      <NameContainer>{name}</NameContainer>{' '}
      <StyledFee>
        ({Numbers.safeFormatPercentage(fee)} Fee){feesExcluded && FootnoteSymbols.excludedInvestments}
      </StyledFee>
    </NameWithFeeContainer>
  );
};

export const formatSubjectWithOptionalFee = (subject: StudioRequestSubject) => {
  return formatNameWithOptionalFee(
    getComparisonLabelForBlockLegend(subject),
    getBaseFee(subject),
    hasFeesExcluded(subject),
  );
};

export const formatNameWithOptionalFee = (name: string, fee?: number, feesExcluded?: boolean): string => {
  if (!fee) return name;
  return `${name} <span style="font-weight:normal; font-style: italic">(${Numbers.safeFormatPercentage(fee)} Fee)${
    feesExcluded ? FootnoteSymbols.excludedInvestments : ''
  }</span>`;
};

export const formatExportableSubjectWithOptionalFee = (subject: StudioRequestSubject) => {
  return formatExportableNameWithOptionalFee(
    getComparisonLabelForBlockLegend(subject),
    getBaseFee(subject),
    hasFeesExcluded(subject),
  );
};

export const formatExportableNameWithOptionalFee = (name: string, fee?: number, feesExcluded?: boolean): string => {
  if (!fee) return name;
  return `${name} (${Numbers.safeFormatPercentage(fee)} Fee)${feesExcluded ? FootnoteSymbols.excludedInvestments : ''}`;
};

const NameWithFeeContainer = styled.span`
  align-content: center;
`;

const NameContainer = styled.span`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledFee = styled.span`
  font-style: italic;
  font-weight: normal;
  max-width: 80px;
`;

export default NameWithOptionalFee;
