import { compact, isNil } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { Snapshot } from 'recoil';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import {
  AllocationPanelLauncherAction,
  CashflowSettingsModal,
  PrivatesAllocatorLauncher,
  SecondarySubjectLine,
  SubjectGroupEditor,
  UserContext,
} from 'venn-components';
import {
  analysisSubjectQuery,
  analysisViewIdState,
  isReportState,
  onSubjectInputDelete,
  requestSubjects,
  type Subject,
  subjectInputGroupName,
  subjectInputGroupSubjects,
  type SubjectInputId,
  type SubjectWithOptionalFee,
  useCachedLoadableValue,
  useSendSignal,
} from 'venn-state';
import type { StudioMenuOption } from 'venn-ui-kit';
import { EllipsisTooltipSpan, GetColor, Icon, ItemIcon, ItemType, StudioMenu, TitleShimmer } from 'venn-ui-kit';
import {
  type AnalysisSubject,
  analyticsService,
  assertExhaustive,
  getBaseFee,
  getRequestSubjectForSecondaryAnalysisSubject,
  MANAGE_DATA_SECTION,
  navigateToManageDataPage,
  useHasFF,
  useModal,
} from 'venn-utils';
import { InputRow } from './InputRow';
import { SingleSubjectSearchPopover } from './SingleSubjectSearchPopover';

const MENU_CLASS = 'subject-menu';
const ACTION_MENU_CLASS = 'action-menu';

const AllocatorLauncher = ({ subject }: { subject?: AnalysisSubject }) => (
  <>
    {subject && <AllocationPanelLauncherAction subject={subject} />}
    {subject?.type === 'private-portfolio' && <PrivatesAllocatorLauncher subject={subject} />}
  </>
);

const SubjectName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 5px 0 15px;
  font-size: 14px;
  height: 30px;
  flex: 1;

  .${MENU_CLASS} {
    left: unset;
    right: -100%;
  }
`;

interface SubjectItemProps {
  subject: SubjectWithOptionalFee;
  onDelete: () => void;
  /** onSwap benefits from memoization, as it can otherwise cause excessive rendering of the dropdown. */
  onSwap: (newSubject: AnalysisSubject) => void;
  readonly: boolean;
  hideAllocatorLauncher?: boolean;
  hideManageData?: boolean;
  /** The subject group the subject is part of */
  groupId: SubjectInputId;
  showAdvisoryFee?: boolean;
  className?: string;
}

type Action = 'Manage Data' | 'Remove Subject' | 'Change Subject' | 'CF Pacing Model Parameters';

export const SubjectItem = ({
  subject,
  onDelete,
  onSwap,
  readonly,
  hideAllocatorLauncher,
  hideManageData,
  groupId,
  showAdvisoryFee,
  className,
}: SubjectItemProps) => {
  const analysisSubject = useCachedLoadableValue(analysisSubjectQuery(subject), undefined);

  const groupSubjects = useRecoilValue(subjectInputGroupSubjects(groupId));
  const history = useHistory();
  const [isSwapOpen, openSwap, closeSwap] = useModal();
  const [isCashflowSettingsModalOpen, openCashflowSettingsModal, closeCashflowSettingsModal] = useModal();

  const isReport = useRecoilValue(isReportState);
  const advisoryFee = isReport ? getBaseFee(subject) : undefined;
  const numberExcluded = Object.keys(subject.feesMapping ?? {}).length - 1;

  const { hasPermission } = useContext(UserContext);
  const hasPrivatesCashFlowSettingsFF = useHasFF('privates_hyperparameters_ff');
  const hasPrivatesCashFlowSettingsEditingFF = useHasFF('privates_hyperparameters_editing_ff');
  const hasEditHyperparametersPermission = hasPermission('EDIT_HYPERPARAMETERS');

  const onSwapSubmit = useCallback(
    (newSubject: AnalysisSubject) => {
      closeSwap();
      onSwap(newSubject);
    },
    [closeSwap, onSwap],
  );

  const refreshStudioView = useSendSignal({ type: 'WorkspaceConfigurationUpdate' });

  if (!analysisSubject) {
    return (
      <SubjectName className={className}>
        <TitleShimmer />
      </SubjectName>
    );
  }

  const onSelectOverMenuOption = (option: Action) => {
    analyticsService.ctaClicked({
      locationOnPage: 'Subject action menu',
      purpose: option,
      text: option,
    });
    switch (option) {
      case 'Manage Data':
        return navigateToManageDataPage(history, subject, 'Studio', true);
      case 'CF Pacing Model Parameters':
        return hasPrivatesCashFlowSettingsEditingFF && hasEditHyperparametersPermission
          ? openCashflowSettingsModal()
          : navigateToManageDataPage(history, subject, 'Studio', true, MANAGE_DATA_SECTION.CASH_FLOW_PACING_SETTINGS);
      case 'Remove Subject':
        return onDelete();
      case 'Change Subject':
        return openSwap();
      default:
        return assertExhaustive(option);
    }
  };

  const menuOptions: StudioMenuOption<Action>[] = compact([
    readonly ? undefined : { value: 'Change Subject' },
    hideManageData ? undefined : { value: 'Manage Data' },
    analysisSubject.private && hasPrivatesCashFlowSettingsFF ? { value: 'CF Pacing Model Parameters' } : undefined,
    readonly ? undefined : { value: 'Remove Subject' },
  ]);

  const isSelectedStrategyOrInvestment = analysisSubject.id !== analysisSubject.superId;
  const secondary = getRequestSubjectForSecondaryAnalysisSubject(analysisSubject);
  const itemType = analysisSubject.portfolio
    ? ItemType.Portfolio
    : analysisSubject.privatePortfolio
      ? ItemType.PrivatePortfolio
      : analysisSubject.private
        ? ItemType.PrivateInvestment
        : ItemType.Investment;
  return (
    <>
      {isCashflowSettingsModalOpen && (
        <CashflowSettingsModal
          onChangesApplied={refreshStudioView}
          onClose={closeCashflowSettingsModal}
          privatePortfolio={analysisSubject?.privatePortfolio}
          subjectName={analysisSubject?.privateFund?.name}
          triggeringFundId={analysisSubject?.privateFund?.id}
          source="STUDIO_SUBJECTS_INPUTS"
        />
      )}
      {isSwapOpen && (
        <SingleSubjectSearchPopover
          excludedSubjects={groupSubjects}
          onClose={closeSwap}
          onSubjectSelected={onSwapSubmit}
          currentLocationForAnalytics="SubjectGroupRow"
          leftOffset={22}
        />
      )}
      <SubjectLinesContainer>
        <SubjectName data-testid="qa-subject-item" className={className}>
          <SpacedItems>
            <MaybeNestedIcons isNested={isSelectedStrategyOrInvestment}>
              <ItemIcon
                item={itemType}
                dataSource={analysisSubject.superItem.dataSource || analysisSubject.portfolio?.dataSource}
                isUploaded={analysisSubject.userUploaded && !analysisSubject.isCompositeBenchmark}
                investmentSource={analysisSubject.fund?.investmentSource}
              />
              {isSelectedStrategyOrInvestment && (
                <>
                  <Icon type="corner" />
                  <ItemIcon item={analysisSubject.strategy?.fund ? ItemType.Investment : ItemType.Portfolio} />
                </>
              )}
            </MaybeNestedIcons>
            <EllipsisTooltipSpan flex maxWidth={hideAllocatorLauncher ? 198 : 184}>
              {analysisSubject.name}
            </EllipsisTooltipSpan>
          </SpacedItems>
          <SpacedItems className={ACTION_MENU_CLASS}>
            {!hideAllocatorLauncher && <AllocatorLauncher subject={analysisSubject} />}
            {menuOptions.length > 0 && (
              <StudioMenu
                renderItem={({ value }) => (
                  <MenuButtonContainer isDelete={value === 'Remove Subject'}>{value}</MenuButtonContainer>
                )}
                options={menuOptions}
                onSelect={onSelectOverMenuOption}
                size="tiny"
              />
            )}
          </SpacedItems>
        </SubjectName>
        {showAdvisoryFee && advisoryFee ? (
          <AdvisoryFee>
            {advisoryFee.toLocaleString('en-US', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            Advisory Fee {numberExcluded > 0 && `(${numberExcluded} excluded)`}
          </AdvisoryFee>
        ) : null}
        {!isNil(secondary) && <SecondarySubjectLine subject={secondary} />}
      </SubjectLinesContainer>
    </>
  );
};

const MenuButtonContainer = styled.div<{ isDelete: boolean }>`
  color: ${({ isDelete }) => (isDelete ? GetColor.Error : GetColor.Black)};
`;

const SubjectLinesContainer = styled.div`
  width: 100%;
  & > :nth-child(2) {
    i {
      margin-left: -11px;
      margin-top: -4px;
    }

    > span:not(:first-child) {
      max-width: calc(100% - 40px);
    }

    > span:first-child {
      margin-top: -1px;
    }
  }
`;

interface SubjectGroupRowWrapperProps {
  groupId: SubjectInputId;
  readonly: boolean;
  noHoverStyles?: boolean;
  trackGroupChanged: (
    snapshot: Snapshot,
    groupId: SubjectInputId,
    groupName: string,
    newSubjects: Subject[],
    action: 'CREATE' | 'EDIT',
  ) => void;
  children: React.ReactNode;
  className?: string;
  deletable: boolean;
}

export const SubjectGroupRowWrapper = ({
  groupId,
  readonly,
  children,
  trackGroupChanged,
  noHoverStyles,
  className,
  deletable,
}: SubjectGroupRowWrapperProps) => {
  const [editorOpen, setEditorOpen] = useState(false);
  const [name, setName] = useRecoilState(subjectInputGroupName(groupId));
  const isReport = useRecoilValue(isReportState);
  const viewId = useRecoilValue(analysisViewIdState);

  // TODO(will, collin, hesham): IMV2 P1 confirmation modal for deletion
  const onGroupDelete = useRecoilCallback(onSubjectInputDelete);
  const onSubjectGroupDelete = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const groupSubjects = await snapshot.getPromise(subjectInputGroupSubjects(groupId));
        const studioSubjects = await snapshot.getPromise(requestSubjects(groupSubjects));
        analyticsService.inputsSubjectGroupDeleted({
          name,
          sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
          subjects: studioSubjects.map((subject) => ({
            type: subject.subjectType,
            id: subject.id,
            name: subject.name,
          })),
          viewId,
        });

        onGroupDelete(groupId);
      },
    [groupId, onGroupDelete, name, isReport, viewId],
  );

  return (
    <>
      {editorOpen && (
        <SubjectGroupEditor
          groupId={groupId}
          onClose={() => setEditorOpen(false)}
          trackGroupChanged={trackGroupChanged}
        />
      )}
      <InputRow
        name={name}
        onNameChange={setName}
        onEdit={() => setEditorOpen(true)}
        onDelete={deletable ? onSubjectGroupDelete : undefined}
        readonly={readonly}
        noHoverStyles={noHoverStyles}
        hoverForOnlyName
        testid="qa-subject-group"
        className={className}
      >
        {children}
      </InputRow>
    </>
  );
};

export const SpacedItems = styled.div`
  column-gap: 4px;
  display: flex;
  align-items: center;
`;

const MaybeNestedIcons = styled.span<{ isNested: boolean }>`
  ${({ isNested }) =>
    isNested &&
    css`
      position: relative;
      min-width: 14px;
      left: -4px;

      & > i,
      & > span {
        position: absolute;
      }

      & > i:nth-child(1),
      & > span:nth-child(1) {
        top: -11px;
        left: -2px;
        font-size: 7px;
      }

      & > i:nth-child(2) {
        transform: rotate(180deg);
        font-weight: normal;
        top: -7px;
        left: 1px;
        font-size: 9px;
        color: ${GetColor.DarkGrey};
      }

      & > i:nth-child(3) {
        left: 4px;
        top: -6px;
      }
    `}
`;

const AdvisoryFee = styled.div`
  color: ${GetColor.DarkGrey};
  margin-left: 34px;
  margin-bottom: 4px;
`;
