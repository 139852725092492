import type {
  Analysis,
  AnalysisPortfolioComparisonTypeEnum,
  AnalysisTypeEnum,
  CustomBenchmarkTypeEnum,
  FrequencyEnum,
  Fund,
  HoldingsCategoryId,
  ImageDetails,
  Message,
  PeerGroupIdentifier,
  Portfolio,
  PortfolioCompare,
  PrivateFund,
  PrivatePortfolioNode,
  Scenario,
  TimeFrame,
} from 'venn-api';
import type {
  DataGridSizeType,
  EditorValue,
  GeoLevelFilterType,
  Page,
  PortfolioStrategyFilterType,
  SectorLevelFilterType,
} from 'venn-components';
import type { DateRange } from 'venn-ui-kit';
import {
  type AnalysisSubject,
  type CustomizableMetric,
  type ExcelCell,
  type SelectableCustomNotablePeriod,
} from 'venn-utils';
import type { ColorIndexSubjectTuple } from './configuration/colors';
import type { CustomMetricGrid } from './customFieldTypes';
import type { CustomBlockFonts } from './fonts';

export type StudioExcelFunction = { excelFunction: (() => ExcelCell[][]) | undefined } | undefined;

export type AllocatorConfig = {
  portfolioId: number;
  selectedStrategyId: number | undefined;
  comparisonConfig?: {
    comparisonType: AnalysisPortfolioComparisonTypeEnum;
    comparisonPortfolioId: number;
    comparisonPortfolioVersion: number | undefined;
    comparisonSelectedStrategyId: number | undefined;
  };
};

export type PrivateAllocatorConfig = {
  privatePortfolioId: string;
};

export type CustomViewOptions = {
  hideBenchmark?: boolean;
  dataGridSizeType?: DataGridSizeType;
  portfolioStrategyFilter?: PortfolioStrategyFilterType;
  geoLevelFilter?: GeoLevelFilterType;
  sectorLevelFilter?: SectorLevelFilterType;
  BLOCK_WIDTH?: number;
  selectedNotablePeriods?: number[];
  selectedInvestmentGroupInformation?: string[];
  customNotablePeriods?: SelectableCustomNotablePeriod[];
  title?: string;
  sectionName?: string;
  subtitle?: string;
  richText?: EditorValue;
  showVenncast?: boolean;
  imageId?: string;
  scenarios?: Scenario[];
  allFactorsSelected?: boolean;
  holdingsCategoryIds?: HoldingsCategoryId[];
  privateAssetIsCumulative?: boolean;
  privateDefaultAsOfDateBehavior?: boolean;
  privateProjectFromLastCashFlow?: boolean;
  startingNAV?: number;
  privateAssetAllocation?: number;
  yAxisUnitFormat?: UnitFormat;
  selectedPeerGroupId?: PeerGroupIdentifier;
  customMetricsSettings?: CustomizableMetric[];
  customMetricGrid?: CustomMetricGrid;
  customFonts?: CustomBlockFonts;
  tableSpacingRatio?: number;
};

export enum UnitFormat {
  ALLOCATION = 0,
  RATIO = 1,
  PERCENT = 2,
}

export type GlobalCustomViewOptions = {
  pages?: Page[];
  coverPageImage?: string;
  images?: ImageDetails[];
  hide_header_company_name?: boolean;
  hide_header_logo?: boolean;
  hide_header_view_name?: boolean;
  hide_footer_pagination?: boolean;
  hide_footer_disclaimer?: boolean;
  custom_footer_text?: string;
  primaryColor?: string;
  allocatorConfig?: AllocatorConfig;
  colorIndexSubjectMapping?: ColorIndexSubjectTuple[];
  privateAllocatorConfig?: PrivateAllocatorConfig;
};

export type Subject = {
  fundId?: string;
  privateFundId?: string;
  privatePortfolioId?: string;
  portfolioId?: number;
  portfolioVersion?: number;
};

export const SUBJECT_KEYS: (keyof Subject)[] = [
  'fundId',
  'privateFundId',
  'privatePortfolioId',
  'portfolioId',
  'portfolioVersion',
];

/**
 * Returns an object containing only subject fields (defined above)
 * Helpful when Subject is used as a key in Recoil atom/selector family
 */
export function toSubjectOnly(subject: Subject): Subject;
export function toSubjectOnly(subject: undefined): undefined;
export function toSubjectOnly(subject: Subject | undefined): Subject | undefined;
export function toSubjectOnly(subject?: Subject): Subject | undefined {
  if (!subject) {
    return undefined;
  }
  return Object.fromEntries([...SUBJECT_KEYS.map((key) => [key, subject[key]])]);
}

export type SubjectWithOptionalFee = Subject & { feesMapping?: Record<string, number> };

export type SubjectWithFee = Subject & { feesMapping: Record<string, number> };

export type StudioRequestSubject = {
  id: string;
  name: string;
  /**
   * Defined if this subject's portfolio is different than the last saved version of it due to either:
   *   - being modified in the allocator panel
   *   - being a previously saved version of that portfolio
   */
  modifiedPortfolio?: Portfolio;
  modifiedPrivatePortfolio?: PrivatePortfolioNode;
  /**
   * Active benchmark of the subject, if set.
   */
  individualBenchmark?: PortfolioCompare;
  /**
   * A mapping of subject id to management fee
   * A portfolio can have the root set to a given fee, whilst leaf nodes have 0 fees
   * Fees are not supported for private assets
   */
  feesMapping?: { [key: string]: number };
  /**
   * Portfolio OR fund has to be present (noted in the type concatenation below).
   */
  portfolio?: Portfolio;
  fund?: Fund;
  privatePortfolio?: PrivatePortfolioNode;
  privateFund?: PrivateFund;

  /**
   * Strategy is defined on 'PORTFOLIO' type, if a different strategy than root is selected
   */
  strategy?: Portfolio;
  /**
   * Both `portfolioComparisonType` and `primaryPortfolio` are only defined if the current subject is a comparison
   * subject of another portfolio (set in the allocator).
   */
  portfolioComparisonType?: AnalysisPortfolioComparisonTypeEnum;
  /** Only defined if the current subject is a comparison subject of another portfolio. */
  primaryPortfolio?: Portfolio;
  private: boolean;
} & (
  | {
      portfolio: Portfolio;
      subjectType: 'PORTFOLIO';
      private: false;
    }
  | {
      fund: Fund;
      subjectType: 'INVESTMENT';
      private: false;
    }
  | {
      privatePortfolio: PrivatePortfolioNode;
      subjectType: 'PORTFOLIO';
      private: true;
    }
  | {
      privateFund: PrivateFund;
      subjectType: 'INVESTMENT';
      private: true;
    }
);

/**
 For holdings tables, we are putting all subjects together in the tables
 We need additional information to pass to figure out which were benchmarks
 */
export type HoldingsRequestSubject = StudioRequestSubject & {
  isBenchmark: boolean;
};
export type SubjectGroupId = string;

export type BlockId = string;

export type BenchmarkConfig = {
  relative: boolean;
  type: CustomBenchmarkTypeEnum;
  subject?: AnalysisSubject;
};

export type StudioAnalysis = Analysis & {
  globalError?: Message;
};
export type AnalysisResult = (StudioAnalysis | undefined)[];

export type AnalysesResult = AnalysisResult[];

export interface StudioAnalysisRequest {
  dateRange: DateRange;
  analysesTypes: AnalysisTypeEnum[];
  subject: StudioRequestSubject;
  benchmark: StudioRequestSubject | undefined;
  /** The type of the {@link StudioAnalysisRequest.benchmark} on this subject. */
  benchmarkType: CustomBenchmarkTypeEnum;
  relative: boolean;
  frequency: FrequencyEnum;
  rollingYears?: number;
  /** True if this request is for benchmark data. */
  isBenchmark?: boolean;
  selectedNotablePeriods?: number[];
  includeAllPredefinedPeriods?: boolean;
  customTimeFrames?: TimeFrame[];
  forecastWindow?: number;
  initialNavAmount?: number;
  forecastConfidenceLevels?: number[];
  scenarios?: Scenario[];
  groupRootLevelInvestments?: boolean;
  peerGroupIdentifier?: PeerGroupIdentifier;
  returnsBasedSubjectStartingNav?: number;
}
