import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import type { DropdownMenuItemsDropDirection } from './DropdownMenuItems';
import { DropdownMenuItems } from './DropdownMenuItems';
import HamburgerTrigger from './HamburgerTrigger';
import { GetColor, ExternalActivityListener, LegacyRelativePortal, DropDirection, ZIndex } from 'venn-ui-kit';

export type AnchorAlign = 'left' | 'center' | 'right';

interface DropdownMenuProps {
  className?: string;
  dropDirection?: DropdownMenuItemsDropDirection;
  trigger?: JSX.Element;
  anchor?: AnchorAlign;
  debounceExternalActivityCallback?: boolean;
  isOpenByDefault?: boolean;
  /**
   * Whether a React portal is used for the menu, false by default
   */
  portal?: boolean;

  onToggle?(isOpen: boolean): void;

  footer?: JSX.Element;
  limitHeight?: boolean;
  dropdownStyle?: React.CSSProperties;
}

export interface DropdownMenuState {
  isOpen: boolean;
}

export class DropdownMenu extends Component<DropdownMenuProps, DropdownMenuState> {
  static defaultProps: DropdownMenuProps = {
    debounceExternalActivityCallback: true,
  };

  triggerRef: React.RefObject<HTMLDivElement>;

  constructor(props: DropdownMenuProps) {
    super(props);
    this.state = {
      isOpen: !!props.isOpenByDefault,
    };
    this.triggerRef = React.createRef();
  }

  onToggle = () => {
    const isOpen = !this.state.isOpen;
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    this.props.onToggle && this.props.onToggle(isOpen);
  };

  onClose = () => {
    this.setState({ isOpen: false });
    this.props.onToggle && this.props.onToggle(false);
  };

  render() {
    const {
      className,
      children,
      dropDirection,
      trigger,
      anchor,
      portal,
      debounceExternalActivityCallback,
      footer,
      limitHeight,
      dropdownStyle,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <ExternalActivityListener
        listeningEnabled={isOpen}
        onExternalActivity={this.onClose}
        debounce={debounceExternalActivityCallback}
      >
        <DropdownMenuWrapper className={className}>
          {trigger ? (
            <DropdownMenuButton onClick={this.onToggle} ref={this.triggerRef}>
              {trigger}
            </DropdownMenuButton>
          ) : (
            <DropdownMenuButton ref={this.triggerRef}>
              <HamburgerTrigger onClick={this.onToggle} />
            </DropdownMenuButton>
          )}

          {isOpen && children && (
            <>
              {anchor && <Anchor dropDirection={dropDirection} align={anchor} />}
              {portal ? (
                <LegacyRelativePortal component="div" style={{ zIndex: ZIndex.ModalCover }}>
                  <DropdownItems
                    footer={footer}
                    anchorMargin={!!anchor}
                    dropDirection={dropDirection}
                    onClose={this.onClose}
                    limitHeight={limitHeight}
                    width={this.triggerRef?.current ? this.triggerRef.current.getBoundingClientRect().width : undefined}
                    style={dropdownStyle}
                  >
                    {children}
                  </DropdownItems>
                </LegacyRelativePortal>
              ) : (
                <DropdownItems
                  footer={footer}
                  anchorMargin={!!anchor}
                  dropDirection={dropDirection}
                  onClose={this.onClose}
                  limitHeight={limitHeight}
                  style={dropdownStyle}
                >
                  {children}
                </DropdownItems>
              )}
            </>
          )}
        </DropdownMenuWrapper>
      </ExternalActivityListener>
    );
  }
}

export default DropdownMenu;

const DropdownItems = styled(DropdownMenuItems)<{ limitHeight?: boolean; width?: number }>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  min-width: 300px;
  max-width: 500px;
  border-radius: 0;
  border: 1px solid #f1f1f2;
  box-shadow: none;

  ${(props) =>
    props.limitHeight &&
    `
    max-height: 400px;
    overflow-y: auto;
    width: 350px;
  `}
`;

const DropdownMenuWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const DropdownMenuButton = styled.div`
  padding: 0;
  cursor: pointer;
`;

interface AnchorProps {
  dropDirection?: DropDirection;
  align: AnchorAlign;
}

const Anchor = styled.div<AnchorProps>`
  &,
  &::after {
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  border: 10px solid transparent;
  z-index: ${ZIndex.Front};

  &::after {
    content: ' ';
    border: 9px solid transparent;
    margin-left: -9px;
  }

  ${(props) => {
    switch (props.dropDirection) {
      case DropDirection.Up:
        return css`
          bottom: 100%;
          margin-bottom: -10px;
          border-top-color: ${GetColor.HintGrey};
          &::after {
            bottom: 0;
            margin-bottom: -7px;
            border-top-color: ${GetColor.White};
          }
        `;
      case DropDirection.Left:
      default:
        return css`
          top: 100%;
          margin-top: -10px;
          border-bottom-color: ${GetColor.HintGrey};
          &::after {
            margin-top: -7px;
            border-bottom-color: ${GetColor.White};
          }
        `;
    }
  }} ${(props) => {
    switch (props.align) {
      case 'left':
        return css`
          left: 0%;
          margin-left: 2px;
        `;
      case 'center':
        return css`
          left: 50%;
          margin-left: -10px;
        `;
      case 'right':
        return css`
          left: 100%;
          margin-left: -22px;
        `;
    }
    return null;
  }};
`;
