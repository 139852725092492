import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { isEqual, isNil } from 'lodash';
import {
  formatConstraintValue,
  formatItemsName,
  getConstraintCondition,
  getConstraintItemTypeAndColor,
} from '../constraintsFormatUtils';
import type { AllocationCondition, AllocationItem, AllocConstraint, ConstraintValueType } from 'venn-utils';
import { formatAllocation, Numbers } from 'venn-utils';
import ConstraintSubjectDropdown from '../../dropdowns/ConstraintSubjectDropdown';
import ItemsTooltip from './ItemsTooltip';
import { Idx, LockedInput, PartWrapper, PartWrapperButton } from './styled';
import { CenteredText, Constraint, ConstraintSubjectTypeLabel } from '../../components/styled';
import ConstraintConditionDropdown from '../../dropdowns/ConstraintConditionDropdown';
import type { SmallMultiToggleItem } from '../../../toggle/SmallMultiToggle';
import SmallMultiToggle from '../../../toggle/SmallMultiToggle';
import ConstraintValueInput from '../../components/ConstraintValueInput';
import { Label, Tooltip } from 'venn-ui-kit';
import type { Portfolio } from 'venn-api';
import { allocationValueTypeOptions, getAllocationConditionOptions } from '../content';
import ConstraintActions, { NEW_CONSTRAINT_MESSAGE } from '../../components/ConstraintActions';

export enum QueryCreationStep {
  'SELECT_SUBJECT',
  'SELECT_CONDITION',
  'SELECT_VALUE',
  'READY',
  'CREATED',
}

interface EditableAllocationConstraintViewProps extends Partial<AllocConstraint> {
  creationStep: QueryCreationStep;
  portfolio: Portfolio;
  idx: number;
  existingConstraint?: AllocConstraint;
  onUpdateValues: (updated: Partial<AllocConstraint>) => void;
  onDeleteConstraint: () => void;
  onResetConstraint: () => void;
  disabled?: boolean;
}

const EditableAllocationConstraintView = ({
  creationStep,
  portfolio,
  idx,
  existingConstraint,
  items,
  allInvestments,
  condition,
  valueType,
  value,
  onUpdateValues,
  onDeleteConstraint,
  onResetConstraint,
  disabled,
}: EditableAllocationConstraintViewProps) => {
  const [unconfirmedItems, setUnconfirmedItems] = useState<AllocationItem[] | undefined>(items);
  const [valueInputFocused, setValueInputFocused] = useState(false);

  useEffect(() => {
    if (!isNil(existingConstraint)) {
      setUnconfirmedItems(existingConstraint.allInvestments ? undefined : existingConstraint.items);
      setValueInputFocused(false);
    }
  }, [existingConstraint]);

  const theme = useContext(ThemeContext);
  const { itemsType, color } = getConstraintItemTypeAndColor(
    unconfirmedItems ?? [],
    allInvestments,
    portfolio.id,
    theme,
  );
  const conditionOptions = useMemo(() => {
    const allocationConditionOptions = getAllocationConditionOptions();
    return (unconfirmedItems ?? []).some((item) => item.id === portfolio.id)
      ? allocationConditionOptions.filter((option) => option.value === 'maxTurnover')
      : allocationConditionOptions;
  }, [unconfirmedItems, portfolio.id]);
  const valueTypeToggleOptions = useMemo(() => getAllocationValueTypeOptions(condition), [condition]);

  const formattedValue = isNil(existingConstraint)
    ? undefined
    : formatConstraintValue(
        existingConstraint.items.length,
        existingConstraint.valueType,
        existingConstraint.valueType === 'currentValue'
          ? existingConstraint.items.length === 1
            ? existingConstraint.items[0].allocation
            : undefined
          : existingConstraint.value,
      );
  const formattedFullValueForTooltip = isNil(existingConstraint)
    ? undefined
    : formatConstraintValue(
        existingConstraint.items.length,
        existingConstraint.valueType,
        existingConstraint.valueType === 'currentValue'
          ? existingConstraint.items.length === 1
            ? existingConstraint.items[0].allocation
            : undefined
          : existingConstraint.value,
        true,
      );

  const isEditingValue =
    valueInputFocused ||
    isNil(existingConstraint) ||
    isNil(valueType) ||
    (isNil(value) && valueType !== 'currentValue');

  return (
    <Constraint>
      <Idx>
        <Label inverted>{idx}</Label>
      </Idx>

      <ConstraintSubjectDropdown
        portfolio={portfolio}
        disableRoot
        disableAll={existingConstraint?.isNew}
        disableAllInvestmentsToggle={existingConstraint?.isNew}
        headerText={existingConstraint?.isNew ? NEW_CONSTRAINT_MESSAGE : undefined}
        allInvestmentsSelected={!!allInvestments}
        selected={unconfirmedItems ?? []}
        onChangeSelection={(selected: AllocationItem[], allInvestmentsSelected?: boolean) => {
          setUnconfirmedItems(allInvestmentsSelected === false ? undefined : selected);

          onUpdateValues({
            valueType: undefined,
            value: undefined,
            allInvestments: !!allInvestmentsSelected,
          });
        }}
        onClose={() => {
          const updatedValue: Partial<AllocConstraint> = {};
          if (!isEqual(unconfirmedItems, items)) {
            // Clear constraint value type & value if items were modified
            updatedValue.valueType = undefined;
            updatedValue.value = undefined;
          }
          updatedValue.items = unconfirmedItems;
          onUpdateValues(updatedValue);
        }}
      >
        {(open, onToggle) => (
          <ItemsTooltip items={allInvestments ? [] : unconfirmedItems ?? []} rootId={portfolio.id}>
            <PartWrapperButton
              disabled={disabled}
              empty={!unconfirmedItems?.length && !allInvestments}
              active={open && ((unconfirmedItems?.length ?? 0) > 0 || allInvestments)}
              onClick={() => {
                if (open) {
                  onUpdateValues({
                    items: unconfirmedItems,
                  });
                }
                onToggle(!open);
              }}
            >
              {!unconfirmedItems?.length && !allInvestments ? (
                'Select strategies or investments'
              ) : (
                <>
                  <ConstraintSubjectTypeLabel color={color}>{itemsType}</ConstraintSubjectTypeLabel>{' '}
                  {formatItemsName(unconfirmedItems ?? [], allInvestments)}
                </>
              )}
            </PartWrapperButton>
          </ItemsTooltip>
        )}
      </ConstraintSubjectDropdown>

      {creationStep >= QueryCreationStep.SELECT_CONDITION && (
        <>
          <CenteredText>{unconfirmedItems?.length === 1 ? 'has a' : 'have a'}</CenteredText>
          <ConstraintConditionDropdown<AllocationCondition>
            options={conditionOptions}
            selected={condition}
            onSelect={(selectedCondition) => {
              if (selectedCondition !== condition) {
                // Clear constraint value type & value if the condition was modified
                onUpdateValues({
                  condition: selectedCondition,
                  valueType: undefined,
                  value: undefined,
                });
              }
            }}
          >
            {(open, onToggle) => (
              <PartWrapperButton
                disabled={disabled || existingConstraint?.isNew}
                empty={creationStep === QueryCreationStep.SELECT_CONDITION}
                onClick={() => onToggle(!open)}
              >
                {creationStep === QueryCreationStep.SELECT_CONDITION
                  ? 'select a condition'
                  : getConstraintCondition(condition!)}
              </PartWrapperButton>
            )}
          </ConstraintConditionDropdown>
        </>
      )}

      {creationStep >= QueryCreationStep.SELECT_VALUE &&
        (isEditingValue ? (
          <>
            <CenteredText>of</CenteredText>
            <ValueTypeSelector>
              {existingConstraint?.isNew ? (
                <CurrencySign>$</CurrencySign>
              ) : (
                <SmallMultiToggle
                  selectedIdx={valueTypeToggleOptions.findIndex((option) => option.value === valueType)}
                  items={valueTypeToggleOptions}
                  onChange={(selected) => {
                    if (selected === 'currentValue') {
                      setValueInputFocused(false);
                    }
                    onUpdateValues({
                      value: selected === 'currentValue' && items?.length === 1 ? items[0].allocation : undefined,
                      valueType: selected as ConstraintValueType,
                    });
                  }}
                />
              )}
            </ValueTypeSelector>
            {valueType === 'currentValue' ? (
              <PartWrapper>{formatConstraintValue(items?.length ?? 0, 'currentValue', value)}</PartWrapper>
            ) : (
              <ConstraintValueInput
                placeholder={
                  items?.length === 1 && !isNil(items[0].allocation)
                    ? valueType === 'fixedValue'
                      ? formatAllocation(items[0].allocation, false)
                      : !isNil(portfolio.allocation)
                        ? Numbers.safeFormatNumber((items[0].allocation / portfolio.allocation) * 100, 1)
                        : undefined
                    : undefined
                }
                defaultValue={value}
                onFocus={() => setValueInputFocused(true)}
                onSetValue={(value) => {
                  onUpdateValues({ value });
                  setValueInputFocused(false);
                }}
                isPercentMode={valueType === 'percentOfPortfolio'}
              />
            )}
            {valueType === 'percentOfPortfolio' ? <CenteredText> of the portfolio</CenteredText> : null}
          </>
        ) : (
          <>
            <CenteredText>of</CenteredText>
            <Tooltip content={formattedFullValueForTooltip} usePortal>
              {existingConstraint?.valueType === 'currentValue' ? (
                <PartWrapperButton disabled={disabled} onClick={() => setValueInputFocused(true)}>
                  {formattedValue}
                </PartWrapperButton>
              ) : (
                <LockedInput type="button" disabled={disabled} onClick={() => setValueInputFocused(true)}>
                  {formattedValue}
                </LockedInput>
              )}
            </Tooltip>
          </>
        ))}

      <ConstraintActions
        portfolio={portfolio}
        isInPortfolioPolicy={!!existingConstraint?.isInPortfolioPolicy}
        isExperimental={existingConstraint?.isNew}
        onDeleteConstraint={onDeleteConstraint}
        canResetConstraint={!isNil(existingConstraint) && creationStep !== QueryCreationStep.CREATED}
        onResetConstraint={() => {
          setUnconfirmedItems(existingConstraint?.allInvestments ? undefined : existingConstraint?.items);
          setValueInputFocused(false);
          onResetConstraint();
        }}
        disabled={disabled}
      />
    </Constraint>
  );
};

export default EditableAllocationConstraintView;

const ValueTypeSelector = styled.div`
  margin-left: 5px;
`;

const CurrencySign = styled.strong`
  line-height: 20px;
  margin-right: -2px;
`;

const getAllocationValueTypeOptions = (condition?: AllocationCondition): SmallMultiToggleItem[] => {
  if (condition === 'maxTurnover') {
    return allocationValueTypeOptions.filter((option) => option.value !== 'currentValue');
  }
  return allocationValueTypeOptions;
};
