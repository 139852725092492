import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { Page } from 'venn-components';
import { dimensions, useAppPrintMode } from 'venn-components';
import { PageType } from 'venn-utils';
import GridPage from './pages/GridPage';
import DisclosurePage from './pages/DisclosurePage';
import { studioPrintOrientationType } from 'venn-state';
import { useRecoilValue } from 'recoil';
import TitlePage from './pages/TitlePage';
import SectionPage from './pages/SectionPage';

export const PAGE_WRAPPER_CLASS = 'pageWrapper';
export const PAGE_CLASS = 'page';

interface PageProps {
  zoom: number;
  pageNumber: number;
  totalPages: number;
  page: Page;
  focusBlockIndex: React.MutableRefObject<number | undefined>;
  focusBlockRef: React.RefObject<HTMLDivElement>;
}
/** Component for a Report Lab page in either edit or print mode.  */
const PageComponent = ({ zoom, pageNumber, page, focusBlockIndex, focusBlockRef, totalPages }: PageProps) => {
  const { inPrintMode } = useAppPrintMode();
  const printOrientationType = useRecoilValue(studioPrintOrientationType);
  const { totalWidth, totalHeight } = dimensions[printOrientationType];

  const width = totalWidth;
  /** We let the browser decide how to split disclosure pages, so we leave height as undefined instead of capping the height and clipping off the disclaimer. */
  const height = page.type === PageType.DISCLOSURE && inPrintMode ? undefined : totalHeight;

  const innerComponent =
    page.type === PageType.DISCLOSURE ? (
      <DisclosurePage />
    ) : page.type === PageType.TITLE ? (
      <TitlePage pageNumber={pageNumber} page={page} />
    ) : page.type === PageType.SECTION ? (
      <SectionPage pageNumber={pageNumber} page={page} />
    ) : (
      <GridPage
        focusBlockIndex={focusBlockIndex}
        focusBlockRef={focusBlockRef}
        pageNumber={pageNumber}
        page={page}
        totalPages={totalPages}
      />
    );

  return (
    <OverflowWrapper
      width={width}
      height={height}
      id={`page-${pageNumber}`}
      zoom={zoom}
      className={PAGE_WRAPPER_CLASS}
      inPrintMode={inPrintMode}
    >
      <PageWrapper width={width} height={height} zoom={zoom} className={PAGE_CLASS} inPrintMode={inPrintMode}>
        {innerComponent}
      </PageWrapper>
    </OverflowWrapper>
  );
};

export default PageComponent;

const OverflowWrapper = styled.div<{ zoom: number; width: number; height?: number; inPrintMode: boolean }>`
  width: ${({ zoom, width }) => zoom * width}px;
  contain: paint layout style inline-size;

  ${({ zoom, height }) =>
    height &&
    css`
      height: ${zoom * height}px;
      /* Setting contain strict improves correctness for pages with defined height, preventing wide blocks 
          from harming page layout. */
      contain: strict;
    `};

  ${({ inPrintMode }) =>
    !inPrintMode &&
    css`
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    `}

  @media print {
    page-break-before: always;
  }
`;

const PageWrapper = styled.div<{ zoom: number; width: number; height?: number; inPrintMode: boolean }>`
  /* 
  * Overflow hidden is incredibly important to allow ag-grid and highcharts to properly render within the bounds of the page during printing.
  * See VENN-21027 for more information.
  */
  overflow: hidden;
  transform: scale(${({ zoom }) => zoom});
  transform-origin: top left;
  background-color: ${GetColor.White};
  display: flex;
  flex-direction: column;
  align-items: center;
  // TODO: remove empty functions
  width: ${({ width }) => width}px;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  ${({ inPrintMode }) =>
    !inPrintMode &&
    css`
      border: 1px solid ${GetColor.GreyScale.Grey10};
    `}

  // TODO: Remove when footer is no longer used in old reporting
  > :first-child {
    width: 100%;
  }
`;
