import React, { useContext } from 'react';
import { GetColor, LoadingSize, Spinner } from 'venn-ui-kit';
import { FileIcon } from './Icons';
import styled, { ThemeContext } from 'styled-components';

const FileLoading = ({ label }: { label: string }) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <Row className="qa-preloader">
      <IconWrapper>
        <FileIcon color={Colors.HighlightDark} solid />
      </IconWrapper>
      <TextWrapper>
        <NameWrapper>
          <Label>{label}</Label>
          <SpinnerWrapper>
            <Spinner size={LoadingSize.nano} />
          </SpinnerWrapper>
        </NameWrapper>
        <Subtitle>uploading...</Subtitle>
      </TextWrapper>
    </Row>
  );
};

export default FileLoading;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${GetColor.Black};
  line-height: normal;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Subtitle = styled.span`
  font-size: 14px;
  line-height: normal;
`;

const SpinnerWrapper = styled.div`
  padding-left: 2px;
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  font-size: 25px;
`;
