import React, { useContext, useState } from 'react';
import { MAX_WIDTH, MIN_WIDTH, UNIT_WIDTH } from '../../../shared';
import { ConfirmationModal, StudioContext, UserContext, StudioTile } from 'venn-components';
import { analyticsService } from 'venn-utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isReportState, selectedBlockRelativeWidth, selectedBlockSettings } from 'venn-state';
import styled from 'styled-components';
import { PanelSection } from '../../../PanelSection';

interface ActionsSectionProps {
  selected: string;
}

const ActionsSection = ({ selected }: ActionsSectionProps) => {
  const { hasPermission } = useContext(UserContext);
  const { onDuplicateBlock, analysisView, onDeleteBlock, onBlockReorderUp, onBlockReorderDown } =
    useContext(StudioContext);
  const isReport = useRecoilValue(isReportState);
  const selectedBlockSetting = useRecoilValue(selectedBlockSettings);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [blockRelativeWidth, setBlockRelativeWidth] = useRecoilState(selectedBlockRelativeWidth);

  const blockSupportsWidthChange =
    selectedBlockSetting?.customBlockType && selectedBlockSetting.customBlockType !== 'PAGE_BREAK';

  const selectedBlockIndex = analysisView?.customizedViews?.findIndex((v) => v.refId === selected) ?? -2;
  const noBlocks = analysisView?.customizedViews?.length ?? 0;

  const duplicateBlock = () => {
    if (!analysisView?.customizedViews) {
      return;
    }

    const view = analysisView.customizedViews[selectedBlockIndex];

    if (view) {
      onDuplicateBlock(view, selectedBlockIndex + 1, selectedBlockSetting?.customBlockType);

      analyticsService.ctaClicked({
        purpose: 'copy studio block',
        locationOnPage: 'block settings panel',
      });
    }
  };

  const onUpdateWidth = (value: number) => {
    const origin = blockRelativeWidth || 1;
    const updated = origin + value;

    analyticsService.ctaClicked({
      purpose: `${value < 0 ? 'decrease' : 'increase'} width of the block`,
      locationOnPage: 'block settings panel',
    });

    setBlockRelativeWidth(updated);
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
  };

  return (
    <PanelSection header="Actions">
      <ActionsWrapper>
        {!isReport && (
          <>
            <StudioTile
              type="arrow-up"
              tooltip="Move Up"
              disabled={!hasPermission('STUDIO_REORDER_BLOCKS') || selectedBlockIndex === 0}
              onClick={() => onBlockReorderUp(selected)}
              small
              bold
            />
            <StudioTile
              type="arrow-down"
              tooltip="Move Down"
              disabled={!hasPermission('STUDIO_REORDER_BLOCKS') || selectedBlockIndex + 1 === noBlocks}
              onClick={() => onBlockReorderDown(selected)}
              small
              bold
            />
          </>
        )}
        <StudioTile
          type="trash"
          tooltip="Delete"
          disabled={!hasPermission('STUDIO_DELETE_BLOCKS')}
          onClick={() => setConfirmDelete(true)}
          small
          bold
        />
        <StudioTile
          type="clone"
          tooltip="Copy"
          disabled={!hasPermission('STUDIO_INSERT_BLOCKS')}
          onClick={duplicateBlock}
          small
          bold
        />
        {!isReport && (
          <>
            <StudioTile
              type="compress"
              tooltip="Decrease Size"
              onClick={() => onUpdateWidth(-UNIT_WIDTH)}
              disabled={
                !blockRelativeWidth ||
                blockRelativeWidth <= MIN_WIDTH ||
                !blockSupportsWidthChange ||
                !hasPermission('STUDIO_REORDER_BLOCKS')
              }
              bold
              small
            />
            <StudioTile
              type="expand"
              tooltip="Increase Size"
              onClick={() => onUpdateWidth(UNIT_WIDTH)}
              disabled={
                !blockRelativeWidth ||
                blockRelativeWidth >= MAX_WIDTH ||
                !blockSupportsWidthChange ||
                !hasPermission('STUDIO_REORDER_BLOCKS')
              }
              bold
              small
            />
          </>
        )}
      </ActionsWrapper>
      {confirmDelete && (
        <ConfirmationModal
          destructive
          text="The configuration for this block will be lost permanently."
          subhead="You are about to delete this block."
          onCancel={() => setConfirmDelete(false)}
          onProceed={() => {
            onDeleteBlock(selected);
            setConfirmDelete(false);
          }}
        />
      )}
    </PanelSection>
  );
};

export default ActionsSection;

const ActionsWrapper = styled.div`
  display: flex;
  column-gap: 5px;
`;
