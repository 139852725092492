import { atomFamily, DefaultValue, selectorFamily } from 'recoil';
import type { BlockId, HoldingsRequestSubject, StudioRequestSubject } from '../types';
import type { CustomizedBlock, InfoGraphicTypeEnum } from 'venn-api';
import { blockBenchmarkRelative, blockBenchmarkSubject } from './benchmark';
import { availableFactorMetrics, blockSettingsMap } from '../globalConfig';
import {
  BOX_CHART_CONFIDENCE_LEVELS,
  type CategoryMetric,
  type CustomizableMetric,
  DEFAULT_BREAKDOWN_CHART_CONFIDENCE_LEVELS,
  DEFAULT_BREAKDOWN_CHART_VISIBILITY,
  DEFAULT_CONFIDENCE_LEVELS,
  DEFAULT_CONFIDENCE_LEVELS_VISIBILITY,
  DEFAULT_FORECAST_WINDOW,
  DEFAULT_USE_LOGARITHMIC_SCALE,
  getBlockAllowsSecondaryAboveSubjectLimit,
  getMaxSubjects,
  getSecondarySubjectFromRequests,
  hasDisabledBenchmark,
  isPrivatesBlock,
  isPublicPrivateAssetGrowthBlock,
} from 'venn-utils';
import { benchmarkRequestSubjects, blockRequestSubjects } from './subjects';
import { unsavedChangesEffect } from '../../effects/unsavedChangesEffect';
import { compact, isNil } from 'lodash';
import { blockCustomMetricSettingsState, blockMetricsInternal, blockSettingId, blockSettings } from './blockSettings';
import { viewSectionName, viewSubtitle, viewTitle } from './viewOptions';
import { customBlockTypeWithRelativeLabel, getPrivateMetricName, subjectsMultiselect } from '../utils';
import type { BenchmarkInputId } from './input-management/benchmarkInput';
import { blockBenchmarkInput } from './input-management/benchmarkInput';
import type { SubjectInputId } from './input-management/subjectInput';
import { blockSubjectInputGroups } from './input-management/subjectInput';
import type { DateRangeInputId } from './input-management/dateRangeInput';
import { blockDateRangeInputState } from './input-management/dateRangeInput';
import { blockPrivateAssetIsCumulative } from './privateAssets';
import type { MetricSpecificError } from './errors';
import ordinal from 'ordinal';

export const blockAllFactorsSelected = atomFamily<boolean, BlockId>({
  key: 'blockAllFactorsSelected',
  default: false,
  effects: [unsavedChangesEffect],
});

export const blockFactorIds = selectorFamily<string[], BlockId>({
  key: 'blockFactorIds',
  set:
    (id) =>
    ({ set }, factorIds) =>
      set(blockSelectedFactorIdsAtom(id), factorIds),
  get:
    (id) =>
    ({ get }) =>
      get(blockAllFactorsSelected(id))
        ? get(availableFactorMetrics).map((metric) => metric.id)
        : get(blockSelectedFactorIdsAtom(id)),
});

export const blockFactors = selectorFamily<CategoryMetric[], BlockId>({
  key: 'blockFactors',
  get:
    (id) =>
    ({ get }) => {
      const factors = get(availableFactorMetrics);
      const ids = get(blockFactorIds(id));
      return factors.filter((factor) => ids.includes(factor.id));
    },
});

export const blockDoesNotSupportBenchmark = selectorFamily<boolean, BlockId>({
  key: 'blockDoesNotSupportBenchmark',
  get:
    (id) =>
    ({ get }) => {
      const infoGraphicType = get(blockInfoGraphicType(id));
      const blockType = get(blockSettings(id)).customBlockType;
      return hasDisabledBenchmark(blockType, infoGraphicType);
    },
});

export const blockDoesNotSupportPrivateBenchmark = selectorFamily<boolean, BlockId>({
  key: 'blockDoesNotSupportPrivateBenchmark',
  get:
    (id) =>
    ({ get }) => {
      const blockType = get(blockSettings(id)).customBlockType;
      return ['PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES'].includes(blockType);
    },
});

/**
 * All the metrics a given block supports
 */
export const blockCustomizableMetrics = selectorFamily<CustomizableMetric[], BlockId>({
  key: 'blockCustomizableMetrics',
  get:
    (id) =>
    ({ get }) => {
      const settings = get(blockSettings(id));
      return settings?.metrics ?? [];
    },
});

/**
 * All the metrics a given block supports
 */
export const blockCustomizableMetricsMap = selectorFamily<{ [key: string]: CustomizableMetric }, BlockId>({
  key: 'blockCustomizableMetrics',
  get:
    (id) =>
    ({ get }) => {
      const settings = get(blockSettings(id));
      if (!settings?.metrics) {
        return {};
      }
      return Object.fromEntries(settings.metrics.map((m) => [m.key, m]));
    },
});

/**
 * The keys of the currently selected metrics for a given block
 * When removing metrics this also handles cleaning up their definitions
 * if the metrics are custom, i.e. user created.
 */
export const blockMetrics = selectorFamily<string[], BlockId>({
  key: 'blockMetrics',
  get:
    (id) =>
    ({ get }) =>
      get(blockMetricsInternal(id)),
  set:
    (id) =>
    ({ set, get }, value) => {
      set(blockMetricsInternal(id), value);

      set(
        blockCustomMetricSettingsState(id),
        value instanceof DefaultValue
          ? value
          : get(blockCustomMetricSettingsState(id)).filter((metric) => value.includes(metric.key)),
      );
    },
});

/**
 * Errors of metrics within this block
 * This atomFamily should not be used directly. Prefer {@see useMetricsErrors} instead to set/read the errors.
 */
export const blockMetricsErrors = atomFamily<MetricSpecificError[], BlockId>({
  key: 'blockMetricsErrors',
  default: [],
  effects: [],
});

export const blockSelectedFactorIdsAtom = atomFamily<string[], BlockId>({
  key: 'blockUserSelectedFactorIds',
  default: [],
  effects: [unsavedChangesEffect],
});

export const blockRollingYears = atomFamily<number | undefined, BlockId>({
  key: 'blockRollingYears',
  default: undefined,
  effects: [unsavedChangesEffect],
});

export const blockContributionToPercentage = atomFamily<boolean, BlockId>({
  key: 'blockContributionToPercentage',
  default: false,
  effects: [unsavedChangesEffect],
});

export const blockInfoGraphicType = atomFamily<InfoGraphicTypeEnum | undefined, BlockId>({
  key: 'blockInfoGraphicType',
  default: undefined,
  effects: [unsavedChangesEffect],
});

export const blockForecastWindow = atomFamily<number | undefined, BlockId>({
  key: 'blockForecastWindow',
  default: DEFAULT_FORECAST_WINDOW,
  effects: [unsavedChangesEffect],
});

/** Please do not read values from this selector family directly. Use the useConfidenceLevels() hook instead */
export const blockConfidenceLevelsVisibility = atomFamily<boolean[], BlockId>({
  key: 'blockConfidenceLevelsVisibility',
  default: selectorFamily<boolean[], BlockId>({
    key: 'blockConfidenceLevelsVisibilityDefault',
    get:
      (id) =>
      ({ get }) => {
        const blockType = get(blockSettings(id)).customBlockType;
        switch (blockType) {
          case 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN':
            return DEFAULT_BREAKDOWN_CHART_VISIBILITY;
          default:
            return DEFAULT_CONFIDENCE_LEVELS_VISIBILITY;
        }
      },
  }),
  effects: [unsavedChangesEffect],
});

/** Please do not read values from this atom family directly. Use the useConfidenceLevels() hook instead */
const blockCustomConfidenceLevels = atomFamily<number[], BlockId>({
  key: 'blockCustomConfidenceLevels',
  default: selectorFamily<number[], BlockId>({
    key: 'blockCustomConfidenceLevelsDefault',
    get:
      (id) =>
      ({ get }) => {
        const blockType = get(blockSettings(id)).customBlockType;
        switch (blockType) {
          case 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN':
            return DEFAULT_BREAKDOWN_CHART_CONFIDENCE_LEVELS;
          default:
            return DEFAULT_CONFIDENCE_LEVELS;
        }
      },
  }),
  effects: [unsavedChangesEffect],
});

/** Please do not read values from this selector family directly. Use the useConfidenceLevels() hook instead */
export const blockConfidenceLevels = selectorFamily<number[], BlockId>({
  key: 'blockConfidenceLevels',
  get:
    (id) =>
    ({ get }) => {
      const infoGraphicType = get(blockInfoGraphicType(id));
      const blockType = get(blockSettings(id)).customBlockType;
      if (blockType === 'GROWTH_SIMULATION' && infoGraphicType === 'BOX_CHART') {
        return BOX_CHART_CONFIDENCE_LEVELS;
      }
      return get(blockCustomConfidenceLevels(id));
    },
  set:
    (id) =>
    ({ set }, value) => {
      set(blockCustomConfidenceLevels(id), value);
    },
});

export const blockLogarithmicScale = atomFamily<boolean | undefined, BlockId>({
  key: 'blockLogarithmicScale',
  default: undefined,
  effects: [unsavedChangesEffect],
});

export const blockHeader = atomFamily<string | undefined, BlockId>({
  key: 'blockHeader',
  default: undefined,
  effects: [unsavedChangesEffect],
});

export const blockSubHeader = atomFamily<string | undefined, BlockId>({
  key: 'blockSubHeader',
  default: undefined,
  effects: [unsavedChangesEffect],
});

export const blockMaxSubjects = selectorFamily<number, BlockId>({
  key: 'blockMaxSubjects',
  get:
    (id) =>
    ({ get }) => {
      const settingId = get(blockSettingId(id));
      const infoGraphicType = get(blockInfoGraphicType(id));
      const mapper = get(blockSettingsMap);
      const selectedMetrics = get(blockMetrics(id));

      if (!settingId || !infoGraphicType) {
        return 0;
      }

      return getMaxSubjects(mapper[settingId].customBlockType, infoGraphicType, selectedMetrics);
    },
});

/**
 * Helper to get or set most block atoms using one selector.
 * Subscribing to this selector will subscribe to all block atoms.
 *
 * Does NOT support setting benchmark relative or benchmark type via the setter.
 *
 * TODO(VENN-24677): relativeToBenchmark should not be used in this selector, instead use blockBenchmarkRelative directly
 */
export const customizedBlock = selectorFamily<CustomizedBlock, BlockId>({
  key: 'customizedBlock',
  get:
    (id) =>
    ({ get }) => {
      const rollingYears = get(blockRollingYears(id));
      const contributionToPercentage = get(blockContributionToPercentage(id));
      const relativeToBenchmark = get(blockBenchmarkRelative(id));
      const selectedMetrics = get(blockMetrics(id));
      const selectedFactors = get(blockFactorIds(id));
      const settingId = get(blockSettingId(id));
      const infoGraphicType = get(blockInfoGraphicType(id));
      const forecastWindow = get(blockForecastWindow(id)) ?? DEFAULT_FORECAST_WINDOW;
      const confidenceLevelsVisibility = get(blockConfidenceLevelsVisibility(id));
      const confidenceLevels = get(blockConfidenceLevels(id));
      const logarithmicScale = get(blockLogarithmicScale(id) ?? DEFAULT_USE_LOGARITHMIC_SCALE);
      const header = get(blockHeader(id));
      const subHeader = get(blockSubHeader(id));
      const linkedBenchmarkSettings = get(blockBenchmarkInput(id));
      const linkedSubjectGroups = get(blockSubjectInputGroups(id));
      const linkedDateRange = get(blockDateRangeInputState(id));

      return {
        rollingYears,
        contributionToPercentage,
        relativeToBenchmark,
        selectedMetrics,
        selectedFactors,
        settingId,
        infoGraphicType,
        forecastWindow,
        confidenceLevels,
        confidenceLevelsVisibility,
        logarithmicScale,
        header,
        subHeader,
        linkedBenchmarkSettings,
        linkedSubjectGroups,
        linkedDateRange,
      };
    },
  set:
    (id) =>
    ({ set }, block: CustomizedBlock | DefaultValue) => {
      if (block instanceof DefaultValue) {
        return;
      }
      set(blockRollingYears(id), block.rollingYears);
      set(blockContributionToPercentage(id), block.contributionToPercentage);
      set(blockMetrics(id), block.selectedMetrics);
      set(blockFactorIds(id), block.selectedFactors);
      set(blockSettingId(id), block.settingId);
      set(blockInfoGraphicType(id), block.infoGraphicType);
      set(blockForecastWindow(id), block.forecastWindow ?? DEFAULT_FORECAST_WINDOW);
      block.confidenceLevels && set(blockConfidenceLevels(id), block.confidenceLevels);
      block.confidenceLevelsVisibility && set(blockConfidenceLevelsVisibility(id), block.confidenceLevelsVisibility);
      set(blockLogarithmicScale(id), block.logarithmicScale);
      set(blockHeader(id), block.header);
      set(blockSubHeader(id), block.subHeader);
      set(blockBenchmarkInput(id), block.linkedBenchmarkSettings as BenchmarkInputId);
      block.linkedSubjectGroups &&
        set(
          blockSubjectInputGroups(id),
          block.linkedSubjectGroups.map((id) => id as SubjectInputId),
        );
      block.linkedDateRange && set(blockDateRangeInputState(id), block.linkedDateRange as DateRangeInputId);
    },
});

export const blockDisplayHeader = selectorFamily<string, BlockId>({
  key: 'blockDisplayHeader',
  get:
    (id) =>
    ({ get }) => {
      const settings = get(blockSettings(id));
      const infoGraphicType = get(blockInfoGraphicType(id));
      const userSavedHeader = get(blockHeader(id));
      const defaultBlockHeader = settings.title;

      if (userSavedHeader) {
        return userSavedHeader;
      }

      if (customBlockTypeWithRelativeLabel(settings.customBlockType) || infoGraphicType === 'DIVERGENT_BAR') {
        const selectedMetrics = get(blockMetrics(id));
        const { label = defaultBlockHeader, relativeLabel } =
          settings.metrics.find((metric) => metric.key === selectedMetrics[0]) || {};

        return get(blockBenchmarkRelative(id)) && relativeLabel ? relativeLabel : label;
      }

      if (isPrivatesBlock(settings.customBlockType)) {
        const selectedMetrics = get(blockMetrics(id));
        let title = settings.title;

        if (
          selectedMetrics.length &&
          settings.customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES' &&
          infoGraphicType !== 'GRID'
        ) {
          title = getPrivateMetricName(get(blockCustomizableMetricsMap(id)), selectedMetrics[0]);
        }

        if (get(blockPrivateAssetIsCumulative(id))) {
          title = `Cumulative ${title}`;
        }

        return `Private Assets: ${title}`;
      }

      if (settings.customBlockType === 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN') {
        const confidenceLevel = get(blockConfidenceLevels(id))[0];
        return `Asset Growth NAV Breakdown - ${ordinal(Math.round(confidenceLevel * 100))} Percentile`;
      }

      return get(viewTitle(id)) || get(viewSectionName(id)) || get(viewSubtitle(id)) || defaultBlockHeader;
    },
});

export const blockLimitedHoldingsSubjectsWithFees = selectorFamily<HoldingsRequestSubject[], BlockId>({
  key: 'blockLimitedHoldingsSubjectsWithFees',
  get:
    (blockId) =>
    ({ get }) => {
      const subjects = get(blockRequestSubjects(blockId));
      const max = get(blockMaxSubjects(blockId));
      const benchmarks = compact([get(blockBenchmarkSubject(blockId))]);
      const benchmarksRequests = get(benchmarkRequestSubjects(benchmarks));
      return subjects
        .concat(benchmarksRequests)
        .map((subject, index) => ({
          ...subject,
          isBenchmark: index >= subjects.length,
        }))
        .slice(0, max);
    },
});

export const blockLimitedRequestSubjects = selectorFamily<StudioRequestSubject[], BlockId>({
  key: 'blockLimitedRequestSubjects',
  get:
    (id) =>
    ({ get }) => {
      const max = get(blockMaxSubjects(id));

      const infoGraphicType = get(blockInfoGraphicType(id));
      const blockType = get(blockSettings(id)).customBlockType;
      const allowSecondary = getBlockAllowsSecondaryAboveSubjectLimit(infoGraphicType);

      const allBlockSubjects = get(blockRequestSubjects(id));
      if (isPublicPrivateAssetGrowthBlock(blockType)) {
        return subjectsMultiselect({
          subjects: allBlockSubjects,
          maxPrivateSubjects: 1,
          maxPublicSubjects: 1,
        });
      }

      // If the block allows only one subject, but ALSO a secondary subject above that default limit,
      // check if the subject following the last one is its secondary and, if so, include it.
      const actualMax =
        max === 1 &&
        allowSecondary &&
        !isNil(getSecondarySubjectFromRequests(allBlockSubjects?.[0], allBlockSubjects?.[1]))
          ? 2
          : max;

      return allBlockSubjects.slice(0, actualMax);
    },
});
