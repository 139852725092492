import React from 'react';
import styled from 'styled-components';
import type { ColumnMapping, FileMapping, FileUploadMetadata } from 'venn-api';
import { getTypeId } from '../../fetchers';
import type { DataUploaderMode } from '../../types';
import { DO_NOT_MAP, FUND } from '../../types';
import { updateColumnMappingsByFundChange, updateMappingColumnState } from '../../views/mapping/helpers';
import type { ErrorViewModel } from '../../views/review/helpers';
import { DataStatusIcon, ErrorToggle } from '../review-data';
import { getFirstCustomErrorMessage } from './helpers';

export const ErrorCellRenderer = ({
  index,
  errors,
  metadata,
  editingSeriesId,
  toggleEditing,
  hoverRowIndex,
  mode,
  mapping,
  columns,
  onChange,
  columnMapping,
  useCustomErrorMessage,
}: {
  index: number;
  errors: ErrorViewModel[];
  metadata: FileUploadMetadata;
  editingSeriesId: string | undefined;
  toggleEditing: (seriesId: string) => void;
  hoverRowIndex: number;
  mode: DataUploaderMode;
  mapping: FileMapping;
  columns: ColumnMapping[];
  onChange: (newMapping: FileMapping) => void;
  columnMapping: ColumnMapping;
  useCustomErrorMessage?: boolean;
}) => {
  const { appendId, currency, seriesId, typeId, newDataCount, fundId } = columnMapping;
  const fundTypeId = getTypeId(metadata, 'dataType', FUND);
  const deletedTypeId = getTypeId(metadata, 'dataType', DO_NOT_MAP);
  const isDeleted = Number(typeId) === deletedTypeId;
  const values = [appendId, currency, isDeleted ? fundTypeId : deletedTypeId];
  const columnErrors = errors.filter((e) => e.seriesId === seriesId);
  const remainErrorNumber = columnErrors.filter((c) => !c.isValid).length;
  const hasErrors = remainErrorNumber > 0;
  // don't show the icon when all errors have been corrected (show in error toggle instead)
  const showStatusIcon = !columnErrors.length || hasErrors;
  const noNewData = newDataCount === 0;
  const errorMessage = getFirstCustomErrorMessage(errors, seriesId, useCustomErrorMessage);

  const icon = (
    <DataStatusIcon
      hover={hoverRowIndex === index}
      isDeleted={isDeleted}
      noNewData={noNewData}
      hasErrors={hasErrors}
      isNew={!fundId}
      errorMessage={errorMessage}
      onDeleteToggle={(e) => {
        e?.stopPropagation?.();
        onChange(
          updateMappingColumnState({ mapping, columns, values, seriesId }, updateColumnMappingsByFundChange, mode),
        );
      }}
    />
  );

  return (
    <ItemStatusContainer>
      {showStatusIcon && <StatusIconContainer>{icon}</StatusIconContainer>}
      {!noNewData && columnErrors.length > 0 && !!toggleEditing && (
        <ErrorToggle
          isDeleted={isDeleted}
          errors={remainErrorNumber}
          open={editingSeriesId === seriesId}
          onToggle={() => columnErrors.length && !isDeleted && toggleEditing(seriesId)}
          checkIcon={icon}
        />
      )}
    </ItemStatusContainer>
  );
};

const ItemStatusContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const StatusIconContainer = styled.div`
  margin-left: auto;
  margin-right: 21px;
`;
