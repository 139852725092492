import React, { useMemo, useRef } from 'react';
import { StudioContext, StudioSidePanelContext } from 'venn-components';
import useSelectionSystem from '../logic/useSelectionSystem';
import useStudioSetup from '../logic/useStudioSetup';
import useStudioToolbar from '../logic/useStudioToolbar';
import useStudioExport from '../logic/useStudioExport';
import { useSyncColors } from '../logic/useSyncColors';

const StudioContextStore: React.FC<React.PropsWithChildren<unknown>> = React.memo(function StudioContextStore({
  children,
}) {
  const setupProps = useStudioSetup();
  const {
    analysisView,
    baselineView,
    setAnalysisView,
    setBaselineView,
    isGlobalAnalysisRangeLoading,
    firstOpeningOfTheView,
    setFirstOpeningOfTheView,
    afterUnsavedChangesAction,
    setAfterUnsavedChangesAction,
    hasUnsavedChange,
    setIsDuplicateReportName,
    setIsCheckingDuplicateReportName,
  } = setupProps;

  const analysisViewRef = useRef(analysisView);
  analysisViewRef.current = analysisView;

  const sideBarProps = useSelectionSystem(analysisView);
  const { onSelectBlock, onSelectGlobal, onSelectPage } = sideBarProps;

  const exportProps = useStudioExport(analysisViewRef);

  const toolBarProps = useStudioToolbar({
    analysisViewRef,
    analysisView,
    baselineView,
    firstOpeningOfTheView,
    setFirstOpeningOfTheView,
    afterUnsavedChangesAction,
    setAfterUnsavedChangesAction,
    hasUnsavedChange,
    onSelectPage,
    onSelectBlock,
    onSelectGlobal,
    setAnalysisView,
    setBaselineView,
    onExport: exportProps.onExport,
    setIsDuplicateReportName,
    setIsCheckingDuplicateReportName,
  });

  useSyncColors();

  const contextValue = useMemo(() => {
    return {
      ...setupProps,
      ...toolBarProps,
      ...exportProps,
      isGlobalAnalysisRangeLoading,
    };
  }, [exportProps, isGlobalAnalysisRangeLoading, setupProps, toolBarProps]);

  return (
    <StudioContext.Provider value={contextValue}>
      <StudioSidePanelContext.Provider value={sideBarProps}>{children}</StudioSidePanelContext.Provider>
    </StudioContext.Provider>
  );
});

export default StudioContextStore;
