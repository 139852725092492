import React, { Component } from 'react';
import flatten from 'lodash/flatten';
import type { FactorWithNonSerializedReturns as FactorEntity } from 'venn-api';
import type { FactorCategory, CheckableFactor } from './build-factors';
import buildFactors from './build-factors';
import type { DropMenuCheckboxItem } from 'venn-ui-kit';
import { FilterDropMenu } from 'venn-ui-kit';

export interface FactorSelectProps {
  factors: FactorEntity[];
  selectedFactors?: FactorEntity[];
  className?: string;
  onChange(items: FactorEntity[]): void;
}

export interface FactorSelectState {
  items: FactorCategory[];
  externallySelectedFactors?: FactorEntity[];
}

export class FactorSelect extends Component<FactorSelectProps, FactorSelectState> {
  state: FactorSelectState = { items: [], externallySelectedFactors: undefined };

  static getDerivedStateFromProps(nextProps: FactorSelectProps, prevState: FactorSelectState) {
    const { factors, selectedFactors } = nextProps;
    const newlySelectedFactors =
      prevState.externallySelectedFactors === undefined && (nextProps?.selectedFactors?.length ?? 0) > 0;
    if (!prevState || !prevState.items.length || newlySelectedFactors) {
      return {
        externallySelectedFactors: newlySelectedFactors ? selectedFactors : undefined,
        items: buildFactors(factors, selectedFactors),
      };
    }
    return prevState;
  }

  onFilterChange = (filterItems: DropMenuCheckboxItem[]) => {
    const { items } = this.state;
    const newItems: FactorCategory[] = [];
    items.forEach((category: FactorCategory) => {
      newItems.push({
        name: category.name,
        factors: category.factors.map((factor: CheckableFactor) => {
          const selectedItem = filterItems.find((item: DropMenuCheckboxItem) => item.value === factor.factor.name);
          return {
            checked: !!(selectedItem && selectedItem.checked),
            factor: factor.factor,
          };
        }),
      });
    });
    this.setState({ items: newItems }, this.triggerOnChange);
  };

  getCheckedItems = () =>
    flatten(
      this.state.items.map((category) =>
        category.factors.filter((factor) => factor.checked).map((factor) => factor.factor),
      ),
    );

  triggerOnChange = () => {
    this.props.onChange(this.getCheckedItems());
  };

  render() {
    const { items } = this.state;
    const { className } = this.props;

    return (
      <FilterDropMenu
        onChange={this.onFilterChange}
        label="Select Factor(s):"
        height="60vh"
        width={300}
        items={buildFilterItems(items)}
        header="Select one factor to enable inputs."
        selectedMetricText="Factor"
        className={className}
      />
    );
  }
}

const buildFilterItems = (items: FactorCategory[]): DropMenuCheckboxItem[] =>
  items.reduce<DropMenuCheckboxItem[]>(
    (prev, category) => [
      ...prev,
      {
        label: category.name,
        value: category.name,
        checked: category.factors.every((factor: CheckableFactor) => factor.checked),
        level: 0,
      },
      ...category.factors.map((factor) => ({
        label: factor.factor.name,
        value: factor.factor.name,
        checked: factor.checked,
        level: 1,
      })),
    ],
    [],
  );

export default FactorSelect;
