import noop from 'lodash/noop';
import React from 'react';
import type { Portfolio } from 'venn-api';
import { createPortfolioV3 } from 'venn-api';
import { LoadingSize, Spinner } from 'venn-ui-kit';
import type { NewFundDraft } from 'venn-utils';
import { addInvestmentsToPortfolio, createPortfolioFromFunds, logMessageToSentry, FS } from 'venn-utils';
import ConditionalOverlay from '../../conditional-overlay';
import { ModalContent, ModalFooter, ModalHeader, ModalSubhead } from '../../modal';
import AddToStrategy from '../add-to-portfolio/AddToStrategy';
import type { BasePortfolioChangeCallback, BasePortfolioStateUpdater } from './BasePortfolio';
import BasePortfolio from './BasePortfolio';
import { Provider } from './context';
import Name from './Name';
import type { BasePortfolioField, NameField } from './types';
import { OwnershipContextSelector } from '../components/OwnershipContextSelector';
import { UserContext } from '../../contexts';
import { isNull, isNil, omitBy } from 'lodash';

export interface CreatePortfolioModalContentWithoutRefreshProps {
  baseline?: Portfolio;
  hideBasePortfolio?: boolean;
  disableBaselineSelection?: boolean;
  canCreateFromScratch?: boolean;
  createDraft?: boolean;

  onClose(): void;
  onSubmit(portfolio: Portfolio, createdFromMaster: boolean): void;
  investments?: NewFundDraft[];

  isCreatingFromStrategy?: boolean;
  defaultName?: string;
}
export interface CreatePortfolioModalContentProps extends CreatePortfolioModalContentWithoutRefreshProps {
  refreshPortfolioList: () => Promise<void>;
  currentContextId?: string;
}

export interface CreatePortfolioModalContentState {
  defaultBaseline?: Portfolio;
  name: NameField;
  basePortfolio: BasePortfolioField;
  addToStrategy?: Portfolio;
  submitting: boolean;
  ownerContextId: string | null;
}

class CreatePortfolioModalContent extends React.Component<
  CreatePortfolioModalContentProps,
  CreatePortfolioModalContentState
> {
  static getDerivedStateFromProps(props: CreatePortfolioModalContentProps, state: CreatePortfolioModalContentState) {
    if (props.baseline !== state.defaultBaseline) {
      return {
        ...state,
        defaultBaseline: props.baseline,
        basePortfolio: {
          ...state.basePortfolio,
          baseline: props.baseline,
        },
      };
    }
    return null;
  }

  constructor(props: CreatePortfolioModalContentProps) {
    super(props);

    const { baseline, disableBaselineSelection, canCreateFromScratch, defaultName } = props;

    this.state = {
      name: {
        validating: true,
        isError: false,
        value: defaultName,
      },
      basePortfolio: {
        disabled: !!disableBaselineSelection,
        allowFromScratch: !!canCreateFromScratch,
        hidden: false,
        fromMaster: !!baseline?.master,
        loading: false,
        id: baseline?.id,
        baseline,
      },
      addToStrategy: undefined,
      submitting: false,
      defaultBaseline: baseline,
      ownerContextId: null,
    };
  }

  onNameChange = (name: NameField) => this.setState(() => ({ name }));

  onBasePortfolioChange = (updater: BasePortfolioStateUpdater, callback: BasePortfolioChangeCallback = noop) =>
    this.setState((prevState) => ({ basePortfolio: updater(prevState.basePortfolio) }), callback);

  onStrategySelected = (selected: Portfolio) => this.setState({ addToStrategy: selected });

  createPortfolioFromScratch = () => {
    const { addToStrategy } = this.state;
    const { investments } = this.props;
    return investments ? createPortfolioFromFunds(investments, addToStrategy?.name) : {};
  };

  createPortfolioFromBaseline = () => {
    const { addToStrategy } = this.state;
    const { baseline } = this.state.basePortfolio;
    const { investments } = this.props;
    if (!baseline) {
      logMessageToSentry('Failed to get baseline portfolio when create portfolio');
      return {};
    }

    const portfolio = investments
      ? addInvestmentsToPortfolio(investments, baseline, addToStrategy?.id, addToStrategy?.name).updatedPortfolio
      : baseline;
    return {
      ...portfolio,
      demo: false,
    };
  };

  onSubmit = async () => {
    const { ownerContextId } = this.state;
    const { baseline } = this.state.basePortfolio;
    const { value } = this.state.name;
    const { createDraft, currentContextId } = this.props;

    if (!value) {
      // This should never be reached.
      return;
    }

    this.setState({
      submitting: true,
    });

    const baselinePortfolio = baseline ? this.createPortfolioFromBaseline() : this.createPortfolioFromScratch();
    const createdFromMaster = !!baseline && (baselinePortfolio as Portfolio).master;

    try {
      const newPortfolio = (
        await createPortfolioV3({
          ...baselinePortfolio,
          name: value,
          optimizationId: undefined,
          draft: createDraft,
          ...omitBy({ ownerContextId: ownerContextId ?? currentContextId }, isNull),
        })
      ).content;

      this.setState({
        submitting: false,
      });
      this.props.refreshPortfolioList();
      this.props.onSubmit(newPortfolio, createdFromMaster);
    } catch (error) {
      this.setState({
        submitting: false,
      });
    }
  };

  render() {
    const { hideBasePortfolio, onClose, investments, currentContextId, isCreatingFromStrategy } = this.props;
    const { name, basePortfolio, submitting, addToStrategy, ownerContextId } = this.state;
    const enabled = !name.isError && !name.validating && !basePortfolio.loading && !submitting;
    const investmentsText = investments?.length === 1 ? 'investment' : 'investments';
    const loading = submitting || name.validating || basePortfolio.loading;

    return (
      <Provider
        value={{
          name,
          basePortfolio,
          submitting,
        }}
      >
        <ModalHeader>Create portfolio</ModalHeader>
        <ConditionalOverlay center condition={submitting}>
          {!isNil(investments) ? (
            <ModalSubhead>{`Create new portfolio from (${investments.length}) ${investmentsText}.`}</ModalSubhead>
          ) : isCreatingFromStrategy && !isNil(basePortfolio.baseline) ? (
            <ModalSubhead>{`Create new portfolio from '${basePortfolio.baseline.name}' strategy.`}</ModalSubhead>
          ) : null}
          <ModalContent>
            <Name onChange={this.onNameChange} initialValue={name.value} />
            {!hideBasePortfolio && <BasePortfolio onChange={this.onBasePortfolioChange} />}
            {FS.has('context_switching') && (
              <OwnershipContextSelector
                requiredAction="CREATE_PORTFOLIO"
                ownerContextId={ownerContextId ?? currentContextId}
                setOwnerContextId={(newOwnerContextId) =>
                  this.setState((state) => ({
                    ...state,
                    ownerContextId: newOwnerContextId,
                  }))
                }
              />
            )}
            {investments && (
              <AddToStrategy
                label={`Add ${investmentsText} to strategy:`}
                allowCreateNew
                portfolio={basePortfolio.baseline}
                // @ts-expect-error: TODO fix strictFunctionTypes
                onSelected={this.onStrategySelected}
                initialSelectedStrategy={addToStrategy}
              />
            )}
          </ModalContent>
          <ModalFooter
            onCancel={onClose}
            primaryLabel="Create"
            primaryClassName="qa-create-portfolio"
            primaryDisabled={!enabled}
            rightChildren={loading && <Spinner size={LoadingSize.small} />}
            onPrimaryClick={this.onSubmit}
          />
        </ConditionalOverlay>
      </Provider>
    );
  }
}

const CreatePortfolioModalContentWithCurrentContext = (
  props: Omit<CreatePortfolioModalContentProps, 'currentContextId'>,
) => (
  <UserContext.Consumer>
    {(userContext) => <CreatePortfolioModalContent {...props} currentContextId={userContext.currentContext} />}
  </UserContext.Consumer>
);

export default CreatePortfolioModalContentWithCurrentContext;
