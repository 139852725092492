import type { Snapshot } from 'recoil';
import { DefaultValue, selector } from 'recoil';
import type { BenchmarkConfig, CustomViewOptions, Subject, SubjectWithOptionalFee } from './types';
import type {
  AnalysisPeriod,
  AnalysisSubjectTypeEnum,
  AnalysisView,
  AnalysisViewBenchmarkSettings,
  AnalysisViewDateRange,
  AnalysisViewSubject,
  AnalysisViewSubjectGroup,
  CustomizedBlock,
} from 'venn-api';
import {
  type BenchmarkInputId,
  type DateRangeInputId,
  inaccessibleSubjectsState,
  type SubjectInputId,
  analysisViewTypeState,
  benchmarkInputIsRelative,
  benchmarkInputName,
  benchmarkInputs,
  benchmarkInputSubject,
  benchmarkInputType,
  blockBenchmarkConfig,
  blockSettings,
  blockSubjects,
  customizedBlock,
  customViewOptions,
  dateRangeInputConsistencyState,
  dateRangeInputDateRangeState,
  dateRangeInputNameState,
  dateRangeInputsState,
  globalCustomViewOptions,
  studioPrintOrientationType,
  subjectInputGroupName,
  subjectInputGroups,
  subjectInputGroupSubjects,
  isReportState,
  analysisViewIdState,
  analysisViewRefIdState,
} from './configuration';
import type { DateRange, RangeType } from 'venn-ui-kit';
import { compact, omit } from 'lodash';
import { clearHasUnsavedChanges } from '../effects/unsavedChangesEffect';
import { type CustomBlockTypeEnum, isPublicPrivateAssetGrowthBlock } from 'venn-utils';
import { getSubjectId } from './utils';

export const currentAnalysisView = selector<AnalysisView | undefined>({
  key: 'currentAnalysisView',
  // TODO: Implement get when all fields of analysis view are mapped to state atoms
  get: () => undefined,
  set: ({ set }, view) => {
    if (view instanceof DefaultValue || view === undefined) {
      return;
    }

    set(analysisViewIdState, view.id);
    set(analysisViewRefIdState, view.refId);

    view.printOrientationType && set(studioPrintOrientationType, view.printOrientationType);

    set(inaccessibleSubjectsState, getInaccessibleSubjects(view));

    set(subjectInputGroups, view.subjectGroups?.map((g) => g.id as SubjectInputId) ?? []);
    view.subjectGroups?.forEach(({ id, subjects, label }) => {
      set(
        subjectInputGroupSubjects(id as SubjectInputId),
        subjects
          .filter((subject) => !subject.subjectInaccessible)
          .map((s) => {
            const feesMapping = s.feesMapping ?? {};
            if (s.isPrivate && s.subjectType === 'PORTFOLIO') {
              return { privatePortfolioId: s.id, feesMapping };
            }
            if (s.isPrivate && s.subjectType === 'INVESTMENT') {
              return { privateFundId: s.id, feesMapping };
            }
            if (s.subjectType === 'PORTFOLIO') {
              return { portfolioId: Number(s.id), feesMapping };
            }
            return { fundId: s.id, feesMapping };
          }),
      );
      set(subjectInputGroupName(id as SubjectInputId), label);
    });
    set(dateRangeInputsState, view.dateRanges?.map((d) => d.id as DateRangeInputId) ?? []);
    view.dateRanges?.forEach(({ id, period, label, resolution }) => {
      set(dateRangeInputNameState(id as DateRangeInputId), label);
      set(dateRangeInputDateRangeState(id as DateRangeInputId), convertAnalysisPeriodToDateRange(period));
      set(dateRangeInputConsistencyState(id as DateRangeInputId), resolution);
    });

    set(benchmarkInputs, view.benchmarkSettings?.map((s) => s.id as BenchmarkInputId) ?? []);
    view.benchmarkSettings?.forEach(({ id, label, relative, subject, type }) => {
      set(benchmarkInputName(id as BenchmarkInputId), label);
      set(benchmarkInputIsRelative(id as BenchmarkInputId), relative);
      subject &&
        !subject.subjectInaccessible &&
        set(benchmarkInputSubject(id as BenchmarkInputId), analysisSubjectToSubject(subject));
      set(benchmarkInputType(id as BenchmarkInputId), type);
    });

    set(globalCustomViewOptions, view.customViewOptions);

    view.customizedViews?.forEach((customView) => {
      if (!customView.refId) {
        return;
      }

      if (customView.customizedBlock) {
        set(customizedBlock(customView.refId), customView.customizedBlock);
      }

      const blockOptions = customView.customViewOptions as CustomViewOptions;
      set(customViewOptions(customView.refId), blockOptions);
    });

    view.analysisViewType && set(analysisViewTypeState, view.analysisViewType);

    // We just loaded an entirely new view so it doesn't have unsaved changes.
    // The fact we're using setTimeout is sort of a hack to get around the semi-asynchronous nature of recoil sets.
    // There might be a better way to do this.
    setTimeout(clearHasUnsavedChanges, 0);
  },
});

export const combineAnalysisViewWithSnapshot = async (
  view: Partial<AnalysisView>,
  snapshot: Snapshot,
): Promise<Partial<AnalysisView>> => {
  let customizedViews: AnalysisView[] = [];

  if (view.customizedViews) {
    customizedViews = await Promise.all(
      view.customizedViews.map(async (customView) => {
        if (!customView.refId) {
          return customView;
        }

        const settings = await snapshot.getPromise(blockSettings(customView.refId));
        const block = filterBlockSettings(
          settings.customBlockType,
          await snapshot.getPromise(customizedBlock(customView.refId)),
        );
        const viewOptions = await snapshot.getPromise(customViewOptions(customView.refId));

        const subjectGroup = await snapshot.getPromise(blockSubjects(customView.refId));
        const benchmark = await snapshot.getPromise(blockBenchmarkConfig(customView.refId));
        const subjects = getSubjects(subjectGroup, benchmark);

        const shouldPersistCustomFontState = await snapshot.getPromise(isReportState);
        const customViewOptionsValue = {
          ...customView.customViewOptions,
          ...viewOptions,
        };
        if (!shouldPersistCustomFontState) {
          delete customViewOptionsValue.customFonts;
          delete customViewOptionsValue.tableSpacingRatio;
        }

        return {
          ...customView,
          customizedBlock: block,
          customViewOptions: customViewOptionsValue,
          subjects,
          version: 2,
        };
      }),
    );
  }

  const id = await snapshot.getPromise(analysisViewIdState);
  const refId = await snapshot.getPromise(analysisViewRefIdState);
  const viewOptions = await snapshot.getPromise(globalCustomViewOptions);
  const subjectGroups = await getSubjectGroups(snapshot);
  const dateRanges = await getDateRanges(snapshot);
  const benchmarkSettings = await getBenchmarkSettings(snapshot);
  const printOrientationType = await snapshot.getPromise(studioPrintOrientationType);

  return {
    ...view,
    id,
    refId,
    customizedViews,
    printOrientationType,
    benchmarkSettings,
    subjectGroups,
    dateRanges,
    version: 2,
    customizedBlock: {
      relativeToBenchmark: false,
      contributionToPercentage: false,
      selectedFactors: [],
      selectedMetrics: [],
      linkedBenchmarkSettings: '',
      linkedDateRange: '',
      linkedSubjectGroups: [],
    },
    customViewOptions: {
      ...view.customViewOptions,
      ...viewOptions,
    },
  };
};

type OptionalFields = 'confidenceLevels' | 'confidenceLevelsVisibility';

const getSubjectGroups = async (snapshot: Snapshot): Promise<AnalysisViewSubjectGroup[]> => {
  const ids = await snapshot.getPromise(subjectInputGroups);
  return Promise.all(
    ids.map(async (id) => {
      const label = await snapshot.getPromise(subjectInputGroupName(id));
      const temp = await snapshot.getPromise(subjectInputGroupSubjects(id));
      const subjects: AnalysisViewSubject[] = temp.map((subject) => ({
        comparisonType: 'COMPARISON' as const,
        id: getSubjectId(subject),
        subjectType: getAnalysisSubjectType(subject),
        feesMapping: subject.feesMapping,
        isPrivate: !!subject.privateFundId || !!subject.privatePortfolioId,
      }));
      return {
        id,
        label,
        subjects,
      };
    }),
  );
};

const getDateRanges = async (snapshot: Snapshot): Promise<AnalysisViewDateRange[]> => {
  const ids = await snapshot.getPromise(dateRangeInputsState);
  return Promise.all(
    ids.map(async (id) => {
      const label = await snapshot.getPromise(dateRangeInputNameState(id));
      const period = convertDateRangeToAnalysisPeriod(await snapshot.getPromise(dateRangeInputDateRangeState(id)));
      const resolution = await snapshot.getPromise(dateRangeInputConsistencyState(id));
      return {
        id,
        label,
        period,
        resolution,
      };
    }),
  );
};

const getBenchmarkSettings = async (snapshot: Snapshot): Promise<AnalysisViewBenchmarkSettings[]> => {
  const ids = await snapshot.getPromise(benchmarkInputs);
  return Promise.all(
    ids.map(async (id) => {
      const label = await snapshot.getPromise(benchmarkInputName(id));
      const relative = await snapshot.getPromise(benchmarkInputIsRelative(id));
      const type = await snapshot.getPromise(benchmarkInputType(id));
      const subjectId = await snapshot.getPromise(benchmarkInputSubject(id));
      const subject: AnalysisViewSubject | undefined = subjectId && {
        comparisonType: 'BENCHMARK' as const,
        id: getSubjectId(subjectId),
        subjectType: getAnalysisSubjectType(subjectId),
        isPrivate: !!subjectId.privateFundId || !!subjectId.privatePortfolioId,
      };
      return {
        id,
        label,
        relative,
        type,
        subject,
      };
    }),
  );
};

const filterBlockSettings = (type: CustomBlockTypeEnum, settings: CustomizedBlock) => {
  return omit<CustomizedBlock, OptionalFields>(
    settings,
    type === 'GROWTH_SIMULATION' || isPublicPrivateAssetGrowthBlock(type)
      ? []
      : ['confidenceLevels', 'confidenceLevelsVisibility'],
  );
};

const getSubjects = (subjectGroup: SubjectWithOptionalFee[], benchmark: BenchmarkConfig): AnalysisViewSubject[] =>
  compact([
    ...subjectGroup.map((subject, idx) => {
      const id = getSubjectId(subject);
      return {
        comparisonType: idx === 0 ? ('PRIMARY' as const) : ('COMPARISON' as const),
        id,
        subjectType: getAnalysisSubjectType(subject),
        feesMapping: subject.feesMapping,
        isPrivate: !!subject.privateFundId || !!subject.privatePortfolioId,
      };
    }),
    benchmark.subject && {
      comparisonType: 'BENCHMARK' as const,
      id: String(benchmark.subject.id),
      subjectType: benchmark.subject.type as AnalysisSubjectTypeEnum,
      isPrivate: benchmark.subject.private,
    },
  ]);

const convertAnalysisPeriodToDateRange = ({ periodToDate, endDate, startDate }: AnalysisPeriod): DateRange => {
  if (!periodToDate && !endDate && !startDate) {
    return { period: 'full' };
  }
  const period = periodToDate as RangeType;
  return {
    to: endDate,
    from: startDate,
    period,
  };
};

const convertDateRangeToAnalysisPeriod = (dateRange: DateRange = {}): AnalysisPeriod => {
  return {
    endDate: dateRange.to,
    periodToDate: dateRange.period,
    startDate: dateRange.from,
  };
};

const analysisSubjectToSubject = ({ subjectType, id, isPrivate }: AnalysisViewSubject): Subject => ({
  privateFundId: isPrivate && subjectType === 'INVESTMENT' ? id : undefined,
  privatePortfolioId: isPrivate && subjectType === 'PORTFOLIO' ? id : undefined,
  fundId: !isPrivate && subjectType === 'INVESTMENT' ? id : undefined,
  portfolioId: !isPrivate && subjectType === 'PORTFOLIO' ? Number(id) : undefined,
});

const getAnalysisSubjectType = (subject: SubjectWithOptionalFee) => {
  if (subject.fundId || subject.privateFundId) {
    return 'INVESTMENT' as const;
  }
  return 'PORTFOLIO' as const;
};

const getInaccessibleSubjects = (view: AnalysisView) =>
  view.subjects.filter((subject) => subject.subjectInaccessible).map(analysisSubjectToSubject);
