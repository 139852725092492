import React, { useContext } from 'react';
import type { UserProfileSettings } from 'venn-api';
import { Loading } from 'venn-ui-kit';
import { useHasFF } from 'venn-utils';
import CashflowSettings from './components/cashflow-settings/CashflowSettings';
import FactorForecasts from './components/FactorForecasts';
import AddeparIntegration from './components/integrations/AddeparIntegration';
import Integrations from './components/integrations/Integrations';
import MasterPortfolioSection from './components/MasterPortfolioSection';
import OrganizationCurrency from './components/OrganizationCurrency';
import PrintSettingsSection from './components/PrintSettingsSection';
import { ReportLabFontSizeDefaultsSection } from './components/ReportLabFontSizeDefaultsSection';
import Templates from './components/templates/Templates';
import { UserContext } from 'venn-components';

export default function WorkspaceConfiguration({ profileSettings }: { profileSettings: UserProfileSettings }) {
  const hasAddeparSelfService = useHasFF('addepar_self_service');
  const hasStudioReportEditor = useHasFF('studio_report_editor');
  const hasPrivatesCashFlowSettingsEditingFF = useHasFF('privates_hyperparameters_editing_ff');
  const { hasPermission } = useContext(UserContext);
  const hasEditHyperparametersPermission = hasPermission('EDIT_HYPERPARAMETERS');

  return (
    <>
      <MasterPortfolioSection profileSettings={profileSettings} />
      <OrganizationCurrency profileSettings={profileSettings} />
      {hasStudioReportEditor && <ReportLabFontSizeDefaultsSection />}
      {hasStudioReportEditor ? <PrintSettingsSection /> : null}
      <React.Suspense fallback={<Loading title="Forecasts are loading... " />}>
        <FactorForecasts />
      </React.Suspense>
      {hasPrivatesCashFlowSettingsEditingFF && hasEditHyperparametersPermission && <CashflowSettings />}
      <Templates />
      {hasAddeparSelfService ? <AddeparIntegration /> : <Integrations />}
    </>
  );
}
