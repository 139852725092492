import type { Analysis, CurrencyEnum, FrequencyEnum, Scenario } from 'venn-api';

export const mockPerformanceSummaryAnalysis = {
  maxStartTime: 1010447999999,
  maxEndTime: 1559260799999,
  maxFrequency: 'DAILY' as FrequencyEnum,
  analyses: [
    {
      analysisType: 'PERFORMANCE_SUMMARY_FORECAST',
      forecastedPerformanceSummary: [
        {
          volatility: 0.053075424778541026,
          sharpe: 1.0234702100163178,
          annualizedReturn: 0.05432111614479866,
        },
      ],
    } as Analysis,
    {
      analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
      historicalPerformanceSummary: [
        {
          totalReturn: 3.0293937677027216,
          volatility: 0.06177161338108259,
          maxDrawdown: -0.32143086058129533,
          sharpe: 1.1193404786401915,
          periodReturn: 0.08343355855855306,
          periodAnnualized: true,
        },
      ],
    } as Analysis,
  ] as Analysis[],
};

export const mockScenarioAnalysis = {
  maxStartTime: 1322697599999,
  maxEndTime: 1522540799999,
  maxFrequency: 'MONTHLY',
  analyses: [
    {
      analysisType: 'SCENARIO',
      scenarios: [
        [
          { predicted: -0.001866576047526586, predictedError: 0.0175430546096383 },
          { predicted: -0.004835004132825954, predictedError: 0.022828175035642558 },
          { predicted: -0.005628313162329281, predictedError: 0.01747026675889288 },
          { predicted: -0.0044654242106694775, predictedError: 0.022842974361573884 },
          { predicted: -0.0019003127149371394, predictedError: 0.026964203340085088 },
          { predicted: 0.0022075160938235033, predictedError: 0.027668808461682647 },
        ],
        [
          { predicted: -0.001866576047526586, predictedError: 0.0175430546096383 },
          { predicted: -0.004835004132825954, predictedError: 0.022828175035642558 },
          { predicted: -0.005628313162329281, predictedError: 0.01747026675889288 },
          { predicted: -0.0044654242106694775, predictedError: 0.022842974361573884 },
          { predicted: -0.0019003127149371394, predictedError: 0.026964203340085088 },
          { predicted: 0.0022075160938235033, predictedError: 0.027668808461682647 },
        ],
        [
          { predicted: -0.005334910326521416, predictedError: 0.03746253096754073 },
          { predicted: -0.014510701939140561, predictedError: 0.06141562003901967 },
          { predicted: -0.018259872865791567, predictedError: 0.03734625036614345 },
          { predicted: -0.01565259975138455, predictedError: 0.06145832576135651 },
          { predicted: -0.00851357585643743, predictedError: 0.048729881380692605 },
          { predicted: -0.007293278363557937, predictedError: 0.056985972270179285 },
        ],
      ],
    },
  ],
};

export const mockScenarioList: Scenario[] = [
  {
    id: '57cef8f0-f8bf-4e7b-a780-e41e10ff0955',
    fundId: 'e2f37988-75a0-4b72-8e32-179766642071',
    fundName: 'AQR Multi-Asset I',
    type: 'MARKET',
    seriesType: 'RETURN',
    shock: -0.009722529039790954,
    units: 'PERCENTAGE',
    currency: 'USD' as CurrencyEnum,
    active: true,
    mean: 0.005899723527496641,
    maxShock: 0.1922910498747442,
    maxShockZScore: 4.393179321455707,
    minShock: -0.25192592831356375,
    minShockZScore: -6.271303187628564,
    std: 0.04165387095059821,
    created: 1563375242666,
    updated: 1563375242666,
  },
  {
    id: '732ab993-5e37-4162-aadd-03bbbe8c34da',
    fundId: '65ff2414-b3ef-47c2-8ccb-15445e78bfbd',
    fundName: 'S&P 500 Index',
    type: 'MARKET',
    shock: 0.01,
    seriesType: 'RETURN',
    units: 'PERCENTAGE',
    currency: 'USD' as CurrencyEnum,
    active: true,
    mean: 0.012407429981077356,
    maxShock: 0.1922910498747442,
    maxShockZScore: 4.393179321455707,
    minShock: -0.25192592831356375,
    minShockZScore: -6.271303187628564,
    std: 0.04165387095059821,
    created: 1563209569523,
    updated: 1563375458467,
  },
  {
    id: '54b3f7a4-aeda-440b-b270-0b7e29a999e7',
    fundId: 'e2f37988-75a0-4b72-8e32-179766642071',
    fundName: 'AQR Multi-Asset I',
    type: 'MARKET',
    shock: 0.010277470960209046,
    seriesType: 'RETURN',
    units: 'PERCENTAGE',
    currency: 'USD' as CurrencyEnum,
    active: true,
    mean: 0.005899723527496641,
    maxShock: 0.1922910498747442,
    maxShockZScore: 4.393179321455707,
    minShock: -0.25192592831356375,
    minShockZScore: -6.271303187628564,
    std: 0.04165387095059821,
    created: 1563270999447,
    updated: 1563363955554,
  },
  {
    id: '3c16fc18-8c5f-498f-9da1-62eed02aa715',
    fundId: '65ff2414-b3ef-47c2-8ccb-15445e78bfbd',
    fundName: 'S&P 500 Index',
    type: 'MARKET',
    shock: 0.0,
    units: 'PERCENTAGE',
    seriesType: 'RETURN',
    currency: 'USD' as CurrencyEnum,
    active: true,
    mean: 0.012407429981077356,
    maxShock: 0.1922910498747442,
    maxShockZScore: 4.393179321455707,
    minShock: -0.25192592831356375,
    minShockZScore: -6.271303187628564,
    std: 0.04165387095059821,
    created: 1563271247463,
    updated: 1563364026162,
  },
  {
    id: '16ff8e48-3830-4bed-a2da-927a7e4c705a',
    fundId: '1e8111e5-4a9f-49b5-b56a-85f5fb4bf70c',
    fundName: 'VelocityShares VIX ST ETN',
    type: 'MARKET',
    shock: 0.0,
    units: 'PERCENTAGE',
    seriesType: 'RETURN',
    currency: 'USD' as CurrencyEnum,
    active: true,
    mean: -0.04527375797473269,
    maxShock: 0.1922910498747442,
    maxShockZScore: 4.393179321455707,
    minShock: -0.25192592831356375,
    minShockZScore: -6.271303187628564,
    std: 0.04165387095059821,
    created: 1563271973928,
    updated: 1563364170326,
  },
  {
    id: 'f3f0ca0a-1f25-44d1-95fe-7eccb0b73e7e',
    fundId: '7ff01087-cb98-41c4-b441-ec344a5340b3',
    fundName: 'Bloomberg Commodity Index Total Return',
    type: 'MARKET',
    shock: -0.013000000000000001,
    seriesType: 'RETURN',
    units: 'PERCENTAGE',
    currency: 'USD' as CurrencyEnum,
    active: true,
    mean: 0.0017641330617273127,
    maxShock: 0.1922910498747442,
    maxShockZScore: 4.393179321455707,
    minShock: -0.25192592831356375,
    minShockZScore: -6.271303187628564,
    std: 0.04165387095059821,
    created: 1563290572312,
    updated: 1563381137558,
  },
];

export const mockScenariosIndices = [
  {
    id: 'a151559b-f9fa-4de5-ab30-c15e9b257a24',
    fundId: 'e2f37988-75a0-4b72-8e32-179766642071',
    fundName: 'AQR Multi-Asset I',
    range: 0.044894562224982136,
    active: true,
    seriesType: 'RETURN',
    units: 'PERCENTAGE',
    currency: 'USD' as CurrencyEnum,
    defaultScenario: false,
    defaultScenarioShock: 1.5,
    created: 1563202174939,
    updated: 1563202174939,
    mean: 0.005899723527496641,
  },
  {
    id: 'b4f5044a-2b2d-49dc-bb22-3b4e4ce5b54c',
    fundId: '1e8111e5-4a9f-49b5-b56a-85f5fb4bf70c',
    fundName: 'VelocityShares VIX ST ETN',
    range: 0.3413849284462718,
    active: true,
    seriesType: 'RETURN',
    units: 'PERCENTAGE',
    currency: 'USD' as CurrencyEnum,
    defaultScenario: false,
    defaultScenarioShock: 1.5,
    created: 1562960742017,
    updated: 1562960742017,
    mean: -0.04527375797473269,
  },
  {
    id: '248dac26-d492-472d-97bb-2a249459d0f3',
    fundId: '65ff2414-b3ef-47c2-8ccb-15445e78bfbd',
    fundName: 'S&P 500 Index',
    range: 0.06287441993493544,
    active: true,
    seriesType: 'RETURN',
    units: 'PERCENTAGE',
    currency: 'USD' as CurrencyEnum,
    defaultScenario: false,
    defaultScenarioShock: 1.5,
    created: 1562954609019,
    updated: 1562954609019,
    mean: 0.012407429981077356,
  },
  {
    id: 'f12b0abb-28e8-48f5-bdb7-335b0d254df9',
    fundId: '7ff01087-cb98-41c4-b441-ec344a5340b3',
    fundName: 'Bloomberg Commodity Index Total Return',
    range: 0.04412688760437671,
    active: true,
    seriesType: 'RETURN',
    units: 'PERCENTAGE',
    currency: 'USD' as CurrencyEnum,
    defaultScenario: false,
    defaultScenarioShock: 1.5,
    created: 1562960763532,
    updated: 1562960763532,
    mean: 0.0017641330617273127,
  },
];
