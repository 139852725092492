import React, { useContext, useLayoutEffect, useRef } from 'react';
import { Footer, StudioContext, StudioShimmerBlock, hideChartTooltips, useAppPrintMode } from 'venn-components';
import styled from 'styled-components';
import type { BlockId } from 'venn-state';
import {
  COLUMN_GAP,
  CONTENT_PADDING,
  VIRTUALIZATION_SCROLL_BAR_PX,
  isReportState,
  studioBlockLayout,
  virtualScrollRef,
} from 'venn-state';
import Block from './block/Block';
import ReportPages from './ReportPages';
import StudioPrintContent from './StudioPrintContent';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { Components } from 'react-virtuoso';
import { Virtuoso } from 'react-virtuoso';
import { useVirtualization } from '../logic/useVirtualization';
import { assertNotNil } from 'venn-utils';

/** A component containing the entire layout of Report Lab pages or Studio blocks, in either print or edit modes. */
const BuilderList = React.memo(function BuilderList({ width }: { width: number }) {
  const focusBlockIndex = useRef<number>();
  const focusBlockRef = useRef<HTMLDivElement>(null);
  const { inPrintMode } = useAppPrintMode();
  const { analysisView, isGlobalAnalysisRangeLoading } = useContext(StudioContext);
  const isReport = useRecoilValue(isReportState);
  const isVirtualized = useVirtualization();
  const layout = useRecoilValue(studioBlockLayout);

  const setRecoilRef = useSetRecoilState(virtualScrollRef);

  useLayoutEffect(() => {
    if (focusBlockRef.current && focusBlockIndex.current !== undefined) {
      focusBlockRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      focusBlockIndex.current = undefined;
    }
  }, [analysisView?.customizedViews]);

  if (!analysisView || isGlobalAnalysisRangeLoading) {
    return (
      <>
        <StudioShimmerBlock height={600} />
        <StudioShimmerBlock height={600} />
      </>
    );
  }

  if (isReport) {
    return <ReportPages focusBlockIndex={focusBlockIndex} focusBlockRef={focusBlockRef} />;
  }

  return (
    <div>
      {inPrintMode ? (
        <StudioPrintContent width={width} />
      ) : isVirtualized ? (
        <Virtuoso
          ref={(current) => setRecoilRef({ current })}
          style={{ height: 'calc(100vh - 115px)', width: '100%' }}
          data={layout}
          itemSize={getItemSize}
          itemContent={(pageNumber: number, ids: BlockId[]) => (
            <VirtuosoBlocksWrapper>
              {ids.map((id) => (
                <Block
                  id={id}
                  key={id}
                  index={pageNumber}
                  focusBlockIndex={focusBlockIndex}
                  focusBlockRef={focusBlockRef}
                  width={width}
                />
              ))}
            </VirtuosoBlocksWrapper>
          )}
          components={virtuosoComponents}
          increaseViewportBy={{ bottom: 1000, top: 1000 }}
          onScroll={hideChartTooltips}
        />
      ) : !analysisView?.customizedViews?.length ? (
        <>
          <EmptyStateWrapper>
            <EmptyStateText>Add a Block to Start Analysis</EmptyStateText>
          </EmptyStateWrapper>
          <Footer />
        </>
      ) : (
        <>
          <Header />
          <NonVirtualizedBlocksWrapper>
            {analysisView.customizedViews?.map((view, index) => (
              <Block
                key={view.id ?? view.refId}
                id={assertNotNil(view.refId)}
                index={index}
                focusBlockIndex={focusBlockIndex}
                focusBlockRef={focusBlockRef}
                width={width}
              />
            ))}
          </NonVirtualizedBlocksWrapper>
          <Footer />
        </>
      )}
    </div>
  );
});

export default BuilderList;

/**
 * Measures the size of the element for virtuoso
 * Customised so that we avoid bad scrolling behaviour when scrolling back to an ag-grid block
 * that is in the process of rendering in.
 * Exported only for testing
 * @param el the element to measure
 */
export const getItemSize = (el: Element) => {
  // Fallback to the last known size if ag-grid is loading in
  if (el.querySelector('.ag-root-wrapper')?.getBoundingClientRect().height === 0) {
    return Number(el.getAttribute('data-known-size'));
  }
  return el.children.item(0)?.getBoundingClientRect().height ?? 0;
};

const VirtuosoBlocksWrapper = styled.div`
  display: flex;
  column-gap: ${COLUMN_GAP - VIRTUALIZATION_SCROLL_BAR_PX}px;
`;

const Item = styled.div<{ height: number }>`
  min-height: ${({ height }) => (height === 0 ? 'unset' : height)}px;
`;

const NonVirtualizedBlocksWrapper = styled.div`
  column-gap: ${COLUMN_GAP}px;
  display: flex;
  justify-content: flex-start;
  padding: 0 ${CONTENT_PADDING}px;
  flex-wrap: wrap;
  min-height: 100vh;
`;

const ListContainer = styled.div`
  min-height: calc(100vh - 115px);
  padding: 0 ${CONTENT_PADDING}px;
`;

const Header = styled.div`
  height: 40px;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const EmptyStateText = styled.div`
  text-shadow: 0 1px grey;
  font-weight: bold;
  color: #b8bec5;
  text-align: center;
  font-size: calc(1rem * 20 / 3);
  @media screen and (max-width: 1200px) {
    font-size: 5rem;
  }
`;

const virtuosoComponents: Components<BlockId[], unknown> = {
  List: ListContainer,
  Footer: () => <Footer />,
  Header: () => <Header />,
  Item: ({ children, ...props }) => (
    <Item {...props} height={props['data-known-size']}>
      {children}
    </Item>
  ),
  EmptyPlaceholder: () => (
    <EmptyStateWrapper>
      <EmptyStateText>Add a Block to Start Analysis</EmptyStateText>
    </EmptyStateWrapper>
  ),
};
