/*
 * Venn Platform API 1.0.0 Operations for "datasets"
 * Automatically generated file - do not modify!
 */

import type { DataSet, DataSetWithMembers } from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Add a set of subscriptions to access a specific data set */
export async function addSubscriptionsToDataSet(
  datasetId: string,
  hasRedistributionLicense?: boolean,
  hasExportLicense?: boolean,
  clientProvidedLicense?: boolean,
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/datasets/${encodeURIComponent(datasetId)}/subscriptions${buildQuery({ hasRedistributionLicense, hasExportLicense, clientProvidedLicense })}`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Add funds to a specific data set */
export async function addFundsToDataSet(
  datasetId: string,
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/datasets/${encodeURIComponent(datasetId)}/funds`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Create a data set */
export async function createDataSet(
  body?: Partial<DataSetWithMembers>,
  signal?: AbortSignal,
): Promise<OperationResult<string>> {
  const endpoint = '/api/datasets';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.json()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get specific data sets */
export async function getDataSet(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: DataSetWithMembers }>> {
  const endpoint = '/api/datasets';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: DataSetWithMembers } = (await response.json()) as {
      [key: string]: DataSetWithMembers;
    };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the data sets a user has access to */
export async function getUserDataSets(signal?: AbortSignal): Promise<OperationResult<{ [key: string]: number }>> {
  const endpoint = '/api/datasets/all';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: number } = (await response.json()) as { [key: string]: number };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the dates funds were added to data sets */
export async function getFundDataSetAddedDates(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: { [key: string]: number } }>> {
  const endpoint = '/api/datasets/funds/added';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: { [key: string]: number } } = (await response.json()) as {
      [key: string]: { [key: string]: number };
    };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the permissions of a user for a set of funds */
export async function hasFundAccess(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: boolean }>> {
  const endpoint = '/api/datasets/funds/access';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: boolean } = (await response.json()) as { [key: string]: boolean };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove a set of subscriptions from accessing a specific data set */
export async function removeSubscriptionsFromDataSet(
  datasetId: string,
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/datasets/${encodeURIComponent(datasetId)}/subscriptions/remove`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove funds from a specific data set */
export async function removeFundsFromDataSet(
  datasetId: string,
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/datasets/${encodeURIComponent(datasetId)}/funds/remove`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update dataset metadata */
export async function updateDatasetMetadata(
  datasetId: string,
  body?: Partial<DataSet>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/datasets/${encodeURIComponent(datasetId)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
