/*
 * Venn Platform API 1.0.0 Operations for "account"
 * Automatically generated file - do not modify!
 */

import type { Account, AccountQuery, TableWorkspace, TrialDetails } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Create an account */
export async function createAccount(body?: Partial<Account>, signal?: AbortSignal): Promise<OperationResult<Account>> {
  const endpoint = '/api/account';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Account = (await response.json()) as Account;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all accounts */
export async function getAllAccounts(signal?: AbortSignal): Promise<OperationResult<Account[]>> {
  const endpoint = '/api/account';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Account[] = (await response.json()) as Account[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all invalid accounts */
export async function getAllInvalidAccounts(signal?: AbortSignal): Promise<OperationResult<Account[]>> {
  const endpoint = '/api/account/subscription/invalid';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Account[] = (await response.json()) as Account[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get current users account */
export async function getMyAccount(signal?: AbortSignal): Promise<OperationResult<Account>> {
  const endpoint = '/api/account/me';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Account = (await response.json()) as Account;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get workspaces for account */
export async function getAccountWorkspaces(
  id: number,
  signal?: AbortSignal,
): Promise<OperationResult<TableWorkspace[]>> {
  const endpoint = `/api/account/${encodeURIComponent(id)}/workspaces`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: TableWorkspace[] = (await response.json()) as TableWorkspace[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Initiate a Venn Pro trial */
export async function initiateProTrial(signal?: AbortSignal): Promise<OperationResult<TrialDetails>> {
  const endpoint = '/api/account/trial';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: TrialDetails = (await response.json()) as TrialDetails;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Reach out to Venn about account details */
export async function contactVennAboutAccount(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/account/contact';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove this accounts sponsor */
export async function removeSponsor(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/account/sponsor';
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Request a Venn Pro trial */
export async function requestProTrial(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/account/requestTrial';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Retrieve accounts trial details */
export async function getAccountTrialStatus(signal?: AbortSignal): Promise<OperationResult<TrialDetails>> {
  const endpoint = '/api/account/trial';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: TrialDetails = (await response.json()) as TrialDetails;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Search for accounts */
export async function searchAccounts(
  body?: Partial<AccountQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<Account[]>> {
  const endpoint = '/api/account/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Account[] = (await response.json()) as Account[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Search for accounts, and across users */
export async function searchAccountsAndUsers(
  body?: Partial<AccountQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<Account[]>> {
  const endpoint = '/api/account/deep-search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Account[] = (await response.json()) as Account[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Sign a user in your account out of any existing sessions */
export async function signAccountUserOut(userId: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/account/users/${encodeURIComponent(userId)}/signout`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update an account */
export async function updateAccount(
  id: number,
  body?: Partial<Account>,
  signal?: AbortSignal,
): Promise<OperationResult<Account>> {
  const endpoint = `/api/account/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Account = (await response.json()) as Account;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
