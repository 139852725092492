import type { FC } from 'react';
import React from 'react';

const THICKNESS = 20;
const INDICATOR_COLOR = 'black';

interface Props {
  color?: string;
  height: number;
  width?: number;
  transform: string;
}

const ValueIndicator: FC<React.PropsWithChildren<Props>> = ({
  color = INDICATOR_COLOR,
  height,
  width = 5,
  transform,
}) => (
  <line
    transform={transform}
    height={height}
    width={width}
    style={{ stroke: color }}
    x1={0}
    y1={0}
    x2={0}
    y2={THICKNESS}
  />
);

export default ValueIndicator;
