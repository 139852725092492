import { TOTAL_ID, RESIDUAL_ID, RISK_FREE_RATE_ID } from '../../../factor-chart';
import { SortByOption } from '../../../configuration';
import { generateColor } from '../../../heat-map/utils';
import type { HeatMapTypes } from '../../../heat-map/Types';
import type { FactorsDataPoint, FactorsData, FactorAnalysisGroup, factorAPIResponseKey, RangeGenerator } from './types';
import { RISK_BLOCK, RETURN_BLOCK } from './types';
import type { FactorSummaryChartData } from '../../../factor-summary/factorSummaryTypes';
import type { Analysis, FactorLensWithReturns, ReturnsFactorAnalysis } from 'venn-api';
import type { AnalysisSubject, AnalysisLabelType } from 'venn-utils';
import { getAnalysisLabels, getRequestSubjectFromAnalysisSubject } from 'venn-utils';
import { compact } from 'lodash';
import type { VennColors } from 'venn-ui-kit';
import type { BlockLevelSettings } from '../../../contexts';
import { formatExportableSubjectWithOptionalFee } from '../../../legend';

/**
 * In order to sort data, we need to combine risk/return/exposure data
 *  We also need to parse residual and total data to the same factor structure.
 */
export const parseFactorSummaryToSortableData = (
  analysisGroup: FactorAnalysisGroup,
  activeFactorLens: FactorLensWithReturns | undefined,
  activeBenchmark: boolean,
  isFund: boolean,
): FactorsData[] => {
  if (!analysisGroup || (!analysisGroup.risk && !analysisGroup.return && !analysisGroup.exposure)) {
    return [];
  }
  const [mainRiskData, benchmarkRiskData, baselineRiskData] = getChartData(
    analysisGroup.risk,
    'factorContributionToRisk',
    activeBenchmark,
  ) as ReturnsFactorAnalysis[];
  const [mainReturnData, benchmarkReturnData, baselineReturnData] = getChartData(
    analysisGroup.return,
    'factorContributionToReturn',
    activeBenchmark,
  ) as ReturnsFactorAnalysis[];
  const [mainExposureData, benchmarkExposureData, baselineExposureData] = getChartData(
    analysisGroup.exposure,
    'factorExposures',
    activeBenchmark,
  ) as ReturnsFactorAnalysis[];
  const defaultFactors = activeFactorLens?.factors || [];

  const mappedFactors = defaultFactors.map((factor) => ({
    factorId: factor.id,
    factorName: factor.name,
    mainDataPoint: findFactorsData(factor.id, mainRiskData, mainReturnData, mainExposureData),
    baselineDataPoint: !isFund
      ? findFactorsData(factor.id, baselineRiskData, baselineReturnData, baselineExposureData)
      : undefined,
    comparisonDataPoint: findFactorsData(factor.id, benchmarkRiskData, benchmarkReturnData, benchmarkExposureData),
    categoryDataPoint: isFund
      ? findFactorsData(factor.id, baselineRiskData, baselineReturnData, baselineExposureData)
      : undefined,
  }));
  return calFactorRows(mappedFactors, mainReturnData, baselineReturnData, benchmarkReturnData, isFund);
};

const getChartData = (analysis: Analysis | undefined, key: factorAPIResponseKey, activeBenchmark: boolean) => {
  if (!analysis) {
    return [];
  }
  const analysisData = analysis?.[key];
  if (!analysisData) {
    return [];
  }
  const [mainData, secondaryData, comparisonData] = analysisData;
  const benchmarkData = activeBenchmark ? secondaryData : undefined;
  const baselineData = activeBenchmark ? comparisonData : secondaryData;
  return [mainData, benchmarkData, baselineData];
};

const findFactorsData = (
  factorId: number,
  riskAnalysis: ReturnsFactorAnalysis,
  returnAnalysis: ReturnsFactorAnalysis,
  exposureAnalysis: ReturnsFactorAnalysis,
): FactorsDataPoint | undefined => {
  if (!riskAnalysis && !returnAnalysis && !exposureAnalysis) {
    return undefined;
  }
  const riskFactor = riskAnalysis ? riskAnalysis?.factors?.find((f) => f?.id === factorId) : null;
  const returnFactor = returnAnalysis ? returnAnalysis?.factors?.find((f) => f?.id === factorId) : null;
  const exposureFactor = exposureAnalysis?.factors?.find((f) => f?.id === factorId);

  return {
    risk: riskFactor
      ? {
          value: riskFactor.contribution,
          statisticallySignificant: riskFactor.significant,
          tvalue: riskFactor.tstat,
        }
      : {
          value: null,
          statisticallySignificant: false,
          tvalue: undefined,
        },
    return: returnFactor
      ? {
          value: returnFactor.contribution,
          statisticallySignificant: returnFactor.significant,
          tvalue: returnFactor.tstat,
        }
      : {
          value: null,
          statisticallySignificant: false,
          tvalue: undefined,
        },
    exposure: exposureFactor
      ? {
          value: exposureFactor.contribution,
          statisticallySignificant: exposureFactor.significant,
          tvalue: exposureFactor.tstat,
        }
      : {
          value: null,
          statisticallySignificant: false,
          tvalue: undefined,
        },
  };
};

export const compareFactorSummaryRows = (left: FactorsData, right: FactorsData, sortBy: SortByOption): number => {
  const LEFT_FIRST = -1;
  const RIGHT_FIRST = 1;

  if (left.factorId === TOTAL_ID) {
    return RIGHT_FIRST;
  }
  if (right.factorId === TOTAL_ID) {
    return LEFT_FIRST;
  }

  if (left.factorId === RISK_FREE_RATE_ID) {
    return RIGHT_FIRST;
  }
  if (right.factorId === RISK_FREE_RATE_ID) {
    return LEFT_FIRST;
  }

  if (left.factorId === RESIDUAL_ID) {
    return RIGHT_FIRST;
  }
  if (right.factorId === RESIDUAL_ID) {
    return LEFT_FIRST;
  }

  if (isFactorsDataPointEmpty(left.mainDataPoint)) {
    return RIGHT_FIRST;
  }
  if (isFactorsDataPointEmpty(right.mainDataPoint)) {
    return LEFT_FIRST;
  }

  return compareMetrics(left.mainDataPoint, right.mainDataPoint, sortBy);
};

const isFactorsDataPointEmpty = (dataPoint?: FactorsDataPoint) => {
  return (
    !dataPoint ||
    ((!dataPoint.return || dataPoint.return.value === null) &&
      (!dataPoint.risk || dataPoint.risk.value === null) &&
      (!dataPoint.exposure || dataPoint.exposure.value === null))
  );
};

const compareMetrics = (
  left: FactorsDataPoint | undefined,
  right: FactorsDataPoint | undefined,
  sortBy: SortByOption,
): number => {
  switch (sortBy) {
    case SortByOption.SortByContributionToReturn:
      return (right?.return?.value ?? 0) - (left?.return?.value ?? 0);
    case SortByOption.SortByContributionToRisk:
      return (right?.risk?.value ?? 0) - (left?.risk?.value ?? 0);
    case SortByOption.SortByExposure:
      return (right?.exposure?.value ?? 0) - (left?.exposure?.value ?? 0);
    default:
      return 0;
  }
};

export const filterFactorsInsignificantData = (
  mainDataSetName: string,
  baselineDataSetName: string | undefined,
  comparisonDataSetName: string | undefined,
  categoryDataSetName: string | undefined,
  factorsData: FactorsData[],
  key: 'risk' | 'return' | 'exposure',
  isFund: boolean,
  showCategoryColumn: boolean,
): FactorSummaryChartData => {
  const emptyDataPoint = showCategoryColumn
    ? { value: null, tvalue: undefined, statisticallySignificant: false }
    : undefined;
  const benchmarkEmptyDataPoint = comparisonDataSetName ? emptyDataPoint : undefined;
  const categoryEmptyDataPoint = categoryDataSetName ? emptyDataPoint : undefined;

  return {
    mainDataSetName,
    baselineDataSetName,
    comparisonDataSetName,
    categoryDataSetName,
    rows: factorsData
      .filter(
        (f) =>
          f?.mainDataPoint?.[key]?.value ||
          f?.comparisonDataPoint?.[key]?.value ||
          f?.categoryDataPoint?.[key]?.value ||
          !!f?.baselineDataPoint?.[key]?.value,
      )
      .map((f) => ({
        factorId: f.factorId,
        factorName: f.factorName,
        mainDataPoint: f?.mainDataPoint?.[key],
        baselineDataPoint: !isFund ? f?.baselineDataPoint?.[key] : undefined,
        comparisonDataPoint: f?.comparisonDataPoint?.[key] || benchmarkEmptyDataPoint,
        categoryDataPoint: isFund ? f?.categoryDataPoint?.[key] || categoryEmptyDataPoint : undefined,
      })),
  };
};

const calFactorRows = (
  factorsData: FactorsData[],
  mainAnalysis: ReturnsFactorAnalysis,
  baselineAnalysis: ReturnsFactorAnalysis,
  comparisonAnalysis: ReturnsFactorAnalysis,
  isFund: boolean,
): FactorsData[] => {
  const residualRow = {
    factorName: 'Residual',
    factorId: RESIDUAL_ID,
    mainDataPoint: deriveResidualMetrics(mainAnalysis),
    baselineDataPoint: !isFund ? deriveResidualMetrics(baselineAnalysis) : undefined,
    comparisonDataPoint: deriveResidualMetrics(comparisonAnalysis),
    categoryDataPoint: isFund ? deriveResidualMetrics(baselineAnalysis) : undefined,
  };
  const riskFreeRow = {
    factorName: 'Risk-Free Rate',
    factorId: RISK_FREE_RATE_ID,
    mainDataPoint: deriveRiskFreeMetrics(mainAnalysis),
    baselineDataPoint: !isFund ? deriveRiskFreeMetrics(baselineAnalysis) : undefined,
    comparisonDataPoint: deriveRiskFreeMetrics(comparisonAnalysis),
    categoryDataPoint: isFund ? deriveRiskFreeMetrics(baselineAnalysis) : undefined,
  };
  const totalRow = {
    factorName: 'Total',
    factorId: TOTAL_ID,
    mainDataPoint: deriveTotalMetrics(mainAnalysis),
    baselineDataPoint: !isFund ? deriveTotalMetrics(baselineAnalysis) : undefined,
    comparisonDataPoint: deriveTotalMetrics(comparisonAnalysis),
    categoryDataPoint: isFund ? deriveTotalMetrics(baselineAnalysis) : undefined,
  };

  return [...factorsData, residualRow, riskFreeRow, totalRow];
};

const deriveResidualMetrics = (returnFactoranalysis: ReturnsFactorAnalysis): FactorsDataPoint | undefined =>
  returnFactoranalysis
    ? {
        risk: {
          value: returnFactoranalysis.residualRisk,
          tvalue: returnFactoranalysis.residualTStat,
          statisticallySignificant: returnFactoranalysis.residualSignificant,
        },
        return: {
          value: returnFactoranalysis.periodResidualReturn,
          tvalue: returnFactoranalysis.residualTStat,
          statisticallySignificant: returnFactoranalysis.residualSignificant,
        },
        exposure: undefined,
      }
    : undefined;

const deriveRiskFreeMetrics = (returnFactoranalysis: ReturnsFactorAnalysis): FactorsDataPoint | undefined =>
  returnFactoranalysis
    ? {
        risk: undefined,
        return: {
          value: returnFactoranalysis.riskFreeReturn,
          tvalue: undefined,
          statisticallySignificant: true,
        },
        exposure: undefined,
      }
    : undefined;

const deriveTotalMetrics = (returnFactorAnalysis: ReturnsFactorAnalysis): FactorsDataPoint | undefined =>
  returnFactorAnalysis
    ? {
        risk: { value: null, tvalue: undefined, statisticallySignificant: false },
        return: {
          value: returnFactorAnalysis.periodTotalReturn,
          tvalue: undefined,
          statisticallySignificant: true,
        },
        exposure: undefined,
      }
    : undefined;

export const parseFactorTrendData = (
  key: factorAPIResponseKey,
  analysis: Analysis,
  activeFactorLens: FactorLensWithReturns | undefined,
  type: 'risk' | 'return' | 'exposure' = 'risk',
  analysisSubject: AnalysisSubject,
  multipier: number,
  colors: VennColors,
  feesMapping: { [key: string]: number },
  residualKey?: string,
  hasTotal?: boolean,
  hasRiskFreeRate?: boolean,
  hasActiveBenchmark?: boolean,
  activeBenchmarkName?: string,
  overrideSecondaryName?: string,
): { series: HeatMapTypes.Root[]; analyzedFactors: number } => {
  if (!analysis) {
    return { series: [], analyzedFactors: 0 };
  }
  const [mainData, benchmarkData, baselineData] = getChartData(
    analysis,
    key,
    !!hasActiveBenchmark,
  ) as ReturnsFactorAnalysis[][];
  if (!mainData) {
    return { series: [], analyzedFactors: 0 };
  }
  const isFund = analysisSubject?.superType === 'investment';
  const defaultFactors = activeFactorLens?.factors || [];
  const maxValue = findMaxValue(mainData, residualKey, !!hasRiskFreeRate);
  const colorGetter = generateColor(-maxValue * multipier, maxValue * multipier, colors);
  const rangeGenerator: RangeGenerator = {
    extremes: {
      min: -maxValue,
      max: maxValue,
    },
    colorGetter,
  };
  const factors = defaultFactors
    .map((factor) => ({
      factorId: factor.id,
      type,
      name: factor.name,
      series: groupDataForTrend(
        factor.id,
        analysisSubject,
        mainData,
        benchmarkData,
        baselineData,
        multipier,
        rangeGenerator,
        isFund,
        colors,
        feesMapping,
        activeBenchmarkName,
        overrideSecondaryName,
      ),
    }))
    .filter((factor) => factor?.series?.[0]?.data?.filter((serie) => !!serie?.value)?.length > 0);
  const analyzedFactors = factors.length;
  if (residualKey) {
    factors.push({
      factorId: RESIDUAL_ID,
      name: 'Residual',
      type,
      series: groupResidualforTrend(
        analysisSubject,
        mainData,
        benchmarkData,
        baselineData,
        multipier,
        residualKey,
        rangeGenerator,
        isFund,
        colors,
        feesMapping,
        activeBenchmarkName,
        overrideSecondaryName,
      ),
    });
  }
  if (hasRiskFreeRate) {
    factors.push({
      factorId: RISK_FREE_RATE_ID,
      name: 'Risk-Free Rate',
      type,
      series: groupRiskFreeforTrend(
        analysisSubject,
        mainData,
        benchmarkData,
        baselineData,
        rangeGenerator,
        colorGetter,
        multipier,
        isFund,
        colors,
        feesMapping,
        activeBenchmarkName,
        overrideSecondaryName,
      ),
    });
  }
  if (hasTotal) {
    factors.push({
      factorId: TOTAL_ID,
      name: 'Total',
      type,
      series: groupTotalforTrend(
        analysisSubject,
        mainData,
        benchmarkData,
        baselineData,
        rangeGenerator,
        colorGetter,
        multipier,
        isFund,
        colors,
        feesMapping,
        activeBenchmarkName,
        overrideSecondaryName,
      ),
    });
  }
  return { series: factors, analyzedFactors };
};

const groupDataForTrend = (
  factorId: number,
  analysisSubject: AnalysisSubject,
  mainData: ReturnsFactorAnalysis[],
  benchmarkData: ReturnsFactorAnalysis[],
  baselineData: ReturnsFactorAnalysis[],
  multipier: number,
  rangeGenerator: RangeGenerator,
  isFund: boolean,
  colors: VennColors,
  feesMapping: { [key: string]: number },
  activeBenchmarkName?: string,
  overrideSecondaryName?: string,
): HeatMapTypes.SeriesEntity[] => {
  const secondaryLabel = analysisSubject?.secondaryLabel;
  const analysisLabels = getAnalysisLabels(
    analysisSubject.type,
    secondaryLabel,
    analysisSubject.secondaryPortfolio?.updated,
  );
  const mainAnalysis = generateAnalysisGroup(
    mainData,
    analysisLabels.main,
    factorId,
    getNameForAnalysisSubject(analysisSubject, feesMapping),
    multipier,
    rangeGenerator,
    colors,
  );
  const baselineAnalysis = isFund
    ? undefined
    : generateAnalysisGroup(
        baselineData,
        analysisLabels.comparison,
        factorId,
        overrideSecondaryName ?? analysisSubject?.secondaryPortfolio?.name ?? '',
        multipier,
        rangeGenerator,
        colors,
      );
  const benchmarkAnalysis = generateAnalysisGroup(
    benchmarkData,
    analysisLabels.benchmark,
    factorId,
    activeBenchmarkName ?? '',
    multipier,
    rangeGenerator,
    colors,
  );
  const categoryAnalysis = !isFund
    ? undefined
    : generateAnalysisGroup(
        baselineData,
        analysisLabels.category,
        factorId,
        analysisSubject?.categoryGroup?.name ?? '',
        multipier,
        rangeGenerator,
        colors,
      );
  return compact([mainAnalysis, baselineAnalysis, benchmarkAnalysis, categoryAnalysis]);
};

const generateAnalysisGroup = (
  mainData: ReturnsFactorAnalysis[],
  portfolioType: AnalysisLabelType | undefined,
  factorId: number,
  name: string,
  multipier: number,
  rangeGenerator: RangeGenerator,
  colors: VennColors,
): HeatMapTypes.SeriesEntity | undefined => {
  if (!mainData) {
    return undefined;
  }

  return {
    extremes: rangeGenerator.extremes,
    name,
    portfolioType,
    colors: {
      positive: colors.DivergingColor.B3,
      negative: colors.DivergingColor.A3,
    },
    data: mainData.map((returnsFactorAnalysis) => {
      const findData = returnsFactorAnalysis.factors.find((f) => f.id === factorId);
      if (!findData) {
        return {
          value: undefined,
          nonSignificant: true,
          color: '',
        };
      }
      return {
        value: findData.contribution,
        tValue: findData.tstat,
        nonSignificant: !findData.significant,
        color: rangeGenerator.colorGetter(findData.contribution * multipier) || '',
        residual: false,
      };
    }),
  };
};

const groupRiskFreeforTrend = (
  analysisSubject: AnalysisSubject,
  mainData: ReturnsFactorAnalysis[],
  benchmarkData: ReturnsFactorAnalysis[],
  baselineData: ReturnsFactorAnalysis[],
  rangeGenerator: RangeGenerator,
  colorGetter: (value: number) => string | undefined,
  multipier: number,
  isFund: boolean,
  colors: VennColors,
  feesMapping: { [key: string]: number },
  activeBenchmarkName?: string,
  overrideSecondaryName?: string,
): HeatMapTypes.SeriesEntity[] => {
  const secondaryLabel = analysisSubject?.secondaryLabel;
  const analysisLabels = getAnalysisLabels(
    analysisSubject.type,
    secondaryLabel,
    analysisSubject.secondaryPortfolio?.updated,
  );
  const mainAnalysis = {
    extremes: rangeGenerator.extremes,
    name: getNameForAnalysisSubject(analysisSubject, feesMapping),
    portfolioType: analysisLabels.main,
    colors: {
      positive: colors.DivergingColor.B3,
      negative: colors.DivergingColor.A3,
    },
    data: generateAnalysisRiskFree(mainData, colorGetter, multipier),
  };
  const baselineAnalysis =
    !isFund && baselineData
      ? {
          extremes: rangeGenerator.extremes,
          name: overrideSecondaryName ?? analysisSubject?.secondaryPortfolio?.name ?? '',
          portfolioType: analysisLabels.comparison,
          data: generateAnalysisRiskFree(baselineData, colorGetter, multipier),
        }
      : undefined;
  const benchmarkAnalysis = benchmarkData
    ? {
        extremes: rangeGenerator.extremes,
        name: activeBenchmarkName ?? '',
        portfolioType: analysisLabels.benchmark,
        data: generateAnalysisRiskFree(benchmarkData, colorGetter, multipier),
      }
    : undefined;
  const categoryAnalysis =
    isFund && baselineData
      ? {
          extremes: rangeGenerator.extremes,
          name: analysisSubject?.categoryGroup?.name ?? '',
          portfolioType: analysisLabels.category,
          data: generateAnalysisRiskFree(baselineData, colorGetter, multipier),
        }
      : undefined;
  return compact([mainAnalysis, baselineAnalysis, benchmarkAnalysis, categoryAnalysis]);
};

const generateAnalysisRiskFree = (
  data: ReturnsFactorAnalysis[],
  colorGetter: (value: number) => string | undefined,
  multipier: number,
): HeatMapTypes.DataEntity[] => {
  if (!data) {
    return [];
  }

  return data.map((m) => ({
    value: m.riskFreeReturn,
    nonSignificant: false,
    color: colorGetter(m.riskFreeReturn * multipier) || '',
  }));
};

const groupResidualforTrend = (
  analysisSubject: AnalysisSubject,
  mainData: ReturnsFactorAnalysis[],
  benchmarkData: ReturnsFactorAnalysis[],
  baselineData: ReturnsFactorAnalysis[],
  multipier: number,
  accessor: string,
  rangeGenerator: RangeGenerator,
  isFund: boolean,
  colors: VennColors,
  feesMapping: { [key: string]: number },
  activeBenchmarkName?: string,
  overrideSecondaryName?: string,
): HeatMapTypes.SeriesEntity[] => {
  const secondaryLabel = analysisSubject?.secondaryLabel;
  const analysisLabels = getAnalysisLabels(
    analysisSubject.type,
    secondaryLabel,
    analysisSubject.secondaryPortfolio?.updated,
  );
  const mainAnalysis = generateAnalysisResidual(
    mainData,
    analysisLabels.main,
    multipier,
    getNameForAnalysisSubject(analysisSubject, feesMapping),
    accessor,
    rangeGenerator,
    colors,
  );
  const baselineAnalysis = isFund
    ? undefined
    : generateAnalysisResidual(
        baselineData,
        analysisLabels.comparison,
        multipier,
        overrideSecondaryName ?? analysisSubject?.secondaryPortfolio?.name ?? '',
        accessor,
        rangeGenerator,
        colors,
      );
  const benchmarkAnalysis = generateAnalysisResidual(
    benchmarkData,
    analysisLabels.benchmark,
    multipier,
    activeBenchmarkName ?? '',
    accessor,
    rangeGenerator,
    colors,
  );
  const categoryAnalysis = !isFund
    ? undefined
    : generateAnalysisResidual(
        baselineData,
        analysisLabels.category,
        multipier,
        analysisSubject?.categoryGroup?.name ?? '',
        accessor,
        rangeGenerator,
        colors,
      );
  return compact([mainAnalysis, baselineAnalysis, benchmarkAnalysis, categoryAnalysis]);
};

const generateAnalysisResidual = (
  mainData: ReturnsFactorAnalysis[],
  portfolioType: AnalysisLabelType | undefined,
  multipier: number,
  name: string,
  accessor: string,
  rangeGenerator: RangeGenerator,
  colors: VennColors,
) => {
  if (!mainData) {
    return undefined;
  }
  return {
    extremes: rangeGenerator.extremes,
    name,
    portfolioType,
    colors: {
      positive: colors.DivergingColor.B3,
      negative: colors.DivergingColor.A3,
    },
    data: mainData.map((m) => ({
      value: m[accessor],
      tValue: m.residualTStat,
      nonSignificant: !m.residualSignificant,
      color: rangeGenerator.colorGetter(m[accessor] * multipier) || '',
      residual: m.residualSignificant,
    })),
  };
};

export const getFactorChartTimeStamps = (returnFactorRolling: ReturnsFactorAnalysis[] | undefined): number[] => {
  if (!returnFactorRolling) {
    return [];
  }
  return returnFactorRolling.map((rolling) => rolling.regressionEnd);
};

const findMaxValue = (mainData: ReturnsFactorAnalysis[], residualKey: string | undefined, hasRiskFreeRate: boolean) => {
  let max = 0;

  if (residualKey) {
    // Consider residual in the max value calculation
    mainData.forEach((r) => (max = Math.max(Math.abs(r[residualKey]), max)));
  }

  if (hasRiskFreeRate) {
    // Consider the risk free rate in the max value calculation
    mainData.forEach((r) => (max = Math.max(Math.abs(r.riskFreeReturn), max)));
  }

  // Consider factor contributions in the max value calculation
  mainData.forEach((r) => r.factors.forEach((f) => (max = Math.max(Math.abs(f.contribution), max))));
  return max;
};

const groupTotalforTrend = (
  analysisSubject: AnalysisSubject,
  mainData: ReturnsFactorAnalysis[],
  benchmarkData: ReturnsFactorAnalysis[],
  baselineData: ReturnsFactorAnalysis[],
  rangeGenerator: RangeGenerator,
  colorGetter: (value: number) => string | undefined,
  multipier: number,
  isFund: boolean,
  colors: VennColors,
  feesMapping: { [key: string]: number },
  activeBenchmarkName?: string,
  overrideSecondaryName?: string,
): HeatMapTypes.SeriesEntity[] => {
  const secondaryLabel = analysisSubject?.secondaryLabel;
  const analysisLabels = getAnalysisLabels(
    analysisSubject.type,
    secondaryLabel,
    analysisSubject.secondaryPortfolio?.updated,
  );
  const mainAnalysis = {
    extremes: rangeGenerator.extremes,
    name: getNameForAnalysisSubject(analysisSubject, feesMapping),
    portfolioType: analysisLabels.main,
    colors: {
      positive: colors.DivergingColor.B3,
      negative: colors.DivergingColor.A3,
    },
    data: generateAnalysisTotal(mainData, colorGetter, multipier),
  };
  const baselineAnalysis: HeatMapTypes.SeriesEntity | undefined =
    !isFund && baselineData
      ? {
          extremes: rangeGenerator.extremes,
          name: overrideSecondaryName ?? analysisSubject?.secondaryPortfolio?.name ?? '',
          portfolioType: analysisLabels.comparison,
          data: generateAnalysisTotal(baselineData, colorGetter, multipier),
        }
      : undefined;
  const benchmarkAnalysis: HeatMapTypes.SeriesEntity | undefined = benchmarkData
    ? {
        extremes: rangeGenerator.extremes,
        name: activeBenchmarkName ?? '',
        portfolioType: analysisLabels.benchmark,
        data: generateAnalysisTotal(benchmarkData, colorGetter, multipier),
      }
    : undefined;
  const categoryAnalysis: HeatMapTypes.SeriesEntity | undefined =
    isFund && baselineData
      ? {
          extremes: rangeGenerator.extremes,
          name: analysisSubject?.categoryGroup?.name ?? '',
          portfolioType: analysisLabels.category,
          data: generateAnalysisTotal(baselineData, colorGetter, multipier),
        }
      : undefined;
  return compact([mainAnalysis, baselineAnalysis, benchmarkAnalysis, categoryAnalysis]);
};

const generateAnalysisTotal = (
  data: ReturnsFactorAnalysis[],
  colorGetter: (value: number) => string | undefined,
  multipier: number,
): HeatMapTypes.DataEntity[] => {
  if (!data) {
    return [];
  }

  return data.map((m) => ({
    value: m.periodTotalReturn,
    nonSignificant: false,
    color: colorGetter(m.periodTotalReturn * multipier) || '',
  }));
};

interface TrendResults {
  updatedTrend: FactorAnalysisGroup;
  updatedView: Partial<BlockLevelSettings>;
}

export const parseTrendResults = (analyses: Analysis[], rollingYears?: number): TrendResults => {
  let updatedView = {};
  let updatedTrend = {};
  analyses.forEach((analyse) => {
    const analysisType = analyse.analysisType;
    switch (analysisType) {
      case 'FACTOR_EXPOSURES_TREND':
        updatedTrend = { ...updatedTrend, exposure: { ...analyse } };
        updatedView = { ...updatedView, rollingFactorExposuresPeriod: rollingYears };
        break;
      case 'FACTOR_CONTRIBUTION_TO_RISK_TREND':
        updatedTrend = { ...updatedTrend, risk: { ...analyse } };
        updatedView = { ...updatedView, rollingFactorRiskPeriod: rollingYears };
        break;
      case 'FACTOR_CONTRIBUTION_TO_RETURN_TREND':
        updatedTrend = { ...updatedTrend, return: { ...analyse } };
        updatedView = { ...updatedView, rollingFactorReturnPeriod: rollingYears };
    }
  });

  return {
    updatedTrend,
    updatedView,
  };
};

export const getTargetBlocksResult = (group: FactorAnalysisGroup, block?: string): FactorAnalysisGroup => {
  /** If block is specified, it's a single block */
  if (block) {
    switch (block) {
      // this block using different id for analysis
      case 'FACTOR_CONTRIBUTION_TO_EXPOSURE_TREND':
        return { exposure: group.exposure };
      case RISK_BLOCK:
        return { risk: group.risk };
      case RETURN_BLOCK:
        return { return: group.return };
    }
  }
  return group;
};

function getNameForAnalysisSubject(analysisSubject: AnalysisSubject, feesMapping: { [key: string]: number }) {
  return formatExportableSubjectWithOptionalFee(getRequestSubjectFromAnalysisSubject(analysisSubject, feesMapping));
}
