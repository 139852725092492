import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ChromePicker, type ColorResult } from 'react-color';
import {
  Button,
  CUSTOM_COLORS_KEYS,
  ExternalActivityListener,
  GetColor,
  Headline3,
  Notifications,
  NotificationType,
  RelativePortal,
  type VennColors,
  ZIndex,
  createDivergingColors,
  type CustomColors,
  createPeerGroupColors,
  Tooltip,
  type CustomColorsKeys,
} from 'venn-ui-kit';
import styled, { ThemeContext } from 'styled-components';
import ModalHeader from '../modal/ModalHeader';
import ModalFooter from '../modal/ModalFooter';
import { ModalContent } from '../modal/components/ModalWrapper';
import { cloneDeep, debounce, get, isEqual, isNil, pick, setWith } from 'lodash';
import { Hotkeys, logExceptionIntoSentry, useHasFF, useHotkeys, useModal, useSyncedState } from 'venn-utils';
import ConditionalOverlay from '../conditional-overlay';
import { UserContext } from '../contexts';

interface ColorItem {
  name: string;
  path: string[];
}

type ColorGroup = {
  colors: ColorItem[];
  children: {
    name: string;
    value: ColorGroup;
  }[];
};

interface ColorScheme {
  name: string;
  description: string | JSX.Element;
  colors: ColorGroup;
}
type EditableColorSetting<KEYS extends string | number | symbol> = {
  displayName: string;
  description: string | JSX.Element;
  /** Influences editability, display name, and also sets an implicit ordering based on the ordering of the editable keys. */
  editableKeys?: { key: KEYS; displayName?: string }[];
};

interface ColorPickerProps {
  close: () => void;
}

export const CustomThemeCustomizer = () => {
  const [isOpen, open, close] = useModal();
  const canOpen = useHasFF('color_scheme_picker');

  useHotkeys((event) => {
    if (canOpen) {
      open();
      event.preventDefault();
    }
  }, Hotkeys.COLOR_SCHEME_PICKER);

  return isOpen ? <PickerWarningStateWrapper close={close} /> : null;
};

const PickerWarningStateWrapper = ({ close }: ColorPickerProps) => {
  const hasNewColorScheme = useHasFF('new_venn_color_theme');

  const { customColors } = useContext(ThemeContext);
  // We want to save the state of hasCustomTheme so that we don't switch states once the user clicks reset
  const hasCustomTheme = useRef(!isNil(customColors));

  const { isPublishing: saving, onPublish } = usePublishCallback(close);
  const onSave = () => onPublish(customColors);

  if (!hasNewColorScheme) {
    if (hasCustomTheme.current) {
      return (
        <ModalWrapper>
          <CustomizerHeader />
          <ModalContent>
            <ColorsWrapper>
              <Headline3>
                This workspace has a custom theme applied, but is currently using the pre-rebrand palette, as the
                new_venn_color_theme feature flag is disabled.
              </Headline3>
              <br />
              <Headline3>
                The workspace should either have new_venn_color_theme enabled on the Alpha Site, or the theme should be
                reset to Venn defaults and then published using this panel.
              </Headline3>
              <div>
                <ResetButton />
              </div>
            </ColorsWrapper>
          </ModalContent>
          <ModalFooter primaryLabel="Publish & Close" onPrimaryClick={onSave} primaryDisabled={saving} />
        </ModalWrapper>
      );
    }

    return (
      <ModalWrapper>
        <CustomizerHeader />
        <ModalContent>
          <ColorsWrapper>
            <Headline3>This workspace is currently using the pre-rebrand palette.</Headline3>
            <br />
            <Headline3>
              To customize colors, enable the feature flag new_venn_color_theme for this workspace on the Alpha Site.
            </Headline3>
          </ColorsWrapper>
        </ModalContent>
        <ModalFooter primaryLabel="Close" onPrimaryClick={close} />
      </ModalWrapper>
    );
  }

  return <CustomThemeCustomizerInner close={close} />;
};

function usePublishCallback(onSuccess: () => void) {
  const { saveCustomColors } = useContext(UserContext);
  const [isPublishing, setIsPublishing] = useState(false);
  return {
    isPublishing,
    onPublish: async (customColors: CustomColors | undefined) => {
      setIsPublishing(true);
      try {
        await saveCustomColors(customColors);
        Notifications.notify('Color Scheme Saved', NotificationType.SUCCESS);
        onSuccess?.();
      } catch (e) {
        logExceptionIntoSentry(e);
        Notifications.notify('Color Scheme Failed to Save', NotificationType.ERROR);
      } finally {
        setIsPublishing(false);
      }
    },
  };
}

const CustomThemeCustomizerInner = ({ close }: ColorPickerProps) => {
  const { Colors, setCustomColors, customColors } = useContext(ThemeContext);
  const [originalColors] = useState(() => cloneDeep(Colors));
  const hasChanges = useMemo(() => {
    return !isEqual(Colors, originalColors);
  }, [Colors, originalColors]);

  const [originalCustomColors] = useState(customColors);

  const schemes = useMemo(() => getSchemes(Colors), [Colors]);

  const { isPublishing: saving, onPublish } = usePublishCallback(close);
  const onSave = () => onPublish(customColors);

  const renderColorGroup = ({ colors, children }: ColorGroup) => {
    return (
      <>
        <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '10px' }}>
          {colors.map((color, idx) => {
            return (
              <Inline key={color.name} order={(idx * 2) % colors.length}>
                <ColorPicker color={color} />
              </Inline>
            );
          })}
        </div>
        {children.map(({ name, value }) => (
          <div key={name}>
            <Header>{name}</Header>
            {renderColorGroup(value)}
          </div>
        ))}
      </>
    );
  };

  return (
    <ModalWrapper>
      <ConditionalOverlay center condition={saving}>
        <CustomizerHeader>
          <ResetButton />
        </CustomizerHeader>
        <ModalContent>
          <ColorsWrapper>
            {schemes.map(({ name, description, colors }) => (
              <div key={name}>
                <Header>{name}</Header>
                <div style={{ fontSize: '12px' }}>{description}</div>
                {renderColorGroup(colors)}
              </div>
            ))}
          </ColorsWrapper>
        </ModalContent>
        <ModalFooter
          {...(hasChanges
            ? {
                primaryLabel: 'Publish & Close',
                onPrimaryClick: onSave,
                leftChildren: (
                  <Button
                    style={{ marginLeft: '10px' }}
                    dense
                    destructive
                    onClick={() => {
                      setCustomColors(() => originalCustomColors);
                      close();
                    }}
                  >
                    Discard Changes & Close
                  </Button>
                ),
              }
            : {
                primaryLabel: 'Close',
                onPrimaryClick: close,
              })}
        />
      </ConditionalOverlay>
    </ModalWrapper>
  );
};

const ColorPicker = ({ color }: { color: ColorItem }) => {
  const { Colors, setCustomColors, customColors } = useContext(ThemeContext);
  const [editing, setEditing] = useState(false);
  const [hexColor, setHexColor] = useSyncedState<string>(get(Colors, color.path));

  const debouncedSetCustomColors = useMemo(() => debounce(setCustomColors, 500), [setCustomColors]);

  const onChange = (hexColor: string) => {
    setHexColor(hexColor);
    const currentCustomColors = customColors ?? pick(Colors, CUSTOM_COLORS_KEYS);
    const newCustomColors = setWith({ ...currentCustomColors }, color.path, hexColor, (obj) => ({ ...obj }));
    debouncedSetCustomColors(deriveColors(newCustomColors));
  };

  const deriveColors = (newCustomColors: CustomColors): CustomColors => {
    const { DivergingColor, PeerGroupColor } = newCustomColors;
    return {
      ...newCustomColors,
      DivergingColor: createDivergingColors(DivergingColor.A5, DivergingColor.B5, DivergingColor.MID),
      PeerGroupColor: createPeerGroupColors(PeerGroupColor.GradientHigh),
    };
  };

  const portalClassName = `color-picker-portal-${color.path.join('-')}`;

  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} data-testId="qa-selected-color">
      <ExternalActivityListener
        listeningEnabled={editing}
        onExternalActivity={() => setEditing(false)}
        ignoreActivityFromClassName={portalClassName}
      >
        <ColorPatch aria-label={color.name} color={hexColor} onClick={() => setEditing(true)} highlighted={editing} />
      </ExternalActivityListener>
      {editing && (
        <RelativePortal expectedHeight={260} leftOffset={-40} topOffset={15} className={portalClassName}>
          <ColorSwatch color={hexColor} onChange={onChange} />
        </RelativePortal>
      )}
      <span style={{ paddingLeft: '8px' }}>
        {color.name}
        <span style={{ color: Colors.HintGrey }}>&nbsp;({hexColor})</span>
      </span>
    </div>
  );
};

const ColorSwatch = ({ color, onChange }: { color: string; onChange: (newColor: string) => void }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onChangeComplete = (newColor: ColorResult) => {
    const hexColor = newColor.hex.toUpperCase();
    onChange(hexColor);
  };

  useEffect(() => {
    const hexInput = wrapperRef.current?.querySelector('input');
    hexInput?.focus();
    hexInput?.select();
  }, []);

  return (
    <div ref={wrapperRef}>
      <ChromePicker color={color} onChangeComplete={onChangeComplete} />
    </div>
  );
};

const CustomizerHeader = ({ children }: { children?: React.ReactNode }) => (
  <ModalHeader>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
      <span style={{ whiteSpace: 'nowrap' }}>Color Customizer</span>
      {children}
    </div>
  </ModalHeader>
);

const ResetButton = () => {
  const isNewTheme = useHasFF('new_venn_color_theme');
  const { customColors, setCustomColors } = useContext(ThemeContext);
  const hasCustomTheme = !isNil(customColors);

  return (
    <Tooltip content="Venn defaults are selected" isHidden={hasCustomTheme}>
      <Button disabled={!hasCustomTheme} onClick={() => setCustomColors(undefined)}>
        {isNewTheme ? 'Reset To Venn Defaults' : 'Reset To Pre-rebrand Colors'}
      </Button>
    </Tooltip>
  );
};

const CUSTOM_COLORS_SETTINGS: {
  [key in CustomColorsKeys]: EditableColorSetting<keyof VennColors[key]>;
} = {
  StudioSubjectColor: {
    displayName: 'Subject Based',
    description: (
      <>
        <div>
          These colors are the base palette. In addition to being used for subject-based charts, all other chart types
          should also select colors from this list whenever possible.
        </div>
        <div style={{ paddingTop: '5px' }}>
          For subject-based charts, each unique color reflects a different subject. Affected Formats and Blocks: Line
          and Bar Charts (Asset Growth Simulation, Time Series, Returns Distribution, Notable Historical Periods,
          Sensitivity Analysis), Scatter Plot (Performance and Risk), Donut (Portfolio Composition).
        </div>
      </>
    ),
    editableKeys: [
      { key: 'A1', displayName: 'Color 1' },
      { key: 'A2', displayName: 'Color 2' },
      { key: 'A3', displayName: 'Color 3' },
      { key: 'A4', displayName: 'Color 4' },
      { key: 'A5', displayName: 'Color 5' },
      { key: 'A6', displayName: 'Color 6' },
      { key: 'A7', displayName: 'Color 7' },
      { key: 'A8', displayName: 'Color 8' },
      { key: 'A9', displayName: 'Color 9' },
    ],
  },
  DivergingColor: {
    displayName: 'Magnitude-Sensitive',
    description: `The colors reflect the magnitude of a value e.g. Correlation, Exposure. Colors in these charts are selected from 2 colors configured by the user.
    Affected visualizations and blocks: Heatmap: (Correlations, Factor Trend, Geography), Bar (Factor Bar Charts)
    `,
    editableKeys: [
      { key: 'B5', displayName: 'Positive values (cool)' },
      { key: 'A5', displayName: 'Negative values (warm)' },
      { key: 'MID', displayName: 'Midpoint (neutral)' },
    ],
  },
  HoldingsColor: {
    displayName: 'Holdings',
    description:
      'Each Asset Class and Sector is assigned a specific color that is consistent in every view and report in Studio and Report Lab.',
  },
  CashFlowColor: {
    displayName: 'Private Asset Bar Charts',
    description: `Historical, Projected, and Typical are three items always shown in cash flow charts. Each are assigned a color.
    Affected Visualization and Blocks: Private Asset - Cash Flow, Private Asset - NAV, Private Asset - Distributions, Private Asset - Contributions.`,
  },
  PeerGroupColor: {
    displayName: 'Peer Group Analytics',
    description:
      'The single Box Chart color determines the gradient and percentile colors in both versions of the Peer Group Analytics block.',
    editableKeys: [{ key: 'GradientHigh', displayName: 'Box Chart' }],
  },
};

const getSchemes = (theme: VennColors): ColorScheme[] => {
  return CUSTOM_COLORS_KEYS.map((key) => {
    const setting = CUSTOM_COLORS_SETTINGS[key];
    return {
      name: setting.displayName,
      description: setting.description,
      colors: getColorGroup(theme[key], [key], setting.editableKeys),
    };
  });
};

const getColorGroup = (
  subTheme: object,
  path: string[],
  editableKeys: { key: string; displayName?: string }[] | undefined,
): ColorGroup => {
  const colorsKeys: string[] = Object.keys(subTheme)
    .filter((key) => typeof subTheme[key] === 'string')
    .sort((a, b) => {
      const editableKeyIndexA = editableKeys?.findIndex((x) => x.key === a) ?? -1;
      const editableKeyIndexB = editableKeys?.findIndex((x) => x.key === b) ?? -1;
      return editableKeyIndexA - editableKeyIndexB;
    });

  const childrenKeys: string[] = Object.keys(subTheme).filter((key) => typeof subTheme[key] === 'object');
  return {
    colors: colorsKeys
      .map((key) => ({ key, name: editableKeys?.find((x) => x.key === key)?.displayName ?? key, path: [...path, key] }))
      .filter(({ key }) => editableKeys === undefined || editableKeys.find((x) => x.key === key)),
    children: childrenKeys.map((key) => ({
      name: key,
      value: getColorGroup(subTheme[key], [...path, key], editableKeys),
    })),
  };
};

const ModalWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 600px;
  background-color: ${GetColor.White};
  z-index: ${ZIndex.Modal};
  box-shadow: 0 5px 10px 0 ${GetColor.MidGrey2};
  padding-top: 10px;
`;

const Header = styled(Headline3)`
  margin-top: 10px;
`;

const Inline = styled.div<{ order: number }>`
  padding-bottom: 5px;
  width: 50%;
  order: ${({ order }) => order};
`;

const ColorsWrapper = styled.div`
  height: 500px;
  overflow-y: scroll;
`;

const ColorPatch = styled.button<{ color: string; highlighted: boolean }>`
  width: 40px;
  height: 28px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
  border: 2px solid ${({ highlighted }) => (highlighted ? GetColor.Primary.Main : 'transparent')};
  margin-left: 5px;
`;
