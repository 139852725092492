import { atomFamily, atom, selector, waitForAll } from 'recoil';
import type { Subject, BlockId } from '../../types';
import { unsavedChangesEffect } from '../../../effects/unsavedChangesEffect';
import {
  noDuplicatesValidatorEffect,
  truthyKeyValidatorEffect,
  maxLengthValidatorEffect,
} from '../../../effects/validateSetEffect';
import type { Nominal } from 'venn-utils';
import { MAX_INPUTS_PER_KIND, getRandomId } from 'venn-utils';
import type { CustomBenchmarkTypeEnum } from 'venn-api';
import { getNextInputName } from './getNextInputName';
import { resetOnStudioReset } from '../../../effects/signalEffects';

export type BenchmarkInputId = Nominal<string, 'BenchmarkInputId'>;

export const getNewBenchmarkInputId = getRandomId as () => BenchmarkInputId;

export const nextBenchmarkInputNameState = selector<string>({
  key: 'nextBenchmarkInputName',
  get: ({ get }) => {
    const allIds = get(benchmarkInputs);
    const allNames = get(waitForAll(allIds.map((id) => benchmarkInputName(id))));
    return getNextInputName('Untitled Benchmark ', allNames);
  },
  cachePolicy_UNSTABLE: {
    // Only the most recent is used; don't need to cache all historical benchmark name states created during this session.
    eviction: 'most-recent',
  },
});

/**
 * The user defined name for a benchmark input.
 */
export const benchmarkInputName = atomFamily<string, BenchmarkInputId>({
  key: 'benchmarkInputName',
  effects: (key) => [unsavedChangesEffect, truthyKeyValidatorEffect(key), resetOnStudioReset],
});

export const benchmarkInputType = atomFamily<CustomBenchmarkTypeEnum, BenchmarkInputId>({
  key: 'benchmarkInputType',
  default: 'COMMON',
  effects: (key) => [unsavedChangesEffect, truthyKeyValidatorEffect(key), resetOnStudioReset],
});

/**
 * The common benchmark subject if not using individual benchmarks.
 */
export const benchmarkInputSubject = atomFamily<Subject | undefined, BenchmarkInputId>({
  key: 'benchmarkInputSubject',
  default: undefined,
  effects: [unsavedChangesEffect, resetOnStudioReset],
});

/**
 * Is the benchmark input relative to the selected benchmark subject or individual benchmark
 */
export const benchmarkInputIsRelative = atomFamily<boolean, BenchmarkInputId>({
  key: 'benchmarkInputIsRelative',
  default: false,
  effects: [unsavedChangesEffect, resetOnStudioReset],
});

/**
 * All benchmark input groups (by ID) for a particular view, by insertion or user-defined order.
 */
export const benchmarkInputs = atom<BenchmarkInputId[]>({
  key: 'benchmarkInputs',
  default: [],
  effects: [
    unsavedChangesEffect,
    maxLengthValidatorEffect(MAX_INPUTS_PER_KIND),
    noDuplicatesValidatorEffect,
    resetOnStudioReset,
  ],
});

/** The single benchmark input applied to a block */
export const blockBenchmarkInput = atomFamily<BenchmarkInputId | undefined, BlockId>({
  key: 'blockBenchmarkInput',
  default: undefined,
  effects: [unsavedChangesEffect],
});
