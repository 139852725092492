import isNil from 'lodash/isNil';

export default function assert(condition: unknown, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(`Expected condition to be truthy, but got ${condition}. ${msg ?? ''}`);
  }
}

export function assertExpected<E>(actual: unknown, expected: E, msg?: string): asserts actual is E {
  if (actual === expected) {
    throw new Error(`Expected ${actual} to be ${expected}. ${msg ?? ''}`);
  }
}

export function assertNotNil<T>(value: T | null | undefined, msg?: string): T {
  if (isNil(value)) {
    throw new Error(`Expected ${value} to be not nil. ${msg ?? ''}`);
  }
  return value;
}
