import { atom, selector, selectorFamily } from 'recoil';
import type { BlockId } from './types';
import { blockSettings } from './configuration/blockSettings';
import { customizedViews, studioBlockCustomWidth } from './grid';
import type { VirtuosoHandle } from 'react-virtuoso';
import type { CustomizableBlockSetting } from 'venn-utils';
import { resetOnStudioReset } from '../effects/signalEffects';
import type { AnalysisView } from 'venn-api';

export const studioLeftPanelOpen = atom<boolean>({
  key: 'studioLeftPanelOpen',
  default: true,
});

export const studioLeftBlockConfigPanelExpandedAtom = atom<boolean>({
  key: 'studioLeftBlockConfigPanelExpanded',
  default: true,
});

export const selectedBlockState = atom<{ refId: BlockId; scrollToBlock: boolean } | undefined>({
  key: 'selectedBlockState',
  default: undefined,
  effects: [resetOnStudioReset],
});

export const selectedBlockIdState = selector<BlockId | undefined>({
  key: 'selectedBlockIdState',
  get: ({ get }) => get(selectedBlockState)?.refId,
});

export const selectedViewState = selector<AnalysisView | undefined>({
  key: 'selectedViewState',
  get: ({ get }) => {
    const selectedBlockId = get(selectedBlockIdState);
    return selectedBlockId ? get(customizedViews).find((view) => view.refId === selectedBlockId) : undefined;
  },
});

export const isSelectedBlockState = selectorFamily<boolean, BlockId>({
  key: 'isSelectedBlock',
  get:
    (blockId) =>
    ({ get }) =>
      get(selectedBlockState)?.refId === blockId,
});

export const selectedBlockSettings = selector<CustomizableBlockSetting | undefined>({
  key: 'selectedBlockSettings',
  get: ({ get }) => {
    const selected = get(selectedBlockIdState);
    return selected ? get(blockSettings(selected)) : undefined;
  },
});

export const selectedBlockRelativeWidth = selector<number | undefined>({
  key: 'selectedBlockRelativeWidth',
  get: ({ get }) => {
    const selected = get(selectedBlockIdState);
    return selected ? get(studioBlockCustomWidth(selected)) : undefined;
  },
  set: ({ get, set }, value) => {
    const selected = get(selectedBlockIdState);
    selected && value && set(studioBlockCustomWidth(selected), value);
  },
});

export const studioImageUploading = atom<boolean>({
  key: 'studioImageUploading',
  default: false,
});

export const virtualScrollRef = atom<{ current: VirtuosoHandle | null } | undefined>({
  key: 'virtualScrollRef',
  default: undefined,
});

/** Index of the selected report page. */
export const selectedReportPage = atom<number>({
  key: 'selectedReportPage',
  default: 0,
});
