import type { AnalysisView } from 'venn-api';
import type { GetRecoilValue } from 'recoil';
import { selector } from 'recoil';
import type { ReportingPageGroup } from './configuration';
import {
  studioContentHeight,
  studioHidePageHeader,
  blockSettings,
  PAGE_HEADER_HEIGHT,
  PRINT_ROW_HEIGHT,
  PRINT_GRID_HEADER_HEIGHT,
} from './configuration';
import { customizedViews, studioBlockHeight, studioBlockCustomWidth } from './grid';
import type { CustomBlockTypeEnum } from 'venn-utils';
import { theme } from '../global';

export const DEFAULT_BLOCK_HEIGHT = 400;
const DEFAULT_PORTFOLIO_HEIGHT = 500;
const BLOCK_HEADER_HEIGHT = 80;
const SPACE_GAP = 30;

const getDefaultHeight = (metricsLength: number, blockType?: CustomBlockTypeEnum) => {
  if (blockType === 'TIMESERIES' || blockType === 'MARKDOWN') {
    return 310;
  }

  if (blockType === 'PORTFOLIO_CONTRIBUTION') {
    return DEFAULT_PORTFOLIO_HEIGHT;
  }

  if (blockType === 'PERFORMANCE_SUMMARY' || blockType === 'FACTOR_CONTRIBUTION') {
    return BLOCK_HEADER_HEIGHT + PRINT_GRID_HEADER_HEIGHT * 2 + metricsLength * PRINT_ROW_HEIGHT + SPACE_GAP;
  }

  return DEFAULT_BLOCK_HEIGHT;
};

const lastRowWidth = (get: GetRecoilValue, views: AnalysisView[]) =>
  views.reduce((total: number, curView: AnalysisView) => {
    const cur = curView.refId ? get(studioBlockCustomWidth(curView.refId)) : 1;
    // if currView width is 1 that means we are full width so the next row must have a width of 0
    if (cur === 1) {
      return 0;
    }
    return (total + cur) % 1;
  }, 0);

/**
 * Returns true IFF the browser is in print mode or forced to print mode via URL param or debug panel.
 * This atom should be treated as if read-only: it is written to only from the print mode context.
 */
export const isPrintingState = selector<boolean>({
  key: 'isPrintingState',
  get: ({ get }) => get(theme).inPrintMode,
});

/**
 * Selector that computes the layout of blocks across multiple pages for Studio printing.
 *
 * Warning: this computation uses the block height of the blocks, which means it is dependent on the block rendering, and because renders
 * occur in a non-deterministic order (due to fetching) the number of recalculations and the duration of computation is non-deterministic.
 */
export const studioPrintPages = selector<ReportingPageGroup[]>({
  key: 'studioPrintPages',
  get: ({ get }) => {
    const isPrinting = get(isPrintingState);
    if (!isPrinting) {
      return [];
    }

    const hidePageHeader = get(studioHidePageHeader);
    const views = get(customizedViews);

    if (!views) {
      return [];
    }
    return (
      views
        .reduce(
          (pages: ReportingPageGroup[], curView: AnalysisView, curIndex: number) => {
            const blockSetting = curView.refId ? get(blockSettings(curView.refId)) : undefined;
            const lastPage = pages[pages.length - 1];

            if (blockSetting?.customBlockType === 'PAGE_BREAK') {
              // Page breaks create a new page, which we will build off of as we continue the 'reduce'
              pages.push({
                views: [],
                startIndex: curIndex + 1,
                totalHeight: 0,
                placeholderViews: [],
              });

              return pages;
            }

            const measuredBlockHeight = curView.refId ? get(studioBlockHeight(curView.refId)) : undefined;
            const blockHeight =
              measuredBlockHeight ||
              getDefaultHeight(
                (curView.customizedBlock?.selectedMetrics?.length || 1) *
                  (curView.customizedBlock?.selectedFactors?.length || 1),
                blockSetting?.customBlockType,
              );
            const blockRelativeWidth = curView.refId ? get(studioBlockCustomWidth(curView.refId)) : 1.0;
            const lastWidth = lastRowWidth(get, lastPage.placeholderViews);
            const shouldMergeRows = lastWidth !== 0 && blockRelativeWidth !== 1 && lastWidth + blockRelativeWidth <= 1;
            /**
             * The change in page content height due to the addition of the new block.
             * This can be less than the block height, or even zero, due to row merging of half-width blocks.
             */
            const heightDelta = shouldMergeRows
              ? Math.max(blockHeight - (lastPage.placeholderHeight ?? 0), 0)
              : blockHeight;

            const pageHeight = get(studioContentHeight);

            if (lastPage.totalHeight + heightDelta > pageHeight && shouldMergeRows) {
              // Remove the merged blocks from the previous page, and create a new page with all merged blocks
              const movedViews = lastPage.views.splice(-(lastPage.placeholderViews?.length ?? 0));
              pages.push({
                views: [...movedViews, curView],
                startIndex: curIndex - movedViews.length,
                totalHeight: blockHeight,
                placeholderViews: [...movedViews, curView],
                placeholderHeight: blockHeight,
              });
            } else if (lastPage.totalHeight + blockHeight > pageHeight && !shouldMergeRows) {
              // Make a new page for the new block

              pages.push({
                views: [curView],
                startIndex: curIndex,
                totalHeight: blockHeight,
                placeholderViews: [curView],
                placeholderHeight: blockHeight,
              });
            } else {
              // Add the new block to the last page
              lastPage.views.push(curView);
              lastPage.totalHeight += heightDelta;
              lastPage.placeholderViews.push(curView);
              lastPage.placeholderHeight = (lastPage.placeholderHeight ?? 0) + heightDelta;
            }

            return pages;
          },
          [
            // Start with an empty page, which blocks will get added to as needed.
            {
              views: [],
              startIndex: 0,
              totalHeight: hidePageHeader ? 0 : PAGE_HEADER_HEIGHT,
              placeholderViews: [],
            },
          ],
        )
        // Filter out empty page except for first page
        .filter((page: ReportingPageGroup) => page.views.length > 0)
    );
  },
});
