import { DropDirection, Icon, GetColor, Tooltip } from 'venn-ui-kit';
import { compact } from 'lodash';
import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';

import type { EditMode } from 'venn-components';
import { StudioEditModeContext, DropdownMenu, DropdownMenuItem } from 'venn-components';
import MenuCaret from './MenuCaret';
import { TOP_TOOLBAR_HEIGHT, TopBarColor } from './shared';
import type { FontAwesomePrefixes } from 'venn-utils';
import { useRecoilValue } from 'recoil';
import { isReportState } from 'venn-state';

export interface TopBarDropdownMenuOption {
  /** [Font Awesome](https://fontawesome.com/) prefixes */
  iconPrefix?: FontAwesomePrefixes;
  icon?: string;
  label: string | JSX.Element;
  key: string;
  onClick: () => void;
  disabled?: boolean;
  topBorder?: boolean;
  tooltip?: JSX.Element;
  className?: string;
}

export interface TopBarDropdownMenuProps {
  disabled?: boolean;
  children?: JSX.Element;
  options: TopBarDropdownMenuOption[];
  dropdownStyle?: React.CSSProperties;
}

const TopBarDropdownMenu = ({ disabled, children, options, dropdownStyle = {} }: TopBarDropdownMenuProps) => {
  const editMode = useContext(StudioEditModeContext);
  const isReport = useRecoilValue(isReportState);

  const theme = useContext(ThemeContext);
  const caret = <MenuCaretLarge disabled={disabled} editMode={editMode} />;

  if (options.length === 0) {
    return caret;
  }

  return (
    <DropdownMenu
      dropDirection={DropDirection.Down}
      trigger={children ?? caret}
      dropdownStyle={{
        border: `1 px solid ${TopBarColor[editMode].Border({ theme })}`,
        borderRadius: '6px',
        backgroundColor: `${TopBarColor[editMode][isReport ? 'ReportBackground' : 'Background']({ theme })}`,
        padding: '0px',
        marginTop: '2px',
        position: 'relative',
        ...dropdownStyle,
      }}
      portal
    >
      {!disabled &&
        options.map((value, index) => (
          <Tooltip block content={value.tooltip} key={value.key}>
            <DropdownMenuItemStyled
              first={index === 0}
              last={index === options.length - 1}
              onClick={value.disabled ? undefined : value.onClick}
              editMode={editMode}
              disabled={value.disabled}
              isReport={isReport}
              topBorder={value.topBorder}
              className={compact([value.className, 'top-bar-dropdown-item', `qa-dropdown-item_${value.key}`]).join(' ')}
            >
              <Icon prefix={value.iconPrefix} type={value.icon} />
              <Label>{value.label}</Label>
            </DropdownMenuItemStyled>
          </Tooltip>
        ))}
    </DropdownMenu>
  );
};

export default TopBarDropdownMenu;

const Label = styled.span`
  padding-left: 8px;
`;

const DropdownMenuItemStyled = styled(DropdownMenuItem)<{
  first?: boolean;
  last?: boolean;
  editMode: EditMode;
  isReport?: boolean;
  disabled?: boolean;
  topBorder?: boolean;
}>`
  padding-left: 16px;
  min-height: 34px;

  border: 1 px solid ${({ editMode }) => TopBarColor[editMode].Border};
  border-radius: 0px;
  ${({ first }) =>
    first &&
    css`
      border-radius: 6px 6px 0px 0px;
    `}
  ${({ last }) =>
    last &&
    css`
      border-radius: 0px 0px 6px 6px;
    `}
  ${({ first, last }) =>
    first &&
    last &&
    css`
      border-radius: 6px;
    `}
    ${({ topBorder }) =>
    topBorder &&
    css`
      border-top: 1px solid ${GetColor.GreyScale.Grey50};
    `}
  font-size: 14px;
  font-weight: 400;

  color: ${({ disabled }) => (disabled ? GetColor.DarkGrey : GetColor.White)};
  background-color: ${({ editMode, isReport }) => TopBarColor[editMode][isReport ? 'ReportBackground' : 'Background']};
  :hover {
    background-color: ${({ editMode, isReport }) =>
      TopBarColor[editMode][isReport ? 'ReportActiveBackground' : 'ActiveBackground']};
  }
`;

const MenuCaretLarge = styled(MenuCaret)<{ disabled?: boolean; editMode: EditMode }>`
  height: ${TOP_TOOLBAR_HEIGHT};
  width: 64px;
  margin-left: -25px;
  ${({ disabled, editMode }) =>
    disabled &&
    css`
      color: ${TopBarColor[editMode].DarkGrey};
    `}
`;
