import type { RecoilValue } from 'recoil';
import { atom, RecoilEnv, type RecoilState } from 'recoil';
import type { Nominal } from 'venn-utils';

type StateInner<T> = [RecoilState<T>, T];

export type RecoilWrapperState = <R>(callback: <T>(state: StateInner<T>) => R) => R;

/** Wraps a key-value recoil state in a type-safe manner. */
export const makeState =
  <T,>(state: StateInner<T>): RecoilWrapperState =>
  (callback) =>
    callback(state);

/** Internal nominal typing used by venn-test only. */
export type _internal_RecoilWrapperState = Nominal<RecoilWrapperState, 'computeRecoilValueStateInternal'>;
export type AtomValuePair<T> = { atom: RecoilValue<T>; value: T };
/** Creates a writable override with an initial value for any Recoil state.  */
export const makeAtomValuePair = <T,>(pair: AtomValuePair<T>) => {
  // Temporarily disable recoil duplicate atom key checking because we're intentionally overriding existing atoms here.
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
  const state = makeState([atom({ key: pair.atom.key }), pair.value]) as _internal_RecoilWrapperState;
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = true;
  return state;
};
