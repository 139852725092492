import { atom, selector, selectorFamily, waitForAll } from 'recoil';
import {
  DEFAULT_LANDSCAPE_HEIGHT,
  DEFAULT_MARGIN,
  DEFAULT_PORTRAIT_HEIGHT,
  PAGE_HEADER_HEIGHT,
} from './printConstants';
import type { AnalysisView, Portfolio, PrintOrientationTypeEnum } from 'venn-api';
import { unsavedChangesEffect } from '../../effects/unsavedChangesEffect';
import { resetOnStudioReset } from '../../effects/signalEffects';
import { blockSubjectInputGroups, subjectInputGroupSubjects } from './input-management/subjectInput';
import { PageType, hasFeesAndExcludedInvestments, isAnalysisBlock, assertNotNil, findInPortfolio } from 'venn-utils';
import type { Page } from 'venn-components';
import { compact, uniq } from 'lodash';
import { isReportState } from './view';
import type { BlockId, StudioRequestSubject } from '../types';
import { blockCustomMetricSettingsState, blockSettings } from './blockSettings';
import { requestSubjects } from './subjects';
import { allSubjects } from './allViewSubjects';

export interface ReportingPageGroup {
  startIndex: number;
  views: AnalysisView[];
  totalHeight: number;
  /** If current width is not full width */
  placeholderHeight?: number;
  /** Views that maybe be merged with others in one row */
  placeholderViews: AnalysisView[];
}

export const studioPrintOrientationType = atom<PrintOrientationTypeEnum>({
  key: 'studioPrintOrientationType',
  default: 'PORTRAIT',
  effects: [unsavedChangesEffect, resetOnStudioReset],
});

export const studioIsPortrait = selector<boolean>({
  key: 'studioIsPortrait',
  get: ({ get }) => get(studioPrintOrientationType) === 'PORTRAIT',
});

export const studioFooterHeight = atom<number>({
  key: 'studioFooterHeight',
  default: DEFAULT_MARGIN,
  effects: [resetOnStudioReset],
});

export const studioHideHeaderViewName = atom<boolean>({
  key: 'studioHideHeaderViewName',
  default: false,
  effects: [unsavedChangesEffect, resetOnStudioReset],
});
export const studioHideHeaderCompanyName = atom<boolean>({
  key: 'studioHideHeaderCompanyName',
  default: false,
  effects: [unsavedChangesEffect, resetOnStudioReset],
});
export const studioHideHeaderLogo = atom<boolean>({
  key: 'studioHideHeaderLogo',
  default: false,
  effects: [unsavedChangesEffect, resetOnStudioReset],
});

export const studioHideFooterPagination = atom<boolean>({
  key: 'studioHideFooterPagination',
  default: false,
  effects: [unsavedChangesEffect, resetOnStudioReset],
});
export const studioHideFooterDisclaimer = atom<boolean>({
  key: 'studioHideFooterDisclaimer',
  default: false,
  effects: [unsavedChangesEffect, resetOnStudioReset],
});

export const studioCustomFooterText = atom<string | undefined>({
  key: 'studioCustomFooterText',
  default: undefined,
  effects: [unsavedChangesEffect, resetOnStudioReset],
});

export const studioHidePageHeader = selector<boolean>({
  key: 'studioHidePageHeader',
  get: ({ get }) => get(studioHideHeaderViewName) && get(studioHideHeaderCompanyName) && get(studioHideHeaderLogo),
});

export const studioPageHeight = selector<number>({
  key: 'studioPageHeight',
  get: ({ get }) => (get(studioIsPortrait) ? DEFAULT_PORTRAIT_HEIGHT : DEFAULT_LANDSCAPE_HEIGHT),
});

export const studioContentHeight = selector<number>({
  key: 'studioContentHeight',
  get: ({ get }) => {
    const reservedSpace = (get(studioHidePageHeader) ? DEFAULT_MARGIN : PAGE_HEADER_HEIGHT) + get(studioFooterHeight);
    return get(studioPageHeight) - reservedSpace;
  },
});

export const viewPages = atom<Page[]>({
  key: 'viewPages',
  default: [
    {
      layout: [],
      type: PageType.GRID,
    },
  ],
  effects: [unsavedChangesEffect, resetOnStudioReset],
});

export const blockSupportsFees = selectorFamily<boolean, BlockId>({
  key: 'blockSupportsFees',
  get:
    (id) =>
    ({ get }) => {
      const blockType = get(blockSettings(id)).customBlockType;
      return isAnalysisBlock(blockType) && blockType !== 'PORTFOLIO_BREAKDOWN';
    },
});

export const blocksOnPage = selectorFamily<BlockId[], number>({
  key: 'blocksOnPage',
  get:
    (pageNumber) =>
    ({ get }) => {
      const allPages = get(viewPages);
      const page = allPages[pageNumber];
      if (page.type !== PageType.GRID) {
        return [];
      }
      return page.layout.map((layout) => layout.i);
    },
});

export const hasCustomMetricsOnPage = selectorFamily<boolean, number>({
  key: 'hasCustomMetricsOnPage',
  get:
    (pageNumber) =>
    ({ get }) => {
      const blocks = get(blocksOnPage(pageNumber));
      const blocksCustomMetrics = get(waitForAll(blocks.map((id) => blockCustomMetricSettingsState(id))));
      return blocksCustomMetrics.some((customMetrics) => customMetrics.length !== 0);
    },
});

export const hasExcludedFeesOnPage = selectorFamily<boolean, number>({
  key: 'hasExcludedFeesOnPage',
  get:
    (pageNumber) =>
    ({ get }) => {
      if (!get(isReportState)) {
        return false;
      }
      const blocks = get(blocksOnPage(pageNumber)).filter((id) => get(blockSupportsFees(id)));
      const subjectGroups = uniq(get(waitForAll(blocks.map((id) => blockSubjectInputGroups(id)))).flat());
      const subjects = get(waitForAll(subjectGroups.map((id) => subjectInputGroupSubjects(id)))).flat();
      return subjects.some(hasFeesAndExcludedInvestments);
    },
});

export const allSubjectsWithExcludedInvestmentFees = selector<
  { subject: StudioRequestSubject; excluded: Portfolio[] }[]
>({
  key: 'allSubjectsWithExcludedInvestmentFees',
  get: ({ get }) => {
    const allSubjectsData = get(allSubjects);
    const subjectsWithExcludedFees = get(requestSubjects(allSubjectsData)).filter(hasFeesAndExcludedInvestments);
    return subjectsWithExcludedFees.map((subject) => ({
      subject,
      excluded: compact(
        Object.keys(assertNotNil(subject.feesMapping))
          .filter((key) => subject.feesMapping?.[key] === 0 && key !== subject.id)
          .map((node) => findInPortfolio(subject.portfolio!, Number(node))),
      ),
    }));
  },
});
