import styled, { css } from 'styled-components';
import { ZIndex } from '../zIndexValues';
import { GetColor } from '../style/color';

export const TOP_NAV_HEIGHT = 50;
export const SIDE_NAV_LINK_HEIGHT = 60;
export const SIDE_NAV_WIDTH = 70;

export const LayoutWithMain = styled.div<{ showNav?: boolean }>`
  width: 100vw;
  position: fixed;
  background-color: ${GetColor.White};

  ${({ showNav }) =>
    showNav &&
    css`
      display: grid;
      grid-template: ${TOP_NAV_HEIGHT}px 1fr / ${SIDE_NAV_WIDTH}px 1fr;
      grid-template-areas: 'topnav .' 'sidenav main';
    `}

  @media screen {
    height: 100%;
  }
  @media print {
    display: block;
    max-width: 100%;
    position: relative;
    grid-template-columns: 0 1fr;
    .fa-question-circle {
      display: none;
    }
  }
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const SideNav = styled.div`
  grid-column: sidenav;
`;

export const TopNav = styled.div`
  grid-row: topnav;
`;

interface MainProps {
  hasFooter: boolean;
  fullPage: boolean;
  headerHeight?: number;
}

/**
 * A Main is a top-level, responsive fluid layout container that takes up
 * most of the available screen width.
 *
 * A Main can appear either on its own; next to an Aside; or next to a Panel.
 *
 * Typical widths for a Main:
 *
 *  | Screen width | Main only    | Main next to Aside | Main next to Panel |
 *  |--------------|--------------|--------------------|--------------------|
 *  | 1920 px      | 1520 px      | 1218 px            | 1400 px            |
 *  | 1600 px      | 1280 px      | 1023 px            | 1080 px            |
 *  | 1280 px      | 1170 px      | 934 px             | 850 px             |
 *  | 1024 px      | 914 px       | 726 px             | N/A*               |
 *  | 960 px       | 859 px       | 674 px             | N/A*               |
 *  | 640 px       | 530 px       | 414 px             | N/A*               |
 *  | 320 px       | 210 px       | 154 px             | N/A*               |
 *
 *  *Panels are not shown for screens of this size; use "Main only" column.
 *
 * Content within an Aside does not require padding around it, as there will
 * be a 20-pixel gap automatically applied around it.
 */
export const Main = styled.main<MainProps>`
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -ms-grid-column: 2;
  -ms-grid-row: ${({ headerHeight }) => (headerHeight ? 2 : 1)};
  grid-column: main;
  display: flex;
  @media print {
    height: auto;
    overflow-y: visible;
    overflow-x: visible;
    display: block;
  }

  ${({ hasFooter, fullPage, headerHeight = 0 }) =>
    hasFooter &&
    css`
      display: -ms-grid;
      display: grid;
      height: calc(100vh - ${headerHeight}px);
      -ms-grid-rows: ${fullPage ? 'auto auto' : 'auto 464px'};
      -ms-grid-columns: 100%;
      grid-template:
        'content' ${fullPage ? 'auto' : '1fr'}
        'footer' ${fullPage ? 'auto' : '464px / 1fr'};
      ${fullPage ? '& > footer > footer { margin-top: 0; }' : ''}

      @media print {
        grid-template: 'content' auto;
        height: auto;
      }
    `}
`;

export const Content = styled.div<{ noMinWidth?: boolean; minWidth?: number }>`
  grid-row: content;
  -ms-grid-row: 1;
  /* Higher hierachy to not be blocked by footer */
  z-index: ${ZIndex.Front};
  ${(props) =>
    !props.noMinWidth &&
    css`
      min-width: ${props.minWidth ?? 1280}px;
    `}
  @media print {
    min-width: initial;
  }
`;

export const Footer = styled.footer`
  grid-row: footer;
  -ms-grid-row: 2;
`;
