import type { ReactNode } from 'react';
import React, { PureComponent } from 'react';
import { debounce } from 'lodash';

const MOUSE_LEAVE_TIMEOUT_DURATION = 200;
const DEBOUNCE_DURATION = 200;

export interface ExternalActivityListenerProps {
  className?: string;
  listeningEnabled?: boolean;
  onExternalActivity: (event: Event) => void;
  onMouseLeave?: (event: Event) => void;
  debounce?: boolean;
  svg?: boolean;
  ignoreActivityFromClassName?: string | string[];
  style?: React.CSSProperties;
  children?: ReactNode;
  testId?: string;
}

export default class ExternalActivityListener extends PureComponent<ExternalActivityListenerProps> {
  static defaultProps = {
    listeningEnabled: true,
    onMouseLeave: null,
    debounce: true,
    testId: 'external-activity-listener',
  };

  private container: HTMLElement | SVGGElement | null;

  constructor(props: ExternalActivityListenerProps) {
    super(props);

    if (props.debounce) {
      this.handleWindowEvent = debounce(this.handleWindowEvent, DEBOUNCE_DURATION);
      this.handleMouseLeaveEvent = debounce(this.handleMouseLeaveEvent, DEBOUNCE_DURATION);
    }
  }

  componentDidMount() {
    if (this.props.listeningEnabled) {
      this.addActivityListeners(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: ExternalActivityListenerProps) {
    const { listeningEnabled } = this.props;

    if (!listeningEnabled && nextProps.listeningEnabled) {
      this.addActivityListeners(nextProps);
    } else if (listeningEnabled && !nextProps.listeningEnabled) {
      this.removeActivityListeners(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.props.listeningEnabled) {
      this.removeActivityListeners(this.props);
    }
  }

  addActivityListeners = (props: ExternalActivityListenerProps) => {
    window.addEventListener('mousedown', this.handleWindowEvent, false);
    // Focus events don't bubble up so we need to use capture phase to handle them (by passing true as the third argument)
    window.addEventListener('focus', this.handleWindowEvent, true);

    if (props.onMouseLeave && this.container) {
      this.container.addEventListener('mouseleave', this.handleMouseLeaveEvent, false);
    }
  };

  removeActivityListeners = (props: ExternalActivityListenerProps) => {
    window.removeEventListener('mousedown', this.handleWindowEvent, false);
    window.removeEventListener('focus', this.handleWindowEvent, true);

    if (props.onMouseLeave && this.container) {
      this.container.removeEventListener('mouseleave', this.handleMouseLeaveEvent, false);
    }
  };

  checkIfNodeContainsClass = (target: Node, className: string) => {
    // Check if the node has className that indicates we should ignore clicks coming from it
    if (target instanceof Element && target.className.includes?.(className)) {
      return true;
    }

    // Check if the node is a descendant of a node has className that indicates we should ignore clicks coming from it
    const ignoreNodes = Array.from(document.getElementsByClassName(className));
    return ignoreNodes.some((ignoreNode) => ignoreNode.contains(target));
  };

  shouldIgnoreNodeActivity = (target: Node) => {
    if (!(target instanceof Element)) {
      return false;
    }

    const { ignoreActivityFromClassName = [] } = this.props;
    const ignoredClassNames = Array.isArray(ignoreActivityFromClassName)
      ? ignoreActivityFromClassName
      : [ignoreActivityFromClassName];

    return ignoredClassNames.filter(Boolean).some((className) => this.checkIfNodeContainsClass(target, className));
  };

  /**
   * Checks if the event is a focus event on <main> tag
   * We should ignore such events because main tag covers the entire page
   *
   * I think it was not focusable previously but seems like recently Chrome made it focusable
   * Unfortunately it's up to each browser to decide what elements they consider focusable as there is no standard
   */
  isMainTagFocused = (event: Event) => {
    const isFocusEvent = event.type === 'focus';
    const isMainTag = event.target instanceof HTMLElement && event.target.tagName === 'MAIN';
    return isFocusEvent && isMainTag;
  };

  /**
   * This is a fix to a specific bug: if the event comes from a (now) unmounted node
   * Then it would have been considered an external event, instead of an internal one.
   */
  isUnmounted = (target: Node): boolean => {
    if (target.parentNode === document) {
      return false;
    }
    if (!target.parentNode) {
      return true;
    }
    return this.isUnmounted(target.parentNode);
  };

  handleWindowEvent = (event: Event) => {
    const { onExternalActivity, listeningEnabled } = this.props;
    const target = event?.target;
    const isUnmounted = target instanceof Node ? this.isUnmounted(target) : false;

    if (
      listeningEnabled &&
      !isUnmounted &&
      target !== window &&
      target instanceof Node &&
      this.container &&
      this.container.contains &&
      !this.container.contains(target) &&
      !this.shouldIgnoreNodeActivity(target) &&
      !this.isMainTagFocused(event)
    ) {
      onExternalActivity(event);
    }
  };

  handleMouseLeaveEvent = (event: Event) => {
    const { container } = this;
    const { onMouseLeave } = this.props;
    if (!onMouseLeave || !container || event.target !== container) {
      return;
    }

    function enterListener(enterEvent: Event) {
      if (container && enterEvent.target === container) {
        window.clearTimeout(timeout);
        container.removeEventListener('mouseenter', enterListener);
      }
    }

    function timerExpired() {
      onMouseLeave && onMouseLeave(event);
      container && container.removeEventListener('mouseenter', enterListener);
    }

    const timeout = window.setTimeout(timerExpired, MOUSE_LEAVE_TIMEOUT_DURATION);
    container.addEventListener('mouseenter', enterListener);
  };

  render() {
    const { svg } = this.props;
    if (svg) {
      return (
        <g
          className={`${this.props.className} qa-chart-container`}
          ref={(node) => {
            this.container = node;
          }}
          data-testid={this.props.testId}
        >
          {this.props.children}
        </g>
      );
    }
    return (
      <div
        className={this.props.className}
        ref={(node) => {
          this.container = node;
        }}
        style={this.props.style}
        data-testid={this.props.testId}
      >
        {this.props.children}
      </div>
    );
  }
}
