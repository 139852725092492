import { atom, selector } from 'recoil';
import type { FeatureFlagRecord, PartialFeatureFlagRecord } from '../features';
import { cloneDeep, merge } from 'lodash';

/** Map of any debug overrides that are overriding the server-sent feature state. */
export const featureDebugOverridesMap = atom<PartialFeatureFlagRecord>({
  key: 'featureDebugOverridesMap',
  default: {},
});

/**
 * Recoil-stored feature flag state.
 *
 * Used an atom instead of an atomFamily to enable iteration and existence checks.
 * This could also be done with an key array atom combined with an atomFamily for improved performance at the expense of more
 * brittle code, but we don't need performance here.
 */
export const rawFeatureMapState = atom<FeatureFlagRecord | undefined>({
  key: 'rawFeatureMapState',
  default: undefined,
});

/** The final feature map state containing server-driven features merged with debug overrides. */
export const featureMapState = selector<FeatureFlagRecord | undefined>({
  key: 'featureMapState',
  get: ({ get }) => {
    const rawFeatureMap = get(rawFeatureMapState);
    const overridesMap = get(featureDebugOverridesMap);

    // Our legacy FF behavior is that if we have not yet initialized the raw map, then we always return undefined.
    // This is because we don't want to show any features until we have loaded the feature map.
    // However, if the overridesMap has overrides then it is also ok to return that instead.
    if (!rawFeatureMap && Object.keys(overridesMap).length === 0) return undefined;
    return merge(cloneDeep(rawFeatureMap), overridesMap);
  },
});
