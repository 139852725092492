import React from 'react';
import styled, { css } from 'styled-components';
import { isMatch, compact } from 'lodash';
import { Link } from 'react-router-dom';
import type { ItemType, VennColors } from 'venn-ui-kit';
import {
  Tooltip,
  TooltipPosition,
  GetColor,
  ColorUtils,
  ItemIcon,
  getItemLabel,
  ItemTypePillWithIcon,
  VennProvidedIcon,
} from 'venn-ui-kit';
import type { AnalysisViewSearchResultWithUIState } from 'venn-utils';
import { Dates, FS, getAnalysisViewUrl, isStudioTemplate } from 'venn-utils';
import type { BasicTableColumn, SORTDIR } from 'venn-components';
import type { ActionEnum, AnalysisViewSortTypeEnum } from 'venn-api';
import type { SearchState, ConfirmationType } from './types';
import Warning from './Warning';
import { SavedViewActions } from './SavedViewActions';
import type * as H from 'history';
import { getLaunchText } from '../utils/getLaunchText';

const getOnChangeForField = (
  updateData: React.Dispatch<React.SetStateAction<SearchState>>,
  field: keyof AnalysisViewSearchResultWithUIState,
  item?: AnalysisViewSearchResultWithUIState,
  value?: boolean,
) => {
  updateData((prev) => ({
    ...prev,
    results: prev.results.map((dataItem) => {
      if (item === undefined || isMatch(item, dataItem)) {
        return {
          ...dataItem,
          [field]: value ?? !dataItem[field],
        };
      }
      return dataItem;
    }),
  }));
};

export const toggleItemSelected = (
  item: AnalysisViewSearchResultWithUIState,
  updateData: React.Dispatch<React.SetStateAction<SearchState>>,
) => {
  return getOnChangeForField(updateData, 'selected', item);
};

export const getColumns = (
  updateData: React.Dispatch<React.SetStateAction<SearchState>>,
  handleAction: (item: AnalysisViewSearchResultWithUIState, confirmationType: ConfirmationType) => void,
  selectView: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>,
    item: AnalysisViewSearchResultWithUIState,
  ) => void,
  history: H.History<unknown>,
  userId?: number,
  sortKey?: AnalysisViewSortTypeEnum,
  sortDir?: SORTDIR.ASC | SORTDIR.DESC,
  hasPermissionForResource?: (permission: ActionEnum, resource: { ownerContextId?: string }) => boolean,
): BasicTableColumn<AnalysisViewSearchResultWithUIState>[] =>
  compact([
    {
      label: 'Item Type',
      sortable: false,
      headerStyle: { width: 75 },
      cellRenderer: (item: AnalysisViewSearchResultWithUIState) => (
        <ItemTypePillWithIcon itemType={item.analysisViewType as ItemType} />
      ),
    },
    FS.has('context_switching')
      ? {
          label: 'Ownership',
          sortable: false,
          cellRenderer: (item: AnalysisViewSearchResultWithUIState) => <>{item.ownershipName}</>,
        }
      : null,
    {
      label: 'Name',
      accessor: 'name',
      sortable: true,
      sorted: sortKey === 'NAME' ? sortDir : undefined,
      sortingIsExternal: true,
      className: 'reporting-name-cell',
      cellRenderer: (item: AnalysisViewSearchResultWithUIState) => (
        <>
          <StyledTooltip
            position={TooltipPosition.Top}
            maxWidth={200}
            content={
              <TooltipContentWrapper>
                <Title>{item.name}</Title>
                <SubHeader>
                  <>
                    <ItemIcon item={item.analysisViewType as ItemType} />
                    <ItemTypeLabel>{getItemLabel(item.analysisViewType as ItemType)}</ItemTypeLabel>
                  </>
                </SubHeader>
              </TooltipContentWrapper>
            }
          >
            <NameCell>{item.name}</NameCell>
          </StyledTooltip>
          <LinkGroup className="qa-edit-link">
            {checkCanEditTemplate(item, hasPermissionForResource) ? (
              <Link onClick={(e) => selectView(e, item)} to={getAnalysisViewUrl(item, true)}>
                Edit Template
              </Link>
            ) : null}
            <Link onClick={(e) => selectView(e, item)} to={getAnalysisViewUrl(item)}>
              {getLaunchText(item.analysisViewType)}
            </Link>
          </LinkGroup>
        </>
      ),
    },
    {
      label: 'Template/Block',
      accessor: 'templateName',
      sortable: false,
      cellRenderer: (item: AnalysisViewSearchResultWithUIState) => item.templateName || item.systemTemplate || '--',
    },
    {
      label: 'Last Updated By',
      sortable: false,
      cellRenderer: (item: AnalysisViewSearchResultWithUIState) => {
        if (item.vennTemplate) {
          return <VennProvidedIcon height={24} />;
        }
        return item.owner.id === userId ? 'Me' : item.owner.displayName;
      },
    },
    {
      label: 'Last Updated',
      sortable: false,
      cellStyle: { paddingRight: 15 },
      cellRenderer: (item: AnalysisViewSearchResultWithUIState) => Dates.toDayMonthYear(item.updated),
    },
    {
      label: 'Actions',
      accessor: 'menu',
      sortable: false,
      cellRenderer: (item) => (
        <ActionsWrapper>
          <SavedViewActions
            savedView={item}
            canEditTemplate={checkCanEditTemplate(item, hasPermissionForResource)}
            canDelete={!item.vennTemplate && hasPermissionForResource?.('EDIT_ANALYSIS_VIEW', item)}
            onDelete={() => handleAction(item, 'DELETE')}
            onLaunch={(e) => {
              selectView(e, item);
              history.push(getAnalysisViewUrl(item));
            }}
            onEditTemplate={(e) => {
              selectView(e, item);
              history.push(getAnalysisViewUrl(item, true));
            }}
            onCancelScheduledExport={() => handleAction(item, 'CANCEL')}
          />
          <Warning exportsErrorType={item.exportsErrorType} />
        </ActionsWrapper>
      ),
    },
  ]);

const checkCanEditTemplate = (
  item: AnalysisViewSearchResultWithUIState,
  hasPermissionForResource?: (permission: ActionEnum, resource: { ownerContextId?: string }) => boolean,
) =>
  !!(
    isStudioTemplate(item.analysisViewType) &&
    !item.vennTemplate &&
    hasPermissionForResource?.('STUDIO_CREATE_TEMPLATE', item)
  );

export const getRowStyles = (item: AnalysisViewSearchResultWithUIState, Colors: VennColors) => {
  if (item.selected) {
    return css`
      background-color: ${ColorUtils.hex2rgba(Colors.Primary.Main, 0.15)} !important;
    `;
  }

  if (item.exportsErrorType) {
    return css`
      background-color: ${ColorUtils.hex2rgba(Colors.Alert, 0.1)} !important;
    `;
  }

  return css``;
};

const StyledTooltip = styled(Tooltip)`
  max-width: 100%;
`;

const NameCell = styled.div`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
`;

const TooltipContentWrapper = styled.div`
  font-style: italic;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
`;

const SubHeader = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
`;

const LinkGroup = styled.div`
  display: flex;
  column-gap: 16px;
  font-weight: bold;
  margin-top: 8px;

  a:hover {
    color: ${GetColor.Primary.Main};
  }
`;

const ItemTypeLabel = styled.span`
  padding-left: 5px;
`;

const ActionsWrapper = styled.div`
  & > * + * {
    margin-left: 10px;
  }
`;
