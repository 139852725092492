import { useMemo } from 'react';
import type { DateRange } from '../types';
import { validate } from './logic';
import { DEFAULT_MAX_RANGE } from '../constants';

const INFO_MESSAGE = 'Allow selection of non-overlapping history';

const useNonOverlapping = (
  maxRange: DateRange | undefined,
  allowNonConstraint: boolean,
  checkedNonConstraint: boolean,
  from?: number,
  to?: number,
) => {
  const effectiveMaxRange = useMemo(() => {
    if (allowNonConstraint && checkedNonConstraint) {
      return DEFAULT_MAX_RANGE;
    }
    return maxRange;
  }, [maxRange, allowNonConstraint, checkedNonConstraint]);

  const isValid = useMemo(() => validate({ from, to }, effectiveMaxRange), [from, to, effectiveMaxRange]);

  return {
    infoMessage: INFO_MESSAGE,
    effectiveMaxRange,
    isValid,
  };
};

export default useNonOverlapping;
