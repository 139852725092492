/*
 * Venn Platform API 1.0.0 Operations for "gdpr"
 * Automatically generated file - do not modify!
 */

import type { GdprStatus, NamedEntityInteger } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Accept eula agreement, same as /api/user/agreement/{id} */
export async function acceptEulaAgreements(
  agreementId: number,
  signal?: AbortSignal,
): Promise<OperationResult<GdprStatus>> {
  const endpoint = `/api/gdpr/agreement/${encodeURIComponent(agreementId)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: GdprStatus = (await response.json()) as GdprStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Accept privacy policy for eeu users */
export async function acceptGdprPrivacyChanges(signal?: AbortSignal): Promise<OperationResult<GdprStatus>> {
  const endpoint = '/api/gdpr/privacy';
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: GdprStatus = (await response.json()) as GdprStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get eula agreement, same as /api/user/agreement */
export async function getEulaAgreements(signal?: AbortSignal): Promise<OperationResult<NamedEntityInteger[]>> {
  const endpoint = '/api/gdpr/agreement';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: NamedEntityInteger[] = (await response.json()) as NamedEntityInteger[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get user gdpr status */
export async function getUserGDPRStatus(signal?: AbortSignal): Promise<OperationResult<GdprStatus>> {
  const endpoint = '/api/gdpr/status';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: GdprStatus = (await response.json()) as GdprStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set a users gdpr region */
export async function setGdprArea(
  body?: Partial<GdprStatus>,
  signal?: AbortSignal,
): Promise<OperationResult<GdprStatus>> {
  const endpoint = '/api/gdpr/region';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: GdprStatus = (await response.json()) as GdprStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
