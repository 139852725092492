import type { DeepMapTo } from 'venn-utils';
import type { Theme } from 'venn-ui-kit';
import { EditMode } from 'venn-components';

export const TOP_TOOLBAR_HEIGHT = '65px';

type ThemeProvider = (props: { theme: Theme }) => string;

interface TopBarColorType {
  DarkGrey: string;
  MidGrey2: string;
  Background: string;
  ActiveBackground: string;
  ReportBackground: string;
  ReportActiveBackground: string;
  Border: string;
}

type GetColorType = DeepMapTo<TopBarColorType, ThemeProvider>;
export const TopBarDefaultColor: GetColorType = {
  DarkGrey: ({ theme }) => theme.Colors.DarkGrey,
  MidGrey2: ({ theme }) => theme.Colors.MidGrey2,
  Background: ({ theme }) => theme.Colors.GreyScale.Grey90,
  ActiveBackground: ({ theme }) => theme.Colors.GreyScale.Grey70,
  ReportBackground: ({ theme }) => theme.Colors.GreyScale.Grey90,
  ReportActiveBackground: ({ theme }) => theme.Colors.GreyScale.Grey70,
  Border: ({ theme }) => theme.Colors.GreyScale.Grey30,
};
export const TopBarTemplateColor: GetColorType = {
  DarkGrey: () => '#0000004D', // black, 30% opacity
  MidGrey2: ({ theme }) => theme.Colors.MidGrey2,
  Background: () => '#544B14',
  ActiveBackground: () => '#7C7026',
  ReportBackground: () => '#225457',
  ReportActiveBackground: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.B4,
  Border: ({ theme }) => theme.Colors.GreyScale.Grey30,
};

export const TopBarColor = {
  [EditMode.TEMPLATE]: TopBarTemplateColor,
  [EditMode.DEFAULT]: TopBarDefaultColor,
};
