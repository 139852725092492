import React from 'react';
import type { StudioIconProps } from './shared';
import { Path, SVG } from './shared';
import useCustomIconColor from './useCustomIconColor';

const StudioIcon = ({ isNav, navSelected, color, hoverHighlight, size = 26, top }: StudioIconProps) => {
  const { Colors, fillColor, hoverColor, bgFillColor } = useCustomIconColor(isNav, navSelected, color, hoverHighlight);

  return (
    <SVG
      data-testid="qa-studio-icon"
      width={size}
      height={size}
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      top={top}
    >
      <Path
        d="M10.25 12.5H1.25C0.828125 12.5 0.5 12.875 0.5 13.25V14.75C0.5 15.1719 0.828125 15.5 1.25 15.5H10.25C10.625 15.5 11 15.1719 11 14.75V13.25C11 12.875 10.625 12.5 10.25 12.5ZM10.25 18.5H1.25C0.828125 18.5 0.5 18.875 0.5 19.25V20.75C0.5 21.1719 0.828125 21.5 1.25 21.5H10.25C10.625 21.5 11 21.1719 11 20.75V19.25C11 18.875 10.625 18.5 10.25 18.5Z"
        fillColor={fillColor}
        hoverColor={hoverColor}
      />
      <Path
        d="M1.25 0.5H10.25C10.625 0.5 11 0.875 11 1.25V8.75C11 9.17188 10.625 9.5 10.25 9.5H1.25C0.828125 9.5 0.5 9.17188 0.5 8.75V1.25C0.5 0.875 0.828125 0.5 1.25 0.5Z"
        fillColor={fillColor}
        hoverColor={hoverColor}
      />
      <Path
        d="M8.32143 4.35714H6.39286V2.42857C6.39286 2.20089 6.19196 2 5.96429 2H5.53571C5.29464 2 5.10714 2.20089 5.10714 2.42857V4.35714H3.17857C2.9375 4.35714 2.75 4.55804 2.75 4.78571V5.21429C2.75 5.45536 2.9375 5.64286 3.17857 5.64286H5.10714V7.57143C5.10714 7.8125 5.29464 8 5.53571 8H5.96429C6.19196 8 6.39286 7.8125 6.39286 7.57143V5.64286H8.32143C8.54911 5.64286 8.75 5.45536 8.75 5.21429V4.78571C8.75 4.55804 8.54911 4.35714 8.32143 4.35714Z"
        fillColor={bgFillColor}
      />
      <Path
        d="M14.75 12.5H23.75C24.125 12.5 24.5 12.875 24.5 13.25V14.75C24.5 15.1719 24.125 15.5 23.75 15.5H14.75C14.3281 15.5 14 15.1719 14 14.75V13.25C14 12.875 14.3281 12.5 14.75 12.5Z"
        fillColor={isNav ? Colors.White : fillColor}
        fillOpacity={isNav ? 0.4 : 1}
        hoverColor={hoverColor}
      />
      <Path
        d="M14.75 18.5H23.75C24.125 18.5 24.5 18.875 24.5 19.25V20.75C24.5 21.1719 24.125 21.5 23.75 21.5H14.75C14.3281 21.5 14 21.1719 14 20.75V19.25C14 18.875 14.3281 18.5 14.75 18.5Z"
        fillColor={isNav ? Colors.White : fillColor}
        fillOpacity={isNav ? 0.4 : 1}
        hoverColor={hoverColor}
      />
      <Path
        d="M21.75 6.5H23.75C24.125 6.5 24.5 6.875 24.5 7.25V8.75C24.5 9.17188 24.125 9.5 23.75 9.5H21.75C21.3281 9.5 21 9.17188 21 8.75V7.25C21 6.875 21.3281 6.5 21.75 6.5Z"
        fillColor={isNav ? Colors.White : fillColor}
        fillOpacity={isNav ? 0.4 : 1}
        hoverColor={hoverColor}
      />
      <Path
        d="M14.75 0.5H19.75C20.125 0.5 20.5 0.875 20.5 1.25V2.75C20.5 3.17188 20.125 3.5 19.75 3.5H14.75C14.3281 3.5 14 3.17188 14 2.75V1.25C14 0.875 14.3281 0.5 14.75 0.5Z"
        fillColor={isNav ? Colors.White : fillColor}
        fillOpacity={isNav ? 0.4 : 1}
        hoverColor={hoverColor}
      />
      <Path
        d="M24.2857 1.67857H23.3214V0.714286C23.3214 0.600446 23.221 0.5 23.1071 0.5H22.8929C22.7723 0.5 22.6786 0.600446 22.6786 0.714286V1.67857H21.7143C21.5938 1.67857 21.5 1.77902 21.5 1.89286V2.10714C21.5 2.22768 21.5938 2.32143 21.7143 2.32143H22.6786V3.28571C22.6786 3.40625 22.7723 3.5 22.8929 3.5H23.1071C23.221 3.5 23.3214 3.40625 23.3214 3.28571V2.32143H24.2857C24.3996 2.32143 24.5 2.22768 24.5 2.10714V1.89286C24.5 1.77902 24.3996 1.67857 24.2857 1.67857Z"
        fillColor={fillColor}
        hoverColor={hoverColor}
      />
      <Path
        d="M19.1429 7.46429H17.5357V5.85714C17.5357 5.66741 17.3683 5.5 17.1786 5.5H16.8214C16.6205 5.5 16.4643 5.66741 16.4643 5.85714V7.46429H14.8571C14.6562 7.46429 14.5 7.6317 14.5 7.82143V8.17857C14.5 8.37946 14.6562 8.53571 14.8571 8.53571H16.4643V10.1429C16.4643 10.3438 16.6205 10.5 16.8214 10.5H17.1786C17.3683 10.5 17.5357 10.3438 17.5357 10.1429V8.53571H19.1429C19.3326 8.53571 19.5 8.37946 19.5 8.17857V7.82143C19.5 7.6317 19.3326 7.46429 19.1429 7.46429Z"
        fillColor={fillColor}
        hoverColor={hoverColor}
      />
    </SVG>
  );
};

export default StudioIcon;
