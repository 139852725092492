import React, { useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import ReturnSample from './images/paste/returnSample.png';
import NavsSample from './images/paste/navSample.png';
import { DataUploaderMode } from '../types';
import FileLoading from './FileLoading';

export interface PasteInputProps {
  mode: DataUploaderMode;
  isUploading?: boolean;
  onPaste(data: Blob): unknown;
}

export const PasteInput = ({ isUploading, onPaste, mode }: PasteInputProps) => {
  const [inputNode, setInputNode] = useState<HTMLTextAreaElement | null>(null);
  const setRef = useCallback((node) => {
    setInputNode(node);
  }, []);

  useEffect(() => {
    setText('');
    if (inputNode) {
      if (isUploading) {
        inputNode.blur();
      } else {
        inputNode.focus();
      }
    }
  }, [isUploading, inputNode]);

  const [text, setText] = useState('');
  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');
    const data = new Blob([pastedText], { type: 'text/plain' });
    onPaste(data);
  };

  return (
    <StyledPasteInput>
      {isUploading && (
        <LoadingContainer>
          <FileLoading label="Pasted data" />
        </LoadingContainer>
      )}
      {!text && !isUploading && (
        <Hint>
          <div>Paste data in any format here...</div>
          <StyledImage src={mode === DataUploaderMode.Returns ? ReturnSample : NavsSample} alt="paste data format" />
        </Hint>
      )}
      <StyledTextArea
        onPaste={(e) => handlePaste(e)}
        value={text}
        onChange={(e) => setText(e.target.value)}
        ref={setRef}
      />
    </StyledPasteInput>
  );
};

export default PasteInput;

const StyledPasteInput = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const fontStyles = css`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
`;

const StyledTextArea = styled.textarea`
  ${fontStyles}
  width: 100%;
  height: 100%;
  border: 1px solid ${GetColor.MidGrey2};
  color: ${GetColor.Black};
  resize: none;
`;

// Make sure that the hint text aligns with the textarea cursor,
// and that the hint is not selectable
const Hint = styled.div`
  ${fontStyles}
  position: absolute;
  padding: 6px 11px; // default padding of the textarea + 1 (for the border)
  color: ${GetColor.HintGrey};
  pointer-events: none;
`;

const StyledImage = styled.img`
  width: 360px;
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  ${fontStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
`;
