/*
 * Venn Platform API 1.0.0 Operations for "images"
 * Automatically generated file - do not modify!
 */

import type { ImageDetails } from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Delete specific image */
export async function deleteImageById(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/images/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all images by base path */
export async function getImagesByPath(
  basePath?: string,
  signal?: AbortSignal,
): Promise<OperationResult<ImageDetails[]>> {
  const endpoint = `/api/images${buildQuery({ basePath })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ImageDetails[] = (await response.json()) as ImageDetails[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get image by ID */
export async function getImageById(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/images/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Save image with base path */
export async function storeImage(body?: any, signal?: AbortSignal): Promise<OperationResult<string>> {
  const endpoint = '/api/images';
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.json()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Set image permission group id to global */
export async function setImagePermissionToGlobal(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/images/${encodeURIComponent(id)}/global`;
  const requestInit = buildRequestInit({ method: 'PUT', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
