import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SidePanelOverlay } from 'venn-components';
import { ADVANCED_SEARCH_FAQ_HREF, Button, GetColor, Headline3, Icon, TooltipLink } from 'venn-ui-kit';
import type { LibraryState } from 'venn-utils';
import { analyticsService, LibraryItemType, useHasFF } from 'venn-utils';
import { FilterMenuTrigger, useSelectState } from '../shared';
import type { LibraryFilterSection } from '../types';
import AdvancedQueries from './AdvancedQueries';
import TagsAdvancedFilter from './TagsAdvancedFilter';
import useAdvancedQueries from './useAdvancedQueries';
import CurrencyFilter from '../CurrencyFilter';
import SourceQuickFilter from './SourceQuickFilter';
import ItemTypeFilter from './ItemTypeFilter';
import AdvancedPills from './AdvancedPills';
import DataSourceFilter from './DataSourceFilter';
import AssetTypeFilter from '../AssetTypeFilter';
import CategoryFilter from '../CategoryFilter';
import type { FiltersState } from 'venn-state';
import { useHistory } from 'react-router-dom';

const fieldName = 'ALL FILTERS AND QUERIES';
const cancelButton = 'Cancel';
export const resetTrackingProps = {
  destination: undefined, // Stays on the page
  filled: false,
  locationOnPage: 'Library All Filters panel - bottom right',
  purpose: 'Reset state of All Filters panel',
  text: 'Reset',
  type: 'button',
};
export const getCancelTrackingProps = (isCancelButton: boolean) => ({
  destination: undefined, // Stays on the page
  filled: !isCancelButton,
  locationOnPage: `Library All Filters panel - ${isCancelButton ? 'bottom right' : 'top left'}`,
  purpose: 'Close All Filters panel',
  text: isCancelButton ? cancelButton : '->',
  type: 'button',
});
export const openTrackingProps = {
  destination: undefined, // Stays on the page
  filled: true,
  locationOnPage: 'Library - above table',
  purpose: 'Open All Filters panel',
  text: fieldName,
  type: 'button',
};

interface AdvancedFiltersProps {
  /** Whether the trigger to open the advanced filtering panel is disabled or not */
  disabled?: boolean;
  /** The initially selected parameters for the advanced filter */
  initialSelected: FiltersState;
  /**
   * The function to call with the updated selection.
   */
  onFilter: (updated: FiltersState) => void;
  /** The filter sections: [source filters, item type filters, tag filters] */
  sections: [LibraryFilterSection<string>, LibraryFilterSection<LibraryItemType>, LibraryFilterSection<string>];
}

const AdvancedFilters = ({ disabled, initialSelected, onFilter, sections }: AdvancedFiltersProps) => {
  const history = useHistory<LibraryState>();
  const [isOpen, setOpen] = useState(history.location.state?.advancedFiltersOpen ?? false);
  const [sourceFilterSection, itemTypeSection, tagSection] = sections;
  const { quickFilters, itemType, currency, assetTypes, dataSource, advancedQueries, tags, morningstarCategories } =
    initialSelected;

  const {
    selectedState: sourceFilterSelectedState,
    resetState: resetSourceFilterState,
    updateSelectedState: updateSelectedSourceFilterState,
    onOptionClick: onSourceFilterClick,
  } = useSelectState(quickFilters);

  const [itemTypeState, setItemTypeState] = useState<LibraryItemType>(itemType);
  useEffect(() => {
    setItemTypeState(itemType);
  }, [itemType]);

  const {
    selectedState: currencySelectedState,
    resetState: resetCurrencyState,
    updateSelectedState: updateSelectedCurrencyState,
    onOptionClick: onCurrencyClick,
  } = useSelectState(currency);

  const {
    selectedState: assetTypeSelectedState,
    resetState: resetAssetTypeState,
    updateSelectedState: updateSelectedAssetTypeState,
    onOptionClick: onAssetTypeClick,
  } = useSelectState(assetTypes);

  const {
    selectedState: dataSourceSelectedState,
    resetState: resetDataSourceState,
    updateSelectedState: updateSelectedDataSourceState,
    onOptionClick: onDataSourceClick,
  } = useSelectState(dataSource);

  const {
    selectedState: morningstarCategoriesSelectedState,
    resetState: resetMorningstarCategoriesState,
    updateSelectedState: updateSelectedMorningstarCategoriesState,
    onOptionClick: onMorningstarCategoryClick,
  } = useSelectState(morningstarCategories);

  const {
    selectedState: tagSelectedState,
    resetState: resetTagState,
    setSelectedState: setSelectedTagState,
    onOptionClick: onTagClick,
  } = useSelectState(tags);

  const {
    resetAdvancedQueriesState,
    advancedQueriesState,
    addQuery,
    removeQuery,
    updateQuery,
    getValidAdvancedQueries,
  } = useAdvancedQueries(advancedQueries);

  // if there are fields that have errors, show them
  const [showErrors, setShowErrors] = useState(false);

  const applyChanges = useCallback(() => {
    if (advancedQueriesState.some(({ error }) => error)) {
      setShowErrors(true);
      return;
    }
    setShowErrors(false);
    onFilter({
      quickFilters: Array.from(sourceFilterSelectedState),
      morningstarCategories: Array.from(morningstarCategoriesSelectedState),
      itemType: itemTypeState,
      assetTypes: Array.from(assetTypeSelectedState),
      currency: Array.from(currencySelectedState),
      dataSource: Array.from(dataSourceSelectedState),
      advancedQueries: getValidAdvancedQueries(),
      tags: Array.from(tagSelectedState),
    });
    setOpen(false);
  }, [
    advancedQueriesState,
    assetTypeSelectedState,
    currencySelectedState,
    dataSourceSelectedState,
    getValidAdvancedQueries,
    itemTypeState,
    onFilter,
    sourceFilterSelectedState,
    tagSelectedState,
    morningstarCategoriesSelectedState,
  ]);

  const resetState = useCallback(() => {
    setShowErrors(false);
    resetTagState();
    resetAssetTypeState();
    resetCurrencyState();
    resetDataSourceState();
    resetSourceFilterState();
    setItemTypeState(itemType);
    resetAdvancedQueriesState();
    resetMorningstarCategoriesState();
  }, [
    resetAdvancedQueriesState,
    itemType,
    resetAssetTypeState,
    resetCurrencyState,
    resetDataSourceState,
    resetSourceFilterState,
    resetTagState,
    resetMorningstarCategoriesState,
  ]);

  const onResetClick = useCallback(() => {
    resetState();
    analyticsService.ctaClicked(resetTrackingProps);
  }, [resetState]);

  const getOnCancel = useCallback(
    (isCancelButton: boolean) => () => {
      resetState();
      setOpen(false);
      analyticsService.ctaClicked(getCancelTrackingProps(isCancelButton));
    },
    [resetState],
  );

  const onOpen = useCallback(() => {
    setOpen(true);
    analyticsService.ctaClicked(openTrackingProps);
  }, []);

  const hasCategoriesSearchFF = useHasFF('categories_search_ff');
  return (
    <>
      <FilterMenuTrigger
        dominant
        label={fieldName}
        disabled={disabled}
        onClick={onOpen}
        aria-expanded={isOpen}
        aria-haspopup
      />
      <TooltipLink
        href={ADVANCED_SEARCH_FAQ_HREF}
        positions={{
          top: -60,
          left: -90,
        }}
        top
      />
      {!disabled && (
        <SidePanelOverlay
          className="qa-advanced-filters-side-panel"
          isOpen={isOpen}
          side="right"
          handleClose={getOnCancel(false)}
          width={1280}
          mainContentElement={document.getElementsByTagName('main')[0]}
          content={
            <>
              <FilterRow style={{ overflow: 'unset' }}>
                <Section>
                  <SectionHeading>
                    <Icon type="filter" />
                    &nbsp; All Filters
                  </SectionHeading>
                  <Row>
                    <Section className="qa-source-filter">
                      <SourceQuickFilter
                        onFilter={updateSelectedSourceFilterState}
                        selected={Array.from(sourceFilterSelectedState)}
                        sourceItems={sourceFilterSection.items}
                      />
                    </Section>
                    <Section className="qa-item-type-filter">
                      <ItemTypeFilter
                        onFilter={(updated) => setItemTypeState(updated)}
                        selected={itemTypeState}
                        itemTypeItems={itemTypeSection.items}
                      />
                    </Section>
                    <Section className="qa-currency-filter">
                      <CurrencyFilter
                        onFilter={updateSelectedCurrencyState}
                        selected={Array.from(currencySelectedState)}
                        isAdvanced
                      />
                    </Section>
                    <Section className="qa-asset-type-filter">
                      <AssetTypeFilter
                        onFilter={updateSelectedAssetTypeState}
                        menuWidth={295}
                        selected={Array.from(assetTypeSelectedState)}
                        isAdvanced
                      />
                    </Section>
                    <Section className="qa-data-source-filter">
                      <DataSourceFilter
                        onFilter={updateSelectedDataSourceState}
                        selected={Array.from(dataSourceSelectedState)}
                        isAdvanced
                      />
                    </Section>
                    {hasCategoriesSearchFF && (
                      <Section className="qa-morningstar-category-filter">
                        <CategoryFilter
                          onFilter={updateSelectedMorningstarCategoriesState}
                          selected={Array.from(morningstarCategoriesSelectedState)}
                          closeOnOnlyClick
                        />
                      </Section>
                    )}
                  </Row>
                  <AdvancedPills
                    sourceFilterSelected={Array.from(sourceFilterSelectedState)}
                    itemTypeSelected={itemTypeState}
                    currencySelected={Array.from(currencySelectedState)}
                    dataSourceSelected={Array.from(dataSourceSelectedState)}
                    morningstarCategorySelected={Array.from(morningstarCategoriesSelectedState)}
                    assetTypeSelected={Array.from(assetTypeSelectedState)}
                    removeSourceFilter={onSourceFilterClick}
                    removeItemType={() => setItemTypeState(LibraryItemType.ALL)}
                    removeCurrency={onCurrencyClick}
                    removeDataSource={onDataSourceClick}
                    removeAssetType={onAssetTypeClick}
                    removeMorningstarCategory={onMorningstarCategoryClick}
                  />
                </Section>
              </FilterRow>
              <FilterRow style={{ overflow: 'unset' }}>
                <Section>
                  <SectionHeading>
                    <Icon type="crosshairs" />
                    &nbsp;Advanced Queries
                  </SectionHeading>
                  <Note>
                    Investments, Portfolios, and Benchmarks with insufficient returns data will be excluded from the
                    result set.
                  </Note>
                  <AdvancedQueries
                    rows={advancedQueriesState}
                    onRemoveQuery={removeQuery}
                    onAddQuery={addQuery}
                    onUpdateQuery={updateQuery}
                    showErrors={showErrors}
                  />
                </Section>
              </FilterRow>
              <FilterRow>
                <Section className="qa-tag-filter">
                  <SectionHeading>
                    <Icon type="tag" />
                    &nbsp;Tags
                  </SectionHeading>
                  <TagsAdvancedFilter
                    section={tagSection}
                    initialSelected={tagSelectedState}
                    onUpdateSelected={setSelectedTagState}
                    removeTag={onTagClick}
                  />
                </Section>
              </FilterRow>
            </>
          }
          footer={
            <>
              <ButtonGroup>
                <Button key="cancel" className="qa-cancel-adv-filter" dense onClick={getOnCancel(true)}>
                  {cancelButton}
                </Button>
                <Button key="reset" className="qa-reset-adv-filter" dense onClick={onResetClick}>
                  Reset
                </Button>
              </ButtonGroup>
              <Button key="submit" type="submit" dense dominant onClick={applyChanges}>
                Apply
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

export default AdvancedFilters;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  &:not(:last-child) {
    margin-bottom: 65px;
  }
`;

const Section = styled.div`
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const SectionHeading = styled(Headline3)`
  margin-bottom: 20px;
`;

const Note = styled.p`
  color: ${GetColor.Black};
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  button:not(:first-child) {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  button:not(:last-child) {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button:last-child {
    margin-right: 30px;
  }
`;
