import React, { useState } from 'react';
import styled from 'styled-components';
import type { PrivatePortfolioNode } from 'venn-api';
import { assertExhaustive } from 'venn-utils';
import Modal from '../../modal/Modal';
import CreatePrivatePortfolioStep from './CreatePrivatePortfolioStep';
import SelectPortfolioTypeStep from './SelectPortfolioTypeStep';

interface CreatePortfolioModalWithPrivatesProps {
  onClose: () => void;
  openProFormaModal: () => void;
  onPrivatePortfolioCreated: (portfolio: PrivatePortfolioNode) => void;
}

enum Step {
  SelectPortfolioType,
  CreatePrivatePortfolio,
}

const CreatePortfolioModalWithPrivates = ({
  onClose,
  openProFormaModal,
  onPrivatePortfolioCreated,
}: CreatePortfolioModalWithPrivatesProps) => {
  const [step, setStep] = useState(Step.SelectPortfolioType);

  const renderStep = () => {
    switch (step) {
      case Step.SelectPortfolioType:
        return (
          <SelectPortfolioTypeStep
            openProFormaModal={openProFormaModal}
            onCancel={onClose}
            moveToCreatePrivatePortfolio={() => setStep(Step.CreatePrivatePortfolio)}
          />
        );
      case Step.CreatePrivatePortfolio:
        return <CreatePrivatePortfolioStep onCancel={onClose} onPrivatePortfolioCreated={onPrivatePortfolioCreated} />;
      default:
        throw assertExhaustive(step);
    }
  };

  return (
    <StyledModal closeOnEsc onClose={onClose}>
      {renderStep()}
    </StyledModal>
  );
};

export default CreatePortfolioModalWithPrivates;

const StyledModal = styled(Modal)`
  width: 1000px;
  height: 685px;
  max-height: 685px;
  border-radius: 4px;
  padding: 0;
`;
