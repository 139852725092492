/*
 * Venn Platform API 1.0.0 Operations for "privates"
 * Automatically generated file - do not modify!
 */

import type {
  CashFlowSetting,
  CashflowClassificationParameters,
  MappedPrivateFund,
  ParsedPrivateFunds,
  PrivateFund,
  PrivateFundPerformance,
  PrivateFundTransaction,
  PrivatePortfolioInfo,
  PrivatePortfolioNode,
  PrivatePortfolioNodeResponseWrapper,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Commit privates XLS file to system */
export async function storePrivatesXLS(
  fileId?: string,
  body?: MappedPrivateFund[],
  signal?: AbortSignal,
): Promise<OperationResult<PrivateFund[]>> {
  const endpoint = `/api/privates/file/store${buildQuery({ fileId })}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivateFund[] = (await response.json()) as PrivateFund[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function activate(id: string, body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/privates/settings/activate/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function copyPrivatePortfolio(
  sourcePortfolioId: string,
  newPortfolioName?: string,
  signal?: AbortSignal,
): Promise<OperationResult<PrivatePortfolioNode>> {
  const endpoint = `/api/privates/portfolio/${encodeURIComponent(sourcePortfolioId)}/copy${buildQuery({ newPortfolioName })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivatePortfolioNode = (await response.json()) as PrivatePortfolioNode;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function deleteCashflowSettings(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/privates/settings/delete';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function deletePrivateFund(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/privates/fund/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function deletePrivateFunds(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/privates/funds';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function deletePrivatePortfolio(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/privates/portfolio/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function deletePrivatePortfolios(body?: string[], signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/privates/portfolios';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getActiveCashflowSettings(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: CashFlowSetting }>> {
  const endpoint = '/api/privates/settings/active';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: CashFlowSetting } = (await response.json()) as { [key: string]: CashFlowSetting };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getAllPrivateFunds(signal?: AbortSignal): Promise<OperationResult<PrivateFund[]>> {
  const endpoint = '/api/privates/funds';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivateFund[] = (await response.json()) as PrivateFund[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getAllSystemCashflowSettings(signal?: AbortSignal): Promise<OperationResult<CashFlowSetting[]>> {
  const endpoint = '/api/privates/settings/system/all';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CashFlowSetting[] = (await response.json()) as CashFlowSetting[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getFundsUsingSetting(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<{ [key: string]: PrivateFund }>> {
  const endpoint = `/api/privates/settings/usage/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: PrivateFund } = (await response.json()) as { [key: string]: PrivateFund };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPrivateFund(id: string, signal?: AbortSignal): Promise<OperationResult<PrivateFund>> {
  const endpoint = `/api/privates/fund/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivateFund = (await response.json()) as PrivateFund;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPrivateFundLatestPerformance(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<PrivateFundPerformance>> {
  const endpoint = `/api/privates/fund/${encodeURIComponent(id)}/performance/latest`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivateFundPerformance = (await response.json()) as PrivateFundPerformance;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPrivateFundPerformance(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<PrivateFundPerformance[]>> {
  const endpoint = `/api/privates/fund/${encodeURIComponent(id)}/performance`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivateFundPerformance[] = (await response.json()) as PrivateFundPerformance[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPrivateFundTransactions(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<PrivateFundTransaction[]>> {
  const endpoint = `/api/privates/fund/${encodeURIComponent(id)}/transactions`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivateFundTransaction[] = (await response.json()) as PrivateFundTransaction[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPrivateFundsById(
  fundIds?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<PrivateFund[]>> {
  const endpoint = `/api/privates/funds${buildQuery({ fundIds })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivateFund[] = (await response.json()) as PrivateFund[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPrivatePortfolio(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<PrivatePortfolioNode>> {
  const endpoint = `/api/privates/portfolio/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivatePortfolioNode = (await response.json()) as PrivatePortfolioNode;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPrivatePortfolioByName(
  name?: string,
  signal?: AbortSignal,
): Promise<OperationResult<PrivatePortfolioNodeResponseWrapper>> {
  const endpoint = `/api/privates/portfolio/find-by-name${buildQuery({ name })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivatePortfolioNodeResponseWrapper = (await response.json()) as PrivatePortfolioNodeResponseWrapper;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPrivatePortfolios(signal?: AbortSignal): Promise<OperationResult<PrivatePortfolioNode[]>> {
  const endpoint = '/api/privates/portfolio';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivatePortfolioNode[] = (await response.json()) as PrivatePortfolioNode[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getPrivatePortfoliosWithFund(
  id: string,
  signal?: AbortSignal,
): Promise<OperationResult<PrivatePortfolioInfo[]>> {
  const endpoint = `/api/privates/fund/${encodeURIComponent(id)}/portfolios`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivatePortfolioInfo[] = (await response.json()) as PrivatePortfolioInfo[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getSystemCashflowSetting(
  body?: Partial<CashflowClassificationParameters>,
  signal?: AbortSignal,
): Promise<OperationResult<CashFlowSetting>> {
  const endpoint = '/api/privates/settings/system';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CashFlowSetting = (await response.json()) as CashFlowSetting;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function getUserCreatedCashflowSettings(
  signal?: AbortSignal,
): Promise<OperationResult<CashFlowSetting[]>> {
  const endpoint = '/api/privates/settings/all';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CashFlowSetting[] = (await response.json()) as CashFlowSetting[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function renamePrivateFund(
  id: string,
  name?: string,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/privates/fund/${encodeURIComponent(id)}/name${buildQuery({ name })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function renamePrivatePortfolio(
  id: string,
  name?: string,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/privates/portfolio/${encodeURIComponent(id)}/name${buildQuery({ name })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function saveCashflowSettings(
  body?: Partial<CashFlowSetting>,
  signal?: AbortSignal,
): Promise<OperationResult<CashFlowSetting>> {
  const endpoint = '/api/privates/settings';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: CashFlowSetting = (await response.json()) as CashFlowSetting;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** No summary or description available. Please update the summary or description field in the Openapi spec. */
export async function savePrivatePortfolio(
  body?: Partial<PrivatePortfolioNode>,
  signal?: AbortSignal,
): Promise<OperationResult<PrivatePortfolioNode>> {
  const endpoint = '/api/privates/portfolio';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PrivatePortfolioNode = (await response.json()) as PrivatePortfolioNode;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Upload privates XLS file for mapping and saving to the system later */
export async function uploadPrivatesXLS(
  body?: any,
  signal?: AbortSignal,
): Promise<OperationResult<ParsedPrivateFunds>> {
  const endpoint = '/api/privates/file/xls';
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ParsedPrivateFunds = (await response.json()) as ParsedPrivateFunds;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
