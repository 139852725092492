import { type AtomEffect } from 'recoil';
import { useState, useEffect } from 'react';

type UnsavedChangesListener = (hasUnsavedChanges: boolean) => void;

let hasUnsavedChanges = false;

const listeners = new Set<UnsavedChangesListener>();

/**
 * When the value of the atom changes, the value returned by {@link getHasUnsavedChanges} is set to true.
 *
 * The value can be reset back to false {@link clearHasUnsavedChanges}.
 */
export const unsavedChangesEffect = <T>({ onSet }: Parameters<AtomEffect<T>>[0]) => {
  onSet((newValue, oldValue) => {
    if (oldValue !== newValue) {
      setHasUnsavedChanges(true);
    }
  });
};

/**
 * Prefer using {@link useUnsavedChanges} when within react components.
 *
 * @see {@link unsavedChangesEffect}
 */
function getHasUnsavedChanges() {
  return hasUnsavedChanges;
}

/**
 * Prefer using {@link useUnsavedChanges} when within react components.
 *
 * @see {@link unsavedChangesEffect}
 */
export function clearHasUnsavedChanges() {
  setHasUnsavedChanges(false);
}

/**
 * Prefer using {@link useUnsavedChanges} when within react components.
 *
 * @see {@link unsavedChangesEffect}
 */
function setHasUnsavedChanges(newValue: boolean) {
  if (hasUnsavedChanges !== newValue) {
    hasUnsavedChanges = newValue;
    for (const listener of listeners) {
      listener(newValue);
    }
  }
}

function subscribeToUnsavedChanges(listener: UnsavedChangesListener) {
  listeners.add(listener);
}

function unsubscribeFromUnsavedChanges(listener: UnsavedChangesListener) {
  return listeners.delete(listener);
}

export function useUnsavedChanges() {
  const [reactyUnsavedChanges, setReactUnsavedChanges] = useState(getHasUnsavedChanges);

  // Bridge the divide between react-land and global-land
  useEffect(() => {
    subscribeToUnsavedChanges(setReactUnsavedChanges);
    return () => {
      unsubscribeFromUnsavedChanges(setReactUnsavedChanges);
    };
  }, []);

  // Note that we return a setter that sets the real unsaved changes, not the reacty unsaved changes.
  // But it will propagate to reactyUnsavedChanges through the listener
  return [reactyUnsavedChanges, setHasUnsavedChanges] as const;
}
