import type { TabularDataTypeEnum } from 'venn-utils';
import { CURRENCY_CLASS_PREFIX, PERCENTAGE_CLASS } from '../customAnalysisContants';

export const getMetricDisplayName = (metric: string | undefined) => {
  switch (metric) {
    case 'contributions':
      return 'Contribution';
    case 'distributions':
      return 'Distribution';
    case 'nav':
      return 'NAV';
    case 'net':
      return 'Net Cash Flow';
    default:
      return 'Unknown Metric';
  }
};

export const getCellClassForDataType = (dataType: TabularDataTypeEnum) => {
  switch (dataType) {
    case 'TEXT':
      return 'TEXT';
    case 'NUMERIC':
      return 'NUMERIC';
    case 'PERCENTAGE':
      return PERCENTAGE_CLASS;
    case 'CURRENCY':
      return `${CURRENCY_CLASS_PREFIX}$`;
    default:
      return '';
  }
};
