import React, { useCallback, useContext, useMemo } from 'react';
import type { ReturnCell } from 'venn-api';
import styled from 'styled-components';
import type { GeneralChartProps, Serie, SubjectInfo } from './types';
import UserContext from '../../../contexts/user-context';
import { DownloadableContentBlock } from '../../../content-block';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';
import { getCurrencySymbol } from './chartsUtils';
import GrowthSimulationLineChart from './GrowthSimulationLineChart';
import {
  assertNotNil,
  convertMultipleSeriesDataToExcel,
  DEFAULT_USE_LOGARITHMIC_SCALE,
  type ExcelCell,
} from 'venn-utils';
import useExportUpdate from '../../logic/useExportUpdate';
import { compact } from 'lodash';
import StyledEmptyState from '../StyledEmptyState';
import { formatExportableSubjectWithOptionalFee } from '../../../legend';
import { EMPTY } from '../../logic/exportUtils';
import { useConfidenceLevels } from '../../logic/useConfidenceLevels';
import { EmptyHeaderSpace } from '../../../content-block/DownloadableContentBlock';
import { getSaturationLevel } from '../blocks/public-private/utils';

const GrowthSimulationChart = ({
  metrics,
  selectedBlock,
  analyses,
  requests,
  downloadableContentRef,
  height,
  subjectColors,
  selectedRefId,
  exportMetaData,
  inPrintMode,
}: GeneralChartProps) => {
  const metric = metrics ? metrics[0] : undefined;
  const userContext = useContext(UserContext);
  const workspaceCurrency = userContext.profileSettings?.organization?.currency;
  const request = requests[0];
  const start = new Date();
  const end = new Date();

  end.setFullYear(start.getFullYear() + (request?.forecastWindow ?? 10));
  const confidenceLevels = useConfidenceLevels();

  const useLogarithmicScale = selectedBlock?.logarithmicScale ?? DEFAULT_USE_LOGARITHMIC_SCALE;
  const isExportable = (exportable: boolean[], index: number) => {
    if (index === -1) {
      return false;
    }
    return exportable[index];
  };

  const [series, exportableSeries, subjectInfo] = useMemo(() => {
    const series: Serie[] = [];
    const exportableSeries: { subject: SubjectInfo; series: Serie[] }[] = [];
    const subjectInfo: SubjectInfo[] = [];

    if (!analyses || analyses.length < 1 || !metric || !requests) {
      return [series, exportableSeries, subjectInfo];
    }

    const analysis = analyses[0][0];
    if (!analysis || !analysis.growthSimulation) {
      return [series, exportableSeries, subjectInfo];
    }

    if (compact(analysis.growthSimulation).length !== analysis.growthSimulation.length) {
      // check that growthsimulations does not contain any null values
      return [series, exportableSeries, subjectInfo];
    }

    analysis.growthSimulation.forEach((simulation, subIdx) => {
      const subject = !requests[subIdx]
        ? subIdx === 0
          ? request.subject
          : assertNotNil(request.benchmark)
        : requests[subIdx].subject;

      subjectInfo.push({
        subject,
        key: subIdx,
        return: simulation.forecastReturn,
        volitility: simulation.forecastVolatility,
      });

      const subjectSeries: Serie[] = [];
      confidenceLevels.forEach((confidenceLevel) => {
        const growthSimulation = simulation.growthSimulations[confidenceLevel.originalIndex];
        if (!growthSimulation || !confidenceLevel.visible) {
          return;
        }
        const ts = growthSimulation.forecast;
        const serie = {
          name: `${String(Math.round(growthSimulation.confidenceLevel * 100))}%`,
          color: getSaturationLevel(subjectColors[subIdx], growthSimulation.confidenceLevel),
          data: convertToTimeseries(ts),
          key: subIdx,
        };

        subjectSeries.push(serie);

        if (confidenceLevel.visible) {
          series.push(serie);
        }
      });

      if (isExportable(analysis.exportable ?? [], subIdx)) {
        exportableSeries.push({ subject: subjectInfo[subIdx], series: subjectSeries });
      }
    });

    return [series, exportableSeries, subjectInfo];
  }, [analyses, metric, requests, request?.benchmark, request?.subject, subjectColors, confidenceLevels]);

  const defaultHeader =
    selectedBlock?.relativeToBenchmark && metric?.relativeLabel
      ? metric.relativeLabel
      : !metric
        ? 'Builder Chart'
        : metric.label;

  const excelDataFn = useCallback(() => {
    const headers: ExcelCell[] = [
      EMPTY,
      ...exportableSeries.flatMap(({ subject, series }) =>
        series.map((serie, idx) =>
          idx === 0
            ? {
                value: formatExportableSubjectWithOptionalFee(subject.subject),
                bold: true,
              }
            : EMPTY,
        ),
      ),
    ];
    const data = convertMultipleSeriesDataToExcel(
      exportableSeries
        .flatMap((subject) => subject.series)
        .map((serie) => ({
          name: serie.name,
          series: serie.data,
        })),
      false,
      getCurrencySymbol(workspaceCurrency),
    );
    return [headers, ...data];
  }, [exportableSeries, workspaceCurrency]);

  useExportUpdate({ exportMetaData, excelDataFn, selectedRefId });

  const component =
    series.length > 0 ? (
      <ChartWrapper data-testid="qa-growth-simulation-chart">
        <GrowthSimulationLineChart
          start={start.getTime()}
          end={end.getTime()}
          series={series}
          frequency={requests?.[0]?.frequency ?? 'MONTHLY'}
          yAxisUnitFormat="ratio"
          inPrintMode={inPrintMode}
          height={height}
          useLogarithmicScale={useLogarithmicScale}
          currency={workspaceCurrency}
          subjectInfo={subjectInfo}
        />
      </ChartWrapper>
    ) : (
      <StyledEmptyState selectedRefId={selectedRefId} header="Unable to run analysis" message="Chart is empty" />
    );

  if (inPrintMode) {
    return component;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      downloadable={{
        png: true,
        options: {
          fileName: selectedBlock?.header ? selectedBlock?.header : defaultHeader,
          watermark: { top: WATERMARK_POSITION_TOP, right: 20 },
        },
        tracking: {
          description: 'BUILDER',
          relativeToBenchmark: !!selectedBlock?.relativeToBenchmark,
          userUploaded: false,
          subjectType: undefined,
          subjectId: undefined,
        },
        disabled: series.length === 0,
        target: downloadableContentRef,
      }}
    >
      {component}
    </DownloadableContentBlock>
  );
};

export default GrowthSimulationChart;

const convertToTimeseries = (ts: number[][] | ReturnCell[]): number[][] => {
  if (!ts) {
    return [];
  }
  if (Array.isArray(ts[0])) {
    return ts as number[][];
  }

  return (ts as ReturnCell[]).map((t) => [t.date, t.val]);
};

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div:first-child {
    flex: 1;
  }
`;
