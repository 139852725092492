/*
 * Venn Platform API 1.0.0 Operations for "benchmark"
 * Automatically generated file - do not modify!
 */

import type { Benchmark } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Gets a funds benchmarks */
export async function getFundBenchmarks(id: string, signal?: AbortSignal): Promise<OperationResult<Benchmark[]>> {
  const endpoint = `/api/benchmark/fund/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Benchmark[] = (await response.json()) as Benchmark[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Gets a portfolios benchmarks */
export async function getPortfolioBenchmarks(id: number, signal?: AbortSignal): Promise<OperationResult<Benchmark[]>> {
  const endpoint = `/api/benchmark/portfolio/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Benchmark[] = (await response.json()) as Benchmark[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Sets a funds benchmarks */
export async function setFundBenchmarks(
  id: string,
  body?: Benchmark[],
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/benchmark/fund/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Sets a portfolios benchmarks */
export async function setPortfolioBenchmarks(
  id: number,
  body?: Benchmark[],
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/benchmark/portfolio/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
