import type { FC, ChangeEventHandler, KeyboardEventHandler, MouseEventHandler, SyntheticEvent } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { ColorUtils } from '../../style/colorUtils';
import { GetColor } from '../../style/color';

const backgroundColor = ColorUtils.hex2rgbaFrom(GetColor.LightGrey, 0.4);

interface LabelProps {
  hasText: boolean;
  justify: 'left' | 'center' | 'right';
  disabled: boolean;
  alignItems: 'center' | 'flex-start';
}

/** @component */
const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignItems }) => alignItems};
  flex: 1;
  justify-content: ${({ justify }) => justify};
  border-radius: 50%;
  min-height: 30px;
  min-width: 30px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  /* If there is no children text, add hover background */
  ${(props) =>
    !props.hasText &&
    !props.disabled &&
    `
  :hover {
    background-color: ${backgroundColor};
    transition: background-color 0.3s ease;
  }
  `}
`;

export const CheckboxStyle = css`
  position: relative;
  width: 16px;
  height: 16px;
  margin: 0 !important;
  transition: all 0.15s linear;
  border: 2px solid ${GetColor.Grey};
  border-radius: 2px;
  -moz-appearance: checkbox;
  -webkit-appearance: none;
  appearance: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &::-ms-check {
    border: 2px solid ${GetColor.Grey};
    border-radius: 2px;
  }

  &:indeterminate {
    background-color: ${GetColor.HintGrey};
    border-color: ${GetColor.HintGrey};
  }

  &:checked::-ms-check {
    border-color: ${GetColor.Primary.Dark};
    background-color: ${GetColor.Primary.Dark};
    color: ${GetColor.White};
  }

  &:checked:before {
    color: ${GetColor.White};
    // eslint-disable-next-line
    font-family: FAP !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    line-height: 1;
    text-transform: none;
    content: '\f00c' !important;
    speak: none;
  }

  &:indeterminate:before {
    color: ${GetColor.White};
    // eslint-disable-next-line
    font-family: FAP !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    line-height: 1;
    text-transform: none;
    content: '\f068' !important;
    speak: none;
  }
`;

/** @component */
const Input = styled.input`
  ${CheckboxStyle}
  background-color: ${({ disabled }) => (disabled ? GetColor.WhiteGrey : GetColor.White)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:checked {
    border-color: ${({ disabled }) => (disabled ? GetColor.Grey : GetColor.Primary.Dark)} !important;
    background-color: ${({ disabled }) => (disabled ? GetColor.Grey : GetColor.Primary.Dark)} !important;
  }

  ${({ disabled }) =>
    !disabled
      ? css`
          &:not(:checked):hover {
            border: 2px solid ${GetColor.Primary.Main};
          }
        `
      : ''}
`;

export const CheckboxInput: React.FunctionComponent<React.InputHTMLAttributes<HTMLInputElement>> = (props) => (
  <Input role="checkbox" type="checkbox" {...props} />
);

export interface CheckboxWrapperProps {
  className?: string;
  checked?: boolean;
  indeterminate?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement | HTMLLabelElement>;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  required?: boolean;
  justify?: 'left' | 'center' | 'right';
  alignItems?: 'center' | 'flex-start';
  disabled?: boolean;
  hideCheckbox?: boolean;
  spanStyle?: React.CSSProperties;
  checkboxDataTestId?: string;
}

export const CheckboxWrapper: FC<React.PropsWithChildren<CheckboxWrapperProps>> = ({
  children,
  className,
  checked,
  onChange,
  onClick,
  onKeyUp,
  required = false,
  justify = 'center',
  alignItems = 'center',
  indeterminate = false,
  disabled = false,
  hideCheckbox = false,
  spanStyle,
  checkboxDataTestId = 'checkbox',
}) => (
  <Label
    className={className}
    onClick={onClick && !disabled && hideCheckbox ? onClick : undefined}
    hasText={!!children}
    justify={justify}
    disabled={disabled}
    alignItems={alignItems}
  >
    {!hideCheckbox && (
      <Input
        type="checkbox"
        checked={checked}
        required={required}
        onChange={onChange}
        onClick={onClick || stopPropagation}
        onKeyUp={onKeyUp || stopPropagation}
        ref={(el) => el && (el.indeterminate = indeterminate)}
        data-testid={checkboxDataTestId}
        disabled={disabled}
      />
    )}
    {children && <ChildrenSpan style={spanStyle}>{children}</ChildrenSpan>}
  </Label>
);

const stopPropagation = (ev: SyntheticEvent) => {
  ev.stopPropagation();
};

const ChildrenSpan = styled.span<{ fontWeight?: string; paddingLeft?: string }>`
  font-weight: ${(props) => props?.style?.fontWeight ?? '400'};
  padding-left: ${(props) => props?.style?.paddingLeft ?? '7px'};
`;

export default CheckboxWrapper;
