import type { TimeFrame, AnalysisConfig } from 'venn-utils';
import { analyticsService, AnalyticsUtils, searchPortfolioNodeId, logMessageToSentry } from 'venn-utils';
import type { AnalysisRequest, AnalysisResponse, Portfolio, UserProfileSettings, UserSettings } from 'venn-api';
import { isNil } from 'lodash';

interface TrackAnalysisPerformedOptions {
  analysisConfig: AnalysisConfig;
  content: Partial<AnalysisResponse>;
  configs: Partial<AnalysisRequest>;
  relative: boolean;
  userSettings: UserSettings;
  profileSettings: UserProfileSettings | undefined;
  masterPortfolio?: Portfolio;
  defaultTemplateId: string;
  actualTimeFrame?: TimeFrame;
}
const trackAnalysis = ({
  analysisConfig,
  content,
  configs,
  relative,
  masterPortfolio,
  defaultTemplateId,
  actualTimeFrame,
}: TrackAnalysisPerformedOptions) => {
  if (!analysisConfig.subject) {
    logMessageToSentry('Failed to track analysis because of missing subject');
    return;
  }
  /* Track Analysis Performed */
  const timeFrame = actualTimeFrame ?? analysisConfig.selectedTimeFrame;
  const startDate = AnalyticsUtils.dateRangeFormat(timeFrame.startTime || content.maxStartTime);
  const endDate = AnalyticsUtils.dateRangeFormat(timeFrame.endTime || content.maxEndTime);
  const nodeId = searchPortfolioNodeId(analysisConfig.subject.id as string, masterPortfolio);
  const isInMasterPortfolio = analysisConfig.subject.type === 'investment' ? !isNil(nodeId) : false;
  const analysisId = analysisConfig?.analysisTemplate?.id;
  const isCustomTemplate = !analysisId || (analysisId || '').includes('-');
  analyticsService.analysisPerformed({
    allocationType: document.querySelector('.qa-slider-toggle-toggled-false') ? '$' : '%',
    analysisNames: analysisConfig?.analysisTemplate?.analysisBlocks.map((block) => block.analysisBlockType),
    dateRange: `${startDate}-${endDate}`,
    factorLensId: configs?.analyses?.[0]?.factorLensId || undefined,
    frequency: content.maxFrequency,
    hasBenchmark: analysisConfig.subject.hasBenchmark,
    hasProxy: analysisConfig.subject.hasProxy,
    isCustomTemplate,
    isDefaultTemplate: analysisConfig?.analysisTemplate?.id === defaultTemplateId,
    isInMasterPortfolio,
    isMaster: analysisConfig.subject.master,
    objectId: analysisConfig.subject.id,
    objectName: analysisConfig.subject.name,
    objectType: analysisConfig.subject.type,
    portfolioLevelSelected: analysisConfig.subject.portfolioLevelSelected,
    relativeToBenchmark: !!relative && analysisConfig.subject.hasBenchmark,
    userUploaded: analysisConfig.subject.userUploaded,
  });
  /* End Track Analysis Performed */
};
export default trackAnalysis;
