import React from 'react';
import { Modal, ModalSize, ModalFooter, ModalHeader, ModalSubhead } from '../../modal';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import ScheduledExportTable from './ScheduledExportTable';

interface ExportModalProps {
  viewId: string;
  viewName: string;
  onClose: () => void;
}

const ExportHistoryModal = ({ viewId, viewName, onClose }: ExportModalProps) => {
  return (
    <StyledExportModal size={ModalSize.XLarge} onClose={onClose} noPadding>
      <ModalHeader>Export History</ModalHeader>
      <ModalSubhead>All export history for {viewName}</ModalSubhead>
      <Content>
        <ScheduledExportTable viewId={viewId} />
      </Content>
      <ModalFooter primaryLabel="Done" onPrimaryClick={onClose} />
    </StyledExportModal>
  );
};

export default ExportHistoryModal;

const StyledExportModal = styled(Modal)`
  padding-top: 20px;
`;

const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  && .fa-download {
    color: ${GetColor.Primary.Dark};
  }
  && tr {
    > th:first-child {
      padding-left: 20px;
    }
    > td:first-child {
      padding-left: 20px;
    }
  }
`;
