import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { StatefulInput, useDebounceToGlobal, UserContext } from 'venn-components';

import { GapWrapper, StyledLabel } from '../../../shared';
import { GetColor } from 'venn-ui-kit';
import { PanelSection } from '../../../PanelSection';
import { useRecoilState } from 'recoil';
import { blockHeader, blockSubHeader } from 'venn-state';

const TITLE_MAX_LENGTH = 300;

interface TitleSectionProps {
  selected: string;
}

const TitleSection = ({ selected }: TitleSectionProps) => {
  const { hasPermission } = useContext(UserContext);
  const [header, setHeader] = useRecoilState(blockHeader(selected));
  const [subHeader, setSubHeader] = useRecoilState(blockSubHeader(selected));
  const theme = useContext(ThemeContext);
  const isReadOnly = !hasPermission('STUDIO_EDIT_FORMAT');
  const [debouncedTitle, setDebouncedTitle] = useDebounceToGlobal(header ?? '', setHeader);
  const [debouncedSubtitle, setDebouncedSubtitle] = useDebounceToGlobal(subHeader ?? '', setSubHeader);
  return (
    <PanelSection header="Title / Description">
      <StyledLabel htmlFor="chart-title">Chart Title:</StyledLabel>
      <InputField
        placeholder="Enter Text"
        value={debouncedTitle}
        name="Chart Title"
        id="chart-title"
        onChange={setDebouncedTitle}
        charactersLimit={TITLE_MAX_LENGTH}
        disabled={isReadOnly}
        textInputStyle={isReadOnly ? { backgroundColor: theme.Colors.Grey } : undefined}
      />
      <GapWrapper>
        <StyledLabel htmlFor="chart-subtitle">Chart Subtitle:</StyledLabel>
        <InputField
          placeholder="Enter Text"
          value={debouncedSubtitle}
          name="Chart Subtitle"
          id="chart-subtitle"
          onChange={setDebouncedSubtitle}
          charactersLimit={TITLE_MAX_LENGTH}
          disabled={isReadOnly}
          textInputStyle={isReadOnly ? { backgroundColor: theme.Colors.Grey } : undefined}
        />
      </GapWrapper>
    </PanelSection>
  );
};

export default TitleSection;

const InputField = styled(StatefulInput)<{ disabled?: boolean }>`
  display: flex;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${GetColor.Grey};
    `}

  & > span {
    font-size: 12px;
    left: 0;
  }
`;
