/*
 * Venn Platform API 1.0.0 Operations for "export"
 * Automatically generated file - do not modify!
 */

import type { ExportMetadataRequest, ExportMetadataResponse } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** For each subject get if it's exportable and redistributable. */
export async function batchGetExportMetadata(
  body?: Partial<ExportMetadataRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<ExportMetadataResponse>> {
  const endpoint = '/api/export/metadata/batch-get';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ExportMetadataResponse = (await response.json()) as ExportMetadataResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
