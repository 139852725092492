import { noop } from 'lodash';
import React, { useState, useContext, useCallback } from 'react';
import { constSelector, useRecoilValue } from 'recoil';
import { createGlobalStyle } from 'styled-components';
import { ConfirmationModal, StudioContext } from 'venn-components';
import { redistributableStateIgnoringAllocatorPanel, isReportState, useRecoilValueWithDefault } from 'venn-state';
import { getAppTitle, GetColor, getTextThemeProvider, TooltipBodyDirection } from 'venn-ui-kit';
import { useHasFF, useModal } from 'venn-utils';
import { RedistributionWarnMessage } from '../shared';
import StudioTile from './StudioTile';

const onPdfExportDropdownStyle = { left: '-260px', top: '23px' };

const CONTACT_US_ITEM_CLASSNAME = 'contact-us-dropdown-item';
const DropdownStyleOverride = createGlobalStyle`
  .top-bar-dropdown-item {
    white-space: break-spaces;
  }
  .${CONTACT_US_ITEM_CLASSNAME} {
    color: ${GetColor.GreyScale.Grey50};
  }
  a.${CONTACT_US_ITEM_CLASSNAME} {
    color: ${GetColor.White};
    text-decoration: underline;
  }
`;
const contactUsLink = (
  <a
    className={CONTACT_US_ITEM_CLASSNAME}
    href={`mailto:${getTextThemeProvider().salesEmail}?subject=Report Lab Inquiry`}
  >
    contact us
  </a>
);

export const PdfExportTile = () => {
  const { onPdfExport, isFetchingNewViewRef, isCheckingDuplicateReportName } = useContext(StudioContext);
  const isReport = useRecoilValue(isReportState);

  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [showFinalConfirmationModal, openFinalConfirmationModal, closeFinalConfirmationModal] = useModal();

  const onPdfExportInternal = useCallback(async () => {
    setIsExportingPdf(true);
    await onPdfExport(true);
    setIsExportingPdf(false);
  }, [onPdfExport]);

  const pdfExportOptions = usePdfExportOptions({ onPdfExportInternal, openFinalConfirmationModal });

  return (
    <>
      <DropdownStyleOverride />
      <StudioTile
        header
        prefix="far"
        type="file-pdf"
        tooltip={isReport || pdfExportOptions ? 'PDF Export' : 'PDF Export (internal use only)'}
        onClick={pdfExportOptions ? undefined : onPdfExportInternal}
        options={pdfExportOptions}
        disabled={isFetchingNewViewRef.current || isExportingPdf || isCheckingDuplicateReportName}
        bodyDirection={TooltipBodyDirection.Left}
        dropdownStyle={onPdfExportDropdownStyle}
      />

      {showFinalConfirmationModal && (
        <ConfirmationModal
          header="PDF Export"
          subhead="What’s the difference between a Draft and Final?"
          text={
            <div>
              <div style={{ marginBottom: 40 }}>
                {`DRAFT - PDF is intended for internal purposes and is not retained in ${getAppTitle()}’s Document Archive.
                 An “Internal Use Only” watermark will appear on all documents.`}
              </div>
              <div>
                {`FINAL - PDF is intended for external distribution. Watermarks will be removed.
                All Final documents will be stored in ${getAppTitle()}’s Document Archive upon export. `}
              </div>
            </div>
          }
          onProceed={() => closeFinalConfirmationModal()}
          proceedLabel="Close"
        />
      )}
    </>
  );
};

function usePdfExportOptions({
  onPdfExportInternal,
  openFinalConfirmationModal,
}: {
  onPdfExportInternal: () => Promise<void>;
  openFinalConfirmationModal: () => void;
}) {
  const { onPdfExport } = useContext(StudioContext);
  const isReport = useRecoilValue(isReportState);

  const hasReportLab = useHasFF('studio_report_editor');
  const hasDisabledExport = useHasFF('disable_exports_ff');
  const hasVennBrandedWatermark = useHasFF('venn_branded_watermark_ff');

  const disableFinalExport = useIsFinalExportDisabled();
  if (isReport) {
    return [
      { label: 'Draft', key: 'Draft', disabled: false, onClick: () => onPdfExport(true) },
      {
        label: 'Final',
        key: 'Final',
        tooltip: disableFinalExport ? <RedistributionWarnMessage showLink={false} /> : undefined,
        disabled: disableFinalExport,
        onClick: () => onPdfExport(false),
      },
      {
        label: "What's the difference",
        key: "What's the difference",
        topBorder: true,
        disabled: false,
        onClick: openFinalConfirmationModal,
      },
    ];
  }

  const showStudioExportOptions = !hasReportLab && (hasDisabledExport || hasVennBrandedWatermark);
  if (showStudioExportOptions) {
    return [
      {
        icon: 'file-chart-column',
        key: 'PDF Export (internal use only)',
        label: 'PDF Export (internal use only)',
        onClick: onPdfExportInternal,
      },
      {
        icon: 'file-chart-column',
        key: 'Contact Us',
        label: <>Please {contactUsLink} for enhanced reporting and exporting options.</>,
        className: CONTACT_US_ITEM_CLASSNAME,
        topBorder: true,
        disabled: false,
        onClick: noop,
      },
    ];
  }

  return undefined;
}

function useIsFinalExportDisabled() {
  const { analysisView } = useContext(StudioContext);
  const analysisViewId = analysisView?.refId || analysisView?.id;

  const hasReportLabExportWatermarkFF = useHasFF('report_lab_export_watermark_ff');
  const isRedistributableLoadable = useRecoilValueWithDefault(
    analysisViewId ? redistributableStateIgnoringAllocatorPanel(analysisViewId) : constSelector(true),
    false,
  );

  return !isRedistributableLoadable && hasReportLabExportWatermarkFF;
}
