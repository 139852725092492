/*
 * Venn Platform API 1.0.0 Operations for "custodian"
 * Automatically generated file - do not modify!
 */

import type { Integration, IntegrationRequest, IntegrationSource } from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Cancel an integration. */
export async function cancelIntegration(integrationId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/custodian/integrations${buildQuery({ integrationId })}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of integration sources. */
export async function getIntegrationSourceList(signal?: AbortSignal): Promise<OperationResult<IntegrationSource[]>> {
  const endpoint = '/api/custodian/integrations/providers';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: IntegrationSource[] = (await response.json()) as IntegrationSource[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get existing integrations. */
export async function getIntegrations(signal?: AbortSignal): Promise<OperationResult<Integration[]>> {
  const endpoint = '/api/custodian/integrations';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Integration[] = (await response.json()) as Integration[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Request a new integration. */
export async function createIntegration(
  body?: Partial<IntegrationRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/custodian/integrations';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
