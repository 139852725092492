import type { History } from 'history';
import { omit } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, ThemeContext } from 'styled-components';
import type { Portfolio } from 'venn-api';
import { QUICKLINKS_STORAGE_KEY } from 'venn-api';
import type { SearchMenuItem } from 'venn-components';
import {
  CreatePortfolioModalWrapper,
  DataUploaderModalWrapper,
  DataUploaderMode,
  InviteTeamModal,
  LibraryLinkFooter,
  PortfoliosContext,
  SearchMenuBar,
  SearchMenuColumn,
  SkipToContent,
  StudioPrintSettingsContext,
  UserContext,
} from 'venn-components';
import {
  BrandLogo,
  GetColor,
  Icon,
  Notifications,
  NotificationType,
  SIDE_NAV_LINK_HEIGHT,
  SIDE_NAV_WIDTH,
  SideNav,
  Tooltip,
  TooltipPosition,
  TOP_NAV_HEIGHT,
  TopNav,
  ZIndex,
} from 'venn-ui-kit';
import {
  analyticsService,
  focusUniversalSearch,
  getAnalysisPath,
  getAnalysisPathForPortfolio,
  getAnalysisViewUrl,
  getBlankStudio,
  Hotkeys,
  LibraryItemType,
  LibraryTab,
  navigateToLibrary,
  navigateToManageDataPage,
  navigateToStudioView,
  Routes,
  SpecialCssClasses,
  universalSearchClass,
  useHasFF,
  useHotkeys,
} from 'venn-utils';
import SlideoutMenu from '../../../../../studio-page/components/studio-slideout-menu/SlideoutMenu';
import AdminSwitchOrg from './AdminSwitchOrg';
import BottomLinks from './BottomLinks';
import ContextSwitcher from './ContextSwitcher';
import type { BaseTrackingOpts, NavActions } from './shellNavigationConfigInitializer';
import { navigationConfigInitializer, navigationConfigInitializerV1 } from './shellNavigationConfigInitializer';
import ShellNavigationLink, { TooltipContent, TOP_ICON_WIDTH } from './ShellNavigationLink';

interface ShellNavigationProps {
  history: History;
}

const StyledSideNav = styled(SideNav)`
  background-color: ${GetColor.Black};
  height: 100%;
  z-index: ${ZIndex.Navigation};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate3d(0, 0, 0);
  position: relative;
  overflow-y: auto;
  @media print {
    display: none;
  }
  width: 70px;
`;

const StyledLogo = styled.div<{ showTopNav?: boolean }>`
  display: flex;
  align-items: center;
  height: ${({ showTopNav }) => (showTopNav ? '100%' : `${SIDE_NAV_LINK_HEIGHT}px`)};
  ${({ showTopNav }) => showTopNav && `width: ${SIDE_NAV_WIDTH}px;`}
  padding: 10px 20px;

  &:hover,
  &:focus {
    background-color: ${GetColor.NavigationBarColor.ActiveBackground};
  }

  ${({ showTopNav }) =>
    (!showTopNav &&
      `
    justify-content: center;
    padding: 10px 0 10px 0;
  `) ||
    ''};
`;

const StyledNavigationLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const onLogoClick = () => {
  analyticsService.navigationTriggered({
    location: 'navigation bar',
    itemType: 'link',
    userIntent: 'navigate',
    destinationPageTitle: 'Home',
  });
};

const SEARCH_COLUMNS = [
  SearchMenuColumn.CCY,
  SearchMenuColumn.IDENTIFIER,
  SearchMenuColumn.TYPE,
  SearchMenuColumn.AVAILABLE_DATES,
  SearchMenuColumn.LAST_UPDATED,
];

const ShellNavigationBar = ({ history }: ShellNavigationProps) => {
  const hasContextSwitching = useHasFF('context_switching');
  const hasStudioFF = useHasFF('studio_ff');
  const hasStudioReportEditor = useHasFF('studio_report_editor');
  const hasPrivatesFF = useHasFF('private_analytics');

  const mounted = useRef(false);
  const { settings, updateSettings, hasPermission } = useContext(UserContext);
  const { Colors } = useContext(ThemeContext);
  const { demoPortfolio, masterPortfolio } = useContext(PortfoliosContext);
  const [studioSlideout, setStudioSlideout] = useState<boolean>();
  const [reportLabSlideout, setReportLabSlideout] = useState<boolean>();
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const { addDisclosurePage } = useContext(StudioPrintSettingsContext);
  const { currentContext } = useContext(UserContext);

  const [invitationOpen, setInvitationOpen] = useState(false);
  const [lastTimeBeforeUpdate, setLastTimeBeforeUpdate] = useState<number>();
  const [createPortfolioOpen, setCreatePortfolioOpen] = useState(false);

  const onSearchSelect = useCallback(
    ({ value, category, viewInfo, helpArticle }: SearchMenuItem) => {
      switch (category) {
        case 'article':
          if (helpArticle) {
            window.open(helpArticle.url, '_blank');
          }
          return;
        case 'view':
          if (viewInfo) {
            history.push(getAnalysisViewUrl(viewInfo));
          }

          return;
        default:
          if (value) {
            if (!value.private) {
              history.push(getAnalysisPath(value.superType, value.id));
            } else {
              const subject =
                value.superType === 'private-investment'
                  ? { privateFundId: value.privateFund?.id }
                  : { privatePortfolioId: value.privatePortfolio?.id };
              navigateToManageDataPage(history, subject, 'Library', false);
            }
          }
      }
    },
    [history],
  );

  const onUploadClick = useCallback(() => {
    setUploaderOpen(true);
    setLastTimeBeforeUpdate(Date.now());
  }, []);

  const onCreatePortfolioClick = useCallback(() => {
    if (masterPortfolio) {
      setCreatePortfolioOpen(true);
    } else {
      history.push(Routes.CREATE_PORTFOLIO);
    }
  }, [history, masterPortfolio]);

  const onInvitationClick = useCallback(() => {
    setInvitationOpen(true);
  }, []);

  const onOpenPortfolioLabClick = useCallback(
    (baseTrackingOpts: BaseTrackingOpts) => {
      if (history.location?.pathname?.startsWith(Routes.PORTFOLIO_LAB_PATH)) {
        // If we are already on the portfolio lab page don't trigger a nav change.
        return;
      }

      history.push(Routes.PORTFOLIO_LAB_PATH);
      analyticsService.navigationTriggered({
        ...baseTrackingOpts,
        destinationPageTitle: 'Portfolio Lab',
      });
    },
    [history],
  );

  const toggleStudioSlideout = useCallback(() => {
    setStudioSlideout((prev) => !prev);
  }, []);

  const toggleReportLabSlideout = useCallback(() => {
    setReportLabSlideout((prev) => !prev);
  }, []);

  const navActions: NavActions = {
    openUploaderModal: onUploadClick,
    openCreatePortfolioModal: onCreatePortfolioClick,
    openInvitationModal: onInvitationClick,
    openPortfolioLab: onOpenPortfolioLabClick,
    toggleStudioSlideout,
    toggleReportLabSlideout,
  };

  const onCompleteUploader = useCallback(
    (mode: DataUploaderMode, uploadedFundIds?: string[]) => {
      Notifications.notify('Upload Successfully Completed!', NotificationType.SUCCESS);
      setUploaderOpen(false);
      navigateToLibrary(history, {
        tab: mode === DataUploaderMode.Privates ? LibraryTab.PrivateAssets : LibraryTab.ReturnsData,
        selectedIds: uploadedFundIds ?? [],
        selectedFilters: {
          itemType: LibraryItemType.UPLOAD,
          quickFilters: [],
          tags: [],
        },
        lastTimeBeforeUpdate,
      });
    },
    [history, lastTimeBeforeUpdate],
  );

  const onSubmitCreatePortfolio = useCallback(
    (portfolio: Portfolio, createdFromMaster: boolean) => {
      setCreatePortfolioOpen(false);
      history.push(getAnalysisPathForPortfolio(portfolio.id, undefined, createdFromMaster));
    },
    [history],
  );
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useHotkeys((e) => {
    e.preventDefault(); // make sure hotkey isn't typed into the search input
    focusUniversalSearch();
  }, Hotkeys.UNIVERSAL_SEARCH);

  const configInitializer =
    hasStudioFF || hasStudioReportEditor ? navigationConfigInitializerV1 : navigationConfigInitializer;
  const config = configInitializer(navActions, history);
  const logoTooltip = <TooltipContent>Home</TooltipContent>;
  const showQuickLinks = settings?.user?.[QUICKLINKS_STORAGE_KEY] ?? false;
  const filterAvailableQuicklinks = config.quickLinks.filter(
    (item) => !item.requiredPermission || hasPermission(item.requiredPermission),
  );

  const topNav = (
    <StyledTopNav className={SpecialCssClasses.HideInReports}>
      <TopLeftNavGroup>
        <HomeTooltip
          usePortal
          showShadow
          content={logoTooltip}
          position={TooltipPosition.Right}
          background={Colors.Black}
        >
          <Link
            onMouseEnter={() => setIsLogoAnimated(true)}
            onMouseLeave={() => setIsLogoAnimated(false)}
            onClick={onLogoClick}
            to={Routes.HOME_PATH}
            className="qa-navigate-to-home"
          >
            <StyledLogo showTopNav>
              <BrandLogo isAnimated={isLogoAnimated} animateOnHover />
            </StyledLogo>
          </Link>
        </HomeTooltip>
        <SearchMenuWrapper>
          {hasContextSwitching && <ContextSwitcher />}
          <SearchMenuBar
            className={universalSearchClass}
            onSelected={onSearchSelect}
            location="universalSearch"
            autofocus={false}
            defaultMenuIsOpen={false}
            showQuickFilters
            footer={LibraryLinkFooter}
            columns={SEARCH_COLUMNS}
            value={null} // remove the concept of a selected item for this search bar
            darkPlaceholder
            clearQueryOnBlur
            fixedMenuWidth
            canSearchEverything
            privateAssetSearchMode={hasPrivatesFF ? 'ALL' : 'PUBLIC_ONLY'}
          />
        </SearchMenuWrapper>
      </TopLeftNavGroup>
      <TopRightNavGroup enableArchive={hasStudioReportEditor}>
        <BottomLinks />
      </TopRightNavGroup>
    </StyledTopNav>
  );

  return (
    <>
      <SkipToContent />
      <>
        {topNav}
        <StyledSideNav className={`side-navbar ${SpecialCssClasses.HideInReports}`}>
          <div>
            <StyledNavigationLinks>
              <StaticLinksWrapper>
                {config.top.map((navigationGroup) => {
                  return navigationGroup.filter(
                    (item) => !item.requiredPermission || hasPermission(item.requiredPermission),
                  ).length > 0 ? (
                    <StyledShellNavigationGroup key={`group-${navigationGroup[0].navId}`}>
                      {navigationGroup
                        .filter((item) => !item.requiredPermission || hasPermission(item.requiredPermission))
                        .map((navigationConfig) => (
                          <StyledShellNavigationLink
                            wrapperStyle={navigationConfig.wrapperStyle}
                            key={navigationConfig?.navId}
                            {...omit(navigationConfig, ['wrapperStyle'])}
                          />
                        ))}
                    </StyledShellNavigationGroup>
                  ) : null;
                })}
              </StaticLinksWrapper>
              {filterAvailableQuicklinks?.length !== 0 ? (
                <div>
                  <QuickLinksDropdown
                    className={`qa-quick-links ${showQuickLinks && 'qa-quick-links-open'}`}
                    onClick={() => {
                      updateSettings({ [QUICKLINKS_STORAGE_KEY]: !showQuickLinks });
                    }}
                  >
                    <Caret type={showQuickLinks ? 'caret-down' : 'caret-right'} />
                    <QuickLinksDropdownIcon type="plus" />
                  </QuickLinksDropdown>
                  <QuickLinkWrapper showQuickLinks={showQuickLinks}>
                    {filterAvailableQuicklinks.map((navigationConfig) => (
                      <QuickLink
                        key={navigationConfig?.navId}
                        {...navigationConfig}
                        tabIndex={showQuickLinks ? 0 : -1}
                      />
                    ))}
                  </QuickLinkWrapper>
                </div>
              ) : undefined}
              <AdminSwitchOrg />
            </StyledNavigationLinks>
          </div>
        </StyledSideNav>
      </>
      {uploaderOpen && (
        <DataUploaderModalWrapper
          mode={DataUploaderMode.Returns}
          onCancel={() => (mounted.current ? setUploaderOpen(false) : null)}
          onCompleteNavigate={onCompleteUploader}
        />
      )}
      {createPortfolioOpen && (
        <CreatePortfolioModalWrapper
          baseline={masterPortfolio || demoPortfolio}
          canCreateFromScratch
          onClose={() => (mounted.current ? setCreatePortfolioOpen(false) : null)}
          onSubmit={onSubmitCreatePortfolio}
          onPrivatePortfolioCreated={(portfolio) => {
            const newDocument = getBlankStudio(
              'TEARSHEET',
              addDisclosurePage,
              [{ privatePortfolioId: portfolio.id }],
              currentContext,
            );

            analyticsService.creatingNewStudios({
              source: 'add to studio library button',
              name: 'blank tearsheet',
            });
            navigateToStudioView(history, { newDocument, openAllocatorForPrivatePortfolio: portfolio });
          }}
        />
      )}
      {invitationOpen && <InviteTeamModal onCancel={() => setInvitationOpen(false)} />}
      <SlideoutMenu isOpen={!!studioSlideout} setIsOpen={setStudioSlideout} isReport={false} />
      <SlideoutMenu isOpen={!!reportLabSlideout} setIsOpen={setReportLabSlideout} isReport />
    </>
  );
};

export default ShellNavigationBar;

const StyledShellNavigationGroup = styled.div`
  background-color: ${GetColor.NavigationBarColor.InactiveBackground};
  padding: 10px 0;
`;

const StaticLinksWrapper = styled.div`
  & > ${StyledShellNavigationGroup} + ${StyledShellNavigationGroup} {
    margin-top: 7px;
  }
`;

const StyledShellNavigationLink = styled(ShellNavigationLink)<{ wrapperStyle?: string }>`
  ${({ wrapperStyle }) => wrapperStyle}
`;

const QuickLinksDropdown = styled.button`
  display: flex;
  align-items: center;
  color: ${GetColor.MidGrey2};
  padding-left: 13px;
  width: 100%;
  height: 50px;

  &:hover {
    color: ${GetColor.White};
  }
`;

interface QuickLinkWrapperProps {
  showQuickLinks: boolean;
}

const Caret = styled(Icon)`
  margin-right: 5px;
  width: 8px;
`;

const QuickLinkWrapper = styled.div<QuickLinkWrapperProps>`
  max-height: ${(props) => (props.showQuickLinks ? '180px' : '0')};
  overflow: ${(props) => (props.showQuickLinks ? 'visible' : 'hidden')};

  @keyframes open {
    from {
      overflow: hidden;
    }
    to {
      overflow: visible;
    }
  }

  ${(props) =>
    props.showQuickLinks &&
    css`
      animation: 0.5s open steps(1, end);
    `}
  transition: max-height 0.5s ease-in-out;
`;

const QuickLinksDropdownIcon = styled(Icon)`
  font-size: 20px;
  margin-right: 6px;
`;

const QuickLink = styled(ShellNavigationLink)`
  > div {
    margin: 0 auto;
    width: 85%;
  }

  i {
    font-size: 16px;
  }
`;

const HomeTooltip = styled(Tooltip)`
  height: auto;
`;

const TopNavGroup = styled.div`
  display: flex;
  align-items: center;
  z-index: ${ZIndex.Navigation};
`;

const TopLeftNavGroup = styled(TopNavGroup)`
  flex-grow: 1;
`;

const TopRightNavGroup = styled(TopNavGroup)<{ enableArchive?: boolean }>`
  width: ${({ enableArchive }) => (enableArchive ? TOP_ICON_WIDTH * 4 : TOP_ICON_WIDTH * 3)}px;
`;

const StyledTopNav = styled(TopNav)`
  background-color: ${GetColor.Black};
  height: ${TOP_NAV_HEIGHT}px;
  width: 100vw;
  z-index: ${ZIndex.Navigation};
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const SearchMenuWrapper = styled.div`
  display: flex;
  column-gap: 30px;
  align-items: center;
  flex: 1;
  min-width: 900px;
  max-width: 1300px;
  @media (max-width: 900px) {
    min-width: calc(100vw - 300px);
  }
  margin-left: 9px;

  > div:first-child {
    width: 400px;
  }

  > div:last-child {
    flex: 1;
  }
`;
