import { atom, atomFamily, DefaultValue, selector, selectorFamily, waitForAll } from 'recoil';
import type { FrequencyEnum } from 'venn-api';
import type { ExportMetaData } from 'venn-components';
import { getExportRollingPeriod, isPrivatesBlock, isPublicPrivateAssetGrowthBlock } from 'venn-utils';
import { allBlockIdsState } from '../grid';
import type { BlockId, StudioExcelFunction } from '../types';
import { blockBenchmarkConfig } from './benchmark';
import { blockContributionToPercentage, blockMetrics, blockRollingYears } from './blockConfig';
import { blockSettings } from './blockSettings';
import { blockDateRange } from './dateRange';
import { blockSelectedPeerGroupId } from './peerGroups';

export const exportingToExcel = atom<boolean>({
  key: 'exportingToExcel',
  default: false,
});

export const blockExcelFunction = atomFamily<StudioExcelFunction, BlockId>({
  key: 'blockExcelFunction',
  default: undefined,
});

export const viewExcelFunctions = selector<StudioExcelFunction[]>({
  key: 'viewExcelFunctions',
  get: ({ get }) => {
    const ids = get(allBlockIdsState);
    return get(waitForAll(ids.map((id) => blockExcelFunction(id))));
  },
  set: ({ set, get }, value) => {
    get(allBlockIdsState).forEach((blockId, idx) =>
      set(blockExcelFunction(blockId), value instanceof DefaultValue ? value : value[idx]),
    );
  },
});

export const blockExportMetadata = selectorFamily<ExportMetaData, BlockId>({
  key: 'blockExportMetadata',
  get:
    (id) =>
    ({ get }) => {
      const contributionToPercentage = get(blockContributionToPercentage(id));
      const selectedMetrics = get(blockMetrics(id));
      const rollingYears = get(blockRollingYears(id));
      const { relative, type } = get(blockBenchmarkConfig(id));
      const dateRange = get(blockDateRange(id));
      const settings = get(blockSettings(id));
      const isPrivate = isPrivatesBlock(settings.customBlockType);
      const isPublicPrivateAssetGrowth = isPublicPrivateAssetGrowthBlock(settings.customBlockType);
      const peerGroupName = get(blockSelectedPeerGroupId(id))?.categoryName;

      if (isPrivate) {
        return {
          frequency: 'QUARTERLY',
          customBlockType: settings.customBlockType,
        };
      }

      let frequency: FrequencyEnum | undefined;

      if (isPublicPrivateAssetGrowth) {
        frequency = 'QUARTERLY';
      }

      return {
        startDate: dateRange?.range.from,
        endDate: dateRange?.range.to,
        frequency: frequency ?? dateRange?.frequency,
        relativeToBenchmark: relative,
        benchmarkType: type,
        contributionToPercentage,
        rollingPeriod: getExportRollingPeriod(selectedMetrics, rollingYears),
        customBlockType: settings.customBlockType,
        simulationStartDate: isPublicPrivateAssetGrowth ? dateRange?.range?.to : undefined,
        peerGroupName,
      };
    },
});
