import type { ColDef } from 'ag-grid-community';
import React from 'react';
import type { PrivatesAnalysisResponse } from 'venn-api';
import { useBlockId } from '../../contexts/BlockIdContext';
import { METADATA_GREY_BG_CLASS } from '../../customAnalysisContants';
import { useCommonGridProps } from '../../logic/columnUtils';
import { DATA_TYPE_EXCEL_STYLES } from '../../logic/customBlockUtils';
import { useIsBlockGridCompact } from '../../logic/useIsBlockGridCompact';
import usePrivatesDataGrid from '../../logic/usePrivatesDataGrid';
import ExportableGrid from './ExportableGrid';

type PrivateCashFlowGridProps = Readonly<{
  data: PrivatesAnalysisResponse;
  isExportable: boolean;
}>;

const gridOverrides: ColDef = {
  cellClassRules: {
    [METADATA_GREY_BG_CLASS]: ({ data }) => data.isMetadata,
  },
};

const PrivateCashFlowGrid = ({ data, isExportable }: PrivateCashFlowGridProps) => {
  const blockId = useBlockId();
  const { rowData, columnDefs } = usePrivatesDataGrid(data);
  const isCompact = useIsBlockGridCompact();

  const commonGridProps = useCommonGridProps(gridOverrides);

  return (
    <ExportableGrid
      {...commonGridProps}
      exportable={isExportable}
      selectedRefId={blockId}
      rowData={rowData}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      isCompact={isCompact}
      tooltipShowDelay={1200}
      suppressRowVirtualisation
    />
  );
};

export default PrivateCashFlowGrid;
