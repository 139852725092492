import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import RangePicker from '../range-picker/RangePicker';
import TextField from '../../text-field/TextField';
import { Label } from '../../typography';
import ButtonBase from '../../button/Button';
import type { DatePickerProps } from '../types';
import { getTypeFromRange } from './logic';
import useNonOverlapping from './useNonOverlapping';
import { CheckboxWrapper } from '../../checkbox-wrapper';

const GRANULARITY = 'year';
const YearPicker = ({
  value,
  maxRange,
  onUpdatePeriod,
  onApply,
  onUpdateDate,
  onCancel,
  options,
  allowNonConstraint,
  checkedNonConstraint,
  onToggleNonConstraint,
  hideActions = false,
  autoFocus = true,
}: DatePickerProps) => {
  const fromInputRef = useRef<HTMLInputElement>(null);

  const [from, setFrom] = useState<number | undefined>(value?.from);
  const [to, setTo] = useState<number | undefined>(value?.to);
  const initialFromRef = useRef(value?.from);
  const initialToRef = useRef(value?.to);
  const onUpdateDateRef = useRef(onUpdateDate);
  onUpdateDateRef.current = onUpdateDate;
  useEffect(() => {
    if (from !== initialFromRef.current || to !== initialToRef.current) {
      onUpdateDateRef.current?.({ from, to });
    }
  }, [from, to]);

  const range = useMemo(
    () => getTypeFromRange(GRANULARITY, { from, to, period: value?.period }, maxRange),
    [from, to, maxRange, value?.period],
  );

  const { isValid, infoMessage } = useNonOverlapping(maxRange, !!allowNonConstraint, !!checkedNonConstraint, from, to);

  const handleChangeFrom = useCallback((fromYear: number) => {
    setFrom(moment.utc().set('year', fromYear).startOf('year').valueOf());
  }, []);
  const handleChangeTo = useCallback((toYear: number) => {
    setTo(moment.utc().set('year', toYear).startOf('year').valueOf());
  }, []);

  const handleApply = useCallback(() => {
    onApply?.({ from, to });
  }, [from, to, onApply]);

  // Focus the input on mount
  useEffect(() => {
    if (!autoFocus) {
      return;
    }
    fromInputRef.current?.focus();
    fromInputRef.current?.select();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update local state if props are changing
  useEffect(() => {
    setFrom(value?.from);
    setTo(value?.to);
  }, [value?.from, value?.to]);

  const fromYear = useMemo(() => (from ? moment.utc(from).year() : undefined), [from]);
  const toYear = useMemo(() => (to ? moment.utc(to).year() : undefined), [to]);

  return (
    <Container>
      {maxRange && onUpdatePeriod && (
        <RangePicker
          range={maxRange}
          disposition="vertical"
          value={range ?? 'full'}
          onChange={onUpdatePeriod}
          options={options}
          granularity={GRANULARITY}
          checkedNonConstraint={checkedNonConstraint}
        />
      )}
      {allowNonConstraint && (
        <CheckboxWrapper checked={checkedNonConstraint} onChange={() => onToggleNonConstraint?.()}>
          {infoMessage}
        </CheckboxWrapper>
      )}
      <Form>
        <Row>
          <Label htmlFor="fromYear">From:</Label>&nbsp;
          <TextField<number>
            id="fromYear"
            value={fromYear}
            type="number"
            innerRef={fromInputRef}
            className="qa-date-picker-year-from"
            onChange={handleChangeFrom}
          />
        </Row>
        <Row>
          <Label htmlFor="toYear">To:</Label>&nbsp;
          <TextField<number>
            id="toYear"
            value={toYear}
            type="number"
            className="qa-date-picker-year-to"
            onChange={handleChangeTo}
          />
        </Row>
        {!hideActions && (
          <Actions>
            <Button type="submit" dense dominant onClick={handleApply} disabled={!isValid}>
              Apply
            </Button>
            <Button dense onClick={onCancel}>
              Cancel
            </Button>
          </Actions>
        )}
      </Form>
    </Container>
  );
};

const Container = styled.div`
  width: 250px;
`;

const Form = styled.div`
  margin: 20px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  ${Label} {
    width: 40px;
  }
`;

const Button = styled(ButtonBase)`
  width: 100px;
  margin: 0;
`;

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export default YearPicker;
