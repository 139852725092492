import MarkdownTab from './MarkdownTab';
import ImagesTab from './ImagesTab';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockSettings } from 'venn-state';
import { AnalysisBlockTab } from './AnalysisBlockTab';
import ActionsSection from './sections/ActionsSection';

interface BlockTabsProps {
  selected: string;
}

/** Block-specific section for block configuration/settings editing. */
const BlockTabs = ({ selected }: BlockTabsProps) => {
  const settings = useRecoilValue(blockSettings(selected));

  const getConfigComponent = () => {
    switch (settings.customBlockType) {
      case 'PAGE_BREAK':
        return null;
      case 'MARKDOWN':
        return <MarkdownTab />;
      case 'IMAGE':
        return <ImagesTab selected={selected} />;
      default:
        return <AnalysisBlockTab selected={selected} />;
    }
  };
  const ConfigComponent = getConfigComponent();
  return (
    <>
      <ActionsSection selected={selected} />
      {ConfigComponent}
    </>
  );
};

export default BlockTabs;
