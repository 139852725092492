import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { GetColor, getItemLabel, Notifications, NotificationType } from 'venn-ui-kit';
import {
  ConfirmationModal,
  EditMode,
  SaveAnalysisViewModal,
  ShareModal,
  StudioContext,
  UserContext,
} from 'venn-components';
import { analyticsService, copyToClipboard, isStudioTemplate, Numbers, useModal } from 'venn-utils';
import { InlineInput } from '../pages/Shared';
import TopBarIcon from './TopBarIcon';
import type { TopBarDropdownMenuOption } from './TopBarDropdownMenu';
import { TOP_TOOLBAR_HEIGHT, TopBarColor } from './shared';
import StudioTile from './StudioTile';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  exportingToExcel,
  isReportState,
  PageLayout,
  reportPageLayout,
  reportZoom,
  selectedBlockIdState,
  selectedBlockSettings,
} from 'venn-state';
import { useRedistributableNotification } from '../../logic/useRedistributableNotification';
import { PdfExportTile } from './PdfExportTile';

type ConfirmationModalActionType = 'DELETE_BLOCK';

interface TopBarProps {
  openReportConfigModal?: () => void;
}

const TopBar = React.memo(function TopBar({ openReportConfigModal }: TopBarProps) {
  const [zoom, setZoom] = useRecoilState(reportZoom);
  const [layout, setLayout] = useRecoilState(reportPageLayout);
  const isExportingExcel = useRecoilValue(exportingToExcel);
  const { hasPermissionForResource, currentContext } = useContext(UserContext);
  const {
    hasUnsavedChange,
    analysisView,
    onSave: onSaveInput,
    onExcelExport,
    reportNameValue,
    onChangeReportName,
    onDeleteBlock,
    setAfterUnsavedChangesAction,
    onSaveAs,
    isCheckingDuplicateReportName,
    isDuplicateReportName,
    isFetchingNewViewRef,
    editMode,
    setEditMode,
  } = useContext(StudioContext);
  const isReport = useRecoilValue(isReportState);
  const selectedId = useRecoilValue(selectedBlockIdState);
  const blockSettingLoadable = useRecoilValueLoadable(selectedBlockSettings);
  const blockSetting =
    blockSettingLoadable.state === 'hasValue' && selectedId ? blockSettingLoadable.getValue() : undefined;
  const customBlockType = blockSetting?.customBlockType;

  useRedistributableNotification();

  const [isSaving, setIsSaving] = useState(false);

  const onSave = useCallback(async () => {
    setIsSaving(true);
    await onSaveInput?.();
    setIsSaving(false);
  }, [onSaveInput]);

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalActionType | undefined>(undefined);
  const onClose = useCallback(() => setConfirmationModal(undefined), []);

  const [subhead, text, onProceed] = useMemo(() => {
    if (!selectedId) {
      setConfirmationModal(undefined);
      return [undefined, undefined, undefined];
    }

    switch (confirmationModal) {
      case 'DELETE_BLOCK':
        return [
          'You are about to delete this block.',
          'The configuration for this block will be lost permanently.',
          () => {
            onDeleteBlock(selectedId);
            analyticsService.ctaClicked({
              purpose: 'delete studio block',
              locationOnPage: `${customBlockType} block toolbar`,
            });
            onClose();
          },
        ];
      default:
        return [undefined, undefined, undefined];
    }
  }, [selectedId, confirmationModal, onDeleteBlock, customBlockType, onClose]);

  const [isOpenShareEmailModal, openShareEmailModal, closeShareEmailModal] = useModal();
  const [isOpenSaveAsModal, openSaveAsModal, closeSaveAsModal] = useModal();
  const isTemplate = isStudioTemplate(analysisView?.analysisViewType);

  const canSave = hasPermissionForResource(isTemplate ? 'STUDIO_CREATE_TEMPLATE' : 'EDIT_ANALYSIS_VIEW', {
    ownerContextId: analysisView?.ownerContextId ?? currentContext,
  });

  // Save as would use current context as default, check if has permission
  const canSaveAs = hasPermissionForResource('CREATE_STUDIO_VIEW', {
    ownerContextId: currentContext,
  });

  const handleCopyLink = async () => {
    const response = await copyToClipboard(window.location.href);
    if (response) {
      Notifications.notify('Link copied', NotificationType.SUCCESS);
      analyticsService.ctaClicked({
        purpose: isReport ? 'copy Report Lab Link' : 'copy studio link',
        locationOnPage: isReport ? 'Report Lab Dropdown' : 'studio share dropdown',
      });
    } else {
      Notifications.notify('Problem copying link', NotificationType.ERROR);
    }
  };

  const handleShareAction = (proceedCallback: () => void) => {
    if (hasUnsavedChange) {
      setAfterUnsavedChangesAction({
        proceedCallback,
        cancelCallback: undefined,
        hideDiscardBtn: !analysisView?.id,
      });
    } else {
      proceedCallback();
    }
  };

  const saveDropDownMenuOptions: TopBarDropdownMenuOption[] = [
    {
      iconPrefix: 'far',
      label: 'Save',
      key: 'Save',
      disabled:
        !hasUnsavedChange ||
        isSaving ||
        isDuplicateReportName ||
        isCheckingDuplicateReportName ||
        isFetchingNewViewRef.current ||
        !canSave,
      onClick: onSave,
    },
    {
      iconPrefix: 'far',
      label: 'Save As',
      key: 'Save As',
      onClick: openSaveAsModal,
      disabled: isFetchingNewViewRef.current || !analysisView?.id || !canSaveAs,
    },
  ];

  const shareDropDownMenuOptions: TopBarDropdownMenuOption[] = [
    {
      iconPrefix: 'far',
      icon: 'envelope',
      label: 'Email',
      key: 'Email',
      onClick: () => handleShareAction(openShareEmailModal),
    },
    {
      iconPrefix: 'far',
      icon: 'link',
      label: 'Copy URL',
      key: 'Copy URL',
      onClick: () => handleShareAction(handleCopyLink),
    },
  ];

  const onZoom = useCallback(
    (zoom: number, ratio: number) => {
      analyticsService.ctaClicked({
        purpose: `zoom ${ratio > 0 ? 'in' : 'out'}`,
        locationOnPage: 'studio topbar',
      });
      setZoom(zoom + ratio);
    },
    [setZoom],
  );

  const onLayoutChange = useCallback(
    (layout: PageLayout, layoutOption: string) => {
      analyticsService.ctaClicked({
        purpose: `change layout to ${layoutOption}`,
        locationOnPage: 'studio topbar',
      });
      setLayout(layout);
    },
    [setLayout],
  );
  const onZoomOut = useCallback(() => onZoom(zoom, -0.1), [onZoom, zoom]);
  const onZoomIn = useCallback(() => onZoom(zoom, 0.1), [onZoom, zoom]);
  const onHorizontalLayout = useCallback(() => onLayoutChange(PageLayout.HORIZONTAL, 'horizontal'), [onLayoutChange]);
  const onColumnLayout = useCallback(() => onLayoutChange(PageLayout.COLUMNS, 'columns'), [onLayoutChange]);
  const onVerticalLayout = useCallback(() => onLayoutChange(PageLayout.VERTICAL, 'vertical'), [onLayoutChange]);
  const onPreviewTemplate = useCallback(() => setEditMode(EditMode.DEFAULT), [setEditMode]);
  const onEditTemplate = useCallback(() => setEditMode(EditMode.TEMPLATE), [setEditMode]);

  return (
    <>
      <TopBarWrapper editMode={editMode} isReport={isReport}>
        <LeftToolBars>
          <TopBarIcon
            prefix="fal"
            type="gear"
            tooltip="Setup"
            onClick={openReportConfigModal}
            disabled={isFetchingNewViewRef.current}
          />
          {isTemplate &&
            (editMode === EditMode.TEMPLATE ? (
              <TopBarIcon
                prefix="fal"
                type="play"
                tooltip="Live Preview"
                onClick={onPreviewTemplate}
                disabled={isFetchingNewViewRef.current}
              />
            ) : (
              <TopBarIcon
                prefix="fal"
                type="pen-to-square"
                tooltip="Edit Template"
                onClick={onEditTemplate}
                disabled={isFetchingNewViewRef.current}
              />
            ))}
          <TopBarIcon
            type="save"
            tooltip="Save Report"
            disabled={isFetchingNewViewRef.current || isSaving}
            options={saveDropDownMenuOptions}
            menu
          />
          <TopBarIcon
            type="share-alt"
            tooltip="Share Report"
            options={shareDropDownMenuOptions}
            disabled={isFetchingNewViewRef.current}
            menu
          />

          <LabelDateStack>
            <CenteredDocumentLabelWrapper>
              <SavedViewNameWrapper>
                {isTemplate && (
                  <TemplateLabelWrapper isReport={isReport}>
                    {getItemLabel(analysisView?.analysisViewType ?? 'TEARSHEET_TEMPLATE')}
                  </TemplateLabelWrapper>
                )}
                <DocumentNameLabel
                  $inPrintMode={false}
                  placeholder="Untitled Analysis"
                  value={reportNameValue}
                  onChange={onChangeReportName}
                  $isDuplicateReportName={isDuplicateReportName}
                  disabled={isFetchingNewViewRef.current}
                  $editMode={editMode}
                />
              </SavedViewNameWrapper>
            </CenteredDocumentLabelWrapper>
          </LabelDateStack>
        </LeftToolBars>
        <RightToolBars>
          {isReport && (
            <>
              <ToolBarGroup>
                <Label>Zoom</Label>
                <TileGroup editMode={editMode}>
                  <StudioTile
                    type="search-minus"
                    tooltip="Zoom Out"
                    header
                    disabled={zoom < 0.15 || isFetchingNewViewRef.current}
                    onClick={onZoomOut}
                  />
                  <StudioTile
                    type="search-plus"
                    tooltip="Zoom In"
                    header
                    disabled={zoom > 1.95 || isFetchingNewViewRef.current}
                    onClick={onZoomIn}
                  />
                </TileGroup>
                <StyledZoomValue>{Numbers.safeFormatPercentage(zoom, 0)}</StyledZoomValue>
              </ToolBarGroup>
              <ToolBarGroup>
                <Label>Layout</Label>
                <TileGroup editMode={editMode}>
                  <StudioTile
                    type="border-center-v"
                    tooltip="Horizontal"
                    header
                    disabled={layout === PageLayout.HORIZONTAL || isFetchingNewViewRef.current}
                    onClick={onHorizontalLayout}
                  />
                  <StudioTile
                    type="columns"
                    tooltip="Side by Side"
                    header
                    disabled={layout === PageLayout.COLUMNS || isFetchingNewViewRef.current}
                    onClick={onColumnLayout}
                  />
                  <StudioTile
                    type="border-center-h"
                    tooltip="Vertical"
                    header
                    disabled={layout === PageLayout.VERTICAL || isFetchingNewViewRef.current}
                    onClick={onVerticalLayout}
                  />
                </TileGroup>
              </ToolBarGroup>
            </>
          )}
          <ToolBarGroup>
            <Label>Export</Label>
            <TileGroup editMode={editMode}>
              <StudioTile
                header
                prefix="far"
                type="file-excel"
                tooltip="Excel Export"
                onClick={onExcelExport}
                disabled={isFetchingNewViewRef.current || isExportingExcel}
              />
              <PdfExportTile />
            </TileGroup>
          </ToolBarGroup>
        </RightToolBars>
      </TopBarWrapper>
      {confirmationModal && text && onProceed && (
        <ConfirmationModal destructive text={text} subhead={subhead} onCancel={onClose} onProceed={onProceed} />
      )}
      {isOpenShareEmailModal && (
        <ShareModal
          onClose={closeShareEmailModal}
          shareMetaData={{ savedViewName: analysisView?.name }}
          closeOnOutsideClick
        />
      )}
      {isOpenSaveAsModal && (
        <SaveAnalysisViewModal
          mode="SAVE_AS"
          defaultName={`${analysisView?.name} (Copy)`}
          onSubmit={onSaveAs}
          onClose={closeSaveAsModal}
        />
      )}
    </>
  );
});

export const TopBarWrapper = styled.div<{ editMode: EditMode; isReport?: boolean }>`
  height: ${TOP_TOOLBAR_HEIGHT};
  background: ${({ editMode, isReport }) =>
    isReport ? TopBarColor[editMode].ReportBackground : TopBarColor[editMode].Background};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ToolBarGroup = styled.div`
  display: flex;
  align-items: center;

  height: 50px;
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 4px;
  column-gap: 5px;
`;

const LeftToolBars = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const RightToolBars = styled(LeftToolBars)`
  justify-content: right;
`;

const DocumentNameLabel = styled(InlineInput)<{ $isDuplicateReportName?: boolean; $editMode: EditMode }>`
  padding-left: 5px;
  padding-right: 25px;
  overflow: hidden;
  font-weight: bold;
  font-size: 20px;
  color: ${GetColor.White};

  > input {
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 1px;
  }

  input:disabled {
    color: ${({ $editMode }) => TopBarColor[$editMode].DarkGrey};
  }

  ${({ $isDuplicateReportName }) =>
    $isDuplicateReportName &&
    css`
      > input {
        color: ${GetColor.Error};
        outline: 1px solid ${GetColor.Error};
        :hover {
          outline: 1px solid ${GetColor.Alert};
        }
        :focus {
          outline: 1px solid ${GetColor.Alert};
        }
      }
    `}
`;

const Label = styled.span`
  height: 16px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  color: ${GetColor.White};
  margin-right: 10px;
`;

const TileGroup = styled.div<{ editMode: EditMode }>`
  display: flex;
  border: 1px solid ${({ editMode }) => TopBarColor[editMode].Border};
  box-sizing: border-box;
  border-radius: 6px;
`;

const StyledZoomValue = styled(Label)`
  width: 38px;
  text-align: right;
`;

const CenteredDocumentLabelWrapper = styled.div`
  color: ${GetColor.White};
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
`;

const SavedViewNameWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
`;

const TemplateLabelWrapper = styled.div<{ isReport?: boolean }>`
  /* gold-dark */
  background: ${(props) =>
    props.isReport
      ? TopBarColor[EditMode.TEMPLATE].ReportActiveBackground
      : TopBarColor[EditMode.TEMPLATE].ActiveBackground};
  border: 1px solid ${GetColor.White};
  color: ${GetColor.White};
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 8px;
  padding: 5px;
  height: 22px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
`;

const LabelDateStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 1024px) {
    margin-left: 25px;
  }
`;

export default TopBar;
