import { type DeepMapTo, logMessageToSentry } from 'venn-utils';
import type { HoldingsBreakdownTypeEnum } from 'venn-api';
import type { ThemeProvider } from './themes';
import tinycolor from 'tinycolor2';
import type { CustomColors } from './customColors';
import type React from 'react';

export interface Typography {
  fontFamily: string;
}

// TODO: why is this duplicated with Theme in themes.ts
export interface Theme {
  Typography: Typography;
  Colors: VennColors;
  Schemes: Schemes;
  customColors: CustomColors | undefined;
  setCustomColors: React.Dispatch<React.SetStateAction<CustomColors | undefined>>;
  setDebugColors: React.Dispatch<React.SetStateAction<VennColors | undefined>>;
}

export interface FactorColorContext {
  mainColor: string;
  comparisonColor: string;
  labelColor: string;
}

export interface BarChartColors {
  mainColor: string;
  secondaryColor: string;
  comparisonColor: string;
  lineColor: string;
}

export interface LineChartColors {
  mainColor: string;
  secondaryColor: string;
  comparisonColor: string;
  lineColor: string;
}

/** These colors should be used only in the Navigation Bar */
export interface NavigationBarColors {
  Active: string;
  ActiveBackground: string;
  ActiveLighter: string;
  InactiveBackground: string;
  ShimmerBackground: string;
}

export type DataBarColors = {
  LightGold: string;
  LightPaleBlue: string;
  LightDarkBlue: string;
};

export type DataLineColors = {
  Gold: string;
  PaleGold: string;
  Yellow: string;
  Green: string;
  PaleBlue: string;
  DarkBlue: string;
  Pink: string;
  DeepGreen: string;
};

export interface DivergingColors {
  /**
   * A5 is used for low values in chart UI that has a gradient or progressive color system.
   * By default, A5 is the warmset-toned (red) color. It is used for high numbers.
   */
  A5: string;
  A4: string;
  A3: string;
  A2: string;
  A1: string;
  MID: string;
  B1: string;
  B2: string;
  B3: string;
  B4: string;
  /**
   * B5 is used for high values in chart UI that has a gradient or progressive color system.
   * By default, B5 is the coolest-toned (blue) color. It is used for high numbers.
   */
  B5: string;
}

/** These colors exist only due to legacy designs and should not be used in any new implementations moving forward. */
export interface UnsafeColors {
  LightBlue: string;
  Separator: string;
  Silver: string;
  Azure: string;
  Steel: string;
  Red: string;
}

export interface GreyScale {
  Grey10: string;
  Grey20: string;
  Grey30: string;
  Grey40: string;
  Grey50: string;
  Grey60: string;
  Grey70: string;
  Grey80: string;
  Grey90: string;
  Grey100: string;
}

export interface OptimizationColors {
  Positive: string;
  Negative: string;
}

interface PaletteColor {
  Main: string;
  Dark: string;
  Medium: string;
  Light: string;
}

export interface VennColors {
  // General colors
  Primary: PaletteColor;
  PaleTeal: string;
  WhiteGrey: string;
  PaleGrey: string;
  LightGrey: string;
  LightGrey2: string;
  Grey: string;
  MidGrey1: string;
  MidGrey2: string;
  HintGrey: string;
  DarkGrey: string;
  DarkGrey2: string;
  HoverGrey: string;
  Black: string;
  TransparentBlack: string;
  White: string;
  HighlightDark: string;
  HighlightLight: string;
  HighlightLightBackground: string;
  HighlightBackground: string;
  Alert: string;
  Error: string;
  Warning: string;

  // Text colors
  Gold: string;
  PaleGold: string;
  Green: string;
  PaleBlue: string;
  DarkBlue: string;
  Pink: string;

  PeerGroupCarouselColor: '#11696F';

  /** TODO(VENN-25575): these deprecated colors are used by UI, not bar charts, turn these into regular colors */
  DEPRECATED_DivergingColor: DivergingColors;
  /** TODO(VENN-25575): these deprecated colors are used by UI, not bar charts, turn these into regular colors */
  DEPRECATED_DataBarColor: {
    LightGold: string;
    LightPaleGold: string;
    LightPaleBlue: string;
    LightPink: string;
    LightDarkBlue: string;
  };
  /** TODO(VENN-25575): these deprecated colors are used by UI, not bar charts, turn these into regular colors */
  DEPRECATED_DataLineColor: {
    Gold: string;
    PaleGold: string;
    Green: string;
    PaleBlue: string;
    DarkBlue: string;
    Pink: string;
    DeepGreen: string;
  };

  CashFlowColor: CashFlowColors;
  HoldingsColor: HoldingsColors;
  PeerGroupColor: PeerGroupsColors;
  Optimization: OptimizationColors;
  DataBarColor: DataBarColors;
  DataLineColor: DataLineColors;
  StudioSubjectColor: StudioSubjectColors;
  DivergingColor: DivergingColors;
  NavigationBarColor: NavigationBarColors;
  UNSAFE: UnsafeColors;
  GreyScale: GreyScale;
}

export interface StudioSubjectColors {
  A1: string;
  A2: string;
  A3: string;
  A4: string;
  A5: string;
  A6: string;
  A7: string;
  A8: string;
  A9: string;
}

const SUPPORTED_HOLDINGS_SECTORS = [
  'Cyclical',
  'Defensive',
  'Sensitive',
  'Cash And Equivalents',
  'Corporate',
  'Derivative',
  'Government',
  'Municipal',
  'Securitized',
  'Unknown',
] as const;
type HoldingsSector = (typeof SUPPORTED_HOLDINGS_SECTORS)[number];

const parseHoldingsSector = (sector: string): HoldingsSector | undefined =>
  SUPPORTED_HOLDINGS_SECTORS.find((supported) => sector === supported);

const SUPPORTED_HOLDINGS_ASSET_CLASSES = [
  'US Stock',
  'Non-US Stock',
  'US Fixed Income',
  'Non-US Fixed Income',
  'Preferred',
  'Convertible',
  'Cash',
  'Other',
  'Unknown',
] as const;
type HoldingsAssetClass = (typeof SUPPORTED_HOLDINGS_ASSET_CLASSES)[number];

const parseHoldingsAssetClass = (assetClass: string): HoldingsAssetClass | undefined =>
  SUPPORTED_HOLDINGS_ASSET_CLASSES.find((supported) => assetClass === supported);

export interface PeerGroupsColors {
  GradientHigh: string;
  GradientLow: string;
}

export interface HoldingsColors {
  SectorColor: { [sector in HoldingsSector]: string };
  AssetColor: { [assetClass in HoldingsAssetClass]: string };
}

export interface CashFlowColors {
  Historical: string;
  Projected: string;
  Typical: string;
}

/** Color schemes that are derived from the main VennColors */
export interface Schemes {
  FactorColors: FactorColorContext;
  BarChartColors: BarChartColors;
  LineChartColors: LineChartColors;
  Proxy: {
    proxyLine: string;
    /** Reserved for cases where the regular proxyLine has insufficient contrast with a non-white, non-dark background. */
    darkProxyLine: string;
    extrapolation: string;
    subjectLine: string;
    invertedSubjectLine: string;
    badgeBg: string;
    badgeText: string;
  };
}

type GetColorType = DeepMapTo<VennColors, ThemeProvider>;
type GetSchemeType = DeepMapTo<Pick<Schemes, 'LineChartColors' | 'Proxy'>, ThemeProvider>;

export const GetScheme: GetSchemeType = {
  LineChartColors: {
    mainColor: (props: { theme: Theme }) => props.theme.Schemes.LineChartColors.mainColor,
    secondaryColor: (props: { theme: Theme }) => props.theme.Schemes.LineChartColors.secondaryColor,
    comparisonColor: (props: { theme: Theme }) => props.theme.Schemes.LineChartColors.comparisonColor,
    lineColor: (props: { theme: Theme }) => props.theme.Schemes.LineChartColors.lineColor,
  },
  Proxy: {
    proxyLine: ({ theme }) => theme.Schemes.Proxy.proxyLine,
    darkProxyLine: ({ theme }) => theme.Schemes.Proxy.darkProxyLine,
    extrapolation: ({ theme }) => theme.Schemes.Proxy.extrapolation,
    subjectLine: ({ theme }) => theme.Schemes.Proxy.subjectLine,
    invertedSubjectLine: ({ theme }) => theme.Schemes.Proxy.invertedSubjectLine,
    badgeBg: ({ theme }) => theme.Schemes.Proxy.badgeBg,
    badgeText: ({ theme }) => theme.Schemes.Proxy.badgeText,
  },
};

type RGB = {
  r: number;
  g: number;
  b: number;
};

const tint = (tintFactor: number, color: RGB) => {
  return {
    r: Math.ceil(color.r + (255 - color.r) * tintFactor),
    g: Math.ceil(color.g + (255 - color.g) * tintFactor),
    b: Math.ceil(color.b + (255 - color.b) * tintFactor),
  };
};

const getBaseColor = (name: string, colors: VennColors): string => {
  const sector = parseHoldingsSector(name);
  if (sector === undefined) {
    logMessageToSentry(`Unexpected category name '${name}' encountered while getting base color.`);
    return colors.MidGrey1;
  }
  return colors.HoldingsColor.SectorColor[sector];
};

export const getTintFactor = (name: string): number => {
  switch (name) {
    case 'Government':
      return 0.0;
    case 'Government Related':
      return 0.3;
    case 'Municipal Taxable':
      return 0.0;
    case 'US Municipal Tax Advantaged':
      return 0.3;
    case 'Cash And Equivalents':
      return 0.0;
    case 'Bank Loan':
      return 0.0;
    case 'Convertible':
      return 0.4;
    case 'Corporate Bond':
      return 0.6;
    case 'Preferred':
      return 0.8;
    case 'Agency Mortgage Backed':
      return 0.0;
    case 'Non Agency Residential Mortgage Backed':
      return 0.8;
    case 'Commercial Mortgage Backed':
      return 0.4;
    case 'Covered Bond':
      return 0.6;
    case 'Asset Backed':
      return 0.0;
    case 'Swap':
      return 0.6;
    case 'Future Forward':
      return 0.0;
    case 'Option Warrant':
      return 0.2;
    case 'Basic Materials':
      return 0.0;
    case 'Consumer Cyclical':
      return 0.3;
    case 'Financial Services':
      return 0.5;
    case 'Real Estate':
      return 0.7;
    case 'Consumer Defensive':
      return 0.0;
    case 'Healthcare':
      return 0.2;
    case 'Utilities':
      return 0.4;
    case 'Communication Services':
      return 0.0;
    case 'Energy':
      return 0.2;
    case 'Industrial':
    case 'Industrials':
      return 0.4;
    case 'Technology':
      return 0.6;
    // Unclassified:
    case 'Other':
      return 0.0;
    // Unknown categories
    case 'Unknown':
      return 0.0;
    default:
      logMessageToSentry(`Unexpected category name '${name}' encountered while getting tint factor.`);
      return 0.0;
  }
};

const getColorForSector = (name: string, parentCategoryName: string, Colors: VennColors) => {
  const baseColor = getBaseColor(parentCategoryName, Colors);
  const tintedColor = tint(getTintFactor(name), tinycolor(baseColor).toRgb());
  return `rgb(${tintedColor.r}, ${tintedColor.g}, ${tintedColor.b})`;
};

export const getHoldingsCategoryColor = (
  parentCategoryName: string,
  name: string,
  breakdownType: HoldingsBreakdownTypeEnum,
  level: number,
  Colors: VennColors,
) => {
  if (breakdownType === 'REGION' || level > 2) {
    return Colors.MidGrey1;
  }

  if (breakdownType === 'SECTOR') {
    if (name === 'Unknown') {
      // Unknown is actually a level-1 category that we render with 2 levels to improve the look-and-feel, so we do need to give it a color as a special case.
      return Colors.HoldingsColor.SectorColor.Unknown;
    }
    if (level !== 2) {
      // This color is used as a filler value for the category rows, but they shouldn't show any colored bars anyway.
      return Colors.MidGrey1;
    }

    return getColorForSector(name, parentCategoryName, Colors);
  }

  // Asset Class
  const assetClass = parseHoldingsAssetClass(name);
  if (assetClass === undefined) {
    logMessageToSentry(`Unexpected category name '${name}' encountered while getting asset class.`);
    return Colors.MidGrey2;
  }
  return Colors.HoldingsColor.AssetColor[assetClass];
};

export const GetColor: GetColorType = {
  Primary: {
    Main: ({ theme }) => theme.Colors.Primary.Main,
    Medium: ({ theme }) => theme.Colors.Primary.Medium,
    Light: ({ theme }) => theme.Colors.Primary.Light,
    Dark: ({ theme }) => theme.Colors.Primary.Dark,
  },
  PaleTeal: ({ theme }) => theme.Colors.PaleTeal,
  WhiteGrey: ({ theme }) => theme.Colors.WhiteGrey,
  PaleGrey: ({ theme }) => theme.Colors.PaleGrey,
  LightGrey: ({ theme }) => theme.Colors.LightGrey,
  LightGrey2: ({ theme }) => theme.Colors.LightGrey2,
  Grey: ({ theme }) => theme.Colors.Grey,
  MidGrey1: ({ theme }) => theme.Colors.MidGrey1,
  MidGrey2: ({ theme }) => theme.Colors.MidGrey2,
  HintGrey: ({ theme }) => theme.Colors.HintGrey,
  DarkGrey: ({ theme }) => theme.Colors.DarkGrey,
  DarkGrey2: ({ theme }) => theme.Colors.DarkGrey2,
  HoverGrey: ({ theme }) => theme.Colors.HoverGrey,
  Black: ({ theme }) => theme.Colors.Black,
  TransparentBlack: ({ theme }) => theme.Colors.TransparentBlack,
  White: ({ theme }) => theme.Colors.White,
  HighlightDark: ({ theme }) => theme.Colors.HighlightDark,
  HighlightLight: ({ theme }) => theme.Colors.HighlightLight,
  HighlightLightBackground: ({ theme }) => theme.Colors.HighlightLightBackground,
  HighlightBackground: ({ theme }) => theme.Colors.HighlightBackground,
  Alert: ({ theme }) => theme.Colors.Alert,
  Error: ({ theme }) => theme.Colors.Error,
  Warning: ({ theme }) => theme.Colors.Warning,
  PeerGroupCarouselColor: ({ theme }) => theme.Colors.PeerGroupCarouselColor,

  Gold: ({ theme }) => theme.Colors.Gold,
  PaleGold: ({ theme }) => theme.Colors.PaleGold,
  Green: ({ theme }) => theme.Colors.Green,
  PaleBlue: ({ theme }) => theme.Colors.PaleBlue,
  DarkBlue: ({ theme }) => theme.Colors.DarkBlue,
  Pink: ({ theme }) => theme.Colors.Pink,

  HoldingsColor: {
    SectorColor: {
      Cyclical: ({ theme }) => theme.Colors.HoldingsColor.SectorColor.Cyclical,
      Defensive: ({ theme }) => theme.Colors.HoldingsColor.SectorColor.Defensive,
      Sensitive: ({ theme }) => theme.Colors.HoldingsColor.SectorColor.Sensitive,
      'Cash And Equivalents': ({ theme }) => theme.Colors.HoldingsColor.SectorColor['Cash And Equivalents'],
      Corporate: ({ theme }) => theme.Colors.HoldingsColor.SectorColor.Corporate,
      Derivative: ({ theme }) => theme.Colors.HoldingsColor.SectorColor.Derivative,
      Government: ({ theme }) => theme.Colors.HoldingsColor.SectorColor.Government,
      Municipal: ({ theme }) => theme.Colors.HoldingsColor.SectorColor.Municipal,
      Securitized: ({ theme }) => theme.Colors.HoldingsColor.SectorColor.Securitized,
      Unknown: ({ theme }) => theme.Colors.HoldingsColor.SectorColor.Unknown,
    },
    AssetColor: {
      'US Stock': ({ theme }) => theme.Colors.HoldingsColor.AssetColor['US Stock'],
      'Non-US Stock': ({ theme }) => theme.Colors.HoldingsColor.AssetColor['Non-US Stock'],
      'US Fixed Income': ({ theme }) => theme.Colors.HoldingsColor.AssetColor['US Fixed Income'],
      'Non-US Fixed Income': ({ theme }) => theme.Colors.HoldingsColor.AssetColor['Non-US Fixed Income'],
      Preferred: ({ theme }) => theme.Colors.HoldingsColor.AssetColor.Preferred,
      Convertible: ({ theme }) => theme.Colors.HoldingsColor.AssetColor.Convertible,
      Cash: ({ theme }) => theme.Colors.HoldingsColor.AssetColor.Cash,
      Other: ({ theme }) => theme.Colors.HoldingsColor.AssetColor.Other,
      Unknown: ({ theme }) => theme.Colors.HoldingsColor.AssetColor.Unknown,
    },
  },

  PeerGroupColor: {
    GradientHigh: ({ theme }) => theme.Colors.PeerGroupColor.GradientHigh,
    GradientLow: ({ theme }) => theme.Colors.PeerGroupColor.GradientLow,
  },

  CashFlowColor: {
    Historical: ({ theme }) => theme.Colors.CashFlowColor.Historical,
    Projected: ({ theme }) => theme.Colors.CashFlowColor.Projected,
    Typical: ({ theme }) => theme.Colors.CashFlowColor.Typical,
  },

  StudioSubjectColor: {
    A1: ({ theme }) => theme.Colors.StudioSubjectColor.A1,
    A2: ({ theme }) => theme.Colors.StudioSubjectColor.A2,
    A3: ({ theme }) => theme.Colors.StudioSubjectColor.A3,
    A4: ({ theme }) => theme.Colors.StudioSubjectColor.A4,
    A5: ({ theme }) => theme.Colors.StudioSubjectColor.A5,
    A6: ({ theme }) => theme.Colors.StudioSubjectColor.A6,
    A7: ({ theme }) => theme.Colors.StudioSubjectColor.A7,
    A8: ({ theme }) => theme.Colors.StudioSubjectColor.A8,
    A9: ({ theme }) => theme.Colors.StudioSubjectColor.A9,
  },

  Optimization: {
    Positive: ({ theme }) => theme.Colors.Optimization.Positive,
    Negative: ({ theme }) => theme.Colors.Optimization.Negative,
  },

  DEPRECATED_DataBarColor: {
    LightGold: ({ theme }) => theme.Colors.DEPRECATED_DataBarColor.LightGold,
    LightPaleGold: ({ theme }) => theme.Colors.DEPRECATED_DataBarColor.LightPaleGold,
    LightPaleBlue: ({ theme }) => theme.Colors.DEPRECATED_DataBarColor.LightPaleBlue,
    LightPink: ({ theme }) => theme.Colors.DEPRECATED_DataBarColor.LightPink,
    LightDarkBlue: ({ theme }) => theme.Colors.DEPRECATED_DataBarColor.LightDarkBlue,
  },

  DataBarColor: {
    LightGold: ({ theme }) => theme.Colors.DataBarColor.LightGold,
    LightPaleBlue: ({ theme }) => theme.Colors.DataBarColor.LightPaleBlue,
    LightDarkBlue: ({ theme }) => theme.Colors.DataBarColor.LightDarkBlue,
  },

  DEPRECATED_DataLineColor: {
    Gold: ({ theme }) => theme.Colors.DEPRECATED_DataLineColor.Gold,
    PaleGold: ({ theme }) => theme.Colors.DEPRECATED_DataLineColor.PaleGold,
    Green: ({ theme }) => theme.Colors.DEPRECATED_DataLineColor.Green,
    PaleBlue: ({ theme }) => theme.Colors.DEPRECATED_DataLineColor.PaleBlue,
    DarkBlue: ({ theme }) => theme.Colors.DEPRECATED_DataLineColor.DarkBlue,
    Pink: ({ theme }) => theme.Colors.DEPRECATED_DataLineColor.Pink,
    DeepGreen: ({ theme }) => theme.Colors.DEPRECATED_DataLineColor.DeepGreen,
  },

  DataLineColor: {
    Gold: ({ theme }) => theme.Colors.DataLineColor.Gold,
    PaleGold: ({ theme }) => theme.Colors.DataLineColor.PaleGold,
    Yellow: ({ theme }) => theme.Colors.DataLineColor.Yellow,
    Green: ({ theme }) => theme.Colors.DataLineColor.Green,
    PaleBlue: ({ theme }) => theme.Colors.DataLineColor.PaleBlue,
    DarkBlue: ({ theme }) => theme.Colors.DataLineColor.DarkBlue,
    Pink: ({ theme }) => theme.Colors.DataLineColor.Pink,
    DeepGreen: ({ theme }) => theme.Colors.DataLineColor.DeepGreen,
  },

  DEPRECATED_DivergingColor: {
    A5: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.A5,
    A4: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.A4,
    A3: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.A3,
    A2: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.A2,
    A1: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.A1,
    MID: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.MID,
    B1: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.B1,
    B2: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.B2,
    B3: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.B3,
    B4: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.B4,
    B5: ({ theme }) => theme.Colors.DEPRECATED_DivergingColor.B5,
  },

  DivergingColor: {
    A5: ({ theme }) => theme.Colors.DivergingColor.A5,
    A4: ({ theme }) => theme.Colors.DivergingColor.A4,
    A3: ({ theme }) => theme.Colors.DivergingColor.A3,
    A2: ({ theme }) => theme.Colors.DivergingColor.A2,
    A1: ({ theme }) => theme.Colors.DivergingColor.A1,
    MID: ({ theme }) => theme.Colors.DivergingColor.MID,
    B1: ({ theme }) => theme.Colors.DivergingColor.B1,
    B2: ({ theme }) => theme.Colors.DivergingColor.B2,
    B3: ({ theme }) => theme.Colors.DivergingColor.B3,
    B4: ({ theme }) => theme.Colors.DivergingColor.B4,
    B5: ({ theme }) => theme.Colors.DivergingColor.B5,
  },

  NavigationBarColor: {
    Active: ({ theme }) => theme.Colors.NavigationBarColor.Active,
    ActiveBackground: ({ theme }) => theme.Colors.NavigationBarColor.ActiveBackground,
    ActiveLighter: ({ theme }) => theme.Colors.NavigationBarColor.ActiveLighter,
    InactiveBackground: ({ theme }) => theme.Colors.NavigationBarColor.InactiveBackground,
    ShimmerBackground: ({ theme }) => theme.Colors.NavigationBarColor.ShimmerBackground,
  },

  UNSAFE: {
    LightBlue: ({ theme }) => theme.Colors.UNSAFE.LightBlue,
    Separator: ({ theme }) => theme.Colors.UNSAFE.Separator,
    Silver: ({ theme }) => theme.Colors.UNSAFE.Silver,
    Azure: ({ theme }) => theme.Colors.UNSAFE.Azure,
    Steel: ({ theme }) => theme.Colors.UNSAFE.Steel,
    Red: ({ theme }) => theme.Colors.UNSAFE.Red,
  },

  GreyScale: {
    Grey10: ({ theme }) => theme.Colors.GreyScale.Grey10,
    Grey20: ({ theme }) => theme.Colors.GreyScale.Grey20,
    Grey30: ({ theme }) => theme.Colors.GreyScale.Grey30,
    Grey40: ({ theme }) => theme.Colors.GreyScale.Grey40,
    Grey50: ({ theme }) => theme.Colors.GreyScale.Grey50,
    Grey60: ({ theme }) => theme.Colors.GreyScale.Grey60,
    Grey70: ({ theme }) => theme.Colors.GreyScale.Grey70,
    Grey80: ({ theme }) => theme.Colors.GreyScale.Grey80,
    Grey90: ({ theme }) => theme.Colors.GreyScale.Grey90,
    Grey100: ({ theme }) => theme.Colors.GreyScale.Grey100,
  },
};
