import React, { Component, useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import { DrawdownTable } from '../../../../drawdown-table/DrawdownTable';
import convertToExcel from '../../../../basictable/convertToExcel';
import { ConfigurationLabel } from '../../../../configuration';
import { DownloadableContentBlock } from '../../../../content-block';
import { Input } from '../../../../input/Input';
import { FailedAnalysisInfo, TrackSuccessfulAnalysis } from '../../../chart-errors';
import RangeAnalysis from '../range-analysis/RangeAnalysis';
import type { Analysis, DrawdownPeriodAnalysis } from 'venn-api';
import { analysis } from 'venn-api';
import type { AnalysesPeriod, AnalysisGroup, AnalysisSubject, TimeFrame } from 'venn-utils';
import { analyticsService, AnalyticsUtils, getAnalysisRequest, scrollToElementById } from 'venn-utils';
import type { Theme } from 'venn-ui-kit';
import { GetColor, KeyCodes, Loading } from 'venn-ui-kit';
import { convertAnalysisDrawDown } from '../utils';
import convertRangeAnalysis from './convertRangeAnalysis';
import { getColumns } from '../../../../drawdown-table/getColumns';
import MediaQuery from 'react-responsive';
import type { TrackAnalysisProps } from '../../../chart-errors/TrackAnalysis';
import { AnalysisViewContext, blockLevelDefaultSettings, MetadataContext } from '../../../../contexts';
import type { DownloadMetaData } from '../../../../downloadable';

interface HistoricalAnalysisProps {
  analysesPeriod?: AnalysesPeriod;
  subject: AnalysisSubject;
  relative: boolean;
  analysisGroup: AnalysisGroup<DrawdownPeriodAnalysis[]>;
  categoryActive: boolean;
  onResetTimeFrame?: () => void;
  getHeaderOffset?: () => number;
  trackingProps: TrackAnalysisProps;
  initialThreshold?: number;
  onUpdateThreshold?: (value: number) => void;
  theme: Theme;
  downloadMetaData?: DownloadMetaData;
  onUpdateRange?: (start: number | undefined, end: number | undefined) => void;
}

interface HistoricalAnalysisState {
  inputValue: string;
  selectedRow: DrawdownPeriodAnalysis | null;
  threshold: number;
  rangeLoading: boolean;
  rangeError?: string;
  analyses: Analysis[];
}

const MAX_THRESHOLD = 0;

class HistoricalAnalysis extends Component<HistoricalAnalysisProps, HistoricalAnalysisState> {
  private abortController: AbortController;

  constructor(props: HistoricalAnalysisProps) {
    super(props);

    const threshold = props.initialThreshold ?? blockLevelDefaultSettings.hypotheticalDrawdownThreshold;

    this.state = {
      selectedRow: null,
      threshold,
      inputValue: threshold.toFixed(1),
      rangeLoading: false,
      rangeError: undefined,
      analyses: [],
    };
    props.onUpdateRange?.(undefined, undefined);
  }

  componentDidUpdate(nextProps: HistoricalAnalysisProps) {
    if (nextProps.subject !== this.props.subject) {
      this.setState({
        selectedRow: null,
      });
      nextProps.onUpdateRange?.(undefined, undefined);
    }
  }

  onPeriodSelect = (selectedRow: DrawdownPeriodAnalysis) => {
    const { start, end } = selectedRow;
    this.trackTimeChange(start, end, true);
    this.setState({ selectedRow }, () =>
      this.fetchAnalysis({
        startTime: start,
        endTime: end,
      }),
    );
    this.props.onUpdateRange?.(start, end);
  };

  trackTimeChange = (start: number, end: number, vulnerabilityRow?: boolean) => {
    const formattedStartDate = AnalyticsUtils.dateRangeFormat(start);
    const formattedEndDate = AnalyticsUtils.dateRangeFormat(end);
    analyticsService.drawdownSelected({
      drawdownType: vulnerabilityRow ? 'vulnerability row' : 'custom date range',
      dateRange: `${formattedStartDate}-${formattedEndDate}`,
      threshold: this.state.threshold,
    });
  };

  render() {
    const { inputValue, selectedRow, threshold, rangeLoading, rangeError, analyses } = this.state;
    const {
      analysesPeriod,
      subject,
      analysisGroup,
      onResetTimeFrame,
      relative,
      trackingProps,
      categoryActive,
      theme,
      downloadMetaData,
    } = this.props;

    if (!analysisGroup.subject) {
      const { trackingId, updateAnalysisStatusForTracking, blockNames, blockTitles } = trackingProps;
      return (
        <FailedAnalysisInfo
          subject={subject}
          analysesPeriod={analysesPeriod}
          onResetAnalysisPeriod={onResetTimeFrame}
          error={{
            message: analysisGroup?.message?.text || 'Could not perform Drawdown Analysis',
            code: analysisGroup?.message?.code,
          }}
          regressionName="Drawdown Analysis"
          isAdvancedAnalysis
          relativeToBenchmark={relative}
          categoryActive={categoryActive}
          blockNames={blockNames}
          blockTitles={blockTitles}
          updateAnalysisStatusForTracking={updateAnalysisStatusForTracking}
          trackingId={trackingId}
        />
      );
    }
    const drawdownPeriods = convertAnalysisDrawDown(analysisGroup);
    const isPortfolio = subject?.type === 'portfolio';
    const formatThreshold = Number((threshold / 100).toFixed(3));
    const canSelectDrawdownPeriod = drawdownPeriods && drawdownPeriods.length > 0;
    const drawdownRangeAnalysis = convertRangeAnalysis(analyses, relative, !!(subject && subject.activeBenchmark));
    const filteredData: Partial<DrawdownPeriodAnalysis>[] = drawdownPeriods.filter(
      (period) => Number(((period.maxDrawdown ?? NaN) * 100).toFixed(1)) <= formatThreshold * 100,
    );

    return (
      <MediaQuery print>
        {(print) => {
          const cols = getColumns(subject, drawdownPeriods, analysisGroup, true, relative, 'DAILY', print, theme);
          // @ts-expect-error: TODO fix strictFunctionTypes
          const excelData = convertToExcel(filteredData, cols);
          return (
            <TrackSuccessfulAnalysis {...trackingProps}>
              <DownloadableContentBlock
                header="Hypothetical Drawdown Periods"
                subHeader={
                  subject && (
                    <>
                      <div>
                        Given your current factor exposures, which periods in history would cause significant drawdowns
                        in {subject.name}?
                      </div>
                      <StyledSubtitle>
                        Assumes zero non-factor returns, and zero rebalancing during the drawdown period.
                      </StyledSubtitle>
                    </>
                  )
                }
                downloadable={{
                  disabled: filteredData.length === 0,
                  png: true,
                  excel: excelData,
                  options: {
                    fileName: `Hypothetical Drawdown Periods - ${subject.name}`,
                    watermark: {
                      right: 40,
                      top: 40,
                    },
                    metaData: downloadMetaData,
                  },
                  tracking: {
                    description: 'HYPOTHETICAL_DRAWDOWN_ANALYSIS',
                    relativeToBenchmark: false,
                    subjectType: subject.type,
                    subjectId: subject.id,
                    userUploaded: subject.userUploaded,
                  },
                }}
              >
                <Wrapper>
                  <DrawdownLabel label="Drawdown Threshold:" className="qa-drawdown-threshold">
                    <StyledInput
                      onClick={this.onClick}
                      onBlur={this.onThresholdBlur}
                      onChange={this.onThresholdChange}
                      onKeyDown={this.onThresholdKeydown}
                      value={inputValue}
                    />
                  </DrawdownLabel>
                  <DrawdownTable
                    drawdownPeriods={drawdownPeriods}
                    hasAnalyzeError
                    onPeriodSelect={this.onPeriodSelect}
                    selectedRow={selectedRow}
                    threshold={formatThreshold}
                    isPortfolio={isPortfolio}
                    columns={cols}
                  />
                </Wrapper>
              </DownloadableContentBlock>
              <RangeWrapper id="range-wrapper">
                {rangeLoading ? (
                  <Loading />
                ) : (
                  <RangeAnalysis
                    drawdownRangeAnalysis={drawdownRangeAnalysis ?? null}
                    error={rangeError}
                    subject={subject}
                    print={print}
                    start={rangeError || !selectedRow ? undefined : selectedRow.start}
                    end={rangeError || !selectedRow ? undefined : selectedRow.end}
                    completed={rangeError || !selectedRow ? false : selectedRow.completed}
                    canSelectDrawdownPeriod={canSelectDrawdownPeriod}
                    downloadMetaData={downloadMetaData}
                  />
                )}
              </RangeWrapper>
            </TrackSuccessfulAnalysis>
          );
        }}
      </MediaQuery>
    );
  }

  private onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  };

  private onThresholdKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === KeyCodes.Enter) {
      e.currentTarget.blur();
    }
  };

  private onThresholdBlur = () => {
    const { onUpdateThreshold } = this.props;
    const threshold = Number(this.state.inputValue);
    if (!Number.isNaN(threshold)) {
      const fixedThreshold = Number(threshold.toFixed(1));
      const validateThreshold = fixedThreshold > 0 ? fixedThreshold * -1 : fixedThreshold;
      this.setState({ threshold: validateThreshold, inputValue: `${validateThreshold.toFixed(1)}` });
      onUpdateThreshold?.(validateThreshold);
      return;
    }
    this.setState({ threshold: MAX_THRESHOLD, inputValue: `${MAX_THRESHOLD}` });
    onUpdateThreshold?.(MAX_THRESHOLD);
  };

  private onThresholdChange = (inputValue: string) => this.setState({ inputValue });

  private fetchAnalysis = async (timeFrame: TimeFrame) => {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.setState({
      rangeLoading: true,
    });
    const { relative, subject, categoryActive, trackingProps } = this.props;
    const configs = getAnalysisRequest(
      ['FACTOR_DRAWDOWN_PERIOD', 'FACTOR_ENVIRONMENT'],
      subject,
      timeFrame,
      relative,
      categoryActive,
      undefined,
      trackingProps.trackingId,
    );
    this.abortController = new AbortController();
    try {
      const { content } = await analysis(configs, this.abortController.signal);
      this.setState({
        rangeLoading: false,
        analyses: content.analyses,
        rangeError: '',
      });
    } catch (e) {
      const error = await e;
      if (error.name === 'AbortError') {
        return;
      }

      this.setState({
        rangeError: error.content?.message,
        rangeLoading: false,
      });
    }
    // Scroll to selected row once analysis request is complete
    scrollToElementById('range-wrapper', this.props.getHeaderOffset?.());
  };
}

export default withTheme((props: HistoricalAnalysisProps) => {
  const { hypotheticalDrawdownThreshold, onUpdateAnalysisViewParam } = useContext(AnalysisViewContext);
  const { setDrawdownPeriod } = useContext(MetadataContext);
  return (
    <HistoricalAnalysis
      {...props}
      initialThreshold={hypotheticalDrawdownThreshold}
      onUpdateThreshold={(value: number) =>
        onUpdateAnalysisViewParam({
          hypotheticalDrawdownThreshold: value,
        })
      }
      onUpdateRange={setDrawdownPeriod}
    />
  );
});

const Wrapper = styled.div`
  position: relative;
`;

const DrawdownLabel = styled(ConfigurationLabel)`
  position: relative;
  left: 20px;
`;

const StyledInput = styled(Input)`
  position: relative;
  width: 100px;
  border: 1px solid ${GetColor.Grey};
  border-radius: 0;

  @media print {
    border: none;
    width: 60px;
  }

  &:before {
    content: '%';
    right: 8px;
    position: absolute;
    color: ${GetColor.MidGrey2};
  }
`;

const RangeWrapper = styled.section`
  margin-top: 60px;
  @media print {
    padding-top: 0px;
    top: 0px !important;
  }
`;

const StyledSubtitle = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;
