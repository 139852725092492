import { atom, atomFamily, selector } from 'recoil';
import type { BlockId } from './types';
import type { AnalysisView } from 'venn-api';
import { compact } from 'lodash';
import { unsavedChangesEffect } from '../effects/unsavedChangesEffect';
import { resetOnStudioReset } from '../effects/signalEffects';

export const customizedViews = atom<AnalysisView[]>({
  key: 'customizedViews',
  default: [],
  effects: [resetOnStudioReset],
});

export const studioBlockHeight = atomFamily<number, BlockId>({
  key: 'studioBlockHeight',
  default: 0,
});

/** All block IDs in the entire view. */
export const allBlockIdsState = selector<BlockId[]>({
  key: 'allBlockIds',
  get: ({ get }) => {
    const cvs = get(customizedViews);
    if (!cvs) return [];
    return compact(cvs.map((cv) => cv.refId || cv.id));
  },
  cachePolicy_UNSTABLE: {
    // Only need the most-recent set of block IDs, not every set of block IDs ever
    eviction: 'most-recent',
  },
});

export const studioBlockCustomWidth = atomFamily<number, BlockId>({
  key: 'studioBlockCustomWidth',
  default: 1.0,
  effects: [unsavedChangesEffect],
});

/**
 * Represents the rows and columns of blocks for the studio scroll
 * Blocks will be packed into the same row while the total block width adds less than 1
 */
export const studioBlockLayout = selector<BlockId[][]>({
  key: 'studioBlockLayout',
  get: ({ get }) => {
    const allBlocks = get(allBlockIdsState);
    const layout: BlockId[][] = [];
    allBlocks.forEach((id) => {
      if (
        layout.length > 0 &&
        layout[layout.length - 1].reduce(
          (prev, cur) => get(studioBlockCustomWidth(cur)) + prev,
          get(studioBlockCustomWidth(id)),
        ) <= 1
      ) {
        layout[layout.length - 1].push(id);
      } else {
        layout.push([id]);
      }
    });
    return layout;
  },
});
