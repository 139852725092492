import React from 'react';
import styled from 'styled-components';
import { Caption, GetColor, Headline3, Icon, Subtitle1 } from 'venn-ui-kit';
import { analyticsService } from 'venn-utils';
import { DataUploaderMode } from '../types';
import { getIconForFormat } from './Icons';
import type { Sample } from './SampleData';
import { FileLayout } from './SampleData';

interface SampleCardProps {
  sample: Sample;
  mode: DataUploaderMode;
}

const SampleCard = ({ sample, mode }: SampleCardProps) => {
  return (
    <a
      target="_blank"
      href={`/api/file/tag/${sample.tag}`}
      rel="noreferrer"
      onClick={() =>
        analyticsService.fileTemplateDownloaded({
          isPrivatesTemplate: mode === DataUploaderMode.Privates,
          tag: sample.tag,
        })
      }
    >
      <Container data-testid="qa-sample-card">
        <IconContainer>{getIconForFormat(sample.layout)}</IconContainer>
        <Details>
          <StyledHeadline>
            {sample.title}
            <DownloadIconWrapper>
              <Icon type="download" /> (DOWNLOAD)
            </DownloadIconWrapper>
          </StyledHeadline>
          <Subtitle1>{sample.description}</Subtitle1>
          <Info>
            {sample.info ? (
              <InfoCaption>
                <Icon prefix="far" type="circle-info" />
                &nbsp;
                {sample.info}
              </InfoCaption>
            ) : (
              <>
                <Caption>
                  <b>Layout: </b>
                  <LayoutIcon
                    type={sample.layout === FileLayout.GRID ? 'th-large' : 'line-columns'}
                    prefix="fad"
                    layout={sample.layout}
                  />{' '}
                  {sample.layout}
                </Caption>
                {sample.frequency && (
                  <Caption>
                    <b>Supported Frequencies: </b> {sample.frequency.join(', ')}
                  </Caption>
                )}
              </>
            )}
          </Info>
        </Details>
      </Container>
    </a>
  );
};

export default SampleCard;

const Container = styled.div`
  width: 100%;
  border: 1px solid ${GetColor.Grey};
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  &:hover {
    border: 1px solid ${GetColor.Primary.Main};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: top;
  align-items: baseline;
  width: 175px;
  min-width: 175px;
  height: auto;
  background-color: ${GetColor.WhiteGrey};
  border-right: 1px solid ${GetColor.Grey};
  overflow: hidden;
  font-size: 20px;
  padding: 5px;
`;

const Details = styled.div`
  padding: 10px 10px 10px 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const LayoutIcon = styled(Icon)<{ layout: FileLayout }>`
  padding: 0 2px;
  ${(props) => props.layout === FileLayout.HORIZONTAL && 'transform: rotate(90deg);'}
`;

const StyledHeadline = styled(Headline3)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const DownloadIconWrapper = styled.div`
  font-size: 14px;
  color: ${GetColor.Primary.Dark};
`;

const InfoCaption = styled(Caption)`
  color: ${GetColor.Primary.Dark};
  font-size: 14px;
`;
