import { useHasFF } from 'venn-utils';
import { OldColorScheme, NewColorScheme } from '../default/Colors';
import { useMemo, useState } from 'react';
import type { VennColors } from '../../style/color';
import { getCurrentTheme } from './getCurrentTheme';
import { transformCustomColorsToVennColors, type CustomColors } from '../../style/customColors';

const GreenColorScheme = (
  process.env.USE_GREEN === 'true' || process.env.NODE_ENV === 'test'
    ? require('../green/Colors').default
    : require('../default/Colors').default
) as VennColors;

export const useColorTheme = () => {
  const hasNewTheme = useHasFF('new_venn_color_theme');
  const baseColors = getCurrentTheme() !== 'default' ? GreenColorScheme : hasNewTheme ? NewColorScheme : OldColorScheme;

  const [customColors, setCustomColors] = useState<CustomColors | undefined>(undefined);
  const [debugColors, setDebugColors] = useState<VennColors | undefined>(undefined);

  const colors = useMemo(
    () => (customColors ? transformCustomColorsToVennColors(baseColors, customColors) : baseColors),
    [baseColors, customColors],
  );

  return { colors: debugColors ?? colors, customColors, setCustomColors, setDebugColors } as const;
};
