import type { CustomizableMetric } from 'venn-utils';

export const PERFORMANCE_BLOCK_METRICS: CustomizableMetric[] = [
  {
    key: 'periodReturn',
    label: 'Return (Analysis Period)',
    relativeLabel: 'Excess Return (Analysis Period)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'periodReturn',
    relativeMetricPath: 'periodExcessReturn',
  },
  {
    key: 'ytdReturn',
    label: 'Return (YTD)',
    relativeLabel: 'Excess Return (YTD)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'ytdReturn',
  },
  {
    key: 'YEAR_1-periodReturn',
    label: 'Return (1Y)',
    relativeLabel: 'Excess Return (1Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_1.periodReturn',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_1.periodExcessReturn',
  },
  {
    key: 'YEAR_3-periodReturn',
    label: 'Return (3Y)',
    relativeLabel: 'Excess Return (3Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_3.periodReturn',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_3.periodExcessReturn',
  },
  {
    key: 'YEAR_5-periodReturn',
    label: 'Return (5Y)',
    relativeLabel: 'Excess Return (5Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_5.periodReturn',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_5.periodExcessReturn',
  },
  {
    key: 'YEAR_10-periodReturn',
    label: 'Return (10Y)',
    relativeLabel: 'Excess Return (10Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_10.periodReturn',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_10.periodExcessReturn',
  },
  {
    key: 'YEAR_15-periodReturn',
    label: 'Return (15Y)',
    relativeLabel: 'Excess Return (15Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_15.periodReturn',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_15.periodExcessReturn',
  },
  {
    key: 'YEAR_20-periodReturn',
    label: 'Return (20Y)',
    relativeLabel: 'Excess Return (20Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_20.periodReturn',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_20.periodExcessReturn',
  },
  {
    key: 'averageUpMonth',
    label: 'Average Up Month',
    relativeLabel: 'Average Up Month',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'averageUpMonth',
  },
  {
    key: 'averageDownMonth',
    label: 'Average Down Month',
    relativeLabel: 'Average Down Month',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'averageDownMonth',
  },
  {
    key: 'sharpe',
    label: 'Sharpe Ratio',
    relativeLabel: 'Information Ratio',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'sharpe',
    relativeMetricPath: 'informationRatio',
  },
  {
    key: 'YEAR_1-sharpe',
    label: 'Sharpe Ratio (1Y)',
    relativeLabel: 'Information Ratio (1Y)',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_1.sharpe',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_1.informationRatio',
  },
  {
    key: 'YEAR_3-sharpe',
    label: 'Sharpe Ratio (3Y)',
    relativeLabel: 'Information Ratio (3Y)',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_3.sharpe',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_3.informationRatio',
  },
  {
    key: 'YEAR_5-sharpe',
    label: 'Sharpe Ratio (5Y)',
    relativeLabel: 'Information Ratio (5Y)',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_5.sharpe',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_5.informationRatio',
  },
  {
    key: 'YEAR_10-sharpe',
    label: 'Sharpe Ratio (10Y)',
    relativeLabel: 'Information Ratio (10Y)',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_10.sharpe',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_10.informationRatio',
  },
  {
    key: 'YEAR_15-sharpe',
    label: 'Sharpe Ratio (15Y)',
    relativeLabel: 'Information Ratio (15Y)',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_15.sharpe',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_15.informationRatio',
  },
  {
    key: 'YEAR_20-sharpe',
    label: 'Sharpe Ratio (20Y)',
    relativeLabel: 'Information Ratio (20Y)',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_20.sharpe',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_20.informationRatio',
  },
  {
    key: 'volatility',
    label: 'Volatility',
    relativeLabel: 'Tracking Error',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'volatility',
    relativeMetricPath: 'trackingError',
  },
  {
    key: 'YEAR_1-volatility',
    label: 'Volatility (1Y)',
    relativeLabel: 'Tracking Error (1Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_1.volatility',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_1.trackingError',
  },
  {
    key: 'YEAR_3-volatility',
    label: 'Volatility (3Y)',
    relativeLabel: 'Tracking Error (3Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_3.volatility',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_3.trackingError',
  },
  {
    key: 'YEAR_5-volatility',
    label: 'Volatility (5Y)',
    relativeLabel: 'Tracking Error (5Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_5.volatility',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_5.trackingError',
  },
  {
    key: 'YEAR_10-volatility',
    label: 'Volatility (10Y)',
    relativeLabel: 'Tracking Error (10Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_10.volatility',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_10.trackingError',
  },
  {
    key: 'YEAR_15-volatility',
    label: 'Volatility (15Y)',
    relativeLabel: 'Tracking Error (15Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_15.volatility',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_15.trackingError',
  },
  {
    key: 'YEAR_20-volatility',
    label: 'Volatility (20Y)',
    relativeLabel: 'Tracking Error (20Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_20.volatility',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_20.trackingError',
  },
  {
    key: 'maxDrawdown',
    label: 'Max drawdown',
    relativeLabel: 'Max underperformance',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'maxDrawdown',
    relativeMetricPath: 'maxUnderperformance',
  },
  {
    key: 'YEAR_1-maxDrawdown',
    label: 'Max drawdown (1Y)',
    relativeLabel: 'Max underperformance (1Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_1.maxDrawdown',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_1.maxUnderperformance',
  },
  {
    key: 'YEAR_3-maxDrawdown',
    label: 'Max drawdown (3Y)',
    relativeLabel: 'Max underperformance (3Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_3.maxDrawdown',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_3.maxUnderperformance',
  },
  {
    key: 'YEAR_5-maxDrawdown',
    label: 'Max drawdown (5Y)',
    relativeLabel: 'Max underperformance (5Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_5.maxDrawdown',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_5.maxUnderperformance',
  },
  {
    key: 'YEAR_10-maxDrawdown',
    label: 'Max drawdown (10Y)',
    relativeLabel: 'Max underperformance (10Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_10.maxDrawdown',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_10.maxUnderperformance',
  },
  {
    key: 'YEAR_15-maxDrawdown',
    label: 'Max drawdown (15Y)',
    relativeLabel: 'Max underperformance (15Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_15.maxDrawdown',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_15.maxUnderperformance',
  },
  {
    key: 'YEAR_20-maxDrawdown',
    label: 'Max drawdown (20Y)',
    relativeLabel: 'Max underperformance (20Y)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'fixedPeriodsPerformance.YEAR_20.maxDrawdown',
    relativeMetricPath: 'fixedPeriodsPerformance.YEAR_20.maxUnderperformance',
  },
  {
    key: 'calendarReturns2023',
    label: '2023 Return',
    relativeLabel: '2023 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Calendar Year Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'yearlyPerformance[2023]',
  },
  {
    key: 'calendarReturns2022',
    label: '2022 Return',
    relativeLabel: '2022 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Calendar Year Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'yearlyPerformance[2022]',
  },
  {
    key: 'calendarReturns2021',
    label: '2021 Return',
    relativeLabel: '2021 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Calendar Year Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'yearlyPerformance[2021]',
  },
  {
    key: 'calendarReturns2020',
    label: '2020 Return',
    relativeLabel: '2020 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Calendar Year Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'yearlyPerformance[2020]',
  },
  {
    key: 'calendarReturns2019',
    label: '2019 Return',
    relativeLabel: '2019 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Calendar Year Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'yearlyPerformance[2019]',
  },
  {
    key: 'calendarReturns2018',
    label: '2018 Return',
    relativeLabel: '2018 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Calendar Year Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'yearlyPerformance[2018]',
  },
  {
    key: 'calendarReturns2017',
    label: '2017 Return',
    relativeLabel: '2017 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Calendar Year Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'yearlyPerformance[2017]',
  },
  {
    key: 'calendarReturns2016',
    label: '2016 Return',
    relativeLabel: '2016 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Calendar Year Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'yearlyPerformance[2016]',
  },
  {
    key: 'calendarReturns2015',
    label: '2015 Return',
    relativeLabel: '2015 Excess Return',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Calendar Year Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'yearlyPerformance[2015]',
  },
  {
    key: 'upsideVolatility',
    label: 'Upside Volatility',
    relativeLabel: 'Upside Volatility',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'upsideVolatility',
  },
  {
    key: 'downsideVolatility',
    label: 'Downside Volatility',
    relativeLabel: 'Downside Volatility',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'downsideVolatility',
  },
  {
    key: 'battingAverage',
    label: 'Batting Average',
    relativeLabel: 'Batting Average',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'battingAverage',
  },
  {
    key: 'conditionalValueAtRisk95',
    label: 'CVaR (5%)',
    relativeLabel: 'CVaR (5%)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'conditionalValueAtRisk95',
  },
  {
    key: 'valueAtRisk',
    label: 'VaR (95%)',
    relativeLabel: 'VaR (95%)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'valueAtRisk',
  },
  {
    key: 'valueAtRisk975',
    label: 'VaR (97.5%)',
    relativeLabel: 'VaR (97.5%)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'valueAtRisk975',
  },
  {
    key: 'valueAtRisk99',
    label: 'VaR (99%)',
    relativeLabel: 'VaR (99%)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'valueAtRisk99',
  },
  {
    key: 'skewness',
    label: 'Skewness',
    relativeLabel: 'Skewness',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'skewness',
  },
  {
    key: 'kurtosis',
    label: 'Kurtosis',
    relativeLabel: 'Kurtosis',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'kurtosis',
  },
  {
    key: 'sortino',
    label: 'Sortino Ratio',
    relativeLabel: 'Sortino Ratio',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'sortino',
  },
  {
    key: 'calmar',
    label: 'Calmar Ratio',
    relativeLabel: 'Calmar Ratio',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Risk Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'calmar',
  },
  {
    key: 'autocorrelation',
    label: 'Autocorrelation',
    relativeLabel: 'Autocorrelation',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Historical Performance',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'autocorrelation',
  },
  {
    key: 'FORECAST-annualizedReturn',
    label: 'Return (Forecast)',
    relativeLabel: 'Excess Return (Forecast)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_FORECAST',
    category: 'Forecast Performance',
    analysisResultKey: 'forecastedPerformanceSummary',
    metricPath: 'annualizedReturn',
    relativeMetricPath: 'annualizedExcessReturn',
  },
  {
    key: 'FORECAST-volatility',
    label: 'Volatility (Forecast)',
    relativeLabel: 'Tracking Error (Forecast)',
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_FORECAST',
    category: 'Forecast Performance',
    analysisResultKey: 'forecastedPerformanceSummary',
    metricPath: 'volatility',
    relativeMetricPath: 'trackingError',
  },
  {
    key: 'FORECAST-sharpe',
    label: 'Sharpe Ratio (Forecast)',
    relativeLabel: 'Information Ratio (Forecast)',
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_FORECAST',
    category: 'Forecast Performance',
    analysisResultKey: 'forecastedPerformanceSummary',
    metricPath: 'sharpe',
    relativeMetricPath: 'informationRatio',
  },
  {
    key: 'RESIDUAL-periodReturn',
    label: 'Return (Residual)',
    relativeLabel: 'Excess Return (Residual)',
    dataType: 'PERCENTAGE',
    analysisType: 'RESIDUAL_RETURNS_ANALYSIS',
    category: 'Historical Residual Performance',
    analysisResultKey: 'residualReturnsAnalyses',
    metricPath: 'historicalResidualPerformanceSummary.periodReturn',
    relativeMetricPath: 'historicalResidualPerformanceSummary.periodExcessReturn',
  },
  {
    key: 'RESIDUAL-volatility',
    label: 'Volatility (Residual)',
    relativeLabel: 'Tracking Error (Residual)',
    dataType: 'PERCENTAGE',
    analysisType: 'RESIDUAL_RETURNS_ANALYSIS',
    category: 'Historical Residual Performance',
    analysisResultKey: 'residualReturnsAnalyses',
    metricPath: 'historicalResidualPerformanceSummary.volatility',
    relativeMetricPath: 'historicalResidualPerformanceSummary.trackingError',
  },
  {
    key: 'RESIDUAL-sharpe',
    label: 'Sharpe Ratio (Residual)',
    relativeLabel: 'Information Ratio (Residual)',
    dataType: 'NUMERIC',
    analysisType: 'RESIDUAL_RETURNS_ANALYSIS',
    category: 'Historical Residual Performance',
    analysisResultKey: 'residualReturnsAnalyses',
    metricPath: 'historicalResidualPerformanceSummary.sharpe',
    relativeMetricPath: 'historicalResidualPerformanceSummary.informationRatio',
  },
  {
    key: 'RESIDUAL_FORECAST-annualizedReturn',
    label: 'Return (Residual) (Forecast)',
    relativeLabel: 'Excess Return (Residual)',
    dataType: 'PERCENTAGE',
    analysisType: 'RESIDUAL_RETURNS_ANALYSIS',
    category: 'Forecast Residual Performance',
    analysisResultKey: 'residualReturnsAnalyses',
    metricPath: 'forecastedResidualPerformanceSummary.annualizedReturn',
    relativeMetricPath: 'forecastedResidualPerformanceSummary.annualizedExcessReturn',
  },
  {
    key: 'RESIDUAL_FORECAST-volatility',
    label: 'Volatility (Residual) (Forecast)',
    relativeLabel: 'Tracking Error (Residual) (Forecast)',
    dataType: 'PERCENTAGE',
    analysisType: 'RESIDUAL_RETURNS_ANALYSIS',
    category: 'Forecast Residual Performance',
    analysisResultKey: 'residualReturnsAnalyses',
    metricPath: 'forecastedResidualPerformanceSummary.volatility',
    relativeMetricPath: 'forecastedResidualPerformanceSummary.trackingError',
  },
  {
    key: 'RESIDUAL_FORECAST-sharpe',
    label: 'Sharpe Ratio (Residual) (Forecast)',
    relativeLabel: 'Information Ratio (Residual) (Forecast)',
    dataType: 'NUMERIC',
    analysisType: 'RESIDUAL_RETURNS_ANALYSIS',
    category: 'Forecast Residual Performance',
    analysisResultKey: 'residualReturnsAnalyses',
    metricPath: 'forecastedResidualPerformanceSummary.sharpe',
    relativeMetricPath: 'forecastedResidualPerformanceSummary.informationRatio',
  },
  {
    key: 'betaToBenchmark',
    label: 'Beta To Benchmark',
    relativeLabel: undefined,
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'betaToBenchmark',
  },
  {
    key: 'correlation',
    label: 'Correlation',
    relativeLabel: undefined,
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'correlation',
  },
  {
    key: 'downsideCorrelation',
    label: 'Downside Correlation',
    relativeLabel: undefined,
    dataType: 'NUMERIC',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'downsideCorrelation',
  },
  {
    key: 'downsideCaptureRatio',
    label: 'Downside Capture',
    relativeLabel: undefined,
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'downsideCaptureRatio',
  },
  {
    key: 'upsideCaptureRatio',
    label: 'Upside Capture',
    relativeLabel: undefined,
    dataType: 'PERCENTAGE',
    analysisType: 'PERFORMANCE_SUMMARY_HISTORICAL',
    category: 'Benchmark Required Statistics',
    analysisResultKey: 'historicalPerformanceSummary',
    metricPath: 'upsideCaptureRatio',
  },
];
