import React, { useState } from 'react';
import styled from 'styled-components';
import type { DateRange } from 'venn-ui-kit';
import { Button, DateRangePicker, GetColor, Label, DEFAULT_START_DATE } from 'venn-ui-kit';
import type { CustomNotablePeriod } from 'venn-utils';
import { useModal } from 'venn-utils';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalSubhead } from 'venn-components';
import { InputField } from '../../../../shared';
import { isNil } from 'lodash';
import moment from 'moment';

const NAME_MAX_LENGTH = 100;
const MAX_RANGE: DateRange = {
  from: moment.utc(DEFAULT_START_DATE).valueOf(),
  to: moment.utc().endOf('day').valueOf(),
};

interface AddCustomPeriodProps {
  onAddCustomPeriod: (customPeriod: CustomNotablePeriod) => void;
}

const AddCustomPeriod = ({ onAddCustomPeriod }: AddCustomPeriodProps) => {
  const [isModalOpened, open, close] = useModal();
  const [name, setName] = useState<string>();
  const [dateRange, setDateRange] = useState<DateRange>(MAX_RANGE);

  const onClose = () => {
    setName(undefined);
    setDateRange(MAX_RANGE);
    close();
  };

  const isMissingData = !name?.length || isNil(dateRange.from) || isNil(dateRange.to);
  const onSubmit = () => {
    if (isMissingData) {
      return;
    }

    onAddCustomPeriod({ name, start: dateRange.from!, end: dateRange.to!, id: -Date.now() });
    onClose();
  };

  return (
    <>
      <Button onClick={open} disabled={isModalOpened}>
        Add Custom Period
      </Button>
      {isModalOpened && (
        <Modal>
          <ModalHeader>Add Custom Notable Period</ModalHeader>
          <ModalSubhead>Set the name, start, and end date to view statistics for a custom period.</ModalSubhead>
          <ModalContent>
            <Label>
              Custom period name: <RequiredSymbol>*</RequiredSymbol>
            </Label>
            <InputField
              placeholder="Enter period name"
              value={name}
              name="Custom period name"
              id="period-name"
              onChange={setName}
              charactersLimit={NAME_MAX_LENGTH}
              disabled={false}
              error={name?.length === 0}
            />
            <RangeContainer>
              <RangeLabelContainer>
                <Label>
                  Custom period start and end: <RequiredSymbol>*</RequiredSymbol>
                </Label>
              </RangeLabelContainer>
              <DateRangePicker value={dateRange} range={MAX_RANGE} granularity="day" onChange={setDateRange} />
            </RangeContainer>
          </ModalContent>
          <ModalFooter
            onCancel={onClose}
            onPrimaryClick={onSubmit}
            primaryDisabled={isMissingData}
            primaryLabel="Add Period"
          />
        </Modal>
      )}
    </>
  );
};

export default AddCustomPeriod;

const RequiredSymbol = styled.span`
  color: ${GetColor.Error};
`;

const RangeLabelContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const RangeContainer = styled.div`
  margin-top: 20px;

  & > div > div {
    margin-top: 10px;
  }
`;
