import { atomFamily, selectorFamily, DefaultValue } from 'recoil';
import { type BlockId } from '../types';
import { type CustomBlockFonts, type CustomFont, type GridStyle } from './types';
import { blockFontDefaults, newBlockTableSpacingDefault } from './workspaceDefaults';
import { unsavedChangesEffect } from '../../effects/unsavedChangesEffect';
import { findKey, mapValues } from 'lodash';
import { resetOnStudioReset } from '../../effects';
import { GetTypography } from 'venn-ui-kit';
import { ptToPx } from 'venn-utils';
import { tableSpaceSettings } from './constants';
import { isReportState } from '../configuration/view';

/** Scaling applied to Report Lab only. This should not be used in regular Studio. */
export const SCALE_FACTOR = 0.7;

export const blockFontsDefaults = {
  blockTitle: createBlockFontDefault('blockTitle'),
  blockSubtitle: createBlockFontDefault('blockSubtitle'),
  blockHeaderInfo: createBlockFontDefault('blockHeaderInfo'),
  blockTableData: createBlockFontDefault('blockTableData'),
  blockChartAxis: createBlockFontDefault('blockChartAxis'),
  blockChartLegend: createBlockFontDefault('blockChartLegend'),
} satisfies { [k in keyof CustomBlockFonts]: ReturnType<typeof createBlockFontDefault> };

function createBlockFontDefault(key: keyof CustomBlockFonts) {
  return selectorFamily<CustomFont, BlockId>({
    key: `${key}FontDefault`,
    get:
      (blockId) =>
      ({ get }) =>
        get(blockFontDefaults(blockId))[key],
  });
}

export const blockFonts = {
  blockTitle: createBlockFont('blockTitle'),
  blockSubtitle: createBlockFont('blockSubtitle'),
  blockHeaderInfo: createBlockFont('blockHeaderInfo'),
  blockTableData: createBlockFont('blockTableData'),
  blockChartAxis: createBlockFont('blockChartAxis'),
  blockChartLegend: createBlockFont('blockChartLegend'),
} satisfies { [k in keyof CustomBlockFonts]: ReturnType<typeof createBlockFont> };

function createBlockFont(key: keyof CustomBlockFonts) {
  const innerAtom = atomFamily<CustomFont, BlockId>({
    key: `${key}Font`,
    default: blockFontsDefaults[key],
    effects: [unsavedChangesEffect, resetOnStudioReset],
  });

  return selectorFamily<CustomFont, BlockId>({
    key: `${key}FontSelector`,
    get:
      (blockId) =>
      ({ get }) => {
        // In some cases we want to always derive the font from defaults instead of storing state, such as in Studio
        const shouldPersist = get(isReportState);
        return shouldPersist ? get(innerAtom(blockId)) : get(blockFontsDefaults[key](blockId));
      },
    set:
      (blockId) =>
      ({ set }, newValue) => {
        set(innerAtom(blockId), newValue);
      },
  });
}

/** Selector to get or edit all block fonts in one go, such as during saving and loading. */
export const allBlockFontsState = selectorFamily<CustomBlockFonts, BlockId>({
  key: 'blockCustomFonts',
  get:
    (blockId) =>
    ({ get }) =>
      mapValues(blockFonts, (font) => get(font(blockId))),
  set:
    (blockId) =>
    ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        for (const blockFont of Object.values(blockFonts)) {
          reset(blockFont(blockId));
        }
        return;
      }

      for (const [key, value] of Object.entries(blockFonts)) {
        set(value(blockId), newValue[key]);
      }
    },
});

export const blockTableSpacingRatioState = selectorFamily<number, BlockId>({
  key: 'blockTableSpacingRatio',
  get:
    (blockId) =>
    ({ get }) => {
      // In some cases we want to always derive the font from defaults instead of storing state, such as in Studio
      const shouldPersist = get(isReportState);
      return shouldPersist
        ? get(internalBlockTableSpacingRatioState(blockId))
        : get(newBlockTableSpacingDefault(blockId));
    },
  set:
    (blockId) =>
    ({ set }, newValue) => {
      set(internalBlockTableSpacingRatioState(blockId), newValue);
    },
});

const internalBlockTableSpacingRatioState = atomFamily<number, BlockId>({
  key: 'internalBlockTableSpacingRatio',
  default: selectorFamily<number, BlockId>({
    key: 'blockTableSpacingRatioDefault',
    get:
      (blockId) =>
      ({ get }) =>
        get(newBlockTableSpacingDefault(blockId)),
  }),
  effects: [unsavedChangesEffect, resetOnStudioReset],
});

export const blockGridStyleState = selectorFamily<GridStyle, BlockId>({
  key: 'blockGridStyleState',
  get:
    (blockId) =>
    ({ get }) => {
      const customDataFont = get(blockFonts.blockTableData(blockId));
      const customGridSizeRatio = get(blockTableSpacingRatioState(blockId));
      const gridSizePx = ptToPx(customDataFont.fontSizePt) * customGridSizeRatio;
      return {
        gridSize: `${gridSizePx}px`,
        fontSize: `${customDataFont.fontSizePt}pt`,
        cellHorizontalPaddingPx: gridSizePx * 2,
        indentPerAutoGroupLevel: 8,
        fontFamily: GetTypography.fontFamily,
        className: `ag-theme-custom-${blockId}-${getTableSpace(customGridSizeRatio)}-${numberToCssSafeString(customDataFont.fontSizePt)}`,
      };
    },
});

const EPSILON = 0.001;
/**
 * Retrieves the string name of the given grid size
 * Default to grid size if it does not fit a known setting
 */
const getTableSpace = (gridSize: number) =>
  findKey(tableSpaceSettings, (value) => Math.abs(value - gridSize) < EPSILON) ?? numberToCssSafeString(gridSize);

const numberToCssSafeString = (num: number) => String(num).replace('.', '_');
