import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';
import { GetColor, ZIndex } from 'venn-ui-kit';

export interface DropdownMenuItemBaseProps {
  disabled?: boolean;
  icon?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const DropdownMenuItemBase = styled.div<DropdownMenuItemBaseProps>`
  display: flex;
  align-items: center;
  padding: 4px ${(props) => (props.icon ? '0' : '10px')};
  color: ${GetColor.LightGrey};
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  flex: 1;
  z-index: ${ZIndex.Front};
  min-height: 25px;

  &:hover {
    background-color: ${GetColor.PaleGrey};
  }

  ${(props) =>
    props.disabled &&
    `
    color: ${GetColor.LightGrey};
    pointer-events: none;
  `};
`;

export const DropdownMenuItemIcon = styled.i`
  width: 9px;
  margin: 0 6px;
  color: ${GetColor.LightGrey};
  font-size: 9px;
`;

const DropdownMenuItemLabel = styled.div<{ level?: number }>`
  padding-left: ${({ level = 0 }) => level * 20}px;
  width: 100%;
  display: inline-flex;
  align-items: baseline;
`;

export interface DropdownMenuItemProps extends DropdownMenuItemBaseProps {
  level?: number;
  onClose?: () => void;
  children?: ReactNode;
}

export class DropdownMenuItem extends Component<DropdownMenuItemProps> {
  static defaultProps = {
    onClick: noop,
    onClose: noop,
  };

  onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { onClick, onClose } = this.props;
    onClick && onClick(event);
    onClose && onClose();
  };

  render() {
    const { className, disabled, icon, children, level } = this.props;

    return (
      <DropdownMenuItemBase className={className} disabled={disabled} icon={icon} onClick={this.onClick}>
        {icon && <DropdownMenuItemIcon className={icon} />}

        <DropdownMenuItemLabel level={level}>{children}</DropdownMenuItemLabel>
      </DropdownMenuItemBase>
    );
  }
}

export default DropdownMenuItem;
