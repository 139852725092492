import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import type { DropdownOption, DropdownValue } from '../Dropdown';
import Dropdown from '../Dropdown';
import { DataUploaderMode } from '../../types';

export interface MapDataTableHeaderColumn {
  /**
   * List of options for a given column.
   */
  options: DropdownOption[];
  /**
   * If readonly, the value will be displayed but won't be changeable.
   */
  readonly?: boolean;

  mixedLabel?: string;

  hide?: boolean;
}

interface MapDataTableHeaderProps {
  /**
   * List of columns, each having a set of options.
   */
  columns: MapDataTableHeaderColumn[];
  /**
   * Set of values, must be equal in length to the columns array, and
   * contains values that can be found in their respective column options.
   */
  values: DropdownValue[];
  /**
   * Returns all values, for all columns
   */
  onChange?: (values: DropdownValue[]) => void;
  /**
   * Left margin for the first dropdown in px
   */
  marginLeft?: number;
  mode: DataUploaderMode;
}

const MapDataTableHeader: FC<React.PropsWithChildren<MapDataTableHeaderProps>> = ({
  columns,
  values,
  onChange,
  marginLeft,
  mode,
}) => {
  if (columns.length !== values.length && mode !== DataUploaderMode.Privates) {
    throw Error(
      `The columns array must have the same length as the values array.
      If using read-only columns, you may set their respective values in the values array to null.`,
    );
  }
  return (
    <Container marginLeft={marginLeft}>
      {columns.map((column, index) =>
        column.hide ? null : (
          <Dropdown
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            options={column.options}
            readonly={column.readonly}
            value={values[index]}
            mixedLabel={column.mixedLabel}
            onChange={(value) => {
              if (onChange) {
                const newValues = [...values.slice(0, index), value, ...values.slice(index + 1)];
                onChange(newValues);
              }
            }}
          />
        ),
      )}
    </Container>
  );
};

const Container = styled.div<{ marginLeft?: number }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${GetColor.Primary.Dark};
  background-color: ${GetColor.PaleGrey};
  height: 30px;

  > div {
    padding: 0 20px;
    line-height: 30px;
  }

  ${({ marginLeft }) =>
    marginLeft !== undefined &&
    `
    > div:first-child {
      margin-left: ${marginLeft}px;
    }
  `}
`;

export default MapDataTableHeader;
