import type { Images } from 'venn-ui-kit';
import type { DynamicThemeType } from '../../style/themes';
import { getAssetWithDefault } from './getAssetWithDefault';

export const getImageTheme = (theme: DynamicThemeType): Images => ({
  holdingsAsset: getAssetWithDefault(theme, 'holdingsAsset.png'),
  privatesAsset: getAssetWithDefault(theme, 'privatesAsset.png'),
  privateAssetAGSConeChart: getAssetWithDefault(theme, 'privateAssetAGSConeChart.svg'),
  privateAssetCFPModelSettings: getAssetWithDefault(theme, 'cfpModelSettingsCarousel.png'),
  peerGroupAnalysisAsset: getAssetWithDefault(theme, 'peerGroupAnalysisAsset.png'),
  extrapolationAsset: getAssetWithDefault(theme, 'extrapolationAsset.png'),
  reportLabAsset: getAssetWithDefault(theme, 'reportLabAsset.png'),
  morningstarCategoryAsset: getAssetWithDefault(theme, 'morningstarCategoryAsset.png'),
  six: getAssetWithDefault(theme, 'six.png'),
  correlations: getAssetWithDefault(theme, 'correlations.png'),
  cumulativeResidualReturn: getAssetWithDefault(theme, 'cumulativeResidualReturn.png'),
  factorContributionsToExposureTrend: getAssetWithDefault(theme, 'factorContributionsToExposureTrend.png'),
  factorContributionsToReturnTrend: getAssetWithDefault(theme, 'factorContributionsToReturnTrend.png'),
  factorContributionsToRiskTrend: getAssetWithDefault(theme, 'factorContributionsToRiskTrend.png'),
  historicalDrawdownChart: getAssetWithDefault(theme, 'historicalDrawdownChart.png'),
  performanceAttribution: getAssetWithDefault(theme, 'performanceAttribution.png'),
  residualPerformanceSummary: getAssetWithDefault(theme, 'residualPerformanceSummary.png'),
  rollingReturn: getAssetWithDefault(theme, 'rollingReturn.png'),
  scenarioAnalysis: getAssetWithDefault(theme, 'scenarioAnalysis.png'),
  northernTrust: getAssetWithDefault(theme, 'northernTrust.svg'),
  addepar: getAssetWithDefault(theme, 'addepar.svg'),
  backstop: getAssetWithDefault(theme, 'backstop.svg'),
  bny: getAssetWithDefault(theme, 'bny.svg'),
  citco: getAssetWithDefault(theme, 'citco.svg'),
  citi: getAssetWithDefault(theme, 'citi.svg'),
  dynamo: getAssetWithDefault(theme, 'dynamo.svg'),
  jpMorgan: getAssetWithDefault(theme, 'jpmChase.svg'),
  mercer: getAssetWithDefault(theme, 'mercer.svg'),
  parilux: getAssetWithDefault(theme, 'parilux.svg'),
  solovis: getAssetWithDefault(theme, 'solovis.svg'),
  ssAndC: getAssetWithDefault(theme, 'ssAndC.svg'),
  stateStreet: getAssetWithDefault(theme, 'stateStreet.svg'),
  wilShire: getAssetWithDefault(theme, 'wilshire.svg'),
  integrationBanner: getAssetWithDefault(theme, 'integrationBanner.png'),
  riskReturn: getAssetWithDefault(theme, 'riskReturn.png'),
  scenario: getAssetWithDefault(theme, 'scenario.png'),
  macroScenario: getAssetWithDefault(theme, 'macroScenario.png'),
  portfolioLabs: getAssetWithDefault(theme, 'portfolioLabs.png'),
  triangles: getAssetWithDefault(theme, 'triangles.png'),
  trianglesRepeating: getAssetWithDefault(theme, 'trianglesRepeating.png'),
  trend: getAssetWithDefault(theme, 'trend.png'),
  credit: getAssetWithDefault(theme, 'credit.png'),
  factorBreakdown: getAssetWithDefault(theme, 'factorBreakdown.png'),
  drawdown: getAssetWithDefault(theme, 'drawdown.png'),
  vennProvidedLogo: getAssetWithDefault(theme, 'vennProvidedLogo.png'),
  vennProvidedLogoGreyscale: getAssetWithDefault(theme, 'vennProvidedLogoGreyscale.png'),
  vennBackground: getAssetWithDefault(theme, 'vennBackground.svg'),
  brandLogo: getAssetWithDefault(theme, 'brandLogo.svg'),
  addeparStep1AddeparLogin: getAssetWithDefault(theme, 'addeparStep1AddeparLogin.png'),
  addeparStep1Authorization: getAssetWithDefault(theme, 'addeparStep1Authorization.png'),
  addeparStep2PortfolioPrefix: getAssetWithDefault(theme, 'addeparStep2PortfolioPrefix.png'),
  addeparStep2PortfolioName: getAssetWithDefault(theme, 'addeparStep2PortfolioName.png'),
  addeparStep2InvestmentName: getAssetWithDefault(theme, 'addeparStep2InvestmentName.png'),
  addeparStep5Sync: getAssetWithDefault(theme, 'addeparStep5Sync.png'),
  customFonts: getAssetWithDefault(theme, 'customFonts.png'),
});
