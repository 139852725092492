import React, { useCallback, useContext } from 'react';
import { POPOVER_WIDTH } from '../../factor-chart/constants';
import { ThemeContext } from 'styled-components';
import { isNil } from 'lodash';

const formatValue = (value?: number): string | undefined =>
  isNil(value) ? undefined : value === 1 ? '1' : value.toFixed(2);

const PRINT_FONT_SIZE = 10;
const FONT_SIZE = 14;
const CELL_PADDING = 8;

export type MouseEventType = (
  coords: { columnId: number; rowId: number },
  cellPositions?: {
    x: number;
    y: number;
    width: number;
    height: number;
    tooltipRight?: boolean;
  },
) => void;

interface CellProps {
  displayValue?: number;
  x: number;
  y: number;
  width: number;
  height: number;
  clickable: boolean;
  leftOffset?: number;
  rightOffset?: number;
  fill?: string;
  style?: string;
  onClick?: MouseEventType;
  totalCount: number;
  print?: boolean;
  fontSize?: string;
}

const Cell = ({
  x,
  y,
  displayValue,
  width,
  height,
  leftOffset = 0,
  rightOffset,
  totalCount,
  fill,
  clickable,
  onClick,
  print,
  fontSize,
}: CellProps) => {
  const handleOnClick = useCallback(
    (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
      const dimension = (event.target as SVGRectElement).getBoundingClientRect();
      const popupX = event.clientX - dimension.left;
      const tooltipRight = (rightOffset ?? 0) + (totalCount - x) * width - popupX < POPOVER_WIDTH;

      onClick?.(
        { columnId: x, rowId: y },
        { x: x * width + leftOffset + popupX, y: y * height, width, height, tooltipRight },
      );
    },
    [leftOffset, rightOffset, totalCount, x, width, height, y, onClick],
  );

  const { Colors } = useContext(ThemeContext);

  const positionProps = { x: x * width + leftOffset, y: y * height, height: height - (clickable ? 1 : 0), width };

  return (
    <>
      <rect
        {...positionProps}
        fill={fill}
        stroke={fill}
        onClick={handleOnClick}
        style={{ cursor: clickable && onClick ? 'pointer' : 'default' }}
        data-x={x}
        data-y={y}
      />
      {!isNil(displayValue) && (
        <g>
          <text
            {...positionProps}
            x={positionProps.x + width - CELL_PADDING}
            y={positionProps.y + height / 2 + (print ? PRINT_FONT_SIZE : FONT_SIZE) / 2}
            fontSize={fontSize || (print ? PRINT_FONT_SIZE : FONT_SIZE)}
            textAnchor="end"
            fill={displayValue > 0.5 || displayValue < -0.5 ? Colors.White : Colors.Black}
          >
            {formatValue(displayValue)}
          </text>
        </g>
      )}
    </>
  );
};

export default React.memo(Cell);
