import type { Portfolio } from 'venn-api';
import { find, isEqualWith } from 'lodash';

export const arePortfoliosEqual = (portfolio: Portfolio, other: Portfolio): boolean =>
  isEqualWith(portfolio, other, (_, __, key: string | number | symbol | undefined) =>
    key === 'updated' || key === 'compare' ? true : undefined,
  );

export const findInPortfolio = (portfolio: Portfolio, id: number): Portfolio | undefined =>
  portfolio.id === id ? portfolio : find(portfolio.children.map((child) => findInPortfolio(child, id)));
