import React, { useState, useCallback } from 'react';
import {
  analysisViewIdState,
  type DateRangeInputId,
  dateRangeInputNameState,
  dateRangeInputGlobalComputedRange,
  dateRangeInputDateRangeState,
  dateRangeInputConsistencyState,
  onDateInputDelete,
  isReportState,
} from 'venn-state';
import {
  formatRange,
  GetColor,
  Icon,
  getRangeFromType,
  RANGE_TYPE_TO_DISPLAY_NAME,
  ShimmerBlock,
  TitleShimmer,
  SubTitleShimmer,
  formatEndOfRange,
} from 'venn-ui-kit';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { DateRangeInputEditor } from 'venn-components';
import { FREQUENCY_DATEPICKER_MODES, buttonize, withSuspense, analyticsService, AnalyticsUtils } from 'venn-utils';
import { InputRow } from './InputRow';

const ACTION_ICON_CLASS = 'date-range-action-icon';

interface DateRangeInputProps {
  isPrivate: boolean;
  deletable: boolean;
  inputId: DateRangeInputId;
  readonly?: boolean;
}

/**
 * Should be wrapped in a {@link InputRowWrapper} for proper functionality.
 * These two components are separated for layout flexibility and composability.
 */
export const DateRangeInputRow = React.memo(withSuspense(<ShimmerBlock height={64} />, DateRangeInputRowInternal));

function DateRangeInputRowInternal({ inputId, isPrivate, readonly = false, deletable }: DateRangeInputProps) {
  const [editorOpen, setEditorOpen] = useState(false);
  const [name, setName] = useRecoilState(dateRangeInputNameState(inputId));
  const viewId = useRecoilValue(analysisViewIdState);
  const consistency = useRecoilValue(dateRangeInputConsistencyState(inputId));
  const range = useRecoilValue(dateRangeInputDateRangeState(inputId));

  // TODO(will, collin): IMV2 confirmation modal for deletion
  const onInputDelete = useRecoilCallback(onDateInputDelete);
  const onDateRangeGroupDelete = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        snapshot.getPromise(isReportState).then((isReport) =>
          analyticsService.inputsDateRangeGroupDeleted({
            name,
            sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
            viewId,
            consistency,
            range: AnalyticsUtils.rangeFormat(range.period),
            startDate: range.from ? AnalyticsUtils.dateFormat(range.from) : undefined,
            endDate: range.to ? AnalyticsUtils.dateFormat(range.to) : undefined,
          }),
        );

        onInputDelete(inputId);
      },
    [inputId, onInputDelete, name, consistency, range, viewId],
  );

  const closeEditor = useCallback(() => setEditorOpen(false), [setEditorOpen]);
  const openEditor = useCallback(() => setEditorOpen(true), [setEditorOpen]);

  return (
    <div data-testid="qa-date-input">
      {editorOpen && <DateRangeInputEditor inputId={inputId} onClose={closeEditor} />}
      <InputRow
        name={name}
        onNameChange={setName}
        onEdit={openEditor}
        onDelete={deletable ? onDateRangeGroupDelete : undefined}
        readonly={readonly}
      >
        <RangeTextSection isPrivate={isPrivate} inputId={inputId} readonly={readonly} openEditor={openEditor} />
      </InputRow>
    </div>
  );
}

const RangeTextSection = withSuspense(
  <>
    <TitleShimmer />
    <SubTitleShimmer />
  </>,
  ({
    inputId,
    readonly,
    openEditor,
    isPrivate,
  }: {
    inputId: DateRangeInputId;
    readonly?: boolean;
    openEditor: () => void;
    isPrivate: boolean;
  }) => {
    const consistency = useRecoilValue(dateRangeInputConsistencyState(inputId));
    const userDefinedDateRange = useRecoilValue(dateRangeInputDateRangeState(inputId));

    const computedRange = useRecoilValue(dateRangeInputGlobalComputedRange(inputId));
    const granularity = FREQUENCY_DATEPICKER_MODES[computedRange.frequency];

    const userDefinedPeriod = userDefinedDateRange.period;
    const userSettingText = userDefinedPeriod
      ? RANGE_TYPE_TO_DISPLAY_NAME[userDefinedPeriod]
      : formatRange(userDefinedDateRange, granularity);

    const getComputedSettingText = () => {
      const computedDateRange = computedRange.range;

      if (consistency === 'BLOCK') {
        return undefined;
      }

      if (computedDateRange?.from && computedDateRange?.to) {
        if (isPrivate) {
          return formatEndOfRange(computedDateRange, granularity);
        }

        // If we have a computed range and user defined period, then the computed range
        // should have taken the period into account.
        if (userDefinedPeriod) {
          const periodOfComputedRange = formatRange(
            getRangeFromType(userDefinedPeriod, computedDateRange, granularity),
            granularity,
          );
          return periodOfComputedRange;
        }

        // If we have both a computed range and a user defined range, the range we ultimately use must
        // be the minimal range available, the part of the user-defined range and the computed range that intersects or overlaps.
        const overlappingRange = {
          from: Math.max(computedDateRange?.from, userDefinedDateRange.from ?? Number.NEGATIVE_INFINITY),
          to: Math.min(computedDateRange?.to, userDefinedDateRange.to ?? Number.POSITIVE_INFINITY),
        };

        // It is possible that the ranges don't overlap at all, for example data is available 2020-2021 and user
        // set range to 2015-2016, or vice versa.
        if (overlappingRange.from > overlappingRange.to) {
          return 'Selected range has no overlap with the subject data';
        }

        return formatRange(overlappingRange, granularity);
      }

      if (userDefinedPeriod) {
        // If there is no computed range, but we do have a period, then we can still compute the range from the period as a last resort.
        const userRange = getRangeFromType(userDefinedPeriod, computedRange.maxRange, granularity);
        const computedRangeFromPeriod = formatRange(userRange, granularity);

        if (isPrivate) {
          return formatEndOfRange(userRange, granularity);
        }

        return computedRangeFromPeriod;
      }

      // No data is available to show a range. This sometimes happens, if there are no relevant subjects, for example.
      return undefined;
    };
    const computedSettingText = getComputedSettingText();

    return (
      <>
        <Row>
          <CalendarIcon
            className={readonly ? undefined : ACTION_ICON_CLASS}
            type="calendar"
            {...(readonly ? {} : buttonize(openEditor))}
          />
          {userSettingText}
        </Row>
        <div style={{ paddingLeft: 20, height: '15px', fontSize: '12px' }}>
          {consistency === 'BLOCK' && <>Block-level date ranges enabled.</>}
          {computedSettingText && <>{computedSettingText}</>}
        </div>
      </>
    );
  },
);

/**
 * Separately exported from {@link DateRangeInputRow} so that it can be mix-and-matched with other components.
 */
export const InputRowWrapper = styled.div`
  .${ACTION_ICON_CLASS} {
    color: ${GetColor.Primary.Dark};
    visibility: hidden;
    cursor: pointer;
  }

  :hover {
    background-color: ${GetColor.GreyScale.Grey10};

    .${ACTION_ICON_CLASS} {
      color: ${GetColor.Primary.Dark};
      visibility: visible;

      &&:hover {
        color: ${GetColor.Primary.Main};
      }
    }
  }
`;

const CalendarIcon = styled(Icon)`
  font-size: 14px;
  margin-right: 8px;

  /* Override the general icon style from Wrapper  */
  && {
    color: ${GetColor.Grey};
    visibility: visible;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 30px;
`;
