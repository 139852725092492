import React, { useContext } from 'react';
import ColumnLabel from '../../factor-chart/column-labels/ColumnLabel';
import type { HeatMapTypes } from '../Types';
import Value from './Value';
import { ThemeContext } from 'styled-components';

interface Props {
  source: HeatMapTypes.Root[];
  selectedColumn?: number;
  height: number;
  width: number;
  offset: number;
  isPercentage: boolean;
  labels: string[];
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const ValuesWrapper = React.memo(({ source, selectedColumn, width, height, offset, labels, isPercentage }: Props) => {
  const { Typography, Colors } = useContext(ThemeContext);
  const verticalCenter = height / 2 + 2;

  return (
    <g>
      {labels.map((label, i) => (
        <ColumnLabel
          key={label}
          x={i * width + offset}
          y={-55}
          width={width}
          lineHeight={15}
          maxLines={4}
          padding={3}
          text={label}
        />
      ))}
      {typeof selectedColumn === 'number' &&
        source[0].series.map((serie, j) =>
          source.map((sourceRow, i) => (
            <Value
              // eslint-disable-next-line react/no-array-index-key
              key={serie.name + i + j}
              isPercentage={isPercentage}
              selectedColumn={selectedColumn}
              x={offset + (j + 1) * width - 10}
              y={i * height + verticalCenter}
              height={height}
              serie={sourceRow.series[j]}
              bold={j === 0}
              colors={
                (j === 0 ? source[0].series[j].colors : undefined) ?? {
                  positive: Colors.DarkGrey,
                  negative: Colors.DarkGrey,
                }
              }
              typography={Typography}
            />
          )),
        )}
    </g>
  );
});

export default ValuesWrapper;
