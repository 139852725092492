import { atomFamily } from 'recoil';
import type { BlockId } from '../types';
import { unsavedChangesEffect } from '../../effects/unsavedChangesEffect';
import type { PeerGroupIdentifier } from 'venn-api';

export const blockSelectedPeerGroupId = atomFamily<PeerGroupIdentifier | undefined, BlockId>({
  key: 'blockSelectedPeerGroupId',
  default: undefined,
  effects: [unsavedChangesEffect],
});
