import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';

const AbsoluteErrorMessage = styled.span<{ errorColor?: string }>`
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  padding-top: 5px;
  color: ${(props) => props.errorColor ?? GetColor.Error};
`;

export default AbsoluteErrorMessage;
