/*
 * Venn Platform API 1.0.0 Operations for "scenarioanalysis"
 * Automatically generated file - do not modify!
 */

import type { Scenario, ScenarioAnalysisIndex } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Add or update a saved analysis view scenario */
export async function storeSavedViewScenarios(
  customParamId: string,
  body?: Partial<Scenario>,
  signal?: AbortSignal,
): Promise<OperationResult<Scenario>> {
  const endpoint = `/api/scenarioanalysis/scenarios/${encodeURIComponent(customParamId)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Scenario = (await response.json()) as Scenario;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Add or update a user scenario */
export async function storeScenarios(
  body?: Partial<Scenario>,
  signal?: AbortSignal,
): Promise<OperationResult<Scenario>> {
  const endpoint = '/api/scenarioanalysis/scenarios';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Scenario = (await response.json()) as Scenario;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Delete user scenario */
export async function deleteScenario(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/scenarioanalysis/scenarios/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of scenarios for saved analysis view */
export async function getSavedViewScenarios(
  customParamId: string,
  signal?: AbortSignal,
): Promise<OperationResult<Scenario[]>> {
  const endpoint = `/api/scenarioanalysis/scenarios/${encodeURIComponent(customParamId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Scenario[] = (await response.json()) as Scenario[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of scenarios for user */
export async function getScenarios(signal?: AbortSignal): Promise<OperationResult<Scenario[]>> {
  const endpoint = '/api/scenarioanalysis/scenarios';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Scenario[] = (await response.json()) as Scenario[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a list of valid system scenario index configurations */
export async function getDefaultIndicesForUser(
  signal?: AbortSignal,
): Promise<OperationResult<ScenarioAnalysisIndex[]>> {
  const endpoint = '/api/scenarioanalysis/indices';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ScenarioAnalysisIndex[] = (await response.json()) as ScenarioAnalysisIndex[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
