/*
 * Venn Platform API 1.0.0 Operations for "legal"
 * Automatically generated file - do not modify!
 */

import type {
  DocumentSummary,
  DocumentVersion,
  LegalAgreementEntry,
  LegalAgreementQuery,
  LegalDocument,
  LegalDocumentContent,
  LegalDocumentJurisdiction,
  LegalDocumentKeyEnum,
  LegalDocumentQuery,
  RequiredAgreementQuery,
  RequiredLegalDocument,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Accept legacy terms */
export async function acceptLegacyTerms(
  id: number,
  signal?: AbortSignal,
): Promise<OperationResult<LegalDocumentContent[]>> {
  const endpoint = `/api/legal/agree/legacy/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocumentContent[] = (await response.json()) as LegalDocumentContent[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Accept terms */
export async function acceptTerms(
  key: string,
  version?: string,
  signal?: AbortSignal,
): Promise<OperationResult<LegalDocumentContent[]>> {
  const endpoint = `/api/legal/agree/${encodeURIComponent(key)}${buildQuery({ version })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocumentContent[] = (await response.json()) as LegalDocumentContent[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Add document jurisdiction */
export async function addDocumentJurisdiction(
  key: string,
  version: string,
  countryCode: string,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/legal/doc/${encodeURIComponent(key)}/${encodeURIComponent(version)}/jurisdiction/${encodeURIComponent(countryCode)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Add document requirement */
export async function addDocumentRequirement(
  body?: Partial<RequiredLegalDocument>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/legal/require';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Admin get document file */
export async function getSpecificDocumentFile(
  key: string,
  version: string,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/legal/doc/${encodeURIComponent(key)}/${encodeURIComponent(version)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Admin get document text */
export async function getSpecificDocumentText(
  key: string,
  version: string,
  signal?: AbortSignal,
): Promise<OperationResult<LegalDocumentContent>> {
  const endpoint = `/api/legal/text/${encodeURIComponent(key)}/${encodeURIComponent(version)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocumentContent = (await response.json()) as LegalDocumentContent;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Admin search documents */
export async function searchDocuments(
  body?: Partial<LegalDocumentQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<LegalDocumentJurisdiction[]>> {
  const endpoint = '/api/legal/doc/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocumentJurisdiction[] = (await response.json()) as LegalDocumentJurisdiction[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Bulk accept terms */
export async function bulkAcceptTerms(
  body?: DocumentVersion[],
  signal?: AbortSignal,
): Promise<OperationResult<LegalDocumentContent[]>> {
  const endpoint = '/api/legal/agree';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocumentContent[] = (await response.json()) as LegalDocumentContent[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Deactivate legal document */
export async function deactivateLegalDocument(
  key: string,
  version: string,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/legal/doc/${encodeURIComponent(key)}/${encodeURIComponent(version)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Deactivate required document */
export async function removeDocumentRequirement(key: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/legal/require/${encodeURIComponent(key)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get document jurisdictions */
export async function getDocumentJurisdictions(
  key: string,
  version: string,
  signal?: AbortSignal,
): Promise<OperationResult<string[]>> {
  const endpoint = `/api/legal/doc/${encodeURIComponent(key)}/${encodeURIComponent(version)}/jurisdictions`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string[] = (await response.json()) as string[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get legacy pdf document */
export async function getLegacyDocumentFile(legacyId: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/legal/doc/legacy/${encodeURIComponent(legacyId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get legacy text */
export async function getLegacyDocumentText(
  legacyId: number,
  signal?: AbortSignal,
): Promise<OperationResult<LegalDocumentContent>> {
  const endpoint = `/api/legal/text/legacy/${encodeURIComponent(legacyId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocumentContent = (await response.json()) as LegalDocumentContent;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get legal document file */
export async function getDocumentFile(
  documentKey: string,
  jurisdiction?: string,
  version?: string,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/legal/doc/${encodeURIComponent(documentKey)}${buildQuery({ jurisdiction, version })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get legal document file */
export async function getDocumentText(
  documentKey: string,
  jurisdiction?: string,
  version?: string,
  signal?: AbortSignal,
): Promise<OperationResult<LegalDocumentContent>> {
  const endpoint = `/api/legal/text/${encodeURIComponent(documentKey)}${buildQuery({ jurisdiction, version })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocumentContent = (await response.json()) as LegalDocumentContent;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get legal document list */
export async function listDocumentSummaries(signal?: AbortSignal): Promise<OperationResult<DocumentSummary[]>> {
  const endpoint = '/api/legal/doc';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: DocumentSummary[] = (await response.json()) as DocumentSummary[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get legal documents a user has access to */
export async function getLegalDocuments(signal?: AbortSignal): Promise<OperationResult<LegalDocument[]>> {
  const endpoint = '/api/legal/documents';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocument[] = (await response.json()) as LegalDocument[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get legal keys */
export async function getLegalDocumentKeys(signal?: AbortSignal): Promise<OperationResult<LegalDocumentKeyEnum[]>> {
  const endpoint = '/api/legal/doc/keys';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocumentKeyEnum[] = (await response.json()) as LegalDocumentKeyEnum[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get required agreements to sign */
export async function getRequiredAgreements(signal?: AbortSignal): Promise<OperationResult<LegalDocumentContent[]>> {
  const endpoint = '/api/legal/agree';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocumentContent[] = (await response.json()) as LegalDocumentContent[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove document jurisdiction */
export async function removeDocumentJurisdiction(
  key: string,
  version: string,
  countryCode: string,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/legal/doc/${encodeURIComponent(key)}/${encodeURIComponent(version)}/jurisdiction/${encodeURIComponent(countryCode)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Search agreements */
export async function searchAgreements(
  body?: Partial<LegalAgreementQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<LegalAgreementEntry[]>> {
  const endpoint = '/api/legal/agree/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalAgreementEntry[] = (await response.json()) as LegalAgreementEntry[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Search all document requirements */
export async function searchDocumentRequirements(
  body?: Partial<RequiredAgreementQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<RequiredLegalDocument[]>> {
  const endpoint = '/api/legal/require/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: RequiredLegalDocument[] = (await response.json()) as RequiredLegalDocument[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Upload a legal document */
export async function uploadLegalDocument(body?: any, signal?: AbortSignal): Promise<OperationResult<LegalDocument>> {
  const endpoint = '/api/legal/doc';
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: LegalDocument = (await response.json()) as LegalDocument;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
