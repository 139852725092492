import type { ReactNode } from 'react';
import React from 'react';
import { BaseRecoilWrapper } from './BaseRecoilWrapper';
import { makeState } from './makeState';
import type { RecoilState } from 'recoil';

/**
 * Convenience utility to wrap a hook with a {@link BaseRecoilWrapper} and optional observers.
 *
 * @param states An optional array of Recoil initial state values
 * @param observers An optional array of JSX elements representing observers for the Recoil state, used as prepended siblings to the hook component.
 */
export const createRecoilHookWrapper = <T,>(states?: [RecoilState<T>, T][], observers?: JSX.Element[]) =>
  function RecoilHookWrapper({ children }: { children?: ReactNode }) {
    return (
      <BaseRecoilWrapper states={states?.map(makeState)}>
        {observers}
        {children}
      </BaseRecoilWrapper>
    );
  };
