import React, { useMemo } from 'react';
import { ErrorMultiWrapper } from './components/error-wrappers/ErrorMultiWrapper';
import { usePortfolioComparison } from './logic/usePortfolioComparison';
import ExportableGrid from './components/grid/ExportableGrid';
import { useIsBlockGridCompact } from './logic/useIsBlockGridCompact';
import { useCommonTreeGridProps } from './logic/columnUtils';
import { autoGroupColumnDefStaticConstants } from './components/grid/AgGridThemeOverrides';
import { getValueFormatZeroAsUndefined } from './logic/gridStyling';
import { useBlockId } from './contexts/BlockIdContext';
import { UnexpectedErrorWrapper } from './components/error-wrappers/UnexpectedErrorWrapper';
import { InfoGraphicTypeErrorWrapper } from './components/error-wrappers/InfoGraphicTypeErrorWrapper';
import { ComparisonMetricsErrorWrapper } from './components/error-wrappers/ComparisonMetricsErrorWrapper';
import ValueCellRenderer from './components/grid/renderers/ValueCellRenderer';
import { ComparisonSubjectsErrorWrapper } from './components/error-wrappers/ComparisonSubjectsErrorWrapper';
import { withSuspense } from 'venn-utils';
import { usePortfolioGridDisplaySettings } from './logic/usePortfolioGridDisplaySettings';
import { useLabelColumnWidth } from './logic/useLabelColumnMaxWidth';
import type { ColDef, ColGroupDef } from 'ag-grid-community';
import type { AllocationRowData } from './logic/usePortfolioComparisonUtils';
import { BlockSuspenseFallback } from './components/BlockSuspenseFallback';
import { ComparisonDuplicateSubjectsError } from './components/error-wrappers/ComparisonDuplicateSubjectsError';

const useAutoGroupColumnDef = (columnDefs: ColGroupDef<AllocationRowData>[]): ColDef => {
  const { maxWidth, minWidth } = useLabelColumnWidth(columnDefs);

  return useMemo(
    () => ({
      ...autoGroupColumnDefStaticConstants,
      autoHeight: true,
      minWidth,
      maxWidth,
      cellRendererParams: {
        ...autoGroupColumnDefStaticConstants.cellRendererParams,
        innerRendererParams: { editable: true },
      },
    }),
    [maxWidth, minWidth],
  );
};

const treeGridOverrides: ColDef<AllocationRowData> = {
  cellRenderer: ValueCellRenderer,
  valueFormatter: getValueFormatZeroAsUndefined,
};

const PortfolioComparisonBlockInner = () => {
  const blockId = useBlockId();
  const isCompact = useIsBlockGridCompact();

  const commonGridProps = useCommonTreeGridProps(treeGridOverrides);

  const { rowData, columnDefs, onCellValueChanged, pinnedBottomRowData } = usePortfolioComparison(blockId);
  const shouldExpand = usePortfolioGridDisplaySettings(rowData, columnDefs);
  const autoGroupColumnDef = useAutoGroupColumnDef(columnDefs);

  return (
    <ExportableGrid
      {...commonGridProps}
      exportable
      autoGroupColumnDef={autoGroupColumnDef}
      selectedRefId={blockId}
      isCompact={isCompact}
      rowData={rowData}
      columnDefs={columnDefs}
      shouldExpand={shouldExpand}
      onCellValueChanged={onCellValueChanged}
      pinnedBottomRowData={pinnedBottomRowData}
      treeData
    />
  );
};

const PurePortfolioComparisonBlock = () => {
  const blockId = useBlockId();
  return (
    <ErrorMultiWrapper
      wrappers={[
        UnexpectedErrorWrapper,
        InfoGraphicTypeErrorWrapper,
        ComparisonSubjectsErrorWrapper,
        ComparisonMetricsErrorWrapper,
        ComparisonDuplicateSubjectsError,
      ]}
      blockId={blockId}
    >
      <PortfolioComparisonBlockInner />
    </ErrorMultiWrapper>
  );
};

export const PortfolioComparisonBlock = React.memo(
  withSuspense(<BlockSuspenseFallback />, PurePortfolioComparisonBlock),
);
