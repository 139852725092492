import React, { useContext } from 'react';
import { DropMenu } from 'venn-ui-kit';
import styled from 'styled-components';
import RequiredLabel from '../../label';
import { UserContext } from '../../contexts';
import { getContextMenuItems } from '../../utils';
import type { ActionEnum } from 'venn-api';

const Label = styled(RequiredLabel)`
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  margin-top: 20px;
`;

interface OwnershipContextSelectorProps {
  setOwnerContextId: (x: string) => void;
  ownerContextId?: string;
  disabled?: boolean;
  requiredAction: ActionEnum;
}

const qaClass = 'qa-portfolio-ownership';

export const OwnershipContextSelector = ({
  disabled,
  ownerContextId,
  setOwnerContextId,
  requiredAction,
}: OwnershipContextSelectorProps) => {
  const userContextValue = useContext(UserContext);
  const { currentContext } = userContextValue;
  const availableContexts = userContextValue.profileSettings?.availableContexts;
  const items = getContextMenuItems(availableContexts, currentContext, requiredAction);
  return (
    <Wrapper className={qaClass}>
      <Label required>Ownership:</Label>
      <DropMenu
        disabled={disabled}
        selected={ownerContextId}
        onChange={(item) => setOwnerContextId(item.value)}
        items={items}
      />
    </Wrapper>
  );
};
