import { isNil } from 'lodash';
import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil';
import type { PrivatePortfolioNode } from 'venn-api';
import type { AnalysisSubject } from 'venn-utils';
import { recoilHistoryEffect } from '../../effects';
import { resetOnStudioReset } from '../../effects/signalEffects';
import { unsavedChangesEffect } from '../../effects/unsavedChangesEffect';
import type { AllocatorConfig, PrivateAllocatorConfig, Subject } from '../types';
import { toSubjectOnly } from '../types';

export const openAllocatorSubject = atom<Subject | undefined>({
  key: 'openAllocatorSubject',
  default: undefined,
  effects: [recoilHistoryEffect, resetOnStudioReset],
});

export const openPrivateAllocatorPortfolioAtom = atom<PrivatePortfolioNode | undefined>({
  key: 'openPrivateAllocatorPortfolioAtom',
  default: undefined,
  effects: [recoilHistoryEffect, resetOnStudioReset],
});

export const openPrivateAllocatorConfig = atom<PrivateAllocatorConfig | undefined>({
  key: 'openPrivateAllocatorConfig',
  default: undefined,
  effects: [unsavedChangesEffect, resetOnStudioReset],
});

export const openPrivateAllocatorPortfolio = selector<PrivatePortfolioNode | undefined>({
  key: 'openPrivateAllocatorPortfolio',
  get: ({ get }) => {
    return get(openPrivateAllocatorPortfolioAtom);
  },
  set: ({ set, reset }, value) => {
    set(openPrivateAllocatorPortfolioAtom, value);
    if (value instanceof DefaultValue) {
      reset(openPrivateAllocatorPortfolioAtom);
      reset(openPrivateAllocatorConfig);
      return;
    }

    set(openPrivateAllocatorPortfolioAtom, value);
    set(openPrivateAllocatorConfig, value?.id ? { privatePortfolioId: value.id } : undefined);
  },
});

export const isAnyAllocatorOpen = selector<boolean>({
  key: 'isAnyAllocatorOpen',
  get: ({ get }) => {
    return !isNil(get(openAllocatorSubject)) || !isNil(get(openPrivateAllocatorPortfolio));
  },
});

// Contains the part of the Allocator settings that get saved in the analysis view
export const openAllocatorSubjectConfig = atom<AllocatorConfig | undefined>({
  key: 'openAllocatorSubjectConfig',
  default: undefined,
  effects: [unsavedChangesEffect],
});

const allocatorAnalysisSubjectInternal = atomFamily<AnalysisSubject | undefined, Subject | undefined>({
  key: 'allocatorAnalysisSubjectInternal',
  default: undefined,
  effects: [recoilHistoryEffect, resetOnStudioReset],
});

export const allocatorAnalysisSubject = selectorFamily<AnalysisSubject | undefined, Subject | undefined>({
  key: 'allocatorAnalysisSubject',
  get:
    (subject) =>
    ({ get }) => {
      return get(allocatorAnalysisSubjectInternal(toSubjectOnly(subject)));
    },
  set:
    (subject) =>
    ({ set }, value) => {
      set(allocatorAnalysisSubjectInternal(toSubjectOnly(subject)), value);
    },
});

export const secondarySubject = selectorFamily<Subject | undefined, Subject>({
  key: 'secondarySubject',
  get:
    (subject) =>
    ({ get }) => {
      const allocatorSubject = get(allocatorAnalysisSubject(subject));
      if (allocatorSubject?.secondaryPortfolio) {
        return {
          portfolioId: allocatorSubject.secondaryPortfolio.id,
          portfolioVersion: allocatorSubject.secondaryPortfolio.version,
        };
      }
      return undefined;
    },
});

export const compareColumnOpen = atom<boolean>({
  key: 'compareColumnOpen',
  default: false,
});

export const hasAllocationPercentError = atom<boolean>({
  key: 'hasAllocationPercentError',
  default: false,
});
