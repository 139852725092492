import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  CheckboxWrapper,
  EllipsisTooltipSpan,
  EmptyStateIcon,
  GetColor,
  Headline3,
  Hint,
  Icon,
  Tooltip,
} from 'venn-ui-kit';
import { FilterMenuTrigger, useSelectState } from './shared';
import { SidePanelOverlay, TypeaheadSearchBar } from 'venn-components';
import styled from 'styled-components';
import { LinkButton } from '../../StyledComponents';
import { toClassName } from 'venn-utils';

interface TagFilterProps {
  /** Whether the trigger to filter by tags is disabled or not */
  disabled?: boolean;
  /** The selectable tags for the filter */
  tags: string[];
  /** The initially selected tags */
  initialSelected: string[];
  /**
   * The function to call with the updated selection.
   */
  onFilter: (updated: string[]) => void;
  /** Tooltip content to display for the trigger */
  tooltipContent?: string;
  /** Badge to display after the label, if any */
  badge?: JSX.Element;
}

const label = 'Tags';

const TagFilter = ({ disabled, tags, initialSelected, onFilter, tooltipContent, badge }: TagFilterProps) => {
  const [isOpen, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>();

  const { selectedState, resetState, onOptionClick } = useSelectState(initialSelected);

  const applyChanges = () => {
    onFilter(Array.from(selectedState));
    setOpen(false);
  };

  const onCancel = () => {
    resetState();
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen((prevOpen) => {
      if (prevOpen) {
        onCancel();
      }
      return !prevOpen;
    });
  };

  const filteredTags = useMemo(() => {
    return tags.filter((tag) => tag.toLowerCase().includes(query.trim().toLowerCase()));
  }, [query, tags]);

  const clearQuery = () => setQuery('');

  const trigger = (
    <FilterMenuTrigger
      icon="tag"
      disabled={disabled}
      label={label}
      onClick={toggleOpen}
      aria-haspopup
      aria-expanded={isOpen}
      badge={badge}
    />
  );

  const renderTagOption = (tag: string) => {
    const checked = selectedState.has(tag);
    return (
      <CheckboxWrapper
        className={`${toClassName(tag)}-tag-filter`}
        key={tag}
        justify="left"
        checked={checked}
        onChange={() => onOptionClick(tag)}
      >
        <TagLabel>
          <Icon type="tag" />
          &nbsp;&nbsp;
          <EllipsisTooltipSpan maxWidth={280} usePortal flex>
            {tag}
          </EllipsisTooltipSpan>
        </TagLabel>
      </CheckboxWrapper>
    );
  };

  return (
    <>
      {tooltipContent ? (
        <Tooltip content={tooltipContent} usePortal showShadow>
          {trigger}
        </Tooltip>
      ) : (
        trigger
      )}
      {!disabled && (
        <SidePanelOverlay
          focusOnOpen={inputRef}
          className="qa-tags-side-panel"
          isOpen={isOpen}
          side="left"
          title={
            <>
              <Icon type="tag" />
              &nbsp; TAGS
            </>
          }
          handleClose={onCancel}
          width={390}
          mainContentElement={document.getElementsByTagName('main')[0]}
          content={
            <TagsFilterContainer>
              <SearchBarContainer>
                <TypeaheadSearchBar
                  disableAutofocus
                  placeholder="Search tags"
                  value={query}
                  onChange={setQuery}
                  inputRefProp={inputRef}
                />
              </SearchBarContainer>
              <TagsContainer>
                {filteredTags.length === 0 && (
                  <EmptyState data-testid="qa-tags-empty-state">
                    <EmptyStateIcon />
                    <Headline3 center>No tags found</Headline3>
                    {query.length > 0 && (
                      <Hint>
                        <LinkButton className="qa-clear-tag-query" onClick={clearQuery}>
                          Clear query
                        </LinkButton>{' '}
                        to view all tags
                      </Hint>
                    )}
                  </EmptyState>
                )}
                {filteredTags.map(renderTagOption)}
              </TagsContainer>
            </TagsFilterContainer>
          }
          footer={
            <>
              <Button key="cancel" className="qa-cancel-filter" dense onClick={onCancel}>
                Cancel
              </Button>
              <Button key="submit" type="submit" dense dominant onClick={applyChanges}>
                Select
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

export default TagFilter;

const SearchBarContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`;

const TagsFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TagsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const EmptyState = styled.div`
  text-align: center;
  margin-top: 60px;
`;

const TagLabel = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  i {
    color: ${GetColor.Primary.Dark};
  }
`;
