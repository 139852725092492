/*
 * Venn Platform API 1.0.0 Operations for "sponsor"
 * Automatically generated file - do not modify!
 */

import type {
  HomeContent,
  SignupRequest,
  Sponsor,
  SponsorInvitation,
  SponsoredAccountsQuery,
  SponsoredAccountsResults,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Delete the current custom content defined by this account for sponsored accounts */
export async function deleteSponsoredContent(signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = '/api/sponsor/content';
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the accounts sponsored by user */
export async function getSponsoredAccounts(
  body?: Partial<SponsoredAccountsQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<SponsoredAccountsResults>> {
  const endpoint = '/api/sponsor/accounts';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: SponsoredAccountsResults = (await response.json()) as SponsoredAccountsResults;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the custom content defined by this account for sponsored accounts */
export async function getSponsoredContent(signal?: AbortSignal): Promise<OperationResult<HomeContent>> {
  const endpoint = '/api/sponsor/content';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: HomeContent = (await response.json()) as HomeContent;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the information for a sponsor */
export async function getSponsor(id: number, signal?: AbortSignal): Promise<OperationResult<Sponsor>> {
  const endpoint = `/api/sponsor/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Sponsor = (await response.json()) as Sponsor;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the sponsors information for a given key */
export async function getInvitation(key: string, signal?: AbortSignal): Promise<OperationResult<Sponsor>> {
  const endpoint = `/api/sponsor/invitation/key/${encodeURIComponent(key)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Sponsor = (await response.json()) as Sponsor;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove sponsorship invitations */
export async function removeSponsorshipInvitations(
  body?: string[],
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/sponsor/invitation';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Resend a sponsorship invitation */
export async function resendInvitation(
  body?: string,
  signal?: AbortSignal,
): Promise<OperationResult<SponsorInvitation>> {
  const endpoint = '/api/sponsor/invitation/resend';
  const requestInit = buildRequestInit({
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: SponsorInvitation = (await response.json()) as SponsorInvitation;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Send a sponsorship invitation */
export async function sendInvitation(
  body?: Partial<SignupRequest>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/sponsor/invitation';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Unsponsor an account */
export async function unsponsorAccount(id: number, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/sponsor/accounts/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update a sponsor */
export async function updateSponsor(body?: Partial<Sponsor>, signal?: AbortSignal): Promise<OperationResult<Sponsor>> {
  const endpoint = '/api/sponsor';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: Sponsor = (await response.json()) as Sponsor;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update the custom content defined by this account for sponsored accounts */
export async function updateSponsoredContent(
  body?: Partial<HomeContent>,
  signal?: AbortSignal,
): Promise<OperationResult<HomeContent>> {
  const endpoint = '/api/sponsor/content';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: HomeContent = (await response.json()) as HomeContent;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Use a sponsorship invitation key */
export async function useKey(key: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/sponsor/invitation/key/${encodeURIComponent(key)}/use`;
  const requestInit = buildRequestInit({ method: 'PUT', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
