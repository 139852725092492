/*
 * Venn Platform API 1.0.0 Operations for "homecontent"
 * Automatically generated file - do not modify!
 */

import type { HomeContent } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Get the home content for the current user */
export async function getHomeContent(signal?: AbortSignal): Promise<OperationResult<HomeContent>> {
  const endpoint = '/api/homecontent';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: HomeContent = (await response.json()) as HomeContent;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
