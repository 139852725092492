import React, { Component } from 'react';
import styled from 'styled-components';
import type { FactorWithNonSerializedReturns as FactorEntity, FrequencyEnum } from 'venn-api';
import type { SearchMenuItem } from 'venn-components';
import {
  SliderToggle,
  FactorSelect,
  ConfigurationLabel as Label,
  subjectToSearchMenuItem,
  SearchMenuBar,
} from 'venn-components';
import { DateRangePicker, ZIndex, GetColor } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import { FREQUENCY_DATEPICKER_MODES, hasAnalysisSubjectNoReturns } from 'venn-utils';

interface ConfigurationProps {
  startTimestamp?: number;
  endTimestamp?: number;
  minTimestamp?: number;
  maxTimestamp?: number;
  showInputs: boolean;
  factors: FactorEntity[];
  inputsEnabled: boolean;
  subject?: AnalysisSubject;
  selectedFactors?: FactorEntity[];
  frequency?: FrequencyEnum;

  onFactorSelect(factors: FactorEntity[]): void;

  onSubjectSelect(subject?: AnalysisSubject): void;

  onInputsToggle(): void;

  onDateChange(start?: number, end?: number): void;
}

const qaClasses = {
  factor: 'qa-select-factor',
  period: 'qa-period',
  inputs: 'qa-factor-inputs',
  fundSearch: 'qa-search-menu-bar',
};

export default class Configuration extends Component<ConfigurationProps> {
  searchValue = this.props.subject ? subjectToSearchMenuItem({ subject: this.props.subject }) : undefined;

  onSearchSelect = (search: SearchMenuItem | null = null) => {
    this.props.onSubjectSelect(search?.value);
  };

  optionDisabledTooltipContent = (option: SearchMenuItem) =>
    // The portfolio objects in the search results don't have their allocations and children
    // populated so we're actually not able to properly disable those options
    option.value?.fund && hasAnalysisSubjectNoReturns(option.value)
      ? 'This investment has no returns and cannot be analyzed'
      : '';

  isOptionDisabled = (option: SearchMenuItem) => hasAnalysisSubjectNoReturns(option.value);

  render() {
    const {
      factors,
      showInputs,
      startTimestamp,
      endTimestamp,
      onFactorSelect,
      onInputsToggle,
      inputsEnabled,
      maxTimestamp,
      minTimestamp,
      selectedFactors,
      frequency,
      onDateChange,
    } = this.props;

    return (
      <StyledConfiguration>
        <FactorSelect
          className={qaClasses.factor}
          factors={factors}
          onChange={onFactorSelect}
          selectedFactors={selectedFactors}
        />

        <StyledSearchMenuBar
          defaultValue={this.searchValue}
          onSelected={this.onSearchSelect}
          onClear={this.onSearchSelect}
          defaultMenuIsOpen={false}
          autofocus={false}
          className={qaClasses.fundSearch}
          optionDisabledTooltipContent={this.optionDisabledTooltipContent}
          isOptionDisabled={this.isOptionDisabled}
          fixedMenuWidth
          location="factorInsights"
          privateAssetSearchMode="PUBLIC_ONLY"
        />

        <Label label="Show Factor Inputs:" className={qaClasses.inputs}>
          <SliderToggle
            disabled={!inputsEnabled}
            toggled={showInputs && inputsEnabled}
            onToggle={onInputsToggle}
            tooltipMessage={!inputsEnabled ? 'Select one factor to enable inputs' : undefined}
          />
        </Label>

        <TimeframeLabel label="Analysis Period:" className={qaClasses.period}>
          <TimeframeWrapper>
            {!startTimestamp && !endTimestamp && (
              <NullState>
                &ndash;&ndash; <span>-</span> &ndash;&ndash;
              </NullState>
            )}
            <DateRangePicker
              value={{
                from: Math.max(startTimestamp || 0, minTimestamp || 0),
                to: Math.min(endTimestamp || Number.MAX_SAFE_INTEGER, maxTimestamp || Number.MAX_SAFE_INTEGER),
              }}
              range={{ from: minTimestamp, to: maxTimestamp }}
              granularity={FREQUENCY_DATEPICKER_MODES[frequency ?? 'DAILY']}
              onChange={(range) => {
                if (!range) {
                  return;
                }
                const { from, to } = range;
                onDateChange(from, to);
              }}
            />
          </TimeframeWrapper>
        </TimeframeLabel>
      </StyledConfiguration>
    );
  }
}

const StyledConfiguration = styled.div`
  position: relative;
  padding: 0 20px 20px;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 35px;
  background-color: ${GetColor.White};

  > *:not(:last-child) {
    margin-right: 20px;
  }

  &:after {
    height: 35px;
    left: 20px;
    right: 20px;
    background: ${GetColor.Black};
    content: '';
    position: absolute;
    bottom: -35px;
  }
`;

const StyledSearchMenuBar = styled(SearchMenuBar)`
  width: 500px;
  height: 33px;

  & > div {
    min-height: 33px;
  }
`;

const TimeframeWrapper = styled.div`
  position: relative;

  .timeframeTrigger {
    min-width: 170px;
    box-shadow: 0 1px 0 0 ${GetColor.Grey};
    border: solid 1px ${GetColor.PaleGrey};
    padding: 9px 20px 9px 15px;
    font-weight: normal;
    font-family: ${(props) => props.theme.Typography.fontFamily};
    font-size: 14px;
  }

  .dateRangeContent {
    margin-top: 16px;
  }
`;

const TimeframeLabel = styled(Label)`
  margin-left: auto;

  & > div {
    color: ${GetColor.Black};
  }
`;

const NullState = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: ${ZIndex.Front};
  top: 0;
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin: 0 25px;
  }
`;
