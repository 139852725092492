import type { ComponentProps } from 'react';
import React from 'react';
import { basicAuthedStudio } from './commonTestStates';
import { BaseRecoilWrapper } from 'venn-test/recoil';

/** Wraps {@link BaseRecoilWrapper} with some handy default states like auth and a mock theme. */
export const StudioRecoilWrapper = ({ children, states, ...otherProps }: ComponentProps<typeof BaseRecoilWrapper>) => {
  return (
    <BaseRecoilWrapper {...otherProps} states={[...basicAuthedStudio(), ...(states ?? [])]}>
      {children}
    </BaseRecoilWrapper>
  );
};
