import { isArray } from 'lodash';
import { getCsrfToken } from '../csrf';
import type { Interceptor } from '@connectrpc/connect';

export function buildQuery(params: { [key: string]: unknown }): string {
  const queryParams = Object.keys(params)
    .filter((name) => params[name] !== undefined)
    .map((name) => {
      const value = params[name];
      if (isArray(value)) {
        return value.map((item) => `${name}=${encodeURIComponent(item)}`).join('&');
      }
      return `${name}=${encodeURIComponent(value as string | number | boolean)}`;
    });
  if (queryParams.length === 0) {
    return '';
  }
  return `?${queryParams.join('&')}`;
}

export function buildRequestInit(base: Partial<RequestInit>): RequestInit {
  const request = {
    credentials: 'include' as const,
    ...base,
  };

  if (!request.headers) {
    request.headers = {};
  }

  const token = getCsrfToken();

  if (token) {
    request.headers['X-Csrf-Token'] = token;
  }

  const context = ContextService.getCurrentContext();

  if (context) {
    request.headers['User-Context'] = context;
  }

  return request;
}

export async function extractError(response: Response): Promise<OperationResult<OperationError>> {
  try {
    const content = (await response.json()) as OperationError;
    return {
      content,
      status: response.status,
      headers: {},
    };
  } catch (error) {
    let message = 'Unknown error';
    if (response.status === 401) {
      message = response.statusText || 'Unauthorized';
    }
    return {
      status: response.status,
      headers: {},
      content: {
        message,
        originalError: error,
      },
    };
  }
}

export interface OperationResult<Content> {
  readonly status: number;
  readonly headers: { readonly [key: string]: string };
  readonly content: Content;
}

export interface OperationError {
  readonly message: string;
  readonly originalError: unknown;
}

export class ContextService {
  private static currentContext: string | undefined;

  public static setCurrentContext(newContext: string | undefined) {
    this.currentContext = newContext;
  }

  public static getCurrentContext() {
    return this.currentContext;
  }
}

export const vennHeadersInterceptor: Interceptor = (next) => async (req) => {
  const token = getCsrfToken();

  if (token) {
    req.header.set('X-Csrf-Token', token);
  }

  const context = ContextService.getCurrentContext();

  if (context) {
    req.header.set('User-Context', context);
  }

  return next(req);
};
