/*
 * Venn Platform API 1.0.0 Operations for "user"
 * Automatically generated file - do not modify!
 */

import type {
  AppUser,
  AuthenticatedResponse,
  CookiePolicyTypeEnum,
  CustomSetting,
  EmailSubscriptionSetting,
  EnableMFARequest,
  GdprStatus,
  MFAResponse,
  NamedEntityInteger,
  PageLoginAuditEntry,
  PagedSearchString,
  ResponseBody,
  ResponseBodyBoolean,
  TableWorkspace,
  UpdatePassword,
  UserCookiePolicyStatus,
  UserOrgRoleEnum,
  UserProfileSettings,
  UserSettings,
  WorkspaceUser,
} from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Add a new system setting for a set of users */
export async function addSystemSetting(
  body?: Partial<CustomSetting>,
  signal?: AbortSignal,
): Promise<OperationResult<UserSettings>> {
  const endpoint = '/api/user/settings';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserSettings = (await response.json()) as UserSettings;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Agree to a specific agreement */
export async function agreeToTerms(agreementId: number, signal?: AbortSignal): Promise<OperationResult<GdprStatus>> {
  const endpoint = `/api/user/agreement/${encodeURIComponent(agreementId)}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: GdprStatus = (await response.json()) as GdprStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Check if IP is under GPDR  */
export async function checkIPGdprApplied(signal?: AbortSignal): Promise<OperationResult<boolean>> {
  const endpoint = '/api/user/gdpr';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: boolean = (await response.json()) as boolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Check if user is authenticated */
export async function isAuthenticated(signal?: AbortSignal): Promise<OperationResult<AuthenticatedResponse>> {
  const endpoint = '/api/user/authenticated';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AuthenticatedResponse = (await response.json()) as AuthenticatedResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Check if userID has MFA enabled */
export async function hasMFA(signal?: AbortSignal): Promise<OperationResult<ResponseBodyBoolean>> {
  const endpoint = '/api/user/mfa';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ResponseBodyBoolean = (await response.json()) as ResponseBodyBoolean;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Enable specific user's MFA */
export async function disableMFA(signal?: AbortSignal): Promise<OperationResult<MFAResponse>> {
  const endpoint = '/api/user/mfa';
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MFAResponse = (await response.json()) as MFAResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Enable specific user's MFA */
export async function enableMFA(
  body?: Partial<EnableMFARequest>,
  signal?: AbortSignal,
): Promise<OperationResult<MFAResponse>> {
  const endpoint = '/api/user/mfa';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MFAResponse = (await response.json()) as MFAResponse;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get a page of the user's login history ordered by descending login time */
export async function getUserLogins(
  body?: Partial<PagedSearchString>,
  signal?: AbortSignal,
): Promise<OperationResult<PageLoginAuditEntry>> {
  const endpoint = '/api/user/logins';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PageLoginAuditEntry = (await response.json()) as PageLoginAuditEntry;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get email subscriptions */
export async function getUserEmailSubscriptions(
  signal?: AbortSignal,
): Promise<OperationResult<EmailSubscriptionSetting[]>> {
  const endpoint = '/api/user/settings/subscriptions';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: EmailSubscriptionSetting[] = (await response.json()) as EmailSubscriptionSetting[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get required agreements to sign */
export async function getAgreements(signal?: AbortSignal): Promise<OperationResult<NamedEntityInteger[]>> {
  const endpoint = '/api/user/agreement';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: NamedEntityInteger[] = (await response.json()) as NamedEntityInteger[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get settings for a user */
export async function getUserSettings(signal?: AbortSignal): Promise<OperationResult<UserSettings>> {
  const endpoint = '/api/user/settings';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserSettings = (await response.json()) as UserSettings;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the current users profile */
export async function getProfile(signal?: AbortSignal): Promise<OperationResult<UserProfileSettings>> {
  const endpoint = '/api/user/profile';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserProfileSettings = (await response.json()) as UserProfileSettings;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the user's cookie policy status */
export async function getUserAcceptCookiePolicy(
  signal?: AbortSignal,
): Promise<OperationResult<UserCookiePolicyStatus>> {
  const endpoint = '/api/user/cookie-policy';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserCookiePolicyStatus = (await response.json()) as UserCookiePolicyStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get user role in organization options */
export async function getUserOrgRoleOptions(signal?: AbortSignal): Promise<OperationResult<{ [key: string]: string }>> {
  const endpoint = '/api/user/organizationrole/options';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: { [key: string]: string } = (await response.json()) as { [key: string]: string };
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get user's role for the current organization */
export async function getUserOrganizationRole(signal?: AbortSignal): Promise<OperationResult<UserOrgRoleEnum>> {
  const endpoint = '/api/user/organizationrole';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserOrgRoleEnum = (await response.json()) as UserOrgRoleEnum;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get workspaces a user is a member of */
export async function getSwitchableWorkspaces(signal?: AbortSignal): Promise<OperationResult<TableWorkspace[]>> {
  const endpoint = '/api/user/organizations';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: TableWorkspace[] = (await response.json()) as TableWorkspace[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Persist current users profile */
export async function updateProfile(
  body?: Partial<UserProfileSettings>,
  signal?: AbortSignal,
): Promise<OperationResult<UserProfileSettings>> {
  const endpoint = '/api/user/profile';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserProfileSettings = (await response.json()) as UserProfileSettings;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Remove a system setting for a set of users */
export async function removeSystemSetting(
  body?: Partial<CustomSetting>,
  signal?: AbortSignal,
): Promise<OperationResult<UserSettings>> {
  const endpoint = '/api/user/settings';
  const requestInit = buildRequestInit({
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserSettings = (await response.json()) as UserSettings;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Switch between workspaces */
export async function switchWorkspaces(
  body?: Partial<WorkspaceUser>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/user/organization';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update all settings for a user */
export async function updateAllUserSettings(
  body?: { [key: string]: any },
  signal?: AbortSignal,
): Promise<OperationResult<UserSettings>> {
  const endpoint = '/api/user/settings';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserSettings = (await response.json()) as UserSettings;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update display name for a user */
export async function updateDisplayName(
  body?: Partial<AppUser>,
  signal?: AbortSignal,
): Promise<OperationResult<AppUser>> {
  const endpoint = '/api/user/displayName';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AppUser = (await response.json()) as AppUser;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update password for a user */
export async function updatePassword(
  body?: Partial<UpdatePassword>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/user/password';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update selected settings for a user */
export async function updateSelectUserSettings(
  body?: { [key: string]: any },
  signal?: AbortSignal,
): Promise<OperationResult<UserSettings>> {
  const endpoint = '/api/user/settings';
  const requestInit = buildRequestInit({
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserSettings = (await response.json()) as UserSettings;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update the user's cookie policy status */
export async function updateUserAcceptCookiePolicy(
  body?: CookiePolicyTypeEnum[],
  signal?: AbortSignal,
): Promise<OperationResult<UserCookiePolicyStatus>> {
  const endpoint = '/api/user/cookie-policy';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: UserCookiePolicyStatus = (await response.json()) as UserCookiePolicyStatus;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update user email subscriptions */
export async function updateEmailSubscription(
  body?: Partial<EmailSubscriptionSetting>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = '/api/user/settings/subscriptions';
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Update user's role in the current organization */
export async function updateUserOrganizationRole(
  userId: number,
  body?: UserOrgRoleEnum,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/user/${encodeURIComponent(userId)}/organizationrole`;
  const requestInit = buildRequestInit({
    method: 'PUT',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Validate that a user knows the old/current password */
export async function validateCurrentPassword(
  body?: Partial<UpdatePassword>,
  signal?: AbortSignal,
): Promise<OperationResult<ResponseBody>> {
  const endpoint = '/api/user/password/validate';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ResponseBody = (await response.json()) as ResponseBody;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
