import React, { useState } from 'react';
import styled from 'styled-components';
import { EllipsisTooltipSpan, GetColor, TooltipPosition } from 'venn-ui-kit';
import Dropdown from '../Dropdown';
import type { FundCellProps } from '../map-data/NavsFundCell';
import type { Option as FundTypeaheadOption } from '../../../../fund-typeahead/types';
import FundLinker from '../map-data/FundLinker';
import { DataUploaderMode } from '../../types';

export const PrivateFundCell = ({
  fund,
  disabled,
  alwaysEnableAppendTypeSelector,
  settingsOptions,
  settings,
  onSettingsChanged,
  className,
  opensUp,
  isNew,
  onLink,
  onLinkNew,
}: FundCellProps) => {
  const [selectorOpen, updateSelector] = useState(false);

  const allowUnlink = false; // TODO(VENN-23680): remove this when ready to link/unlink investments
  return (
    <Container className={className}>
      <Center>
        <FundName greyed={disabled}>
          <EllipsisTooltipSpan
            position={TooltipPosition.Bottom}
            maxWidth={200}
            onClick={() => updateSelector(!selectorOpen)}
          >
            {fund}
          </EllipsisTooltipSpan>
          {allowUnlink && (
            <StyledFundLinker
              className="qa-rename-fund"
              leftPosition={-30}
              disabled={disabled}
              isNew={isNew}
              onFundSelect={(selectedFund: FundTypeaheadOption) => selectedFund.fund && onLink(selectedFund.fund)}
              onNewSelect={({ label }) => onLinkNew(label)}
              value={fund}
              selectorOpen={selectorOpen}
              updateSelector={updateSelector}
              mode={DataUploaderMode.Privates}
            />
          )}
        </FundName>
        <FundInfo>
          <Settings className="qa-currency">
            {settingsOptions.map((setting, index) => (
              <Dropdown
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                opensUp={opensUp}
                options={setting.options}
                readonly={
                  setting.readonly || (disabled && !(alwaysEnableAppendTypeSelector && setting.isAppendSetting))
                }
                value={settings[index]}
                onChange={(value) => {
                  if (onSettingsChanged) {
                    const newSettings = [...settings.slice(0, index), value, ...settings.slice(index + 1)];
                    onSettingsChanged(newSettings);
                  }
                }}
              />
            ))}
          </Settings>
        </FundInfo>
      </Center>
    </Container>
  );
};

const Container = styled.div`
  width: 250px;
  display: flex;
`;

const FundName = styled.div<{ greyed: boolean }>`
  font-size: 14px;
  font-weight: bold;
  color: ${({ greyed }) => (greyed ? GetColor.MidGrey2 : GetColor.Black)};
  margin-top: 2px;
  display: flex;
  align-items: center;
`;

const Settings = styled.div`
  display: inline-flex;
  font-size: 10px;
  color: ${GetColor.Primary.Dark};

  > div {
    margin-right: 15px;
  }
`;

const Center = styled.div`
  flex: 1;
`;

const FundInfo = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFundLinker = styled(FundLinker)`
  font-size: 12px;
  margin-left: 6px;
`;
