import React, { useContext, useState, useEffect, useMemo } from 'react';
import { FactorLensesContext } from '../../../contexts';
import type { DateRange, RangeType } from 'venn-ui-kit';
import { Headline3, Link as LinkStyle, Body1, Subtitle2, FORECASTS_FAQ_HREF } from 'venn-ui-kit';
import type { CustomFactorForecast } from 'venn-api';
import EditableText from './EditableText';
import { FORECAST_INPUT_TEXT } from '../components/shared';
import { ForecastPanelDateEditor } from '../components/ForecastPanelDateEditor';
import styled from 'styled-components';
import { ForecastPanelActionsContext } from '../contexts/ForecastPanelActionsContext';

const DATE_RANGE_OPTIONS: RangeType[] = ['1yr', '3yr', '5yr'];

interface HistoricalPeriodEditorV2Props {
  forecast?: CustomFactorForecast;
  onCancel: () => void;
}

export const HistoricalPeriodEditorV2 = ({ forecast, onCancel }: HistoricalPeriodEditorV2Props) => {
  const [name, setName] = useState<string>(forecast?.name ?? '');
  const { onUpdateOrCreateFactorForecast } = useContext(ForecastPanelActionsContext);
  // refresh the name when user starts editing a different forecast
  useEffect(() => {
    setName(forecast?.name ?? '');
  }, [forecast?.name]);

  const factorContext = useContext(FactorLensesContext);

  const range = useMemo(
    () => ({
      from: factorContext.primaryFactorLens?.latestStartDate,
      to: factorContext.primaryFactorLens?.earliestEndDate,
    }),
    [factorContext],
  );

  const onUpdateDate = async (dateRange: DateRange) => {
    const updatedForecast = forecast
      ? {
          ...forecast,
          name,
          startDate: dateRange?.from,
          endDate: dateRange?.to,
        }
      : {
          name,
          startDate: dateRange?.from,
          endDate: dateRange?.to,
        };
    await onUpdateOrCreateFactorForecast(updatedForecast as CustomFactorForecast, !forecast);
    setDateRangeValue(dateRange);
  };

  const [dateRangeValue, setDateRangeValue] = useState<DateRange>(range);

  useEffect(() => {
    const forecastRange =
      forecast && forecast.startDate ? ({ from: forecast.startDate, to: forecast.endDate } as DateRange) : range;

    setDateRangeValue(forecastRange);
  }, [forecast, range]);

  return (
    <>
      <HistoricalPeriodHeadline>Select a Historical Period</HistoricalPeriodHeadline>
      <HistoricalPeriodBody>
        <LinkStyle>
          <a target="_blank" rel="noopener noreferrer" href={FORECASTS_FAQ_HREF}>
            Learn more{' '}
          </a>
        </LinkStyle>{' '}
        about how the selected period is translated into your forecasts.
      </HistoricalPeriodBody>
      <Subtitle2>Enter Forecast Name</Subtitle2>
      <NameEditorWrapper>
        <EditableText
          data-testid="historical-forecast-name"
          text={name}
          placeholder={FORECAST_INPUT_TEXT}
          displayEditViewByDefault
          allowEmptyStrings
          onSubmit={setName}
        />
      </NameEditorWrapper>
      <ForecastPanelDateEditor
        dateRange={dateRangeValue}
        onApply={onUpdateDate}
        onCancel={onCancel}
        disabled={name === ''}
        options={DATE_RANGE_OPTIONS}
        layout="left"
      />
    </>
  );
};

const NameEditorWrapper = styled.div`
  margin-left: 6px;
  margin-bottom: 20px;
`;

const HistoricalPeriodHeadline = styled(Headline3)`
  margin-top: 18px;
  margin-bottom: 10px;
`;

const HistoricalPeriodBody = styled(Body1)`
  margin-bottom: 20px;
`;
