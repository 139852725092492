import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ColorUtils, GetColor, Headline2, Icon, ZIndex } from 'venn-ui-kit';
import { FileLayout } from './SampleData';
import HorizontalSample from './images/upload/horizontal.png';
import VerticalSample from './images/upload/vertical.png';
import DBSample from './images/upload/vertical-db.png';
import GridSample from './images/upload/returns-grid.png';
import MultiDateSample from './images/upload/multi-date.png';

export const FileIcon = ({
  label,
  color,
  solid,
  multiline,
  icon,
}: {
  label?: string;
  icon?: string;
  color: string;
  solid?: boolean;
  multiline?: boolean;
}) => {
  const { Colors } = useContext(ThemeContext);
  return (
    <span className="fa-stack fa-lg">
      <StyledIcon className="fas fa-file fa-stack-2x" color={solid ? ColorUtils.opacify(color, 0.15) : Colors.White} />
      <StyledIcon className="fal fa-file fa-stack-2x" color={color} />
      {icon ? (
        <CenterIcon className={`fa fa-${icon} fa-stack-1x`} color={color} />
      ) : (
        <Label className="fa fa-stack-1x" multiline={multiline}>
          {label}
        </Label>
      )}
    </span>
  );
};

const StyledIcon = styled.i<{ color: string }>`
  color: ${(props) => props.color};
`;

const CenterIcon = styled(StyledIcon)`
  font-size: 0.7em;
  top: 0.25em;
`;

const Label = styled.i<{ multiline?: boolean }>`
  color: ${GetColor.Black};
  font-size: ${(props) => (props.multiline ? 0.3 : 0.45)}em;
  top: 2em;
  font-family: ${(props) => props.theme.Typography.fontFamily};
  line-height: normal;
  ${(props) => props.multiline && 'padding: 1em;'}
`;

const LeftFile = styled.div`
  transform: rotate(-10deg);
  font-size: 25px;
  position: relative;
  left: 40px;
  top: 10px;
`;

const MiddleFile = styled.div`
  font-size: 30px;
  z-index: ${ZIndex.Front};
`;

const RightFile = styled.div`
  transform: rotate(10deg);
  font-size: 25px;
  position: relative;
  left: -40px;
  top: 10px;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const HiddenFileContainer = styled(FileContainer)`
  display: none;
`;

const DropTitle = styled(Headline2)`
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
  display: none;
`;

export const getIconForFormat = (fileLayout: FileLayout): React.ReactNode => {
  switch (fileLayout) {
    case FileLayout.VERTICAL:
      return <StyledImg src={VerticalSample} alt="Vertical Format" />;
    case FileLayout.HORIZONTAL:
      return <StyledImg src={HorizontalSample} alt="Horizontal Format" />;
    case FileLayout.GRID:
      return <StyledImg src={GridSample} alt="Grid Format" />;
    case FileLayout.DATABASE:
      return <StyledImg src={DBSample} alt="Database Format" />;
    case FileLayout.MULTIDATE:
      return <StyledImg src={MultiDateSample} alt="Multiple Date Column Format" />;
  }
  return null;
};

export const IconClasses = {
  main: 'icon-main',
  hover: 'icon-hover',
  drag: 'icon-drag',
};

export const OnlyExcel = () => {
  const { Colors } = useContext(ThemeContext);
  return (
    <>
      <FileContainer className={IconClasses.main}>
        <MiddleFile>
          <FileIcon label=".xls" color={Colors.Green} />
        </MiddleFile>
      </FileContainer>
      <HiddenFileContainer className={IconClasses.drag}>
        <MiddleFile>
          <FileIcon label=".xls" color={Colors.Green} />
        </MiddleFile>
      </HiddenFileContainer>
      <HiddenFileContainer className={IconClasses.hover}>
        <MiddleFile>
          <FileIcon label=".xls" color={Colors.Green} />
        </MiddleFile>
      </HiddenFileContainer>
      <DropTitle className={IconClasses.drag}>Drop file here</DropTitle>
    </>
  );
};

export const FilesTrifecta = () => {
  const { Colors } = useContext(ThemeContext);
  return (
    <>
      <FileContainer className={IconClasses.main}>
        <LeftFile>
          <FileIcon label=".pdf" color={Colors.Error} />
        </LeftFile>
        <MiddleFile>
          <FileIcon label=".csv" color={Colors.HighlightDark} />
        </MiddleFile>
        <RightFile>
          <FileIcon label=".xls" color={Colors.Green} />
        </RightFile>
      </FileContainer>
      <HiddenFileContainer className={IconClasses.drag}>
        <LeftFile>
          <FileIcon color={Colors.HintGrey} solid />
        </LeftFile>
        <MiddleFile>
          <FileIcon icon="upload" color={Colors.HighlightDark} solid />
        </MiddleFile>
        <RightFile>
          <FileIcon color={Colors.HintGrey} solid />
        </RightFile>
      </HiddenFileContainer>
      <HiddenFileContainer className={IconClasses.hover}>
        <LeftFile>
          <FileIcon label=".pdf" color={Colors.Error} solid />
        </LeftFile>
        <MiddleFile>
          <FileIcon label=".csv" color={Colors.HighlightDark} solid />
        </MiddleFile>
        <RightFile>
          <FileIcon label=".xls" color={Colors.Green} solid />
        </RightFile>
      </HiddenFileContainer>
      <DropTitle className={IconClasses.drag}>Drop file here</DropTitle>
    </>
  );
};

const StyledImg = styled.img`
  width: 100%;
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0); /* IE 9 */
  -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
  transform: translateZ(0);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1));
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0.1)));
`;

const Columns = styled(Icon)`
  color: ${GetColor.Green};
  position: relative;
  left: 30px;
  top: -20px;
`;

const Rows = styled(Icon)`
  color: ${GetColor.HighlightDark};
  transform: rotate(90deg);
  position: relative;
  left: -30px;
  top: 20px;
`;

const GridContainer = styled.div`
  font-size: 170px;
  opacity: 0.1;
  position: absolute;
  z-index: ${ZIndex.Background};
  top: 10px;
`;

export const GridBackground = () => (
  <GridContainer>
    <Columns type="line-columns" prefix="fad" />
    <Rows type="line-columns" prefix="fad" />
  </GridContainer>
);
