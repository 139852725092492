import { useMemo } from 'react';
import type { AnalysisResponseParser, MetricRowData, ScatterSeries } from '../../types';
import { compact, isNil } from 'lodash';
import { formatSubjectWithOptionalFee } from '../../../legend';
import type { BlockAnalysisType, CustomizableMetric } from 'venn-utils';
import { dataValueToNumber } from '../../logic/typeUtils';
import type { SubjectPoint } from './chartsUtils';
import { convertScatterSeriesDataToExcel } from './chartsUtils';
import type { Analysis, CustomizedBlock } from 'venn-api';
import type { StudioAnalysisRequest } from 'venn-state';

export function getScatterMetrics(selectedMetrics: CustomizableMetric[]) {
  /**
   * Export metrics is just the selected metrics, capped to the maximum allowed.
   * Unlike the 2d scatter chart, we don't need to show a duplicate metric if only 1 metric is selected.
   */
  const exportMetrics = selectedMetrics.slice(0, 2);
  /** If only one metric selected, value will be used for both x & y. */
  const chartMetrics = exportMetrics.length === 1 ? [exportMetrics[0], exportMetrics[0]] : exportMetrics;
  return { chartMetrics, exportMetrics };
}

export function getScatterLabels(metrics: CustomizableMetric[] | undefined, isRelativeToBenchmark: boolean) {
  if (!metrics?.length) {
    return { xLabel: '', yLabel: '', xUnit: 'ratio' as const, yUnit: 'ratio' as const };
  }

  const { chartMetrics } = getScatterMetrics(metrics);

  const getLabel = (metricIndex: number) =>
    isRelativeToBenchmark ? chartMetrics[metricIndex].relativeLabel : chartMetrics[metricIndex].label ?? '';

  const getUnit = (metricIndex: number) =>
    chartMetrics[metricIndex]?.dataType === 'PERCENTAGE' ? ('percent' as const) : ('ratio' as const);

  const xLabel = getLabel(0);
  const yLabel = getLabel(1);

  const xUnit = getUnit(0);
  const yUnit = getUnit(1);

  return { xUnit, yUnit, xLabel, yLabel };
}

export function isExportable(
  analysis: (Analysis | undefined)[] | undefined,
  analysisType: BlockAnalysisType | undefined,
) {
  if (!analysisType) return false;
  return !!analysis?.find((a) => a?.analysisType === analysisType)?.exportable?.[0];
}

export function createPointCoordinates(
  requestIndex: number,
  parsedRowData: MetricRowData[],
  analysis: (Analysis | undefined)[] | undefined,
  chartMetrics: CustomizableMetric[],
) {
  return chartMetrics.map((metric: CustomizableMetric) => {
    const rawValue = dataValueToNumber(parsedRowData.find((p) => p.key === metric.key)?.value[requestIndex]);
    return {
      name: metric.label,
      chartValue: rawValue,
      excelValue: isExportable(analysis, metric.analysisType) ? rawValue : undefined,
    };
  });
}

export function convertSubjectPointsToChartPoints(subjectPoints: SubjectPoint[]): ScatterSeries {
  return compact(
    subjectPoints.map(({ subject, subjectColor, coordinates }) => {
      const dataPoints = coordinates.map((x) => x.chartValue);
      if (dataPoints.some(isNil) || dataPoints.some(Number.isNaN)) {
        return undefined;
      }

      const point = {
        type: 'scatter' as const,
        name: formatSubjectWithOptionalFee(subject),
        data: [dataPoints as [number, number]],
        color: subjectColor,
      };
      return point;
    }),
  );
}

export function useScatterChartData(
  selectedMetrics: CustomizableMetric[] | undefined,
  responseParser: AnalysisResponseParser,
  selectedBlock: CustomizedBlock,
  analyses: (Analysis | undefined)[][] | undefined,
  requests: Pick<StudioAnalysisRequest, 'subject'>[],
  subjectColors: string[],
) {
  return useMemo(() => {
    if (!selectedMetrics?.length) {
      return [];
    }

    const parsedRowData = responseParser.parser(selectedBlock, analyses) as MetricRowData[];
    if (!parsedRowData.length) {
      return [];
    }

    const { chartMetrics, exportMetrics } = getScatterMetrics(selectedMetrics);

    const subjectPoints = requests.map((request, requestIndex) => ({
      subject: request.subject,
      subjectColor: subjectColors[requestIndex],
      coordinates: createPointCoordinates(requestIndex, parsedRowData, analyses?.[requestIndex], chartMetrics),
    }));

    const chartSeriesPoints = convertSubjectPointsToChartPoints(subjectPoints);
    const excelDataFn = () => convertScatterSeriesDataToExcel(subjectPoints, exportMetrics);
    return [chartSeriesPoints, excelDataFn] as const;
  }, [selectedMetrics, analyses, requests, responseParser, selectedBlock, subjectColors]);
}
