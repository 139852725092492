import React, { useContext } from 'react';
import styled from 'styled-components';
import { Headline2, GetColor } from 'venn-ui-kit';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { Routes } from 'venn-utils';
import { UserContext, getMenuItemById, TABS } from 'venn-components';
import User from '../User';
import Company from '../Company';
import WorkspaceConfiguration from '../WorkspaceConfiguration';
import Plan from '../Plan';
import Security from '../Security';
import Notifications from '../Notifications';
import Sponsorships from '../Sponsorships';

const Section = () => {
  const { profileSettings, settings, updateSettings, refresh, hasPermission } = useContext(UserContext);
  const match = useRouteMatch<{ section: TABS | undefined }>();
  const section = getMenuItemById(match.params.section);
  return (
    <Container className="sticky-container">
      <StyledHeadline2>{section.title}</StyledHeadline2>
      {profileSettings && (
        <div>
          <SectionOutlet id={section.id}>
            <Switch>
              <Route path={`${Routes.ACCOUNT_PATH}`} exact>
                <Redirect to={`${Routes.ACCOUNT_PATH}/${TABS.COMPANY}`} />
              </Route>
              <Route path={`${Routes.ACCOUNT_PATH}/${TABS.COMPANY}`}>
                <Company profileSettings={profileSettings} hasPermission={hasPermission} />
              </Route>
              <Route path={`${Routes.ACCOUNT_PATH}/${TABS.USER}`}>
                <User
                  profileSettings={profileSettings}
                  userSettings={settings}
                  updateUserSettings={updateSettings}
                  refresh={refresh}
                />
              </Route>
              <Route path={`${Routes.ACCOUNT_PATH}/${TABS.WORKSPACE_CONFIGURATION}`}>
                <WorkspaceConfiguration profileSettings={profileSettings} />
              </Route>
              <Route path={`${Routes.ACCOUNT_PATH}/${TABS.PLAN}`}>
                <Plan />
              </Route>
              <Route path={`${Routes.ACCOUNT_PATH}/${TABS.SECURITY}`}>
                <Security profileSettings={profileSettings} refresh={refresh} />
              </Route>
              <Route path={`${Routes.ACCOUNT_PATH}/${TABS.NOTIFICATIONS}`}>
                <Notifications />
              </Route>
              <Route path={`${Routes.ACCOUNT_PATH}/${TABS.SPONSORSHIPS}`}>
                <Sponsorships profileSettings={profileSettings} />
              </Route>
            </Switch>
          </SectionOutlet>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  b,
  strong {
    color: ${GetColor.HighlightDark};
  }
  min-height: 500px;
`;

const SectionOutlet = styled.section`
  padding: 0;
`;

const StyledHeadline2 = styled(Headline2)`
  padding-bottom: 20px;
`;
export default Section;
