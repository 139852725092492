import { atom, selector } from 'recoil';
import type { AnalysisViewTypeEnum } from 'venn-api';
import { resetOnStudioReset } from '../../effects';
import { isReport } from 'venn-utils';

/** The type of view currently loaded in studio or report lab */
export const analysisViewTypeState = atom<AnalysisViewTypeEnum>({
  key: 'analysisViewTypeState',
  default: 'ANALYSIS',
  effects: [resetOnStudioReset],
});

/** Whether the current view is a report lab view. */
export const isReportState = selector<boolean>({
  key: 'isReportState',
  get: ({ get }) => {
    const viewType = get(analysisViewTypeState);
    return isReport(viewType);
  },
});

/** The id of the currently loaded analysis view */
export const analysisViewIdState = atom<string | undefined>({
  key: 'analysisViewIdState',
  default: undefined,
  effects: [resetOnStudioReset],
});

/**
 * The ref id of the currently loaded analysis view.
 * Used as a stable id for the view before the view is saved.
 */
export const analysisViewRefIdState = atom<string | undefined>({
  key: 'analysisViewRefIdState',
  default: undefined,
  effects: [resetOnStudioReset],
});
