import type { AnalysisView, Scenario } from 'venn-api';
import { cloneDeep, cloneDeepWith, compact, isEmpty, isEqual, isNil, isObjectLike, isPlainObject } from 'lodash';
import {
  analyticsService,
  compareSelectedBlock,
  getBlockSettingsMapForUser,
  isReport,
  isStudioTemplate,
  PageType,
  PORTFOLIO_COMPARISON_BLOCK,
} from 'venn-utils';
import type { Page } from 'venn-components';
import type { CustomizableBlockSetting } from 'venn-utils';

export const DEFAULT_YEARS = 10;
export const DEFAULT_SCALE = true;

export const compareViewsHasDiff = (updatedView?: AnalysisView, baselineView?: AnalysisView): boolean => {
  if (!updatedView) {
    return false;
  }

  // Draft view
  if (!baselineView) {
    return true;
  }

  // If children's name are different, we can ignore
  if (updatedView.analysisViewType !== 'ASSEMBLY_CHILD' && !isEqual(updatedView.name, baselineView.name)) {
    return true;
  }

  // Only compare what we care
  if (
    !isEqual(updatedView.printOrientationType, baselineView.printOrientationType) ||
    !isEqual(updatedView.analysisViewType, baselineView.analysisViewType) ||
    !isEqual(updatedView.published, baselineView.published) ||
    !isEqual(updatedView.ownerContextId, baselineView.ownerContextId) ||
    !compareSelectedBlock(updatedView?.customizedBlock, baselineView.customizedBlock) ||
    !isEqual(updatedView.subjects, baselineView.subjects)
  ) {
    return true;
  }

  if (updatedView.customizedViews?.length !== baselineView.customizedViews?.length) {
    return true;
  }

  if (updatedView.customViewOptions?.length !== baselineView.customViewOptions?.length) {
    return true;
  }

  if (
    !isEmpty(updatedView.customViewOptions) &&
    checkCustomViewOptionsEquality(
      { customViewOptions: updatedView.customViewOptions },
      { customViewOptions: baselineView.customViewOptions },
    )
  ) {
    return true;
  }

  if (updatedView.customizedViews && updatedView.customizedViews.length > 0) {
    return !!updatedView.customizedViews.find((view, index) =>
      compareViewsHasDiff(view, baselineView.customizedViews?.[index]),
    );
  }
  return false;
};

export const ACTIVE_BLOCK_CLASS = 'active-block';

const cloneIgnoreUndefinedAndEmptyFields = (value: unknown) => {
  return cloneDeepWith(cloneDeep(value), (deepValue: unknown) => {
    // Why do we use isPlainObject and not just typeof deepValue === 'object'?
    if (isPlainObject(deepValue)) {
      const deepValueObject = deepValue as Record<string, unknown>;
      Object.keys(deepValueObject).forEach((key) => {
        const v = deepValueObject[key];
        if (isNil(v) || (isObjectLike(v) && isEmpty(v))) {
          delete deepValueObject[key];
        }
      });
    }
    return undefined;
  });
};

const checkCustomViewOptionsEquality = (
  updatedCustomViewOptions: Pick<AnalysisView, 'customViewOptions'>,
  baseCustomViewOptions: Pick<AnalysisView, 'customViewOptions'>,
) => {
  const updated = cloneIgnoreUndefinedAndEmptyFields(updatedCustomViewOptions.customViewOptions);
  const base = cloneIgnoreUndefinedAndEmptyFields(baseCustomViewOptions.customViewOptions);
  if ((isEmpty(updated) && base === undefined) || (updated === undefined && isEmpty(base))) {
    return false;
  }

  return !isEqual(updated, base);
};

export const getDynamicIcon = (blockSetting: CustomizableBlockSetting) => {
  if (blockSetting.customBlockType === 'MARKDOWN') {
    return 'text';
  }

  if (blockSetting.customBlockType === 'PAGE_BREAK') {
    return 'file-dashed-line';
  }

  if (blockSetting.customBlockType === 'IMAGE') {
    return 'image-landscape';
  }

  if (blockSetting.hasFactors) {
    return 'function';
  }

  return 'chart-line';
};

/** Set row value according to current index of list */
export const updateRowBasedOnIndex = (analysisViews: AnalysisView[]): AnalysisView[] =>
  analysisViews.map((item, index) => ({
    ...item,
    row: index,
  }));

export const getPageTitle = (page: Page, index: number): string => {
  let pageTitles: (string | undefined)[] = [];
  switch (page.type) {
    case PageType.TITLE:
      pageTitles = [page.titleSettings?.title, page.titleSettings?.subTitle, 'Title Page'];
      break;
    case PageType.SECTION:
      pageTitles = [
        page.sectionSettings?.title,
        page.sectionSettings?.topText,
        page.sectionSettings?.subTitle,
        'Section Page',
      ];
      break;
    case PageType.DISCLOSURE:
      pageTitles = ['Disclosures'];
      break;
  }
  pageTitles = pageTitles.filter((x) => x);
  return pageTitles.length > 0 ? pageTitles[0]! : `Page ${index + 1}`;
};

export const trackViewOpened = (analysisView: AnalysisView) => {
  const blockSettingsMap = getBlockSettingsMapForUser();
  const blocks = analysisView.customizedViews?.map(
    (view) => view.customizedBlock?.settingId && blockSettingsMap[view.customizedBlock.settingId].customBlockType,
  );
  const allScenarios: Scenario[] = compact(
    analysisView.customizedViews?.map((view) => view.customViewOptions.scenarios),
  ).flat();
  const numPortfolioComparisonBlocks = analysisView.customizedViews?.filter(
    (block) => block.customizedBlock?.settingId === PORTFOLIO_COMPARISON_BLOCK.id,
  ).length;
  const numPortfolioComparisonBlocksWithCustomMetrics = analysisView.customizedViews?.filter(
    (block) =>
      block.customViewOptions.customMetricsSettings && block.customViewOptions.customMetricsSettings.length > 0,
  ).length;
  const isReportView = analysisView && isReport(analysisView);
  const isTemplate = isStudioTemplate(analysisView.analysisViewType);
  const trackingFunction = isReportView ? analyticsService.reportLabOpened : analyticsService.studioOpened;
  trackingFunction({
    viewId: analysisView.id,
    isTemplate,
    blocks,
    blockCount: blocks?.length,
    scenarioFundNames: allScenarios.map((s) => s.fundName),
    containsMacroScenarios: allScenarios.some((s) => s.type === 'MACRO'),
    containsMarketScenarios: allScenarios.some((s) => s.type === 'MARKET'),
    numPortfolioComparisonBlocks,
    numPortfolioComparisonBlocksWithCustomMetrics,
  });
};
