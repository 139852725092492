/*
 * Venn Platform API 1.0.0 Operations for "peer"
 * Automatically generated file - do not modify!
 */

import type { PeerGroup, PeerGroupQuery } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Search available peer groups */
export async function getPeerGroups(
  body?: Partial<PeerGroupQuery>,
  signal?: AbortSignal,
): Promise<OperationResult<PeerGroup[]>> {
  const endpoint = '/api/peer-groups/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PeerGroup[] = (await response.json()) as PeerGroup[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
