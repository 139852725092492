import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { AnalysisView, AnalysisViewSubject, ImageDetails } from 'venn-api';
import {
  checkViewName,
  saveAnalysisView,
  savePrivatePortfolio,
  storeImageWithBasePath,
  updatePortfolioV3,
} from 'venn-api';
import {
  AnalysisSubject,
  analyticsService,
  assertExhaustive,
  assertNotNil,
  getDefaultStudioViewName,
  getRandomId,
  insert,
  isReport,
  isRequestSuccessful,
  logExceptionIntoSentry,
  updateUrlParam,
  useHasFF,
  useModal,
} from 'venn-utils';
import type {
  AfterUnsavedChangeAction,
  Page,
  PageInsertOptions,
  SaveArgs,
  StudioSidePanelContextProps,
} from 'venn-components';
import { DataGridSizeType, SavedViewMessage, useDebounceToGlobal, UserContext } from 'venn-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Notifications, NotificationType } from 'venn-ui-kit';
import { cloneDeep, omit, sortBy } from 'lodash';
import moment from 'moment';
import { updateRowBasedOnIndex } from './studioUtils';
import type { Layout } from 'react-grid-layout';
import type { UnwrapRecoilValue } from 'recoil';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import {
  allocatorAnalysisSubject,
  availableFactorMetrics,
  benchmarkInputs,
  blockAllFactorsSelected,
  blockBenchmarkInput,
  blockDateRangeInputState,
  blockSettingsMap,
  blockSubjectInputGroups,
  combineAnalysisViewWithSnapshot,
  currentAnalysisView,
  customizedBlock,
  customViewOptions,
  dateRangeInputsState,
  hasUnsavedChangesInPrivatesAllocator,
  hasUnsavedPortfolioChangesInAllocator,
  openAllocatorSubject,
  openPrivateAllocatorConfig,
  openPrivateAllocatorPortfolio,
  originalAnalysisSubjectQuery,
  predefinedNotablePeriods,
  selectedBlockIdState,
  studioLeftPanelOpen,
  subjectInputGroups,
  updateInputIds,
  useRecoilValueWithDefault,
  useUnsavedChanges,
  viewPages,
  type CustomViewOptions,
  analysisViewIdState,
} from 'venn-state';
import type { CustomBlockTypeEnum, CustomizableBlockSetting } from 'venn-utils';

interface InsertBlockOptions {
  insertIndex?: number;
  customBlockType?: CustomBlockTypeEnum;
  globalId?: string;
}

export type StudioToolbarInput = {
  analysisViewRef: React.RefObject<AnalysisView | undefined>;
  analysisView: AnalysisView | undefined;
  baselineView: AnalysisView | undefined;
  firstOpeningOfTheView: boolean;
  hasUnsavedChange: boolean;
  afterUnsavedChangesAction?: AfterUnsavedChangeAction;
  setAfterUnsavedChangesAction: (action?: AfterUnsavedChangeAction) => void;
  setFirstOpeningOfTheView: (firstView: boolean) => void;
  setAnalysisView: React.Dispatch<React.SetStateAction<AnalysisView | undefined>>;
  setBaselineView: (studio?: AnalysisView) => void;
  onExport: (
    isInternal: boolean,
    analysisViewName?: string,
    analysisViewId?: string,
    onComplete?: () => void,
  ) => Promise<void>;
  setIsDuplicateReportName: (isDuplicateReportName: boolean) => void;
  setIsCheckingDuplicateReportName: (checking: boolean) => void;
} & Pick<StudioSidePanelContextProps, 'onSelectBlock' | 'onSelectGlobal' | 'onSelectPage'>;

const prepareNewBlockFromBlockSource = (blockSource: AnalysisView): Partial<AnalysisView> => {
  const propsToOmit: (keyof AnalysisView)[] = [
    'created',
    'customTemplateId',
    'customizedViews',
    'id',
    'refId',
    'row',
    'updated',
  ];
  return {
    ...omit(blockSource, propsToOmit),
    refId: getRandomId(),
  };
};

/** Storage as const prevents repeated rerenders. */
const defaultEmptyBlockSettings: UnwrapRecoilValue<typeof blockSettingsMap> = {};
/** Storage as const prevents repeated rerenders. */
const defaultEmptyFactorMetrics: UnwrapRecoilValue<typeof availableFactorMetrics> = [];

/** Handle studio's interactions in block/global toolbars, such as save/save as/insert in top bar, and interactions in block's toolbar */
const useStudioToolbar = ({
  analysisView,
  analysisViewRef,
  baselineView,
  firstOpeningOfTheView,
  setFirstOpeningOfTheView,
  hasUnsavedChange,
  afterUnsavedChangesAction,
  setAfterUnsavedChangesAction,
  onSelectBlock,
  onSelectGlobal,
  setAnalysisView,
  setBaselineView,
  onExport,
  setIsDuplicateReportName,
  setIsCheckingDuplicateReportName,
  onSelectPage,
}: StudioToolbarInput) => {
  const hasStudioReportEditor = useHasFF('studio_report_editor');

  const allNotablePeriods = useRecoilValueWithDefault(predefinedNotablePeriods, undefined);
  const setLeftPanelOpen = useSetRecoilState(studioLeftPanelOpen);
  const setCurrentAnalysisView = useSetRecoilState(currentAnalysisView);
  const preventRedirectOnSave = useRef(false);
  const [imageUploading, setImageUploading] = useState(false);
  const hasUnsavedReturnsAllocatorChanges = useRecoilValueWithDefault(hasUnsavedPortfolioChangesInAllocator, false);
  const hasUnsavedPrivatesAllocatorChanges = useRecoilValueWithDefault(hasUnsavedChangesInPrivatesAllocator, false);

  const history = useHistory();
  const { profileSettings, currentContext } = useContext(UserContext);
  const blockSettingMapper = useRecoilValueWithDefault(blockSettingsMap, defaultEmptyBlockSettings);

  const factorMetrics = useRecoilValueWithDefault(availableFactorMetrics, defaultEmptyFactorMetrics);

  const [isSaving, setIsSaving] = useState(false);
  const [isOpenReportConfigModal, openReportConfigModal, closeReportConfigModal] = useModal();
  const [, setHasUnsavedRecoilChanges] = useUnsavedChanges();

  const isReportView = analysisView && isReport(analysisView) && hasStudioReportEditor;

  const save = useRecoilCallback(
    ({ snapshot }) =>
      async ({
        name,
        id,
        currentAnalysisView,
        ownerContextId,
      }: {
        name: string;
        id?: string;
        currentAnalysisView?: AnalysisView;
        ownerContextId?: string;
      }): Promise<string | undefined> => {
        const view = currentAnalysisView ?? analysisViewRef.current!;
        if (!view) {
          return undefined;
        }
        const savingView = Notifications.notify('Saving view...', NotificationType.LOADING);
        try {
          const toSaveView = {
            ...view,
          };

          if (toSaveView.customizedViews) {
            toSaveView.customizedViews = toSaveView.customizedViews.map((view) => {
              if (!id) {
                // Make sure make a copy for save as item
                return {
                  ...view,
                  id: undefined,
                };
              }
              return view;
            });
          }
          setIsSaving(true);
          const combinedView = await combineAnalysisViewWithSnapshot(toSaveView, snapshot);
          const viewToSave = updateInputIds(combinedView);
          const { content: updatedSavedView } = await saveAnalysisView({
            ...viewToSave,
            id,
            name,
            ownerContextId,
          });
          setCurrentAnalysisView(updatedSavedView);
          setBaselineView(updatedSavedView);
          setAnalysisView(updatedSavedView);
          setHasUnsavedRecoilChanges(false);
          setIsSaving(false);
          Notifications.notifyUpdate(
            savingView,
            <SavedViewMessage type={updatedSavedView.analysisViewType} />,
            NotificationType.INFO,
          );
          if (preventRedirectOnSave.current) {
            preventRedirectOnSave.current = false;

            return undefined;
          }

          if (id !== updatedSavedView.id) {
            updateUrlParam(history, 'PUSH', 'savedId', updatedSavedView.id);
          }
          return updatedSavedView.id;
        } catch (e) {
          Notifications.notifyUpdate(savingView, 'Failed to save the view', NotificationType.ERROR);
          logExceptionIntoSentry(e);
          setIsSaving(false);
          return undefined;
        }
      },
    [analysisViewRef, setCurrentAnalysisView, setBaselineView, setAnalysisView, setHasUnsavedRecoilChanges, history],
  );

  const onSave = useRecoilCallback(
    ({ snapshot }) =>
      async (saveArgs?: SaveArgs): Promise<{ savedName?: string; savedId?: string }> => {
        const { name, currentAnalysisState } = saveArgs || {};

        const view = currentAnalysisState ?? analysisViewRef.current!;
        const id = await snapshot.getPromise(analysisViewIdState);

        const ownerContextId = view.ownerContextId ?? currentContext;
        const timestamp = moment().format('YYYY-MM-DD hh:mm A');
        // If the name field is empty, fallback to save with original name
        const savedName = name ?? view?.name ?? getDefaultStudioViewName(timestamp, view?.analysisViewType);
        const savedId = await save({
          name: savedName,
          id,
          currentAnalysisView: view,
          ownerContextId,
        });
        analyticsService.ctaClicked({
          destination: undefined,
          text: 'Save',
          purpose: isReportView ? 'Save report' : 'Save studio',
          type: 'button',
          filled: false,
        });
        return { savedName, savedId };
      },
    [analysisViewRef, currentContext, save, isReportView],
  );

  const onSaveAs = useCallback(
    (name: string, ownerContextId?: string) => {
      save({ name, ownerContextId, id: undefined });
      analyticsService.ctaClicked({
        destination: undefined,
        text: 'Save As...',
        purpose: isReportView ? 'Save report' : 'Save studio',
        type: 'button',
        filled: false,
      });
      analyticsService.creatingNewStudios({
        source: 'studio toolbar - save as',
        type: 'template',
        name: analysisView?.systemTemplate,
      });
    },
    [save, analysisView, isReportView],
  );

  const onRename = useRecoilCallback(
    ({ snapshot }) =>
      async (name: string) => {
        const analysisView = analysisViewRef.current;
        if (!analysisView || name === analysisView.name) {
          return;
        }

        // if document hasn't been saved before save it before renaming
        if (!analysisView.id) {
          await onSave({ name });
          return;
        }

        try {
          const toSaveBaselineView = {
            ...baselineView,
            name,
          };
          const combinedView = await combineAnalysisViewWithSnapshot(toSaveBaselineView, snapshot);
          const { content: updatedSavedView } = await saveAnalysisView(combinedView);
          const updatedAnalysisView = {
            ...analysisView,
            name: updatedSavedView.name,
          };
          setAnalysisView(updatedAnalysisView);
          setBaselineView(updatedSavedView);
        } catch (e) {
          logExceptionIntoSentry(e);
        }
      },
    [analysisViewRef, onSave, baselineView, setAnalysisView, setBaselineView],
  );

  const onBlockReorderUp = useCallback(
    (refId: string) => {
      setAnalysisView((current) => {
        let studio = current;

        if (studio?.customizedViews) {
          // Use refId that is generated in frontend which won't be empty
          const index = studio.customizedViews.findIndex((view) => refId === view.refId);
          const reordered = [...studio.customizedViews];

          reordered.splice(index - 1, 0, reordered.splice(index, 1)[0]);

          analyticsService.ctaClicked({
            purpose: 'move block up',
            locationOnPage: `'${
              current?.customizedBlock?.settingId
                ? blockSettingMapper[current.customizedBlock.settingId]?.customBlockType
                : 'undefined'
            } block toolbar'`,
          });

          studio = {
            ...studio,
            customizedViews: updateRowBasedOnIndex(reordered),
          };
        }

        return studio;
      });
    },
    [blockSettingMapper, setAnalysisView],
  );

  const onBlockReorderDown = useCallback(
    (refId: string) => {
      setAnalysisView((analysisView) => {
        let newAnalysisView = analysisView;

        if (newAnalysisView?.customizedViews) {
          // Use refId that is generated in frontend which won't be empty
          const index = newAnalysisView.customizedViews.findIndex((view) => refId === view.refId);
          const reordered = [...newAnalysisView.customizedViews];

          reordered.splice(index + 1, 0, reordered.splice(index, 1)[0]);

          analyticsService.ctaClicked({
            purpose: 'move block down',
            locationOnPage: `'${
              analysisView?.customizedBlock?.settingId
                ? blockSettingMapper[analysisView.customizedBlock.settingId]?.customBlockType
                : 'undefined'
            } block toolbar'`,
          });

          newAnalysisView = {
            ...newAnalysisView,
            customizedViews: updateRowBasedOnIndex(reordered),
          };
        }

        return newAnalysisView;
      });
    },
    [blockSettingMapper, setAnalysisView],
  );

  const prepareNewBlockObject = useCallback(
    (blockSetting: CustomizableBlockSetting, rowIndex: number): AnalysisView => {
      return {
        refId: getRandomId(),
        analysisViewType: 'ASSEMBLY_CHILD',
        systemTemplate: 'custom',
        subjects: [] as AnalysisViewSubject[],
        customViewOptions: {
          ...(blockSetting.customBlockType === 'NOTABLE_PERIODS'
            ? { selectedNotablePeriods: allNotablePeriods?.map(({ id }) => id) }
            : {}),
          ...(blockSetting.customBlockType === 'RETURNS_GRID' ? { dataGridSizeType: DataGridSizeType.COMPACT } : {}),
          ...(blockSetting.hasFactors ? { allFactorsSelected: true } : {}),
        } satisfies Partial<CustomViewOptions>,
        customizedBlock: {
          settingId: blockSetting.id,
          contributionToPercentage: false,
          // No default metrics for timeseries
          selectedMetrics:
            blockSetting.customBlockType === 'TIMESERIES' || blockSetting.customBlockType === 'PEER_GROUPS'
              ? []
              : blockSetting.defaultMetrics.length
                ? blockSetting.defaultMetrics
                : blockSetting.metrics.map((m) => m.key),
          selectedFactors: blockSetting.hasFactors ? factorMetrics.map((f) => f.id) : [],
          // Use the first item as default
          infoGraphicType: blockSetting.supportedGraphicTypes[0] ?? 'GRID',
        },
        row: rowIndex,
      } as unknown as AnalysisView;
    },
    [allNotablePeriods, factorMetrics],
  );

  const insertBlock = useRecoilCallback(
    ({ set }) =>
      (
        newBlock: AnalysisView,
        { insertIndex, globalId }: InsertBlockOptions = {},
        pageInsertOptions?: PageInsertOptions,
      ) => {
        const analysisView = analysisViewRef.current;
        const newBlockInsertIndex = insertIndex ?? analysisView?.customizedViews?.length ?? 0;
        const customizedViews = !analysisView?.customizedViews
          ? [newBlock]
          : updateRowBasedOnIndex(insert(analysisView.customizedViews, newBlockInsertIndex, newBlock));

        const updatedGlobalView = !analysisView?.customizedViews
          ? ({
              analysisViewType: analysisView?.analysisViewType ?? 'TEARSHEET',
              name: undefined,
              systemTemplate: 'custom',
              subjects: [] as AnalysisViewSubject[],
              customizedViews,
              refId: globalId,
            } as AnalysisView)
          : {
              ...analysisView,
              customizedViews,
              systemTemplate: 'custom',
            };

        setAnalysisView(updatedGlobalView);

        onSelectBlock(newBlock.refId, {
          scrollIntoView: !isReportView,
          pageIndex: pageInsertOptions?.pageNumber,
        });

        // Ensure we do not insert the block into the grid until the analysis view has been set,
        // Otherwise it's size will be reset as it will not be able to render until this is set
        if (pageInsertOptions !== undefined) {
          const { pageNumber, layout } = pageInsertOptions;
          set(viewPages, (current) => [
            ...current.slice(0, pageNumber),
            {
              ...current[pageNumber],
              layout: [
                ...layout.map((l) =>
                  l.i === 'dropping_item'
                    ? {
                        ...l,
                        i: assertNotNil(newBlock.refId),
                      }
                    : l,
                ),
              ],
            },
            ...current.slice(pageNumber + 1),
          ]);
        }
      },
    [analysisViewRef, onSelectBlock, setAnalysisView, isReportView],
  );

  const linkBlockToDefaults = useRecoilCallback(
    ({ set, snapshot }) =>
      async (blockId?: string) => {
        if (!blockId) {
          return;
        }

        const subjectGroups = await snapshot.getPromise(subjectInputGroups);
        subjectGroups.length && set(blockSubjectInputGroups(blockId), [subjectGroups[0]]);

        const dateRangeGroups = await snapshot.getPromise(dateRangeInputsState);
        set(blockDateRangeInputState(blockId), dateRangeGroups[0]);

        const benchmarkSettings = await snapshot.getPromise(benchmarkInputs);
        set(blockBenchmarkInput(blockId), benchmarkSettings[0]);
      },
    [],
  );

  const onInsertBlock = useRecoilCallback(
    ({ set }) =>
      async (
        { value: blockSetting }: DropMenuItem<CustomizableBlockSetting>,
        insertIndex?: number,
        pageInsertOptions?: PageInsertOptions,
      ) => {
        const newIndex = insertIndex ?? 0;
        const newBlock = prepareNewBlockObject(blockSetting, newIndex);

        newBlock.customizedBlock && newBlock.refId && set(customizedBlock(newBlock.refId), newBlock.customizedBlock);
        newBlock.refId && set(blockAllFactorsSelected(newBlock.refId), true);
        await linkBlockToDefaults(newBlock.refId);
        insertBlock(
          newBlock,
          {
            insertIndex,
            customBlockType: blockSetting.customBlockType,
          },
          pageInsertOptions,
        );

        return newBlock.refId;
      },
    [insertBlock, linkBlockToDefaults, prepareNewBlockObject],
  );

  const cloneBlockState = useRecoilCallback(
    ({ set, snapshot }) =>
      async (fromId?: string, toId?: string) => {
        if (!fromId || !toId) {
          return;
        }

        set(blockSubjectInputGroups(toId), await snapshot.getPromise(blockSubjectInputGroups(fromId)));
        set(blockDateRangeInputState(toId), await snapshot.getPromise(blockDateRangeInputState(fromId)));
        set(blockBenchmarkInput(toId), await snapshot.getPromise(blockBenchmarkInput(fromId)));

        set(customizedBlock(toId), await snapshot.getPromise(customizedBlock(fromId)));
        set(customViewOptions(toId), await snapshot.getPromise(customViewOptions(fromId)));
      },
    [],
  );

  const onDuplicateBlock = useRecoilCallback(
    ({ snapshot }) =>
      async (blockSource: AnalysisView, insertIndex: number, customBlockType?: CustomBlockTypeEnum) => {
        const newBlock = prepareNewBlockFromBlockSource(blockSource) as AnalysisView;
        let pageInsertOptions: PageInsertOptions | undefined;

        const pages = await snapshot.getPromise(viewPages);
        if (isReportView) {
          const pageNumber = pages.findIndex((p: Page) => p.layout.some((l) => l.i === blockSource.refId));
          const page: Page = pages[pageNumber];
          const sourceLayout = page.layout.find((l) => l.i === blockSource.refId);
          const layout = [
            ...page.layout,
            {
              ...sourceLayout!,
              y: sourceLayout!.y + sourceLayout!.h,
              i: newBlock.refId!,
            },
          ];
          pageInsertOptions = {
            pageNumber,
            layout,
          };
        }

        await cloneBlockState(blockSource.refId, newBlock.refId);
        insertBlock(
          newBlock,
          {
            insertIndex,
            customBlockType,
          },
          pageInsertOptions,
        );
      },
    [cloneBlockState, insertBlock, isReportView],
  );

  const onDeleteBlock = useRecoilCallback(
    ({ set, snapshot }) =>
      async (blockId: string) => {
        const selectedBlockId = await snapshot.getPromise(selectedBlockIdState);
        if (blockId === selectedBlockId) {
          onSelectGlobal();
        }

        if (isReportView) {
          set(viewPages, (current) =>
            current.map((page: Page) => ({
              ...page,
              layout: page.layout.filter((layout) => blockId !== layout.i),
            })),
          );
        }

        setAnalysisView((analysisView) => {
          return analysisView
            ? analysisView.customizedViews
              ? {
                  ...analysisView,
                  customizedViews: updateRowBasedOnIndex(
                    analysisView.customizedViews.filter((view) => view.refId !== blockId),
                  ),
                }
              : analysisView
            : undefined;
        });
      },
    [isReportView, setAnalysisView, onSelectGlobal],
  );

  const onUpdateCustomViewOptions = useCallback(
    (updates: Pick<AnalysisView, 'customViewOptions'>, view: AnalysisView) => {
      const analysisView = analysisViewRef.current;
      if (!analysisView) {
        return;
      }

      const newView = {
        ...view,
        customViewOptions: { ...view.customViewOptions, ...updates.customViewOptions },
      };

      const buildersViews = (analysisView?.customizedViews ?? []).map((block) => {
        if (block.id === view.id && block.refId === view.refId) {
          return newView;
        }
        return block;
      });

      if ((view.id && view.id === analysisView?.id) || (view.refId && view.refId === analysisView?.refId)) {
        setAnalysisView(newView);
      } else {
        setAnalysisView({
          ...analysisView,
          customizedViews: buildersViews,
        });
      }
    },
    [analysisViewRef, setAnalysisView],
  );

  const onUpdateCustomViewOptionsChildViewAndGlobal = useCallback(
    (
      updatesChild: Pick<AnalysisView, 'customViewOptions'>,
      view: AnalysisView,
      updatesGlobal: Pick<AnalysisView, 'customViewOptions'>,
    ) => {
      const analysisView = analysisViewRef.current;
      if (!analysisView || view.refId === analysisView.refId) {
        return;
      }

      const newView = {
        ...view,
        customViewOptions: { ...view.customViewOptions, ...updatesChild.customViewOptions },
      };

      const newGlobal = {
        ...analysisView,
        customViewOptions: { ...analysisView.customViewOptions, ...updatesGlobal.customViewOptions },
      };

      const buildersViews = (analysisView?.customizedViews ?? []).map((block) => {
        if (block.id === view.id && block.refId === view.refId) {
          return newView;
        }
        return block;
      });

      setAnalysisView({
        ...newGlobal,
        customizedViews: buildersViews,
      });
    },
    [analysisViewRef, setAnalysisView],
  );

  const onDeletePage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (pageIndex: number) => {
        const analysisView = analysisViewRef.current;
        if (!analysisView) return;

        const pages = await snapshot.getPromise(viewPages);

        const newPages = [...pages.slice(0, pageIndex), ...pages.slice(pageIndex + 1)];
        const idsToDelete = pages[pageIndex].layout.map((l: Layout) => l.i);
        const newAnalysisView = analysisView.customizedViews
          ? {
              ...analysisView,
              customizedViews: analysisView.customizedViews.filter(
                (view) => !idsToDelete.includes(assertNotNil(view.refId)),
              ),
            }
          : analysisView;

        set(viewPages, newPages);
        setAnalysisView(newAnalysisView);
      },
    [analysisViewRef, setAnalysisView],
  );

  const onDuplicatePage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (pageIndex: number) => {
        const analysisView = analysisViewRef.current;
        if (!analysisView) return;
        const pages = await snapshot.getPromise(viewPages);
        const newPage = cloneDeep(pages[pageIndex]);
        const newViews: AnalysisView[] = [];

        await Promise.all(
          pages[pageIndex].layout.map(async (item: Layout, index: number) => {
            const newRefId = getRandomId();
            newPage.layout[index] = {
              ...item,
              i: newRefId,
            };
            const toDuplicateView = analysisView.customizedViews?.find((view) => view.refId === item.i);
            await cloneBlockState(toDuplicateView?.refId, newRefId);
            if (toDuplicateView) {
              newViews.push({
                ...toDuplicateView,
                id: undefined,
                refId: newRefId,
              });
            }
          }),
        );

        setAnalysisView({
          ...analysisView,
          customizedViews: (analysisView.customizedViews ?? []).concat(newViews),
        });

        const newPages = [...pages.slice(0, pageIndex + 1), newPage, ...pages.slice(pageIndex + 1)];
        set(viewPages, newPages);
        onSelectPage(pageIndex + 1);
      },
    [analysisViewRef, cloneBlockState, onSelectPage, setAnalysisView],
  );

  const onAddNewPage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (page: Page) => {
        const pages = await snapshot.getPromise(viewPages);
        const newPages = [...pages, page];
        set(viewPages, newPages);
        onSelectPage(pages.length);
      },
    [onSelectPage],
  );

  const blockOptions = useMemo(
    () =>
      sortBy(
        Object.values(blockSettingMapper).map((item) => ({
          label: item.title,
          value: item,
        })),
        'label',
      ),
    [blockSettingMapper],
  );

  const saveAllocatedPortfolio = useRecoilCallback(
    ({ snapshot, refresh, set }) =>
      async () => {
        const notificationId = Notifications.notify('Saving portfolio...', NotificationType.LOADING);
        const openSubject = await snapshot.getPromise(openAllocatorSubject);
        const subject = await snapshot.getPromise(allocatorAnalysisSubject(openSubject));
        if (!subject?.portfolio) {
          return;
        }
        try {
          const updatedPortfolio = await updatePortfolioV3(subject.portfolio.id, subject.portfolio);
          refresh(originalAnalysisSubjectQuery(openSubject));
          set(
            allocatorAnalysisSubject(openSubject),
            new AnalysisSubject({ ...updatedPortfolio.content }, 'portfolio', {
              ...subject.getOptionsCopy(),
              strategyId: subject.strategyId,
            }),
          );
          Notifications.notifyUpdate(notificationId, 'Successfully updated portfolio', NotificationType.SUCCESS);
        } catch (e) {
          logExceptionIntoSentry(e);
          Notifications.notifyUpdate(
            notificationId,
            'An error occurred updating the portfolio',
            NotificationType.ERROR,
          );
        }
      },
    [],
  );

  const savePrivateAllocatorPortfolio = useRecoilCallback(
    ({ snapshot, refresh, set }) =>
      async () => {
        const savingPortfolioNotification = Notifications.notify('Saving portfolio...', NotificationType.LOADING);
        try {
          const privateAllocatorConfig = await snapshot.getPromise(openPrivateAllocatorConfig);
          const subject = await snapshot.getPromise(allocatorAnalysisSubject(privateAllocatorConfig));

          const response = await savePrivatePortfolio(subject?.privatePortfolio);
          if (isRequestSuccessful(response)) {
            const savedPortfolio = response.content;
            const newStudioSubject = { privatePortfolioId: savedPortfolio.id };
            refresh(originalAnalysisSubjectQuery(newStudioSubject));

            set(openPrivateAllocatorPortfolio, savedPortfolio);
            set(allocatorAnalysisSubject(newStudioSubject), new AnalysisSubject(savedPortfolio, 'private-portfolio'));

            Notifications.notifyUpdate(
              savingPortfolioNotification,
              'Portfolio saved successfully.',
              NotificationType.SUCCESS,
            );
          } else {
            Notifications.notifyUpdate(
              savingPortfolioNotification,
              'An error occurred updating the portfolio.',
              NotificationType.ERROR,
            );
          }
        } catch (error) {
          logExceptionIntoSentry(error);
          Notifications.notifyUpdate(
            savingPortfolioNotification,
            'An error occurred updating the portfolio.',
            NotificationType.ERROR,
          );
        }
      },
    [],
  );

  const handleAfterUnsavedChangesAction = useCallback(
    async (event: 'cancel' | 'proceed' | 'saveAndProceed') => {
      const analysisView = analysisViewRef.current;
      setAfterUnsavedChangesAction(undefined);

      if (event === 'cancel') {
        afterUnsavedChangesAction?.cancelCallback?.();
        return;
      }

      const originalView = {
        savedName: analysisView?.name,
        savedId: analysisView?.id,
      };

      if (event === 'saveAndProceed') {
        const [saveResult] = await Promise.all([
          hasUnsavedChange ? onSave(undefined) : undefined,
          hasUnsavedReturnsAllocatorChanges ? saveAllocatedPortfolio() : undefined,
          hasUnsavedPrivatesAllocatorChanges ? savePrivateAllocatorPortfolio() : undefined,
        ]);

        const finalView = saveResult ?? originalView;
        afterUnsavedChangesAction?.proceedCallback(finalView.savedName, finalView.savedId);
        return;
      }

      if (event === 'proceed') {
        afterUnsavedChangesAction?.proceedCallback(originalView.savedName, originalView.savedId);
        return;
      }

      throw assertExhaustive(event);
    },
    [
      analysisViewRef,
      setAfterUnsavedChangesAction,
      afterUnsavedChangesAction,
      hasUnsavedChange,
      onSave,
      hasUnsavedReturnsAllocatorChanges,
      saveAllocatedPortfolio,
      hasUnsavedPrivatesAllocatorChanges,
      savePrivateAllocatorPortfolio,
    ],
  );

  const onPdfExport = useCallback(
    async (isInternal: boolean) => {
      const analysisView = analysisViewRef.current;
      if (hasUnsavedChange || hasUnsavedReturnsAllocatorChanges || hasUnsavedPrivatesAllocatorChanges) {
        await new Promise<void>((resolve) =>
          setAfterUnsavedChangesAction({
            proceedCallback: async (analysisViewName, analysisViewId) => {
              await onExport(isInternal, analysisViewName, analysisViewId);
              resolve();
            },
            cancelCallback: () => resolve(),
            hideDiscardBtn: !analysisView?.id,
          }),
        );
      } else {
        await onExport(isInternal);
      }
    },
    [
      analysisViewRef,
      hasUnsavedReturnsAllocatorChanges,
      hasUnsavedPrivatesAllocatorChanges,
      hasUnsavedChange,
      onExport,
      setAfterUnsavedChangesAction,
    ],
  );

  const checkDuplicateReportName = useCallback(
    (updatedReportName: string) => {
      const analysisView = analysisViewRef.current;
      const updatedAnalysisView = {
        ...analysisView,
        name: updatedReportName,
      } as AnalysisView;

      if (updatedReportName === baselineView?.name) {
        setAnalysisView(updatedAnalysisView);
        setIsDuplicateReportName(false);
        setIsCheckingDuplicateReportName(false);
        return;
      }

      checkViewName(updatedReportName)
        .then((isDuplicateName) => {
          if (isDuplicateName.content) {
            Notifications.notify(
              `Report name '${updatedReportName}' already exists.  Please choose a different name.`,
              NotificationType.INFO,
            );
          }
          setIsDuplicateReportName(isDuplicateName.content);
        })
        .finally(() => {
          setAnalysisView(updatedAnalysisView);
          setIsCheckingDuplicateReportName(false);
        });
    },
    [analysisViewRef, baselineView?.name, setAnalysisView, setIsCheckingDuplicateReportName, setIsDuplicateReportName],
  );

  // TODO: this seems like dead code?
  const selectImage = useCallback(
    (imageId: string, view: AnalysisView) => {
      onUpdateCustomViewOptions({ customViewOptions: { imageId } }, view);
    },
    [onUpdateCustomViewOptions],
  );

  // TODO: this seems like dead code? Very confusing because it has the same name as uploadReportImage in useStudioIamges,
  // but slightly different behavior, and seems unused.
  const uploadReportImage = useCallback(
    async (input: HTMLInputElement | null, coverImage: boolean, view?: AnalysisView) => {
      const analysisView = analysisViewRef.current;
      if (!input || !input.files || !analysisView) {
        return;
      }

      setImageUploading(true);
      const toastId = Notifications.notify('Uploading image', NotificationType.LOADING);
      const image = input.files[0];
      const basePath = 'report';
      await storeImageWithBasePath(image, basePath)
        .then((response) => {
          const imageId = response.content;

          const existingImages = analysisView?.customViewOptions?.images ?? [];
          const newImage: ImageDetails = {
            id: imageId,
            basePath,
            contentStream: undefined,
            contentType: 'image',
          };
          const images = [...existingImages, newImage];

          if (view) {
            onUpdateCustomViewOptionsChildViewAndGlobal({ customViewOptions: { imageId } }, view, {
              customViewOptions: { images },
            });
          } else {
            const toSave = coverImage
              ? {
                  customViewOptions: {
                    images,
                    coverPageImage: imageId,
                  },
                }
              : { customViewOptions: { images } };
            onUpdateCustomViewOptions(toSave, analysisView);
          }

          setImageUploading(false);
          Notifications.notifyUpdate(toastId, 'Uploaded image', NotificationType.SUCCESS);
        })
        .catch(() => {
          setImageUploading(false);
          Notifications.notifyUpdate(toastId, 'Upload image failed', NotificationType.ERROR);
          logExceptionIntoSentry('Unable to upload image for user');
        });
    },
    [analysisViewRef, onUpdateCustomViewOptions, onUpdateCustomViewOptionsChildViewAndGlobal],
  );

  const [reportName, setReportName] = useDebounceToGlobal(analysisView?.name ?? '', checkDuplicateReportName);
  const [reportNameValue, setReportNameValue] = useDebounceToGlobal(reportName, setReportName);

  const onChangeReportName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      setIsCheckingDuplicateReportName(true);
      const updatedReportName = typeof e === 'string' ? e : e.target.value;
      setIsDuplicateReportName(false);
      setReportNameValue(updatedReportName);
    },
    [setIsCheckingDuplicateReportName, setIsDuplicateReportName, setReportNameValue],
  );

  useEffect(() => {
    if (firstOpeningOfTheView) {
      setFirstOpeningOfTheView(false);
      onSelectGlobal(true);
      setLeftPanelOpen(true);
      setReportNameValue(analysisView?.name ?? '');
      setReportName(analysisView?.name ?? '');
    }
  }, [
    analysisView,
    firstOpeningOfTheView,
    onSelectGlobal,
    openReportConfigModal,
    setFirstOpeningOfTheView,
    setReportName,
    setReportNameValue,
    setLeftPanelOpen,
  ]);

  // Update url if analysis view id changes
  useEffect(() => {
    setReportNameValue(analysisView?.name ?? '');
    setReportName(analysisView?.name ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisView?.id]);

  // Memo the returned object, otherwise consumers were recomputing and rerendering unnecessarily.
  return useMemo(
    () => ({
      isSaving,
      hasUnsavedChange,
      noAccessModifiedView: !!analysisView?.owner && analysisView?.owner.id !== profileSettings?.user.id,
      blockOptions,
      // block toolbar actions
      onBlockReorderUp,
      onBlockReorderDown,
      onDeleteBlock,
      onInsertBlock,
      onDuplicateBlock,
      // Page actions
      onDuplicatePage,
      // studio toolbar actions
      onSave,
      onSaveAs,
      onRename,
      onUpdateCustomViewOptions,
      handleAfterUnsavedChangesAction,
      onDeletePage,
      onAddNewPage,
      onPdfExport,
      reportName,
      reportNameValue,
      setReportName,
      setReportNameValue,
      onChangeReportName,
      selectImage,
      uploadReportImage,
      imageUploading,
      isOpenReportConfigModal,
      openReportConfigModal,
      closeReportConfigModal,
    }),
    [
      analysisView?.owner,
      blockOptions,
      closeReportConfigModal,
      handleAfterUnsavedChangesAction,
      hasUnsavedChange,
      imageUploading,
      isOpenReportConfigModal,
      isSaving,
      onAddNewPage,
      onBlockReorderDown,
      onBlockReorderUp,
      onChangeReportName,
      onDeleteBlock,
      onDeletePage,
      onDuplicateBlock,
      onDuplicatePage,
      onInsertBlock,
      onPdfExport,
      onRename,
      onSave,
      onSaveAs,
      onUpdateCustomViewOptions,
      openReportConfigModal,
      profileSettings?.user.id,
      reportName,
      reportNameValue,
      selectImage,
      setReportName,
      setReportNameValue,
      uploadReportImage,
    ],
  );
};

export default useStudioToolbar;
