export interface PGADatapoint {
  value?: number;
  rank?: number;
}

export interface PGARowData {
  name: string;
  rowId: string;
  isMetadata?: boolean;
  isUniverse?: boolean;
  rowData: PGADatapoint[];
}

type UniverseMetadata = {
  name: string;
  id: string;
};

export const universeDatapoints: UniverseMetadata[] = [
  {
    name: '5th Percentile',
    id: 'p5',
  },
  {
    name: '25th Percentile',
    id: 'p25',
  },
  {
    name: 'Median',
    id: 'p50',
  },
  {
    name: '75th Percentile',
    id: 'p75',
  },
  {
    name: '95th Percentile',
    id: 'p95',
  },
  {
    name: '# of Investments',
    id: 'constituents',
  },
];
