import type { SetterOrUpdater, RecoilValue, RecoilState } from 'recoil';
import { useRecoilValueLoadable, useRecoilStateLoadable } from 'recoil';
import { useEffect, useState } from 'react';

/**
 * Like `useRecoilValue`, but when the value has been updated, it keeps returning the previous value
 * throughout the time of the update.
 *
 * @param recoilState - the recoil atom or selector,
 * @param defaultValue - the value to return before initial load
 */
export function useCachedLoadableValue<T>(recoilState: RecoilValue<T | undefined>, defaultValue?: T): T | undefined {
  const [cachedValue, setCachedValue] = useState<T | undefined>(defaultValue);
  const recoilLoadable = useRecoilValueLoadable(recoilState);
  useEffect(() => {
    if (recoilLoadable.state === 'hasValue' && recoilLoadable.contents !== cachedValue) {
      setCachedValue(recoilLoadable.contents);
    }
  }, [recoilLoadable, cachedValue]);
  return cachedValue;
}

/**
 * Like `useRecoilState`, but when the value has been updated, it keeps returning the previous value
 * throughout the time of the update.
 *
 * @param recoilState - the recoil atom or selector`,
 * @param defaultValue - the value to return before initial load
 */
export function useCachedLoadableState<T>(
  recoilState: RecoilState<T | undefined>,
  defaultValue?: T,
): [T | undefined, SetterOrUpdater<T | undefined>] {
  const [recoilLoadable, setRecoilState] = useRecoilStateLoadable(recoilState);
  const [cachedValue, setCachedValue] = useState<T | undefined>(defaultValue);
  useEffect(() => {
    if (recoilLoadable.state === 'hasValue' && recoilLoadable.contents !== cachedValue) {
      setCachedValue(recoilLoadable.contents);
    }
  }, [recoilLoadable, cachedValue]);
  return [cachedValue, setRecoilState];
}
