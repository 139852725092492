import type { Analysis, CustomizedBlock, ReturnsFactorAnalysis } from 'venn-api';
import type { CategoryMetric, ExcelCell, AnyDuringEslintMigration, CustomizableMetric } from 'venn-utils';
import {
  formatDate,
  formatExportValue,
  RESIDUAL_FACTOR_ID,
  RISK_FREE_RATE_FACTOR_ID,
  TOTAL_FACTOR_ID,
  hasFeesExcluded,
  getComparisonLabelForBlockLegend,
  getBaseFee,
  assertNotNil,
} from 'venn-utils';
import type { VennColors } from 'venn-ui-kit';
import { ColorUtils } from 'venn-ui-kit';
import { get, keyBy } from 'lodash';
import * as moment from 'moment';
import type { AnalysisRequest } from '../../../types';
import type { LineChartLegendItem } from '../../../../charts/analysis-charts/factor-charts/components/parseData';
import { TOTAL_LABEL } from '../../../customAnalysisContants';
import { getResidualKey } from '../factorTrendUtils';
import type { SeriesLineOptions } from 'highcharts';
import { formatExportableNameWithOptionalFee } from '../../../../legend';

const FACTOR_EXPOSURE_TREND_KEY = 'factorExposuresTrend';
const FACTOR_CONTRIBUTION_TO_RISK_KEY = 'factorContributionToRiskTrend';
const FACTOR_CONTRIBUTION_TO_RETURN_KEY = 'factorContributionToReturnTrend';

const excludedFactorMap = new Map<string, string[]>([
  [FACTOR_EXPOSURE_TREND_KEY, [RISK_FREE_RATE_FACTOR_ID, TOTAL_FACTOR_ID]],
  [FACTOR_CONTRIBUTION_TO_RISK_KEY, [RISK_FREE_RATE_FACTOR_ID, TOTAL_FACTOR_ID]],
  [FACTOR_CONTRIBUTION_TO_RETURN_KEY, []],
]);

interface AnalysisResultsInputProps {
  requests: AnalysisRequest[];
  factorMetrics?: CategoryMetric[];
  selectedBlock?: CustomizedBlock;
  analyses?: (Analysis | undefined)[][];
  currentMetric?: CustomizableMetric;
  colors: VennColors;
}

export const getColor = (index: number, colors: VennColors) => {
  const LINE_COLORS = [
    colors.DataLineColor.Gold,
    colors.DataLineColor.PaleGold,
    colors.DataLineColor.Yellow,
    colors.DataLineColor.Green,
    colors.DataLineColor.PaleBlue,
    colors.DataLineColor.DarkBlue,
    colors.DataLineColor.Pink,
    colors.DataLineColor.DeepGreen,
  ];

  return LINE_COLORS[index % 8];
};

export const getFactorColor = (baseColor: string, itemIndex: number) => {
  // First item with full color, lighter every factor
  const opacity = Math.max(1 - itemIndex * 0.03, 0.1);
  return ColorUtils.opacify(baseColor, opacity);
};

export const parseLineChartItems = ({
  requests,
  analyses,
  colors,
  selectedBlock,
  factorMetrics,
  currentMetric,
}: AnalysisResultsInputProps): LineChartLegendItem[] => {
  if (!analyses || !currentMetric?.analysisResultKey) {
    return [];
  }

  const filteredFactors = factorMetrics?.filter(
    (factor) => !excludedFactorMap.get(currentMetric.key)?.includes(factor.id),
  );

  const factorMap = keyBy(filteredFactors, 'id');
  return requests.reduce<LineChartLegendItem[]>((results, req, sIdx) => {
    if (req.isBenchmark) {
      // Ignore benchmark
      return results;
    }
    const factors = selectedBlock?.selectedFactors ?? [];
    const analysis = analyses[sIdx]?.find((a) => a?.analysisType === currentMetric?.analysisType);
    const returnAnalysis = get(analysis, [currentMetric.analysisResultKey!, 0]);

    if (!returnAnalysis) {
      return results;
    }
    factors.forEach((fId, index) => {
      const color = getColor(sIdx, colors);
      const factor = factorMap[fId];
      if (factor) {
        const itemColor = getFactorColor(color, index);
        results.push({
          name: factor.name,
          color: itemColor,
          mainSerie: getSerieData(fId, returnAnalysis, currentMetric.analysisResultKey!),
          seriesTypes: {
            mainSerieType: getComparisonLabelForBlockLegend(req.subject),
          },
          fee: getBaseFee(req.subject),
          feesExcluded: hasFeesExcluded(req.subject),
          lineWidth: 1 + sIdx * 0.25, // differentiate subjects for color and line width
        });
      }
    });

    return results;
  }, []);
};

const getSerieData = (
  factorId: string,
  returnsFactorAnalyses: ReturnsFactorAnalysis[],
  analysisResultKey: string,
): NonNullable<SeriesLineOptions['data']> => {
  const residualKey = getResidualKey(analysisResultKey);

  return returnsFactorAnalyses.map((returnsFactorAnalysis) => {
    if (factorId === TOTAL_FACTOR_ID) {
      return [returnsFactorAnalysis.regressionEnd, returnsFactorAnalysis.periodTotalReturn];
    }
    if (factorId === RESIDUAL_FACTOR_ID && residualKey) {
      return [
        returnsFactorAnalysis.regressionEnd,
        returnsFactorAnalysis[residualKey],
        returnsFactorAnalysis.residualTStat,
      ] as AnyDuringEslintMigration;
    }

    if (factorId === RISK_FREE_RATE_FACTOR_ID) {
      return [returnsFactorAnalysis.regressionEnd, returnsFactorAnalysis.riskFreeReturn];
    }

    const findData = returnsFactorAnalysis.factors.find((f) => f.id === Number(factorId));
    if (!findData) {
      return [returnsFactorAnalysis.regressionEnd, null, null];
    }
    return [returnsFactorAnalysis.regressionEnd, findData.contribution, findData.tstat];
  });
};

export const convertLineDataToExcel = (
  items: LineChartLegendItem[],
  title: string,
  isPercentage: boolean,
): ExcelCell[][] => {
  const header: ExcelCell[] = [{ value: title }, { value: 'Instrument' }];

  const endTimeSet = new Set<number>();
  items.forEach((item) =>
    item.mainSerie.forEach((data) => {
      if (data?.[0]) {
        endTimeSet.add(data[0]);
      }
    }),
  );

  const timestamps = [...endTimeSet].sort();
  header.push(...timestamps.map((stamp) => ({ value: formatDate(stamp) })));

  const excelSheet = [header];
  loadFactorResults(excelSheet, items, isPercentage, timestamps);
  return excelSheet;
};

const loadFactorResults = (
  excelSheet: ExcelCell[][],
  items: LineChartLegendItem[],
  isPercentage: boolean,
  timestamps: number[],
) => {
  items.forEach((factor) => {
    if (!factor.mainSerie) {
      return;
    }

    const mainSerie = timestamps.map((t) => {
      const findSerie = factor.mainSerie.find((serie) => moment.utc(t).isSame(serie?.[0], 'day')) ?? [t, null, null];
      return findSerie;
    });

    const subjectName = formatExportableNameWithOptionalFee(
      assertNotNil(factor.seriesTypes.mainSerieType),
      factor.fee,
      factor.feesExcluded,
    );

    const factorValueRow = [
      { value: factor.name },
      {
        value: subjectName,
      },
      ...mainSerie.map((datapoint) => formatExportValue(datapoint[1], isPercentage)),
    ];
    excelSheet.push(factorValueRow);

    if (factor.name !== TOTAL_LABEL) {
      const factorTstatRow = [
        { value: `${factor.name} T-stat` },
        { value: subjectName },
        ...mainSerie.map((datapoint) => formatExportValue(datapoint[2], false)),
      ];
      excelSheet.push(factorTstatRow);
    }
  });
};
