import type { ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from '../../../style/color';
import { CheckboxWrapper } from '../../checkbox-wrapper';
import type { DropMenuCheckboxItem } from '../types';
import { toClassName } from 'venn-utils';
import EllipsisTooltipSpan from '../../ellipsis-tooltip/EllipsisTooltipSpan';
import { isNil } from 'lodash';
import { TooltipPosition } from '../../enums';
import classNames from 'classnames';

export interface CheckboxMenuItemProps<T = string> {
  item: DropMenuCheckboxItem<T>;
  onCheck: () => void;
  onOnly?: () => void;
  showLines?: boolean;
  maxWidth?: number;
  hideCheckbox?: boolean;
  isFirst?: boolean;
  isReadOnly?: boolean;
  children?: ReactNode;
  tooltipPortal?: boolean;
}

const ONLY_BUTTON_WIDTH = 80;
const LEVEL_INDENT = 20;
const CONTEXT_CHILD_FACTOR_PADDING = 10;

class CheckboxMenuItem<T> extends React.PureComponent<CheckboxMenuItemProps<T>> {
  render() {
    const {
      item,
      onCheck,
      onOnly,
      showLines,
      maxWidth,
      hideCheckbox,
      isFirst,
      isReadOnly,
      tooltipPortal = false,
    } = this.props;
    const {
      label,
      level,
      checked,
      description,
      disabled,
      className,
      icon,
      badge,
      tooltipContent,
      alwaysShowTooltip,
      contextualChildItems,
    } = item;
    const qaClass = toClassName(label);
    const showOnly = !isNil(onOnly) && !hideCheckbox;
    const isRowActive = hideCheckbox && checked;
    return (
      <>
        <FilterCheckboxRow
          level={level}
          className={classNames(
            'qa-dropmenu-checkbox-item',
            qaClass,
            className,
            isRowActive && 'qa-dropmenu-checkbox-item-active',
          )}
          disabled={disabled}
          active={isRowActive}
        >
          {showLines && level !== undefined && (
            <Lines level={level}>
              {Array(level)
                .fill(0)
                .map((_, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Line key={idx} level={idx} />
                ))}
            </Lines>
          )}
          <CheckboxWrapper
            checked={checked}
            onChange={onCheck}
            onClick={hideCheckbox && !isNil(onOnly) ? onOnly : undefined}
            indeterminate={item.indeterminate}
            disabled={disabled || isReadOnly}
            hideCheckbox={hideCheckbox}
          >
            {maxWidth ? (
              <EllipsisTooltipSpan
                maxWidth={maxWidth - (level ?? 0) * LEVEL_INDENT - (showOnly ? ONLY_BUTTON_WIDTH : 0) - 40}
                flex
                alwaysShowTooltip={alwaysShowTooltip}
                position={isFirst ? TooltipPosition.Bottom : TooltipPosition.Top}
                content={tooltipContent}
                usePortal={tooltipPortal}
              >
                {icon}
                {label}
                {badge}
              </EllipsisTooltipSpan>
            ) : (
              <>
                {icon}
                {label}
                {badge}
              </>
            )}
          </CheckboxWrapper>
          {showOnly && !disabled && !isReadOnly ? (
            <Only
              className="qa-checkbox-item-only"
              onClick={(e) => {
                e.stopPropagation();
                onOnly?.();
              }}
            >
              only
            </Only>
          ) : null}
        </FilterCheckboxRow>
        {description ? (
          <FilterCheckboxRow>
            <Description>{description}</Description>
          </FilterCheckboxRow>
        ) : null}
        {(contextualChildItems || []).map((item) => (
          <ContextChildItem key={item} level={level}>
            {maxWidth !== undefined ? (
              <EllipsisTooltipSpan
                flex
                maxWidth={maxWidth - ((level ?? 0) + 1) * LEVEL_INDENT - CONTEXT_CHILD_FACTOR_PADDING - 40}
                position={isFirst ? TooltipPosition.Bottom : TooltipPosition.Top}
              >
                {item}
              </EllipsisTooltipSpan>
            ) : (
              <>{item}</>
            )}
          </ContextChildItem>
        ))}
      </>
    );
  }
}

interface ItemProps {
  level?: number;
  disabled?: boolean;
  active?: boolean;
}

const Description = styled.div`
  font-size: 10px;
  color: ${GetColor.HintGrey};
  line-height: 1.4;
  padding: 0 24px;
`;

export const FilterCheckboxRow = styled.div<ItemProps>`
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  padding-left: 20px;
  label > span {
    flex: 1;
    font-size: 14px;
  }
  ${(props) => !isNil(props.level) && `padding-left: ${props.level * LEVEL_INDENT + 20}px;`}
  color: ${({ disabled }) => (disabled ? GetColor.Grey : GetColor.Black)};
  :hover {
    background-color: ${GetColor.WhiteGrey};
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${GetColor.WhiteGrey};
    `}
`;

const ContextChildItem = styled.div<ItemProps>`
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  padding-left: 20px;
  label > span {
    flex: 1;
    font-size: 14px;
  }
  ${(props) =>
    !isNil(props.level) && `padding-left: ${(props.level + 1) * LEVEL_INDENT + CONTEXT_CHILD_FACTOR_PADDING + 20}px;`}
  color: ${({ disabled }) => (disabled ? GetColor.Grey : GetColor.Black)};
  :hover {
    background-color: ${GetColor.WhiteGrey};
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${GetColor.WhiteGrey};
    `}
`;

const Lines = styled.div<{ level: number }>`
  position: relative;
  ${({ level }) => css`
    margin-left: -${level * LEVEL_INDENT}px;
    width: ${level * LEVEL_INDENT}px;
  `}
`;

const Line = styled.div<{ level: number }>`
  border-left: 1px dashed ${GetColor.Grey};
  position: absolute;
  height: 110%;
  ${({ level }) => css`
    left: ${level * LEVEL_INDENT + 7}px;
  `}
`;

const Only = styled.div`
  opacity: 0;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${GetColor.Primary.Dark};
  font-size: 11px;
  cursor: pointer;
  line-height: 24px;
  width: 33px;
  padding: 0;
  margin-right: 10px;
  margin-left: 20px;
  display: inline-flex;
  align-items: center;
  /* stylelint-disable-next-line */
  ${FilterCheckboxRow}:hover & {
    opacity: 1;
  }
`;

export default CheckboxMenuItem;
