import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const useCustomIconColor = (isNav?: boolean, navSelected?: boolean, color?: string, hoverHighlight?: boolean) => {
  const { Colors } = useContext(ThemeContext);
  const fillColor = isNav ? (navSelected ? Colors.WhiteGrey : Colors.MidGrey1) : color ?? Colors.Black;
  const hoverColor = hoverHighlight ? (isNav ? Colors.WhiteGrey : Colors.Primary.Main) : undefined;
  const bgFillColor = isNav
    ? navSelected
      ? Colors.NavigationBarColor.Active
      : Colors.NavigationBarColor.InactiveBackground
    : Colors.White;
  return {
    fillColor,
    hoverColor,
    bgFillColor,
    Colors,
  };
};

export default useCustomIconColor;
