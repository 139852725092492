import { type RecoilState, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';

interface RecoilSyncerProps<T> {
  node: RecoilState<T>;
  value: T;
}

export const RecoilSyncer = <T,>({ node, value }: RecoilSyncerProps<T>) => {
  const setState = useSetRecoilState(node);

  useEffect(() => {
    setState(value);
  }, [value, setState]);

  return null;
};
