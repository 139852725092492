/*
 * Venn Platform API 1.0.0 Operations for "snapshot"
 * Automatically generated file - do not modify!
 */

import type { AnalysisSearch, AnalysisSnapshot, PageAnalysisSnapshot } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Delete snapshot file */
export async function deleteSnapshotFile(fileId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/snapshot/${encodeURIComponent(fileId)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Download file data */
export async function getSnapshotFileData(fileId: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/snapshot/${encodeURIComponent(fileId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get active snapshot lists for specific saved analysis view */
export async function getSpecificViewSnapshots(
  analysisViewId: string,
  signal?: AbortSignal,
): Promise<OperationResult<AnalysisSnapshot[]>> {
  const endpoint = `/api/snapshot/view/${encodeURIComponent(analysisViewId)}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AnalysisSnapshot[] = (await response.json()) as AnalysisSnapshot[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get user's active snapshots */
export async function getAnalysisSnapshots(signal?: AbortSignal): Promise<OperationResult<AnalysisSnapshot[]>> {
  const endpoint = '/api/snapshot';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: AnalysisSnapshot[] = (await response.json()) as AnalysisSnapshot[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Save snapshot file */
export async function saveSnapshotFile(body?: any, signal?: AbortSignal): Promise<OperationResult<string>> {
  const endpoint = '/api/snapshot';
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: string = (await response.json()) as string;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Search user's active snapshots */
export async function searchAnalysisSnapshots(
  body?: Partial<AnalysisSearch>,
  signal?: AbortSignal,
): Promise<OperationResult<PageAnalysisSnapshot>> {
  const endpoint = '/api/snapshot/search';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: PageAnalysisSnapshot = (await response.json()) as PageAnalysisSnapshot;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
