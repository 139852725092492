import type { FC } from 'react';
import React from 'react';
import { GetColor } from 'venn-ui-kit';
import styled from 'styled-components';

interface Props {
  formatter?: (value: number) => string;
  value: number;
  print: boolean;
  textAnchor?: string;
  x?: number;
  y?: number;
  fontSize?: string;
}

const Label: FC<React.PropsWithChildren<Props>> = ({
  formatter,
  value,
  print,
  y = 0,
  x = 0,
  textAnchor = 'start',
  fontSize,
}) => (
  <LabelText textAnchor={textAnchor} x={x} y={y} print={print} fontSize={fontSize}>
    {formatter ? formatter(value) : value}
  </LabelText>
);

export default Label;

const LabelText = styled.text<{ print: boolean; fontSize?: string }>`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  font-size: ${(props) => (props.fontSize ? props.fontSize : props.print ? '12px' : '14px')};
  fill: ${GetColor.Black};
`;
