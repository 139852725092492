import React, { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { copyPrivatePortfolio, type PrivatePortfolioNode, savePrivatePortfolio } from 'venn-api';
import type { SearchMenuItem } from 'venn-components';
import { Flexbox, IconPosition, LoadingSize, PRIVATE_ASSET_LAB_FAQ_HREF, selectClasses, Spinner } from 'venn-ui-kit';
import ConditionalOverlay from '../../conditional-overlay';
import FaqLink from '../../faq-link/FaqLink';
import { useDebounce } from '../../hooks';
import RequiredLabel from '../../label';
import ModalFooter from '../../modal/ModalFooter';
import { CreateFromScratchFooter } from '../../search-menu/components/CreateCompositeFooter';
import SearchMenuBar from '../../search-menu/SearchMenuBar';
import BaseStatefulInput from '../../stateful-input/StatefulInput';
import { useValidatePrivatePortfolioName } from '../../utils/useValidatePrivatePortfolioName';
import { Header } from '../data-uploader/components/page-parts';
import Field from '../validation/Field';

const DEBOUNCE_DELAY = 500;

interface CreatePrivatePortfolioStepProps {
  onCancel: () => void;
  onPrivatePortfolioCreated: (portfolio: PrivatePortfolioNode) => void;
  investments?: PrivatePortfolioNode[];
}

interface FieldType<T> {
  value: T;
  error: string;
  isLoading: boolean;
}

const NameInput = ({
  name,
  setName,
}: {
  name: FieldType<string>;
  setName: Dispatch<SetStateAction<FieldType<string>>>;
}) => {
  const [debouncedName] = useDebounce(name.value, DEBOUNCE_DELAY);

  useValidatePrivatePortfolioName(
    debouncedName,
    useMemo(
      () => ({
        onEmpty: () => setName((name) => ({ ...name, error: '' })),
        onValid: () => setName((name) => ({ ...name, error: '' })),
        onInvalid: (error: string) => setName((name) => ({ ...name, error })),
        setIsLoading: (isLoading: boolean) => setName((name) => ({ ...name, isLoading })),
      }),
      [setName],
    ),
  );

  return (
    <Field error={name.error}>
      <Label required>Portfolio name:</Label>
      <StatefulInput
        placeholder="Enter portfolio name"
        value={name.value}
        onChange={(value) => setName((name) => ({ ...name, value, isLoading: true }))}
        selectOnFocus
        icon={name.isLoading ? <Spinner size={LoadingSize.micro} /> : null}
        iconPosition={IconPosition.Right}
      />
    </Field>
  );
};

const SourcePortfolioInput = ({
  sourcePortfolio,
  setSourcePortfolio,
}: {
  sourcePortfolio: FieldType<SearchMenuItem | null>;
  setSourcePortfolio: Dispatch<SetStateAction<FieldType<SearchMenuItem | null>>>;
}) => {
  const setValue = (value: SearchMenuItem | null) =>
    setSourcePortfolio((sourcePortfolio) => ({ ...sourcePortfolio, value }));

  return (
    <Field error={sourcePortfolio.error}>
      <Label>Create new portfolio from:</Label>
      <StyledSearchMenuBar
        smallScreen
        autofocus={false}
        customPlaceholder="Create from scratch..."
        value={sourcePortfolio.value}
        isClearable
        portfoliosOnly
        privateAssetSearchMode="PRIVATES_ONLY"
        includeTags={false}
        includeBenchmarks={false}
        showRecentlyAnalyzed={false}
        onSelected={setValue}
        location="Create private portfolio"
        footer={CreateFromScratchFooter(() => setValue(null))}
      />
    </Field>
  );
};

function isValid(name: FieldType<string>) {
  return name.value !== '' && name.error === '' && !name.isLoading;
}

function getTooltip(name: FieldType<string>) {
  if (name.value === '') {
    return 'Please enter portfolio name';
  }
  if (name.isLoading) {
    return 'Please wait while we validate portfolio name';
  }
  return '';
}

function useCreatePrivatePortfolioFormState() {
  const [name, setName] = useState<FieldType<string>>({
    value: '',
    error: '',
    isLoading: false,
  });

  const [sourcePortfolio, setSourcePortfolio] = useState<FieldType<SearchMenuItem | null>>({
    value: null,
    error: '',
    isLoading: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const createPortfolio = async (investments: PrivatePortfolioNode[]) => {
    if (!isValid(name)) {
      throw new Error('Invalid name');
    }

    const sourcePortfolioId = sourcePortfolio.value?.searchResult?.privatePortfolioId;

    try {
      setIsSubmitting(true);
      let portfolio: PrivatePortfolioNode;
      if (sourcePortfolioId != null) {
        const response = (await copyPrivatePortfolio(sourcePortfolioId, name.value)).content;

        const investmentsWithNewParent: PrivatePortfolioNode[] = investments.map((i) => {
          return { ...i, rootNodeId: response.id, parentNodeId: response.id };
        });
        const modifiedPortfolio = { ...response, children: response.children.concat(investmentsWithNewParent) };
        const newPortfolio = await savePrivatePortfolio(modifiedPortfolio);
        portfolio = newPortfolio.content;
      } else {
        const response = await savePrivatePortfolio({ name: name.value, children: investments });
        portfolio = response.content;
      }
      return portfolio;
    } finally {
      setIsSubmitting(false);
    }
  };

  return { name, setName, sourcePortfolio, setSourcePortfolio, createPortfolio, isSubmitting };
}

const CreatePrivatePortfolioStep = ({
  onCancel,
  onPrivatePortfolioCreated,
  investments = [],
}: CreatePrivatePortfolioStepProps) => {
  const { name, setName, sourcePortfolio, setSourcePortfolio, createPortfolio, isSubmitting } =
    useCreatePrivatePortfolioFormState();

  return (
    <StyledConditionalOverlay center condition={isSubmitting} contentClassName="conditional-overlay-content">
      <StyledHeader>
        <h1>Create new privates portfolio</h1>
        <h2>
          Give your portfolio a name. Then, either select an existing portfolio template or create a new portfolio from
          scratch.
        </h2>
        <h3>
          Have more questions? Visit the <FaqLink link={PRIVATE_ASSET_LAB_FAQ_HREF} />.
        </h3>
      </StyledHeader>

      <Flexbox direction="column" grow={1} alignItems="center" justifyContent="center">
        <Flexbox
          direction="column"
          gap={20}
          css={css`
            width: 500px;
          `}
        >
          <NameInput name={name} setName={setName} />
          <SourcePortfolioInput sourcePortfolio={sourcePortfolio} setSourcePortfolio={setSourcePortfolio} />
        </Flexbox>
      </Flexbox>

      <ModalFooter
        className="no-margin-footer"
        onCancel={onCancel}
        primaryLabel="Create"
        primaryDisabled={!isValid(name)}
        onPrimaryClick={async () => {
          const portfolio = await createPortfolio(investments);
          onCancel();
          onPrivatePortfolioCreated(portfolio);
        }}
        primaryTooltip={getTooltip(name)}
      />
    </StyledConditionalOverlay>
  );
};

export default CreatePrivatePortfolioStep;

const Label = styled(RequiredLabel)`
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
`;

const StatefulInput = styled(BaseStatefulInput)`
  display: flex;
`;

const StyledSearchMenuBar = styled(SearchMenuBar)`
  .${selectClasses.SearchIcon} {
    display: none;
  }
`;

const StyledHeader = styled(Header)`
  padding-top: 0;
  padding-bottom: 25px;
`;

const StyledConditionalOverlay = styled(ConditionalOverlay)`
  height: 100%;

  & > .conditional-overlay-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
