import type { VennColors, Theme, Typography, Images, Schemes } from 'venn-ui-kit';
import { noop } from 'lodash';
import type { Videos } from '../../../../../venn-ui-kit/src/style/themes';

export const mockVideos: Videos = {
  splashScreenAnimation: 'https://app.venn.twosigma.com/splash-screen-animation.mp4',
  factorLensVideo: 'https://app.venn.twosigma.com/TSFL.mp4',
  productTourVideo: 'https://app.venn.twosigma.com/productWalkthrough.mp4',
  aisDemoVideo: 'https://app.venn.twosigma.com/ais_demo.mp4',
};
export const mockGreenVideos: Videos = {
  splashScreenAnimation: 'https://dev.altivant.com/splash-screen-animation.mp4',
  factorLensVideo: 'https://dev.altivant.com/TSFL.mp4',
  productTourVideo: 'https://dev.altivant.com/productWalkthrough.mp4',
  aisDemoVideo: 'https://dev.altivant.com/ais_demo.mp4',
};

export const mockImages: Images = {
  holdingsAsset: 'default/holdingsAsset.png',
  extrapolationAsset: '',
  reportLabAsset: '',
  macroScenario: '',
  scenario: '',
  vennBackground: '',
  six: '',
  portfolioLabs: '',
  trend: '',
  customFonts: '',
};
export const mockGreenImages: Images = {
  holdingsAsset: 'green/holdingsAsset.png',
  extrapolationAsset: '',
  reportLabAsset: '',
  macroScenario: '',
  scenario: '',
  vennBackground: '',
  six: '',
  portfolioLabs: '',
  trend: '',
  customFonts: '',
};
export const mockTypography: Typography = {
  fontFamily: 'Roboto,sans-serif',
};

export const mockColors: VennColors = {
  Primary: {
    Main: '#00CCCC',
    Dark: '#00818A',
    Light: '#D4F3F2',
    Medium: '#94BEC3',
  },
  PaleTeal: '#94BEC3',
  WhiteGrey: '#F7F7F7',
  PaleGrey: '#F1F1F2',
  LightGrey: '#B2B2B2',
  LightGrey2: '#E6E6E6',
  Grey: '#D8D8D8',
  MidGrey1: '#BBBDBF',
  MidGrey2: '#999999',
  HintGrey: '#767676',
  DarkGrey: '#666666',
  DarkGrey2: '#242A2F',
  HoverGrey: '#F3F1F1',
  Black: '#10161B',
  TransparentBlack: 'rgba(16, 22, 27, 0.9)',
  White: '#FFFFFF',
  HighlightDark: '#6600FF',
  HighlightLight: '#AC75FF',
  HighlightLightBackground: '#ECDEFF',
  HighlightBackground: '#302683',
  Alert: '#DE9F9B',
  Error: '#B73A3A',
  Warning: '#FFAB00',
  PeerGroupCarouselColor: '#11696F',

  Gold: '#7C7026',
  PaleGold: '#A08D14',
  Green: '#008854',
  PaleBlue: '#189ACD',
  DarkBlue: '#0072AF',
  Pink: '#AE5A88',

  Optimization: {
    Positive: '#11696F',
    Negative: '#8F5017',
  },

  DEPRECATED_DataBarColor: {
    LightGold: '#9E9459',
    LightPaleGold: '#CBBC5B',
    LightPaleBlue: '#6ED6FF',
    LightPink: '#DE9BC0',
    LightDarkBlue: '#3396C7',
  },

  DataBarColor: {
    LightGold: '#9E9459',
    LightPaleBlue: '#6ED6FF',
    LightDarkBlue: '#3396C7',
  },

  DEPRECATED_DataLineColor: {
    Gold: '#867A30',
    PaleGold: '#BEAB32',
    Green: '#00B072',
    PaleBlue: '#4ACCFF',
    DarkBlue: '#007CB9',
    Pink: '#D682B0',
    DeepGreen: '#00897B',
  },

  DataLineColor: {
    Gold: '#867A30',
    PaleGold: '#BEAB32',
    Yellow: '#EAEA06',
    Green: '#00B072',
    PaleBlue: '#4ACCFF',
    DarkBlue: '#007CB9',
    Pink: '#D682B0',
    DeepGreen: '#00897B',
  },

  HoldingsColor: {
    SectorColor: {
      Cyclical: '#33C08F',
      Defensive: '#6ED6FF',
      Sensitive: '#0072AF',
      'Cash And Equivalents': '#008854',
      Corporate: '#EFEF38',
      Derivative: '#DE9BC0',
      Government: '#9E9459',
      Municipal: '#CBBC5B',
      Securitized: '#6D4C5E',
      Unknown: '#BBBDBF',
    },
    AssetColor: {
      'US Stock': '#9E9459',
      'Non-US Stock': '#6ED6FF',
      'US Fixed Income': '#DBD08C',
      'Non-US Fixed Income': '#B7EBFF',
      Preferred: '#FBFBCD',
      Convertible: '#F2F26A',
      Cash: '#008854',
      Other: '#BBBDBF',
      Unknown: '#BBBDBF',
    },
  },

  PeerGroupColor: {
    GradientHigh: '#60C1B4',
    GradientLow: '#D6EDEA',
  },

  CashFlowColor: {
    Historical: '#867A30',
    Projected: '#00897B',
    Typical: '#007CB9',
  },

  StudioSubjectColor: {
    A1: '#CE7322',
    A2: '#0EC5B4',
    A3: '#4637BC',
    A4: '#E2DA1A',
    A5: '#EE71B4',
    A6: '#0EA4FC',
    A7: '#CA481E',
    A8: '#0EC479',
    A9: '#D95B78',
  },

  DEPRECATED_DivergingColor: {
    A5: '#4E2508',
    A4: '#8F5017',
    A3: '#B7793A',
    A2: '#DCB887',
    A1: '#EEDDC7',
    MID: '#F5F5F5',
    B1: '#CAE4E6',
    B2: '#8CC8CC',
    B3: '#3E9397',
    B4: '#11696F',
    B5: '#003638',
  },

  DivergingColor: {
    A5: '#4E2508',
    A4: '#8F5017',
    A3: '#B7793A',
    A2: '#DCB887',
    A1: '#EEDDC7',
    MID: '#F5F5F5',
    B1: '#CAE4E6',
    B2: '#8CC8CC',
    B3: '#3E9397',
    B4: '#11696F',
    B5: '#003638',
  },

  NavigationBarColor: {
    Active: '#2F3942',
    ActiveBackground: '#479EA5',
    ActiveLighter: '#009AA6',
    InactiveBackground: '#2F3942',
    ShimmerBackground: '#505A63',
  },

  UNSAFE: {
    LightBlue: '#B7E3F5',
    Separator: '#E2E2E2',
    Silver: '#D4D8DC',
    Azure: '#2196F3',
    Steel: '#76848F',
    Red: '#B82A2B',
  },

  GreyScale: {
    Grey10: '#F5F7FA',
    Grey20: '#EBEFF5',
    Grey30: '#DDE3ED',
    Grey40: '#C8D1E0',
    Grey50: '#AFBACC',
    Grey60: '#8E99AB',
    Grey70: '#707A8A',
    Grey80: '#58606E',
    Grey90: '#434A54',
    Grey100: '#333840',
  },
};

export const mockSchemes: Schemes = {
  FactorColors: {
    mainColor: '#3E9397',
    comparisonColor: '#B7793A',
    labelColor: '#666666',
  },
  BarChartColors: {
    mainColor: '#9E9459',
    secondaryColor: '#3396C7',
    comparisonColor: '#6ED6FF',
    lineColor: '#F1F1F2',
  },
  LineChartColors: {
    mainColor: '#867A30',
    secondaryColor: '#007CB9',
    comparisonColor: '#4ACCFF',
    lineColor: '#F1F1F2',
  },
  Proxy: {
    proxyLine: '#BEAB32',
    darkProxyLine: '#867A30',
    extrapolation: '#4ACCFF',
    subjectLine: '#10161B',
    invertedSubjectLine: '#FFFFFF',
    badgeText: '#FFFFFF',
    badgeBg: '#A08D14',
  },
};

export const mockTheme: Theme = {
  Images: mockImages,
  Typography: mockTypography,
  Colors: mockColors,
  Schemes: mockSchemes,
  Videos: mockVideos,
  setCustomColors: noop,
  customColors: undefined,
  setDebugColors: noop,
  inPrintMode: false,
  inPrintModeOrReportLab: false,
};
