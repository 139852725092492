import React from 'react';
import { Label, Icon, Tooltip, Caption } from 'venn-ui-kit';
import styled from 'styled-components';

interface HeaderProps {
  label: string;
  subLabel?: React.ReactNode;
  info?: string;
  link?: string;
  rightContent?: React.ReactNode;
  disabledTooltip?: string;
}

const MetaHeader = ({ label, info, link, rightContent, disabledTooltip, subLabel }: HeaderProps) => (
  <Container>
    <LabelContainer>
      <Label>
        <Tooltip content={disabledTooltip}>{label}</Tooltip>
        {info && (
          <Tooltip content={info}>
            <StyledInfoLink target="_blank" rel="noopener noreferrer" href={link}>
              <StyledInfoIcon type="info-circle" prefix="fas" />
            </StyledInfoLink>
          </Tooltip>
        )}
      </Label>
      {rightContent}
    </LabelContainer>
    <Caption>{subLabel}</Caption>
  </Container>
);

export const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledInfoLink = styled.a`
  color: inherit;
`;

const StyledInfoIcon = styled(Icon)`
  margin: 0 3px;
`;

export default MetaHeader;
