import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { AuthContainer, AuthCopyright, AuthLogo, Loading, Terms } from 'authentication-page';
import type { UserContextState } from 'venn-components';
import {
  contextHasAction,
  getCurrentContext,
  getPermissions,
  setLastUsedContext,
  UserContext,
  useUrlState,
} from 'venn-components';
import {
  type AccountCreationRequest,
  type ActionEnum,
  type LoginResponse,
  type NamedEntityInteger,
  type Portfolio,
  type SignupUser,
  type SimpleFund,
  type OperationResult,
  type TypedUserSettings,
  updateTheme,
  QUICKLINKS_STORAGE_KEY,
  LIBRARY_NEW_USER_KEY,
  activateAccountV3,
  agreeToTerms,
  AuthenticationErrorType,
  AuthenticationStatus,
  ContextService,
  forgotPassword as apiForgotPassword,
  getAgreements as apiGetAgreements,
  getProfile,
  getSwitchableWorkspaces,
  getUserActivity,
  getUserSettings,
  isAuthenticated,
  resetPassword as apiResetPassword,
  signin as apiSignIn,
  SupportedErrorCodes,
  updateSelectUserSettings,
  validateAccountActivation,
  validatePasswordReset,
  deleteTheme,
  type CustomTheme,
} from 'venn-api';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import {
  analyticsService,
  AnalyticsUtils,
  FS,
  logUserInfoToSentry,
  RECENT_ANALYSIS_STORAGE_KEY,
  Routes,
  useHasFF,
  useInitializeFeatures,
  userActivity,
} from 'venn-utils';
import OriginalLocationRedirect from './OriginalLocationRedirect';
import { type CustomColors, getAppTitle, GetColor, getTextThemeProvider, ZIndex } from 'venn-ui-kit';
import { RECENT_PORTFOLIO_LAB_SETTINGS_KEY } from '../../../portfolio-lab-page/src/logic/useValuesFromHistoryState';
import { useSetRecoilState } from 'recoil';
import { userIsAuthenticated } from 'venn-state';
import { TwoFactorSetupRoute } from '../../../authentication-page/src/two-factor/TwoFactorSetup';

const INCORRECT_CREDENTIALS_ERROR = {
  errorType: AuthenticationErrorType.IncorrectCredentials,
  message: 'The username and password you entered are not valid. Please try again.',
};

const INCORRECT_MFA_ERROR = {
  errorType: AuthenticationErrorType.IncorrectOrMissingExtraCredentials,
  message: 'The provided authentication or backup code was invalid. Please try again.',
};

const SERVER_ERROR = {
  errorType: AuthenticationErrorType.ServerError,
  message: 'An unexpected error occurred',
};

const NO_ORG_ERROR = {
  errorType: AuthenticationErrorType.NotMemberOfOrganization,
  message:
    "This account has been removed from its organization. Please contact your organization's administrator, " +
    `or reach out to ${getTextThemeProvider().supportEmail} if you think this is incorrect.`,
};

const ACTIVATION_CODE_EXPIRED = {
  errorType: AuthenticationErrorType.ActivationCodeIncorrectOrExpired,
  message:
    'It appears that the password setup link you used expired ' +
    'or had already been used. We have sent a new link to your registered ' +
    'email. Please click on it to set your password.',
};

const ACCOUNT_ALREADY_ACTIVATED = {
  errorType: AuthenticationErrorType.AccountAlreadyCreated,
  message: `You have already created your password. Please log in to use ${getAppTitle()}.`,
};

const ACCOUNT_ACTIVATION_LINK_INVALID = {
  errorType: AuthenticationErrorType.VerificationCodeIncorrectOrExpired,
  title: 'Verification link is invalid.',
  message:
    'It appears that this account verification link is invalid. ' +
    'Please double-check the link in your email and try again.',
};

const ACCOUNT_ACTIVATION_CODE_EXPIRED = {
  errorType: AuthenticationErrorType.VerificationCodeIncorrectOrExpired,
  title: 'Verification link has expired.',
  message:
    'It appears that this account verification link is expired. ' +
    'We have sent a new link to your registered ' +
    'email. Please click on it to verify your account.',
};

const ACCOUNT_LOCKED_MESSAGE = `${getAppTitle()} locks user accounts after a meaningful period of inactivity. ' +
  'To keep your account safe, please follow instructions sent to your ' +
  'email address to reset your password and reactivate your account.`;

const API_ERROR_MAP = {
  [SupportedErrorCodes.ActivationCodeExpiration]: ACCOUNT_ACTIVATION_CODE_EXPIRED,
  [SupportedErrorCodes.InvalidActivationCode]: ACCOUNT_ACTIVATION_LINK_INVALID,
  [SupportedErrorCodes.EnfUser]: ACCOUNT_ACTIVATION_LINK_INVALID,
  [SupportedErrorCodes.AccountNoActivate]: ACCOUNT_ACTIVATION_LINK_INVALID,
};

const CONTEXT_URL_KEY = 'context';

interface AuthProps extends RouteComponentProps {
  isAppExpired: boolean;
}

const AuthComponent: React.FC<React.PropsWithChildren<AuthProps>> = React.memo(function AuthComponent({
  children,
  history,
  isAppExpired,
}) {
  const hasContextSwitching = useHasFF('context_switching');

  const [unsignedAgreements, setUnsignedAgreements] = useState<{
    agreements: NamedEntityInteger[];
    loading: boolean;
    error: string;
  }>({
    agreements: [],
    loading: false,
    error: '',
  });

  const [authStatus, setAuthStatus] = useState(AuthenticationStatus.Unknown);
  const setIsAuthenticated = useSetRecoilState(userIsAuthenticated);
  const [loginResponse, setLoginResponse] = useState<LoginResponse | undefined>(undefined);
  const [credentials, setCredentials] = useState<SignupUser | undefined>(undefined);
  const [permissions, setPermissions] = useState(new Set<ActionEnum>());
  // Using Readonly Record because mutability is unsafe in React state.
  const [debugPermissions, setDebugPermissions] = useState({} as Readonly<Record<ActionEnum, boolean>>);
  const [currentUrlContext, setCurrentUrlContext] = useUrlState<string | undefined>(CONTEXT_URL_KEY, undefined);
  const { Videos, setCustomColors } = useContext(ThemeContext);

  const [requestedForgotPassword, setRequestedForgotPassword] = useState(false);
  const [userContext, setUserContext] = useState<UserContextState>({
    ...useContext(UserContext),
    profileSettings: undefined,
    organizations: [],
    loading: false,
  });

  const initializeFeatures = useInitializeFeatures();

  useEffect(() => {
    const isAuthenticated = authStatus === AuthenticationStatus.Authenticated;
    setIsAuthenticated(isAuthenticated);
    if (
      authStatus !== AuthenticationStatus.AuthenticatedMfaSetupRequired &&
      history.location.pathname === TwoFactorSetupRoute
    ) {
      history.replace(isAuthenticated ? Routes.HOME_PATH : Routes.SIGN_IN_PATH);
    } else if (
      authStatus === AuthenticationStatus.AuthenticatedMfaSetupRequired &&
      history.location.pathname !== TwoFactorSetupRoute
    ) {
      history.push(TwoFactorSetupRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, authStatus, setIsAuthenticated]);

  const hasDebugOverride = useCallback((permission: ActionEnum) => permission in debugPermissions, [debugPermissions]);

  const hasPermission = useCallback(
    (permission: ActionEnum) =>
      permission in debugPermissions
        ? debugPermissions[permission]
        : !hasContextSwitching || permissions.has(permission),
    [debugPermissions, hasContextSwitching, permissions],
  );

  const debugForcePermission = useCallback(
    (permission: ActionEnum, enabled: boolean) =>
      setDebugPermissions(
        Object.create(debugPermissions, {
          [permission]: { value: enabled },
        }),
      ),
    [debugPermissions],
  );

  const updateSettings = useCallback(
    async (updatedSettings: TypedUserSettings) => {
      const prevSettings = { ...userContext };
      setUserContext((c) => ({
        ...c,
        settings: {
          ...c.settings,
          user: {
            ...c.settings.user,
            ...updatedSettings,
          },
        },
      }));
      try {
        await updateSelectUserSettings(updatedSettings);
      } catch {
        // If there's an error updating settings, restore to previous state
        setUserContext(prevSettings);
      }
    },
    [userContext],
  );

  const fetchFullIdentity = useCallback(
    async (isNewUser?: boolean) => {
      const setUserHasUploaded = async () => {
        try {
          const response = await getUserActivity();
          const userInvestmentsResults: SimpleFund[] = response?.content?.recentUserInvestments ?? [];
          const orgInvestmentsResults: SimpleFund[] = response?.content?.recentOrganizationInvestments ?? [];
          const userUploadResults = userInvestmentsResults.filter((item) => item.investmentSource === 'UPLOAD');
          const orgUploadResults = orgInvestmentsResults.filter((item) => item.investmentSource === 'UPLOAD');
          setUserContext((c) => ({
            ...c,
            hasCompletedAnUpload: userUploadResults.length > 0,
            hasOrgCompleteAnUpload: orgUploadResults.length > 0,
          }));
        } catch {
          setUserContext((c) => ({
            ...c,
            hasCompletedAnUpload: false,
            hasOrgCompleteAnUpload: false,
          }));
        }
      };

      setUserContext((c) => ({
        ...c,
        loading: true,
      }));

      const setUserSettings = async (isNewUser?: boolean) => {
        try {
          if (isNewUser) {
            const newUserSettings = {
              [LIBRARY_NEW_USER_KEY]: true,
              [QUICKLINKS_STORAGE_KEY]: true,
            };
            await updateSelectUserSettings(newUserSettings);
            setUserContext((c) => ({
              ...c,
              settings: {
                system: {},
                user: newUserSettings,
              },
            }));
            return;
          }
          const { content: settings } = await getUserSettings();
          const userSettings = {
            ...settings,
            user: {
              ...settings.user,
              [QUICKLINKS_STORAGE_KEY]: true,
            },
          };
          setUserContext((c) => ({
            ...c,
            settings: userSettings,
          }));
        } catch {
          const settings = {
            system: {},
            user: {},
          };
          setUserContext((c) => ({
            ...c,
            settings,
          }));
        }
      };

      const setUserOrgs = async () => {
        try {
          const { content: organizations } = await getSwitchableWorkspaces();
          setUserContext((c) => ({
            ...c,
            organizations,
          }));
        } catch {
          setUserContext((c) => ({
            ...c,
            organizations: [],
          }));
        }
      };

      try {
        const [{ content }] = await Promise.all([
          getProfile(),
          setUserSettings(isNewUser),
          setUserHasUploaded(),
          initializeFeatures(),
        ]);
        setUserOrgs();
        setUserContext((c) => ({
          ...c,
          loading: false,
        }));
        // WARNING: to switch this FS.has to a hook would require refactoring this function entirely,
        // because we call initializeFeatures right before checking this FF
        const hasMfaUpdatesFF = FS.has('mfa_updates_ff');
        const authStatus =
          hasMfaUpdatesFF && content.organization.mfaRequired && !content.mfaEnabled
            ? AuthenticationStatus.AuthenticatedMfaSetupRequired
            : AuthenticationStatus.Authenticated;
        setAuthStatus(authStatus);
        setUserContext((c) => ({
          ...c,
          profileSettings: content,
          currentContext: getCurrentContext(c.currentContext, content),
        }));
        setCustomColors(() => content.customTheme?.theme as CustomColors | undefined);
      } catch {
        setAuthStatus(AuthenticationStatus.CredentialsRequired);
        setUserContext((c) => ({
          ...c,
          loading: false,
        }));
      }
    },
    [initializeFeatures, setCustomColors],
  );

  const getAgreements = async () => {
    setUnsignedAgreements((e) => ({
      ...e,
      loading: true,
    }));
    try {
      const response = await apiGetAgreements();
      setUnsignedAgreements((e) => ({
        ...e,
        agreements: response.content,
        loading: false,
        error: '',
      }));
    } catch {
      setUnsignedAgreements((e) => ({
        ...e,
        error: 'An unexpected error occurred',
        loading: false,
      }));
    }
  };

  const checkStatus = useCallback(
    async (isNewUser?: boolean, fallbackAuthStatus = AuthenticationStatus.CredentialsRequired) => {
      try {
        setAuthStatus(AuthenticationStatus.Checking);
        await fetchFullIdentity(isNewUser);
      } catch {
        setAuthStatus(fallbackAuthStatus);
      }
    },
    [fetchFullIdentity, setAuthStatus],
  );

  const signIn = async (newCredentials: SignupUser) => {
    setUserContext((c) => ({
      ...c,
      error: undefined,
    }));
    setCredentials(newCredentials);
    try {
      setAuthStatus(AuthenticationStatus.Checking);
      const { content } = await apiSignIn(newCredentials);
      if (content.mfaRequired) {
        setLoginResponse(content);
        setAuthStatus(AuthenticationStatus.CredentialsRequired);
        if (newCredentials.mfaCode) {
          setUserContext((c) => ({
            ...c,
            error: INCORRECT_MFA_ERROR,
          }));
        }
      } else {
        await fetchFullIdentity();
        if (isAppExpired) {
          window.location.reload();
        }
      }
    } catch (err: unknown) {
      const error = await err;
      setAuthStatus(AuthenticationStatus.CredentialsRequired);
      let displayError = SERVER_ERROR;
      if (typeof error === 'object' && error) {
        const maybeErrorResponse = error as Partial<Response>;
        const maybeOperationResult = error as Partial<OperationResult<{ code: AuthenticationErrorType }>>;
        if (maybeErrorResponse.status === 401) {
          displayError = INCORRECT_CREDENTIALS_ERROR;
        } else if (maybeOperationResult.content?.code === AuthenticationErrorType.NotMemberOfOrganization) {
          displayError = NO_ORG_ERROR;
        } else if (maybeErrorResponse.status === 403) {
          setAuthStatus(AuthenticationStatus.AccountLocked);
          return;
        }
      }
      setUserContext((c) => ({
        ...c,
        error: displayError,
      }));
    }
  };

  const activateAccount = async (newCredentials: Partial<AccountCreationRequest>) => {
    try {
      setAuthStatus(AuthenticationStatus.Checking);
      setUserContext((c) => ({
        ...c,
        error: undefined,
      }));
      const apiRequest = activateAccountV3;
      await apiRequest(newCredentials);
      // Fire analytics only when sign up successfully
      analyticsService.userCreated({
        source: 'organic',
      });
      analyticsService.signupFunnelCompleted();
      history.push(Routes.SIGN_IN_PATH);
      await fetchFullIdentity(true);
    } catch (err) {
      const error = await err;
      setAuthStatus(AuthenticationStatus.CredentialsRequired);
      setUserContext((c) => ({
        ...c,
        error: error.status === 401 ? INCORRECT_CREDENTIALS_ERROR : SERVER_ERROR,
      }));
    }
  };

  const forgotPassword = async (newCredentials: SignupUser) => {
    try {
      setAuthStatus(AuthenticationStatus.Checking);
      setCredentials(newCredentials);
      setUserContext((c) => ({
        ...c,
        error: undefined,
      }));
      await apiForgotPassword(newCredentials);
      setRequestedForgotPassword(true);
      setAuthStatus(AuthenticationStatus.CredentialsRequired);
    } catch {
      setUserContext((c) => ({
        ...c,
        error: SERVER_ERROR,
      }));
    }
  };

  const resetPassword = async (newCredentials: SignupUser) => {
    try {
      setCredentials(newCredentials);
      setAuthStatus(AuthenticationStatus.Checking);
      setUserContext((c) => ({
        ...c,
        error: undefined,
      }));
      const result = await apiResetPassword(newCredentials);
      setAuthStatus(AuthenticationStatus.CredentialsRequired);
      if (result.status === 200) {
        signIn(newCredentials);
      }
    } catch {
      setUserContext((c) => ({
        ...c,
        error: SERVER_ERROR,
      }));
    }
  };

  const activationCodeCheck = async (newCredentials: SignupUser) => {
    try {
      setCredentials(newCredentials);
      setAuthStatus(AuthenticationStatus.Checking);
      setUserContext((c) => ({
        ...c,
        error: undefined,
      }));
      const response = await validatePasswordReset(newCredentials);
      setAuthStatus(AuthenticationStatus.CredentialsRequired);
      if (response.status !== 200 || (response.content && !response.content.body)) {
        history.replace(Routes.SIGN_IN_PATH);
        setUserContext((c) => ({
          ...c,
          error: ACTIVATION_CODE_EXPIRED,
        }));
        await apiForgotPassword(newCredentials);
      }
    } catch (error) {
      setUserContext((c) => ({
        ...c,
        error: SERVER_ERROR,
      }));
    }
  };

  const verificationCodeCheck = async (creds: AccountCreationRequest) => {
    try {
      setUserContext((c) => ({
        ...c,
        error: undefined,
      }));
      const response = await validateAccountActivation(creds);
      if (response.content === 'ACTIVE') {
        setUserContext((c) => ({
          ...c,
          error: ACCOUNT_ALREADY_ACTIVATED,
        }));
      }
    } catch (error) {
      setUserContext((c) => ({
        ...c,
        error: API_ERROR_MAP[error?.content?.code] ?? SERVER_ERROR,
      }));
    }
  };

  const setUserCompletedUpload = useCallback((hasCompletedAnUpload: boolean) => {
    setUserContext((c) => ({
      ...c,
      hasCompletedAnUpload,
      hasOrgCompleteAnUpload: hasCompletedAnUpload,
    }));
  }, []);

  const setCurrentContext = useCallback(
    (newContext: string) => {
      history.push(Routes.HOME_PATH);
      // Prevent to access recent resources in different context
      userActivity.removeLocalStorageItemPerUser(RECENT_PORTFOLIO_LAB_SETTINGS_KEY);
      userActivity.removeLocalStorageItemPerUser(RECENT_ANALYSIS_STORAGE_KEY);
      setUserContext((c) => ({
        ...c,
        currentContext: newContext,
      }));
    },
    [history],
  );

  useEffect(() => {
    const { currentContext } = userContext;
    setCurrentUrlContext(currentContext);
    ContextService.setCurrentContext(currentContext);
    if (userContext.profileSettings?.user.id) {
      setLastUsedContext(userContext.profileSettings?.user.id, currentContext);
      currentContext && setPermissions(getPermissions(currentContext, userContext.profileSettings.availableContexts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.profileSettings?.user.id, userContext.currentContext]);

  useEffect(() => {
    setCurrentUrlContext(userContext.currentContext);
  }, [setCurrentUrlContext, userContext.currentContext]);

  useEffect(() => {
    if (userContext.profileSettings && userContext.profileSettings.user.id) {
      initializeFeatures();
      userActivity.setUser(userContext.profileSettings);
      AnalyticsUtils.identifyAndGroup(userContext.profileSettings);
      logUserInfoToSentry(userContext.profileSettings);
    }
  }, [initializeFeatures, userContext.profileSettings]);

  const syncServerInterval = useRef<number | undefined>(undefined);
  /**
   * Track the last sync time so that we don't sync too frequently or infrequently.
   * Start at current time so that we don't redundantly fetch with the other auth fetching.
   */
  const lastSyncTimeMs = useRef(Date.now());

  const syncServer = useCallback(() => {
    const checkIfAuthenticated = async () => {
      if (!userContext.profileSettings) {
        return;
      }
      try {
        await isAuthenticated();
      } catch (e) {
        const error = await e;
        // If the user is non-authenticated at the time we fetch their features
        // re-load the page so the user can sign-in
        if (error.status === 401 || error.status === 403) {
          window.location.reload();
        }
      }
    };

    // don't sync as frequently if we're not authenticated
    // since we probably don't need super up to date features while signing up/in
    const syncIntervalSeconds = authStatus === AuthenticationStatus.Authenticated ? 60 : 60 * 30;
    // don't need to keep syncing features if browser tab is not visible
    const syncFunctions =
      document.visibilityState === 'visible' ? [initializeFeatures, checkIfAuthenticated] : [checkIfAuthenticated];
    const runSyncFunctions = () => {
      lastSyncTimeMs.current = Date.now();
      syncFunctions.forEach((f) => f());
    };

    // We need to check when the last time we ran the function is and potentially run it immediately, otherwise background and foregrounding tabs could
    // delay synchronization indefinitely.
    if (lastSyncTimeMs.current < Date.now() - 1000 * syncIntervalSeconds) {
      runSyncFunctions();
    }

    // Should this function execute again, such as after a visibilitychange or auth status change, reset the period function interval
    clearInterval(syncServerInterval.current);
    syncServerInterval.current = window.setInterval(runSyncFunctions, 1000 * syncIntervalSeconds);
  }, [authStatus, initializeFeatures, userContext.profileSettings]);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (syncServerInterval.current !== undefined) {
        clearInterval(syncServerInterval.current);
      }
      syncServer();
    };
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [syncServer]);

  useEffect(() => {
    syncServer();
    return () => clearInterval(syncServerInterval.current);
  }, [syncServer]);

  useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  useEffect(() => {
    // Load agreements after users sign in
    if (authStatus === AuthenticationStatus.Authenticated) {
      getAgreements();
    }
  }, [authStatus]);

  const acceptTerm = async (term: NamedEntityInteger) => {
    await agreeToTerms(term.id);
    getAgreements();
  };

  const notFullyAuthenticated =
    authStatus !== AuthenticationStatus.Authenticated || !!unsignedAgreements?.agreements?.length;
  const showSpinner =
    unsignedAgreements.loading || (authStatus !== AuthenticationStatus.Authenticated && userContext.loading);
  const showAuthContainer = ![AuthenticationStatus.Authenticated, AuthenticationStatus.AccountLocked].includes(
    authStatus,
  );

  const hasPermissionForResource = useCallback(
    (permission: ActionEnum, resource: { ownerContextId?: string }) =>
      !hasContextSwitching ||
      !resource.ownerContextId ||
      contextHasAction(resource.ownerContextId, userContext.profileSettings?.availableContexts ?? [], permission),
    [hasContextSwitching, userContext.profileSettings?.availableContexts],
  );

  const canWriteToPortfolio = useCallback(
    (portfolio: Portfolio) =>
      !(
        portfolio.draft ||
        portfolio.remoteId ||
        portfolio.demo ||
        (portfolio.ownerContextId && !hasPermissionForResource('EDIT_PORTFOLIO', portfolio))
      ),
    [hasPermissionForResource],
  );

  const saveCustomColors = useCallback(
    async (customColors: CustomColors | undefined) => {
      let newCustomTheme: CustomTheme | undefined;
      if (!customColors) {
        if (userContext.profileSettings?.customTheme?.id) {
          await deleteTheme(userContext.profileSettings.customTheme.id);
        }
      } else {
        const { content } = await updateTheme({
          id: userContext.profileSettings?.customTheme?.id,
          theme: customColors,
        });
        newCustomTheme = content;
      }
      setUserContext((current) => ({
        ...current,
        profileSettings: current.profileSettings
          ? { ...current.profileSettings, customTheme: newCustomTheme }
          : undefined,
      }));
    },
    [userContext.profileSettings],
  );

  const userContextActions = useMemo(
    () => ({
      refresh: fetchFullIdentity,
      currentContext: currentUrlContext,
      updateSettings,
      setUserCompletedUpload,
      setCurrentContext,
      hasPermissionForResource,
      canWriteToPortfolio,
      hasPermission,
      debugForcePermission,
      hasDebugOverride,
      saveCustomColors,
    }),
    [
      canWriteToPortfolio,
      currentUrlContext,
      debugForcePermission,
      fetchFullIdentity,
      hasDebugOverride,
      hasPermission,
      hasPermissionForResource,
      setCurrentContext,
      setUserCompletedUpload,
      updateSettings,
      saveCustomColors,
    ],
  );

  // All consumers will rerender if the actual object changes reference
  const contextValue = useMemo(() => ({ ...userContext, ...userContextActions }), [userContext, userContextActions]);
  return (
    <UserContext.Provider value={contextValue}>
      {showSpinner && <Loading />}
      {notFullyAuthenticated ? (
        <OriginalLocationRedirect>
          {showAuthContainer && (
            <AuthContainer
              error={userContext.error}
              loginResponse={loginResponse}
              credentials={credentials}
              signIn={signIn}
              forgotPassword={forgotPassword}
              resetPassword={resetPassword}
              // @ts-expect-error: TODO fix strictFunctionTypes
              activationCodeCheck={activationCodeCheck}
              verificationCodeCheck={verificationCodeCheck}
              pending={[AuthenticationStatus.Unknown, AuthenticationStatus.Checking].includes(authStatus)}
              activateAccount={activateAccount}
              checkStatus={checkStatus}
              requestedForgotPassword={requestedForgotPassword}
            />
          )}
          {authStatus !== AuthenticationStatus.Authenticated ? null : (
            <Terms
              accept={acceptTerm}
              terms={unsignedAgreements.agreements}
              loading={unsignedAgreements.loading}
              error={unsignedAgreements.error}
            />
          )}
          {authStatus === AuthenticationStatus.AccountLocked && (
            <AccountLockedLayout message={ACCOUNT_LOCKED_MESSAGE} video={Videos.splashScreenAnimation} />
          )}
        </OriginalLocationRedirect>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
});

const AccountLockedLayout = ({ message, video }: { message: string; video: string }) => (
  <FixedContainer>
    <SplashVideo autoPlay loop muted>
      <source src={video} type="video/mp4" />
    </SplashVideo>
    <PageContainer>
      <MessageWrapper>
        <AuthLogo />
        <MessageContainer>
          <p>{message}</p>
        </MessageContainer>
        <AuthCopyright lineBreak />
      </MessageWrapper>
    </PageContainer>
  </FixedContainer>
);

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${GetColor.Black};
  z-index: ${ZIndex.Front};
`;

const SplashVideo = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  z-index: ${ZIndex.Background};
`;

const PageContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  margin: 100px auto 20px;
`;

const MessageContainer = styled.div`
  padding-right: 10px;
  max-height: 300px;
  font-size: 16px;
  text-align: left;
  overflow: auto;
  color: ${GetColor.UNSAFE.Silver};

  p {
    font-size: 16px;
    line-height: 25px;
  }
`;

export default withRouter(AuthComponent);
