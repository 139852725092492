import React, { useCallback, useContext, useMemo } from 'react';
import type { Page, ReorderableItem } from 'venn-components';
import { ReorderableList, StudioContext, StudioSidePanelContext, UserContext } from 'venn-components';
import { BorderBottom } from '../../shared';
import styled from 'styled-components';
import { Icon } from 'venn-ui-kit';
import type { Layout } from 'react-grid-layout';
import { buttonize } from 'venn-utils';
import type { AnalysisView } from 'venn-api';
import { compact } from 'lodash';
import BlockItem from './components/BlockItem';
import ItemActions from './components/ItemActions';
import NewPageButton from './components/NewPageButton';
import { getPageTitle } from '../../../logic/studioUtils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isReportState, selectedReportPage, viewPages } from 'venn-state';
import { ApplyFontDefaultsSection } from './components/ApplyFontDefaultsSection';

type Items = (ReorderableItem & { value: Page })[];

const PagesPanel = () => {
  const { hasPermission } = useContext(UserContext);
  const { analysisView, onDeletePage, onDuplicatePage } = useContext(StudioContext);
  const isReport = useRecoilValue(isReportState);
  const { onSelectPage } = useContext(StudioSidePanelContext);

  const selectedPage = useRecoilValue(selectedReportPage);
  const [pages, setPages] = useRecoilState(viewPages);

  const views: AnalysisView[][] = useMemo(
    () =>
      pages.map((page) => {
        const viewsOnPage = page.layout.map((view: Layout) => view.i) ?? [];
        return (
          compact(
            viewsOnPage?.map((refId: string) => analysisView?.customizedViews?.find((view) => view.refId === refId)),
          ) ?? []
        );
      }),
    [analysisView, pages],
  );

  const onReorderPages = useCallback(
    (newItems: Items) => {
      setPages(newItems.map((item) => item.value));
    },
    [setPages],
  );

  const items: Items = useMemo(
    () =>
      pages.map((page, index) => ({
        value: page,
        key: index.toString(),
        label: getPageTitle(page, index),
      })),
    [pages],
  );

  return (
    <div>
      {!isReport ? null : (
        <>
          <ApplyFontDefaultsSection />
          <BorderBottom>
            <NewPageButton />
          </BorderBottom>
          <ListContainer>
            <ReorderableList
              margin={15}
              disabled={!hasPermission('STUDIO_REORDER_BLOCKS')}
              reorderableItems={items}
              onReorderItems={onReorderPages}
              hideDelete
              itemRenderer={(item, index) => {
                const pageTitle = getPageTitle(item.value, index);
                return (
                  <ReorderItem>
                    <PageItem
                      {...buttonize(() => {
                        onSelectPage(index);
                      })}
                      key={index}
                    >
                      <Text fontSize={14} selected={selectedPage === index}>
                        <FileIcon type="file-lines" />
                        {pageTitle}
                      </Text>

                      <ItemActions
                        onDuplicate={!hasPermission('STUDIO_INSERT_BLOCKS') ? undefined : () => onDuplicatePage(index)}
                        onDelete={!hasPermission('STUDIO_DELETE_BLOCKS') ? undefined : () => onDeletePage(index)}
                        customDeleteText={`page (${pageTitle})`}
                      />
                    </PageItem>
                    {views[index].map((view) => (
                      <BlockItem key={view.refId} block={view} />
                    ))}
                  </ReorderItem>
                );
              }}
            />
          </ListContainer>
        </>
      )}
    </div>
  );
};

export default PagesPanel;

const ReorderItem = styled.div`
  width: 100%;
`;

const ListContainer = styled.div`
  cursor: pointer;
  > ul {
    margin: 0;

    > div {
      display: flex;
      align-items: center;
      min-height: 40px;
    }
  }
`;

const Text = styled.div<{ selected?: boolean; fontSize: number }>`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'inherit')};
  font-size: ${({ fontSize }) => fontSize}px;
`;

const PageItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  column-gap: 5px;
  height: 33px;
`;

const FileIcon = styled(Icon)`
  margin-right: 10px;
`;
