import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Icon, GetColor, Caption } from 'venn-ui-kit';
import MetaHeader from './MetaHeader';
import FieldWithLabel from './investment/FieldWithLabel';
import StatefulInput from '../stateful-input/StatefulInput';

interface TextRowProps {
  label: string;
  value?: string;
  isEditable: boolean;
  icon?: string;
  iconColor?: string;
  onChange?: (newValue: string) => void;
  className?: string;
  placeholder?: string;
  required?: boolean;
  showDisabledStyling: boolean;
  onBlur?: () => void;
  disabledTooltip?: string;
}

export const TextRow = ({
  label,
  value,
  isEditable,
  icon,
  iconColor,
  onChange,
  className,
  placeholder,
  required,
  showDisabledStyling,
  onBlur,
  disabledTooltip,
}: TextRowProps) => {
  const [error, setError] = useState<boolean>(false);
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (required) {
        const inputValue = event.target.value;
        setError(!inputValue || inputValue.length === 0);
      }
      onBlur?.();
    },
    [onBlur, required],
  );
  return (
    <FieldWithLabel className={className} showDisabledStyling={showDisabledStyling}>
      <MetaHeader disabledTooltip={showDisabledStyling ? disabledTooltip : undefined} label={label} />
      {isEditable ? (
        <>
          <StatefulInput
            blurOnChange
            value={value ?? ''}
            onChange={onChange}
            icon={icon}
            placeholder={placeholder}
            error={error}
            onBlur={handleBlur}
            data-testid="metadata-input"
          />
          {error && <Error>{label} cannot be empty</Error>}
        </>
      ) : (
        <div>
          {icon && <Icon type={icon} style={{ color: iconColor, marginRight: 2 }} />}
          {value || '--'}
        </div>
      )}
    </FieldWithLabel>
  );
};

export default TextRow;

const Error = styled(Caption)`
  color: ${GetColor.Error};
`;
