import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { GetColor, TooltipLink, INTERCOM_HELP_HREF } from 'venn-ui-kit';
import { BOTTOM_MESSAGE_HEIGHT, getFactorBarExportableExcel, getFactorsItems } from './factorBarUtils';
import type { GeneralChartProps } from '../types';
import useExportUpdate from '../../../logic/useExportUpdate';
import { formatExportableSubjectWithOptionalFee } from '../../../../legend';
import Measure from 'react-measure';
import { useSetBlockSize } from '../../../logic/useSetBlockSize';
import { recoilBlockContentSize, blockFonts, getCustomFontSize, availableFactorMetrics } from 'venn-state';
import { HorizontalBars } from './HorizontalBars';
import { useBlockWidth } from '../../../contexts/BlockWidthContext';
import { useRecoilValue } from 'recoil';

const FactorBar = ({
  height,
  metrics,
  selectedBlock,
  analyses,
  requests,
  exportMetaData,
  selectedRefId,
}: GeneralChartProps) => {
  const availableMetrics = useRecoilValue(availableFactorMetrics);
  const tableFont = useRecoilValue(blockFonts.blockTableData(selectedRefId));
  const legendFont = useRecoilValue(blockFonts.blockChartLegend(selectedRefId));
  const onResize = useSetBlockSize(recoilBlockContentSize.transformedState(selectedRefId));
  const metric = metrics ? metrics[0] : undefined;
  const { items, excludedFactors } = useMemo(
    () =>
      metric
        ? getFactorsItems(metric, false, selectedBlock, analyses, availableMetrics)
        : { items: [], excludedFactors: 0 },
    [analyses, metric, selectedBlock, availableMetrics],
  );

  const headers = useMemo(
    () =>
      requests.map((req) => ({
        label: formatExportableSubjectWithOptionalFee(req.subject),
        id: req.subject.id,
        isBenchmark: req.isBenchmark,
        portfolioComparisonType: req.subject.portfolioComparisonType,
      })),
    [requests],
  );

  const firstRequestBenchmark = requests[0]?.benchmark;
  const excelDataFn = useCallback(
    () =>
      getFactorBarExportableExcel(
        headers,
        items,
        metric?.dataType ?? 'NUMERIC',
        firstRequestBenchmark,
        !!selectedBlock?.relativeToBenchmark,
      ),
    [headers, items, metric?.dataType, firstRequestBenchmark, selectedBlock?.relativeToBenchmark],
  );

  useExportUpdate({
    selectedRefId,
    exportMetaData,
    excelDataFn,
  });

  const fontSize = getCustomFontSize(tableFont);
  const headerFontSize = getCustomFontSize(tableFont);
  const legendFontSize = getCustomFontSize(legendFont);

  const props = {
    items,
    height: height ? height - (excludedFactors ? BOTTOM_MESSAGE_HEIGHT : 0) : undefined,
    format: metric?.dataType,
    headers,
    isWaterfall:
      metric?.analysisType === 'FACTOR_CONTRIBUTION_TO_RETURN' ||
      metric?.analysisType === 'FACTOR_CONTRIBUTION_TO_RISK',
    fontSize,
    headerFontSize,
    legendFontSize,
  };

  const width = useBlockWidth().raw.innerWidth;
  return (
    <Measure bounds onResize={onResize}>
      {({ measureRef }) => {
        return (
          // TODO(collin.irwin): it isn't clear that this outer div is necessary. It used to be wrong (too large) and there was no ill effect.
          <div style={{ width }}>
            <div ref={measureRef}>
              <HorizontalBars {...props} />
              {excludedFactors > 0 && (
                <BottomMessage fontSize={fontSize}>
                  Two Sigma's factor selection methodology excluded {excludedFactors} insignificant
                  {excludedFactors > 1 ? ' factors' : ' factor'}.
                  <TooltipLink
                    positions={{
                      top: -60,
                      left: -90,
                    }}
                    href={INTERCOM_HELP_HREF}
                    top
                  />
                </BottomMessage>
              )}
            </div>
          </div>
        );
      }}
    </Measure>
  );
};

export default FactorBar;

const BottomMessage = styled.div<{ fontSize?: string }>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  color: ${GetColor.GreyScale.Grey70};
  font-style: italic;
  padding-left: calc(1rem * 2 / 3);
  padding-top: calc(1rem * 2 / 3);
  display: flex;
  align-items: center;
`;
