import type { ComponentType } from 'react';
import React from 'react';
import { BaseRecoilWrapper } from './BaseRecoilWrapper';
import type { Nominal } from 'venn-utils';
import type { RecoilWrapperState } from './makeState';

interface RenderRecoilComponentParams<T> {
  dependencyAtomValuePairs?: (RecoilWrapperStateInternal | RecoilWrapperState)[];
  Component: ComponentType<T>;
}

type RecoilWrapperStateInternal = Nominal<RecoilWrapperState, 'computeRecoilValueStateInternal'>;

/**
 * This is a helper HoC that allows you to mock recoil state use by a component.
 * Instead of mocking out the whole state tree, you can simply modify values of selectors / atoms
 * that are dependencies of the component with constant values.
 * example usage:
 * const WiredComponent = withMockRecoilValue({
 *     Component: RecoilComponent,
 *     dependencyAtomValuePairs: [makeAtomValuePair({ atom: foo, value: 3 })],
 *   });
 *   render(<WiredComponent {...props} />
 * @param dependencyAtomValuePairs: dependency atoms / selectors we want to mock. Must be created by makeAtomValuePair
 * @param Component: component under test that accesses recoil state
 */
export const withMockRecoilValue = <T,>({ dependencyAtomValuePairs, Component }: RenderRecoilComponentParams<T>) =>
  function mockRecoilValueHoc(props: T & JSX.IntrinsicAttributes) {
    return (
      <BaseRecoilWrapper states={dependencyAtomValuePairs}>
        <Component {...props} />
      </BaseRecoilWrapper>
    );
  };
