import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { KeyCodes, portalMenuIgnoreActivityClassName } from 'venn-ui-kit';
import { eventTargetOrAncestorContainsClass } from 'venn-utils';
import type { ModalWrapperProps } from './components/ModalWrapper';
import ModalWrapper from './components/ModalWrapper';
import type { ModalOverlayProps } from './components/ModalOverlay';
import ModalOverlay from './components/ModalOverlay';
import FocusTrap from 'focus-trap-react';

export interface ModalProps extends ModalOverlayProps, Partial<ModalWrapperProps> {
  /** Function fired to close Modal */
  onClose?: () => void;
  /** Allows to fire onClose on ESC */
  closeOnEsc?: boolean;
  /** Allows to fire onClose on click outside the modal */
  closeOnOutsideClick?: boolean;
  className?: string;
  /**
   * Removes top padding to allow custom content
   */
  noPadding?: boolean;
  /**
   * Removes the 1px border
   */
  noBorder?: boolean;
  /**
   * Adds more padding, mainly from the top
   */
  extraPadding?: boolean;
  /**
   * When width below 1024px, show modal in full screen
   */
  smallFullScreen?: boolean;
  /**
   * Makes the background transparent
   */
  noBackground?: boolean;
  /**
   * Changes the modal background to black to match the inverted colorscheme
   */
  inverted?: boolean;
  /**
   * Stroke width of the close icon.
   */
  closeIconWidth?: number;
  /**
   * Selector of the DOM node to receive initial focus, i.e. '.btnClass'.
   * If not present, the first focusable element inside the modal will be focused.
   */
  initialFocus?: string;

  testId?: string;
}

/**
 * Modal uses React 16 portal to appear in the different DOM node.
 * If You provide it with node, it will portal inside, otherwise
 * it will mount itself inside the div at the end of the `<body>`
 *
 * If You provide custom node, the overlay will be disabled.
 */
export class Modal extends Component<React.PropsWithChildren<ModalProps>> {
  private modal: Element;

  private defaultNode: HTMLElement;

  constructor(props: ModalProps) {
    super(props);
    this.prepareDOM(props);
  }

  componentDidMount() {
    this.prepareDOM(this.props);
    if (!this.props.onClose) {
      return;
    }
    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.onKeydown);
    }
    if (this.props.closeOnOutsideClick) {
      document.addEventListener('click', this.onOutsideModalClick);
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.onKeydown);
    }
    if (this.props.closeOnOutsideClick) {
      document.removeEventListener('click', this.onOutsideModalClick);
    }
    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode);
    }
  }

  public render() {
    const {
      children,
      className,
      node,
      size,
      zIndex,
      noPadding = false,
      noBorder = false,
      extraPadding = false,
      smallFullScreen = false,
      noBackground = false,
      inverted = false,
      initialFocus,
      testId = '',
    } = this.props;

    return ReactDOM.createPortal(
      <ModalOverlay node={node} zIndex={zIndex} data-testid={testId}>
        <FocusTrap focusTrapOptions={{ initialFocus, fallbackFocus: '#modal-wrapper', clickOutsideDeactivates: true }}>
          <ModalWrapper
            id="modal-wrapper"
            ref={(modal: HTMLElement) => {
              this.modal = modal;
            }}
            size={size}
            className={className}
            noPadding={noPadding}
            extraPadding={extraPadding}
            smallFullScreen={smallFullScreen}
            noBackground={noBackground}
            noBorder={noBorder}
            inverted={inverted}
          >
            {children}
          </ModalWrapper>
        </FocusTrap>
      </ModalOverlay>,
      this.props.node || this.defaultNode,
    );
  }

  private prepareDOM = (props: ModalProps): void => {
    if (!props.node && !this.defaultNode) {
      this.defaultNode = document.createElement('div');
      document.body.appendChild(this.defaultNode);
    }
  };

  private onOutsideModalClick = (event: MouseEvent): void => {
    const isNonPrimaryButtonPressed: boolean = event.button !== undefined && event.button !== 0;
    if (eventTargetOrAncestorContainsClass(event, portalMenuIgnoreActivityClassName)) {
      return;
    }
    if (!this.modal || this.modal.contains(event.target as HTMLElement) || isNonPrimaryButtonPressed) {
      return;
    }

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private onKeydown = (event: KeyboardEvent): void => {
    if (event.keyCode === KeyCodes.Escape && this.props.onClose) {
      this.props.onClose();
    }
  };
}

export default Modal;
