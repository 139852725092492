import React, { useContext, useEffect, useCallback, useState } from 'react';
import Header from './Header';
import type { SearchMenuItem } from 'venn-components';
import {
  FactorLensesContext,
  Footer,
  StickyNode,
  EmptySubjects,
  CompareControls,
  UserContext,
  withPageByolWatermark,
  PrintDisabledWarning,
} from 'venn-components';
import { analyticsService, hasAnalysisSubjectNoReturns, hasNoOverlap, logMessageToSentry, useHasFF } from 'venn-utils';
import styled, { css } from 'styled-components';
import MediaQuery from 'react-responsive';
import { GetColor, ZIndex, Loading } from 'venn-ui-kit';
import useComparison from '../logic/useComparison';
import AnalysisBlocks from './AnalysisBlocks';
import { xlsxDownload } from '../logic/excelDownload';
import PrintHeader from './PrintHeader';
import CombinedAnalysisTable from './CombinedAnalysisTable';
import getAnalysisBlocks from '../analysisBlocks';
import { HIDE_FORECASTS_IN_EXPORTS_KEY } from 'venn-api';

const ComparisonPage = () => {
  const factorLens = useContext(FactorLensesContext).primaryFactorLens;
  const { settings } = useContext(UserContext);
  const hideForecasts = !!settings?.user?.[HIDE_FORECASTS_IN_EXPORTS_KEY];
  const hasExportsDisabled = useHasFF('disable_exports_ff');
  const hasReportLab = useHasFF('studio_report_editor');

  const [isHeaderCompressed, setHeaderCompressed] = useState(false);
  const [isScrolled, setScrolled] = useState(false);

  useEffect(() => {
    const scrollContainer = document.getElementById('comparison-main');
    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        const scrollTop = scrollContainer?.scrollTop ?? 0;
        if (scrollTop > 100) {
          setHeaderCompressed(true);
        }
        if (scrollTop < 1) {
          setHeaderCompressed(false);
        }
        setScrolled(scrollTop > 0);
      });
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [setHeaderCompressed]);

  const {
    subjects,
    analyses,
    setHovered,
    dateRange,
    updateDateRange,
    maxDateRange,
    granularity,
    addSubject,
    clearSubjects,
    removeSubject,
    benchmark,
    updateBenchmark,
    relative,
    toggleRelative,
    analysisIsLoading,
    isAddDisabled,
    controlsDisabled,
    maxSelections,
    trackingId,
    analysisIsFetching,
    analysisUpdatedTime,
    isPivoted,
    togglePivoted,
    hoveredMetricIdx,
    onSave,
    onSaveAs,
    onRename,
    currentViewName,
    isViewInitLoading,
    hasUnsavedChanges,
    savedId,
    noAccessModifiedView,
  } = useComparison();

  const onXlsxDownload = useCallback(() => {
    if (!factorLens) {
      logMessageToSentry('Failed to download xlsx because missing factor lens');
      return;
    }
    xlsxDownload(
      subjects,
      analyses?.analyses ?? [],
      relative,
      factorLens,
      dateRange.to && dateRange.from ? dateRange : maxDateRange,
      isPivoted,
      false, // Don't hide forecast block in excel download for consistency (and as it's easy to modify by hand)
    );
    analyticsService.dataExported({
      objectType: 'Comparison',
      outputDescription: 'Comparison tables',
      outputFileType: 'xlsx',
      relativeToBenchmark: relative,
      userUploaded: false,
      subjectId: subjects.map((subject) => subject.analysisSubject?.id).join(','),
    });
  }, [subjects, analyses, relative, factorLens, dateRange, maxDateRange, isPivoted]);

  const optionDisabledTooltipContent = useCallback(
    (option) => {
      if (option.category === 'tag') {
        return '';
      }
      if (hasAnalysisSubjectNoReturns(option.value)) {
        return 'This item has no returns and cannot be analyzed';
      }
      if (hasNoOverlap(maxDateRange, option.searchResult)) {
        return "This item's available date range has no overlap with the date range of the existing subjects.";
      }
      return '';
    },
    [maxDateRange],
  );

  const isOptionDisabled = useCallback(
    (option: SearchMenuItem) => {
      return (
        option.category !== 'tag' &&
        (hasAnalysisSubjectNoReturns(option.value) || hasNoOverlap(maxDateRange, option.searchResult))
      );
    },
    [maxDateRange],
  );

  const ComparisonComponent = isPivoted ? CombinedAnalysisTable : AnalysisBlocks;
  const maxFrequency = analyses ? analyses.maxFrequency : 'UNKNOWN';

  const isEmptyPage = subjects.length === 0;
  if (!factorLens) {
    logMessageToSentry('Failed to render comparison page because of missing factor lens');
    return null;
  }

  return (
    <MainContent id="comparison-main">
      <MediaQuery print>
        {(print) => (
          <Content fitToPage={isEmptyPage || isPivoted} numSubjects={subjects.length}>
            {isEmptyPage ? (
              <>
                <Header downloadDisabled onXlsxDownload={onXlsxDownload} compressed={false} subjectsIsEmpty />
                {isViewInitLoading ? (
                  <LoadingWrapper>
                    <Loading pageLoader />
                  </LoadingWrapper>
                ) : (
                  <EmptySubjects
                    onAddSubject={addSubject}
                    header="Add anything to start comparing!"
                    location="emptyComparisonPage"
                  />
                )}
              </>
            ) : (
              <>
                {print ? (
                  <PrintHeader
                    subjects={subjects}
                    dateRange={dateRange}
                    maxDateRange={maxDateRange}
                    benchmark={benchmark}
                    relative={relative}
                    maxFrequency={maxFrequency}
                    currentViewName={currentViewName}
                  />
                ) : (
                  <StickyHeader shadow={isScrolled}>
                    <Header
                      downloadDisabled={subjects.length === 0 || controlsDisabled}
                      onXlsxDownload={onXlsxDownload}
                      compressed={isHeaderCompressed}
                      showCachedLoader={analysisIsFetching && !analysisIsLoading}
                      analysisUpdatedTime={analysisUpdatedTime}
                      subjectsIsEmpty={!subjects?.length}
                      currentViewName={currentViewName}
                      hasUnsavedChanges={hasUnsavedChanges}
                      onSave={onSave}
                      onRename={onRename}
                      onSaveAs={onSaveAs}
                      savedId={savedId}
                      noAccessModifiedView={noAccessModifiedView}
                    />
                    <CompareControls
                      locationOnPage="Comparison PageComponent - top right"
                      toggleLabel="Relative to Benchmark:"
                      toggleDisabled={!benchmark}
                      toggleDisabledMessage={
                        !benchmark ? 'Select a benchmark to enable benchmark-relative analysis' : undefined
                      }
                      subjects={subjects}
                      onAddSubject={addSubject}
                      onClearSubjects={clearSubjects}
                      onHoverSubject={setHovered}
                      onRemoveSubject={removeSubject}
                      dateRange={dateRange}
                      onDateRangeChange={updateDateRange}
                      maxDateRange={maxDateRange}
                      granularity={granularity}
                      benchmark={benchmark}
                      onBenchmarkChange={updateBenchmark}
                      relative={relative}
                      toggleRelative={toggleRelative}
                      isAddDisabled={isAddDisabled}
                      controlsDisabled={controlsDisabled}
                      maxSelections={maxSelections}
                      isPivoted={isPivoted}
                      togglePivoted={togglePivoted}
                      isOptionDisabled={isOptionDisabled}
                      optionDisabledTooltipContent={optionDisabledTooltipContent}
                    />
                  </StickyHeader>
                )}
                {hasExportsDisabled && !hasReportLab && <PrintDisabledWarning />}
                {(!print || !hasExportsDisabled || hasReportLab) && (
                  <ComparisonComponent
                    subjects={subjects}
                    analyses={analyses?.analyses ?? []}
                    blocks={getAnalysisBlocks(print && hideForecasts)}
                    factorLens={factorLens}
                    subjectErrors={analyses && analyses.subjectErrors}
                    setHovered={setHovered}
                    hoveredMetricIdx={hoveredMetricIdx}
                    setDateRange={updateDateRange}
                    relative={relative}
                    isLoading={analysisIsLoading}
                    trackingId={trackingId}
                    dateRange={dateRange}
                    hasBenchmark={!!benchmark}
                    maxFrequency={maxFrequency}
                    isPrinting={print}
                    removeComparisonSubject={removeSubject}
                    fetchingInBackground={analysisIsFetching && !!analyses?.analyses}
                    onRemoveSubject={removeSubject}
                  />
                )}
              </>
            )}
            <FooterContainer>
              <Footer />
            </FooterContainer>
          </Content>
        )}
      </MediaQuery>
    </MainContent>
  );
};

const MainContent = styled.div`
  width: 100%;
  overflow: auto;
`;

const Content = styled.div<{ fitToPage: boolean; numSubjects: number }>`
  @media screen {
    padding: 0 60px;
    display: inline-block;
    min-width: 1100px;
  }
  ${({ fitToPage }) => fitToPage && 'width: 100%;'}
  @media print {
    // Hack to ensure we print the footer in the pivot view.
    // There is no sense to try and print the pivoted combined table because it would literally never fit on the page.
    // Therefore, on print in pivot view, we print the "classic" view. The whole page doesn't re-render, just the table,
    // causing any overflowing content (including parts of the Correlation block and the legal footer) to be cut off.
    // Setting min-height that depends on the number of subjects makes sure we can fit in the whole Correlations block
    // and the footer too.
    min-height: ${({ numSubjects }) => 3000 + numSubjects * 200}px;
  }
`;

const StickyHeader = styled(StickyNode)<{ shadow?: boolean }>`
  background-color: ${GetColor.White};
  margin: 0 -60px;
  padding: 0 60px;
  ${({ shadow }) =>
    shadow
      ? css`
          box-shadow: 0px 20px 20px -20px ${GetColor.Grey};
        `
      : ''}
  transition: box-shadow 0.5s;
  min-width: 1100px;
  z-index: ${ZIndex.Sticky};
`;

const FooterContainer = styled.div`
  margin: 200px -60px 0 -60px;
  position: relative;
  z-index: ${ZIndex.StickyCover};
`;

const LoadingWrapper = styled.div`
  height: 300px;
`;

export default React.memo(withPageByolWatermark(ComparisonPage));
