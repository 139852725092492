/*
 * Venn Platform API 1.0.0 Operations for "releasenotes"
 * Automatically generated file - do not modify!
 */

import type { ReleaseNote, ReleaseNoteContent } from '../types';
import type { OperationResult } from '../../utils';
import { buildQuery, buildRequestInit, extractError } from '../../utils';

/** Delete a existing release note */
export async function deleteReleaseNote(id: string, signal?: AbortSignal): Promise<OperationResult<null>> {
  const endpoint = `/api/releasenotes/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({ method: 'DELETE', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Edit a existing release note */
export async function editReleaseNote(
  id: string,
  body?: Partial<ReleaseNoteContent>,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/releasenotes/${encodeURIComponent(id)}`;
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get all stored release notes */
export async function getAllReleaseNotes(signal?: AbortSignal): Promise<OperationResult<ReleaseNote[]>> {
  const endpoint = '/api/releasenotes/all';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ReleaseNote[] = (await response.json()) as ReleaseNote[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the current published release notes */
export async function getReleaseNotes(
  from?: number,
  to?: number,
  signal?: AbortSignal,
): Promise<OperationResult<ReleaseNoteContent[]>> {
  const endpoint = `/api/releasenotes${buildQuery({ from, to })}`;
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ReleaseNoteContent[] = (await response.json()) as ReleaseNoteContent[];
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Get the date of the most recently published note */
export async function getMostRecentReleaseNoteDate(signal?: AbortSignal): Promise<OperationResult<number>> {
  const endpoint = '/api/releasenotes/latest';
  const requestInit = buildRequestInit({ method: 'GET', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: number = (await response.json()) as number;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Publishes a release note */
export async function publishReleaseNote(
  body?: Partial<ReleaseNoteContent>,
  signal?: AbortSignal,
): Promise<OperationResult<ReleaseNote>> {
  const endpoint = '/api/releasenotes';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: ReleaseNote = (await response.json()) as ReleaseNote;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Test sending a release note */
export async function testSendingReleaseNote(
  id: string,
  email?: string,
  signal?: AbortSignal,
): Promise<OperationResult<null>> {
  const endpoint = `/api/releasenotes/${encodeURIComponent(id)}/send${buildQuery({ email })}`;
  const requestInit = buildRequestInit({ method: 'POST', signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content = null;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
