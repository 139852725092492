import React from 'react';
import { css, default as styled } from 'styled-components';
import type { PrivateFund } from 'venn-api';
import { GetColor, Headline3 } from 'venn-ui-kit';
import { formatDate, TextRow } from 'venn-components';
import { Dates } from 'venn-utils';
import { startCase } from 'lodash';
import { getFormattedValue } from '../../../home-page/src/components';

interface PrivateInvestmentInformationProps {
  fund?: PrivateFund;
}

export const PrivateInvestmentInformation = ({ fund }: PrivateInvestmentInformationProps) => {
  return (
    <>
      <Header>
        <Headline3>Fund Information</Headline3>
      </Header>
      <StyledMetaDataFields>
        <Columns>
          <Container showBackground>
            <TextRow
              label="Fund Manager"
              isEditable={false}
              value={fund?.manager}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Asset Class"
              isEditable={false}
              value={startCase(fund?.assetClass?.toLowerCase())}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Strategy"
              isEditable={false}
              value={startCase(fund?.strategy?.toLowerCase())}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Sub-Strategy"
              isEditable={false}
              value={startCase(fund?.subStrategy?.toLowerCase())}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />

            <TextRow
              label="Vintage"
              isEditable={false}
              value={fund?.vintage ? Dates.toYear(fund?.vintage) : '--'}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Fund Size"
              isEditable={false}
              value={getFormattedValue(fund?.size)}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Capital Commitment"
              isEditable={false}
              value={getFormattedValue(fund?.capitalCommitment)}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Currency"
              isEditable={false}
              value={fund?.currency ? startCase(fund?.currency?.toUpperCase()) : '--'}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
          </Container>
          <Container showBackground>
            <TextRow
              label="Fund Status"
              isEditable={false}
              value={fund?.status ? startCase(fund?.status?.toLowerCase()) : '--'}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Fund Type"
              isEditable={false}
              value={fund?.fundType ? startCase(fund?.fundType?.toLowerCase()) : '--'}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Geographic Focus"
              isEditable={false}
              value={startCase(fund?.geographicFocus?.toLowerCase())}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Core Industries"
              isEditable={false}
              value={fund?.coreIndustries.join(', ') ?? '--'}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Industries"
              isEditable={false}
              value={fund?.industries.join(', ') ?? '--'}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Final Close Date"
              isEditable={false}
              value={fund?.finalCloseDate ? Dates.toDayMonthYear(fund?.finalCloseDate) : '--'}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Final Close Size"
              isEditable={false}
              value={getFormattedValue(fund?.finalCloseSize)}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
            <TextRow
              label="Last Updated"
              isEditable={false}
              value={formatDate(fund?.updated) ?? '--'}
              onChange={undefined}
              showDisabledStyling
              disabledTooltip=""
              onBlur={undefined}
            />
          </Container>
        </Columns>
      </StyledMetaDataFields>
    </>
  );
};

const Columns = styled.div`
  display: flex;
  padding-left: 60px;
  flex-direction: column;
  align-items: flex-start;
  flex-flow: wrap;
  gap: 16px;
`;
const Container = styled.div<{ showBackground: boolean }>`
  ${({ showBackground }) =>
    showBackground &&
    css`
      min-width: 500px;
      width: 500px;
      max-width: 500px;
      background-color: ${GetColor.WhiteGrey};
      border-radius: 4px;
      margin-bottom: 20px;
    `}
`;
const StyledMetaDataFields = styled.div`
  max-width: 1200px;
  font-size: 14px;
  margin: 0 auto;
`;

const Header = styled.div`
  max-width: 1200px;
  font-size: 14px;
  margin: 0 auto;
  padding-top: 60px;
  padding-left: 60px;
`;
