import React, { useContext } from 'react';
import { StudioContext, StudioPrintSettingsContext, useDebounceToGlobal, UserContext } from 'venn-components';
import styled, { ThemeContext } from 'styled-components';
import { CheckboxWrapper, getAppTitle, GetColor, Icon, Tooltip, TooltipBodyDirection } from 'venn-ui-kit';
import { CUSTOM_FOOTER_MAX_LENGTH, GapWrapper, Img, ImgWrapper, InputField, StyledLabel } from '../shared';
import type { SetterOrUpdater } from 'recoil';
import { useRecoilState } from 'recoil';
import {
  studioCustomFooterText,
  studioHideFooterDisclaimer,
  studioHideFooterPagination,
  studioHideHeaderCompanyName,
  studioHideHeaderLogo,
  studioHideHeaderViewName,
} from 'venn-state';

const getHideToggleConfig = ([isHidden, setter]: [boolean, SetterOrUpdater<boolean>]) => {
  const toggleValue = () => setter((oldValue) => !oldValue);
  return { checked: !isHidden, onChange: toggleValue };
};

const PageSection = () => {
  const { profileSettings, hasPermission } = useContext(UserContext);
  const { analysisView } = useContext(StudioContext);
  const { defaultLogoId, organizationPrintSettings } = useContext(StudioPrintSettingsContext);
  const hideHeaderLogoState = useRecoilState(studioHideHeaderLogo);
  const hideHeaderCompanyNameState = useRecoilState(studioHideHeaderCompanyName);
  const hideHeaderViewNameState = useRecoilState(studioHideHeaderViewName);
  const hideFooterDisclaimerState = useRecoilState(studioHideFooterDisclaimer);
  const hideFooterPaginationState = useRecoilState(studioHideFooterPagination);
  const [customFooterText, setCustomFooterText] = useRecoilState(studioCustomFooterText);
  const [footerText, setFooterText] = useDebounceToGlobal(customFooterText ?? '', (values) => {
    setCustomFooterText(values);
  });
  const theme = useContext(ThemeContext);

  if (!analysisView) {
    return null;
  }

  const canCreateTemplate = hasPermission('STUDIO_CREATE_TEMPLATE');

  return (
    <Wrapper>
      <GapWrapper>
        <StyledLabel>Header</StyledLabel>
        <CheckboxWrapper {...getHideToggleConfig(hideHeaderLogoState)} disabled={!canCreateTemplate}>
          <Field>
            Company Logo
            <Tooltip content="Logo can be updated in Settings" bodyDirection={TooltipBodyDirection.Right}>
              <InfoIcon type="info-circle" prefix="far" />
            </Tooltip>
            :
            {defaultLogoId && (
              <ImgWrapper>
                <Img src={`/api/printing/logo/${defaultLogoId}`} />
              </ImgWrapper>
            )}
          </Field>
        </CheckboxWrapper>
        <CheckboxWrapper {...getHideToggleConfig(hideHeaderCompanyNameState)} disabled={!canCreateTemplate}>
          <strong>Company Name: </strong>{' '}
          {organizationPrintSettings?.organizationName ?? profileSettings?.organization?.name}
        </CheckboxWrapper>
        <CheckboxWrapper {...getHideToggleConfig(hideHeaderViewNameState)} disabled={!canCreateTemplate}>
          <strong>View Name: </strong> {analysisView?.name ?? 'Unsaved View'}
        </CheckboxWrapper>
      </GapWrapper>
      <GapWrapper>
        <StyledLabel>Footer</StyledLabel>
        <TwoColumns>
          <CheckboxWrapper {...getHideToggleConfig(hideFooterDisclaimerState)} disabled={!canCreateTemplate}>
            <strong> Disclaimer</strong>
            <Tooltip
              content={`Appears on bottom of every page in the report in addition to ${getAppTitle()} disclaimers`}
              bodyDirection={TooltipBodyDirection.Right}
            >
              <InfoIcon type="info-circle" prefix="far" />
            </Tooltip>
          </CheckboxWrapper>
          <CheckboxWrapper {...getHideToggleConfig(hideFooterPaginationState)} disabled={!canCreateTemplate}>
            <strong>Page Number</strong>
          </CheckboxWrapper>
        </TwoColumns>
        <StyledLabel htmlFor="custom-text">
          {' '}
          Custom Footer Text:{' '}
          <Tooltip
            content={`Appears at end of every report in addition to ${getAppTitle()} disclosures`}
            bodyDirection={TooltipBodyDirection.Right}
          >
            <InfoIcon type="info-circle" prefix="far" />
          </Tooltip>
        </StyledLabel>
        <InputField
          placeholder="Enter Text"
          value={footerText}
          name="Prepared For"
          id="custom-text"
          onChange={setFooterText}
          charactersLimit={CUSTOM_FOOTER_MAX_LENGTH}
          disabled={!canCreateTemplate}
          textInputStyle={!canCreateTemplate ? { backgroundColor: theme.Colors.Grey } : undefined}
        />
      </GapWrapper>
    </Wrapper>
  );
};

export default PageSection;

const Wrapper = styled.div`
  label {
    justify-content: flex-start;
  }
`;

const Field = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(Icon)`
  margin: 0 3px;
  color: ${GetColor.HintGrey};
`;

const TwoColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  > label {
    min-width: 50%;
  }
`;
