import { compact } from 'lodash';
import { FS } from 'venn-utils';
import type { StickyMenuItem } from '../sticky-menus/StickyMenus';
import type { ActionEnum } from 'venn-api';

export enum TABS {
  COMPANY = 'company',
  USER = 'user',
  WORKSPACE_CONFIGURATION = 'workspace_configuration',
  PLAN = 'plan',
  SECURITY = 'security',
  NOTIFICATIONS = 'notifications',
  SPONSORSHIPS = 'sponsorships',
}

export interface PermissionedStickyMenuItem<T = string> extends StickyMenuItem<T> {
  requiredPermission?: ActionEnum;
}

export const getMenus = (): PermissionedStickyMenuItem<TABS>[] =>
  compact([
    {
      title: 'Workspace & Team',
      id: TABS.COMPANY,
    },
    {
      title: 'Your Profile & Settings',
      id: TABS.USER,
    },
    {
      title: 'Workspace Configuration',
      id: TABS.WORKSPACE_CONFIGURATION,
    },
    {
      title: 'Plan',
      id: TABS.PLAN,
    },
    {
      title: 'Security',
      id: TABS.SECURITY,
    },
    {
      title: 'Notifications',
      id: TABS.NOTIFICATIONS,
    },
    FS.has('sponsor') && {
      title: 'Sponsorships',
      id: TABS.SPONSORSHIPS,
      requiredPermission: 'SPONSORSHIP_ADMIN',
    },
  ]);

export const getMenuItemById = (id?: TABS): PermissionedStickyMenuItem<TABS> =>
  getMenus().find((item) => item.id === id) ?? getMenus()[0];
