import { compact, flatten, isEqual, isNil, mapValues, uniq } from 'lodash';
import type { CallbackInterface } from 'recoil';
import { atom, atomFamily, selector, selectorFamily, waitForAll } from 'recoil';
import type { Fund, LibrarySearchEntity, Portfolio, PortfolioCompare, PrivatePortfolioNode } from 'venn-api';
import { getFund, getPrivateFund, getPrivatePortfolio, getSpecificPortfolioV3 } from 'venn-api';
import type { AnalysisSubjectType } from 'venn-utils';
import {
  AnalysisSubject,
  arePortfoliosEqual,
  assertNotNil,
  getRequestSubjectForSecondaryAnalysisSubject,
  getRequestSubjectFromAnalysisSubject,
} from 'venn-utils';
import { incrementOnStudioReset, incrementOnSubjectChange, resetOnStudioReset } from '../../effects/signalEffects';
import { allBlockIdsState } from '../grid';
import type { BlockId, StudioRequestSubject, Subject, SubjectGroupId, SubjectWithOptionalFee } from '../types';
import { toSubjectOnly } from '../types';
import { allocatorAnalysisSubject, openAllocatorSubject, openPrivateAllocatorPortfolio } from './allocator';
import { blockSettings } from './blockSettings';
import type { SubjectInputId } from './input-management/subjectInput';
import {
  blockSubjectInputGroups,
  multiSubjectInputGroupSubjects,
  subjectInputGroupName,
  subjectInputGroups,
  subjectInputGroupSubjects,
} from './input-management/subjectInput';
import { subjectsAreEqual } from '../utils';
/**
 * An id that is a dependency of apiAnalysisSubjectQuery
 * so that it's cache will be cleared when this atom changes
 * See https://recoiljs.org/docs/guides/asynchronous-data-queries/#use-a-request-id for more info
 */
const apiAnalysisSubjectQueryId = atomFamily<number, Subject>({
  key: 'apiAnalysisSubjectQueryId',
  default: 0,
  effects: (subject) => [incrementOnStudioReset, incrementOnSubjectChange(subject)],
});

const apiAnalysisSubjectQuery = selectorFamily<AnalysisSubject | undefined, Subject>({
  key: 'apiAnalysisSubjectQuery',
  get:
    (subject) =>
    async ({ get }) => {
      get(apiAnalysisSubjectQueryId(subject));

      const portfolio = subject.portfolioId
        ? (await getSpecificPortfolioV3(subject.portfolioId, subject.portfolioVersion)).content
        : undefined;
      const fund = subject.fundId ? (await getFund(subject.fundId)).content : undefined;
      const privatePortfolio = subject.privatePortfolioId
        ? (await getPrivatePortfolio(subject.privatePortfolioId)).content
        : undefined;
      const privateFund = subject.privateFundId ? (await getPrivateFund(subject.privateFundId)).content : undefined;
      const item = portfolio ?? fund ?? privatePortfolio ?? privateFund;
      if (!item) {
        return undefined;
      }

      return new AnalysisSubject(item, getLibrarySearchEntityAnalysisSubjectType(subject));
    },
});

/** Subject query excluding any impact of allocator panel. */
export const originalAnalysisSubjectQuery = selectorFamily<AnalysisSubject | undefined, Subject | undefined>({
  key: 'originalAnalysisSubjectQuery',
  get:
    (subject) =>
    ({ get }) => {
      const subjectOnly = toSubjectOnly(subject);
      if (!subjectOnly) {
        return undefined;
      }
      return get(apiAnalysisSubjectQuery(subjectOnly));
    },
});

/** Single subject query, including any impact from allocator panel. */
export const analysisSubjectQuery = selectorFamily<AnalysisSubject, SubjectWithOptionalFee>({
  key: 'analysisSubject',
  get:
    (subject) =>
    ({ get }) => {
      const subjectToQuery: Subject | undefined = toSubjectOnly(subject);
      return assertNotNil(
        get(allocatorAnalysisSubject(subjectToQuery)) ?? get(originalAnalysisSubjectQuery(subjectToQuery)),
      );
    },
});

/** Uses {@link analysisSubjectQuery} to query for multiple subjects. */
export const analysisSubjects = selectorFamily<AnalysisSubject[], Subject[]>({
  key: 'analysisSubjects',
  get: (subjects) => () => waitForAll(subjects.map((subject) => analysisSubjectQuery(subject))),
});

export const blockSubjects = selectorFamily<SubjectWithOptionalFee[], BlockId>({
  key: 'blockSubjects',
  get:
    (id) =>
    ({ get }) => {
      const subjectGroups = get(blockSubjectInputGroups(id));
      const allSubjects = get(waitForAll(subjectGroups.map(subjectInputGroupSubjects)));
      const result = subjectGroups.map((_, subjectGroupIndex) => allSubjects[subjectGroupIndex]);
      const supportsPublicInvestments = blockSupportsPublicInvestments(id);
      const supportsPrivateInvestments = blockSupportsPrivateInvestments(id);
      return uniq(result.flat()).filter(
        (s) =>
          (supportsPublicInvestments && !s.privateFundId && !s.privatePortfolioId) ||
          (supportsPrivateInvestments && !s.fundId && !s.portfolioId),
      );
    },
});

export const blockAnalysisSubjects = selectorFamily<AnalysisSubject[], SubjectGroupId>({
  key: 'blockAnalysisSubjects',
  get:
    (id) =>
    ({ get }) =>
      get(waitForAll(get(blockSubjects(id)).map((s) => analysisSubjectQuery(s)))),
});

export const modifiedPortfolioForSubject = selectorFamily<Portfolio | undefined, Subject>({
  key: 'modifiedPortfolioForSubject',
  get:
    (subject) =>
    ({ get }) => {
      if (isNil(subject.portfolioId)) {
        return undefined;
      }
      const originalSubject = get(originalAnalysisSubjectQuery(subject));
      if (isNil(originalSubject) || isNil(originalSubject.portfolio)) {
        return undefined;
      }
      const allocatorSubject = get(allocatorAnalysisSubject(subject));
      if (isNil(allocatorSubject) || isNil(allocatorSubject.portfolio)) {
        return undefined;
      }
      return arePortfoliosEqual(originalSubject.portfolio, allocatorSubject.portfolio)
        ? undefined
        : allocatorSubject.portfolio;
    },
});

/**
 * Stores temporary changes to private portfolio in allocator panel
 * like added/removed investments and changed capital commitment values
 */
export const modifiedPrivatePortfolioForSubject = selectorFamily<PrivatePortfolioNode | undefined, Subject>({
  key: 'modifiedPrivatePortfolioForSubject',
  get:
    (subject) =>
    ({ get }) => {
      if (isNil(subject.privatePortfolioId)) {
        return undefined;
      }
      const originalPortfolio = get(openPrivateAllocatorPortfolio);
      if (isNil(originalPortfolio) || subject.privatePortfolioId !== originalPortfolio.id) {
        return undefined;
      }
      const allocatorSubject = get(allocatorAnalysisSubject(subject));
      if (isNil(allocatorSubject) || isNil(allocatorSubject.privatePortfolio)) {
        return undefined;
      }

      if (isEqual(originalPortfolio, allocatorSubject.privatePortfolio)) {
        return undefined;
      }

      return allocatorSubject.privatePortfolio;
    },
});

export const portfolioInAllocator = selector<Portfolio | undefined>({
  key: 'portfolioInAllocator',
  get: ({ get }) => {
    const openSubject = get(openAllocatorSubject);
    return openSubject && get(modifiedPortfolioForSubject(openSubject));
  },
});

/**
 * For benchmarks, we don't generate additional `StudioRequestSubject`s for secondary subjects, nor do we include
 * modified portfolios.
 */
export const benchmarkRequestSubjects = selectorFamily<StudioRequestSubject[], Subject[]>({
  key: 'benchmarkRequestSubjects',
  get:
    (benchmarks) =>
    ({ get }) => {
      const benchmarkAnalysisSubjects = get(
        waitForAll(benchmarks.map((benchmark) => originalAnalysisSubjectQuery(benchmark))),
      );
      return compact(benchmarkAnalysisSubjects).map((subject) => getRequestSubjectFromAnalysisSubject(subject));
    },
});

const requestSubjectAndSecondary = selectorFamily<
  [StudioRequestSubject, StudioRequestSubject | undefined],
  SubjectWithOptionalFee
>({
  key: 'requestSubjectAndSecondary',
  get:
    (subject) =>
    ({ get }) => {
      const modifiedPortfolio = get(modifiedPortfolioForSubject(subject));
      const modifiedPrivatePortfolio = get(modifiedPrivatePortfolioForSubject(subject));
      const analysisSubject = get(analysisSubjectQuery(subject));

      return [
        {
          ...getRequestSubjectFromAnalysisSubject(analysisSubject),
          feesMapping: subject.feesMapping,
          modifiedPortfolio,
          modifiedPrivatePortfolio,
        },
        getRequestSubjectForSecondaryAnalysisSubject(analysisSubject),
      ];
    },
});

export const multiSubjectInputGroupRequestSubjects = selectorFamily<
  Record<SubjectInputId, StudioRequestSubject[]>,
  SubjectInputId[]
>({
  key: 'multiSubjectInputGroupRequestSubjects',
  get:
    (groupIds) =>
    ({ get }) =>
      mapValues(get(multiSubjectInputGroupSubjects(groupIds)), (subjects) => get(requestSubjects(subjects))),
});

export const excludedFees = selectorFamily<Record<SubjectInputId, string[][]>, SubjectInputId[]>({
  key: 'excludedFees',
  get:
    (groupIds) =>
    ({ get }) =>
      mapValues(get(multiSubjectInputGroupRequestSubjects(groupIds)), (subjects) =>
        subjects.map((subject) =>
          Object.keys(subject.feesMapping ?? []).filter(
            (key) =>
              key !== subject?.portfolio?.id?.toString() &&
              key !== subject?.fund?.id &&
              subject.feesMapping?.[key] === 0,
          ),
        ),
      ),
});

export const requestSubjects = selectorFamily<StudioRequestSubject[], SubjectWithOptionalFee[]>({
  key: 'requestSubjects',
  get:
    (subjects) =>
    ({ get }) => {
      return compact(flatten(get(waitForAll(subjects.map((subject) => requestSubjectAndSecondary(subject))))));
    },
});

export const requestSubjectsWithIndividualBenchmarks = selectorFamily<StudioRequestSubject[], SubjectWithOptionalFee[]>(
  {
    key: 'requestSubjectsWithIndividualBenchmarks',
    get:
      (allSubjects) =>
      ({ get }) => {
        const subjects = get(requestSubjects(allSubjects));
        return compact([
          ...subjects,
          ...subjects.map(
            (s) =>
              s.individualBenchmark && getRequestSubjectFromAnalysisSubject(getBenchmarkSubject(s.individualBenchmark)),
          ),
        ]);
      },
  },
);

const usedSubjects = selectorFamily<StudioRequestSubject[], [BlockId, SubjectWithOptionalFee[]]>({
  key: 'usedSubjects',
  get:
    ([id, subjects]) =>
    ({ get }) => {
      const supportsPublicInvestments = get(blockSupportsPublicInvestments(id));
      const supportsPrivateInvestments = get(blockSupportsPrivateInvestments(id));
      const supportsPortfoliosOnly = get(blockSupportsPortfoliosOnly(id));
      return get(
        requestSubjects(
          subjects
            .filter(
              (s) =>
                (supportsPublicInvestments && !s.privatePortfolioId && !s.privateFundId) ||
                (supportsPrivateInvestments && !s.fundId && !s.portfolioId),
            )
            .filter((s) => (supportsPortfoliosOnly ? !s.fundId && !s.privateFundId : true)),
        ),
      );
    },
});

export const subjectGroupRequestSubjects = selectorFamily<StudioRequestSubject[], [BlockId, SubjectInputId]>({
  key: 'subjectGroupRequestSubjects',
  get:
    ([id, groupId]) =>
    ({ get }) => {
      const subjects = get(subjectInputGroupSubjects(groupId));
      return get(usedSubjects([id, subjects]));
    },
});

export const blockRequestSubjects = selectorFamily<StudioRequestSubject[], BlockId>({
  key: 'blockRequestSubjects',
  get:
    (id) =>
    ({ get }) => {
      const subjects = get(blockSubjects(id));
      return get(usedSubjects([id, subjects]));
    },
});

/** use this in a useRecoilCallback hook to delete a subject group for a given subject group id */
export const onSubjectInputDelete =
  ({ snapshot, set, reset }: CallbackInterface) =>
  async (inputId: SubjectInputId) => {
    set(subjectInputGroups, (current) => current.filter((id) => id !== inputId));

    reset(subjectInputGroupName(inputId));
    reset(subjectInputGroupSubjects(inputId));

    const blockIds = await snapshot.getPromise(allBlockIdsState);
    blockIds.forEach((blockId) =>
      set(blockSubjectInputGroups(blockId), (current) => current.filter((id) => id !== inputId)),
    );
  };

const blockSupportsPublicInvestments = selectorFamily<boolean, BlockId>({
  key: 'blockSupportsPublicInvestments',
  get:
    (id) =>
    ({ get }) =>
      get(blockSettings(id)).supportsPublicInvestments,
});
const blockSupportsPrivateInvestments = selectorFamily<boolean, BlockId>({
  key: 'blockSupportsPrivateInvestments',
  get:
    (id) =>
    ({ get }) =>
      get(blockSettings(id)).supportsPrivateInvestments,
});

const blockSupportsPortfoliosOnly = selectorFamily<boolean, BlockId>({
  key: 'blockSupportsPortfoliosOnly',
  get:
    (id) =>
    ({ get }) =>
      get(blockSettings(id)).supportsPortfoliosOnly,
});

/**
 * It is possible for subjects to be deleted from Venn that are contained within a saved view
 * inaccessibleSubjectsState stores any subjects that are no longer accessible in the view
 */
export const inaccessibleSubjectsState = atom<Subject[]>({
  key: 'inaccessibleSubjectsState',
  default: [],
  effects: [resetOnStudioReset],
});

export const inaccessibleSubjectsDismissed = atomFamily<Subject[] | undefined, string>({
  key: 'inaccessibleSubjectsDismissed',
  default: undefined,
});

export const showInaccessibleSubjectsModal = selectorFamily<boolean, string>({
  key: 'showInaccessibleSubjectsModal',
  get:
    (id) =>
    ({ get }) => {
      const inaccessibleSubjects = get(inaccessibleSubjectsState);
      if (inaccessibleSubjects.length === 0) {
        return false;
      }
      const lastDismissedSubjects = get(inaccessibleSubjectsDismissed(id));
      return (
        inaccessibleSubjects.length !== lastDismissedSubjects?.length ||
        inaccessibleSubjects.some((subject, idx) => !subjectsAreEqual(subject, lastDismissedSubjects[idx]))
      );
    },
  set:
    (id) =>
    ({ get, set }, value) => {
      if (value) {
        set(inaccessibleSubjectsDismissed(id), undefined);
      } else {
        set(inaccessibleSubjectsDismissed(id), get(inaccessibleSubjectsState));
      }
    },
});

export const getLibrarySearchEntityAnalysisSubjectType = (
  entity: LibrarySearchEntity | Subject,
): AnalysisSubjectType => {
  return entity.privateFundId
    ? 'private-investment'
    : entity.privatePortfolioId
      ? 'private-portfolio'
      : entity.fundId
        ? 'investment'
        : 'portfolio';
};

export const getBenchmarkSubject = (compare: PortfolioCompare): AnalysisSubject => {
  if (compare.fundId) {
    const fund = { id: compare.fundId, name: compare.name } as Fund;
    return new AnalysisSubject(fund, 'investment');
  }
  const portfolio = {
    id: compare.portfolioId,
    name: compare.name,
    children: [],
    demo: false,
    draft: true,
    master: false,
  } as Portfolio;
  return new AnalysisSubject(portfolio, 'portfolio', { instrumentIdOnly: true });
};
