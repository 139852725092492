import { atomFamily } from 'recoil';
import type { BlockId } from '../types';
import { unsavedChangesEffect } from '../../effects/unsavedChangesEffect';

export const viewTitle = atomFamily<string | undefined, BlockId>({
  key: 'viewTitle',
  default: undefined,
  effects: [unsavedChangesEffect],
});

export const viewSectionName = atomFamily<string | undefined, BlockId>({
  key: 'viewSectionName',
  default: undefined,
  effects: [unsavedChangesEffect],
});

export const viewSubtitle = atomFamily<string | undefined, BlockId>({
  key: 'viewSubtitle',
  default: undefined,
  effects: [unsavedChangesEffect],
});
