import React from 'react';
import styled from 'styled-components';
import type { SelectTypes } from 'venn-ui-kit';
import { GetColor, Icon } from 'venn-ui-kit';
import type { CustomSelectProps, SearchMenuItem } from 'venn-components';

const CreateCompositeFooter =
  (openModal: () => void) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  ({ selectProps }: SelectTypes.MenuListComponentProps<SearchMenuItem>) => {
    const { closeSearchMenu } = selectProps as CustomSelectProps;
    const onClick = () => {
      closeSearchMenu();
      openModal();
    };
    return (
      <Wrapper>
        <Button onClick={onClick}>
          <PlusIcon type="plus-circle" prefix="far" /> Create composite benchmark
        </Button>
      </Wrapper>
    );
  };

export const CreateFromScratchFooter =
  (callback: () => void) =>
  // TODO(VENN-24534): add a display name to this React component
  // eslint-disable-next-line react/display-name
  ({ selectProps }: SelectTypes.MenuListComponentProps<SearchMenuItem>) => {
    const { closeSearchMenu } = selectProps as CustomSelectProps;
    const onClick = () => {
      closeSearchMenu();
      callback();
    };
    return (
      <Wrapper>
        <Button onClick={onClick}>Create from scratch...</Button>
      </Wrapper>
    );
  };

export default CreateCompositeFooter;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${GetColor.LightGrey};
  height: 25px;
  padding-top: 20px;
  margin-top: 10px;
`;

const PlusIcon = styled(Icon)`
  font-size: 20px;
  margin-right: 10px;
`;

const Button = styled.button`
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;
