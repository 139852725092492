/**
 * @fileoverview contains the write-oriented API for feature flags while bridging the gap between the Recoil API with the static API.
 */
import type { FeatureName, PathType } from './features';
import { useRecoilCallback } from 'recoil';
import { featureDebugOverridesMap, featureMapState, rawFeatureMapState } from './state/recoilState';
import { useCallback } from 'react';
import { setFeatureMapGetter } from './state/staticState';
import { useClient } from '../hooks/useClient/useClient';
import { FeatureAvailabilityService } from 'venn-api-client/public_api/misc/v1/feature_availability_connect';
import { AvailabilityStatus } from 'venn-api-client/public_api/misc/v1/feature_availability_pb';
import { retryRpc } from 'venn-api';

/**
 * Returns a callback function that fetches and initializes the feature map for the current user.
 */
export function useInitializeFeatures(pathType?: PathType) {
  const initializeStaticBridge = useInitializeStaticBridge();
  const client = useClient(FeatureAvailabilityService, pathType);

  // TODO(collin.irwin): currently features get initialized from 2-3 places concurrently.
  // We can globally debounce this initialization to avoid multiple concurrent calls.
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        const featuresResult = await retryRpc(() => client.getVisibleFeatures({}));

        if (featuresResult.isOk()) {
          const lowercaseKeysResponse = Object.fromEntries(
            Object.entries(featuresResult.value.features).map(([k, v]) => [k.toLowerCase(), v]),
          );

          // Recoil API
          set(rawFeatureMapState, lowercaseKeysResponse);

          // Static API
          initializeStaticBridge();
        } else {
          set(rawFeatureMapState, {});
        }
      },
    [initializeStaticBridge, client],
  );
}

/**
 * Returns a callback function that overrides a specific feature to have the enabled/disabled state provided.
 */
export function useSetFFDebugOverride() {
  const initializeStaticBridge = useInitializeStaticBridge();

  return useRecoilCallback(
    ({ set }) =>
      (featureName: FeatureName, newHas: boolean) => {
        const newStatus = newHas ? AvailabilityStatus.AVAILABLE : AvailabilityStatus.UNAVAILABLE;

        // Recoil API
        set(featureDebugOverridesMap, (original) => ({
          ...original,
          [featureName]: { ...original[featureName], status: newStatus },
        }));

        // Static API
        initializeStaticBridge();
      },
    [initializeStaticBridge],
  );
}

function useInitializeStaticBridge() {
  const featureMapGetter = useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(featureMapState).valueMaybe(),
    [],
  );

  return useCallback(() => setFeatureMapGetter(featureMapGetter), [featureMapGetter]);
}
