import React, { useState, useMemo, Suspense } from 'react';
import styled from 'styled-components';
import { GetColor, ColorUtils, Button, Subtitle2, ShimmerBlock } from 'venn-ui-kit';
import { useRecoilValue, waitForAll } from 'recoil';
import type { SubjectInputId } from 'venn-state';
import { subjectInputGroupName } from 'venn-state';
import { Message } from '../../../../shared';
import { without } from 'lodash';
import { SubjectGroupEditor } from 'venn-components';
import { SelectSubjectInputSection } from './SelectSubjectInputSection';
import { InputDropMenu } from '../components/shared';
import { MAX_INPUTS_PER_KIND } from 'venn-utils';

/** For selecting or editing a subject input. */
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export const SubjectInputDropMenu = React.memo(
  ({
    menuInputIds,
    selectedInputId,
    onSelectGroup,
    onCollapse,
    readonly,
    invalid,
  }: {
    menuInputIds: SubjectInputId[];
    selectedInputId?: SubjectInputId;
    onSelectGroup: (selectedInputId: SubjectInputId) => void;
    onCollapse?: () => void;
    readonly: boolean;
    invalid?: boolean;
  }) => {
    const [editorOpen, setEditorOpen] = useState(false);
    const menuInputNames = useRecoilValue(waitForAll(menuInputIds.map((groupId) => subjectInputGroupName(groupId))));
    const inputDropMenuItems = menuInputIds.map((groupId, index) => ({ value: groupId, label: menuInputNames[index] }));

    const inputMenuOnCollapse = () => {
      if (!editorOpen) {
        onCollapse?.();
      }
    };

    return (
      <span>
        {editorOpen && (
          <SubjectGroupEditor
            groupId={undefined}
            onClose={(savedGroupId) => {
              setEditorOpen(false);
              savedGroupId && onSelectGroup(savedGroupId);
              onCollapse?.();
            }}
          />
        )}
        <InputDropMenu
          // usePortal is necessary otherwise the dropmenu is constrained by the parent panel and cannot overflow
          usePortal
          // minimumItemsToTrigger of 0 is necessary so that when no subject groups are available, user can still use the menu UI to create a new group
          minimumItemsToTrigger={0}
          items={inputDropMenuItems}
          openByDefault={!selectedInputId}
          onCollapse={inputMenuOnCollapse}
          menuComponent={(highlighted, forceCollapse, className) => (
            <MenuPopup className={className}>
              <Suspense fallback={<ShimmerBlock />}>
                <SubjectInputSelectorMenuContent
                  inputIds={menuInputIds}
                  checkedInputId={highlighted?.value}
                  onSelectGroup={(selectedGroupId) => {
                    onSelectGroup(selectedGroupId);
                    forceCollapse();
                  }}
                  openEditor={() => {
                    setEditorOpen(true);
                    forceCollapse();
                  }}
                />
              </Suspense>
            </MenuPopup>
          )}
          selected={selectedInputId}
          width={180}
          disabled={readonly}
          invalid={invalid}
        />
      </span>
    );
  },
);

/** Sizing constraints on contents, but no styling or layout. */
const MenuPopup = styled.div`
  width: 280px;
`;

/**
 * Layout, styling, and content for the menu, but no constraints on width and height as that is delegated to the parent.
 */
// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
const SubjectInputSelectorMenuContent = React.memo(
  ({
    inputIds,
    checkedInputId,
    onSelectGroup,
    openEditor,
  }: {
    inputIds: SubjectInputId[];
    checkedInputId?: SubjectInputId;
    onSelectGroup: (selectedGroupId: SubjectInputId) => void;
    openEditor: () => void;
  }) => {
    /** Sorted with the checked input Id, if it exists, to the first index. */
    const sortedInputIds = useMemo(
      () => (checkedInputId ? [checkedInputId, ...without(inputIds, checkedInputId)] : inputIds),
      [checkedInputId, inputIds],
    );

    return (
      <SubjectInputMenuContentWrapper>
        <SubjectInputMenuBody>
          <SubjectInputMenuHeader>Subject Inputs</SubjectInputMenuHeader>

          {sortedInputIds.length ? (
            sortedInputIds.map((inputId) => (
              <SelectSubjectInputSection
                key={inputId}
                inputId={inputId}
                isChecked={checkedInputId === inputId}
                onClick={onSelectGroup}
              />
            ))
          ) : (
            <Message colorOverride={ColorUtils.opacifyFrom(GetColor.Warning, 0.1)}>
              No subject input groups are available.
            </Message>
          )}
        </SubjectInputMenuBody>

        <SubjectInputMenuFooter>
          <Button dense onClick={openEditor} icon="search" disabled={inputIds.length >= MAX_INPUTS_PER_KIND}>
            Select New Subject Group
          </Button>
        </SubjectInputMenuFooter>
      </SubjectInputMenuContentWrapper>
    );
  },
);

/** Wraps the content with layout and container-level styling, but no sizing constraints. */
const SubjectInputMenuContentWrapper = styled.div`
  background-color: ${GetColor.White};
  border-radius: 4px;
  border: solid 1px ${GetColor.Grey};
  box-shadow: 0 0 20px ${GetColor.Grey};
`;

const SubjectInputMenuHeader = styled(Subtitle2)`
  margin: 10px 0 10px 8px;
`;

const SubjectInputMenuBody = styled.div`
  min-height: 0;
  max-height: 357px;
  overflow: auto;
  padding-bottom: 16px;
`;

const SubjectInputMenuFooter = styled.footer`
  height: 70px;

  border: solid 1px ${GetColor.Grey};

  /** Horizontally and vertically center contents. */
  display: flex;
  justify-content: center;
  align-items: center;
`;
