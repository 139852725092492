import type { PropsWithChildren } from 'react';
import React, { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import type { ComputedInvestmentResidual, Fund, Portfolio } from 'venn-api';
import { forecastPanelViewSelector, ForecastTab } from 'venn-state';
import { GetColor, Icon, Link as LinkStyle, Tooltip } from 'venn-ui-kit';
import { analyticsService, SpecialCssClasses, useHasFF, useQueryClient } from 'venn-utils';
import { STUDIO_ANALYSIS_CACHE_KEY } from '../../studio-blocks/types';
import { ForecastPanelOverrideActionsProvider } from './contexts/ForecastPanelActionsContext';
import { ForecastPanel } from './ForecastPanel';

interface ForecastsMenuLauncherProps {
  /** When launching this modal we must provide a CTA event for tracking */
  ctaDestination?: string;
  ctaPurpose: string;
  ctaText?: string;
  text?: string;
  iconOnly?: boolean;
  renderCustomMenuOpener?: (props: React.PropsWithChildren<MenuOpenerProps>) => React.ReactElement;
  onDefaultForecastUpdated?: (defaultForecastId: string) => void;
  onResidualForecastUpdated?: (fundId?: string) => void;
  onOpenResidualForecastTarget?: ComputedInvestmentResidual;
  portfolio?: Portfolio;
  fund?: Fund;
  onClose?: () => void;
  onClick?: () => void;
  isReadOnly: boolean;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  ctaDestination,
  ctaPurpose,
  ctaText,
  text = 'long-term forecasts',
  iconOnly,
  renderCustomMenuOpener,
  onDefaultForecastUpdated,
  onResidualForecastUpdated,
  onOpenResidualForecastTarget,
  portfolio,
  fund,
  onClose,
  onClick,
  isReadOnly,
}: ForecastsMenuLauncherProps) => {
  const [showForecastsMenu, setShowForecastMenu] = useState<boolean>(false);
  const setForecastPanelView = useSetRecoilState(forecastPanelViewSelector);
  const queryClient = useQueryClient();
  const hasRedesignFF = useHasFF('forecast_panel_redesign_ff');

  const onDefaultForecastUpdatedInner = useCallback(
    (defaultForecastId: string) => {
      queryClient.invalidateQueries({ queryKey: [STUDIO_ANALYSIS_CACHE_KEY] });
      onDefaultForecastUpdated?.(defaultForecastId);
    },
    [onDefaultForecastUpdated, queryClient],
  );

  /** Toggle the open/closed state of the menu. Changed by on-click calls */
  const toggleForecastsMenu = useCallback(
    (shouldShow: boolean) => {
      setShowForecastMenu(shouldShow);
      shouldShow && analyticsService.forecastsViewed();
      onClick?.();

      /* Log CTA events when opened */
      if (shouldShow) {
        const analyticsOpts = {
          destination: ctaDestination ?? 'Edit forecasts modal',
          text: ctaText ?? 'Edit forecasts',
          purpose: ctaPurpose,
          type: 'button',
        };
        analyticsService.ctaClicked(analyticsOpts);
        if (onOpenResidualForecastTarget) {
          setForecastPanelView({
            tab: ForecastTab.InvestmentForecast,
            detail: {
              type: hasRedesignFF ? 'Empty' : 'InvestmentOverrideEditor',
            },
          });
        }
      }
    },
    [onClick, ctaDestination, ctaText, ctaPurpose, onOpenResidualForecastTarget, hasRedesignFF, setForecastPanelView],
  );

  const renderDefaultMenuOpener = (props: PropsWithChildren<MenuOpenerProps>) => <DefaultMenuOpener {...props} />;

  const forecastMenuRenderer = renderCustomMenuOpener ?? renderDefaultMenuOpener;

  const mainContentElement = document.getElementById('main-content');

  return (
    <React.Suspense fallback={null}>
      {forecastMenuRenderer({
        onClick: () => toggleForecastsMenu(true),
        text,
        iconOnly,
        isReadOnly,
        openResidualForecastTarget: onOpenResidualForecastTarget,
      })}
      {mainContentElement && (
        <ForecastPanelOverrideActionsProvider onDefaultForecastUpdated={onDefaultForecastUpdatedInner}>
          <ForecastPanel
            onResidualForecastUpdated={onResidualForecastUpdated}
            showForecastsMenu={showForecastsMenu}
            toggleForecastsMenu={toggleForecastsMenu}
            portfolio={portfolio}
            fund={fund}
            onClose={onClose}
            isReadOnly={isReadOnly}
            mainContentElement={mainContentElement}
          />
        </ForecastPanelOverrideActionsProvider>
      )}
    </React.Suspense>
  );
};

export interface MenuOpenerProps {
  iconOnly?: boolean;
  onClick: () => void;
  text?: string;
  isReadOnly?: boolean;
  openResidualForecastTarget?: ComputedInvestmentResidual;
}

const DefaultMenuOpener = ({ iconOnly, onClick, text }: MenuOpenerProps) => (
  <InteractiveLink className="qa-forecast-link" onClick={onClick}>
    {!iconOnly && `${text} `}
    <Tooltip content="Edit Forecasts">
      <ActionIcon type="edit" iconOnly={iconOnly} className={SpecialCssClasses.NotDownloadable} />
    </Tooltip>
  </InteractiveLink>
);

const InteractiveLink = styled(LinkStyle)`
  &:hover {
    cursor: pointer;
  }

  @media print {
    display: none;
  }
`;

const ActionIcon = styled(Icon)<{ iconOnly?: boolean }>`
  ${({ iconOnly }) => iconOnly && `color: ${GetColor.Grey};`}
  &:hover {
    color: ${GetColor.Primary.Dark};
    cursor: pointer;
  }
`;
