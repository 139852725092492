import type { FeatureFlagRecord } from '../features';

let featureMapGetter: () => FeatureFlagRecord | undefined = () => undefined;

/** Used exclusively as a bridge to Recoil stored state and not intended for any other use. */
export function setFeatureMapGetter(newGetter: typeof featureMapGetter) {
  featureMapGetter = newGetter;
}

export function getFeatureMap() {
  return featureMapGetter();
}
