import type { Theme, VennColors } from 'venn-ui-kit';
import { FS } from 'venn-utils';

type PortfolioLabColors = { current: string; alternate: string; optimized: string; benchmark: string };
export function getPortfolioLabColors(themeOrColors: Theme | VennColors): PortfolioLabColors {
  const hasNewVennTheme = FS.has('new_venn_color_theme');

  const colors = 'Colors' in themeOrColors ? themeOrColors.Colors : themeOrColors;

  if (hasNewVennTheme) {
    return {
      current: colors.StudioSubjectColor.A1,
      alternate: colors.StudioSubjectColor.A2,
      optimized: colors.DEPRECATED_DataBarColor.LightPaleBlue,
      benchmark: colors.DarkGrey,
    };
  }

  return {
    current: colors.Gold,
    alternate: colors.DarkBlue,
    optimized: colors.DEPRECATED_DataBarColor.LightPaleBlue,
    benchmark: colors.DarkGrey,
  };
}
