export const APPEND_ONLY = 'Append Only';
export const DO_NOT_MAP = 'Do Not Map';
export const FUND = 'Fund';

export enum AppendType {
  OVERWRITE_ALL = 1,
  OVERWRITE_OVERLAPPING,
  APPEND,
}

export enum DataUploaderMode {
  Returns,
  NewNavs,
  Initial,
  Privates,
}

// assign string values just so that number values won't incorrectly be used as the step number
export enum DataUploaderView {
  Upload = 'Upload',
  Review = 'Review',
  DisplayNavMapping = 'DisplayNavMapping',
  Initial = 'Initial',
}

export interface DataCell {
  // 0-indexed
  row?: number;
  column: number;
  value: string;
}

export interface DataPoint {
  x: number;
  y: number;
}

export const DO_NOT_MAP_ID = 1;
