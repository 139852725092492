import React from 'react';
import { FilesTrifecta, GridBackground, OnlyExcel } from './Icons';
import { Body1, getAppTitle, Headline2 } from 'venn-ui-kit';
import { BrowseButton } from './Buttons';
import { DataUploaderMode } from '../types';

interface FilesLayoutProps {
  mode: DataUploaderMode;
  onFileChange: (data: File) => void;
  setError: (error: string | undefined) => void;
}

const FilesLayout = ({ mode, onFileChange, setError }: FilesLayoutProps) => {
  const secondaryMsg =
    mode === DataUploaderMode.Privates
      ? `${getAppTitle()} supports Excel files for Private Asset Data`
      : `${getAppTitle()} supports almost any data layout!`;
  return (
    <>
      <GridBackground />
      {mode !== DataUploaderMode.Privates && <FilesTrifecta />}
      {mode === DataUploaderMode.Privates && <OnlyExcel />}
      <Headline2 className="files-title">Drag and drop files here</Headline2>
      <Body1>{secondaryMsg}</Body1>
      <div>
        <BrowseButton onFileChange={onFileChange} setError={setError} />
      </div>
    </>
  );
};

export default FilesLayout;
