import type { PropsWithChildren } from 'react';
import React from 'react';
import { RecoilRoot } from 'recoil';
import type { RecoilWrapperState } from './makeState';

/**
 * Default fallback for the recoil wrapper suspense boundary.
 * Used to reduce confusion if suspense is accidentally triggered, as opposed to just throwing a promise.
 */
const DefaultFallback = () => {
  return <div>This is the default fallback for the base recoil wrapper suspense boundary</div>;
};

/**
 * Wrapper component providing RecoilRoot and enabling custom initialization of Recoil state.
 *
 * @param children - The child components to be wrapped.
 * @param states - An array of Recoil state and value tuples to be initialized.
 * @param customSuspenseFallback - Overrides the default suspense fallback that this component provides.
 */
export const BaseRecoilWrapper = ({
  children,
  states,
  customSuspenseFallback,
}: PropsWithChildren<{
  states?: RecoilWrapperState[];
  customSuspenseFallback?: React.ReactNode;
}>) => {
  return (
    <React.Suspense fallback={customSuspenseFallback || <DefaultFallback />}>
      <RecoilRoot
        initializeState={(snapshot) => {
          states?.forEach((cb) =>
            cb(([atom, value]) => {
              try {
                snapshot.set(atom, value);
              } catch (error) {
                // eslint-disable-next-line no-console
                console.warn(`setting ${atom.key} to ${value} caused error to be thrown.`);
                throw error;
              }
            }),
          );
        }}
      >
        {children}
      </RecoilRoot>
    </React.Suspense>
  );
};
