import { useEffect, useRef } from 'react';
import type { RecoilValue } from 'recoil';
import { useRecoilValue } from 'recoil';

interface RecoilObserverProps<T> {
  node: RecoilValue<T>;
  onChange: (value: T) => void;
  ignoreFirstValue?: boolean;
}

export const RecoilObserver = <T,>({ node, onChange, ignoreFirstValue = true }: RecoilObserverProps<T>) => {
  const isFirstValue = useRef(ignoreFirstValue);

  const value = useRecoilValue(node);

  useEffect(() => {
    if (isFirstValue.current) {
      isFirstValue.current = false;
    } else {
      onChange(value);
    }
  }, [onChange, value]);

  return null;
};
