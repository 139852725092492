import type { FunctionComponent, CSSProperties } from 'react';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import KeyCodes from '../../../KeyCode';
import { GetColor } from '../../../style/color';

interface TriggerContainerProps {
  className?: string;
  disabled?: boolean;
  invalid?: boolean;
  expanded?: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
  width?: number;
  style?: CSSProperties;
  onClick?: () => void;
  onKeyUp?: () => void;
  onKeyDown?: () => void;
  onKeyEsc?: () => void;
  onKeyEnter?: () => void;
}

const TriggerContainer: FunctionComponent<React.PropsWithChildren<TriggerContainerProps>> = ({
  className,
  children,
  disabled,
  invalid,
  expanded,
  innerRef,
  width,
  style,
  onClick,
  onKeyDown,
  onKeyUp,
  onKeyEnter,
  onKeyEsc,
}) => {
  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!disabled) {
        if (onKeyUp && e.keyCode === KeyCodes.ArrowUp) {
          e.preventDefault();
          onKeyUp();
        } else if (onKeyDown && e.keyCode === KeyCodes.ArrowDown) {
          e.preventDefault();
          onKeyDown();
        } else if (onKeyEnter && (e.keyCode === KeyCodes.Enter || (e.keyCode === KeyCodes.Space && !expanded))) {
          e.preventDefault();
          onKeyEnter();
        } else if (onKeyEsc && e.keyCode === KeyCodes.Escape) {
          e.preventDefault();
          onKeyEsc?.();
        }
      }
    },
    [onKeyDown, onKeyUp, onKeyEnter, onKeyEsc, disabled, expanded],
  );
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (
        !disabled &&
        (e.keyCode === KeyCodes.ArrowUp ||
          e.keyCode === KeyCodes.ArrowDown ||
          (e.keyCode === KeyCodes.Space && !expanded))
      ) {
        e.preventDefault();
      }
    },
    [disabled, expanded],
  );
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled) {
        e.preventDefault();
        onClick?.();
      }
    },
    [onClick, disabled],
  );
  return (
    <Container
      role="combobox"
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      aria-haspopup="true"
      aria-invalid={!!invalid}
      tabIndex={0}
      className={className}
      ref={innerRef}
      style={style}
      disabled={disabled}
      width={width}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<{ disabled?: boolean; width?: number }>`
  ${(props) => css`
    min-width: ${props.width ?? 200}px;
  `}
  ${(props) =>
    !props.disabled &&
    css`
      &:focus-within {
        > div:last-child {
          border: 1px solid ${GetColor.Primary.Main};
        }
      }
    `}
`;

export default TriggerContainer;
