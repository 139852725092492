import React, { useCallback, useState } from 'react';
import { GetColor } from 'venn-ui-kit';
import styled, { css } from 'styled-components';
import Dropzone from 'react-dropzone';
import FilesLayout from './FilesLayout';
import { IconClasses } from './Icons';
import FileLoading from './FileLoading';
import type { DataUploaderMode } from '../types';
import { uploadConfig } from '../utils';

export interface DataInputProps {
  loading: boolean;
  onFileChange: (data: string | File) => void;
  setError(message: string): void;
  mode: DataUploaderMode;
}

const DataInput = ({ mode, onFileChange, loading, setError }: DataInputProps) => {
  const [uploadName, setUploadName] = useState<string>('');
  const onFileUpload = useCallback(
    (file: File) => {
      setUploadName(file.name);
      onFileChange(file);
    },
    [onFileChange],
  );

  const onDrop = useCallback(
    (accepted: File[], rejected: File[]) => {
      if (rejected.length > 0) {
        setError('The file you tried to upload is of the wrong type');
      }

      if (accepted.length) {
        onFileUpload(accepted[0]);
      }
    },
    [onFileUpload, setError],
  );

  const allowDrops = !loading;

  return (
    <StyledFileInput dropzoneActive={allowDrops}>
      <Dropzone
        className="uploader-dropzone"
        activeClassName="is-active"
        multiple={false}
        accept={uploadConfig[mode].fileTypes}
        onDrop={onDrop}
        disableClick
        disabled={!allowDrops}
      >
        {loading && <FileLoading label={uploadName} />}
        {!loading && (
          <FilesLayout
            mode={mode}
            onFileChange={onFileUpload}
            // @ts-expect-error: TODO fix strictFunctionTypes
            setError={setError}
          />
        )}
      </Dropzone>
    </StyledFileInput>
  );
};

export default DataInput;

const StyledFileInput = styled.div<{ dropzoneActive: boolean }>`
  position: relative;
  height: 100%;

  .uploader-dropzone {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${(props) =>
      props.dropzoneActive &&
      css`
        border: 2px dashed ${GetColor.Grey};
      `}
    color: ${GetColor.MidGrey2};
    font-size: 12px;
    font-weight: normal;
    line-height: 1.71;

    input {
      width: 100%;
      height: 100%;
    }

    &:hover {
      .paste-title {
        color: ${GetColor.Primary.Dark};
        font-weight: bold;
      }
      .${IconClasses.hover} {
        display: flex;
      }
      .${IconClasses.main} {
        display: none;
      }
    }

    &.is-active {
      border-color: rgba(0, 204, 204, 0.6);
      cursor: move;
      .${IconClasses.drag} {
        display: flex;
      }
      .${IconClasses.main}, .paste-title,
      .files-title {
        display: none;
      }
    }
  }
`;
