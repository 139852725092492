import React, { useContext, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { getContext, getContextMenuItems, StatefulInput, StudioContext, UserContext } from 'venn-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Headline3, DropMenu } from 'venn-ui-kit';
import { StyledLabel, GapWrapper } from '../shared';
import { useHasFF } from 'venn-utils';

const NAME_INPUT = 'project-name-input';
const GLOBAL_CONTEXT = 'Global';

const Details = () => {
  const hasContextSwitching = useHasFF('context_switching');
  const { onChangeReportName, reportNameValue, isDuplicateReportName, analysisView, setAnalysisView } =
    useContext(StudioContext);
  const { currentContext, profileSettings, hasPermissionForResource } = useContext(UserContext);

  const items: DropMenuItem<string>[] = useMemo(
    () => getContextMenuItems(profileSettings?.availableContexts, currentContext, 'EDIT_ANALYSIS_VIEW') ?? [],
    [currentContext, profileSettings?.availableContexts],
  );

  const canEditView = analysisView && hasPermissionForResource('EDIT_ANALYSIS_VIEW', analysisView);

  // Default to global context if not assigned
  const globalContext = items.find((i) => i.label === GLOBAL_CONTEXT)?.value;

  const onChange = useCallback(
    (item: DropMenuItem<string>) => {
      if (!analysisView) {
        return;
      }
      setAnalysisView({ ...analysisView, ownerContextId: item.value });
    },
    [analysisView, setAnalysisView],
  );

  return (
    <GapWrapper>
      <Headline3>Details</Headline3>
      <LabelWrapper>
        <StyledLabel htmlFor={NAME_INPUT}>Document Name</StyledLabel>
      </LabelWrapper>

      <StatefulInput
        placeholder="Document Name"
        value={reportNameValue}
        onChange={onChangeReportName}
        className="qa-save-name-input"
        selectOnFocus
        error={isDuplicateReportName}
        id={NAME_INPUT}
        style={{ width: '100%' }}
      />
      {hasContextSwitching && (
        <GapWrapper>
          <LabelWrapper>
            <StyledLabel>Ownership</StyledLabel>
          </LabelWrapper>
          {canEditView ? (
            <DropMenu
              searchable
              selected={analysisView?.ownerContextId ?? globalContext ?? ''}
              items={items}
              onChange={onChange}
            />
          ) : (
            getContext(analysisView?.ownerContextId ?? '', profileSettings?.availableContexts ?? [])?.context.name
          )}
        </GapWrapper>
      )}
    </GapWrapper>
  );
};

export default Details;

const LabelWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  font-size: 14px;
`;
