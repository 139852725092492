import { isEqual, compact, isNil, mapValues } from 'lodash';
import type {
  CellClassFunc,
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  IAggFuncParams,
  ICellRendererParams,
  IHeaderParams,
} from 'ag-grid-community';
import type { Portfolio } from 'venn-api';
import {
  type StudioRequestSubject,
  type PortfolioComparisonColumnGroupKey,
  type PCBlockCustomMetricGrid,
  createPCBlockColumnKey,
  createPCBlockRowKey,
  isPortfolioComparisonColumnGroupKey,
  createChangesKey,
  subjectToKeyString,
  isChangesKey,
  type GridStyle,
} from 'venn-state';
import { GetColor, type Theme, getItemColor } from 'venn-ui-kit';
import { BOLD_CLASS, DARK_COLUMN_CLASS } from '../customAnalysisContants';
import { baseHeaderClassFn } from './columnUtils';
import { measureHeader } from '../../utils/grids';
import { convertRequestSubjectToItemType } from '../../analysis/compare/compareUtils';
import type { HeaderCellRendererProps } from '../components/grid/renderers/HeaderCellRenderer';
import HeaderCellRenderer from '../components/grid/renderers/HeaderCellRenderer';
import { BasicHeaderRenderer } from '../components/grid/renderers/BasicHeaderRenderer';
import { classToArr, lazyMergeClasses } from '../../utils/ag-grid/styling';
import { type CustomizableMetric, FootnoteSymbols, assert, templateStringSplit } from 'venn-utils';
import { formatExportableSubjectWithOptionalFee } from '../../legend';
import React from 'react';
import styled from 'styled-components';
import { getDefaultCellClass } from './gridStyling';

export const MAX_CUSTOM_FIELD_CHARS = 1000;

type Allocation = {
  allocation: number;
  weight: number;
};

/** The row data for a single subject (or column group), as opposed to {@link AllocationRowData} which contains all data for an entire row. */
export type SingleSubjectRowChunk = {
  /**
   * Label to be displayed for the row.
   */
  label: string;
  /**
   * Path is used by AG Grid to find data and by us to merge data between different portfolios.
   * We need to use names, not IDs, because IDs are not the same between different portfolios.
   * We should not persist the namePath between sessions, because names can change.
   * Indexes are added to path elements to make sure the path is unique, even if a portfolio contains duplicate names
   * at the same level.
   */
  path: string[];
  /** ID path is used for custom metrics storage. We need to use IDs, not names, because names can change and we persist the ID path between sessions. */
  rowIdPath: string[];

  columnGroupKey: PortfolioComparisonColumnGroupKey;
  allocationMetricData: Allocation;
};

/** Contains all of the row chunks relevant to a single subject. */
export type SingleSubjectRowChunks = SingleSubjectRowChunk[];

type ColumnGroupRowData = {
  rowIdPath: string[];
  allocation: number;
  weight: number;
};

type RowData = Record<PortfolioComparisonColumnGroupKey, ColumnGroupRowData | undefined>;
/** Contains all data relevant to a cohesive row, including all data for each column group. This represents a merged set of {@link SingleSubjectRowChunk}. */
export type AllocationRowData = Pick<SingleSubjectRowChunk, 'label' | 'path'> & {
  rowData: RowData;
};

const TOTAL_ROW_ID_PATH = ['venn-total-row'];
const TOTAL_ROW_KEY = createPCBlockRowKey(TOTAL_ROW_ID_PATH);

export const makeTotalRow = (
  subjects: StudioRequestSubject[],
  customMetrics: CustomizableMetric[],
  customMetricGrid: PCBlockCustomMetricGrid,
): AllocationRowData[] => {
  const changesKey = createChangesKey(subjects);
  const changesRowData = [
    changesKey,
    {
      rowIdPath: TOTAL_ROW_ID_PATH,
      allocation: (subjects[1]?.portfolio?.allocation ?? 0) - (subjects[0]?.portfolio?.allocation ?? 0),
      weight: 0,
      ...Object.fromEntries(
        customMetrics.map((metric) => [
          metric.key,
          customMetricGrid[TOTAL_ROW_KEY]?.[createPCBlockColumnKey(changesKey, metric.key)],
        ]),
      ),
    } satisfies ColumnGroupRowData,
  ] as const;

  const subjectRowData = subjects.map((subject) => {
    const subjectKey = subjectToKeyString(subject);
    return [
      subjectKey,
      {
        rowIdPath: TOTAL_ROW_ID_PATH,
        allocation: subject.portfolio?.allocation ?? 0,
        weight: 1,
        ...Object.fromEntries(
          customMetrics.map((metric) => [
            metric.key,
            customMetricGrid[TOTAL_ROW_KEY]?.[createPCBlockColumnKey(subjectKey, metric.key)],
          ]),
        ),
      } satisfies ColumnGroupRowData,
    ] as const;
  });

  const rowDataEntries = [changesRowData, ...subjectRowData];

  // Need to use casting because fromEntries doesn't properly preserve the type otherwise
  const rowData = Object.fromEntries(rowDataEntries) as Record<
    (typeof rowDataEntries)[number][0],
    (typeof rowDataEntries)[number][1]
  >;

  return [
    {
      label: 'Total',
      rowData,
      path: ['Total'],
    },
  ];
};

export const makePortfolioRows = (subject: StudioRequestSubject): SingleSubjectRowChunks => {
  const useNamesMap = {};
  return isNil(subject.portfolio)
    ? []
    : [
        ...subject.portfolio.children.flatMap((child) =>
          makePortfolioNodeRow(
            child,
            [],
            [],
            subject.portfolio?.allocation ?? 0,
            subjectToKeyString(subject),
            useNamesMap,
          ),
        ),
      ];
};

export const makePortfolioNodeRow = (
  portfolio: Portfolio,
  parentIdPath: string[],
  parentNamePath: string[],
  total: number,
  columnGroupKey: PortfolioComparisonColumnGroupKey,
  usedNamesMap: { [name: string]: number },
): SingleSubjectRowChunks => {
  const nameUseCount = usedNamesMap[portfolio.name] ?? 0;
  usedNamesMap[portfolio.name] = nameUseCount + 1;
  const rowIdPath = [...parentIdPath, portfolio.id.toString()];
  const path = [...parentNamePath, `${portfolio.name}(${nameUseCount})`];
  const childrenUsedNamesMap = {};
  const allocation = portfolio.allocation ?? 0;
  const weight = total !== 0 ? allocation / total : 0;
  return [
    {
      label: portfolio.name,
      columnGroupKey,
      allocationMetricData: { allocation, weight },
      rowIdPath,
      path,
    },
    ...(portfolio.children?.flatMap((child) =>
      makePortfolioNodeRow(child, rowIdPath, path, total, columnGroupKey, childrenUsedNamesMap),
    ) ?? []),
  ];
};

/** Merges rows of individual subjects into a single {@link AllocationRowData} containing row data for all columns. */
export const combinePortfolioRows = (
  subjects: StudioRequestSubject[],
  subjectIndexToRowChunks: SingleSubjectRowChunks[],
): AllocationRowData[] => {
  const combinedRows: AllocationRowData[] = [];
  const findCombinedRow = (rowToFind: SingleSubjectRowChunk) =>
    combinedRows.find((primaryRow) => isEqual(primaryRow.path, rowToFind.path));

  for (const subjectRowChunks of subjectIndexToRowChunks) {
    for (const subjectRowChunk of subjectRowChunks) {
      let combinedRow = findCombinedRow(subjectRowChunk);

      if (!combinedRow) {
        combinedRow = {
          label: subjectRowChunk.label,
          path: subjectRowChunk.path,
          rowData: {},
        };
        combinedRows.push(combinedRow);
      }

      combinedRow.rowData[subjectRowChunk.columnGroupKey] = {
        rowIdPath: subjectRowChunk.rowIdPath,
        ...subjectRowChunk.allocationMetricData,
      };
    }
  }

  // Calculate changes from the first two subjects
  for (const row of combinedRows) {
    const [firstSubjectRowData, secondSubjectRowData] = subjectIndexToRowChunks.slice(0, 2).map((subjectRowChunks) => {
      const columnGroupKey = subjectRowChunks[0]?.columnGroupKey;
      return columnGroupKey ? row.rowData[columnGroupKey] : undefined;
    });

    // The row could exist due to a subject other than the first two subjects.
    if (!firstSubjectRowData && !secondSubjectRowData) {
      continue;
    }

    row.rowData[createChangesKey(subjects)] = {
      rowIdPath: combineRowIdPath(firstSubjectRowData, secondSubjectRowData),
      allocation: (secondSubjectRowData?.allocation ?? 0) - (firstSubjectRowData?.allocation ?? 0),
      weight: (secondSubjectRowData?.weight ?? 0) - (firstSubjectRowData?.weight ?? 0),
    };
  }

  return combinedRows;
};

function combineRowIdPath(
  firstSubjectRowData: ColumnGroupRowData | undefined,
  secondSubjectRowData: ColumnGroupRowData | undefined,
) {
  const firstPath = firstSubjectRowData?.rowIdPath;
  const secondPath = secondSubjectRowData?.rowIdPath;
  const maxLength = Math.max(firstPath?.length ?? 0, secondPath?.length ?? 0);
  return Array.from({ length: maxLength }, (_, i) => [firstPath?.[i] ?? 'nil', secondPath?.[i] ?? 'nil'].join('&'));
}

export const combineRowsWithCustomMetrics = (
  rowData: AllocationRowData[],
  customMetrics: CustomizableMetric[],
  customMetricGrid: PCBlockCustomMetricGrid,
): AllocationRowData[] =>
  rowData.map((row) => {
    return {
      ...row,
      rowData: mapValues(row.rowData, (columnGroupData, columnGroupKey) => {
        assert(isPortfolioComparisonColumnGroupKey(columnGroupKey), `Invalid column group key: ${columnGroupKey}`);
        if (!columnGroupData) {
          return undefined;
        }

        const rowKey = createPCBlockRowKey(columnGroupData.rowIdPath);
        const customMetricRow = customMetricGrid[rowKey];
        if (customMetricRow) {
          customMetrics.forEach((metric) => {
            const columnKey = createPCBlockColumnKey(columnGroupKey, metric.key);
            columnGroupData[metric.key] = customMetricRow[columnKey];
          });
        }
        return columnGroupData;
      }),
    };
  });

const makeCellClass = (params: CellClassParams<AllocationRowData>) => {
  const dark = isChangesKey(params.colDef.cellRendererParams[PC_COLUMN_GROUP_KEY_FIELD]);
  const hasChildren = 'node' in params && params.node?.hasChildren();

  return compact([
    ...classToArr(getDefaultCellClass as CellClassFunc<AllocationRowData>, params),
    hasChildren && BOLD_CLASS,
    dark && DARK_COLUMN_CLASS,
  ]);
};

const makeHeaderClasses = ({ dark }: { dark: boolean }) => compact([dark && DARK_COLUMN_CLASS]);

const Placeholder = styled.span`
  color: ${GetColor.Grey};
  /* The placeholder looks weirder wrapped than not wrapped, although it shouldn't wrap in normal circumstances anyway. */
  white-space: nowrap;
  @media print {
    display: none;
    // Display none doesn't seem to be enough to prevent the placeholder text from affecting things like text wrapping or ag-grid measurement
    //  so we set font size to 0px to prevent the invisible text from affecting things.
    font-size: 0;
  }
`;

const isInPortfolio = (rowData: AllocationRowData | undefined, columnGroupKey: PortfolioComparisonColumnGroupKey) =>
  !isNil(rowData?.rowData[columnGroupKey]);

const PC_COLUMN_GROUP_KEY_FIELD = 'pcColumnKey';

const placeholderCellRendererSelection = { component: PlaceholderCellRenderer };

/** A field path to provide to ag-grid for accessing data within an {@link AllocationRowData} object. */
export type PCField = `rowData.${PortfolioComparisonColumnGroupKey}.${string}` | `rowData.changes.${string}`;

/** Split a Portfolio Comparison block's ag-grid 'field' string into typed parts. */
export function parsePcField(field: PCField) {
  const [pcFieldPrefix, columnGroupKey, metricKey] = templateStringSplit(field, '.');
  assert(pcFieldPrefix === 'rowData', `Invalid PC field: ${field}`);
  assert(isPortfolioComparisonColumnGroupKey(columnGroupKey), `Invalid column group key: ${columnGroupKey}`);
  return { columnGroupKey, metricKey, columnKey: createPCBlockColumnKey(columnGroupKey, metricKey) } as const;
}

const makeChildrenColumnDefs = (
  metrics: CustomizableMetric[],
  columnGroupKey: PortfolioComparisonColumnGroupKey,
  gridStyle: GridStyle,
  theme: Theme,
): ColDef<AllocationRowData>[] =>
  metrics.map((metric) => ({
    headerName: metric.label,
    minWidth: measureHeader(
      metric.label + (metric.analysisType === 'CUSTOM_ENTRY' ? FootnoteSymbols.customMetrics : ''),
      theme,
      gridStyle,
    ),
    headerComponent: (params: IHeaderParams) =>
      metric.analysisType === 'CUSTOM_ENTRY' ? (
        <span>
          {params.displayName}
          <sup>{FootnoteSymbols.customMetrics}</sup>
        </span>
      ) : (
        params.displayName
      ),
    headerClass: lazyMergeClasses(baseHeaderClassFn, makeHeaderClasses({ dark: isChangesKey(columnGroupKey) })),
    cellRendererParams: {
      type: metric.dataType,
      [PC_COLUMN_GROUP_KEY_FIELD]: columnGroupKey,
      maxLength: MAX_CUSTOM_FIELD_CHARS,
    },
    cellClass: makeCellClass,
    autoHeight: true,
    field: `rowData.${columnGroupKey}.${metric.key}` satisfies PCField,

    aggFunc: metric.analysisType === 'CUSTOM_ENTRY' ? undefined : dataAggFunc,
    editable: metric.analysisType === 'CUSTOM_ENTRY' ? isCustomFieldEditable : undefined,
    cellRendererSelector: metric.analysisType === 'CUSTOM_ENTRY' ? customFieldRendererSelector : undefined,
  }));

function PlaceholderCellRenderer() {
  return <Placeholder>Enter text</Placeholder>;
}

function customFieldRendererSelector(params: ICellRendererParams<AllocationRowData>) {
  return !(isNil(params.value) || params.value.toString().trim() === '') ||
    !isInPortfolio(params.data, params.colDef?.cellRendererParams[PC_COLUMN_GROUP_KEY_FIELD])
    ? undefined
    : placeholderCellRendererSelection;
}

function isCustomFieldEditable(params: EditableCallbackParams<AllocationRowData>) {
  return isInPortfolio(params.data, params.colDef.cellRendererParams[PC_COLUMN_GROUP_KEY_FIELD]);
}

function dataAggFunc(params: IAggFuncParams<AllocationRowData, number>) {
  return params.values.reduce((total, current) => total + (current ?? 0), 0);
}

const makeGroupColumnColDef = (
  metrics: CustomizableMetric[],
  subject: StudioRequestSubject,
  gridStyle: GridStyle,
  theme: Theme,
): ColGroupDef<AllocationRowData> => ({
  headerName: formatExportableSubjectWithOptionalFee(subject),
  headerGroupComponent: HeaderCellRenderer,
  headerGroupComponentParams: {
    color: getItemColor(theme.Colors, convertRequestSubjectToItemType(subject)),
    subject,
    isCommonBenchmark: false,
  } as HeaderCellRendererProps,
  children: makeChildrenColumnDefs(metrics, subjectToKeyString(subject), gridStyle, theme),
});

const deltaColumnHeader = 'Changes';

export const makeColumnDefs = (
  metrics: CustomizableMetric[],
  subjects: StudioRequestSubject[],
  gridStyle: GridStyle,
  theme: Theme,
): ColGroupDef<AllocationRowData>[] => {
  const changesColGroup: ColGroupDef<AllocationRowData> = {
    headerName: deltaColumnHeader,
    headerGroupComponent: BasicHeaderRenderer,
    headerGroupComponentParams: { displayName: deltaColumnHeader, color: theme.Colors.Black },
    headerClass: lazyMergeClasses(baseHeaderClassFn, makeHeaderClasses({ dark: true })),
    children: makeChildrenColumnDefs(metrics, createChangesKey(subjects), gridStyle, theme),
  };

  const [firstSubjectColGroup, ...restSubjectColGroups] = subjects.map((subject) =>
    makeGroupColumnColDef(metrics, subject, gridStyle, theme),
  );

  return [firstSubjectColGroup, changesColGroup, ...restSubjectColGroups];
};
