export const eventTargetOrAncestorContainsClass = (event: MouseEvent, className: string) => {
  if (event.target instanceof Element) {
    let currentEvent = event.target;
    while (currentEvent.parentElement instanceof Element) {
      if (currentEvent.className.includes(className)) {
        return true;
      }
      currentEvent = currentEvent.parentElement;
    }
  }
  return false;
};
