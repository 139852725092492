import React, { useContext, useMemo } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { FactorLensesContext, UserContext } from 'venn-components';
import {
  Headline2,
  Headline3,
  Display1,
  Body1,
  Subtitle1,
  Label,
  Hint,
  GetColor,
  Icon,
  ButtonLink,
  ZIndex,
  Tooltip,
  TooltipLink,
  getAppTitle,
  FACTOR_LENS_FAQ_HREF,
  getTextThemeProvider,
} from 'venn-ui-kit';
import { Link } from 'react-router-dom';
import Pyramid from './pyramid';
import { PyramidAlpa } from './chart';
import Background from '../shared/Background';
import { PageHeader } from '../shared/page-header';
import type { FactorLensWithReturns } from 'venn-api';
import { factors } from './content';
import { useHasFF } from 'venn-utils';

export default function FactorLensPage() {
  const theme = useContext(ThemeContext);
  const { profileSettings } = useContext(UserContext);
  const currency = profileSettings?.organization?.currency;
  const excludeFactor = currency !== 'USD' && currency !== 'GBP' ? ['Local Inflation'] : [];
  const factorLens = useContext(FactorLensesContext).primaryFactorLens;
  const factorBlogs = getTextThemeProvider().MarcomSiteLinks;
  const hasNewFactorLens = useHasFF('new_equity_styles_ff');
  const memoizedFactors = useMemo(() => factors(factorBlogs, hasNewFactorLens), [factorBlogs, hasNewFactorLens]);

  return (
    <div>
      <PageContent>
        <PageHeader
          title="Factor Basics"
          subtitle="What are factors and which factors are in the Two Sigma Factor Lens?"
        >
          <ButtonLink
            linkText="Factors FAQ"
            url={FACTOR_LENS_FAQ_HREF}
            ctaTrackingOptions={{
              ctaPurpose: 'Redirect to factors help site',
              ctaText: 'Go to Factors FAQ',
              ctaDestination: 'FAQ',
            }}
          />
        </PageHeader>
        <SubHeader>
          <HalfHalf>
            <div>
              <Headline2 style={{ marginTop: 40 }}>What are factors?</Headline2>
              <Display1 className="qa-factor-description" style={{ marginTop: 30 }}>
                Risk factors are discrete, describable sources of common or systematic risk and return across a diverse
                set of investments.
              </Display1>
            </div>
            <PyramidContainer>
              <Pyramid typography={theme.Typography} />
            </PyramidContainer>
          </HalfHalf>
        </SubHeader>
        <GreyBox>
          <HalfHalf>
            <div>
              <Headline2>Why use a factor approach?</Headline2>
              <Headline3 style={{ marginTop: 30 }}>
                Traditional asset allocation can obfuscate risks that drive correlation across asset classes.{' '}
              </Headline3>
              {hasNewFactorLens ? (
                <Body1 style={{ marginTop: 20 }}>
                  Different asset classes may have exposure to the same risk factors. For example, high yield corporate
                  bonds and stocks have exhibited a long-run positive correlation due to each having exposure to the
                  Equity risk factor (i.e. long-term economic growth and profitability of companies). In fact, an
                  analysis using the USD version of the Two Sigma Factor Lens of the Bloomberg Global High Yield Index
                  indicated that only 20% of the index’s risk was driven by the Credit factor, whereas an additional 43%
                  of the risk was driven by the Equity factor
                  <sup>
                    <a href="#footnote-1">[1]</a>
                  </sup>
                  . Using factors can help allocators identify the common risk and return drivers across asset classes
                  and security types to achieve diversification more effectively.
                </Body1>
              ) : (
                <Body1 style={{ marginTop: 20 }}>
                  Different asset classes may have exposure to the same risk factors. For example, high yield corporate
                  bonds and stocks have exhibited a long-run positive correlation due to each having exposure to the
                  Equity risk factor (i.e. long-term economic growth and profitability of companies). In fact, an
                  analysis using the USD version of the Two Sigma Factor Lens of the Bloomberg Global High Yield Index
                  indicated that only 24% of the index’s risk was driven by the Credit factor, whereas an additional 44%
                  of the risk was driven by the Equity factor
                  <sup>
                    <a href="#footnote-1">[1]</a>
                  </sup>
                  . Using factors can help allocators identify the common risk and return drivers across asset classes
                  and security types to achieve diversification more effectively.
                </Body1>
              )}
            </div>
            <ChartContainer>
              <PyramidAlpa theme={theme} hasNewFactorLens={hasNewFactorLens} />
              <div>
                <Icon type="info-circle" />
                {hasNewFactorLens ? (
                  <Hint>Analysis as of December 6, 2023.</Hint>
                ) : (
                  <Hint>Analysis as of October 01, 2019.</Hint>
                )}
              </div>
            </ChartContainer>
          </HalfHalf>
        </GreyBox>
        <Headline2>The Two Sigma Factor Lens.</Headline2>
        {memoizedFactors.map((category) => (
          <Category key={category.title}>
            <Headline3 className="qa-category-title">{category.title}</Headline3>
            <Subtitle1>{category.subtitle}</Subtitle1>
            {category.groups.map((group) => (
              <FactorGroup
                className="qa-factor-groups"
                key={group.title ?? group.factors.map(({ title }) => title).join(', ')}
              >
                {group.title && <Label>{group.title}</Label>}
                {group.factors
                  .filter((factor) => !excludeFactor.includes(factor.title))
                  .filter((factor) => isFactorInLens(factorLens, factor.title) || category.primary)
                  .map((factor) => (
                    <Factor key={factor.title}>
                      {category.primary ? (
                        factor.hyperlink ? (
                          <FactorNameWithHelpIcon>
                            <FactorName primary followedByIcon>
                              {factor.title}
                            </FactorName>
                            <TooltipLink
                              positions={{
                                top: -60,
                                left: -90,
                              }}
                              href={factor.hyperlink}
                              top
                            />
                          </FactorNameWithHelpIcon>
                        ) : (
                          <FactorName primary>{factor.title}</FactorName>
                        )
                      ) : (
                        <Link
                          className={`qa-${lowerCaseFactorName(factor.title)}`}
                          to={`/factor-lens/deep-dive/${lowerCaseFactorName(factor.title)}`}
                        >
                          <Tooltip content={`Click to view the ${factor.title}  factor Deep Dive.`} maxWidth={330}>
                            <FactorName>{factor.title}</FactorName>
                          </Tooltip>
                        </Link>
                      )}
                      <Body1>{factor.description}</Body1>
                    </Factor>
                  ))}
              </FactorGroup>
            ))}
          </Category>
        ))}
        <Footnotes>
          <Footnote>
            {hasNewFactorLens ? (
              <Hint id="footnote-1">
                <b>[1]</b> {getAppTitle()} Tearsheet Analysis, Factor Contributions to Risk, December 2023. Time period:
                December 7, 2010 - December 6, 2023.
              </Hint>
            ) : (
              <Hint id="footnote-1">
                <b>[1]</b> {getAppTitle()} Tearsheet Analysis, Factor Contributions to Risk, October 2019. Time period:
                December 6, 2010 - October 1, 2019. The index had a positive beta to the Interest Rates factor over this
                time period, however the Interest Rates factor’s (correlation-adjusted) contribution to risk was near
                zero. This is because of Interest Rates’ negative correlation with Equity over this time period and the
                relatively low volatility of the Interest Rates factor versus the index’s other prominent factors:
                Equity, Credit, and Foreign Currency.
              </Hint>
            )}
          </Footnote>
          <Footnote>
            <Hint id="footnote-2">
              <b>[2]</b> Asset class breakdown was sourced from corresponding Index factsheet:{' '}
              <a href="https://www.bloomberg.com/" target="_blank" rel="noopener noreferrer">
                https://www.bloomberg.com/
              </a>
            </Hint>
          </Footnote>
        </Footnotes>
      </PageContent>
      <Background />
    </div>
  );
}

const PageContent = styled.div`
  margin: 60px;
  position: relative;
  z-index: ${ZIndex.Front};
`;

const SubHeader = styled.div`
  display: flex;

  > div {
    flex: 1;
  }
`;

const HalfHalf = styled.div`
  display: flex;
  justify-content: center;

  > * {
    flex: 1;
  }
`;

const GreyBox = styled.div`
  background-color: ${GetColor.WhiteGrey};
  padding: 60px;
  border-radius: 4px;
  margin: 70px 0;
  z-index: ${ZIndex.Front};
`;

const Factor = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const FactorName = styled.div<{ primary?: boolean; followedByIcon?: boolean }>`
  text-transform: uppercase;
  color: ${GetColor.HighlightDark};
  font-weight: bold;
  padding-right: 8px;
  ${(props) =>
    !props.primary &&
    css`
      padding-right: 0;
      line-height: 40px;
      height: 40px;
      width: 190px;
      color: ${GetColor.White};
      background-color: ${GetColor.Primary.Dark};
      margin-right: 20px;
      text-align: center;
    `}
  ${(props) =>
    props.followedByIcon &&
    css`
      padding-right: 0;
    `}
`;

const FactorGroup = styled.div`
  margin-top: 30px;
  font-size: 1.125rem;
`;

const Category = styled.div`
  margin-top: 40px;
`;

const FactorNameWithHelpIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
`;

const PyramidContainer = styled.div`
  position: relative;
`;

const ChartContainer = styled.div`
  text-align: center;
  overflow: hidden;

  > div:nth-child(2) {
    margin-left: 120px;
    margin-top: 20px;
    width: 490px;

    > i {
      margin-right: 10px;
    }
  }
`;

const Footnotes = styled.div`
  margin-top: 200px;
`;

const Footnote = styled.div`
  margin-top: 20px;
`;

const lowerCaseFactorName = (factor: string) => factor.toLowerCase().replace(/ /g, '-');

function isFactorInLens(factorLens: FactorLensWithReturns | undefined, factor: string): boolean {
  if (!factorLens) {
    return true;
  }
  const lowerFactorName = factor.toLowerCase().replace(/\-/g, ' ');
  return factorLens.factors.find((factorEntity) => factorEntity.name.toLowerCase() === lowerFactorName) !== undefined;
}
